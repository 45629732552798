//@ts-check
import React from "react";
/**
 * 
 * @param {{userData: import('./User').UserDetailDto ,
 *  t: import('features/language/Language').LanguageService}} props 
 */
const RootInfo = ({userData, t}) => {

    return <table className="table table-striped small">
        <thead>
            <tr>
                <th>
                    When
                </th>
                <th>
                    What
                </th>
                <th>
                    ClassId
                </th>
                <th>
                    UserAgent
                </th>
            </tr>
        </thead>
        { userData.Root.Visits.map(v=> <tr key={v.Date.getTime()}>
                <td>
                    {t.getShortDateTimeString(v.Date)}
                </td>
                <td>
                    {v.Type}
                </td>
                <td>
                    {v.ClassId}
                </td>
                <td>
                    {v.Agent}
                </td>
            </tr>
        ) }
    </table>

}



export default RootInfo;