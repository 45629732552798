//@ts-check
import dispatchService from "utils/DispatchService";
import { lectureDuck } from "features/course/part/Lectures/LectureDuck";
import { forumService } from "features/classes/ClassItems/Forum/ForumDuck";
import testService from "../test/TestService";
import { courseDuck } from "features/course/CourseDuck";

/**
 * @type import('./Lectures').LecturePlayerService
 */
class LecturePlayerService {
   



    fadeControlsTimerId = null;
    forceShowControls = false;

    waitingForVideoToStop = false;


    controlapi = {};

    /**
     * @type {   import('./Lectures').ScreenApi[]}
     */
    screenapi = [];

    /**
     * @type { import('./Lectures').ControlsApi}
     */
    controls = null;

    currentScreen = -1;

    /**
     * 
     * @param {import('./Lectures').ScreenApi} api 
     */
    RegisterScreen = (api) => {
        this.screenapi.push(api);
    }

    RegisterControls = (ctrlApi) => {
        this.controlapi = ctrlApi;
    }


    Init = () => {
        this.screenapi = [];
        this.controlapi = null;
        if (this.fadeControlsTimerId != null) {
            window.clearTimeout(this.fadeControlsTimerId);
        }
        this.fadeControlsTimerId = null;
        this.forceShowControls = false;
    };


    SeekTo = (fraction, screenType) => {
        if (this.screenapi) {
            try {
                this.screenapi.forEach(a => a.seekTo && a.seekTo(fraction, screenType));
            } catch (error) {
                console.log(error);
            }

        }
    }

    /**
     * 
     * @param {number} positionInseconds 
     * @param {import('./Lectures').LecturePlayerScreenDto} currentScreen 
     */
    mediaPositionChanged(positionInseconds, currentScreen, running) {

        if (!currentScreen || currentScreen.DurationInSeconds <= 0 || !currentScreen.currentCuePoint) {
            return null;
        }

        if (currentScreen.maxPositionInSeconds < positionInseconds) {

            dispatchService.dispatch(lectureDuck.setStoppedAtCuePoint(true));
            console.log("cuep running = " + running);
            console.log("cuep this.waitingForVideoToStop = " + this.waitingForVideoToStop);
            if (this.waitingForVideoToStop) {
                return null;
            }

            this.waitingForVideoToStop = true;

            console.log("cuep running - waitingForVideoToStop  = true");

            if (running === false) {
                this.mediaStopped(currentScreen);
            }
            return currentScreen.maxPositionInSeconds / currentScreen.DurationInSeconds;
        }

        return null;
    }

    mediaStopped(currentScreen) {
        if (this.waitingForVideoToStop === false) {
            return;
        }

        const _this = this;

        // if we have test or forum to show, wait until stack is empty
        if (currentScreen.currentCuePoint.ThreadId) {
            const threadId = currentScreen.currentCuePoint.ThreadId;
            _this.openForumDialog(threadId);
        }

        if (currentScreen.currentCuePoint.test) {
            var testId = currentScreen.currentCuePoint.test;
            _this.openTestDialog(testId);
        }

        window.setTimeout(() => _this.waitingForVideoToStop = false, 200);

    }

    openForumDialog = async (threadId) => {
        const classid = dispatchService.getState().classData.currentClass.Id;
        forumService.getForumDataForClassAndCuePoint(classid, threadId, dispatchService.dispatch);
        dispatchService.dispatch(lectureDuck.setShowForumDialog(true));

    }

    openTestDialog = async (testid) => {


        const classid = dispatchService.getState().classData.currentClass.Id;
        dispatchService.dispatch(courseDuck.setTestInDialog(true));
        testService.getTestWithoutTypename({ dispatch: dispatchService.dispatch, classid, testid, studyMode: true });

        dispatchService.dispatch(lectureDuck.setShowTestDialog(true));
    }

    HandleMouseDown = () => {
        /**
         * @type { import("./Lectures").LectureState }
         */
        const state = dispatchService.getState().lecture;

        if (state.controlsState.fullscreen) {

            if (state.controlsState.showingMenu) {
                dispatchService.dispatch(lectureDuck.setShowingControlsMenu(false));
                if(  state.doPlay === true ){
                    this.StartControlsHidingTimer();
                }
            }
            else if (state.controlsState.visible) {
                if(  state.doPlay === false ){
                    this.StartControlsHidingTimer();
                }
                dispatchService.dispatch(lectureDuck.setWantedPlayingState(!state.doPlay));
            }
            else {
                dispatchService.dispatch(lectureDuck.setControlsVisible(true));
                this.StartControlsHidingTimer();
            }
        }
        else {
            dispatchService.dispatch(lectureDuck.setWantedPlayingState(!state.doPlay));
        }
    }

    SetFullScreenControls = () => {
        dispatchService.dispatch(lectureDuck.setFullScreen(true));
        dispatchService.dispatch(lectureDuck.setControlsVisible(false));

        if (this.fadeControlsTimerId != null) {
            window.clearTimeout(this.fadeControlsTimerId);
        }


    }

    UnsetFullScreenControls = () => {
        dispatchService.dispatch(lectureDuck.setFullScreen(false));
        dispatchService.dispatch(lectureDuck.setControlsVisible(true));
        if (this.fadeControlsTimerId != null) {
            window.clearTimeout(this.fadeControlsTimerId);
        }
    }



    StartControlsHidingTimer = () => {
        if (this.fadeControlsTimerId != null) {
            window.clearTimeout(this.fadeControlsTimerId);
        }
        dispatchService.dispatch(lectureDuck.setControlsVisible(true));
        this.fadeControlsTimerId = window.setTimeout(() =>{
            dispatchService.dispatch(lectureDuck.setControlsVisible(false));
        }, 2000);
    }

    /**
     * 
     * @param {boolean} showing 
     */
    HandleControlsMenuShowing = (showing) => {
        /**
         * @type { import("./Lectures").LectureState }
         */
        const state = dispatchService.getState().lecture;
        if (state.controlsState.fullscreen) {
            if (showing) {
                dispatchService.dispatch(lectureDuck.setShowingControlsMenu(true));
                if (this.fadeControlsTimerId != null) {
                    window.clearTimeout(this.fadeControlsTimerId);
                }
            }
            else{
                this.StartControlsHidingTimer();
            }
        }
    }

    ShowControlsPermanent = (show) => {

        if (show === true) {
            this.forceShowControls = true;
            dispatchService.dispatch(lectureDuck.setControlsVisible(true));
        }
        else {
            this.forceShowControls = false;
            this.StartControlsHidingTimer();
        }
    }




    QuitLecture = () => {
        if (this.controlapi && this.controlapi.Quit) {
            this.controlapi.Quit();
        }
    }


}

const lecturePlayerService = new LecturePlayerService();

export default lecturePlayerService;

