//@ts-check
import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import { classesDuck } from "../../ClassesDuck";
import { forumService } from "./ForumDuck";
import GetStudentsForFilter from "components/GetStudentsForFilter";

/**
 * 
 * @param {{ className: string, subGroupId? :string}} props  
 */
const FilterOutUserInForum = ({ className, subGroupId }) => {

    const dispatch = useDispatch();
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const { userId, filterType } = useTypedSelector(state => state.forum);

    const [users, setUsers] = useState([]);
    const [selected, setSelected] = useState(null);


    useEffect(() => {

        if (subGroupId) {
            const subGr = currentClass.SubGroups.find(s => s.Id === subGroupId);
            if (subGr && subGr.Members) {
                setUsers(subGr.Members.filter(u=> !u.IsArchived));
            }
        }
        else {

            dispatch(classesDuck.getStudentsAndTeachers(currentClass.Id))
                // @ts-ignore
                .then((data) => {
                    setUsers(data.filter(u=> !u.IsArchived));
                    if (userId) {
                        const us = data.find(u => u.Id === userId);
                        if (us) {
                            setSelected(us);
                        }
                    }
                });
        }

        // eslint-disable-next-line 
    }, [])


    const userChanged = useCallback((us) => {

        if (us ) {
            dispatch(forumService.filterData(us.Id, filterType, subGroupId));
        }
        else {
            dispatch(forumService.filterData(null, filterType, subGroupId));
        }

    setSelected(us);

    }, [filterType, setSelected, dispatch, subGroupId]);

    const filterTypeChanged = useCallback((type) => {
        dispatch(forumService.filterData(userId, type, subGroupId));
    }, [userId, dispatch, subGroupId]);



    return <GetStudentsForFilter selected={selected} enabled={(userId !==null)} 
        filterType={filterType} users={users} userChanged={userChanged} filterTypeChanged={filterTypeChanged} className={className} />

}


export default FilterOutUserInForum;
