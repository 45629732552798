//@ts-check
import React from "react";
import { DropdownItem, DropdownMenu } from "reactstrap";

import { Confirm, ModalConstants, ModalDialog } from "components/OnlineModal";
import LnDropDownMenu from "components/LnDropDownMenu";
import LnIcon from "components/LnIcon";

import { docBoxDuck } from "./DocBoxDuck";

import classes from './DocBox.module.scss';
import { useDispatch, useStore } from "react-redux";

/**
 * ActionDropDownFileBuilder
 * @return a dropdown menu.
 * @param {{
    classNameItem?: string,
    classNameMenu?: string,
    currentFolder?: import('features/General/DocBox/DocBox').DocBoxFolderDto,
    currentParentFolder?: import('features/General/DocBox/DocBox').DocBoxFolderDto,
    file: import('features/General/DocBox/DocBox').DocBoxFileDto,
    motherFolderId?: string,
    openAddFile: any,
    openPermissions: any,
    root:  import('./DocBox').DocBoxRootFolderDto,
    type: 'Start' | 'Shared' | 'Trash' | 'SharedTrash',
    t: any,
    moveFileAction: (file:import('features/General/DocBox/DocBox').DocBoxFileDto, targetFolder: import('features/General/DocBox/DocBox').DocBoxFolderDto)=> void;
}} props
 */
const ActionDropDownFileBuilder = ({ classNameItem, classNameMenu, currentFolder, currentParentFolder, file,
    motherFolderId, openAddFile, openPermissions, root, type, t, moveFileAction }) => {


    const dispatch = useDispatch();
    const store = useStore();
    const getState = store.getState;

    const permission = file.Permission;

    const dropDownItems = [];

    const handleDelete = async () => {

        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("delete") + ': ' + file.Name,
            message: (<div dangerouslySetInnerHTML={{ __html: t.getText('file.delete.permanently') }}></div>),
            languageService: t,
            type: "yes/no",
            okOnEnter: true
        });

        if (result === ModalConstants.Yes) {

            docBoxDuck.deleteFile(dispatch, getState, file.Id, type);

        };
    }

    const handleEditFile = () => {
        openAddFile(motherFolderId, file, 'edit');
    }

    const handleMove = () => {

        const listFolders = (folder) => {
            const isDisabled = file.Folder === folder.Id;
            const listItems = [
                <li key={folder.Id} className={["pointer", classes.treeListItem].join(' ')}>
                    {isDisabled ? <><LnIcon className="mr-3 icon-xs" name="dox-icon" />{folder.Name}</> :
                        <button type="button" className={["btn btn-link align-items-center"].join(' ')} onClick={() => moveItem(folder)}><LnIcon className="mr-3 icon-xs" name="dox-icon" />{folder.Name}</button>
                    }
                    {folder.Children && <ul className={classes.treeList}>
                        {folder.Children.map(child => listFolders(child))}
                    </ul>}
                </li>
            ]
            return listItems;
        }

        const moveItem = async (folder) => {
            await moveFileAction(file, folder );
            // await docBoxDuck.moveFile(dispatch, getState, file.Id, folder.Id, type);
            modal.close(undefined);
        }
        const folderListing = listFolders(root);

        const modal = ModalDialog({
            bodyClass: 'modal-body',
            buttons: [{
                className: 'btn btn-primary btn-small',
                text: t.getText('close'),
                type: 'dismiss'
            }],
            headerClass: 'modal-header-default',
            message: (<div className="">
                <ul className={classes.treeList}>
                    {folderListing}

                </ul>
            </div>),
            title: t.getText('chose.folder')
        })

    }

    const handleTrash = async () => {

        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("delete") + ': ' + file.Name,
            message: (<div dangerouslySetInnerHTML={{ __html: t.getText('file.delete.file.warning') }}></div>),
            languageService: t,
            type: "yes/no",
            okOnEnter: true
        });

        if (result === ModalConstants.Yes) {
            docBoxDuck.trashFile(dispatch, getState, currentFolder.Id, type, file.Id);
        };
    }

    const handleUntrash = () => {
        docBoxDuck.untrashFile(dispatch, getState, file.Id, type);
    }

    if (type === 'Start') {

        if (permission.edit) {
            dropDownItems.push(
                <DropdownItem key="change" tag="div" className={classNameItem || ''} onClick={() => handleEditFile()}>
                    <LnIcon className="mr-3 d-none d-md-inline-block pointer icon-small" name="edit-icon" />
                    {t.getText('change')}
                </DropdownItem>
            );
            if (handleMove) {
                dropDownItems.push(
                    <DropdownItem key="move.to.other.folder" tag="div" className={classNameItem || ''} onClick={() => handleMove()}>
                        <LnIcon className="mr-3 d-none d-md-inline-block pointer icon-small" name="movetootherfolder" />
                        {t.getText('move.to.other.folder')}
                    </DropdownItem>,
                );
            }
        }
        if (permission.admin && openPermissions)
            dropDownItems.push(
                <DropdownItem key="share" tag="div" className={classNameItem || ''} onClick={() => openPermissions(file.FileId, 30, false)}>
                    <LnIcon className="mr-3 d-none d-md-inline-block pointer icon-small" name="group" />
                    {t.getText('share')}
                </DropdownItem>
            );
        if (permission.delete) {
            dropDownItems.push(
                <DropdownItem key="delete" tag="div" onClick={() => handleTrash()}>
                    <LnIcon className="mr-3 d-none d-md-inline-block pointer icon-small" name="delete" />
                    {t.getText('delete')}
                </DropdownItem>
            );
        }

    } else if (type === 'Trash') {
        if (permission.delete) {

            if (currentParentFolder === null) {
                dropDownItems.push(
                    <DropdownItem key="untrash" tag="div" className={classNameItem || ''} onClick={() => handleUntrash()}>
                        <LnIcon className="mr-3 d-none d-md-inline-block pointer icon-small" name="info-icon" />
                        {t.getText('reset')}
                    </DropdownItem>
                );
            }

            dropDownItems.push(
                <DropdownItem key="delete" tag="div" onClick={() => handleDelete()}>
                    <LnIcon className="mr-3 d-none d-md-inline-block pointer icon-small" name="delete" />
                    {t.getText('delete')}
                </DropdownItem>
            );
        }
    }

    return (
        dropDownItems.length ? <LnDropDownMenu
            caret={false}
            className={"btn btn-link btn-small pointer pl-4  pl-md-n4  " + (classNameMenu || '')}
            direction="left"
            toggleComponent={
                <>
                    <LnIcon name="more" className={"more "} />
                </>
            }
            toogleClassName={classes.dropDownItem + " "}
            toogleTagName="div"
        >
            <DropdownMenu className={classes.dropDownMenu} tag="div">
                {dropDownItems.map((i) => {
                    return i
                })}
            </DropdownMenu>
        </LnDropDownMenu> : null
    )
}

export default ActionDropDownFileBuilder;