//@ts-check
import React, { useMemo, useCallback, useState } from "react";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import { ErrorMessage } from '@hookform/error-message';

import { endPoint } from "AppConstants";
import { useI18n } from "components/lni18n";
import FileIcon from "components/FileIcons/FileIcon";
import FileUpload from "components/FileUpload/FileUpload";
import LnIcon from "components/LnIcon";
import TextEditor from "components/TextEditor/HtmlTextEditor";
import useTypedSelector from "utils/useTypedSelector";
import classes from './mail.module.scss';

/**
 * MailEditor
 * @module features/classes/ClassItems/ClassMail/MailEditor
 * @return a feed form.
 * @param {{
    fileUploaded: any,
    handleFileDelete: Function,
    mailData:any,
    showNote: boolean,
    updateStatus: Function,
}} props
 */
const MailEditor = ({ fileUploaded, handleFileDelete, mailData, showNote, updateStatus }) => {

    const { languageService: t } = useI18n();
    const [debounceTimerId, setDebounceTimerId] = useState(null);
    const mailconfig = useTypedSelector(state => state.config.mailConfig);
    const user = useTypedSelector(state => state.login.user);

    const useImages = user.CanSwitchUser ? " image" : "";

    const editorSettings = {
        menubar: false,
        ln_custom: {
            useImages: user.CanSwitchUser
        },
        plugins: "visualblocks,code,link,autolink,table,paste,lists",
        browser_spellcheck: true,
        contextmenu: false,
        toolbar: "undo redo | styleselect | table | bold italic  | bullist numlist outdent indent | link code" + useImages,
        valid_elements: "@[id|data*],-h1,-h2,-h3,-h4,-h5,-strong/b,-em/i,#p," +
            "-div[class],br,table[class],tr[class],td[class],thead[class],tbody[class],th[class],tfoot[class],-span" +
            ",-ol,-ul,-li,dl,dt,hr,del/strike,ins,rp,rt,ruby,-small,sub,sup,-code,-blockquote" +
            ",-a[href|target=_blank],img[class|src|alt|title|width|height|align|data-id]",
    }

    const fileOptions = {
        AcceptFileTypes: mailconfig.fileTypes, MaxFileSize: mailconfig.maxFileSize
    };
    /**
     *
     * @param {{key:string, name:string, size:number, type:string}} f
     */
    const handleFileUploadReady = (f) => {
        fileUploaded(f);
        bubbleStatus();

    }




    let initialValues = {
        MailBody: mailData.MailBody || '',
        MailSubject: mailData.MailSubject || '',
    }

    if (showNote) {
        initialValues['Note'] = mailData.Note || '';
    }

    const validationSchema = useMemo(() => Yup.object({
        MailSubject: Yup.string()
            .required(t.getText('emptyheaderwarning')),
        MailBody: Yup.string()
            .required(t.getText('emptybodywarning')),
    }), [t]);

    const { handleSubmit, register, errors, formState, getValues, setValue, trigger } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
        mode: "onBlur"
    });

    const { isDirty, isSubmitting, isValid } = useMemo(() => formState, [formState]);


    const bubbleStatus = useCallback(() => {
        window.setTimeout(() => {
            updateStatus(getValues(), isSubmitting, isValid, isDirty)
        }, 1);

    }, [updateStatus, getValues, isSubmitting, isValid, isDirty]);

    const handleEditorChange = useCallback((e) => {

        if (debounceTimerId != null) {
            window.clearTimeout(debounceTimerId);
            setDebounceTimerId(null);
        }

        const { name, value } = e.target;

        const id = window.setTimeout(async () => {

            setValue(name, value, {
                shouldValidate: true,
                shouldDirty: true
            });
            await trigger(name);
            bubbleStatus();

        }, 400);
        setDebounceTimerId(id);
        // eslint-disable-next-line 
    }, [debounceTimerId, setDebounceTimerId]);

    useCallback(() => {
        bubbleStatus();
        // eslint-disable-next-line
    }, []);

    return (

        <div className="d-flex flex-column">
            <form className={["mb-5", classes['mail-form']].join(' ')} onSubmit={handleSubmit((d) => { })}>


                <div className="form-group mb-4">

                    <label htmlFor="MailSubject">{t.getText('header')}</label>
                    <input onChange={handleEditorChange} ref={register} type="text" name="MailSubject" className="form-control" onBlur={bubbleStatus} />
                    <ErrorMessage as="div" errors={errors} name="MailSubject" className="errorMessage" />
                </div>

                <div className="form-group mb-4">
                    <label htmlFor="MailBody">{t.getText('message')}</label>

                    <TextEditor onBlur={bubbleStatus} register={register} setValue={setValue} editorSettings={editorSettings} initialValue={initialValues.MailBody} name="MailBody"  ></TextEditor>
                    <ErrorMessage as="div" errors={errors} name="MailBody" className="errorMessage" />
                </div>


                {showNote && <div className="form-group mb-4">
                    <label htmlFor="Note">{t.getText('notes')} <br /><small>{t.getText('mail.notes.not.in.mail')}</small></label>

                    <textarea onChange={handleEditorChange} ref={register} onBlur={bubbleStatus} name="Note" className="form-control" rows={3}></textarea>
                </div>}
                <FileUpload imagePreviewOptions={[{ name: "small", maxheight: 100, maxwidth: 100 }]} handleUploadReady={handleFileUploadReady} accept={fileOptions.AcceptFileTypes} languageService={t} maxsize={fileOptions.MaxFileSize} >
                    <div className="d-flex flex-column">
                        {mailData.Attachments.map(f => {
                            return (
                                <div key={f.Id} className="d-flex justify-content-between">
                                    <a className="mb-4" href={endPoint.API_HOST + f.Url}>
                                        <FileIcon className={[classes['file-icon']].join(' ')} size="512px" name={(f.Name || '').split('.').pop()} />
                                        <span>
                                            {f.Name}

                                        </span>
                                    </a>
                                    <button type="button" className="btn btn-link" title={t.getText('delete')} onClick={() => handleFileDelete(f.Id)}>
                                        <LnIcon className={['ml-4 small', classes['icon']].join(' ')} name="delete" />

                                    </button>
                                </div>
                            )

                        })}
                    </div>

                </FileUpload>
            </form>
        </div>

    )

}
export default MailEditor;

