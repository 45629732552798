// @ts-check
import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";
import RegisterForm from "./RegisterForm";
import { SignOnDataStatus } from "features/User/user.enums";
import ReadyForm from "./ReadyForm";
import ErrorHandlerComponent from "components/ErrorHandler/ErrorHandlerComponent";
import classes from './login.module.scss';


const Register = () => {

    const { signOnData } = useTypedSelector(state => state.login);

    if (!signOnData) {
        return <WaitRipple />
    }

    let comp = null;

    if (!signOnData.State || signOnData.State === SignOnDataStatus.showform) {
        comp = <RegisterForm />;
    }
    else if (signOnData.State === SignOnDataStatus.registrationReady) {
        comp = <ReadyForm />;
    }

    return <div className="h-100 ">

        <ErrorHandlerComponent />

        <div className={"d-flex mt-5 align-items-center justify-content-center "}>
            <div className={ classes.register +  " bg-white rounded px-5 pt-4 pb-5 pb-5"}>
                {comp}
            </div>
        </div>
    </div>



}

export default Register;
