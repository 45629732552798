//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import { useI18n } from "components/lni18n";
import TestTop from "../test/TestTop";
import { courseDuck } from "features/course/CourseDuck";
import { drawingTestDuck } from "./DrawingTestDuck";
import useTypedSelector from "utils/useTypedSelector";
import { endPoint } from "AppConstants";
import DrawingTestCanvas from "./DrawingTestCanvas";
import { TestResultStatus } from "../test/TestEnums";
import WaitRipple from "components/WaitRipple";
import DebouncedButton from "components/DebounceButton";


const DrawingTest = props => {

    const currentTheme = courseDuck.useCurrentTheme();
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const state = useTypedSelector(state => state.drawingTest);

    /**
     * @type {import('../test/Test').StartTestDto<any> }
     */
    const currentBaseTest = courseDuck.useCurrentTest();
    const currentPart = courseDuck.useCurrentPart();

    const dispatch = useDispatch();
    const { languageService: t } = useI18n();



    if (!currentBaseTest || !state || !state.currentQuestion) {
        return <WaitRipple />
    }

    const handleSubmit = () => {
        const data = { Explanation: state.currentQuestion.Explanation, Points: state.currentQuestion.Points };
        drawingTestDuck.submitDrawingTest(currentClass.Id, currentBaseTest.TestId, data, dispatch);
    }

    const drawingState = {
        Color: state.currentQuestion.Color,
        Points: state.currentQuestion.Points ? [...state.currentQuestion.Points] : [],
        Selected: null,
        Closed: false,
        DrawHandles: false
    }

    const handleCurveClosed = (state, points) => {
        if (state === false) {
            dispatch(drawingTestDuck.setSplineOk(state));
        }
        else {
            drawingTestDuck.checkCurve(currentClass.Id, currentBaseTest.TestId, points, dispatch);
            dispatch(drawingTestDuck.setPoints([...points]));
        }


    }

    const textChange = (e) => {
        dispatch(drawingTestDuck.setExplaination(e.target.value));
    }


    const readOnly = currentBaseTest.Status !== TestResultStatus.NotSet
        && currentBaseTest.Status !== TestResultStatus.Incomplete && currentBaseTest.Status !== TestResultStatus.TeacherUrge;

    return (
        <div>

            <TestTop languageService={t} classId={currentClass.Id} themeId={currentTheme.Id} partId={currentPart.Id} />

            <div className="mt-3 max-col-width">
                <div >
                    <div className="alert alert-grey max-col-width" >
                        <div>
                            {currentBaseTest.StudentInstructions
                                && <div className="alert border-primary clearfix"
                                    dangerouslySetInnerHTML={{ __html: currentBaseTest.StudentInstructions }} >
                                </div>}
                            <div className="clearfix" dangerouslySetInnerHTML={{ __html: state.currentQuestion.Description }}></div>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col mb-4">

                            <div className="position-relative w-50">
                                {state.savingToserver && <WaitRipple />}
                            </div>

                            <DrawingTestCanvas readOnly={readOnly} handleCurveClosed={handleCurveClosed} state={drawingState} fileUrl={endPoint.API_HOST + state.currentQuestion.ImageUrl} >

                                {!readOnly
                                    && <DebouncedButton onClick={() => handleSubmit()} disabled={!state.canSubmit || state.savingToserver} className="btn btn-primary mr-2" >{t.getText("submit")}</DebouncedButton>}
                            </DrawingTestCanvas>
                        </div>
                        {state.currentQuestion.RequireExplanation
                            && (<div className="col minwidth400">
                                <strong>{t.getText("explaination")}</strong>

                                {!readOnly && <textarea className="form-control" value={state.currentQuestion.Explanation || ""} onChange={textChange} ></textarea>}
                                {readOnly && <div className="word-break-word">{state.currentQuestion.Explanation}</div>}
                            </div>)
                        }

                    </div>
                </div>




                <div className="mt-4">&nbsp;</div>

            </div>

        </div>)
}


export default DrawingTest;

