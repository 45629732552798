//@flow
import React, { useEffect, useRef } from "react";
import linkifyStr from "linkify-string";

    /**
     * 
     * @param {{index: number;
    question: QAQuestionDto;
    dispatchEvent: (any)=>void;
    languageService: import('features/language/languageService').languageService ;
    autoFocus: boolean;
    key: number}} props
     */
const QAQuestion = ({ index, question, dispatchEvent, languageService, autoFocus }) => {

    const reply =useRef(null);

    const handleEvent = (e) => {
        if (reply.current != null) {
            const payload = {
                "index": index,
                "reply": reply.current.value
            };
            dispatchEvent(payload);
        }
    }

    useEffect(() => {
        if (autoFocus === true && reply.current) {
            reply.current.focus();
        }
    }, [reply, autoFocus]);


    return <div className="mt-5">
        <div className="alert alert-grey d-flex justify-content-start">
            <div className="d-inline-flex">
                <strong>
                    {question.QuestionTitle}
                </strong>
            </div>
            <div className="ml-3 clearfix" dangerouslySetInnerHTML={{ __html: question.Question }}></div>
        </div>

        {!question.Edit && <div className="mt-1 alert alert-light word-break-word preserve-white"
            dangerouslySetInnerHTML={{ __html: linkifyStr(question.Reply || "", { attributes:{ target:"_blank", rel: 'noopener noreferrer'}})  }}></div>}
        {question.Edit && <textarea ref={reply} value={question.Reply || ""}
            placeholder={languageService.getText("answer")} className="form-control"
            onChange={handleEvent} ></textarea>}

    </div>

}


export default QAQuestion;