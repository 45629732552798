//@ts-check
import React, { useCallback, useState } from "react";
import { userDuck } from "./UserDuck";

/**
 * 
 * @param {{userData: import('./User').UserDetailDto ,
 *  currentUser:import('./User').CurrentUser,
 *  t: import('features/language/Language').LanguageService}} props 
 */
const UserEmailSettings = ({ userData, currentUser, t }) => {


    const EmailSendingState = {
        none: 0,
        both: 1,
        onlyPrimary: 2,
        onlySecondary: 3
    }

    const [userTestEmailSettings, setUsertestEmailSettings] = useState({
        mail: userData.AllowTestResultEmails === EmailSendingState.both
            || userData.AllowTestResultEmails === EmailSendingState.onlyPrimary,

        mail2: userData.AllowTestResultEmails === EmailSendingState.both
            || userData.AllowTestResultEmails === EmailSendingState.onlySecondary

    });


    // const [userContentEmailSettings, setUserContentEmailSettings] = useState({
    //     mail: userData.AllowCourseChangedEmails === EmailSendingState.both
    //         || userData.AllowCourseChangedEmails === EmailSendingState.onlyPrimary,

    //     mail2: userData.AllowCourseChangedEmails === EmailSendingState.both
    //         || userData.AllowCourseChangedEmails === EmailSendingState.onlySecondary

    // });


    const setTestMailSettings = useCallback(async (e) => {

        if (!e || !e.target) {
            return;
        }

        var newval = { ...userTestEmailSettings };
        newval[e.target.value] = e.target.checked;

        setUsertestEmailSettings(v => newval);

        var state = EmailSendingState.both;

        if (newval.mail && !newval.mail2) {
            state = EmailSendingState.onlyPrimary;
        }

        if (!newval.mail && newval.mail2) {
            state = EmailSendingState.onlySecondary;
        }

        if (!newval.mail && !newval.mail2) {
            state = EmailSendingState.none;
        }

        await userDuck.updateEmailSettings(userData.Id, state);

    }, [setUsertestEmailSettings, EmailSendingState, userData.Id, userTestEmailSettings]);


   
    // const setContentChangedMailSettings = useCallback(async (e) => {

    //     if (!e || !e.target) {
    //         return;
    //     }

    //     var newval = { ...userContentEmailSettings };
    //     newval[e.target.value] = e.target.checked;

    //     setUserContentEmailSettings(v => newval);

    //     var state = EmailSendingState.both;

    //     if (newval.mail && !newval.mail2) {
    //         state = EmailSendingState.onlyPrimary;
    //     }

    //     if (!newval.mail && newval.mail2) {
    //         state = EmailSendingState.onlySecondary;
    //     }

    //     if (!newval.mail && !newval.mail2) {
    //         state = EmailSendingState.none;
    //     }

    //     await userDuck.updateContentChangedEmailSettings(userData.Id, state);

    // }, [setUserContentEmailSettings, EmailSendingState, userData.Id, userContentEmailSettings]);


    return (
        <div className=" pt-4">
            <div className="row mb-4">
                <div className="col">
                    <h3>{t.getText('emails.for.test.comments')}</h3>
                    <div className="custom-control custom-switch mt-3">
                        <input
                            type="checkbox"
                            name="mail"
                            className="custom-control-input"
                            id="id_mail"
                            value="mail"
                            onChange={setTestMailSettings}
                            checked={userTestEmailSettings.mail}


                        />
                        <label
                            className="custom-control-label pt-1"
                            htmlFor="id_mail" >
                            {currentUser.Email}
                        </label>
                    </div>
                    {currentUser.Email2 &&
                    <div className="custom-control custom-switch mt-3">
                            <input
                                type="checkbox"
                                name="mail2"
                                value="mail2"
                                className="custom-control-input"
                                id="id_mail2"
                                onChange={setTestMailSettings}
                                checked={userTestEmailSettings.mail2}

                            />
                            <label
                                className="custom-control-label pt-1"
                                htmlFor="id_mail2" >
                                {currentUser.Email2}
                            </label>
                        </div>
                    }

                </div>

            </div>

            {/* TODO uncomment this when changes emails are deployed */}
            {/* <div className="row mb-4">
                <div className="col">
                    <h3>{t.getText('emails.for.class.changes')}</h3>
                    <div className="custom-control custom-switch mt-3">
                        <input
                            type="checkbox"
                            name="mail"
                            className="custom-control-input"
                            id="idcontent_mail"
                            value="mail"
                            onChange={setContentChangedMailSettings}
                            checked={userContentEmailSettings.mail}


                        />
                        <label
                            className="custom-control-label pt-1"
                            htmlFor="idcontent_mail" >
                            {currentUser.Email}
                        </label>
                    </div>
                    {currentUser.Email2 &&
                    <div className="custom-control custom-switch mt-3">
                            <input
                                type="checkbox"
                                name="mail2"
                                value="mail2"
                                className="custom-control-input"
                                id="idcontent_mail2"
                                onChange={setContentChangedMailSettings}
                                checked={userContentEmailSettings.mail2}

                            />
                            <label
                                className="custom-control-label pt-1"
                                htmlFor="idcontent_mail2" >
                                {currentUser.Email2}
                            </label>
                        </div>
                    }

                </div>

            </div> */}

        </div>
    )

}


export default UserEmailSettings;

