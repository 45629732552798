import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import LnResultIcon from "components/LnResultIcon";
import { useI18n } from "components/lni18n";
import classes from './tests.module.scss';
import { courseDuck } from "features/course/CourseDuck";
import TestLink from "./TestLink";
import { TestResultStatus } from "../test/TestEnums";


/**
 * Tests
 * @module features/course/part/Files/Files
 * @return a list of files either by group or by date.
 *
 */
const Tests = (props) => {

    const { languageService: t } = useI18n();


    const part = courseDuck.useCurrentPart();
    const currentTheme = courseDuck.useCurrentTheme();
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const user = useTypedSelector(state => state.login.user);



    const usedIcons = [];

    if (!part.Tests || part.Tests.length === 0) {
        return null;
    }
    const testsInOrder = part.Tests.map(t => t);
    if (testsInOrder.length > 5) {
        testsInOrder.sort((a, b) => {

            const readyB = b.Status === TestResultStatus.ApprovedByTeacher
                || b.Status === TestResultStatus.ApprovedByTeacherButCanbeBetter
                || b.Status === TestResultStatus.ApprovedWithExcellence;

            const readyA = a.Status === TestResultStatus.ApprovedByTeacher
                || a.Status === TestResultStatus.ApprovedByTeacherButCanbeBetter
                || a.Status === TestResultStatus.ApprovedWithExcellence;

            if (readyA && readyB) {
                return 0;
            }

            return readyB ? -1 : 1;
        })
    }
    const statuses = [  // in the order we want them 

        'teacherurge',
        'incomplete',
        'passed',
        'recievedbyteacher',
        'underreview',
        'followupneeded',
        'approvedbyteacherbutcanbebetter',
        'approvedbyteacher',
        'approvedwithexcellence',
        'failed'
    ];

    const teststatuses = part.Tests.map(t => t.Status);

    statuses.forEach(s => {
        if (teststatuses.indexOf(s) > -1) {
            usedIcons.push({ icon: s, text: t.getText(s.toLowerCase().replace("-", ".")) })
        }
    })

    return <div>
        <div className="d-flex flex-row flex-wrap justify-content-start text-nowrap  border-bottom border-primary border-w2  pb-3 small">
            {usedIcons.map(i => {
                return (<div key={i.text} className={"mt-2 mr-4 d-flex flex-row align-items-center " + classes["icon-item"]}>
                    <LnResultIcon className="mr-3 small" status={i.icon} /> {i.text}
                </div>
                )
            })}
        </div>

        {testsInOrder.filter(t => !!t.ConnectedLecture).length > 0 &&
            <div className="mt-5">
                <h4 className="mb-3">{t.getText("tests.for.study.in.lecture")}
                    {currentClass.CurrentUserIsTeacher && <small className="ml-2" >({t.getText("locked.for.students.until.submitted")})</small>}
                </h4>
                {part.Tests.filter(t => !!t.ConnectedLecture).map(test => <TestLink key={test.Id} user={user} test={test} classId={currentClass.Id} themeId={currentTheme.Id} partId={part.Id} isTeacher={currentClass.CurrentUserIsTeacher} />)}
            </div>
        }

        <div className="mt-5">
            <h4 className="mb-3">{t.getText("tests")}</h4>
            {testsInOrder.filter(t => !t.ConnectedLecture).map(test => <TestLink key={test.Id} user={user} test={test} classId={currentClass.Id} themeId={currentTheme.Id} partId={part.Id} />)}
        </div>
    </div>
}

export default Tests;
