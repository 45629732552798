//@ts-check
import { createSlice } from "@reduxjs/toolkit";
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import TestService from "../test/TestService";



/**
 * @type {{savingToserver: boolean, currentQuestion: {Text: string}}}
 */
const initialState = {
    currentQuestion: null,
    savingToserver: false,
}

const confirmTestSlice = createSlice({
    name: "confirmTest",
    initialState: initialState,
    reducers: {

        /**
         * @param  {  {payload: {Text: string}}} action
         */
        setCurrentConfirmTest(state, action) {
            state.currentQuestion = action.payload;
        },

        /**
   * 
   * @param {{payload: boolean}} action 
   */
        setSavingToserver(state, action) {
            state.savingToserver = action.payload;
        },

    }
});

/**
 * 
 * @param {string} classId 
 * @param {string} testId 
 * @param {Function} dispatch 
 */
const submitConfirmTest = async (classId, testId, dispatch) => {

    dispatch(confirmTestDuck.setSavingToserver(true));
    try {
        const url = endPoint.GET_SUBMIT_CONFIRMTEST_URL(classId, testId)
        await instance.post(url, null);
        TestService.updateTestStatus({ classId: classId, testId: testId }, dispatch);
    } finally {
        dispatch(confirmTestDuck.setSavingToserver(false));
    }

}


export const confirmTestDuck = { ...confirmTestSlice.actions, submitConfirmTest }
export default confirmTestSlice.reducer;
