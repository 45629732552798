import React from "react";

/**
 * 
 * @param {text?: string} props 
 * @returns 
 */
const WaitRipple = ( { text } ) => (
    <div className="ldsRipple">
        {text && <h2>{text}</h2> }
        
        <div></div><div></div></div> );

export default WaitRipple;