import { useSelector} from "react-redux";

/**
 * @type import('types/state').TypedUseSelectorHook
 * 
 */
const useTypedSelector = (expr, shallow) => {
    return useSelector(expr, shallow );
}

export default useTypedSelector;