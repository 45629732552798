//@ts-check

/**
 * Initial data for redux store "text"
 * using x[lang short name] if a trick to force updating when all strings are loaded from API
 */

const data = 
{
    "text": {
        "xsv": {
            "refresh": "Uppdatera",
            "generalerror": "Ett fel har uppstått",
            "network.error": "Fel på nätverket",
            "network.error.message": "Det är något fel med din Internet-uppkoppling. Vi kan inte kontakta LäraNäras server.\nFörsök igen eller om du kan, se över din uppkoppling mot Internet."
        },
        "xen": {
            "refresh": "Refresh",
            "generalerror": "An error has occurred",
            "network.error": "Network error",
            "network.error.message": "There is something wrong with your Internet connection. We can not contact LäraNära's server.\nTry again or if you can, review your connection to the Internet."
        },
        "xda": {
            "refresh": "Opdater",
            "generalerror": "Der er opstået en fejl",
            "network.error": "Netværksfejl",
            "network.error.message": "Der er noget galt med din internetforbindelse. Vi kan ikke kontakte LäraNära's server.\nPrøv igen, eller hvis du kan,  gennemgå din forbindelse til Internettet."
        }
    },
    "languages": [
        {
            "Id": 0,
            "Name": "sv-se",
            "DisplayName": "Svenska",
            "Short": "xsv",
            "IsCurrent": true,
            "StaticText": "Välj språk"
        },
        {
            "Id": 1,
            "Name": "en-gb",
            "DisplayName": "Brittish English",
            "Short": "xen",
            "IsCurrent": false,
            "StaticText": "Change language"
        },
        {
            "Id": 2,
            "Name": "da-dk",
            "DisplayName": "Dansk",
            "Short": "xda",
            "IsCurrent": false,
            "StaticText": "Vælg sprog"
        }
    ]
}

export default data;