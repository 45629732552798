//@ts-check
import React, { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useI18n } from "components/lni18n";
import { Editor } from '@tinymce/tinymce-react';
import ImagePickerDialog, { imagePickerService } from "components/ImagePicker/ImagePickerDialog";
import { imagePickerDuck } from "components/ImagePicker/ImagePickerDuck";
import ImageAlignDialog from "components/ImagePicker/ImageAlignDialog";
import FloatBoxDialog from "./Plugins/FloatBoxDialog";
import ImageDrop from "../ImagePicker/ImageDrop"
import { endPoint } from "AppConstants";

let floatCallback = (res) => { };

const floatboxIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 6.35 6.35" ><path d="M3.096 3.863V2.461H1.257v1.402zM.959 1.721h2.392c.085-.006.156.065.15.15v2.392c.006.085-.065.156-.15.15H.959a.15.15 0 0 1-.15-.15V1.87c-.006-.085.065-.156.15-.15z"/><path d="M.359.377h5.57v.7H.359zm0 4.807h5.57v.7H.359zm3.605-1.602h1.964v.7H3.964zm0-1.603h1.964v.7H3.964z" paint-order="markers fill stroke"/></svg>'

/**
 * @param {{ className?: string, onBlur?: any, register?: any, setValue: Function,
         initialValue:string, onChange?: EventListenerObject, name:string, editorSettings: Object, onSetup?: Function}} props 
 */
const HtmlTextEditor = (props) => {

    /**
     * @type {{current: HTMLDivElement}}
     */
    const editorWrapper = useRef();
    const fileOptions = {
        fileExtensions: "bmp,gif,jpg,png,tif,tiff,jpeg",
        maxsize: "10mb",
        mimetypes: "image/jpeg,image/png,image/gif,image/tiff,image/bmp"
    };


    const dispatch = useDispatch();

    const [boxState, setboxState] = useState(null);
    const [dropFiles, setDropFiles] = useState(null);

    const [menuElementId] = useState("menu_" + Math.floor(Math.random() * 100000));
    /**
     * @type{ {current: HTMLTextAreaElement} }
     */
    const textareaRef = useRef(null);
    const [debounceTimerId, setDebounceTimerId] = useState(null);
    const { currentLanguage, languageService: t } = useI18n();

    // NOTE: to make this works with our language name I have changed file the sv_SE.js to sv.js
    //       and in that file 'tinymce.addI18n('sv_SE'...'  to 'tinymce.addI18n('sv' ...'
    let lang = currentLanguage.Short;


    /**
     * 
     * @param {{value: string, onChange:Function, name: string, editorSettings?: import('./TextEditor').EditorSettings, onSetup?: Function}} props 
     */
    const { initialValue, name, editorSettings, onSetup, setValue, register, onBlur } = props;

    const [editor, setEditor] = useState(null);


    // unique id for each instance of editor
    const [editorId] = useState("id_" + (new Date()).getTime() + "_");

    const imageDialog = useCallback((ed) => {

        /**
         * @type{HTMLImageElement}
         */
        var imgElm = ed.selection.getNode();
        if (imgElm && imgElm.nodeName === "IMG") {

            /**
             * @type {'none' | 'left' | 'right'}
             */
            let align = "none";
            if (imgElm.classList.contains("pull-left") === true) {
                align = "left";
            }
            if (imgElm.classList.contains("pull-right") === true) {
                align = "right";
            }
            const dataId = imgElm.attributes.getNamedItem("data-id");
            const id = dataId ? dataId.value : null;
            const src = imgElm.src;

            dispatch(imagePickerDuck.setAlingData({
                modalOpen: true, imageAlign: align,
                imageId: id, url: src
            }));

            /**
            * 
            * @param {{imageId: string, url:string, imageAlign: 'none' | 'left' | 'right' }} res 
            */
            imagePickerService.onSubmit = (res) => {
                dispatch(imagePickerDuck.setAlingData(null));
                if (res) {
                    imgElm.classList.remove('pull-right', 'pull-left');
                    if (res.imageAlign !== 'none') {
                        imgElm.classList.add('pull-' + res.imageAlign);
                    }
                }
                ed.focus();
            };

        }
        else {
            dispatch(imagePickerDuck.fetchImageData());
            dispatch(imagePickerDuck.setModalState(true));


            /**
             * 
             * @param {{imageId: string, url:string, imageAlign: 'none' | 'left' | 'right' }} res 
             */
            imagePickerService.onSubmit = (res) => {
                dispatch(imagePickerDuck.setModalState(false));
                if (res) {
                    let className = "";
                    if (res.imageAlign !== 'none') {
                        className = `class="pull-${res.imageAlign}"`;
                    }

                    ed.focus();
                    if (res.imageId) {
                        ed.insertContent(`<img ${className} src="${res.url}" data-id="${res.imageId}" />`)
                    }
                    else {
                        ed.insertContent(`<img ${className} src="${res.url}" />`)
                    }
                }
                ed.focus();
            };
        }


    }, [dispatch]);

    const floatBoxDialog = useCallback((ed) => {

        let floatdata = { align: "none", style: "Facts", width: 400, canDelete: false };

        var box = ed.dom.getParent(ed.selection.getNode(), "div.box");
        if (box != null) {
            floatdata.width = parseInt(ed.dom.getAttrib(box, "data-width"), 10);
            floatdata.align = ed.dom.getAttrib(box, "data-align");
            floatdata.style = ed.dom.getAttrib(box, "data-type");
            floatdata.canDelete = true;
        }

        setboxState(floatdata);

        floatCallback = (res) => {
            setboxState(null);
            if (res) {
                const css = `box ${res.style} ${res.align}box w${res.width}`;
                if (box) {

                    if (res.delete) {
                        ed.dom.remove(box, false)
                    }
                    else {
                        ed.dom.setAttrib(box, "data-type", res.style);
                        ed.dom.setAttrib(box, "data-align", res.align);
                        ed.dom.setAttrib(box, "data-width", res.width);
                        ed.dom.setAttrib(box, "class", css);
                    }
                }
                else {
                    const html = `<div class='${css}' data-type='${res.style}' data-align='${res.align}'`
                        + ` data-width='${res.width}'><p>&#160;</p></div>`;
                    ed.insertContent(html);
                }
            }
            ed.focus();

        }
    }, [setboxState]);


    const handleEditorBlur = useCallback(() => {


        /**
         * @type {HTMLTextAreaElement}
         */
        const el = textareaRef.current;
        if (el && el !== undefined) {

            var eventType = "blur", event;

            if ("createEvent" in document) {
                event = document.createEvent("Event");
                event.initEvent(eventType, true, true);
            }
            else if ("Event" in window) {
                event = new Event(eventType, { bubbles: true, cancelable: true });
            }

            el.dispatchEvent(event);

            if (onBlur) {
                el.blur();
            }


        }
    }, [onBlur]);


    useEffect(() => {
        if (editor && editor.setContent && initialValue) {

            editor.setContent(initialValue);
            textareaRef.current.value = initialValue;
        }
    }, [initialValue, editor])

    const handleEditorChange = useCallback((c, e) => {

        if (debounceTimerId != null) {
            window.clearTimeout(debounceTimerId);
            setDebounceTimerId(null);
        }

        const id = window.setTimeout(() => {

            if (setValue) {
                setValue(name, e.getContent(), true, e.getContent({ format: 'text' }));
            }

            handleEditorBlur();

        }, 400);


        setDebounceTimerId(id);
    }, [debounceTimerId, name, setValue, handleEditorBlur]);


    const onPaste = async (ev) => {

        const paste = (ev.clipboardData || window["clipboardData"]);
        const items = paste?.items;
        if (items) {
            for (var i = 0; i < items.length; i++) {
                if (items[i].type.indexOf("image") !== -1) {
                    // We need to represent the image as a file,
                    var blob = items[i].getAsFile();
                    setDropFiles([blob]);
                    break;
                }
            }
        }

        // try {
        //     const clipboardItems = await navigator.clipboard.read();
        //     for (const clipboardItem of clipboardItems) {

        //         const type = clipboardItem.types.find(t => t.indexOf("image") > -1)
        //         if (type) {
        //             const blob = await clipboardItem.getType(type);
        //             setDropFiles([blob]);
        //             return;
        //         }
        //     }
        // } catch (err) {
        //     console.error(err.name, err.message);
        // }
    }


    

    const imageUploader = (ev) => {
        const files = ev.dataTransfer?.files;
        if (files) {
            setDropFiles(files);
        }
    }

    const handleDroppedFileReady = (id) => {
        setDropFiles(null);

        editor.focus();
        if (id) {
            const url = endPoint.GET_IMAGE_URL(id);
            editor.insertContent(`<img src="${url}" data-id="${id}" />`)
        }
    }

    const onCloseDropDialog = () => {
        setDropFiles(null);
    }

    let settings = {
        language: lang,
        relative_urls: false,
        remove_script_host: false,
        // document_base_url: APP_URL,
        //theme: editorSettings && (editorSettings.theme || 'silver'),
        base_url: `${process.env.PUBLIC_URL}/tinymce`,
        plugins: editorSettings && (editorSettings.plugins || "visualblocks,code,link,autolink,table,paste"),
        toolbar: editorSettings && (editorSettings.toolbar || "undo redo | styleselect | table | bold italic  | bullist numlist outdent indent | link code"),
        menubar: editorSettings && (editorSettings.menubar || "edit insert table format view tools"),
        toolbar_items_size: "small",
        browser_spellcheck: true,
        fixed_toolbar_container: "#" + menuElementId,
        skin_url: `${process.env.PUBLIC_URL}/tinymce/skins/ui/oxide`,
        paste_as_text: true,
        contextmenu: false,
        valid_elements: editorSettings && (editorSettings.valid_elements || "@[contenteditable|id|style|data*],-h1,-h2,-h3,-h4,-h5,-strong/b,-em/i,#p," +
            "-div[class],br,table[class],tr[class],td[class],thead[class],tbody[class],th[class],tfoot[class],-span" +
            ",-ol,-ul,-li,dl,dt,hr,del/strike,ins,rp,rt,ruby,-small,sub,sup,-code,-blockquote" +
            ",-a[href|target=_blank],img[class|src|alt|title|width|height|align|data-id]"),

        object_resizing: "img",
        table_default_attributes: {
            'class': 'table'
        },
        table_class_list: [
            { title: 'none', value: '' },
            { title: 'default', value: 'table' },
            { title: 'striped', value: 'table table-striped' },
            { title: 'condensed', value: 'table table-small' },
            { title: 'condensed_striped', value: 'table table-condensed table-striped' },
            { title: 'border', value: 'table table-bordered' }
        ],

        table_row_class_list: [
            { title: 'none', value: '' },
            { title: 'notification.displaytype.success', value: 'table-success' },
            { title: 'active', value: 'table-active' },
            { title: 'notification.displaytype.danger', value: 'table-danger' },
            { title: 'notification.displaytype.warning', value: 'table-warning' }
        ],

        table_cell_class_list: [
            { title: 'none', value: '' },
            { title: 'notification.displaytype.success', value: 'table-success' },
            { title: 'active', value: 'table-active' },
            { title: 'notification.displaytype.danger', value: 'table-danger' },
            { title: 'notification.displaytype.warning', value: 'table-warning' }
        ],

        //table_cell_class_list: [{ title: 'None', value: '' } ],

        table_row_advtab: true,
        table_cell_advtab: false,
        table_advtab: false,

        setup: ed => {

            setEditor(ed);



            ed.on('blur', (e) => {
                handleEditorBlur();
            });

            if (onSetup) {
                onSetup(ed);
            }
            if (ed.settings.ln_custom && ed.settings.ln_custom.useImages === true) {
                ed.on("drop", imageUploader);
                ed.on("paste", onPaste);

                ed.ui.registry.addButton('image', {
                    icon: 'image',
                    tooltip: 'Insert/edit image',
                    onAction: () => imageDialog(ed),
                    stateSelector: 'img:not([data-mce-object],[data-mce-placeholder])'
                });
            }

            ed.ui.registry.addIcon('floatbox', floatboxIcon);

            ed.ui.registry.addButton('floatbox', {
                icon: 'floatbox',
                tooltip: 'Box',
                onAction: () => floatBoxDialog(ed),
            });

        }
    }

    const tableSettings = ["table_class_list", "table_row_class_list", "table_cell_class_list"];

    tableSettings.forEach(key => {
        settings[key].forEach(e => {
            e.title = t.getText(e.title);
        });
    })

    if (editorSettings) {
        settings = { ...settings, ...editorSettings }
    }

    // inline not supported on mobile
    settings.inline = true;

    return (
        <>
            <div ref={editorWrapper} className={props.className + " position-relative"}>
                <div className="outerMceMenuWrapper"><div className="innerMceMenuWrapper" id={menuElementId}></div></div>
                <Editor
                    onEditorChange={handleEditorChange}
                    initialValue={initialValue}
                    id={editorId + name}
                    tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
                    init={settings}
                />
                <textarea defaultValue={initialValue} className="off-screen" onBlur={onBlur} ref={(e) => { textareaRef.current = e; register && register(e); }} name={name}></textarea>
            </div>

            <ImagePickerDialog onSubmit={imagePickerService.onSubmit} />
            <ImageAlignDialog imagePickerService={imagePickerService} />
            <FloatBoxDialog floatCallback={floatCallback} boxState={boxState} />

            <ImageDrop onCloseDialog={onCloseDropDialog} files={dropFiles} imagePreviewOptions={[{ name: "full", maxheight: 1200, maxwidth: 1200 }]}
                onImageReady={handleDroppedFileReady} accept={fileOptions.fileExtensions} languageService={t}
                maxsize={fileOptions.maxsize} />


        </>
    )

}

export default HtmlTextEditor;