//import React from "react";
import { useSelector } from "react-redux";
import languageService from "features/language/languageService";

/**
 * @return {currentLanguage: import('features/language/Language').LanguageDto}, 
            languageService: import('features/language/lan').LanguageService }
 */
export const useI18n = ()=>{
    const currentLanguage = useSelector(state => state.text.currentLang);
    
    return { currentLanguage, languageService };
}