//@ts-check

import React from "react";

import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";

import classes from './dashboard.module.scss';

/**
 * AboutCourseWidget
 * @module features/classes/Dashboard/AboutCourseWidget
 * @return an LnCard with information about the course.
 * @param {{
    Data: import('../Classes').StudentClassDtoForStudent
}} props
 */
const AboutCourseWidget = ({ Data }) => {

    const { languageService: t } = useI18n();
    const studentClass = Data.StudentClass;

    if( !studentClass.CourseDescription || studentClass.CourseDescription.trim() === ""){
        return null;
    }

    return (
        <div className={['card-box big-card-box card ln-card mr-5 mb-5', classes['widget-card']].join(' ')}>
            <div className={["card-header", classes['card-header']].join(' ')}>
                <LnIcon className="header-icon" name="info-icon" />
                {t.getText('studentsmenu.about')}
            </div>
            <div className="card-body overflow-auto pt-5">
                <div className="card-title">
                    <h4>{studentClass.CourseName}</h4>
                </div>
                <div className="card-text" dangerouslySetInnerHTML={{ __html: studentClass.CourseDescription }}></div>
            </div>
        </div>
    )
}

export default AboutCourseWidget;