//@ts-check
import React from "react";
import { Card, CardBody,  UncontrolledTooltip } from 'reactstrap';
import LnIcon from "components/LnIcon";
import { endPoint } from "AppConstants";
import { LnCollapseGroup } from "components/LnCard/LnCollapseGroup";
import { courseDuck } from "features/course/CourseDuck";
import classes from "./links.module.scss";
import { useDispatch } from "react-redux";

/**
 * 
 * @param {{languageService: import("features/language/Language").LanguageService}} props 
 * @returns 
 */
const Links = ({ languageService }) => {

    const dispatch = useDispatch();

    const part = courseDuck.useCurrentPart();
    if( !part.LinkGroups ){
        return null;
    }

    /**
     * 
     * @param {import("features/course/Course").LinkInfo} linkInfo 
     */
    const linkClicked = (linkInfo)=>{
        dispatch(courseDuck.setLinkViewed({ linkId: linkInfo.Id }));
    }
    
    return (
        <div role="list">

            {part.LinkGroups.map(g =>

                (<div role="listitem" key={g.Id} className="mb-4" >
                    <LnCollapseGroup group={g} isOpen={true}>
                        <div role="list" className={classes.cardWrapper}>
                            {
                                g.Links.map(l =>
                                    (
                                        <Card id={"c_" + l.Id} key={l.Id} className={"m-3 " + classes.listCard}>
                                            <CardBody >

                                                <div  className={classes.linkInfo + " pointer"} dangerouslySetInnerHTML={{ __html: l.Description }}></div>

                                                <a target="_blank" onClick={()=>linkClicked(l)} rel="noopener noreferrer" className="" href={endPoint.API_HOST + l.Url}>
                                                    {/* <LnIcon className="" name="link-icon" /> */}
                                                    {l.Name.split('://').pop()}
                                                </a>
                                                {(l.Viewed) && <LnIcon className="done-icon natural" name="checked" />}
                                            </CardBody>
                                            <UncontrolledTooltip placement="right" target={'c_' + l.Id} trigger={'hover'}>
                                                <div dangerouslySetInnerHTML={{ __html: l.Description }}></div>
                                            </UncontrolledTooltip>
                                        </Card>

                                    )
                                )}
                        </div>
                    </LnCollapseGroup>
                </div>)
            )}
        </div >
    )
}

export default Links;
