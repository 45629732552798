//@ts-check
import React from "react";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import Link from "redux-first-router-link";
import classes from './dashboard.module.scss';
import { endPoint } from "AppConstants";


/**
 * ClassesWidget
 * @return an LnCard with user's classes
 * @param {{Data: import("types/types").IdAndNameDto[], unitId:string }} props
 */
const ClassesWidget = ({ Data , unitId}) => {

    const { languageService: t } = useI18n();


    if (!Data || Data.length === 0) {
        return null;
    }

    const classesInOrder = Data.map(d => d).sort((c1, c2) => {
        if (c1.Name === c2.Name) {
            return 0;
        }
        return c1.Name > c2.Name ? 1 : -1;
    })

    return <div className='ml-auto card mr-auto mt-5 mb-5 max-col-width'>
        <div className="card-header">
            <LnIcon className="header-icon" name="slides-stack" />
            {t.getText('courses')}
        </div>
        <div className="card-body"  >

            <div className={"m-5 " + classes.schoolClass} style={{ backgroundImage: `url(${endPoint.GET_UNIT_IMAGE_URL(unitId)})` }} >

                {classesInOrder.map(a => {
                    return (<div key={a.Id} className="mb-3">

                            <Link  className="px-4 pt-1 pb-2" to={{ type: "CLASS", payload: { classid: a.Id } }}>{a.Name}</Link>

                    </div>
                    )
                })}
            </div>
        </div>
    </div>
}

export default ClassesWidget;