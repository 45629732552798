//@ts-check
import React, { useCallback, useEffect, useState } from "react";
import LnIcon from "components/LnIcon";

import classes from '../classmail.module.scss';
import { useI18n } from "components/lni18n";
import { classMailDuck } from "../ClassMailDuck";
import { useDispatch } from "react-redux";

/**
 * @param {{
    mailData: import("../ClassMail").BulkMailProjectDetailDto,
    iconTypes: any,
}} props
 */
const RecieversResendList = (props) => {

    const { mailData, iconTypes } = props;
    const { languageService: t } = useI18n();

    const dispatch = useDispatch();

    const [showExplain, setShowExplain] = useState(false);
    const [cansubmit, setCanSubmit] = useState(false);
    const [userData, setUserData] = useState({ students: [], teachers: [] });

    useEffect(() => {
        const statusLookUp = {};
        mailData.Statuses.forEach(s => statusLookUp[s.Receiver.Id] = { ...s, Id: s.Receiver.Id, checked: false });

        setUserData(data => {

            let d ;
            if (data.students.length === 0 && data.teachers.length === 0) {

                d = {
                    students: mailData.Students.map(s => {
                        return statusLookUp[s.Receiver.Id] || { ...s, Id: s.Receiver.Id, checked: false, Address: s.Receiver.Mail };
                    }),

                    teachers: mailData.Teachers.map(s => {
                        return statusLookUp[s.Receiver.Id] || { ...s, Id: s.Receiver.Id, checked: false, Address: s.Receiver.Mail };
                    })
                };

            }
            else {
                d = {
                    students: data.students.map(s => {
                        if (statusLookUp[s.Receiver.Id]) {
                            return { ...s, Status: statusLookUp[s.Receiver.Id].Status }
                        } else {
                            return { ...s };
                        }
                    }),
                    teachers: data.teachers.map(s => {
                        if (statusLookUp[s.Receiver.Id]) {
                            return { ...s, Status: statusLookUp[s.Receiver.Id].Status }
                        } else {
                            return { ...s };
                        }
                    })
                }
            }

            return d;
        });

    }, [mailData.Students, mailData.Teachers, mailData.Statuses]);


    const setStudentChecked = (id, state) => {
        let ok = false;
        setUserData(d => {
            const newdata = { students: [...d.students], teachers: [...d.teachers] };
            const usr = newdata.students.find(st => st.Id === id)
            if (usr) usr.checked = state;

            ok = newdata.students.find(s => s.checked) || newdata.teachers.find(s => s.checked);
            return newdata;
        });

        window.setTimeout(() => setCanSubmit(ok), 100);
    }
    const setTeacherChecked = (id, state) => {
        let ok = false;
        setUserData(d => {
            const newdata = { students: [...d.students], teachers: [...d.teachers] };
            const usr = newdata.teachers.find(st => st.Id === id)
            if (usr) usr.checked = state;

            ok = newdata.students.find(s => s.checked) || newdata.teachers.find(s => s.checked);
            return newdata;
        });

        window.setTimeout(() => setCanSubmit(ok), 100);
    }

    const resend = useCallback(() => {
        
        const ids = userData.teachers.filter(t => t.checked).map(t => t.Id).concat( userData.students.filter(t => t.checked).map(t => t.Id) );
        dispatch(classMailDuck.reSendMail( ids, mailData.Id ));
        setUserData(d => {
            return { students: d.students.map(s => ({...s, checked: false})), teachers: d.teachers.map(s => ({...s, checked: false})) };
        });
        setCanSubmit(false);


    }, [userData, mailData.Id, dispatch]);



    return (

        <div>
            <button type="button" className="btn btn-link p-4" onClick={() => setShowExplain(s => !s)}>
                <LnIcon className="icon-small" name="questionmark-icon" />
            </button>
            {showExplain &&
                <div className="alert alert-warning preserve-white"> <small>{t.getText('resend.email.explain')}</small></div>
            }


            <h4 className="mt-3">{t.getText("students")}</h4>
            <ul className="list-group list-group-flush">
                {userData.students.map((s, index) => {

                    const status = s.Status === undefined ? "notInSendList" : s.Status;

                    return <li key={index + '_' + s.Id} className={"list-group-item d-flex " + (s.Status === 'opened' ? " bg-primary-lightest" : "")}>

                        <div className="custom-control custom-checkbox">
                            <input id={`radio_${s.Id}`} className="custom-control-input" type="checkbox" value="true" onChange={(e) => setStudentChecked(s.Id, e.currentTarget.checked)} checked={s.checked} />
                            <label className="custom-control-label pl-3 pointer" htmlFor={`radio_${s.Id}`}>
                                {iconTypes[status] || <LnIcon className={["mr-4", classes.spinnerIcon].join(' ')} name="spinner" />}
                                {s.Receiver.FullName}
                            </label>
                        </div>
                        <small className="ml-auto">
                            {s.Address}
                        </small>
                    </li>
                })

                }
            </ul>

            <h4 className="mt-4">{t.getText("teachers")}</h4>

            <ul className="list-group list-group-flush">
                {userData.teachers.map((s, index) => {

                    const status = s.Status === undefined ? "notInSendList" : s.Status;

                    return <li key={index + '_' + s.Id} className={"list-group-item d-flex " + (s.Status === 'opened' ? " bg-primary-lightest" : "")}>

                        <div className="custom-control custom-checkbox">
                            <input id={`radio_${s.Id}`} className="custom-control-input" type="checkbox" value="true" onChange={(e) => setTeacherChecked(s.Id, e.currentTarget.checked)} checked={s.checked} />
                            <label className="custom-control-label pl-3 pointer" htmlFor={`radio_${s.Id}`}>
                                {iconTypes[status] || <LnIcon className={["mr-4", classes.spinnerIcon].join(' ')} name="spinner" />}
                                {s.Receiver.FullName}
                            </label>
                        </div>
                        <small className="ml-auto">
                            {s.Address}
                        </small>
                    </li>
                })

                }
            </ul>

            <button disabled={!cansubmit} type="button" className="btn btn-primary btn-small mt-5" onClick={() => resend()}>{t.getText('send')} </button>

        </div>


    )
}
export default RecieversResendList;
