//@ts-check
import React from "react";
import { useDispatch, useStore } from "react-redux";
import { Alert } from "components/OnlineModal";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import { endPoint } from "AppConstants";
import { singleMailDuck } from "features/General/SingleMail/SingleMailDuck";
import classes from './members.module.scss';
import UserImg from "components/UserImg";
import {classesDuck} from "features/classes/ClassesDuck";

/**
 * @param {{item: import("features/User/User").UserInfoCellDto, currentUserIsTeacher:boolean, isStudent: boolean, showNotes: Function, classId: string }} props 
 */
const MemberCard = ({ item: userdata, currentUserIsTeacher, isStudent, showNotes, classId }) => {

    const { languageService: t } = useI18n();
    const dispatch = useDispatch();
    const store = useStore();


    const phoneClicked = () => {
        if (userdata.Tele || userdata.Tele2) {
            let tnums = []
            if (userdata.Tele) {
                tnums.push('<div><a href="tel:' + userdata.Tele + '">' + userdata.Tele + '</a><div>')
            }
            if (userdata.Tele2) {
                tnums.push('<div class="mt-4"><a href="tel:' + userdata.Tele2 + '">' + userdata.Tele2 + '</a></div>')
            }
            Alert({
                backdrop: "static",
                title: t.getText("tele"),
                message: tnums.join(' '),
                button: {
                    className: "btn btn-primary",
                    text: t.getText("ok"),
                    type: "submit"
                }
            });
        }
    }

    const mailClicked = async () => {
        if (userdata.Mail) {
            const mailid = await singleMailDuck.newMail(dispatch, store.getState, userdata.Id)
            dispatch({ type: "GENERAL_SINGLE_MAIL_EDIT", payload: { mailid: mailid, listtype: 'draft' } });

        }
    }

    const showInfoClicked = () => {
        dispatch({ type: "GENERAL_PERSONAL_SETTINGS", payload: { userid: userdata.Id, type: "personal" } });
    }

    const editNotes = () => {
        showNotes(userdata.Id);
    }

    const toResults = async ()=>{
        await dispatch(classesDuck.setStudentForSingleTestResult( userdata.Id ));
        dispatch({type: "CLASS_FULL_PROGRESS", payload:{ classid: classId, type: 'results'  }});
    }

    const getNotesColor = (state) => {
        let css;
        switch (state) {
            case "NoNotes":
                css = "filter-gray";
                break;
            case "HasReadNotes":
                css = "filter-green";
                break;
            case "HasUnreadNotes":
                css = "filter-red";
                break;
            default:
                css = "";
                break;
        }

        return css;
    }


    return (
        <div className="card ln-card card-vertical-fixed m-4 " >
            { currentUserIsTeacher && userdata.IsArchived && <div className={classes.cloud}>
                <LnIcon name="cloud" className="filter-white" />
            </div> }
            <div className={ (userdata.IsArchived ? classes.dimmed : "" ) + " card-img-top pointer"} onClick={(e) => showInfoClicked()}>
                {userdata.ImageUrl ? <UserImg src={endPoint.API_HOST + userdata.ImageUrl} alt={userdata.FullName} /> : <LnIcon name="user-icon" />}
                {userdata.IsMainTeacher ? <div className={classes['main-teacher']}></div> : null}
            </div>
            <div className="card-body p-0" >
                <div className="btn-group card-btn-group d-print-none" role="group">
                    <button type="button" onClick={(e) => phoneClicked()} className={"btn card-btn-group-btn"} disabled={!userdata.Tele && !userdata.Tele2}>
                        <LnIcon className="icon-medium" name="phone-icon" />
                    </button>
                    <button type="button" onClick={(e) => mailClicked()} className={"btn card-btn-group-btn"}>
                        <LnIcon className="icon-medium" name="email-icon" />
                    </button>
                    {currentUserIsTeacher && isStudent && userdata.NoteState !== "DoNotShow" && <button type="button" onClick={(e) => editNotes()} className={"btn card-btn-group-btn"}>
                        <LnIcon className={"icon-medium " + getNotesColor(userdata.NoteState)} name="edit" />
                    </button>
                    }

                    {currentUserIsTeacher && isStudent && <button type="button" onClick={(e) => toResults()} className={"btn card-btn-group-btn"}>
                        <LnIcon className="icon-medium" name="table" />
                    </button>
                    }

                </div>
                <h4 onClick={(e) => showInfoClicked()} className={[classes['online-status'], 'card-title mt-4 pointer', (userdata.IsOnline ? classes['online'] : classes['offline'])].join(' ')}>{userdata.FullName}</h4>
                <div className="d-none d-print-block mt-3">
                    {userdata.Tele ? <span className="mb-4">{userdata.Tele}<br /></span> : null}
                    {userdata.Tele2 ? <span className="mb-4">{userdata.Tele2}<br /></span> : null}
                    {userdata.Mail ? <span className="mb-4">{userdata.Mail}</span> : null}
                </div>
            </div>
        </div>

    )
}

export default MemberCard;