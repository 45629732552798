//@ts-check
import { createSlice } from "@reduxjs/toolkit";

/**
 * @type import('./ErrorHandler').ErrorState
 */
const initialState = {
    messages: []
};

const errorHandlerSlice = createSlice({
    name: "errors",
    initialState: initialState,
    reducers: {

        /**
         * @param {{payload: import('./ErrorHandler').ErrorMessage}} action 
         */
        setError(state, action) {

            if (action.payload.errorId) {
                const mess =  state.messages.find(m => m.errorId === action.payload.errorId);
                if( mess){  // same message already on the stack, ignore to avoid too many dialogs popping up
                    return;
                }
            }

            state.messages.push(action.payload);
        },

        /**
         * @param {{payload: import('./ErrorHandler').ErrorMessage}} action 
         */
        resetError(state, action) {
            state.messages = state.messages.filter(m => m.when !== action.payload.when)
        },
    }
});

export const errorDuck = { ...errorHandlerSlice.actions };
export default errorHandlerSlice.reducer;
