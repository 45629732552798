//@ts-check
import React from "react";
import { useI18n } from "components/lni18n";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Draggable from "react-draggable"
import useTypedSelector from "utils/useTypedSelector";
import ImagePicker from "./ImagePicker";
import classes from "./ImagePicker.module.scss";

class ImagePickerService {

  onSubmit = null;
  submitBrooker = (res) => {
    if( this.onSubmit) {
      this.onSubmit(res);
    }
  }

}

/**
 * 
 * @param {{onSubmit:Function}} props
 */
const ImagePickerDialog = ({ onSubmit }) => {

  const { languageService: t } = useI18n();
  const { modalOpen } = useTypedSelector(state => state.imagepicker);


  return <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>

    <Modal size="xl" role="dialog" isOpen={modalOpen} toggle={() => imagePickerService.submitBrooker()} className={classes.imgDialog + " draggable-dialog"} backdrop={'static'}>
      <ModalHeader className={""} toggle={() => imagePickerService.submitBrooker()}>{t.getText("image")}</ModalHeader>
      <ModalBody className={""} tabIndex={-1}>
        <ImagePicker onSubmit={onSubmit} />
      </ModalBody>
      <ModalFooter>

        <Button className="btn btn-inverse" onClick={() => imagePickerService.submitBrooker()}>{t.getText("close")}</Button>
      </ModalFooter>
    </Modal>
  </Draggable>

}

export const imagePickerService = new ImagePickerService();
export default ImagePickerDialog;
