// @ts-check
import React from "react";
import classes from './layout.module.scss';
import LnIcon from "components/LnIcon";
import { useDispatch } from "react-redux";
import { searchDuck } from "../search/SearchDuck";
import useTypedSelector from "utils/useTypedSelector";

const SearchMenu = (props) => {

    const dispatch = useDispatch();
    const { showSearch } = useTypedSelector(state => state.search)

    const toggleOpen = () => {
        dispatch(searchDuck.toggleShow(!showSearch));
    }


    return <div key="search" className={classes["search"]}>
        <div>

            <div className={classes.dropDownItem + " btn btn-minimal pointer"} onClick={() => toggleOpen()}>
                <LnIcon name="search-icon" />
            </div>


        </div>
    </div>
}

export default SearchMenu;