//@ts-check
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import { ErrorMessage } from '@hookform/error-message';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {Alert} from "components/OnlineModal";
import Draggable from "react-draggable"
import * as Yup from "yup";

import { useI18n } from "components/lni18n";

import classes from './user.module.scss';
import { userDuck } from "./UserDuck";
import { errorDuck } from "components/ErrorHandler/ErrorHandlerDuck";
import { useMemo } from "react";

/**
 * ChangePasswordModal
 * @module features/User/ChangePasswordModal.js
 * @return a modal with form to change password.
 * @param {{
    userData: import('./User').UserDetailDto , 
    currentUser: import('./User').CurrentUser,
    getOpenFunction:(Function)=>void
}} props
 */
const ChangePasswordModal = ({ userData, currentUser, getOpenFunction }) => {

    const { languageService: t } = useI18n();
    const dispatch = useDispatch();

    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handlePasswordSave = async (values) => {
        setSubmitting(true);
        setErrorMessage(null);
        const postData = {
            Password: values.oldPassword,
            NewPassword: values.newPassword,
        };
        try {
            await userDuck.saveUserPassword(userData.Id, postData, dispatch);
            setSubmitting(false);
            setModal(false);
            Alert({
                backdrop: true,
                title: t.getText('password.replace'),
                message: t.getText('changed'),
                button: {
                    className: 'btn btn-primary',
                    text: t.getText("ok"),
                    type: "submit"
                }
            });
        } catch (error) {
            console.error(error);
            setSubmitting(false);
            if (error.synteticError) {
                setErrorMessage(error.synteticError.message);

                // surpress error dialog
                dispatch(errorDuck.resetError(error.synteticError));
            }
            else {
                setErrorMessage(JSON.stringify(error));
            }
        }

    }

    const toggleModal = () => setModal(!modal);

    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (getOpenFunction) {
            getOpenFunction(() => {
                setModal(true);
            });
        }
    }, [getOpenFunction])



    const formSchema = useMemo(() => {

        if (currentUser.Id === userData.Id) {
            return Yup.object({
                oldPassword: Yup.string()
                    .required(t.getText('required_field')),
                newPassword: Yup.string()
                    .required(t.getText('required_field'))
                    .min(5, t.getText('toshortpasswd')),
                repeatPassword: Yup.string()
                    .required(t.getText('required_field'))
                    .min(5, t.getText('toshortpasswd'))
                    .test('passwords-match', t.getText('passwd_same'), function (value) {
                        return this.parent.newPassword === value;
                    }),
            });
        }
        else{
            return Yup.object({
                newPassword: Yup.string()
                    .required(t.getText('required_field'))
                    .min(5, t.getText('toshortpasswd')),
                repeatPassword: Yup.string()
                    .required(t.getText('required_field'))
                    .min(5, t.getText('toshortpasswd'))
                    .test('passwords-match', t.getText('passwd_same'), function (value) {
                        return this.parent.newPassword === value;
                    }),
            });
        }

    }
        , [t, currentUser, userData]);



    const { register, handleSubmit, errors, formState, reset } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            oldPassword: '',
            newPassword: '',
            repeatPassword: ''
        },
        mode: "onBlur"
    });

    useEffect(() => {
        setErrorMessage(null);
        reset();
    }, [modal, reset])

    const { isDirty, isValid } = formState;

    return (
        <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>

            <Modal role="dialog" isOpen={modal} toggle={toggleModal} className="change-password-modal" backdrop='static'>

                <form onSubmit={handleSubmit(handlePasswordSave)}>
                    <ModalHeader toggle={toggleModal}>{t.getText('password.replace')}</ModalHeader>
                    <ModalBody className={[classes['change-password-body']].join(' ')} tabIndex={-1}>

                        {errorMessage && <div className="alert alert-danger m-3">{errorMessage}</div>}

                        <div className="">
                            {currentUser.Id === userData.Id &&
                                <div className="form-group">
                                    <label>{t.getText('passwd')}</label>
                                    <input ref={register} type="password" name="oldPassword" className="form-control" />
                                    <ErrorMessage as="div" errors={errors} className="errorMessage" name="oldPassword" />
                                </div>
                            }
                            <div className="form-group">
                                <label>{t.getText('new_passwd')}</label>
                                <input ref={register} type="password" name="newPassword" className="form-control" />
                                <ErrorMessage as="div" errors={errors} className="errorMessage" name="newPassword" />
                            </div>
                            <div className="form-group">
                                <label>{t.getText('passwd2')}</label>
                                <input ref={register} type="password" name="repeatPassword" className="form-control" />
                                <ErrorMessage as="div" errors={errors} className="errorMessage" name="repeatPassword" />


                            </div>

                        </div>


                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-primary btn-small mr-4"
                            disabled={submitting || !isValid || !isDirty}
                            type="submit" >{t.getText('submit')}</button>
                        <button className="btn btn-inverse btn-small" type="button" onClick={() => toggleModal()}> {t.getText('cancel')} </button>
                    </ModalFooter>
                </form>
            </Modal>
        </Draggable>
    )
}
export default ChangePasswordModal;
