//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import { useI18n } from "components/lni18n";
import useTypedSelector from "utils/useTypedSelector";
import { fileUploadDuck } from "components/FileUpload/FileUploadDuck"
import { imagePickerDuck } from "./ImagePickerDuck";

/**
 *
 * @param {{
 *  onSaveImage?: Function
 *  }} props
 */
const ImageScaler = (props) => {

  const dispatch = useDispatch();
  const { languageService: t } = useI18n();
  const { imagePreviewWidth } = useTypedSelector(state => state.imagepicker);
  const { imagePreviews } = useTypedSelector(state => state.fileUpload);


  if( !imagePreviews || !imagePreviews.full ){
    return null;
  }

  const clearImage = () => {
    dispatch(fileUploadDuck.setImagePreviews(null));
  }

  const widthChange = (e) => {
    var w = parseInt(e.target.value, 10);
    dispatch(imagePickerDuck.setImagePreviewWidth(w));
  }


  return <div>
    <div className="col-12 col-md-8 mx-auto">
      <button type="button" onClick={()=>props.onSaveImage()} className="btn btn-small btn-primary mb-3 mt-3 mr-4" >{t.getText("save.short")}</button>
      <button type="button" onClick={clearImage} className="btn btn-small btn-inverse mb-3 mt-3" >{t.getText("clear")}</button>

      <div className="my-4">
        <strong>{t.getText("width")}</strong> {imagePreviewWidth}px &nbsp;&nbsp;&nbsp; <strong>{t.getText("height")}</strong> {Math.round(imagePreviewWidth * imagePreviews.full.height / imagePreviews.full.width)}px
      </div>

      <div className="form-group range ">
        <label htmlFor="imgwidthRange">{t.getText("size")}</label>
        <input value={imagePreviewWidth} onChange={widthChange} type="range" min={25} max={imagePreviews.full.width} className="custom-range" id="imgwidthRange" />
      </div>
    </div>

    <img style={{ width: `min( 100%, ${imagePreviewWidth}px)` }} className="d-block m-auto" src={imagePreviews.full.src} alt={"preview"} />



  </div>

}

export default ImageScaler;
