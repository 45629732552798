//@ts-check
import React from "react";
import LnResultIcon from "components/LnResultIcon";
import classes from './tests.module.scss';
import LnIcon from "components/LnIcon";
import Link from "redux-first-router-link";
import { useI18n } from "components/lni18n";

/**
 * 
 * @param {{classId:string, themeId: string, partId: string, test: import("features/course/Course").TestInfo}}} props
 * @returns 
 */

function StudyTest ({ classId, themeId, partId, test }){

    const { languageService: t } = useI18n();

    return  <div className={[classes['test-list-item'], 'mb-3'].join(' ')}>
  <div className={classes['icon-text'] + " d-flex"}>
    <div className="flex-fill">
      <LnResultIcon className="mr-3" status={test.Status} />
      {test.Name}
      <LnIcon name="locked" className="ml-3 icon-small" />
      </div>
      

          <Link className="ml-3 " to={{
        type: 'CLASS_THEME_PART_LECTURE_PLAY',
        payload: {
            classid: classId,
            themeid: themeId,
            partid: partId,
            lectureid: test.ConnectedLecture.Id,
            slide: 1
                }
    }} >{t.getText("lecture")} - {test.ConnectedLecture.Name}</Link>


          
  </div>

</div>

}

export default StudyTest