//@ts-check
import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import Link from "redux-first-router-link";
import ScrollContent from 'components/ScrollContent';
import { endPoint } from "AppConstants";
import LnResultIcon from "components/LnResultIcon";
import { useI18n } from "components/lni18n";
import classes from './partlist.module.scss';
import { courseDuck } from "features/course/CourseDuck";
import { UncontrolledTooltip } from "reactstrap";

const PartList = () => {

    const { languageService: t } = useI18n();
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const currentTheme = courseDuck.useCurrentTheme();

    if (currentTheme == null)
        return <div></div>

    if (!currentTheme.CourseParts || currentTheme.CourseParts.length === 0) {
        return <ScrollContent scrollname="course-partlist">
            <div className="card-box big-card-box pt-5">
                <div className="ml-3" >
                    <h3 className="mb-3">
                        {currentTheme.Name}
                    
                    </h3>

                    <div>{t.getText("theme.empty")}</div>
                    <div>&nbsp;</div>
                </div>
            </div>
        </ScrollContent>
    }

    return (
        <ScrollContent scrollname="course-partlist">
            <div className="card-box big-card-box pt-5">
                <div role="list" className="d-flex flex-wrap ">

                    {currentTheme.CourseParts.map(part => (
                        <div id={"l_" + part.Id} key={part.Id} className={["m-3 ", classes['part-listing']].join(' ')}>


                            <Link to={{
                                type: 'CLASS_THEME_PART',
                                payload: { classid: currentClass.Id, themeid: currentTheme.Id, partid: part.Id, type: "intro" }
                            }}>
                                <div className={['card-box card', classes['part-listing-card']].join(' ')} >
                                    <div className={"d-flex align-items-center justify-content-center " + classes['card-image']}>
                                        {part.ImageUrl ?
                                            <img src={endPoint.API_HOST + part.ImageUrl} alt={part.Name} />
                                            : <div className={classes.imageDummy + " w-100 h-100"}>&nbsp;</div>
                                        }

                                    </div>
                                    <div className="card-box-content card-body">

                                        <h4 className={['card-title mt-4'].join(' ')}>{part.Name}</h4>
                                        <p className={[classes['line-clamp'], 'card-text'].join(' ')} dangerouslySetInnerHTML={{ __html: part.TextExtract }}></p>
                                    </div>
                                </div>
                            </Link>

                            {part.Status !== 'notset' &&
                                <LnResultIcon status={part.Status} className="done-icon-right-top" />
                            }

                            <UncontrolledTooltip placement="auto-start" autohide={false} target={'l_' + part.Id} trigger={'hover'}
                                delay={{ show: 300, hide: 0 }}>
                                <strong>{part.Name}</strong>
                                <div >{part.TextExtract}</div>
                            </UncontrolledTooltip>

                        </div>
                    ))}
                </div>
            </div>
        </ScrollContent>

    );
}


export default PartList;
