//@ts-check

import React from "react";

import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";

import classes from './dashboard.module.scss';

/**
 * SponsorsWidget
 * @module features/classes/Dashboard/AboutCourseWidget
 * @return an LnCard with information about the course.
 * @param {{
    Data: import('../Classes').StudentClassDtoForStudent
}} props
 */
const SponsorsWidget = ({ Data }) => {

    const { languageService: t } = useI18n();

    return <div className={['card-box big-card-box card ln-card mr-5 mb-5', classes['widget-card']].join(' ')}>
        <div className={["card-header", classes['card-header']].join(' ')}>
            <LnIcon className="header-icon" name="hand-shake" />
            {t.getText('sponsors')}
        </div>
        <div className="card-body overflow-auto pt-5">
            {Data.Addons && Data.Addons.length > 0 &&
                <div>
                    {Data.Addons.map(a => {
                        return (<div key={a.Id} className="mb-3">
                            <div className="card-title">
                                <h4>{a.Header}</h4>

                            </div>
                            <div className="card-text" dangerouslySetInnerHTML={{ __html: a.Description }}></div>
                            <div>
                                {a.Link && <div>
                                    <a href={a.Link} target="_blank"  rel="noopener noreferrer" >{a.Link}</a>
                                </div>
                                }
                            </div>
                        </div>
                        )
                    })}
                </div>
            }
        </div>
    </div>

}

export default SponsorsWidget;