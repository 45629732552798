//@ts-check
import React from "react";
import ScrollContent from "components/ScrollContent";
import useTypedSelector from 'utils/useTypedSelector';
import GroupMembers from "./GroupMembers"
import FeedView from "../Feed/FeedView";
import ForumForGroup from "./ForumForGroup";
import GroupMail from "./GroupMail";
import { useI18n } from "components/lni18n";
import { NavLink } from "redux-first-router-link";


const SubGroup = () => {

    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const location = useTypedSelector(state => state.location);
    const group = currentClass.SubGroups.find(gr => gr.Id === location.payload.groupid);
    const type = location.payload.type;

    const { languageService: t } = useI18n();


    if (!group) {
        return <ScrollContent scrollname="class-subgroup">
            <div className="card-box big-card-box">
                <div className="card-body pt-5 mb-5">{t.getText("no.permission")}</div>
                <div>&nbsp;</div>
            </div>
        </ScrollContent>
    }

    const menuItems = makeMenuItems({ languageService: t, group: group, type: type, classId: currentClass.Id });

    const routeViews = {
        "info": <GroupMembers />,
        "feed": <FeedView subGroupId={location.payload.groupid} />,
        "forum": <ForumForGroup />,
        "mail": <GroupMail />
    }

    return (
        <ScrollContent scrollname="class-subgroup">
            <div className="card-box big-card-box">
                <div className="card-header ln-nostyle d-flex justify-content-between">
                    <h3>
                        {t.getText('subgroup') + " " + group.Name}
                    </h3>
                </div>
                <div className="card-body pt-5 mb-5 d-print-none custom-tab-heading-list">
                    {menuItems}

                </div>

                <main >
                    {routeViews[type]}
                </main>

            </div>
        </ScrollContent>
    )
}


const makeMenuItems = (params) => {

    const menuArr = [];
    const t = params.languageService;


    menuArr.push(<NavLink to={{
        type: 'CLASS_SUBGROUP',
        payload: { classid: params?.classId, groupid: params.group?.Id, type: "info" }
    }}
        key="info"
        isActive={(match, location) => params.type === 'info'}
        activeClassName={'active'}
    >
        {t.getText("members")}
    </NavLink>);

    if (params.group?.ShowFeed) {

        menuArr.push(<NavLink to={{
            type: 'CLASS_SUBGROUP',
            payload: { classid: params.classId, groupid: params.group.Id, type: "feed" }
        }}
            key="feed"
            isActive={(match, location) => params.type === 'feed'}
            activeClassName={'active'}
        >
            {t.getText("feed.menuname")}
        </NavLink>);
    }

    if (params.group?.ShowForum) {

        menuArr.push(<NavLink to={{
            type: 'CLASS_SUBGROUP',
            payload: { classid: params.classId, groupid: params.group.Id, type: "forum" }
        }}
            key="forum"
            isActive={(match, location) => params.type === 'forum'}
            activeClassName={'active'}
        >
            {t.getText("forum")}
        </NavLink>);
    }

    menuArr.push(<NavLink to={{
        type: 'CLASS_SUBGROUP_MAIL',
        payload: { classid: params.classId, groupid: params.group.Id, type: "mail", listtype: "draft" }
    }}
        key="mail"
        isActive={(match, location) => params.type === 'mail'}
        activeClassName={'active'}
    >
        {t.getText("email")}
    </NavLink>);


    return menuArr;
}
export default SubGroup;
