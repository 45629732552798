//@ts-check

import Dexie from "dexie";


/**
 * 
 * @returns {import("types/DB").LnDataBase}
 */
export default function configDB() {

    /**
     * @type{import("types/DB").LnDataBase}
     */
    const db = new Dexie('LaraNaraDB');

    db.version(1).stores({
        forumDraft: '++id, userId, forumId, date',
        feedDraft: '++id, userId, feedId, date'
    });


    /*
    swallow all exceptions here. Seen in iOS:  "MissingAPIError: IndexedDB API missing"
    Hopefully :| Index DB is working at a later stage
     */


    try {

        // delete data more than 2 days old
        const twoDaysAgoMillsec = (new Date()).getTime() - 2 * 24 * 3600 * 1000;
        const lower = new Date(2000, 0, 1);
        const upper = new Date(twoDaysAgoMillsec);
        console.log(upper.toUTCString())
        db.forumDraft.where("date").between(lower, upper)
            .toArray()
            .then(data => {
                const ids = data.map(d => d.id);
                db.forumDraft.bulkDelete(ids);
            })
            .catch((err) => {

            });

        db.feedDraft.where("date").between(lower, upper)
            .toArray()
            .then(data => {
                const ids = data.map(d => d.id);
                db.feedDraft.bulkDelete(ids);
            })
            .catch((err) => {

            });

    }
    catch (error) {

    }




    return db;
}
