//@ts-check
import WaitRipple from "components/WaitRipple";
import React, { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";

let GeneralNotes = null;

let generalNotesDuck = null;

/**
 * 
 * @params {{userid: string}} props
 */
const GeneralNotesProxy = ({userid}) => {
    const [ready, setReady ] = useState(false);
    
    const store = useStore();
    const dispatch = useDispatch();

    import("teacherFeatures").then(features => {
        GeneralNotes = features.GeneralNotes;
        if(  !store.asyncReducers["generalNotes"] ){
            store.injectReducer( "generalNotes", features.GeneralNotesReducer  );
        }

        generalNotesDuck = features.generalNotesDuck;

        setReady(true);
    });

    useEffect(()=>{
        if( ready && userid){
            dispatch( generalNotesDuck.getNotes(userid));
        }
    }, [ready, userid, dispatch ]);

    if (!ready) {
        return <WaitRipple />;
    }
    else {
        return <GeneralNotes />
    }
}

export default GeneralNotesProxy;
