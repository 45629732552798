import React from "react";
import Loader from 'components/loader';
import { useI18n } from "components/lni18n";
import { courseDuck } from "features/course/CourseDuck";

const PartInfo = (props) => {

        const currentPart = courseDuck.useCurrentPart();
        const { languageService: t } = useI18n();
        return currentPart ?
                <div className="max-col-width">
                        <article className="mb-4">
                                <h2>{t.getText("introtext")}</h2>
                                <div className="clearfix" dangerouslySetInnerHTML={{ __html: currentPart.Intro }}></div>
                        </article>
                        {currentPart.Goals &&
                                <article className="mb-4">
                                        <h2>{t.getText("goal_contents")}</h2>
                                        <div className="clearfix" dangerouslySetInnerHTML={{ __html: currentPart.Goals }}>
                                        </div>
                                </article>}
                        {currentPart.Literature &&
                                <article>
                                        <h2>{t.getText("litterature")}</h2>
                                        <div className="clearfix" dangerouslySetInnerHTML={{ __html: currentPart.Literature }}>
                                        </div>
                                </article>}

                </div>
                : <Loader />
}
export default PartInfo;