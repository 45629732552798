//@ts-check
import React, { useEffect, useCallback } from "react";
import { useDispatch, useStore } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import LnIcon from "components/LnIcon";
import Link from "redux-first-router-link";
import { pathToAction } from "redux-first-router";
import { searchDuck } from "./SearchDuck";
import { useI18n } from "components/lni18n";

import classes from "./searchform.module.scss"

const SearchForm = () => {

    const dispatch = useDispatch();
    const store = useStore();
    const { languageService: t } = useI18n();

    const { searchText, result, showSearch, page } = useTypedSelector(state => state.search)
    const { routesMap, pathname } = useTypedSelector(state => state.location)


    const closeSearch = useCallback(() => {
        dispatch(searchDuck.toggleShow(false));
    }, [dispatch]);

    useEffect(() => {
        closeSearch();
    }, [pathname, closeSearch])

    const onChangeText = (e) => {
        dispatch(searchDuck.setSearchText(e.target.value));
    }


    /**
     * 
     * @param {import("react").SyntheticEvent} e 
     */
    const submitForm = (e) => {
        e.stopPropagation();
        e.preventDefault();
        doSearch();
    }

    const doSearch = () => {
        searchDuck.doSearch(dispatch, store.getState);
    }

    /**
     * 
     * @param {"next" | "prev"} state 
     */
    const fetchMore = (state) => {
        searchDuck.fetchMoreResults(dispatch, store.getState, state);
    }


    return <>
        { showSearch && <div className={classes.searchMenu + " box-shadow-black"} >
            <div className="text-right mr-3 mt-3 pr-3">
                <button onClick={() => closeSearch()} className="btn btn-link" ><LnIcon title={t.getText("close")} name="close" className="icon-medium" /></button>
            </div>
            <div className={classes.searchform}>

                <form onSubmit={submitForm} className="input-group">

                    <input autoFocus value={searchText || ""} onChange={onChangeText} type="text" className="form-control" name="stext" placeholder={t.getText("search")} aria-label={t.getText("search")} aria-describedby="btnGroupAddon" />
                    <div className="input-group-append" >
                        <button type="button" className="btn btn-small btn-primary" onClick={doSearch} >
                            <LnIcon name="search-icon" className="icon-small" />
                        </button>
                    </div>
                </form>
            </div>

            {result && <div className={"overflow-x-hidden overflow-y-auto ml-3 " + classes.results}>
                {result.Results.map((r, i) => <div key={i} >

                    {r.Classes.map(cl => {
                        const action = pathToAction(cl.Url, routesMap);
                        return <div key={cl.Class.Id} className="mx-3 mb-4 pb-2 border-bottom">
                            <div className="d-flex">
                                <Link to={action} >{r.CoursePart.Name}</Link>
                                <span className="ml-2 small">{cl.Class.Name}</span>
                            </div>

                            {cl.SubResults && cl.SubResults.length > 0 && <table className="mt-3 ml-4 small table table-condensed">
                                <tbody>
                                    {cl.SubResults.map((su, ii) => {
                                        const act = pathToAction(su.Url, routesMap);
                                        return <tr key={"s" + ii} className="d-flex">
                                            <td className="border-0">{t.getText(su.Type)}</td>
                                            <td className="border-0">
                                                <Link to={act}>{su.Name}</Link>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>}
                        </div>
                    })}
                </div>)}
                <div className="d-flex justify-content-between mb-3">
                    {page > 1 && <button onClick={() => fetchMore("prev")} className="ml-3 btn btn-inverse btn-small">{t.getText("previous")}</button>}
                    {result.HasMoreResults && <button onClick={() => fetchMore("next")} className="mr-3  ml-3 btn btn-inverse btn-small">{t.getText("search.get.more.results")}</button>}

                </div>



            </div>
            }
        </div>

        }
    </>



}


export default SearchForm;