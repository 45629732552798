//@ts-check
import { routePaths } from "../routes";

class NavigationBlocker {

    /**
     * 
     * @param {{ getState: Function }} store 
     */
    init(store) {
        
        window.addEventListener('beforeunload', function (e) {

            const state =store.getState(); 
            const type = state.location.type;

            let checkResult = undefined;

            if(  routePaths[type] && routePaths[type].confirmLeave )
            {
                checkResult =  routePaths[type].confirmLeave(state);
            }
            

            if (checkResult !== undefined) {
                // Cancel the event
                e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
                // Chrome requires returnValue to be set
                e.returnValue = '';
            }
        });
    }
}

const blocker = new NavigationBlocker();
export default blocker;
