//@ts-check
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import UserImg from "components/UserImg";
import { endPoint } from "AppConstants";
import FileIcon from "components/FileIcons/FileIcon";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import { ModalDialog } from "components/OnlineModal.js";
import WaitRipple from "components/WaitRipple";
import useTypedSelector from 'utils/useTypedSelector';
import { forumService } from 'features/classes/ClassItems/Forum/ForumDuck';
import { Confirm, ModalConstants } from "components/OnlineModal";
import ForumAnswerCard from  "features/classes/ClassItems/Forum/ForumAnswerCard";
import ForumForm from "features/classes/ClassItems/Forum//ForumForm"
import classes from 'features/classes/ClassItems/Forum//forum.module.scss';
import ShowImage from "features/classes/ClassItems/Forum//ShowImage";
import EditForumForm from "features/classes/ClassItems/Forum//EditForumForm";
import LnDropDownMenu from "components/LnDropDownMenu";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { useEffect } from "react";
import ForumTestCommentStub from "features/classes/ClassItems/Forum//ForumTestCommentStub";
import { useCallback } from "react";

const FeedbackThread = ({ onLeaveThreadClick }) => {
    const { languageService: t } = useI18n();
    const dispatch = useDispatch();
    const [editMode, setEditMode] = useState(false);
    const [showCommentDialog, setShowCommentDialog] = useState(false);



    const toggleEdit = () => {
        setEditMode(old => !old);
    };

    /**
     * @type {{currentThread: import("features/classes/ClassItems/Forum/Forum").ForumThreadDto, currentForum: import("features/classes/ClassItems/Forum/Forum").ForumPageDto, userId: string }}
     */
    const { currentThread, currentForum, userId } = useTypedSelector(state => state.forum);
    const { eventsSinceDate } = useTypedSelector(state => state.classData);
    const currentUser = useTypedSelector(state => state.login.user);
    const [showForm, toggleShowForm] = useState(false);

    /**
     * @type {{ current: import("c:/dev/online3.2/src/teacherFeatures/index")}}
     */
    const teacherFeatures = useRef();

    const onDismissCommentDialog = useCallback(() => {
        setShowCommentDialog(false);
        if (teacherFeatures.current) {
            dispatch(teacherFeatures.current.TestCommentService.setCurrentTestInComment(null));
        }
    }, [teacherFeatures, dispatch]);


    let openCommentDialog = useCallback((id) => {
        if (teacherFeatures.current) {
            teacherFeatures.current.TestResultService
                .GetTestResultForComment({ resultId: id, classId: currentForum.Id, dispatch });
        }
        setShowCommentDialog(true);
    }, [currentForum.Id, dispatch, teacherFeatures]);


    useEffect(() => {
        if (currentForum.IsTeacher) {
            import("teacherFeatures").then(features => {
                teacherFeatures.current = features;
            });
        }
    }, [currentForum]);

    /**
     * @type {{current: HTMLDivElement}}
     */
    const articleDiv = useRef();


    const getArticleText = () => {
        if (articleDiv.current) {
            return articleDiv.current.innerText;
        }

        return null;
    }


    const toggleForm = () => {
        toggleShowForm(old => !old);
    };

    if (currentThread === null) {
        return <WaitRipple />
    };

    const setPublished = () => {
        dispatch(forumService.setPostingPublishState({ forumId: currentForum.Id, threadId: currentThread.Id, parentId: null, state: !currentThread.Published }));
    };


    const editPost = () => {
        setEditMode(true);
    }

    const deleteCurrent = async () => {
        let mess = !currentThread.ConnectedTest ? t.getText("delete_posting") :
            t.getText("forum.delete.post.warning.testresults.are.deleted");


        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("delete"),
            message: (<div className="preserve-white">{mess}</div>),
            languageService: t,
            type: "yes/no"
        });

        if (result === ModalConstants.Yes) {
            await dispatch(forumService.deletePosting(currentForum.Id, currentThread.Id, undefined));
            onLeaveThreadClick();

        };
    };

    const displayReadBy = () => {
        ModalDialog({
            bodyClass: 'modal-body',
            buttons: [{
                className: 'btn btn-primary btn-small',
                text: t.getText('close'),
                type: 'dismiss'
            }],
            headerClass: 'modal-header-default',
            message: (<>
                <table role="table" className={["table", classes['forum-table']].join(' ')}>
                    <thead>
                        <tr role="row">
                            <th role="columnheader" className={classes['th']}>
                                {t.getText('name')}
                            </th>
                            <th role="columnheader" className={classes['th']}>
                                {t.getText('date_and_time')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentThread.ReadBy.map(tr => (
                            (
                                <tr role="row" key={tr.Id}>
                                    <td role="cell">
                                        <span className="d-flex flex-row align-items-center">
                                            {tr.ImageUrl ? <UserImg src={endPoint.API_HOST + tr.ImageUrl} className={['rounded-circle mr-4', 'avatar-img', classes['thread-writer-avatar']].join(' ')} alt={tr.FullName} /> : <LnIcon className={['rounded-circle mr-4', 'avatar-img', classes['thread-generic-avatar']].join(' ')} name="user-icon" />}
                                            {tr.FullName}
                                        </span>
                                    </td>
                                    <td role="cell">
                                        {t.getShortDateTimeString(tr.LastRead)}
                                    </td>
                                </tr>
                            )
                        ))}
                    </tbody>

                </table>
            </>),
            title: t.getText('read_by')
        });
    }

    if (!currentThread) {
        return <WaitRipple />
    }
    const newPost = currentThread.Writer.Id !== currentUser.Id && currentThread.Created && eventsSinceDate.getTime() < currentThread.Created.getTime();


    return (
        <>
            <div className="card-header ln-nostyle d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <h3>
                        <LnIcon className="header-icon" name="ear" /></h3>
                    <h3>
                        {t.getText('feedback')}
                    </h3>

                </div>
                <div className="d-print-none">
                    <button type="button" className="btn btn-inverse btn-small mr-4 mb-3" onClick={() => displayReadBy()}>{t.getText('read_by')}</button>
                    <button id="backFromFeedThread" type="button" role="link" className="btn btn-primary btn-small" onClick={() => onLeaveThreadClick()} >{t.getText("back")}</button>
                </div>
            </div>

            <div className="card-body pt-5 ">


                <div className={["position-relative card mb-5", classes['forum-card']].join(' ')}>

                    <div className={"d-flex justify-content-between pr-5" + (currentThread.Writer.Id === userId ? " bg-primary-lightest" : "")}>

                        <div className={"avatar-wrapper"}>
                            {currentThread.Writer.ImageUrl ? <UserImg src={endPoint.API_HOST + currentThread.Writer.ImageUrl} className={['rounded-circle m-4', 'avatar-img'].join(' ')} alt={currentThread.Writer.FullName} /> : <LnIcon className={['rounded-circle m-4', 'avatar-img'].join(' ')} name="user-icon" />}
                        </div>
                        <div className="flex-grow-1 pl-4">
                            <div className={['card-title d-flex justify-content-between mt-4', classes['forum-head']].join(' ')}>
                                <h5 className={classes['forum-title']}>
                                    {currentThread.Writer.FullName}
                                </h5>
                                <div className="d-flex">
                                    <small className={"d-flex flex-row flex-sm-column align-items-end " + (newPost && " font-weight-bold")}>
                                        <div>
                                            {t.getShortDateTimeString(currentThread.Created)}
                                        </div>
                                        {currentThread.Edited && <>
                                            <div>{t.getText("edited")} </div>
                                            <div>{t.getShortDateTimeString(currentThread.Edited)}</div></>}
                                    </small>
                                    <div className="d-flex flex-md-column  flex-row">
                                        {(currentThread.IsModerator || currentThread.CanEdit) &&
                                            <LnDropDownMenu
                                                direction="down"
                                                caret={false}
                                                toggleComponent={
                                                    <button type="button" className="edit round" title={t.getText("edit")} >
                                                        <LnIcon className="icon-small" name="edit-icon" alt="Edit" />
                                                    </button>
                                                }
                                                toogleClassName=""
                                                className="ml-3 mb-3"
                                                toogleTagName="div"
                                            >
                                                <DropdownMenu right className="" container="body">
                                                    <div className="d-flex flex-column">
                                                        {currentThread.CanEdit &&
                                                            <DropdownItem onClick={() => editPost()} tag="span" >{t.getText("edit")}</DropdownItem>
                                                        }
                                                        {(currentThread.IsModerator || currentThread.CanDelete) &&
                                                            <DropdownItem onClick={() => deleteCurrent()} tag="span" >{t.getText("delete")}</DropdownItem>
                                                        }

                                                    </div>
                                                </DropdownMenu>
                                            </LnDropDownMenu>
                                        }
                                        {currentThread.IsModerator &&
                                            <button type="button" onClick={setPublished} className={"ml-3 edit round " + (currentThread.Published ? "" : "inverse")}>
                                                <LnIcon className="small" name={currentThread.Published ? "eye-icon" : "eye-icon-off"} />
                                            </button>

                                        }
                                    </div>

                                </div>

                            </div>
                            <div className={[classes['forum-text-wrapper']].join(' ')}>
                                <div className="d-flex flex-wrap flex-md-nowrap">
                                    {(currentThread.Attachment)
                                        && <div className="mr-4">
                                            {
                                                currentThread.Attachment.MediaType === 1 ?
                                                    <button type="button" className="btn btn-link" onClick={() => ShowImage(endPoint.API_HOST + currentThread.Attachment.Url, currentThread.Attachment.FileName, t)}>
                                                        <img src={endPoint.API_HOST + currentThread.Attachment.ThumbUrl} alt={currentThread.Attachment.FileName} />
                                                    </button>
                                                    : <a href={endPoint.API_HOST + currentThread.Attachment.Url}>
                                                        <FileIcon className={[classes['file-icon']].join(' ')} size="512px" name={(currentThread.Attachment.FileName || '').split('.').pop()} /><br />
                                                        {currentThread.Attachment.FileName}
                                                    </a>
                                            }
                                        </div>}
                                    <div className="mb-3 flex-grow-1">
                                        {!editMode &&
                                            <>
                                                <div ref={articleDiv} className={"word-break-word infocard-text max-col-width " + (newPost && " first-line-bold")} dangerouslySetInnerHTML={{ __html: currentThread.Article }}></div>
                                                {currentThread.TestData &&
                                                    <div className="badge-pill badge-warning badge">{t.getText("forum.part.of.test", currentThread.TestData.TestName, currentThread.TestData.PartName)}</div>
                                                }

                                                {(currentThread.Attachment && currentThread.Attachment.MediaType === 3) &&
                                                    <audio controls={true} src={endPoint.API_HOST + currentThread.Attachment.Url}></audio>
                                                }

                                                {(currentThread.Attachment && currentThread.Attachment.MediaType === 2) &&
                                                    <video className="mt-3" controls={true} src={endPoint.API_HOST + currentThread.Attachment.Url} ></video>
                                                }
                                            </>
                                        }

                                        {editMode &&
                                            <EditForumForm onLeaveThread={onLeaveThreadClick} forumId={currentForum.Id} parentId={undefined} postType="thread" fileOptions={{
                                                imageFileTypes: currentForum.ImageFileTypes,
                                                videoFileTypes: currentForum.VideoFileTypes,
                                                audioFileTypes: currentForum.AudioFileTypes,
                                                AcceptFileTypes: currentForum.AcceptFileTypes,
                                                MaxFileSize: currentForum.MaxFileSize
                                            }} attachment={currentThread.Attachment}
                                                article={getArticleText()}
                                                showFileUpload={true}
                                                postid={currentThread.Id}
                                                toggleForm={toggleEdit} />
                                        }

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {currentThread.CanAddAnwser && !showForm && currentThread.Published ? <div className="mt-3 pl-4 mb-3">
                        <button type="button" className="btn btn-link btn-small" onClick={() => toggleForm()}>{t.getText('comment')} </button></div> : null}
                    {showForm ? <div><ForumForm fileOptions={{
                        imageFileTypes: currentForum.ImageFileTypes,
                        videoFileTypes: currentForum.VideoFileTypes,
                        audioFileTypes: currentForum.AudioFileTypes,
                        AcceptFileTypes: currentForum.AcceptFileTypes,
                        MaxFileSize: currentForum.MaxFileSize
                    }} formType={'answer'} isTeacher={currentForum.IsTeacher} replyTo={currentThread.Id} forumId={currentThread.ForumId} toggleForm={toggleForm} /></div> : null}


                    {currentThread.Answers.length ? <div className="mt-3">
                        {currentThread.Answers.map(a => (
                            <ForumAnswerCard onCommentTest={openCommentDialog} isTeacher={currentForum.IsTeacher} key={a.Id} answer={a} />
                        ))}
                    </div> : <div></div>}


                </div>

            </div>
            {currentForum && currentForum.IsTeacher && <ForumTestCommentStub show={showCommentDialog} onDismiss={() => onDismissCommentDialog()} />}
        </>
    )

}


export default FeedbackThread;