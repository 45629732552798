//@ts-check
import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import { classesDuck } from "../../ClassesDuck";
import { feedDuck } from "./FeedDuck";
import GetStudentsForFilter from "components/GetStudentsForFilter";

/**
 * 
 * @param {{ className: string, subGroupId? :string}} props  
 */
const FilterOutUserInFeed = ({ className, subGroupId }) => {

    const dispatch = useDispatch();
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const { userId, filterType } = useTypedSelector(state => state.feed);

    const [users, setUsers] = useState([]);
    const [selected, setSelected] = useState(null);


    useEffect(() => {

        if (subGroupId) {
            const subGr = currentClass.SubGroups.find(s => s.Id === subGroupId);
            if (subGr) {
                setUsers(subGr.Members);
            }
        }
        else {

            dispatch(classesDuck.getStudents(currentClass.Id))
                // @ts-ignore
                .then((data) => {
                    setUsers(data);
                    if (userId) {
                        const us = data.find(u => u.Id === userId);
                        if (us) {
                            setSelected(us);
                        }
                    }
                });
        }

        // eslint-disable-next-line 
    }, [])


    const userChanged = useCallback((us) => {

        if (us ) {
            dispatch(feedDuck.filterData(us.Id, currentClass.Id, filterType, subGroupId));
        }
        else {
            dispatch(feedDuck.filterData(null, currentClass.Id, filterType, subGroupId));
        }

    setSelected(us);

    }, [filterType, setSelected, dispatch, subGroupId, currentClass]);

    const filterTypeChanged = useCallback((type) => {
        dispatch(feedDuck.filterData(userId, currentClass.Id, type, subGroupId));
    }, [userId, dispatch, subGroupId, currentClass]);



    return <GetStudentsForFilter selected={selected} enabled={(userId !==null)} 
        filterType={filterType} users={users} userChanged={userChanged} filterTypeChanged={filterTypeChanged} className={className} />

}


export default FilterOutUserInFeed;
