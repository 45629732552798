//@ts-check
import React, { useState } from "react";
import { courseDuck } from "features/course/CourseDuck";
import useTypedSelector from "utils/useTypedSelector";
import { useI18n } from "components/lni18n";
import { LnCollapseGroup } from "components/LnCard/LnCollapseGroup";
import dayjs from "dayjs";
import FileCard from './FileCard';

import classes from './files.module.scss';

/**
 * Files
 * @module features/course/part/Files/Files
 * @return a list of files either by group or by date.
 *
 */
const Files = () => {

    const { languageService: t } = useI18n();
    const part = courseDuck.useCurrentPart();
    const { eventsSinceDate } = useTypedSelector(state => state.classData);
    const [sortBy, setOrder] = useState('group');
    const setSortOrder = (order) => {
        setOrder(order);
    }

    if (!part.FileGroups || part.FileGroups.length === 0) {
        return null;
    }


    const changesDate = dayjs(eventsSinceDate);
    let output;


    if (sortBy === 'group') {
        output = (
            part.FileGroups.map(g =>
                <div role="listitem" key={g.Id} className="mb-4">
                    <LnCollapseGroup group={g} isOpen={true}>
                        <div role="list" className={classes.cardWrapper}>
                            {
                                g.Files.map(f => {
                                    const isNew = dayjs(f.LastModified).isAfter(changesDate, 'day');
                                    return <FileCard isNew={isNew} key={f.Id} file={f} />
                                }
                                )}
                        </div>
                    </LnCollapseGroup>
                </div>
            )
        )
    } else {
        output = (
            <div className="d-flex justify-content-start flex-wrap">
                {part.AllFiles.map(f => {
                    const isNew = dayjs(f.LastModified).isAfter(changesDate, 'day');
                    return <FileCard isNew={isNew} key={f.Id} file={f} />
                }
                )}
            </div>
        )
    }

    return (
        <div>
            <div role="list">
                <div className={classes['btn-group-wrapper']}>
                    <div className="btn-group" role="group">
                        <button type="button" onClick={() => setSortOrder('group')} className={"btn " + (sortBy === 'group' ? 'btn-primary' : 'btn-inverse')}>{t.getText("coursepart.file.display.grouped")}</button>
                        <button type="button" onClick={() => setSortOrder('bydate')} className={"btn " + (sortBy === 'bydate' ? 'btn-primary' : 'btn-inverse')}>{t.getText("coursepart.file.display.lastmodified")}</button>
                    </div>
                </div>
                <div>
                    {output}
                </div>
            </div>
        </div>
    )

}

export default Files;
