//@ts-check
import React,{useState} from "react";
import WaitRipple from "components/WaitRipple"
let Attendance = null;

const AttendanceStub = (props) => {

    const [ready, setReady ] = useState(false);

    import("teacherFeatures").then(features => {
        Attendance = features.Attendance
        setReady(true);
    });

    if (!ready) {
        return <WaitRipple />;
    }
    else {
        return <Attendance />
    }
}

export default AttendanceStub;
