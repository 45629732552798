//@ts-check
import React from "react";
import LnIcon from "components/LnIcon";

import classes from '../classmail.module.scss';

/**
 * @param {{
    mailData: import("../ClassMail").BulkMailProjectDetailDto,
    iconTypes: any
}} props
 */
const RecieversList = (props) => {

    const {mailData, iconTypes} = props;
    
    return (

        <div className="">
        <ul className="list-group list-group-flush">
            {mailData.Statuses.map((s, index) => {
                return <li key={index + '_' + s.Receiver.Id} className={"list-group-item d-flex justify-content-between" + (s.Status === 'opened' ? " bg-primary-lightest" : "")}>
                    <span>
                        {iconTypes[s.Status] || <LnIcon className={["mr-4", classes.spinnerIcon].join(' ')} name="spinner" />}
                        {s.Receiver.FullName}
                    </span>
                    <small>
                        {s.Address}
                    </small>
                </li>
            })

            }
        </ul>
    </div>


    )
}
export default RecieversList;
