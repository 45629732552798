//@ts-check
import Donut from "components/Donut/Donut";
import WaitRipple from "components/WaitRipple";
import React, { useEffect, useState } from "react";
import Link from "redux-first-router-link";
import classes from "./dashboard.module.scss";
import { useI18n } from "components/lni18n";
import { classesDuck } from "../ClassesDuck";
import LnIcon from "components/LnIcon";

/**
 * Dashboard
 * @module features/classes/Dashboard/Dashboard
 * @return a wrapper for available Dashboard widgets.
 * @param { {data: import("../Classes").TeacherClassDto, since: Date}} props
 */
const TeacherMiniDashboard = ({ data, since }) => {

    const { languageService: t } = useI18n();

    /**
    * @type {[import("../Classes").StudentClassDtoForTeacher, (d:import("../Classes").StudentClassDtoForTeacher)=>void ]}
    */
    const [localdata, setLocalData] = useState(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {

        /**
         * @type{import("../Classes").StudentClassDtoForTeacher}
         */
        const d = {
            NewCommentedTestResultCount: 0,
            ShowLecturesViewed: false,
            PercentLecturesViewed: 0,
            FinishedUser: null,
            HasPlan: false,
            LastClass: false,
            Changes: null,
            Notifications: [],
            ServiceProviders: [],
            StudentClass: data.StudentClass,
            Addons: null,
            CourseType: 0,
            TestResultCounts: data.TestResultCounts,
            CourseImageUrl: null,
            NewSubmittedTestResultCount: 0,
            ShowAllLecturesViewed: false,
            PercentAllLecturesViewed: 0,

        }
        setLocalData(d);

        classesDuck.getTeacherClassData(data.StudentClass.Id, since)
            .then(da => {
                setLocalData(da);
                setLoaded(true);
            });
        // eslint-disable-next-line
    }, [])

    if (!localdata) {
        return <WaitRipple />
    }

    let donutTestData = [];
    if (localdata.TestResultCounts.Approved) {
        donutTestData.push(
            {
                value: localdata.TestResultCounts.Approved,
                cssClass: "donut-approved-stroke"
            })
    };

    if (localdata.TestResultCounts.Failed) {
        donutTestData.push(
            {
                value: localdata.TestResultCounts.Failed,
                cssClass: "donut-failed-stroke"
            }
        );
    }

    if (localdata.TestResultCounts.Submitted) {
        donutTestData.push(
            {
                value: localdata.TestResultCounts.Submitted,
                cssClass: "donut-passed-stroke"
            }
        );
    }

    donutTestData.push(
        {
            value: localdata.TestResultCounts.Total - (localdata.TestResultCounts.Submitted + localdata.TestResultCounts.Approved + localdata.TestResultCounts.ToFollowUp + localdata.TestResultCounts.Failed + localdata.TestResultCounts.ToFollowUp),
            cssClass: "donut-notset-stroke"
        });

    const numReady = localdata.TestResultCounts.Submitted + localdata.TestResultCounts.Approved + localdata.TestResultCounts.ToFollowUp;

    return (
        <div className={"w-100 card " + classes.classDataCard} >
            <div className="card-header font-weight-bold">
                <Link className="link-nocolor" to={{
                    type: 'CLASS',
                    payload: { classid: localdata.StudentClass.Id }
                }}>
                    {localdata.StudentClass.Name}
                </Link>
            </div>
            <div className="card-body pt-1 ">
                <div className="d-sm-flex d-block pr-3">
                    <div className="d-flex  w-sm-50 ">
                        <div className="w-25 ">
                            <Donut thickness={25} gap={2} segments={donutTestData}
                                centerText={numReady + "/" + localdata.TestResultCounts.Total} />
                        </div>
                        <div className="w-75 pl-3">
                            <ul className="list-unstyled mb-4">
                                <li className="border-list">{t.getText('approvedbyteacher')} </li>
                                <li className="border-list pink-border-list">{t.getText('failed')} </li>
                                <li className="border-list yellow-border-list">{t.getText('passed')} </li>
                            </ul>
                        </div>
                    </div>

                    <div className="w-sm-50">
                        {localdata.Changes && localdata.Changes.Notifications.length > 0
                            && <div className="mb-1 d-flex align-items-start">
                                <span className="badge badge-pill badge-primary">{localdata.Changes.Notifications.length}</span>
                                <span className="ml-3">{t.getText("new.notifications")}</span>
                            </div>
                        }

                        {localdata.Changes && localdata.NewSubmittedTestResultCount > 0
                            && <div className="mb-1 d-flex align-items-start">
                                <span className="badge badge-pill badge-primary">{localdata.NewSubmittedTestResultCount}</span>
                                <span className="ml-3">{t.getText("new.submitted.test.results")}</span>
                            </div>
                        }


                        {localdata.Changes && (localdata.Changes.MajorEvent || localdata.Changes.CourseParts.length > 0)
                            && <div className="mb-1 d-flex align-items-start">
                                <span className="badge badge-pill badge-primary">&nbsp;!&nbsp;</span>
                                <span className="ml-3">{t.getText("new.course.content")}</span>
                            </div>
                        }


                        {localdata.Changes && (localdata.Changes.ClassFiles && localdata.Changes.ClassFiles.length > 0)
                            && <div className="mb-1 d-flex align-items-start">
                                <span className="badge badge-pill badge-primary">{localdata.Changes.ClassFiles.length}</span>
                                <span className="ml-3">{t.getText("new.class.files")}</span>
                            </div>
                        }


                        {localdata.Changes && (localdata.Changes.TotalFeedPostCount > 0)
                            && <div className="mb-1 d-flex align-items-start">
                                <span className="badge badge-pill badge-primary">{localdata.Changes.TotalFeedPostCount}</span>
                                <span className="ml-3">{t.getText("feed.unread.postings")}</span>
                            </div>
                        }


                        {localdata.Changes && (localdata.Changes.TotalForumCount > 0)
                            && <div className="mb-1 d-flex align-items-start">
                                <span className="badge badge-pill badge-primary">{localdata.Changes.TotalForumCount}</span>
                                <span className="ml-3">{t.getText("new.forums")}</span>
                            </div>
                        }


                        <div>
                            <Link to={{
                                type: 'CLASS_FULL_PROGRESS',
                                payload: { classid: localdata.StudentClass.Id, type: 'results' }
                            }}>
                                {t.getText("class.full.progress")}
                            </Link>
                        </div>
                        { !loaded &&
                            <div>
                                <LnIcon name="waiter" className="header-icon" />    
                            </div>
                        }



                    </div>



                </div>

            </div>

        </div>
    )
}

export default TeacherMiniDashboard;
