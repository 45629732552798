//@ts-check
import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import ScrollContent from "components/ScrollContent";
import { useDispatch } from "react-redux";
import ForumView from "./ForumView";
import ForumThread from "./ForumThread";
import WaitRipple from "components/WaitRipple";

const ForumForClass = () => {
    const route = useTypedSelector(state => state.location.type);
    const currentForum = useTypedSelector(state => state.forum.currentForum);
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const dispatch = useDispatch();

    const handleThreadClick = (threadId) => {
        dispatch({
            type: "CLASS_FORUM_THREAD",
            payload: {
                classid: currentClass.Id,
                forumId: currentForum.Id,
                thread: threadId
            }
        });
    }

    const handleLeaveThreadClick = () => {
        dispatch({
            type: "CLASS_FORUM",
            payload: { classid: currentClass.Id }
        });
    }

    if( currentForum === null || currentForum.Id !== currentClass.Id){
        return <WaitRipple />
    }

    return (
        <>
            {route === "CLASS_FORUM" &&
                <ScrollContent scrollname="class-forum">
                    <div className="card-box big-card-box">
                        <ForumView onThreadClick={handleThreadClick} />
                    </div>
                </ScrollContent>
            }
            {route === "CLASS_FORUM_THREAD" &&
                <ScrollContent scrollname="class-forumthread">
                    <div className="card-box big-card-box">
                        <ForumThread onLeaveThreadClick={handleLeaveThreadClick} />
                    </div>
                </ScrollContent>
            }


        </>
    )




}


export default ForumForClass;