//@ts-check
import DebouncedButton from "components/DebounceButton";
import WaitRipple from "components/WaitRipple";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { mcTestDuck } from "./McTestDuck";

/**
 * 
 * @param {{currentTest: import("./McTest").MCTestStartDto, 
 *      currentQuestion: import("./McTest").MCTestQuestionData,
 *      toNextQuestion: ()=>void, 
 *      submitAnswer: ()=>void, 
 *      languageService: any }} param0 
 */
const McTestCheckOptions = ({ currentTest, currentQuestion, toNextQuestion, submitAnswer, languageService }) => {

    const t = languageService;
    const dispatch = useDispatch();
    let firstControl = useRef(null);
    useEffect(() => {

        if (firstControl.current != null && firstControl.current.children) {
            // focus on first input control
            firstControl.current.children[0].focus();
        }

    });

    const handleChange = (i, checked) => {
        dispatch(mcTestDuck.handleAnswerChecked({ index: i, state: checked }));
    }

    const checkAnswers = () => {
        dispatch(mcTestDuck.checkMultipleAnswers());

    }

    return <>
        {currentQuestion.data.Answers.map((a, i) => {
            return (
                <div ref={el => i === 0 ? (firstControl.current = el) : null}
                    key={a.Index}
                    className="custom-control custom-checkbox mb-4"
                >
                    <input
                        onChange={(e) => handleChange(a.Index, e.target.checked)}
                        checked={a.Checked || false}
                        disabled={currentQuestion.pending}
                        type="checkbox"
                        name="answers"
                        className="custom-control-input"
                        id={"id_" + a.Index}
                    />
                    <label
                        className="custom-control-label pt-1"
                        htmlFor={"id_" + a.Index}
                        dangerouslySetInnerHTML={{ __html: a.Text }}
                    ></label>
                </div>
            );

        })

        }

        <div className="position-relative w-50">
            {currentQuestion.savingToServer && <WaitRipple />}
        </div>

        {!currentTest.StudyMode &&
            <div className="mt-2">
                <DebouncedButton type="button" disabled={!currentQuestion.data.CanPost || currentQuestion.pending || currentQuestion.savingToServer} onClick={submitAnswer} className="btn btn-primary mr-2" >{t.getText("save.short")}</DebouncedButton>
                {currentQuestion.pending ? <DebouncedButton type="button" onClick={() => toNextQuestion()} className="btn btn-inverse btn-primary mr-2" >{t.getText("next")}</DebouncedButton> : null}
            </div>
        }
        {/* else */}
        {currentTest.StudyMode &&
            <DebouncedButton type="button" disabled={currentQuestion.pending || !currentQuestion.data.CanPost} onClick={checkAnswers} className="btn btn-primary mr-2" >{t.getText("check")}</DebouncedButton>
        }

    </>


}

export default McTestCheckOptions;