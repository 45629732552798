import React from "react";
import { courseDuck } from "features/course/CourseDuck";
import { useI18n } from "components/lni18n";
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import DebouncedButton from "components/DebounceButton";

const EvalForm = (props) => {
    const part = courseDuck.useCurrentPart();
    const { languageService: t } = useI18n();
    const dispatch = useDispatch();
    const currentClass = useTypedSelector(state => state.classData.currentClass);


    const handleMcChange = (questionId, answerNumber) => {
        dispatch(courseDuck.setEvalMcAnswerForCurrentPart({ questionId: questionId, answerNumber: answerNumber }));
    }

    const handleTextChange = (event, questionId) => {
        dispatch(courseDuck.setEvalTextAnswerForCurrentPart({ questionId: questionId, text: event.target.value }));
    }

    const submit = () => {
        courseDuck.submitEval(dispatch, part, currentClass.Id);
    }
    if (part.Eval.Questions === null || part.Eval.Questions.length === 0) {
        return null;
    }

    return <div className="max-col-width">


        {part.Goals !== null && part.Goals !== "" &&
            <div className="mb-3">
                <h3>
                    {t.getText("coursepart.goals")}
                </h3>
                <div dangerouslySetInnerHTML={{ __html: part.Goals }}></div>
            </div>
        }

        <div role="list">{part.Eval.Questions.map((question, i) => (


            <div key={i} className="mb-3">
                <div key={i} className="alert alert-grey d-flex">
                    <div>
                        <strong>{t.getText("question")}&nbsp;{(i + 1)}</strong>
                    </div>

                    <div className="ml-3" dangerouslySetInnerHTML={{ __html: question.Text }}></div>
                </div>

                {question.AnswerType === "freetext" && <textarea value={question.TextAnswer}
                    placeholder={t.getText("answer")} onChange={(e) => handleTextChange(e, question.Id)} className="form-control"></textarea>}

                {question.AnswerType === "multiplechoice" && <div>
                    {question.Answers.map((a, ii) => (
                        <div key={ii} className="custom-control custom-radio mb-4" >
                            <input
                                checked={a.Checked || false}
                                type="radio"
                                name={"answ" + question.Id}
                                className="custom-control-input"
                                id={"id_" + question.Id + ii}
                                onChange={(e) => handleMcChange(question.Id, a.Id)} />

                            <label
                                className="custom-control-label pt-1 ml-4 pointer"
                                htmlFor={"id_" + question.Id + ii}
                                dangerouslySetInnerHTML={{ __html: a.Text }}>
                            </label>
                        </div>
                    ))}
                </div>
                }

            </div>

        ))}
        </div>
        <div className="mt-3 mb-3">
            <DebouncedButton disabled={!part.Eval.CanSubmit} className="btn btn-primary" onClick={submit} >{t.getText("submit")}</DebouncedButton>
            {!part.Eval.CanSubmit && <div className="mt-3 small preserve-white">{t.getText("evaluation.answerallquestions")}</div> }
        </div>
    </div >
}

export default EvalForm;