//@ts-check
export const POINTRADIUS = 7;
export const LINEWIDTH = 2;
export const SPLINETENSION = 0.4;


class DrawingTestService {

    pressStartPos = null;
    dragging = false;
    newPointSelected = false;
    hovered = null;

    /**
     * @type import('./DrawingTest').DrawingState
     */
    state = { Selected: null, Color: "#ff0000", Closed: false, Points: [], DrawHandles: true };

    reset = () => {
        this.state = { Selected: null, Color: "#ff0000", Closed: false, Points: [], DrawHandles: true };
        this.pressStartPos = null;
        this.dragging = false;
        this.newPointSelected = false;

    }

    setState = (state) => {
        this.state = state;
        this.pressStartPos = null;
        this.dragging = false;
        this.newPointSelected = false;
    }

    getModel = () => {return this.state};


    erasePath = () => {
        if (this.state) {
            this.state.Points = [];
            this.state.Closed = false;
            this.dragging = false;
            this.newPointSelected = false;
            this.hovered = null;
            this.pressStartPos = null;
            return this.state;
        }

        return null;


    }

    setHandlesState = (state) => {
        this.state.DrawHandles = state;
        return this.state;
    }

    handleMousePress = (pos) => {

        if (this.state.DrawHandles === false && this.state.Points.length > 0) {
            return null;
        }


        this.pressStartPos = pos;
        this.dragging = false;

        let point = this.getPoint(pos);
        
        // Create a new point if there wasn't one there and the line isn't closed.
        if (!this.state.Closed && !point) {

            point = {
                X: pos.X,
                Y: pos.Y
            };

            this.state.Points.push(point);

            this.newPointSelected = true;
        } else {
            this.newPointSelected = false;
        }

        this.state.Selected = point;


        return this.state;
    }

    /**
     * @param { import('types/types').Point } pos
     * @return import('./DrawingTest').DrawingState
     */
    handleMouseMove = (pos) => {

        let draw = false;

        // Move the selected point.
        if (this.state.Selected) {

            // Determine if the user is dragging or clicking the selected point.
            // If the cursor has moved 2 pixels away from its starting point,
            //   we're going to assume they're dragging.
            if (this.distSqr(pos, this.pressStartPos) > 4) {
                this.dragging = true;
            }

            const index = this.state.Points.indexOf(this.state.Selected);
            let arr = this.state.Points.slice();

            arr.splice(index,1, pos);
            this.state.Points = arr;
            this.state.Selected = pos;

            draw = true;
        } else {
            // Update the hovered point.
            const h = this.getPoint(pos);
            draw = this.hovered !== h;
            this.hovered = h;
        }

        return draw ? this.state : null;
    }

    /**
     * @returns {{model: import('./DrawingTest').DrawingState, closedStateChanged: boolean}}
     */
    handleMouseUp = () => {
       
        const oldClosedState = this.state.Closed;
        let wasSelected = false;
        if (this.state.Selected) {
            wasSelected = true;
            let firstPoint = this.state.Points[0];
            let lastPoint = this.state.Points[this.state.Points.length - 1];
            

            // Close the loop if the user clicked the first point.
            // Must be at least 3 points on the canvas.
            if (!this.dragging &&
                this.state.Points.length > 2 &&
                this.state.Selected === firstPoint &&
                this.state.Closed === false) {

                this.state.Closed = true;
            }

            // Close the loop if the last point and the first point are close enough (their edges are overlapping).  
            // Must be at least 4 points on the canvas, since the last point will be removed.
            if (this.dragging &&
                this.state.Points.length > 3 &&
                this.distSqr(firstPoint, lastPoint) <= Math.pow(2 * POINTRADIUS, 2) &&
                this.state.Closed === false) {
                this.state.Points.pop();
                this.state.Closed = true;
            }
            // End dragging.
            this.state.Selected = null;
            this.pressStartPos = null;
            this.dragging = false;
        }

        return {model: this.state, closedStateChanged: wasSelected || (this.state.Closed !== oldClosedState) };
    }

    /**
   * @returns {import('./DrawingTest').DrawingState}
   */
    handleMouseOut = () => {
        if (this.state.Selected) {


            // Remove the selected point if the circle isn't closed.
            if (this.state.Closed === false) {
                for (var i = 0; i < this.state.Points.length; i++) {
                    var point = this.state.Points[i];
                    if (point === this.state.Selected) {
                        this.state.Points.splice(i, 1);
                        break;
                    }
                }
            }


            // End dragging.
            this.state.Selected = null;
            this.pressStartPos = null;
            this.dragging = false;

            return this.state
        }

        return null;
    }

    handleDblClick = () => {
        // Close the loop, if there are at least 3 points.
        if (this.state.Closed === false && this.state.Points.length > 2) {
            this.state.Closed = true;
            return this.state;
        }

        return false;
    };

    getPoint = (pos) => {

        for (let i = 0; i < this.state.Points.length; i++) {
            let point = this.state.Points[i];

            if (this.distSqr(point, pos) <= Math.pow(POINTRADIUS, 2)) {
                return point;
            }
        }

        return null;
    }

    distSqr = (a, b) => {
        return Math.pow(a.X - b.X, 2) + Math.pow(a.Y - b.Y, 2);
    }

}

/**
 * @type {{setState: Function, handleMousePress: Function, handleMouseUp: Function, 
 * handleMouseMove: Function, handleMouseOut: Function, getModel: Function,
 * erasePath: Function, handleDblClick: Function, setHandlesState: Function, reset: Function}}
 */
const drawingTestService = new DrawingTestService();
export default drawingTestService;