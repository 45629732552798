//@ts-check
import React, { useCallback, useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import { endPoint } from "AppConstants";
import FormTextInput from "./FormTextInput";
import { v4 as uuid } from 'uuid'
import useTypedSelector from "utils/useTypedSelector";
import LnIcon from "components/LnIcon";
import { userDuck } from "./UserDuck";
import FileUpload from "components/FileUpload/FileUpload";
import { Confirm, Input, ModalConstants } from "components/OnlineModal";
import ChangePasswordModal from "./ChangePasswordModal";
import { fileUploadDuck } from "components/FileUpload/FileUploadDuck"
import { LoginDuck } from "features/login/LoginDuck";
import classes from "./user.module.scss";
import UserImg from "components/UserImg";
import { UncontrolledAlert } from "reactstrap";


let uploadAction = null;

/**
 * 
 * @param {{userData: import('./User').UserDetailDto , t: import('features/language/languageService').LanguageService}} props 
 */
const UserDataGeneral = ({ userData, t }) => {

    const dispatch = useDispatch();
    const user = useTypedSelector(state => state.login.user);
    const userImage = endPoint.GET_USER_IMAGE_URL(userData.Id) + `?x=${uuid()}`;
    const imagePreviews = useTypedSelector(state => state.fileUpload.imagePreviews);

    const [editMode, setEditMode] = useState(userData && (!user.HasShadow && userData.Id === user.Id && userData.CanEdit && userData.Federated === 0));
    const [submitting, setSubmitting] = useState(false);
    const [shadow, setShadow] = useState(true);
    const [showIsSavedAlert, setShowIsSavedAlert] = useState(false);
    const [showIsSavedAlertTimer, setShowIsSavedAlertTimer] = useState(-1);
    const [displayPDExplain, setDisplayPDExplain] = useState(false);
    const [displayAdaptionsExplain, setDisplayAdaptionsExplain] = useState(false);
    const [uniqueAddress, setUniqueAddress] = useState({});


    const [formReady, setFormReady] = useState(false);

    /**
    *
    * @param {number} type
    */
    const sendEmailVerification = (type) => {
        dispatch(userDuck.sendVerifyEmail(userData.Id, type));
    }

    const nameDict = useMemo(() => {
        return {
            FirstName: "fn",
            LastName: "ln",
            Email: "em",
            Email2: "em2",
            street: "st",
            zipcode: "zc",
            city: "cy",
            tele: "t1",
            tele2: "t2",
            invoiceorg: "invo",
            invoiceorg2: "invo2",
            invoicestreet: "invstr",
            invoicezip: "invz",
            invoicecity: "invci",
            invoicecountry: "invco"
        }
    }, []);


    const obscureKey = (key) => {
        return nameDict[key] || key;
    }


    /**
     *
     * @param {1|2} type
     */
    const verifyEmail = async (type) => {

        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("verify"),
            message: (<div className="preserve-white">{t.getText("really.verify.email")}</div>),
            languageService: t,
            type: "yes/no"
        });

        if (result === ModalConstants.Yes) {
            dispatch(userDuck.verifyEmail(userData.Id, type));
        };



    }

    const switchUsr = useCallback(() => {
        dispatch(LoginDuck.switchUser(userData.Id, shadow));
    }, [shadow, dispatch, userData.Id]);

    let openPasswdFunction = () => { };
    const fileOptions = { AcceptFileTypes: "bmp, gif, png, tif, tiff, jpg, jpeg, jfif, jfi", MaxFileSize: 5242880 };
    /**
     *
     * @param {{key:string, name:string, size:number, type:string}} file
     */
    const handleFileUploadReady = async (file) => {

        const doUpload = async (passwd) => {
            const data = {
                FileName: file.name,
                FileTempName: file.key,
                Password: passwd
            }
            try {
                await dispatch(userDuck.uploadAvatar(userData.Id, data));
            }
            finally {
                dispatch(fileUploadDuck.setImagePreviews(null));
                uploadAction = null;
            }
        }

        if (user.Id !== userData.Id && userData.CanEdit) {
            doUpload(null);
        }
        else {
            const promise = Input({
                message: null,
                closeOnEnter: true,
                title: t.getText("personal.info.password"),
                languageService: t,
                fields: [{ name: "password", type: "password", label: t.getText("passwd"), placeholder: "" }]
            }).promise;

            promise.then(async (dialogresult) => {
                if (!dialogresult || !dialogresult.password) {
                    return;
                }

                doUpload(dialogresult.password);

            });
        }
    }

    const imagePreviewReady = (action) => {
        uploadAction = action;
    }

    const doSaveImage = () => {
        uploadAction();
    }

    const deleteUserImage = () => {

        const doDeleteImage = async (passwd) => {

            try {
                await dispatch(userDuck.deleteAvatar(userData.Id, passwd));
            }
            finally {
                dispatch(fileUploadDuck.setImagePreviews(null));
                uploadAction = null;
            }
        }

        if (user.Id !== userData.Id && userData.CanEdit) {
            doDeleteImage(null);
        }
        else {
            const promise = Input({
                message: <div><div className="preserve-white">{t.getText("delete_general_confirm")}</div>
                    <div className="mt-3">{t.getText("personal.info.password")}</div></div>,
                closeOnEnter: true,
                title: t.getText("image.delete"),
                languageService: t,
                fields: [{ name: "password", type: "password", label: t.getText("passwd"), placeholder: "" }]
            }).promise;

            promise.then(async (dialogresult) => {
                if (!dialogresult || !dialogresult.password) {
                    return;
                }

                doDeleteImage(dialogresult.password);

            });
        }
    }

    const handleImageCancel = () => {
        dispatch(fileUploadDuck.setImagePreviews(null));
        uploadAction = null;
    }


    const handleIsUnique = useMemo(() => async (target, propname, value) => {
        if (!editMode || formReady === false) {
            return true;
        }
        else if (value === "") {
            return true;
        } else {
            const address = value.toLowerCase();
            if (uniqueAddress[address] === propname) {
                return true;
            }
            const isUnique = await userDuck.isUnique(propname, address, userData.Id);

            const res = isUnique === 'success';
            if (res) {
                let newdata = { ...uniqueAddress };
                newdata[address] = propname;
                setUniqueAddress(newdata);
            }
            return res;
        }
    }, [editMode, formReady, userData.Id, uniqueAddress]);

    const handleFormSubmit = async (values) => {

        if (userData.Id === user.Id) {
            Input({
                message: null,
                title: t.getText("personal.info.password"),
                languageService: t,
                fields: [{ name: "password", type: "password", label: t.getText("passwd"), placeholder: "" }],
                closeOnEnter: true
            }).promise
                .then((dialogresult) => {
                    if (!dialogresult || !dialogresult.password) {
                        setSubmitting(false);
                        return;
                    }

                    doSubmit(dialogresult.password, values);
                });
        }
        else {
            doSubmit(null, values);
        }

    }

    const doSubmit = async (passwd, values) => {


        setSubmitting(true);

        const postData = {
            Alias: values.Alias,
            Password: passwd,
            ShowPersonalData: values.ShowPersonalData,
        }

        if (userData.Teacher) {
            postData.Adaptions = values.Adaptions;
        }

        if (!userData.Info.Email.WriteOnce) {
            postData.Mail = values[nameDict["Email"]];
        }

        if (!userData.Info.Email2.WriteOnce) {
            postData.Mail2 = values[nameDict["Email2"]];
        }

        if (!userData.Info.FirstNameWriteOnce) {
            postData.FirstName = values[nameDict["FirstName"]];
        }
        if (!userData.Info.LastNameWriteOnce) {
            postData.LastName = values[nameDict["LastName"]];
        }

        const properties = [];

        userData.Info.Properties.forEach(p => {

            if (!p.WriteOnce) {
                let val = values[infoPropertyNames[p.Key]];
                if (val === "") {
                    val = null;
                }
                properties.push({
                    Value: val,
                    Key: p.Key,
                })
            }
        });
        postData['Properties'] = properties;
        try {
            await dispatch(userDuck.saveUserData(userData.Id, postData));
            setSubmitting(false);
            setFormReady(false);
            window.setTimeout(() => {
                reset(values);
                setFormReady(true);
            }, 10);

            setShowIsSavedAlert(true);

            const id = window.setTimeout(() => {
                setShowIsSavedAlertTimer(-1);
                setShowIsSavedAlert(false);
            }, 5000);

            setShowIsSavedAlertTimer(id);


        } catch (error) {
            console.error(error);
            setSubmitting(false);
        }
    };

    useEffect(() => {
        return () => {
            if (showIsSavedAlertTimer > -1) {
                window.clearTimeout(showIsSavedAlertTimer);
            }
        }
    }, [showIsSavedAlertTimer])


    const toggleEditMode = () => {
        setFormReady(false);
        setEditMode(!editMode);
    }

    const togglePDExplain = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDisplayPDExplain(!displayPDExplain);
    }

    const toggleAdaptionsExplain = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDisplayAdaptionsExplain(!displayAdaptionsExplain);
    }

    const formSchema = {
        Alias: Yup.string()
            .test('isUniqueAlias', t.getText('nickname.exists'),
                async function (value) {
                    return await handleIsUnique('Alias', 'alias', value);
                }),
        em: Yup.string()
            .email(t.getText('bad_email_adress'))
            .required(t.getText('required_field'))
            .test('isUniqueEmail', t.getText('user.might.already.exist.description'),
                async function (value) {
                    return await handleIsUnique('Email', 'mail', value);
                }),
        em2: Yup.string()
            .email(t.getText('bad_email_adress'))
            .test('isUniqueEmail2', t.getText('user.might.already.exist.description'),
                async function (value) {
                    return await handleIsUnique('Email2', 'mail', value);
                }),
        fn: Yup.string()
            .required(t.getText('required_field')),
        ln: Yup.string()
            .required(t.getText('required_field')),
    };


    const infoPropertyNames = {};

    userData.Info.Properties.forEach(p => {
        const safeKey = p.Key.replace(/\./g, '_');
        infoPropertyNames[p.Key] = obscureKey(safeKey);
        if (p.Required) {
            formSchema[safeKey] = Yup.string()
                .required(t.getText('required_field'));

        }
    });


    const checks = Yup.object().shape(formSchema);

    const { register, handleSubmit, errors, formState, reset } = useForm({
        resolver: editMode ? yupResolver(checks) : null,
        mode: "onBlur"
    });

    const { isDirty, isValid } = useMemo(() => formState, [formState]);

    const resetForm = () => {
        setEditMode(false);
        reset();
    }

    const openPasswd = () => {
        openPasswdFunction();
    }

    useEffect(() => {
        setFormReady(false);
        if (editMode === true) {

            reset({ ShowPersonalData: userData.Info.ShowPersonalData, Adaptions: userData.Teacher ? userData.Teacher.Adaptions : null })
            window.setTimeout(() => {
                setFormReady(true);
            }, 500);

        }
    }, [userData.Teacher, editMode, reset, userData.Info.ShowPersonalData]);



    return (
        <div className=" pt-4">
            <div className="d-flex justify-content-between mb-4">
                <div>
                    <div>
                        {t.getText('latest.activity')}
                        <strong className="ml-2">
                            {t.getMediumDateTimeString(userData.Info.LastActive)}

                        </strong>

                    </div>
                    {userData.CanEdit &&
                        <div className="mt-3">
                            {t.getText('date.created')}
                            <strong className="ml-2">
                                {t.getMediumDateString(userData.Created)}

                            </strong>
                        </div>
                    }

                    {userData.Teacher && userData.Teacher.StudentClasses.filter(cl => cl.IsArchived).length > 0 &&
                        <div className="d-flex mt-3">
                            <div className="mr-3">{t.getText('archived')}</div>
                            <ul className="pl-5">
                                {userData.Teacher.StudentClasses.filter(cl => cl.IsArchived).map(cl =>
                                    <li>{cl.Name}</li>
                                )}
                            </ul>

                        </div>
                    }

                </div>

                {!user.HasShadow && userData.CanEdit && userData.Federated === 0 && !editMode && <div className="d-flex flex-column align-content-end">
                    <button type="button" className="btn btn-small btn-inverse w-100" onClick={() => toggleEditMode()}>
                        <LnIcon className="icon-small mr-4" name="edit-icon" /> {t.getText('edit')}
                    </button>
                </div>}

                {user.CanSwitchUser &&
                    <div className="d-flex flex-column align-content-end">
                        <button type="button" onClick={switchUsr} className="mt-3 btn btn-small btn-inverse btn-danger">{t.getText("switch.user")}</button>
                        {user.IsRoot && <div className="custom-control custom-switch">
                            <input type="checkbox" checked={shadow} onChange={() => setShadow(!shadow)} className="custom-control-input" id="customSwitch1" />
                            <label className="custom-control-label" htmlFor="customSwitch1">Shadow</label>
                        </div>}
                    </div>
                }


            </div>

            <ChangePasswordModal currentUser={user} userData={userData} getOpenFunction={(f) => {
                openPasswdFunction = f;
            }
            } />

            <form onLoad={() => setFormReady(true)} className="mb-5" onSubmit={handleSubmit(handleFormSubmit)}>


                <div className={["row", classes.formRow].join(' ')}>
                    <div className="col-sm-3 col-md-2">
                        {editMode ? <div>

                            <FileUpload previewReady={imagePreviewReady}
                                className="avatar-upload" hideDox={true} imagePreviewOptions={[{ name: "small", maxheight: 200, maxwidth: 200 }]}
                                handleUploadReady={handleFileUploadReady} accept={fileOptions.AcceptFileTypes} languageService={t}
                                maxsize={fileOptions.MaxFileSize} >
                                {imagePreviews ? <div>
                                    <img src={imagePreviews.small.src} alt={"preview"} />
                                    <br />
                                    <button type="button" onClick={doSaveImage} className="btn btn-small btn-primary mb-3 mt-3 mr-4" >{t.getText("save.short")}</button>
                                    <button type="button" onClick={handleImageCancel} className="btn btn-small btn-inverse" >{t.getText("cancel")}</button>

                                </div> :
                                    <UserImg src={userImage} className="" alt={t.getText('user')} />
                                }
                                <div>
                                    <button type="button" className="mt-3 btn btn-danger btn-inverse btn-small" onClick={deleteUserImage} >{t.getText("image.delete")}</button>
                                </div>

                            </FileUpload>


                        </div> : <UserImg src={userImage} className="" alt={t.getText('user')} />}

                    </div>
                    <div className="col-sm-9 col-md-10">

                        {userData.CanEdit && <>
                            <div className="row">
                                <div className="col">
                                    <div className="custom-control custom-checkbox">

                                        <input ref={register}
                                            disabled={!editMode}
                                            type="checkbox"
                                            name="ShowPersonalData"
                                            className="custom-control-input"
                                            id="id_ShowPersonalData"

                                        />
                                        <label
                                            className="custom-control-label pt-1"
                                            htmlFor="id_ShowPersonalData" >
                                            {t.getText('show.personal.data')}
                                            <button type="button" className="btn btn-link ml-4" onClick={(e) => togglePDExplain(e)}>
                                                <LnIcon className="icon-small" name="questionmark-icon" />
                                            </button>
                                        </label>
                                    </div>
                                    <br />
                                    {displayPDExplain && <div className="alert alert-warning preserve-white"> <small>
                                        {t.getText('show.personal.data.explain')}
                                    </small></div>}
                                </div>

                                {userData.Teacher &&
                                    <div className="col">
                                        {!editMode && userData.Teacher.Adaptions &&
                                            <div>
                                                {t.getText('adaptions')}
                                                <LnIcon name="lifebuoy2" className="ml-3" />

                                            </div>}

                                        {editMode &&
                                            <>
                                                <div className="custom-control custom-checkbox">

                                                    <input ref={register}
                                                        disabled={!editMode}
                                                        type="checkbox"
                                                        name="Adaptions"
                                                        className="custom-control-input"
                                                        id="id_Adaptions"

                                                    />
                                                    <label
                                                        className="custom-control-label pt-1" htmlFor="id_Adaptions" >

                                                        {t.getText('adaptions')}
                                                        <LnIcon name="lifebuoy2" className="ml-3" />
                                                        <button type="button" className="btn btn-link ml-4" onClick={(e) => toggleAdaptionsExplain(e)}>
                                                            <LnIcon className="icon-small" name="questionmark-icon" />
                                                        </button>
                                                    </label>
                                                </div>
                                                <br />
                                                {displayAdaptionsExplain && <div className="alert alert-warning preserve-white"> <small>
                                                    {t.getText('adaptions.explain')}
                                                </small></div>
                                                }
                                            </>
                                        }
                                    </div>
                                }



                            </div>

                            <UncontrolledAlert color="warning preserve-white" >
                                <small>{t.getText('userdata.explain')}</small>
                            </UncontrolledAlert>

                            {editMode && <div className="row mb-4">
                                <div className="col mt-3">
                                    <button className="btn btn-primary btn-small mr-4"
                                        disabled={submitting || !isDirty || !isValid}
                                        type="submit" ><LnIcon className="icon-small filter-white mr-4" name="edit-icon" /> {t.getText('save.short')}</button>
                                    <button className="btn btn-inverse btn-small" type="button" onClick={() => resetForm()}><LnIcon className="icon-small mr-4" name="cross-icon" /> {t.getText('cancel')} </button>
                                </div>
                            </div>
                            }
                        </>
                        }

                        <div className="row">
                            <div className="col-lg-4 col-md-6 mb-3">
                                <FormTextInput
                                    as="input"
                                    errors={errors}
                                    refToHook={register}
                                    label={t.getText('firstname')}
                                    name={nameDict["FirstName"]}
                                    type="text"
                                    readOnly={userData.Info.FirstNameWriteOnce || !editMode}
                                    defaultValue={userData.Info.FirstName || ''}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 mb-3">
                                <FormTextInput
                                    as="input"
                                    errors={errors}
                                    refToHook={register}
                                    label={t.getText('lastname')}
                                    name={nameDict["LastName"]}
                                    type="text"
                                    readOnly={userData.Info.LastNameWriteOnce || !editMode}
                                    defaultValue={userData.Info.LastName || ''}
                                />
                            </div>
                            {(editMode || (userData.Info.Email.Address && userData.Info.Email.Address.length > 0)) &&
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <FormTextInput
                                        as="input"
                                        errors={errors}
                                        refToHook={register}
                                        label={<div className="d-flex justify-content-between">{t.getText('mail')} {!userData.Info.Email.Verified && <span className="ml-3 text-danger"> {t.getText("unverified")} <LnIcon name="alert" className="icon-small" /></span>}</div>}
                                        name={nameDict["Email"]}
                                        type="email"
                                        readOnly={userData.Info.Email.WriteOnce || !editMode}
                                        defaultValue={userData.Info.Email.Address || ''}
                                    />
                                    {!userData.Info.Email.Verified && <>
                                        <button type="button" onClick={() => sendEmailVerification(1)} className="btn btn-inverse btn-small mr-3 mb-3">{t.getText("send.verification.email")}</button>
                                        {(user.Id !== userData.Id) && editMode && <button type="button" onClick={() => verifyEmail(1)} className="btn btn-inverse btn-small">{t.getText("verify")}</button>}
                                    </>}

                                </div>
                            }
                        </div>
                        {editMode &&
                            <div className="row mb-5">

                                <div className="col-lg-4 col-md-6 mb-3">
                                    <FormTextInput
                                        as="input"
                                        errors={errors}
                                        refToHook={register}
                                        label={<div className="d-flex justify-content-between">{t.getText('mail2')} {!userData.Info.Email2.Verified && <span className="ml-3 text-danger"> {t.getText("unverified")} <LnIcon name="alert" className="icon-small" /></span>}</div>}
                                        name={nameDict["Email2"]}
                                        type="email"
                                        readOnly={userData.Info.Email2.WriteOnce || !editMode}
                                        defaultValue={userData.Info.Email2.Address || ''}
                                    />
                                    {!userData.Info.Email2.Verified && <>
                                        <button type="button" onClick={() => sendEmailVerification(2)} className="btn btn-inverse btn-small mr-3 mb-3">{t.getText("send.verification.email")}</button>
                                        {(user.Id !== userData.Id) && editMode && <button type="button" onClick={() => verifyEmail(2)} className="btn btn-inverse btn-small">{t.getText("verify")}</button>}
                                    </>}
                                </div>

                                <div className="col-lg-4 col-md-6 mb-3">
                                    <FormTextInput
                                        as="input"
                                        errors={errors}
                                        refToHook={register}
                                        label={t.getText('alias')}
                                        name="Alias"
                                        type="text"
                                        readOnly={!editMode}
                                        defaultValue={userData.Info.Alias || ''}
                                    />
                                </div>

                                <div className="col-lg-4 col-md-6 mb-3">
                                    <label className="d-block">{t.getText('passwd')}</label>
                                    <button type="button" className="btn btn-inverse btn-small mr-4" onClick={openPasswd}>{t.getText('password.replace')}</button>
                                </div>


                            </div>
                        }
                        <div className="row mb-4">
                            {userData.Info.Properties.map(p => {
                                if (!editMode && (p.Value === null || p.Value === "")) {
                                    return null;
                                }
                                return (
                                    <div key={p.Key} className="col-lg-4 col-md-6 mb-3">

                                       
                                            <FormTextInput
                                                label={t.getText(p.Key)}
                                                errors={errors}
                                                refToHook={register}
                                                name={infoPropertyNames[p.Key]}
                                                as={p.Multiline ? "textarea" : "input"}
                                                type={p.InputType || 'text'}
                                                readOnly={p.WriteOnce || !editMode}
                                                defaultValue={p.Value || ''}
                                            />
                                        
                                    </div>
                                )
                            })}

                        </div>
                        {editMode && <div className="row mb-5">

                            {showIsSavedAlert && (<div className="col-sm-12">
                                <div className="alert alert-primary">
                                    {t.getText("saved")}
                                </div>
                            </div>)}

                            <div className="col-sm-12" >

                                <button className="btn btn-primary btn-small mr-4"
                                    disabled={submitting || !isDirty || !isValid}
                                    type="submit" ><LnIcon className="icon-small filter-white mr-4" name="edit-icon" /> {t.getText('save.short')}</button>
                                <button className="btn btn-inverse btn-small" type="button" onClick={() => resetForm()}><LnIcon className="icon-small mr-4" name="cross-icon" /> {t.getText('cancel')} </button>
                            </div>
                        </div>}
                    </div>
                </div>
            </form>
        </div>
    )

}


export default UserDataGeneral;

