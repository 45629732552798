//@ts-check
import React from "react";
import LnIcon from "components/LnIcon";
import { endPoint } from "AppConstants";


/**
 * ClassesWidget
 * @return an LnCard with user's classes
 * @param {{Data: import("../Classes").ClassAllDataDto }} props
 */
const CurrentCourseWidget = ({ Data }) => {

    if (!Data) {
        return null;
    }

    return <div className='ml-auto card mr-auto mb-5 max-col-width'>
        <div className="card-header">
            <LnIcon className="header-icon" name="star" /> {Data.Name}
        </div>
        <div className="card-body d-flex justify-content-center"  >
        { Data.ImageId && <img alt={Data.Name} src={endPoint.GET_IMAGE_URL( Data.ImageId )} /> }  
        </div>
    </div>
}

export default CurrentCourseWidget;