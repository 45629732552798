//@ts-check
import React from "react";
import useTypedSelector from 'utils/useTypedSelector';
import Members from "../Members/Members"
import WaitRipple from "components/WaitRipple";


const GroupMembers = () => {

    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const location = useTypedSelector(state => state.location);
    const group = currentClass.SubGroups.find(gr => gr.Id === location.payload.groupid);


    if( group.Members == null ){
        return <WaitRipple  />;
    }

    return (
        <Members currentUserIsTeacher={currentClass.CurrentUserIsTeacher} classId={currentClass.Id} students={ group.Members } teachers={[]} name={null}/>
    )
}


export default GroupMembers;
