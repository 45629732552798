//@ts-check
import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import classes from './dashboard.module.scss';
import {LoginDuck} from "features/login/LoginDuck"
import { useDispatch } from "react-redux";

const VerifyEmail2Widget = (props) => {

    const { languageService: t } = useI18n();
    const user = useTypedSelector(state => state.login.user);
    const dispatch = useDispatch();


    const sendVerification = ()=>{
        dispatch(LoginDuck.sendVerificationEmail(user.Id, 2));
    }

    if( user.Mail2Verified === null  ||  user.Mail2Verified === true){
        return null;
    }

    return (
        <div className={['card-box big-card-box card ln-card mr-5 mb-5', classes['widget-card']].join(' ')}>
            <div className={["card-header", classes['card-header']].join(' ')}>
                <LnIcon className="header-icon" name="error" />
                {t.getText('dashboard.email.verification.header')}
            </div>
            <div className="card-body overflow-auto pt-5">
                <div className="preserve-white">{ t.getText("dashboard.email.verification.message.email2", user.Email2 )}</div>
                <button className="btn btn-primary btn-small mt-4 mb-5" onClick={sendVerification} >{t.getText("send.verification.email")}</button>
            </div>
        </div>
    )
}

export default VerifyEmail2Widget;