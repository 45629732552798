// @ts-check
import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import { useI18n } from "components/lni18n";
import WaitRipple from "components/WaitRipple";
import classes from './login.module.scss';
import { LOGO } from "assets/images";

const ReadyForm = () => {

    const { languageService: t } = useI18n();
    const { signOnData } = useTypedSelector(state => state.login);


    if (!signOnData) {
        return <WaitRipple />
    }

    return  <div className=" bg-white rounded p-5 pb-5">
            <h2>{t.getText("ready")}</h2>
            <div className="mt-4" dangerouslySetInnerHTML={{ __html: signOnData.HtmlText }}></div>

            <div className="mt-3 pt-3 border-top d-flex flex-wrap justify-content-between align-items-center">
                <div className="mr-3 my-4">
                    LäraNära {(new Date()).getFullYear()} <a target="_blank" rel="noopener noreferrer" href="https://www.laranara.se">https://www.laranara.se</a>
                </div>
                <div className={classes.logoRegister}>
                    <img src={LOGO} alt="logo" />
                </div>
            </div>

        </div>
}

export default ReadyForm;
