//@ts-check
import React from "react";
import { useStore } from "react-redux";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, CardFooter, CardBody } from "reactstrap";
import Draggable from "react-draggable"
import { lectureDuck } from "./LectureDuck";
import useTypedSelector from "utils/useTypedSelector";
import classes from './lecture.module.scss';
import LnIcon from "components/LnIcon";

import { useI18n } from "components/lni18n";
import lecturePlayerService from "./LecturePlayerService";

const ScreensDialog = (props) => {

    const { currentLecture, showScreensList } = useTypedSelector(state => state.lecture);
    const { languageService: t } = useI18n();
    const dispatch = useDispatch();
    const store = useStore();

    const handleThumbClick = (screenNum) => {
        lectureDuck.tryShiftScreen(dispatch, store.getState, lectureDuck.screenJumps.specificNumber, screenNum);
        closeScreensDialog();
    }

    const closeScreensDialog = () => {
        lecturePlayerService.HandleControlsMenuShowing(false);
        dispatch(lectureDuck.setShowScreensList(false));
    }


    return (

        <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>
            <Modal container={props.container} role="dialog" isOpen={showScreensList} onClosed={closeScreensDialog} toggle={closeScreensDialog} size="lg" backdrop={true}>

                <ModalHeader toggle={closeScreensDialog} />
                <ModalBody className={""} tabIndex={-1}>
                    <div className={classes.thumbsList + " d-flex flex-wrap "}>
                        {currentLecture.Screens.map((s, i) => {

                            return <div key={s.Id} className={classes.card + " card pointer d-flex flex-column"} onClick={() => handleThumbClick(s.Order)} >
                                <CardBody className={classes.screenThumbContainer + " d-flex flex-column"}>
                                    <div className={classes['img-wrapper'] + "  m-auto"}>
                                        <img src={s.ThumbnailUrl} className={classes['lecture-img']} alt="" />
                                    </div>
                                </CardBody>
                                <CardFooter className={" text-center"}>
                                    <small>{(i + 1)}</small>
                                    {s.Watched && <LnIcon className="done-icon natural" name="checked" />}
                                </CardFooter>
                            </div>

                        })}

                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-inverse btn-small" type="button" onClick={closeScreensDialog}> {t.getText('close')} </button>
                </ModalFooter>
            </Modal>
        </Draggable>
    )
}
export default ScreensDialog;
