//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint, MAIL_POLL_INTERVAL_IN_SEC } from "AppConstants";
import DispatchService from "utils/DispatchService"
import { errorDuck } from "components/ErrorHandler/ErrorHandlerDuck";

/**
 * @type {import('./classMail').ClassMailState}
 */
const initialState = {
    mailLists: null,
    currentProject: null,
    currentClassId: null,
    basegroupId: null,
    statusCheckTimerId: -1,
    dirty: false,
};

const classMailSlice = createSlice({
    name: 'classMail',
    initialState: initialState,
    reducers: {

        /**
        * @param  {  {payload: { classId: string, basegroupId: string, lists:import('types/Mail').MailProjectListDto}}} action
        */
        setStartData(state, action) {
            state.dirty = false;
            if (action.payload === null) {
                state.mailLists = null;
                state.currentClassId = null;
                state.currentProject = null;
                state.currentClassId = null;

                if (state.statusCheckTimerId > 0) {
                    window.clearTimeout(state.statusCheckTimerId);
                }

                state.statusCheckTimerId = -1;
            }
            else {
                state.mailLists = action.payload.lists;
                state.currentClassId = action.payload.classId;
                state.currentClassId = action.payload.basegroupId;
                state.currentProject = null;
                

            }

            state.dirty = null;
        },

        /**
         * @param  {  {payload: import('./classMail').BulkMailProjectDetailDto}} action
         */
        setCurrentProject(state, action) {
            if (state.statusCheckTimerId > 0) {
                window.clearTimeout(state.statusCheckTimerId);
            }
            state.currentProject = action.payload;
            state.dirty = false;
        },

        /**
             * @param  { {payload: number}} action
         */
        setStatusCheckId(state, action) {
            state.statusCheckTimerId = action.payload;
        },

        /**
            * @param  { {payload: boolean}} action
        */
        setDirty(state, action) {
            state.dirty = action.payload;
        },


        /**
         * @param  {  {payload: import('types/Mail').MailProjectDto}} action
         */
        addNewProject(state, action) {
            state.mailLists.NotSent.splice(0, 0, action.payload);
        },

        /**
         * @param  {  {payload: import('types/Mail').MailProjectDto}} action
         */
        updateUnsentProjectInList(state, action) {
            if (state.mailLists === null) {
                return;
            }

            const project = state.mailLists.NotSent.find(p => p.Id === action.payload.Id);
            if (project === null) {
                return;
            }
            const index = state.mailLists.NotSent.indexOf(project);
            if (index === -1) {
                throw new Error("Could not find project....");
            }

            state.mailLists.NotSent[index] = { ...project, ...action.payload };

        },

        /**
        * @param  {  {payload: import('./classMail').BulkMailProjectDetailDto}} action
        */
        updateProjectToSentInList(state, action) {

            state.currentProject = action.payload;

            if (state.mailLists === null) {
                return;
            }

            let project = state.mailLists.NotSent.find(p => p.Id === action.payload.Id);
            if (project === null) {
                return;
            }
            const index = state.mailLists.NotSent.indexOf(project);
            if (index === -1) {
                throw new Error("Could not find project....");
            }

            // delete from not sent list
            state.mailLists.NotSent.splice(index, 1);

            // add to top of sent list
            project.Queued = action.payload.Queued;
            state.mailLists.Sent.splice(0, 0, project);
        },

        /**
         * @param  {  {payload: {id: number} }} action
         */
        removeProject(state, action) {
            if (state.currentProject && state.currentProject.Id === action.payload.id) {
                state.currentProject = null;
                state.dirty = false;
            }

            if (state.mailLists.NotSent && state.mailLists.NotSent.length > 0) {
                state.mailLists.NotSent = state.mailLists.NotSent.filter(m => m.Id !== action.payload.id);
            }

            if (state.mailLists.Sent && state.mailLists.Sent.length > 0) {
                state.mailLists.Sent = state.mailLists.Sent.filter(m => m.Id !== action.payload.id);
            }
        },

        /**
         * @param  {  {payload: import('types/Mail').MailProjectAttachmentDto}} action
         */
        addFileToProject(state, action) {
            state.currentProject.Attachments.push(action.payload);
        },

        /**
        * @param  {  {payload:{Id: string} }} action
        */
        deleteFileInProject(state, action) {
            state.currentProject.Attachments = state.currentProject.Attachments.filter(a => a.Id !== action.payload.Id)
        },

        /**
        * @param  {  {payload:import('types/Mail').MailProjectJobDto[] }} action
        */
        setJobstatuses(state, action) {
            if (state.currentProject === null) {
                return;
            }
            state.currentProject.Statuses = action.payload;
        },

    }
});


const checkStatus = async () => {

    /**
    * @type {import('./classMail').ClassMailState}
    */
    const classMailState = DispatchService.getState().classMail;
    if( classMailState.statusCheckTimerId  && classMailState.statusCheckTimerId > -1 ){
        window.clearTimeout(classMailState.statusCheckTimerId );
    }

    DispatchService.dispatch(classMailDuck.setStatusCheckId(-1));

    const project = classMailState.currentProject;
    if (project === null) {
        return;
    }
    try {
        const url = endPoint.GET_STATUS_FOR_CLASS_MAIL_URL(project.Id);

        /**
         * @type { {data: import('types/Mail').MailProjectJobDto[]}}
         */
        const result = await instance.get(url);
        if (result && result.data  && result.data.length > 0) {

            await DispatchService.dispatch(classMailDuck.setJobstatuses(result.data));
            var notOpenedMails = result.data.filter(m => m.Status !== "opened");
            if (notOpenedMails.length > 0) {
                const timerId = window.setTimeout(() => {
                    checkStatus();
                }, 1000 * MAIL_POLL_INTERVAL_IN_SEC);

                await DispatchService.dispatch(classMailDuck.setStatusCheckId(timerId));
            }


        }
    } catch (error) {

        DispatchService.dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }

}


/**
 *
 * @param { string } classId
 */
const getMailListsForClass = (classId) => async (dispatch, getState) => {

    try {
        /**
         * @type {import('./classMail').ClassMailState}
         */
        const state = getState().classMail;
        if (state.mailLists !== null && state.currentClassId === classId) {
            dispatch(classMailDuck.setCurrentProject(null));
            return;
        }


        const url = endPoint.GET_MAIL_LIST_FOR_CLASS_URL(classId);
        /**
         * @type {{data: import('types/Mail').MailProjectListDto}} reponse
         */
        const response = await instance.get(url);
        if( !response){
            return;
        }
        dispatch(classMailDuck.setStartData({ lists: response.data, classId: classId, basegroupId: null }));
    } catch (error) {

        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 *
 * @param { string } classId
 */
const getMailListsForSubGroup = (classId, groupId, forceupdate) => async (dispatch, getState) => {

    try {
        /**
         * @type {import('./classMail').ClassMailState}
         */
        const state = getState().classMail;
        if (state.mailLists !== null && state.basegroupId === groupId) {
            dispatch(classMailDuck.setCurrentProject(null));
            return;
        }

        if (state.mailLists != null && state.basegroupId === groupId && forceupdate === false) {
            return;
        }

        const url = endPoint.GET_MAIL_LIST_FOR_BASEGROUP_URL(classId, groupId);
        /**
         * @type {{data: import('types/Mail').MailProjectListDto}} reponse
         */
        const response = await instance.get(url);
        if( !response){
            return;
        }
        dispatch(classMailDuck.setStartData({ lists: response.data, classId: classId, basegroupId: groupId }));
    } catch (error) {

        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}


/**
 * Add a new mail to list. and returns (ASYNC) the Id of the mail
 *
 * @param { import('./ClassMail').BulkMailProjectCreateFormDto } data
 * @param {Function} dispatch
 * @return { Promise<number> }
 */
const newMail = async (data, dispatch) => {
    try {
        const url = endPoint.GET_CREATE_MAIL_FOR_CLASS_URL(data.ClassId);
        /**
         * @type {{data: import('types/Mail').MailProjectDto}} reponse
         */
        const response = await instance.post(url, data);
        if( !response){
            return null;
        }
        await dispatch(classMailDuck.addNewProject(response.data));
        return response.data.Id;
    } catch (error) {

        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 *
 * @param { string } classId
 * @param { number } mailId
 */
const getMail = async (classId, mailId, dispatch) => {
    try {
        const url = endPoint.GET_MAIL_FOR_CLASS_URL(classId, mailId);
        /**
         * @type {{data: import('./classMail').BulkMailProjectDetailDto}} reponse
         */
        const response = await instance.get(url);
        if( !response){
            return;
        }
        await dispatch(classMailDuck.setCurrentProject(response.data));
        if (response.data.Queued !== null) {
            await checkStatus();
        }
    } catch (error) {

        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }

}

/**
 *
 * @param { number } mailId
 * @param { import('./ClassMail').BulkMailProjectFormDto } postdata
 */
const saveMail = (mailId, postdata) => async (dispatch) => {

    try {
        const url = endPoint.GET_SAVE_MAIL_FOR_CLASS_URL(mailId);
        /**
         * @type {{data: import('types/Mail').MailProjectDto}} reponse
         */
        const response = await instance.put(url, postdata);
        if( !response){
            return;
        }
        dispatch(classMailDuck.updateUnsentProjectInList(response.data));
    } catch (error) {

        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}


/**
 *
 * @param { number } mailId
 */
const sendMail = (mailId) => async (dispatch) => {
    try {
        const url = endPoint.GET_SEND_MAIL_FOR_CLASS_URL(mailId);
        /**
         * @type {{data: import('./classMail').BulkMailProjectDetailDto}} response
         */
        const response = await instance.post(url, null);
        if( !response){
            return;
        }
        await dispatch(classMailDuck.updateProjectToSentInList(response.data));
        checkStatus();
    } catch (error) {

        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 *
 * @param { number[] } userIds,
 * @param { number } mailId
 */
const reSendMail = (userIds, mailId) => async (dispatch) => {
    try {
        const url = endPoint.GET_RESEND_MAIL_FOR_CLASS_URL(mailId);
        /**
         * @type {{data: import('./classMail').BulkMailProjectDetailDto}} response
         */
        const response = await instance.post(url, userIds);
        if( !response){
            return;
        }
        //await dispatch(classMailDuck.updateProjectToSentInList(response.data));
        checkStatus();
    } catch (error) {

        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}


/**
 *
 * @param { number } mailId
 */
const deleteMail = (mailId) => async (dispatch) => {
    try {
        const url = endPoint.GET_DELETE_MAIL_FOR_CLASS_URL(mailId);

        await instance.delete(url, null);
        await dispatch(classMailDuck.removeProject({ id: mailId }));
    } catch (error) {

        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 *
 * @param { number } mailId
 */
const addAttachment = (mailId, postdata) => async (dispatch) => {

    try {
        const url = endPoint.GET_ADD_MAIL_ATTACHMENT_FOR_CLASS_MAIL_URL(mailId);
        /**
         * @type {{data: import('types/Mail').MailProjectAttachmentDto}} reponse
         */
        const response = await instance.post(url, postdata);
        if( !response){
            return;
        }
        dispatch(classMailDuck.addFileToProject(response.data));
    } catch (error) {

        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}


/**
 *
 * @param { number } mailId
 * @param{ string} attachmentId
 */
const deleteAttachment = (mailId, attachmentId) => async (dispatch) => {
    try {
        const url = endPoint.GET_DELETE_MAIL_ATTACHMENT_FOR_CLASS_MAIL_URL(mailId, attachmentId);

        await instance.delete(url);
        dispatch(classMailDuck.deleteFileInProject({ Id: attachmentId }));
    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}


export const classMailDuck = {
    ...classMailSlice.actions, getMailListsForClass, getMailListsForSubGroup,
    getMail, deleteMail, newMail, saveMail, reSendMail,
    sendMail, addAttachment, deleteAttachment
};
export default classMailSlice.reducer;