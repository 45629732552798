//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint } from "AppConstants";

/**
 * @type import('./d3Graph').D3GraphState
 */
const initialState = {
    d3LocaleFormats: null,
    d3LoggedinData: null
};


const d3GraphSlice = createSlice({
    name: 'classes',
    initialState: initialState,
    reducers: {

        /**
        * @param  {  {payload: any  }} action
        */
        setD3LocaleData(state, action) {
            state.d3LocaleFormats = action.payload;
        },

        /**
        * @param  {  {payload: string  }} action
        */
        setLoggedInData(state, action) {
            state.d3LoggedinData = action.payload;
        }
    },

});



const fetchLoggedInData = (classId) => async (dispatch, getState) => {

    const dataPromise = instance.get(endPoint.GET_CLASS_LOGGED_IN_STAT_URL(classId))
    .then(response => {
        dispatch(d3GraphDuck.setLoggedInData(response.data));
    });



    const locale = getState().d3graph.d3LocaleFormats;
    if (!locale) {
        const localePromise = instance.get(endPoint.D3_LOCALE_FORMTS_URL)
        .then(response => {
            dispatch(d3GraphDuck.setD3LocaleData(response.data));
        });

        await Promise.all([dataPromise, localePromise]);
    }

}

const getD3LocaleData = () => dispatch => {
    instance.get(endPoint.D3_LOCALE_FORMTS_URL)
        .then(response => {
            dispatch(d3GraphDuck.setD3LocaleData(response.data));
        });

}


export const d3GraphDuck = {
    ...d3GraphSlice.actions,
    getD3LocaleData, fetchLoggedInData
};

export default d3GraphSlice.reducer