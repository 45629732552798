//@ts-check
import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { useI18n } from "components/lni18n";
import { errorDuck } from "components/ErrorHandler/ErrorHandlerDuck";
import MailEditor from "components/Mail/MailEditor";
import MailMeta from "components/Mail/MailMeta";
import { singleMailDuck } from "./SingleMailDuck";
import { Confirm, ModalConstants } from "components/OnlineModal";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import classes from './singlemail.module.scss';
import { endPoint } from "AppConstants";
import UserImg from "components/UserImg";

/**
 * SingleMailForm
 * @module features/General/SingleMail/SingleMailForm
 * @param {{
    mailData: any,
    handleDelete: Function,
    closeForm: Function,
}} props
 */
const SingleMailForm = ({ mailData, handleDelete, closeForm }) => {

    const { languageService: t } = useI18n();
    const dispatch = useDispatch();

    const [mailFormValues, setMailFormValues] = useState({});
    const [mailFormState, setMailFormState] = useState({});
    const [receiverSelected, setReceiverSelected] = useState(mailData.Receiver ? [mailData.Receiver] : []);
    const [haveReceiver, setHaveReceiver] = useState(receiverSelected[0] !== undefined);

    const [canSave, setCanSave] = useState(false);
    const [canSend, setCanSend] = useState(false);
    const [receiverSearchIsLoading, setReceiverSearchIsLoading] = useState(false);
    const [receiverOptions, setReceiverOptions] = useState([]);


    const handleFileDelete = async (id) => {
        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("delete"),
            message: (<div>{t.getText("file.delete.file.warning")}</div>),
            languageService: t,
            type: "yes/no"
        });

        if (result === ModalConstants.Yes) {
            dispatch(singleMailDuck.deleteAttachment(mailData.Id, id));
        }
    }

    const handleFileUploaded = (file) => {
        const data = {
            FileName: file.name,
            FileTempName: file.key

        }
        dispatch(singleMailDuck.addAttachment(mailData.Id, data));
    }

    const handleReceiverChange = (e) => {
        setReceiverSelected(e);
        if (e && e[0] && e[0].Id) {
            setHaveReceiver(true);
            setCanSend(mailFormState['isValid']);
            setCanSave(mailFormState['isValid']);

            dispatch(singleMailDuck.setJobReceiver(e[0]));
        } else {
            setCanSend(false);
            setCanSave(mailFormState['isValid']);
        }
    }

    const handleReceiverFilterBy = () => true;
    /*
    // We want AsyncTypeahead to return whatever the server provides,
    // thus no need to do any additional filtering
    const handleReceiverFilterBy = (option, props) => (
        option.FullName.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ||
        option.Mail.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
    );
    */
    const handleReceiverSearch = useCallback(async (query) => {

        setReceiverSearchIsLoading(true);

        const users = await singleMailDuck.getUsers(query);

        const options = users.map((i) => ({
            Mail: i.Mail,
            Initials: i.Initials,
            Id: i.Id,
            FullName: i.FullName
        }));

        setReceiverOptions(options);
        setReceiverSearchIsLoading(false);

    }, []);

    const handleSave = async () => {
        if (canSave) {
            const data = {
                AutoDelete: true,
                MailBody: mailFormValues['MailBody'],
                MailSubject: mailFormValues['MailSubject'],
                Note: mailFormValues['Note'],
                ReceiverId: receiverSelected[0] && receiverSelected[0].Id,
            }
            try {
                await dispatch(singleMailDuck.saveMail(mailData.Id, data));
                setCanSave(false);
                dispatch(singleMailDuck.setDirty(false));
            } catch (error) {
                console.error(error);
                dispatch(errorDuck.setError({ when: new Date(), header: t.getText('error'), message: t.getText('servererror') + '\n' + error }));
            }
        }
    }

    const handleSend = async () => {
        if (canSend) {
            const result = await Confirm({
                buttons: [],
                backdrop: 'static',
                class: null,
                title: t.getText("send"),
                message: (<div>{t.getText("mail.confirmsend")}</div>),
                languageService: t,
                type: "yes/no"
            });

            if (result === ModalConstants.Yes) {

                try {
                    await handleSave();
                    await dispatch(singleMailDuck.sendMail(mailData.Id));
                    // redirect to detail view
                    dispatch({ type: "GENERAL_SINGLE_MAIL_EDIT", payload: { mailid: mailData.Id, listtype: 'sent' } });

                } catch (error) {
                    console.error(error);
                    dispatch(errorDuck.setError({ when: new Date(), header: t.getText('error'), message: t.getText('servererror') + '\n' + error }));
                }
            };
        }
    }

    const renderReceiverMenuItemChildren = (option, props, index) => {
        const userImage = endPoint.GET_USER_IMAGE_URL(option.Id);
        return [
            <div key="Receiver" className="d-flex">
                <UserImg src={userImage} className={['rounded-circle', 'avatar-img', 'mr-4'].join(' ')} alt={t.getText('user')} />
                <div className="d-flex flex-column">
                    <span>
                        <Highlighter search={props.text}>
                            {option.FullName}
                        </Highlighter>
                    </span>
                    <small>
                        <Highlighter search={props.text}>
                            {option.Mail}
                        </Highlighter>
                    </small>
                </div>
            </div>
        ];
    }

    const updateStatus = (values, isSubmitting, isValid, dirty) => {
        setCanSave((!isSubmitting && isValid && dirty));
        setCanSend((!isSubmitting && isValid && haveReceiver));
        setMailFormValues(values);
        setMailFormState({ isSubmitting, isValid, dirty });
        dispatch(singleMailDuck.setDirty(dirty));
    }

    return (<>
        <div className={[classes['mail-form']].join(' ')}>
            <div className="form-group w-100">
                <label htmlFor="receiver-select">{t.getText('mail.reciever.singular')}</label>
                <div className="d-flex">
                    <div className="flex-fill">
                        <AsyncTypeahead
                            highlightOnlyResult={true}
                            filterBy={handleReceiverFilterBy}
                            id="receiver-select"
                            isLoading={receiverSearchIsLoading}
                            labelKey={(option) => `${option.FullName} - ${option.Mail}`}
                            minLength={3}
                            multiple={false}
                            onChange={handleReceiverChange}
                            onSearch={handleReceiverSearch}
                            options={receiverOptions}
                            placeholder={t.getText('mail.reciever.singular')}
                            renderMenuItemChildren={renderReceiverMenuItemChildren}
                            selected={receiverSelected}

                        />
                    </div>
                    {receiverSelected && (receiverSelected.length > 0) && <UserImg alt={receiverSelected[0].FullName} className="rounded-circle ml-3 avatar-img" src={endPoint.GET_USER_IMAGE_URL(receiverSelected[0].Id)} />}
                </div>
            </div>
        </div>

        <MailEditor fileUploaded={handleFileUploaded} handleFileDelete={handleFileDelete} mailData={mailData} updateStatus={updateStatus} showNote={false} />
        <div className={["pb-4 pt-4 border-top", classes['sticky-footer'], classes['mail-form']].join(' ')}>
            <div className="mb-2 mb-sm-4 d-flex">
                <div>
                    <button className="btn btn-primary btn-small mr-1 mr-sm-4"
                        disabled={!canSave}
                        onClick={() => handleSave()}
                        type="button">{t.getText('save.short')}</button>
                    <button className="btn btn-primary btn-small mr-1 mr-sm-4"
                        disabled={!canSend}
                        onClick={() => handleSend()}
                        type="button">{t.getText('send')}</button>
                    <button className="btn btn-inverse btn-small mr-1 mr-sm-4"
                        onClick={() => closeForm()}
                        type="button">{t.getText('close')}
                    </button>
                </div>
                <button className="btn btn-inverse btn-danger btn-small  ml-auto ml-2" type="button" onClick={() => handleDelete(mailData.Id)}>{t.getText('delete')} </button>
            </div>
            <MailMeta mailData={mailData} t={t} />
        </div>

    </>)

}
export default SingleMailForm;
