// @ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import TestService from "../test/TestService"



/**
 * @type import('./McTest').McTestState
 */
const initialState = {
  currentTest: {
    TestSessionID: undefined,
    Error: undefined,
    State: null,
    Tries: undefined,
    FirstQuestion: undefined,
    UpdatedTestDescription: undefined,
    TestId: undefined,
    ClassId: undefined,

  },
  currentMCQuestion: undefined,
  dirty: false,
  studyState: "notReady",
};


const mcTestSlice = createSlice({
  name: "mctest",
  initialState: initialState,
  reducers: {

    // }
    /**
     * 
     * @param {import('./McTest').McTestState} state 
     * @param {{payload: import('./McTest').MCTestStartDto}} action 
     */
    setCurrentMcTest(state, action) {
      state.currentTest = action.payload;
      state.studyState = "notReady";
      if (action.payload == null) {
        state.currentMCQuestion = undefined;
        state.dirty = false;
        
        return;
      }

      const mcTestData = {
        data: action.payload.FirstQuestion,
        WasCorrect: false,
        TotalCorrect: 0,
        TotalAnswered: 1,
        NextQuestion: undefined,
        pending: false,
        State: action.payload.State

      };
      state.currentMCQuestion = mcTestData;
      state.dirty = false;


    },

    /**
     * 
     * @param {import('./McTest').McTestState} state 
     */
    resetMcTest(state) {
      state.currentTest = null;
      state.currentMCQuestion = null;
      state.dirty = false;
      state.studyState = "notReady";
    },

    /**
     * 
     * @param {import('./McTest').McTestState} state 
     * @param {{payload: import('./McTest').StudyState}} action 
     */
    setStudyState(state, action) {
      state.studyState = action.payload;
    },


    /**
     * 
     * @param {import('./McTest').McTestState} state 
     * @param {{payload: import('./McTest').MCTestQuestionData}} action 
     */
    setCurrentMCQuestion(state, action) {
      state.currentMCQuestion = {
        ...state.currentMCQuestion,
        ...action.payload
      };

      state.currentTest.State = action.payload.State;
      state.studyState = "notReady";

    },

    /**
     * 
     * @param {import('./McTest').McTestState} state 
     * @param {{payload: boolean}} action 
     */
    setCurrentQuestionAnswerCorrect(state, action) {
      state.currentMCQuestion.WasCorrect = action.payload;
      state.studyState = action.payload ? "ok": "fail";
    },

    /**
     * 
     * @param {import('./McTest').McTestState} state 
     */
    setNextMCQuestion(state) {
      state.currentMCQuestion.data = state.currentMCQuestion.NextQuestion;
      state.currentMCQuestion.pending = false;
      state.studyState = "notReady";
      if (state.currentMCQuestion.data) {
        state.currentMCQuestion.data.CanPost = false;
        if (state.currentMCQuestion.data.Answers) {
          state.currentMCQuestion.data.Answers.forEach(a => a.Checked = false);
        }
      }
    },

    /**
     * 
     * @param {import('./McTest').McTestState} state 
     * @param {{payload: {index:number, state: boolean}}} action
     * 
     */
    setAnswerChecked(state, action) {

      state.currentMCQuestion.data.Answers.forEach(a => {
        if (a.Index === action.payload.index) {
          a.Checked = action.payload.state;
        }
        else if (!state.currentMCQuestion.data.CanSelectMultipleAnswers) {
          a.Checked = false;
        }
      });

      state.currentMCQuestion.data.CanPost = state.currentMCQuestion.data.Answers.filter(a => a.Checked).length > 0;

    },

    /**
    * 
    * @param {{payload: {index:number, state: boolean}}} action
    * 
    */
    clearAnswerChecked(state, action) {

      state.currentMCQuestion.data.Answers.forEach(a => {
        a.Checked = false;
      });

      state.currentMCQuestion.data.CanPost = state.currentMCQuestion.data.Answers.filter(a => a.Checked).length > 0;

    },



    /**
    * 
    * @param {{payload:{state:boolean}}} action
    */
    setDirtyState(state, action) {
      state.dirty = action.payload.state;
    },

    /**
   * 
   * @param {{payload:boolean}} action
   */
    setSavingToServer(state, action) {
      if (state.currentMCQuestion) {
        state.currentMCQuestion.savingToServer = action.payload;
      }
    },

    /**
  * 
  * @param {{payload:boolean}} action
  */
    setQuestionsPending(state, action) {
      state.currentMCQuestion.pending = action.payload;
    },

  }
});

const checkMultipleAnswers = () => async (dispatch, getState) => {

  /**
   * @type{ {mctest: import('./McTest').McTestState} }
   */
  const { mctest: mcState } = getState();
  if (mcState.currentMCQuestion.data.CanSelectMultipleAnswers) {
    checkStudyAnswers(dispatch, mcState);
  }


}

const checkStudyAnswers = async (dispatch, mcState) => {


  dispatch(mcTestDuck.setQuestionsPending(true));

  const checkedIndexes = mcState.currentMCQuestion.data.Answers.filter(a => a.Checked).map(a => a.Index);

  const testData = {
    classid: mcState.currentTest.ClassId,
    testid: mcState.currentTest.TestId,
    sessionid: mcState.currentTest.TestSessionID,
    index: mcState.currentMCQuestion.data.Index,
    choices: checkedIndexes
  };

  const isOK = await checkMcTest(testData);

  dispatch(mcTestDuck.setCurrentQuestionAnswerCorrect(isOK));
  if (isOK) {
    window.setTimeout(() => {
      dispatch(mcTestDuck.answerMcTest({ ...testData, moveToNext: true }));
    }, 2000);
  }
  else {
    window.setTimeout(() => {
      dispatch(mcTestDuck.setQuestionsPending(false));
      dispatch(mcTestDuck.clearAnswerChecked());


    }, 2000);
  }

}

/**
* 
* @param {{index:number, state: boolean}} payload
* 
*/
const handleAnswerChecked = (payload) => async (dispatch, getState) => {

  dispatch(mcTestDuck.setAnswerChecked(payload));

  /**
   * @type{ {mctest: import('./McTest').McTestState} }
   */
  const { mctest: mcState } = getState();
  if (mcState.currentTest.StudyMode && !mcState.currentMCQuestion.data.CanSelectMultipleAnswers) {
    checkStudyAnswers(dispatch, mcState);
  }

}

/**
* 
* @param {{
*            classid: string,
*            testid: string,
*            sessionid: string,
*            index: number,
*            choices: number[],
*            moveToNext?: boolean
 *       }} payload
* 
*/
const answerMcTest = (payload) => async (dispatch) => {

  dispatch(mcTestDuck.setSavingToServer(true));


  const url = endPoint.GET_SUBMIT_MCTEST_ANSW_URL(payload.classid, payload.testid, payload.sessionid);
  const data = { Index: payload.index, Choices: payload.choices, StudyMode: !!payload.moveToNext };

  try {
    /**
     * @type {{data:import('./McTest').MCTestQuestionData}}
     */
    const response = await instance.post(url, data);
    if (!response) {
      return;
    }
    await dispatch(mcTestDuck.setCurrentMCQuestion({ ...response.data, pending: true }));
    await dispatch(mcTestDuck.setDirtyState({ state: true }));

    if (response.data.Result?.StudyMode) {

      const url = endPoint.GET_SUBMIT_MCTEST_URL(payload.classid, payload.testid, payload.sessionid);
      try {
        await instance.post(url, {});
        await dispatch(mcTestDuck.setDirtyState({ state: false }));
        await TestService.updateTestStatus({ classId: payload.classid, testId: payload.testid }, dispatch);
        dispatch(mcTestDuck.setStudyState("submitted"));

        return;

      } catch (error) {
        handleErrors(error);
      }
    }
    await TestService.updateTestStatus({ classId: payload.classid, testId: payload.testid }, dispatch);


    if (payload.moveToNext) {
      dispatch(mcTestDuck.setNextMCQuestion());
    }



  } catch (error) {
    handleErrors(error);
    throw error;
  } finally {
    dispatch(mcTestDuck.setSavingToServer(false));
  }
}

/**
* 
* @param {{
*            classid: string,
*            testid: string,
*            sessionid: string,
*            index: number,
*            choices: number[]
*        }} payload
* 
*/
const checkMcTest = async (payload) => {

  const url = endPoint.GET_CHECK_MCTEST_ANSW_URL(payload.classid, payload.testid, payload.sessionid);
  const data = { Index: payload.index, Choices: payload.choices };

  try {
    /**
     * @type {import('axios').AxiosResponse<boolean>}
     */
    const response = await instance.post(url, data);
    if (!response) {
      return;
    }
    return response.data;

  } catch (error) {
    handleErrors(error);
    throw error;
  }
}


/**
 * 
 * @param {{classid:string, testid:string, sessionid:string, doNotUpdateGui?: boolean}} payload 
 */
const submitMcTest = (payload) => async (dispatch) => {
  const url = endPoint.GET_SUBMIT_MCTEST_URL(payload.classid, payload.testid, payload.sessionid);

  try {
    await instance.post(url, {});
    await dispatch(mcTestDuck.setDirtyState({ state: false }));
    if (!payload.doNotUpdateGui) {
      await TestService.getTestWithoutTypename({ dispatch, classid: payload.classid, testid: payload.testid });
    }
    await TestService.updateTestStatus({ classId: payload.classid, testId: payload.testid }, dispatch);


  } catch (error) {
    handleErrors(error);
  }

}

const handleErrors = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log(error.config);

}

const userClosedTest = ({ classid, testid, session }) => async dispatch => {
  const url = endPoint.GET_USER_LEFT_MCTEST_URL(classid, testid, session);
  const data = {};

  try {
    await instance.put(url, data);
    TestService.updateTestStatus({ classId: classid, testId: testid }, dispatch);

  } catch (error) {
    handleErrors(error);
  }
}


export const mcTestDuck = { ...mcTestSlice.actions, answerMcTest, submitMcTest, checkMultipleAnswers, handleAnswerChecked, userClosedTest };
export default mcTestSlice.reducer
