//@ts-check
import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { ErrorMessage } from '@hookform/error-message';
import UserImg from "components/UserImg";
import { endPoint } from "AppConstants";
import { useI18n } from "components/lni18n";
import useTypedSelector from "utils/useTypedSelector";
import FileUpload from "components/FileUpload/FileUpload";

import { forumService } from "./ForumDuck";
import classes from './forum.module.scss';
import { DropdownMenu, UncontrolledCollapse, DropdownItem } from "reactstrap";
import { useEffect } from "react";
import LnDropDownMenu from "components/LnDropDownMenu";
import { useStore } from "react-redux";
import { debouncer } from "utils/debouncer";
import LnIcon from "components/LnIcon";
import { fileUploadDuck } from "components/FileUpload/FileUploadDuck";

/**
 * ForumForm
 * @module features/classes/ClassItems/Forum/ForumForm
 * @return a forum form.
 * @param {{
    toggleForm: any,
    replyTo:number,
    forumId:string,
    hideCancel?: boolean,
    formType: string,
    fileOptions?: {AcceptFileTypes: string, MaxFileSize: number, videoFileTypes: string, audioFileTypes: string, imageFileTypes:string},
    isTeacher: boolean,
    onPostCreated: ()=>void,
}} props
 */



const ForumForm = (props) => {

    const { languageService: t } = useI18n();
    const dispatch = useDispatch();
    const store = useStore();

    const { imagePreviews, uploadedFile } = useTypedSelector(state => state.fileUpload);

    const debouncerRef = useRef(null);
    const draftIdRef = useRef(null);

    const user = useTypedSelector(state => state.login.user);
    const userImage = endPoint.GET_USER_IMAGE_URL(user.Id);
    const [isSubmitting, setIsSubmitting] = useState(false);


    const { replyTo, forumId, fileOptions, toggleForm, isTeacher, onPostCreated } = props;
    const isThreadForm = props.formType === 'thread';


    const [testsToConnect, setTestsToConnect] = useState(null);
    const [connectedTest, setConnectedTest] = useState(null);



    useEffect(() => {
        if (!forumId) {
            return;
        }

        if (replyTo === null && isTeacher) {
            const getTests = async (id) => {
                var tests = await forumService.getTestsToConnect(id);
                if (tests && tests.length > 0) {
                    setTestsToConnect(tests);
                }
            }

            getTests(forumId);
        }
    }, [forumId, replyTo, isTeacher])

    /**
     * @type{ {article: string, header?: string} }
     */
    const initialValues = {
        article: '',
        //header: ''
    };

    let validationSchema;

    if (isThreadForm) {
        initialValues['header'] = '';
        validationSchema = Yup.object({
            article: Yup.string()
                .required(t.getText('servermessage.empty')),
            header: Yup.string()
                .required(t.getText('emptyheaderwarning')),
        })
    } else {
        validationSchema = Yup.object({
            article: Yup.string()
                .required(t.getText('servermessage.empty')),
        })
    }


    const clearFile = () => {
        dispatch(fileUploadDuck.setFile(null));
        dispatch(fileUploadDuck.setImagePreviews(null));
    };


    const handleSubmitForm = async (values, setSubmitting) => {
        setIsSubmitting(true);
        const postData = {
            article: values.article,
            fileName: uploadedFile.name,
            fileTempName: uploadedFile.key,
            header: values.header
        }
        try {
            if (isThreadForm) {
                postData.ConnectTotest = connectedTest?.Id;
                await forumService.createThread(forumId, postData, dispatch);
                toggleForm();

            } else {
                postData['replyTo'] = replyTo;
                await forumService.createReply(forumId, postData, dispatch, store.getState);
                toggleForm();
                onPostCreated();
            }

            forumService.deleteDraft(draftIdRef.current);
            draftIdRef.current = undefined;
        } finally {
            setIsSubmitting(false);
        }

    }


    const { register, handleSubmit, errors, watch, getValues, setValue } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues,
        mode: "onBlur"
    });

    const watchAll = watch();

    // at changes
    useEffect(() => {
        if (debouncerRef.current) {
            debouncerRef.current.debounce();
        }
    }, [watchAll, uploadedFile.key]);


    const upDateDraft = useCallback(() => {
        const values = getValues();
        const { uploadedFile } = store.getState().fileUpload
        /**
         * @type{ import("./Forum").ForumDraft }
         */
        const data = {
            file: uploadedFile,
            id: draftIdRef.current,
            forumId: forumId,
            replyTo: replyTo,
            article: values.article,
            header: values.header,
            userId: user.Id
        }
        forumService.saveDraft(data);

    }, [getValues, replyTo, user, forumId, store]);


    // at upstart
    useEffect(() => {
        if (user && forumId) {
            forumService.getDraft({ forumId, replyTo, userId: user.Id })
                .then(data => {
                    if (data) {
                        setValue("article", data.article);
                        setValue("header", data.header);
                        draftIdRef.current = data.id;
                        if (data.file && data.file.key) {
                            dispatch(fileUploadDuck.setFile(data.file));
                        }
                    }
                });

        }
    }, [setValue, user, forumId, replyTo, dispatch]);

    useEffect(() => {
        const d = new debouncer(() => upDateDraft(), 300);
        debouncerRef.current = d;
        return () => d.clear();
    }, [upDateDraft])



    return (

        <div className="pl-2 d-flex">
            <UserImg src={userImage} className={['rounded-circle', 'avatar-img', classes['answer-avatar']].join(' ')} alt={t.getText('user')} />
            <form onSubmit={handleSubmit(handleSubmitForm)} className={["ml-2 mb-5", classes['forum-form']].join(' ')}>
                <div>
                    <FileUpload className="p-3" imagePreviewOptions={[{ name: "small", maxheight: 100, maxwidth: 100 }]} handleUploadReady={() => { }} accept={fileOptions.AcceptFileTypes} languageService={t} maxsize={fileOptions.MaxFileSize}
                        postchildren={<>
                            <button className="btn btn-primary btn-small" id="togglerFiletext">
                                {t.getText("more.information")}
                            </button>

                            <UncontrolledCollapse toggler="#togglerFiletext">
                                <div className="preserve-white">
                                    <small>{t.getText("forum.attachfile.explain.2", fileOptions.imageFileTypes, fileOptions.audioFileTypes, fileOptions.videoFileTypes)}</small>
                                </div>
                            </UncontrolledCollapse>

                        </>} >
                        {isThreadForm && <div className="form-group mb-4"
                        >
                            <label htmlFor="header">{t.getText('title')}</label>
                            <input ref={register}
                                name="header"
                                className="form-control"
                            />
                            <ErrorMessage as="div" errors={errors} className="errorMessage" name="header" />
                        </div>}

                        <div className="form-group mb-4"
                        >
                            <label htmlFor="article">{t.getText(isThreadForm ? 'posting' : 'submission')}</label>
                            <textarea ref={register}
                                name="article"
                                className="form-control"
                                rows={6}
                            />
                            <ErrorMessage as="div" errors={errors} className="errorMessage" name="article" />
                        </div>

                        {testsToConnect &&
                            <div className="form-group mb-4">
                                <label>{t.getText("forum.connect.to.test")}</label>
                                <div className="card">
                                    <div className="d-flex card-body flex-wrap">
                                        <LnDropDownMenu
                                            direction="down"
                                            caret={false}
                                            toggleComponent={
                                                <button type="button" className="btn btn-primary btn-small" title={t.getText("tests")} >
                                                    {t.getText("tests")} <span className="ml-2 mt-2 white-caret"></span>
                                                </button>
                                            }
                                            toogleClassName=""
                                            className="mx-3 mb-3"
                                            toogleTagName="div"
                                        >
                                            <DropdownMenu className="" container="body">
                                                <div className="d-flex flex-column">
                                                    <DropdownItem onClick={() => setConnectedTest(null)} key="none" tag="span" >{t.getText("none")}</DropdownItem>
                                                    <DropdownItem divider />
                                                    {testsToConnect.map(test => <DropdownItem onClick={() => setConnectedTest(test)} key={test.Id} tag="span" >{test.Name}</DropdownItem>)}
                                                </div>
                                            </DropdownMenu>
                                        </LnDropDownMenu>

                                        <div>{connectedTest === null ? t.getText("no_test_assigned")
                                            :
                                            <div className="d-flex flex-column">
                                                <strong>{connectedTest.Name}</strong>
                                                <div className="preserve-white">{t.getText("forum.connect.test.explain")}</div>
                                            </div>
                                        }
                                        </div>
                                        <div className="btn-group ml-auto ">
                                            <a href="https://static.laranara.se/manuals/forumtest-sv.pdf" rel="noopener noreferrer" target="_blank" className="btn btn-small btn-primary">
                                                {t.getText("manual")} SV
                                            </a>
                                            <a href="https://static.laranara.se/manuals/forumtest-en.pdf" rel="noopener noreferrer" target="_blank" className="btn btn-small btn-primary">
                                                {t.getText("manual")} ENG
                                            </a>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        }

                        {uploadedFile && uploadedFile.name && <div className="d-flex">
                            <div className="alert alert-warning border">
                                <strong>{t.getText("file")}:</strong> {uploadedFile.name}&nbsp;<button title={t.getText("remove")} onClick={clearFile} type="button" className="btn btn-link">
                                    <LnIcon name="delete" className="mt-1 mr-1 ml-3 icon-small " />
                                </button>
                            </div>
                        </div>
                        }
                        {imagePreviews ? <div>
                            <img src={imagePreviews.small.src} alt={uploadedFile.name} />
                        </div> : null
                        }

                    </FileUpload>
                </div>

                <div className="mb-4 mt-4 d-flex justify-content-end">
                    <button className="btn btn-primary btn-small mr-4"
                        disabled={isSubmitting}
                        type="submit">{t.getText('save.short')}</button>
                    {!props.hideCancel &&
                        <button className="btn btn-inverse btn-small" type="button" onClick={() => toggleForm()}>{t.getText('cancel')} </button>
                    }
                </div>
            </form>

        </div>

    )

}
export default ForumForm;
