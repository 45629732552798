//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint } from "AppConstants";

/**
 * @type {import('./Config').ConfigState}
 */
const initialState = {
    mailConfig: null,
    lectureConfig: {
        screenWidth : window.screen.width,
        screenHeight : window.screen.height,
        pixelDensity : window.devicePixelRatio
    }
};

const configSlice = createSlice({
    name: 'config',
    initialState: initialState,
    reducers: {

        /**
        * @param  {  {payload:  import('./config').ConfigState}} action
        */
        setConfigs(state, action) {
            state.mailConfig = action.payload.mailConfig;
            state.lectureConfig = action.payload.lectureConfig;
        },
        
    }
});


const getConfigs = async (dispatch) => {

    /**
 * @type {{data: import('./Config').ConfigState}}
 */
    const response = await instance.get(endPoint.GET_CONFIGDATA_URL);

    if( !response){
        return;
    }

    response.data.lectureConfig = {
        screenWidth : window.screen.width,
        screenHeight : window.screen.height,
        pixelDensity : window.devicePixelRatio
    }

    dispatch(configDuck.setConfigs(response.data));
}


export const configDuck = {
    ...configSlice.actions, getConfigs
};
export default configSlice.reducer;