//@ts-check
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Draggable from "react-draggable"
import { lectureDuck } from "./LectureDuck";
import useTypedSelector from "utils/useTypedSelector";
import { useI18n } from "components/lni18n";
import RunTest from "../test/RunTest";
import { courseDuck } from "features/course/CourseDuck";
import { ModalFooter } from "reactstrap/lib";

const TestInLectureDialog = (props) => {

    const dispatch = useDispatch();
    const { languageService: t } = useI18n();
    const { showTestDialog } 
            = useTypedSelector(state => state.lecture);

  
    useEffect(()=>{
        dispatch(courseDuck.setTestInDialog(true));
        var d = dispatch;
        return ()=>{
            d(courseDuck.setTestInDialog(false));
        }
    }, [dispatch])

    const closeTestDialog = () => {
        dispatch(lectureDuck.setShowTestDialog(false));
    }


    return (

        <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>
            <Modal container={props.container} role="dialog" isOpen={showTestDialog} onClosed={closeTestDialog} toggle={closeTestDialog} size="lg" backdrop='static'>

                <ModalHeader toggle={closeTestDialog} />
                <ModalBody className={""} tabIndex={-1}>
                    <RunTest />
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary btn-small" type="button" onClick={closeTestDialog} >{t.getText("close")}</button>
                </ModalFooter>

            </Modal>
        </Draggable>
    )
}
export default TestInLectureDialog;
