//@ts-check
import React from "react";
import UserImg from "components/UserImg";
import { endPoint } from "AppConstants";
import FileIcon from "components/FileIcons/FileIcon";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import classes from '../forum.module.scss';
import ShowImage from "../ShowImage";
import ThreadAnswer from "./ThreadAnswer";

/**
* @param {{currentThread : import('../Forum').ForumThreadDto}} props
*/

const ThreadView = ({ currentThread }) => {
    const { languageService: t } = useI18n();

    return (
        <>
          

            <div className="card-body pt-5 ">


                <div className={["position-relative card mb-5", classes['forum-card']].join(' ')}>

                    <div className="d-flex justify-content-between pr-5">

                        <div className={"avatar-wrapper"}>
                            {currentThread.Writer.ImageUrl ? <UserImg src={endPoint.API_HOST + currentThread.Writer.ImageUrl} className={['rounded-circle m-4', 'avatar-img'].join(' ')} alt={currentThread.Writer.FullName} /> : <LnIcon className={['rounded-circle m-4', 'avatar-img'].join(' ')} name="user-icon" />}
                        </div>
                        <div className="flex-grow-1 pl-4">
                            <div className={['card-title d-flex justify-content-between mt-4', classes['forum-head']].join(' ')}>
                                <h5 className={classes['forum-title']}>
                                    {currentThread.Writer.FullName}
                                </h5>
                                <div className="d-flex">
                                    <small className="d-flex flex-row flex-sm-column align-items-end ">
                                        <div>
                                            {t.getShortDateTimeString(currentThread.Created)}
                                        </div>
                                        {currentThread.Edited && <>
                                            <div>{t.getText("edited")} </div>
                                            <div>{t.getShortDateTimeString(currentThread.Edited)}</div></>}
                                    </small>

                                </div>

                            </div>
                            <div className={[classes['forum-text-wrapper']].join(' ')}>
                                <div className="d-flex flex-wrap flex-md-nowrap">
                                    {(currentThread.Attachment)
                                        && <div className="mr-4">
                                            {
                                                currentThread.Attachment.MediaType === 1 ?
                                                    <button type="button" className="btn btn-link" onClick={() => ShowImage(endPoint.API_HOST + currentThread.Attachment.Url, currentThread.Attachment.FileName, t)}>
                                                        <img src={endPoint.API_HOST + currentThread.Attachment.ThumbUrl} alt={currentThread.Attachment.FileName} />
                                                    </button>
                                                    : <a href={endPoint.API_HOST + currentThread.Attachment.Url}>
                                                        <FileIcon className={[classes['file-icon']].join(' ')} size="512px" name={(currentThread.Attachment.FileName || '').split('.').pop()} /><br />
                                                        {currentThread.Attachment.FileName}
                                                    </a>
                                            }
                                        </div>}
                                    <div className="mb-3 flex-grow-1">
                                        <div  className="word-break-word infocard-text max-col-width" dangerouslySetInnerHTML={{ __html: currentThread.Article }}></div>

                                        {(currentThread.Attachment && currentThread.Attachment.MediaType === 3) &&
                                            <audio controls={true} src={endPoint.API_HOST + currentThread.Attachment.Url}></audio>
                                        }

                                        {(currentThread.Attachment && currentThread.Attachment.MediaType === 2) &&
                                            <video className="mt-3" controls={true} src={endPoint.API_HOST + currentThread.Attachment.Url} ></video>
                                        }

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    
                    {currentThread.Answers.length ? <div className="mt-3">
                        {currentThread.Answers.map(a => (
                            <ThreadAnswer key={a.Id} answer={a} />
                        ))}
                    </div> : <div></div>}


                </div>

            </div>
        </>
    )

}


export default ThreadView;