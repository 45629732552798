//@ts-check
import { createSlice } from "@reduxjs/toolkit";
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import TestService from "../test/TestService";
import dispatchService from "utils/DispatchService";

/**
 * @type import('./MQATest').MultiQATestState
 */
const initialState = {
  currentTest: undefined,
  canSubmit: false,
  showSubmit: false,
  makeStatusUpdate: null,
  savingToserver: false,
};

const mqaTestSlice = createSlice({
  name: "mqatest",
  initialState: initialState,
  reducers: {


    /**
     * 
     * @param {import('./MQATest').MultiQATestState} state 
     * @param {{ payload: import('../QATest/QATest').QATestData }} action 
     */
    setCurrentMQATest(state, action) {
      state.currentTest = action.payload;
      state.makeStatusUpdate = null;

      if (action.payload != null) {
        const { canSubmit, showSubmit } = getCanSubmit(state.currentTest.Questions);
        state.canSubmit = canSubmit;
        state.showSubmit = showSubmit;
        if (state.canSubmit) {
          state.makeStatusUpdate = false;
        }
      }
    },

    /**
     * 
     * @param {*} action 
     */
    resetMQATest(state, action) {
      state.currentTest = null;
      state.canSubmit = false;
    },

    /**
     * 
     * @param {{payload: boolean}} action 
     */
    setUpdateFlag(state, action) {
      state.makeStatusUpdate = action.payload;
    },

    /**
     * @param {import('./MQATest').MultiQATestState} state 
     * @param {{payload: {index: number, reply: string, classid: string, testid: string}}} action 
     */
    setQuestionReply(state, action) {

      /**
       * @type {import('../QATest/QATest').QATestData}
       */
      const current = state.currentTest;
      if (current == null) {
        return;
      }

      const question =
        current.Questions[action.payload.index];

      if (question.Reply == null && state.makeStatusUpdate == null) { // first time to update state for this question
        state.makeStatusUpdate = true
      }
      question.Reply = action.payload.reply;

      let gotText = false;

      const answers = current.Questions.map((q, Index) => {
        gotText = gotText || (q.Reply != null && q.Reply.length > 0);
        return { Index: Index, Reply: q.Reply, Edit: q.Edit };
      }).filter(a => a.Edit).map(a => { return { Index: a.Index, Reply: a.Reply } });
      const { canSubmit, showSubmit } = getCanSubmit(current.Questions);
      state.canSubmit = canSubmit;
      state.showSubmit = showSubmit;

      if (gotText) {
        saveMqaAnswerAtServer(state.makeStatusUpdate, {
          answers: answers,
          classid: action.payload.classid,
          testid: action.payload.testid
        });
      }
    },

    /**
    * 
    * @param {{payload: boolean}} action 
    */
    setSavingToserver(state, action) {
      state.savingToserver = action.payload;
    },

  }

});


/**
 * @param {import('../QATest/QATest').QAQuestionDto[]} questions
 * @returns { {canSubmit:boolean, showSubmit: boolean}}
 */
const getCanSubmit = (questions) => {

  const filtered = questions.filter(q => q.Edit);
  if (!filtered || filtered.length === 0) {
    return { canSubmit: false, showSubmit: false };
  }

  let can = true;
  filtered.filter(q => q.Edit).forEach(q => {
    can = can && q.Reply != null && q.Reply.length > 2;
  })
  return { canSubmit: can, showSubmit: true };
};

/**
 * Call this on component unload
 */
 const clearOnExit = ()=>  {
  if (timerId != null) {
    window.clearTimeout(timerId);
    timerId = null;
  }
 }

let timerId = null;

/**
 * @param  { boolean } makeStatusUpdate
 * @param { {  answers: any[],  classid: string,  testid: string}} data 
 */
const saveMqaAnswerAtServer = (makeStatusUpdate, data) => {
  if (timerId != null) {
    window.clearTimeout(timerId);
    timerId = null;
  }
  timerId = window.setTimeout(async () => {
    const submitData = {
      Answers: data.answers,
      Saveonly: true
    };
    const url = endPoint.GET_SUBMIT_MQATEST_URL(data.classid, data.testid);
    await instance.post(url, submitData);
    timerId = null;

    if (makeStatusUpdate === true) {
      dispatchService.dispatch(MQATestDuck.setUpdateFlag(false));
      TestService.updateTestStatus({ classId: data.classid, testId: data.testid }, dispatchService.dispatch);
    }


  }, 1000);
};

/**
 * 
 * @param {{classid:string, testid:string, saveonly:boolean}} payload 
 */
export const submitMQATest = (payload) => async (dispatch, getstate) => {


  if (!payload.saveonly) {
    dispatch(MQATestDuck.setSavingToserver(true));
  }

  /**
   * @type import('../QATest/QATest').QAQuestionDto[]
   */
  const questions =
    getstate().mqatest.currentTest.Questions;

  if (timerId != null) {
    window.clearTimeout(timerId);
    timerId = null;
  }

  let gotText = true;
  const answers = questions.map((q, Index) => {
    gotText = gotText && q.Reply && q.Reply.length > 0;
    return { Index: Index, Reply: q.Reply, Edit: q.Edit };
  }).filter(a => a.Edit).map(a => { return { Index: a.Index, Reply: a.Reply } });

  if (gotText) {
    const submitData = {
      Answers: answers,
      Saveonly: payload.saveonly
    };
    const url = endPoint.GET_SUBMIT_MQATEST_URL(
      payload.classid,
      payload.testid
    );

    try {
      await instance.post(url, submitData);
      TestService.updateTestStatus({ classId: payload.classid, testId: payload.testid }, dispatch);
      if (payload.saveonly === false) {
        await TestService.getTest(dispatch, { classid: payload.classid, testtype: "multipleqatest", testid: payload.testid });
      }
    }
    finally {
      dispatch(MQATestDuck.setSavingToserver(false));
    }
  }
};


export const MQATestDuck = { ...mqaTestSlice.actions, submitMQATest, clearOnExit }

export default mqaTestSlice.reducer;
