//@ts-check
import { connectRoutes } from "redux-first-router";

import { configureStore, getDefaultMiddleware, combineReducers } from "@reduxjs/toolkit";

import { browserLanguage } from "AppConstants";
import loginDataReducer from "./features/login/LoginDuck";

import classMailReducer from "./features/classes/ClassItems/ClassMail/ClassMailDuck";
import classFilesReducer from "./features/classes/ClassItems/ClassFiles/ClassFilesDuck";
import classesReducer from "./features/classes/ClassesDuck";
import configReducer from "./utils/Config/ConfigDuck";
import courseReducer from "./features/course/CourseDuck";
import confirmTestReducer from "./features/course/part/ConfirmTest/ConfirmTestDuck";
import docBoxReducer from "./features/General/DocBox/DocBoxDuck";
import drawingTestReducer from "./features/course/part/DrawingTest/DrawingTestDuck";
import errorsReducer from "./components/ErrorHandler/ErrorHandlerDuck";
import feedReducer from "./features/classes/ClassItems/Feed/FeedDuck";
import filetestReducer from "./features/course/part/FileTest/FileTestDuck";
import fileUploadReducer from "./components/FileUpload/FileUploadDuck";
import forumReducer from "./features/classes/ClassItems/Forum/ForumDuck";
import imagePickerReducer from "./components/ImagePicker/ImagePickerDuck";
import layoutReducer from "./features/layout/LayoutDuck";
import lectureReducer from "./features/course/part/Lectures/LectureDuck";
import mcTestReducer from "./features/course/part/McTest/McTestDuck";
import mqaTestReducer from "./features/course/part/MQATest/MQATestDuck";
import networkReconnectReducer from "./features/network/NetworkReconnectDuck";
import permissionsReducer from "./features/Permissions/PermissionDuck";
import singleMailReducer from "features/General/SingleMail/SingleMailDuck";
import qaTestReducer from "./features/course/part/QATest/QATestDuck";
import routeOptions from "utils/RouteOptions";
import textReducer from "./features/language/LangageDuck";
import timetableReducer from "./features/classes/ClassItems/TimeTable/TimeTableDuck";
import userReducer from "./features/User/UserDuck";
import searchReducer from "./features/search/SearchDuck";
import d3GraphReducer from "./ducks/d3Graph/D3GraphDuck";
import textData from "utils/TextData";


import { routePaths } from "./routes";

export default function configureAppStore(clean) {


    const {
        reducer: location,
        middleware: routerMiddleware,
        enhancer: routerEnhancer
    } = connectRoutes(routePaths, routeOptions);

    let staticReducers = {
        classData: classesReducer,
        classFiles: classFilesReducer,
        classMail: classMailReducer,
        confirmTest: confirmTestReducer,
        course: courseReducer,
        config: configReducer,
        drawingTest: drawingTestReducer,
        docbox: docBoxReducer,
        d3graph: d3GraphReducer,
        errors: errorsReducer,
        feed: feedReducer,
        filetest: filetestReducer,
        fileUpload: fileUploadReducer,
        forum: forumReducer,
        imagepicker: imagePickerReducer,
        layout: layoutReducer,
        lecture: lectureReducer,
        location: location,
        login: loginDataReducer,
        mctest: mcTestReducer,
        mqatest: mqaTestReducer,
        networkReconnect: networkReconnectReducer,
        permissions: permissionsReducer,
        qatest: qaTestReducer,
        search: searchReducer,
        singleMail: singleMailReducer,
        text: textReducer,
        timetable: timetableReducer,
        user: userReducer,
    };

    let store = null;

    if (!clean) {


        let text = {};
        let currentLang = {};
        if (browserLanguage) {
            const lang = "x" + browserLanguage.substr(0, 2).toLowerCase();
            text = textData.text[lang];
            currentLang = textData.languages.find(l => l.Short === lang);
        }
        else {
            text = textData.text.xen;  // English default
            currentLang = textData.languages[1];
        }

        let preloadedState = { text: { languages: textData.languages, text: text, currentLang: currentLang } };

        store = configureStore({
            reducer: staticReducers,
            middleware: [
                ...getDefaultMiddleware({ serializableCheck: false }),
                routerMiddleware,

                // save({
                //     debounce: 1000,
                //     states: ["login"]
                // })
            ],
            preloadedState: preloadedState,
            enhancers: defaultEnhancers => [routerEnhancer, ...defaultEnhancers]
        });



    } else {
        store = configureStore({
            reducer: staticReducers,
            middleware: getDefaultMiddleware({ serializableCheck: false })
        });
    }


    // Add a dictionary to keep track of the registered async reducers
    store["asyncReducers"] = {}

    // Create an inject reducer function
    // This function adds the async reducer, and creates a new combined reducer
    store["injectReducer"] = (key, asyncReducer) => {
        store.asyncReducers[key] = asyncReducer
        store.replaceReducer(createReducer(store.asyncReducers, staticReducers))
    }

    return store;
}

function createReducer(asyncReducers, staticReducers) {
    return combineReducers({
      ...staticReducers,
      ...asyncReducers
    })
  }

