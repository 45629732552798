//@ts-check
import { createSlice } from "@reduxjs/toolkit";

/**
 * @type import('./FileUploader').FileUploadState
 */
const initialState = {
    uploadedFile: {},
    isUploading: false,
    percentUploaded: 0,
    errorMess: null,
    imagePreviews: null,
};

const fileUploadSlice = createSlice({
    name: "fileUpload",
    initialState: initialState,
    reducers: {

        /**
         * @param {{payload: any}} action 
         */
        startUpload(state, action) {
            state.isUploading = true;
            state.percentUploaded = 0;
            state.errorMess = null;
        },

        /**
         * @param {{payload: import('./FileUploader').UploadedFile}} action 
         */
        setFile(state, action) {
            if (action.payload) {
                state.uploadedFile = action.payload;
            }
            else {
                state.uploadedFile = {};
            }
        },


        /**
         * @param {{payload: boolean}} action 
         */
        setUploading(state, action) {
            state.isUploading = action.payload;
        },

        /**
         * @param {{payload: string}} action 
         */
        setError(state, action) {
            state.errorMess = action.payload;
        },

        /**
         * @param {{payload: number}} action 
         */
        setPercentage(state, action) {
            state.percentUploaded = action.payload;
        },

        /**
         * @param {{payload: import("types/types").Dictionary<import("./FileUploader").imagePreviewData>}} action 
         */
        setImagePreviews(state, action) {
            state.imagePreviews = action.payload;
        },

    }
});
export const fileUploadDuck = { ...fileUploadSlice.actions }
//export const { setFile, setUploading, setError, setPercentage, startUpload, setImagePreviews } = fileUploadSlice.actions;
export default fileUploadSlice.reducer;
