//@ts-check
import React,{useState} from "react";
import WaitRipple from "components/WaitRipple"
let StudentsTable = null;

const StudentsTableStub = (props) => {

    const [ready, setReady ] = useState(false);

    import("teacherFeatures").then(features => {
        StudentsTable = features.StudentsTable
        setReady(true);
    });

    if (!ready) {
        return <WaitRipple />;
    }
    else {
        return <StudentsTable />
    }
}

export default StudentsTableStub;
