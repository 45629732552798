//@ts-check
import React from "react";
import { Confirm, ModalConstants } from "components/OnlineModal";
import LanguageService from "features/language/languageService";
import restoreScroll from 'redux-first-router-restore-scroll'
import { layoutDuck } from "features/layout/LayoutDuck";
import { anonPaths } from "AppConstants"

const routeOptions = {

  onBeforeChange: (dispatch, getState, bag) => {

    var state = getState();
    const location = state.location;


    if (  !anonPaths.find(p=> p === bag.action.type ) ) {
      if ((bag.action.type !== "LOGIN") && (!state.login.user || !state.login.loggedIn)) {
        dispatch({ type: "LOGIN" });
      }
    }


    if (bag.action.type !== location.type) {
      dispatch(layoutDuck.setLastFeature({ type: location.type, payload: location.payload }))
    }

   

  },

  onAfterChange: (dispatch, getState, bag) => {
    window.setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
  },

  restoreScroll: restoreScroll(),

  /**
   * @param {{text: string, type: "stayOnYes" | "leaveOnYes"}} data
   */
  displayConfirmLeave: (data, callback) => {
    console.log(data);
    Confirm({
      backdrop: 'static',
      class: "",
      languageService: LanguageService,
      message: <div className="preserve-white">{data.text}</div>,
      title: LanguageService.getText("note"),
      type: "yes/no",
      buttons: undefined,
      okOnEnter: true,

    }).then((res) => {
      if (res === ModalConstants.Yes) {
        callback(data.type === "stayOnYes" ? false : true);
      }
      else {
        callback(data.type === "stayOnYes" ? true : false);
      }
    });
  }
};

export default routeOptions;
