import { render, unmountComponentAtNode } from 'react-dom';

const DEFAULT_DESTRUCTION_DELAY = 300;
const DEFAULT_OPTIONS = {
    destructionDelay: DEFAULT_DESTRUCTION_DELAY,
};

const noop = () => { };

export default function reactModal(renderModal, options = {}) {
    const { destructionDelay } = { ...DEFAULT_OPTIONS, ...options };
    const container = document.createElement('div');
    const parentToModal = document.body;
    parentToModal.appendChild(container);



    if(  options.keyHandler != null)
    {
        window.addEventListener("keydown", options.keyHandler);
    }

    function displayModal({ onSubmit, onDismiss }) {
        if( options.getCloseCallback != null && typeof options.getCloseCallback === "function" )
        {
            options.getCloseCallback(onSubmit);
        }



        render(renderModal({ onSubmit, onDismiss, show: true }), container);
    }

    function hideModal({ onSubmit, onDismiss }, callback) {
        render(renderModal({ onSubmit, onDismiss, show: false }), container, callback);
    }

    function destroyModal() {
        unmountComponentAtNode(container);
        parentToModal.removeChild(container);
    }

    const confirmation = new Promise((resolve) => {
        const onSubmit = (value = true) => {
            resolve(value);
        }
        const onDismiss = () => {
            resolve(undefined);
        }
        displayModal({ onSubmit, onDismiss });
    });

    return confirmation["finally"](() => {
        const onSubmit = noop;
        const onDismiss = noop;

        hideModal({ onSubmit, onDismiss }, () => {
            if(  options.keyHandler != null)
            {
                window.removeEventListener("keydown", options.keyHandler);
            }

            setTimeout(destroyModal, destructionDelay);
        });
    });
}