import React from "react";
import { UncontrolledAlert } from "reactstrap";
import { TestResultStatus } from "../test/TestEnums";

const TestSubmitted = (props) => {

    switch (props.status) {
        case TestResultStatus.Passed:
        case TestResultStatus.RecievedByTeacher:
        case TestResultStatus.UnderReview:
        case TestResultStatus.ApprovedByTeacherButCanbeBetter:
        case TestResultStatus.ApprovedByTeacher:
        case TestResultStatus.ApprovedWithExcellence:
        case "approved-excellence":
            return <UncontrolledAlert className="mt-3 max-col-width" color="success">
                <div>{props.languageService.getText("test.finished")}</div>
            </UncontrolledAlert>

        default:
            return null;


    }


}
export default TestSubmitted;