//@ts-check
import React from "react";
import { DropdownMenu, DropdownItem } from 'reactstrap';
import LnDropDownMenu from "components/LnDropDownMenu";
import Link from "redux-first-router-link";
import classes from './layout.module.scss';

/**
 * 
 * @param {{currentClass: import("features/classes/Classes").ClassAllDataDto, userClasses: import("types/types").IdAndNameDto[]}} props
 */
const ClassesMenuStudent = ({currentClass, userClasses}) => {

    let classesMenu;

    if (userClasses && currentClass) {

        if (userClasses.length > 1) {

            const classesInOrder = userClasses.map(c=>c).sort( (c1, c2) =>  {
                if (c1.Name === c2.Name) {
                    return 0;
                }
                return c1.Name > c2.Name ? 1 : -1;
            } );

            classesMenu = (<LnDropDownMenu
                caret={false} direction="down"
                toggleComponent={<div className="d-flex align-content-center">
                    <h2 className="overflow-y-hidden ml-4 mr-2">{currentClass.Name}</h2>
                    <span className={"white-caret"}></span>
                </div>}
                toogleClassName={[classes.currentClassToggle, "btn btn-link"].join(' ')}
                toogleTagName="div"
            >
                <DropdownMenu className={classes.dropDownMenu} tag="ul">
                    {
                        classesInOrder.map(f => {
                            if (f.Id !== currentClass.Id) {
                                return (
                                    <DropdownItem key={f.Id} tag="div"  >
                                        <Link className="px-4 pt-1 pb-2" to={{ type: "CLASS", payload: { classid: f.Id } }}>{f.Name}</Link>
                                    </DropdownItem>)
                            }
                            else {
                                return null;
                            }
                        })
                    }
                </DropdownMenu>
            </LnDropDownMenu>);
        }
        else {
            classesMenu = (<h2 className={"overflow-y-hidden " + classes.oneClass}>{currentClass.Name}</h2>);
        }
    }
    else {
        classesMenu = <div>&nbsp;</div>;
    }

    return classesMenu;

}

export default ClassesMenuStudent;
