//@ts-check
import React from "react";
import { useDispatch, shallowEqual } from "react-redux";
import { userDuck } from "./UserDuck";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";

import classes from "./user.module.scss";
import { TestResultStatus } from "features/course/part/test/TestEnums";
import AllResultsTable from "./AllResultsTable";

const UserTestsWatchedLectures = ({ userData, t }) => {

    const dispatch = useDispatch();

    const { userProgress } = useTypedSelector(state => state.user);
    const user = useTypedSelector(state => state.login.user);
    const { classes: userClasses } = useTypedSelector(state => state.classData, shallowEqual);

    if (userProgress == null || userClasses == null) {
        return <WaitRipple />
    }


    const handleClassChange = (event) => {
        userDuck.getUserProgress(user.Id, event.target.value, dispatch);
    }



    const legendDisplay = (className, label) => {
        return (
            <div className="d-flex ml-4">
                <div className={[classes.legendDisplay, className].join(' ')}></div>
                <div>
                    {label}
                </div>
            </div>

        )
    }


    return (
        <div className=" pt-4">
            <div className="row mb-4">
                <div className="col-sm-3">
                    {userClasses.length > 1 ?
                        <div className="form-group">
                            <label htmlFor="classSelect">{t.getText('studentgroup')}</label>
                            <select className="form-control" id="classSelect" defaultValue={user.CurrentClass} onChange={(e) => handleClassChange(e)}>
                                {userClasses.map((u) => {
                                    return (
                                        <option key={u.Id} value={u.Id}>{u.Name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        : <strong>{userClasses[0].Name}</strong>}
                </div>

                <div className="col-sm-9 d-flex justify-content-end align-items-center flex-wrap">
                    {legendDisplay(TestResultStatus.Incomplete, t.getText('incomplete'))}
                    {legendDisplay(TestResultStatus.FollowUpNeeded, t.getText('followupneeded'))}
                    {legendDisplay(TestResultStatus.Passed, t.getText('passed'))}
                    {legendDisplay(TestResultStatus.Failed, t.getText('failed'))}
                    {legendDisplay(TestResultStatus.ApprovedByTeacher, t.getText('approvedbyteacher'))}
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-sm-12">

                    <AllResultsTable t={t} />

                </div>
            </div>
        </div >
    )

}


export default UserTestsWatchedLectures;

