//@ts-check
import React, { useState, useEffect, useCallback } from "react";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import WaitRipple from "components/WaitRipple";
import MemberCard from './MemberCard';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Draggable from "react-draggable"

import classes from './members.module.scss';
import { CustomInput } from "reactstrap";
import GeneralNotesProxy from "features/User/GeneralNotesProxy";
/**
 * 
 * @param {{students: import("features/User/User").UserInfoCellDto[], 
 * teachers: import("features/User/User").UserInfoCellDto[], name: string, currentUserIsTeacher: boolean, classId: string } } props
 * @returns 
 */
const Members = ({ students, teachers, name, currentUserIsTeacher, classId }) => {

    // { students, teachers }
    const { languageService: t } = useI18n();
    const [filterBy, setFilterBy] = useState('students');
    const [archivedState, setArchivedState] = useState({ show: false, showSwitch: false });
    const [showNotes, setShowNotes] = useState({ userid: null, show: false });

    const [userList, setUserList] = useState(null); // has to be false or else can lead to infinite re renders when no one's online
    const [onlineOnly, toggleOnlineOnly] = useState(false);

    const filterList = useCallback((list, _onlineOnly) => {

        const users = list === 'students' ? students : teachers;

        if (_onlineOnly) {
            return users.filter(us => us.IsOnline);
        }
        return users.filter(us => us.IsArchived === false || us.IsArchived === archivedState.show);
    }, [students, teachers, archivedState]);

    const toggleOnline = () => {
        toggleOnlineOnly(prev => !prev);
    }

    useEffect(() => {
        setUserList(filterList(filterBy, onlineOnly));
    }, [filterBy, onlineOnly, filterList]);

    useEffect(() => {
        if (students && students.length > 0) {
            setArchivedState(state => { return { show: false, showSwitch: currentUserIsTeacher && students.filter(s => s.IsArchived).length > 0 } });
        }
    }, [students, currentUserIsTeacher]);

    const toggleShowArchived = () => {
        setArchivedState(state => { return { show: !state.show, showSwitch: true } });
    }

    const onDismissNotes = () => {
        setShowNotes(old => { return { userid: null, show: false } });
    }

    const showNotesDialog = (id) => {
        setShowNotes(old => { return { userid: id, show: true } });
    }

    if (!userList) {
        setUserList(filterList('students', onlineOnly));
        return <WaitRipple />
    }

    return (

        <>
            {/* print */}
            <div className="d-none d-print-flex mt-4 mb-4 justify-content-center">
                <div>
                    <h3 className="">{name} {filterBy === 'students' ? t.getText('students') : t.getText('teachers')}</h3>
                    <div>{t.getShortDateTimeString(new Date())} </div>
                </div>
            </div>
            {/* END print */}

            <div className="d-flex flex-wrap justify-content-between pb-4 pt-4 d-print-none">
                {teachers != null &&
                    <div className={classes['btn-group-wrapper']}>
                        {students && students.length > 0 && teachers && teachers.length > 0 &&
                            <div className="btn-group" role="group">
                                {students && students.length > 0 &&
                                    <button type="button" onClick={() => setFilterBy('students')} className={"btn " + (filterBy === 'students' ? 'btn-primary' : 'btn-inverse')}>{t.getText("students")}</button>
                                }
                                {teachers && teachers.length > 0 &&
                                    <button type="button" onClick={() => setFilterBy('teachers')} className={"btn " + (filterBy === 'teachers' ? 'btn-primary' : 'btn-inverse')}>{t.getText("teachers")}</button>
                                }
                            </div>
                        }
                    </div>
                }
                <div className="d-flex justify-content-right align-items-center">
                    <form className="mr-5">
                        {archivedState.showSwitch &&
                            <CustomInput type="switch" id="showArchived" checked={archivedState.show} onChange={() => toggleShowArchived()} label={t.getText('show.archived.users')} />
                        }

                        <CustomInput type="switch" id="onlineOnly" checked={onlineOnly} onChange={() => toggleOnline()} label={t.getText('show.only.online.users')} />
                    </form>
                    <button type="button" onClick={() => window.print()} className={['btn btn-inverse mr-2', classes['btn-small']].join(' ')}><LnIcon className="btn-icon filter-gray natural" name="print-icon" /></button>

                </div>
            </div>
            <div role="list" className="d-flex justify-content-start flex-wrap">
                {userList.map(i =>

                    <MemberCard classId={classId} key={i.Id} item={i} showNotes={showNotesDialog} currentUserIsTeacher={currentUserIsTeacher} isStudent={filterBy === "students"} />
                )}
            </div>


            <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>
                <Modal size="lg" role="dialog" isOpen={showNotes.show} toggle={onDismissNotes} backdrop={'static'}>
                    <ModalHeader toggle={onDismissNotes}>{t.getText("notes")}</ModalHeader>
                    <ModalBody tabIndex={-1}>
                        <GeneralNotesProxy userid={showNotes.userid} />

                    </ModalBody>
                    <ModalFooter>
                        <Button key={"btncancel"} className="btn btn-inverse" onClick={onDismissNotes}>{t.getText("close")}</Button>
                    </ModalFooter>
                </Modal>
            </Draggable>


        </>
    )
}

export default Members;
