// @ts-check
import React, { useState } from "react";
import useTypedSelector from "utils/useTypedSelector";
import { useI18n } from "components/lni18n";
import classes from './login.module.scss';
import WaitRipple from "components/WaitRipple";
import FormTextInput from "features/User/FormTextInput";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import LnIcon from "components/LnIcon";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { LoginDuck } from "./LoginDuck";
import { useEffect } from "react";
import { LOGO } from "assets/images";
import { SignOnReplyStatus } from "features/User/user.enums";


const RegisterForm = (props) => {

    const { languageService: t } = useI18n();
    const { signOnData } = useTypedSelector(state => state.login);
    const [showPolicy, setShowPolicy] = useState(false);

    const dispatch = useDispatch();

    const formSchema = {};
    const infoPropertyNames = {};

    if (signOnData && signOnData.Fields) {
        signOnData.Fields.forEach(p => {
            // transform to property names that the form can handle
            const safeKey = p.Key === "mail" ? "email" : (p.Key === "tele" ? "phone" : (p.Key === "tele2" ? "phone2" : p.Key.replace(/\./g, '_')));
            infoPropertyNames[p.Key] = safeKey;

            // add to validation
            if (p.Mandatory) {
                if (p.Type === "email") {
                    formSchema[safeKey] = Yup.string()
                        .email(t.getText('bad_email_adress'))
                        .required(t.getText('required_field'));
                }
                else {
                    formSchema[safeKey] = Yup.string()
                        .required(t.getText('required_field'));
                }
            }
        });

        if (signOnData.ExistingUser) {
            formSchema.password = Yup.string()
                .test('len', t.getText('toshortpasswd'), val => val.length > 4)
                .required(t.getText('required_field'));
        }
    }

    const checks = Yup.object().shape(formSchema);

    const { register, handleSubmit, errors, formState, setValue } = useForm({
        resolver: yupResolver(checks),
        mode: "onChange"
    });

    useEffect(() => {
        if (!signOnData || !infoPropertyNames)
            return;

        for (const key in infoPropertyNames) {
            if (Object.hasOwnProperty.call(infoPropertyNames, key)) {
                const safeKey = infoPropertyNames[key];
                var field = signOnData.Fields.find(f => f.Key === key);
                if (field) {
                    setValue(safeKey, field.Value, { shouldDirty: true });
                }

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signOnData]);

    const { isDirty, isValid } = useMemo(() => formState, [formState]);

    const handleFormSubmit = async (values) => {


        const postData = { ...signOnData, Fields: [...signOnData.Fields] };

        postData.Fields = signOnData.Fields.map(f => {
            const safeKey = infoPropertyNames[f.Key];
            return { ...f, Value: values[safeKey] };

        });

        if (postData.ExistingUser) {
            if (!values.password || values.password.length < 5) {
                return;
            }

            postData.PasswordForExisting = values.password;
        }

        dispatch(LoginDuck.PostSignOnData(postData));
    }

    const sendVerificationEmail = () => {
        /**
         * @type{import("features/language/Language").LanguageDto}
         */
        const langDto = t.currentLanguage;
        const mailData = signOnData.Fields.find(d => d.Type === "email");
        if (mailData) {
            LoginDuck.sendFormVerificationCodeEmail(mailData.Value, langDto.Short, signOnData.ClassId);
        }
    }

    const reload = () => {

        window.location.reload();
    }

    if (!signOnData) {
        return <WaitRipple />
    }

    return <>
        <h1 className="mb-3">{signOnData.ClassName}</h1>
        {signOnData.Error && <div dangerouslySetInnerHTML={{ __html: signOnData.Error }} className="mb-5 img-max-100" ></div>}

        {!signOnData.Error &&
            <form className="mb-2" onSubmit={handleSubmit(handleFormSubmit)}>

                <div dangerouslySetInnerHTML={{ __html: signOnData.HtmlText }} className="mb-2 img-max-100" ></div>


                <div className="mb-3">
                    {signOnData.Status === SignOnReplyStatus.UserExistsAndHaveNotLoggedIn && (
                        <button type="button" onClick={sendVerificationEmail} className="btn btn-primary btn-small">{t.getText("send.code")}</button>
                    )} &nbsp;
                </div>

                {signOnData.ExistingUser &&
                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6 mb-3">
                            <FormTextInput label={t.getText("passwd")}
                                errors={errors}
                                refToHook={register}
                                name={"password"}
                                as="input"
                                type={signOnData.Status === SignOnReplyStatus.UserExistsAndHaveNotLoggedIn ? "text" : "password"}
                                readOnly={false}
                                defaultValue="" />
                        </div>

                    </div>
                }



                <div className="row mb-4">
                    {signOnData.Fields.map(p => {

                        return (
                            <div key={p.Key} className="col-lg-4 col-md-6 mb-3 d-flex align-items-end">
                                <FormTextInput
                                    label={p.Label + (p.Mandatory ? " *" : "")}
                                    errors={errors}
                                    refToHook={register}
                                    name={infoPropertyNames[p.Key]}
                                    as="input"
                                    type={p.Type}
                                    readOnly={p.DoNotChange}
                                    defaultValue={p.Value}
                                />

                            </div>
                        )
                    })}



                </div>

                <div className="d-flex justify-content-between">

                    <button className="btn btn-primary mr-4"
                        disabled={!isDirty || !isValid}
                        type="submit" ><LnIcon className="icon-small filter-white mr-4" name="edit-icon" /> {t.getText('submit')}</button>
                    {isDirty && !isValid && <div className="my-3 preserve-white small">
                        {t.getText("sign.up.enter.in.all.mandatory.fields")}
                    </div>
                    }
                    {signOnData.ExistingUser &&
                        <button type="button" onClick={reload} className="btn btn-danger btn-small" >{t.getText("clear")}</button>
                    }

                </div>
                <div className="mt-3">
                    <h3>{t.getText("personal.data.policy")}</h3>
                    <div className="mb-3 preserve-white">{t.getText("sign.up.data.policy")}</div>
                    {!showPolicy && <button onClick={() => setShowPolicy(true)} className="btn btn-primary btn-small">{t.getText("view")}</button>}
                    {showPolicy && <>
                        <button onClick={() => setShowPolicy(false)} className="btn btn-primary btn-small">{t.getText("hide")}</button>
                        <div className="max-col-width mt-3" dangerouslySetInnerHTML={{ __html: signOnData.PolicyText }} ></div>
                    </>
                    }
                </div>

            </form>
        }

        <div className="mt-3 pt-3 border-top d-flex flex-wrap justify-content-between align-items-center">
            <div className="mr-3 my-4">
                LäraNära {(new Date()).getFullYear()} <a target="_blank" rel="noopener noreferrer" href="https://www.laranara.se">https://www.laranara.se</a>
            </div>
            <div className={classes.logoRegister}>
                <img src={LOGO} alt="logo" />
            </div>
        </div>

    </>



}

export default RegisterForm;
