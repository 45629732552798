//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint, MAIL_POLL_INTERVAL_IN_SEC } from "AppConstants";
import DispatchService from "utils/DispatchService"
import { errorDuck } from "components/ErrorHandler/ErrorHandlerDuck";

/**
 * @type {import('./singleMail').SingleMailState}
 */
const initialState = {
    mailLists: null,
    currentProject: null,
    statusCheckTimerId: -1,
    dirty: false,
};

const singleMailSlice = createSlice({
    name: 'singleMail',
    initialState: initialState,
    reducers: {

        /**
        * @param  {  {payload: import('./SingleMail').SingleMailProjectListDto}} action
        */
        setStartData(state, action) {
            state.dirty = false;
            if (state.statusCheckTimerId > 0) {
                window.clearTimeout(state.statusCheckTimerId);
            }
            state.statusCheckTimerId = -1;

            if (action.payload === null) {
                state.mailLists = null;
                state.statusCheckTimerId = -1;
                state.currentProject = null;
            }
            else {
                state.mailLists = action.payload;
                state.currentProject = null;

            }
        },

        /**
         * @param  {  {payload: import('./singleMail').SingleMailProjectDetailDto}} action
         */
        setCurrentProject(state, action) {
            if (state.statusCheckTimerId > 0) {
                window.clearTimeout(state.statusCheckTimerId);
            }
            state.currentProject = action.payload;
            state.dirty = false;
        },

        /**
             * @param  { {payload: number}} action
         */
        setStatusCheckId(state, action) {
            state.statusCheckTimerId = action.payload;
        },

        /**
            * @param  { {payload: boolean}} action
        */
        setDirty(state, action) {
            state.dirty = action.payload;
        },


        /**
         * @param  {  {payload: import('./SingleMail').SingleMailProjectDto}} action
         */
        addNewProject(state, action) {
            if( state.mailLists == null ){
                state.mailLists = {NotSent: [], Sent: []};
            }
            state.mailLists.NotSent.splice(0, 0, action.payload);
        },

        /**
         * @param  {  {payload: import('types/Mail').MailProjectDto}} action
         */
        updateUnsentProjectInList(state, action) {
            if (state.mailLists === null) {
                return;
            }

            const project = state.mailLists.NotSent.find(p => p.Id === action.payload.Id);
            if (project === null) {
                return;
            }
            const index = state.mailLists.NotSent.indexOf(project);
            if (index === -1) {
                throw new Error("Could not find project....");
            }

            state.mailLists.NotSent[index] = { ...project, ...action.payload };

        },

        /**
        * @param  {  {payload: import('./singleMail').SingleMailProjectDetailDto}} action
        */
        updateProjectToSentInList(state, action) {

            state.currentProject = action.payload;

            if (state.mailLists === null) {
                return;
            }

            let project = state.mailLists.NotSent.find(p => p.Id === action.payload.Id);
            if (project === null) {
                return;
            }
            const index = state.mailLists.NotSent.indexOf(project);
            if (index === -1) {
                throw new Error("Could not find project....");
            }

            // delete from not sent list
            state.mailLists.NotSent.splice(index, 1);

            // add to top of sent list
            project.Queued = action.payload.Queued;
            state.mailLists.Sent.splice(0, 0, project);
        },

        /**
         * @param  {  {payload: {id: number} }} action
         */
        removeProject(state, action) {
            if (state.currentProject && state.currentProject.Id === action.payload.id) {
                state.currentProject = null;
                state.dirty = null;
            }

            if (state.mailLists.NotSent && state.mailLists.NotSent.length > 0) {
                state.mailLists.NotSent = state.mailLists.NotSent.filter(m => m.Id !== action.payload.id);
            }

            if (state.mailLists.Sent && state.mailLists.Sent.length > 0) {
                state.mailLists.Sent = state.mailLists.Sent.filter(m => m.Id !== action.payload.id);
            }
        },

        /**
         * @param  {  {payload: import('types/Mail').MailProjectAttachmentDto}} action
         */
        addFileToProject(state, action) {
            state.currentProject.Attachments.push(action.payload);
        },

        /**
        * @param  {  {payload:{Id: string} }} action
        */
        deleteFileInProject(state, action) {
            state.currentProject.Attachments = state.currentProject.Attachments.filter(a => a.Id !== action.payload.Id)
        },

        /**
        * @param  {  {payload: import('types/types').Dictionary<string> }} action
        */
        setJobstatuses(state, action) {
            if (!(state.mailLists && state.mailLists.Sent)) {
                return;
            }

            state.mailLists.Sent.forEach(m => {
                const status = action.payload[m.Id.toString()];
                if (status) {
                    m.Status = status;
                }
            });
        },

        /**
        * @param  {  {payload: import('features/User/User').UserMailDto }} action
        */
       setJobReceiver(state, action) {
        if (!state.currentProject ) {
            return;
        }
        state.currentProject.Receiver = action.payload;
        let inlist =  state.mailLists.NotSent.find(m=> m.Id === state.currentProject.Id);
        if( inlist)
        {
            inlist.Receiver = action.payload;
        }
        
    },

    }
});


const checkStatus = async () => {

     /**
    * @type {import('./SingleMail').SingleMailState}
    */
   const singleMailState = DispatchService.getState().singleMail;
   if( singleMailState.statusCheckTimerId  && singleMailState.statusCheckTimerId > -1 ){
       window.clearTimeout(singleMailState.statusCheckTimerId );
   }

    DispatchService.dispatch(singleMailDuck.setStatusCheckId(-1));

    const project = singleMailState.currentProject;
    if (project === null) {
        return;
    }

    const url = endPoint.GET_STATUS_FOR_SINGLE_MAIL_URL;

    try {
        /**
         * @type { {data: import('types/types').Dictionary<string>}}
         */
        const result = await instance.get(url);
        if( !result){
            return;
        }
        if (result.data !== null) {
            await DispatchService.dispatch(singleMailDuck.setJobstatuses(result.data));
        }

        let allOK = true;
        for (const id in result.data) {
            if (result.data.hasOwnProperty(id)) {
                const element = result.data[id];
                if (element !== "opened") {
                    allOK = false;
                    break;
                }

            }
        }

        if (allOK === false) {
            const timerId = window.setTimeout(() => {
                checkStatus();
            }, 1000 * MAIL_POLL_INTERVAL_IN_SEC);

            await DispatchService.dispatch(singleMailDuck.setStatusCheckId(timerId));
        }
    } catch (error) {
        DispatchService.dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }

}


/**
 *
 */
const getMailLists = async (dispatch, getState, force) => {

    try {
        /**
         * @type {import('./singleMail').SingleMailState}
         */
        const state = getState().singleMail;
        if (!force && state.mailLists !== null) {
            dispatch(singleMailDuck.setCurrentProject(null));
            return;
        }


        const url = endPoint.GET_SINGLE_MAIL_LIST_URL;
        /**
         * @type {{data: import('./SingleMail').SingleMailProjectListDto}} response
         */
        const response = await instance.get(url);
        if( !response){
            return;
        }
        dispatch(singleMailDuck.setStartData(response.data));
    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}


/**
 * Add a new mail to list. and returns Promise that resolves the Id of the mail
 *
 * @param {Function} dispatch
  * @param {string} recieverId 
 * @return { Promise<number> }
 */
const newMail = async (dispatch, getState, recieverId) => {
    try {
        const url = endPoint.GET_CREATE_SINGLE_MAIL_URL;

        const data = recieverId && { Value: recieverId };

        if( recieverId){
             await getMailLists( dispatch, getState, true );
        }

        /**
         * @type {{data: import('./SingleMail').SingleMailProjectDto}} response
         */
        const response = await instance.post(url, data);
        if( !response){
            return;
        }
        await dispatch(singleMailDuck.addNewProject(response.data));

        return response.data.Id;

    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 *
 * @param { number } mailId
 */
const getMail = async (mailId, dispatch) => {
    try {
        const url = endPoint.GET_SINGLE_MAIL_URL(mailId);
        /**
         * @type {{data: import('./singleMail').SingleMailProjectDetailDto}} response
         */
        const response = await instance.get(url);
        if( !response){
            return;
        }
        await dispatch(singleMailDuck.setCurrentProject(response.data));
        if (response.data.Queued !== null) {
            await checkStatus();
        }
    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 *
 * @param { number } mailId
 * @param { import('./SingleMail').SingleMailProjectFormDto } postdata
 */
const saveMail = (mailId, postdata) => async (dispatch) => {
    try {
        const url = endPoint.GET_SAVE_SINGLE_MAIL_URL(mailId);
        /**
         * @type {{data: import('types/Mail').MailProjectDto}} response
         */
        const response = await instance.put(url, postdata);
        if( !response){
            return;
        }
        dispatch(singleMailDuck.updateUnsentProjectInList(response.data));
    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}


/**
 *
 * @param { number } mailId
 */
const sendMail = (mailId) => async (dispatch) => {
    try {
        const url = endPoint.GET_SEND_SINGLE_MAIL_URL(mailId);
        /**
         * @type {{data: import('./singleMail').SingleMailProjectDetailDto}} response
         */
        const response = await instance.post(url, null);
        if( !response){
            return;
        }
        dispatch(singleMailDuck.updateProjectToSentInList(response.data));
        checkStatus();
    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}



/**
 *
 * @param { number } mailId
 */
const deleteMail = (mailId) => async (dispatch) => {
    try {
        const url = endPoint.GET_DELETE_SINGLE_MAIL_URL(mailId);

        await instance.delete(url, null);
        dispatch(singleMailDuck.removeProject({ id: mailId }));
    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 *
 * @param { number } mailId
 */
const addAttachment = (mailId, postdata) => async (dispatch) => {
    try {
        const url = endPoint.GET_ADD_MAIL_ATTACHMENT_FOR_SINGLE_MAIL_URL(mailId);
        /**
         * @type {{data: import('types/Mail').MailProjectAttachmentDto}} response
         */
        const response = await instance.post(url, postdata);
        if( !response){
            return;
        }
        dispatch(singleMailDuck.addFileToProject(response.data));
    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 *
 * @param { number } mailId
 * @param{ string} attachmentId
 */
const deleteAttachment = (mailId, attachmentId) => async (dispatch) => {
    try {
        const url = endPoint.GET_DELETE_MAIL_ATTACHMENT_FOR_SINGLE_MAIL_URL(mailId, attachmentId);
        await instance.delete(url);
        dispatch(singleMailDuck.deleteFileInProject({ Id: attachmentId }));
    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 *
 * @param { string } string
 */
const getUsers = async (string) => {
    try {
        const url = endPoint.GET_RECEIVERS_URL(string);
        const response = await instance.get(url);
        return (response && response.data) || [];
    } catch (error) {
        DispatchService.dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}



export const singleMailDuck = {
    ...singleMailSlice.actions, getMailLists, getMail, deleteMail,
    newMail, saveMail, sendMail, addAttachment, deleteAttachment, getUsers
};
export default singleMailSlice.reducer;