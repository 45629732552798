//@ts-check
import React from "react";
import UserImg from "components/UserImg";
import { endPoint } from "AppConstants";
import FileIcon from "components/FileIcons/FileIcon";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import classes from '../forum.module.scss';
import ShowImage from "../ShowImage";
import WaitRipple from "components/WaitRipple";
import ThreadAnswerReplyCard from "./ThreadAnswerReplyCard";

/**
 *
 * @param {{
    answer: import("../Forum").ForumThreadDto,
}} props
 */

const ThreadAnswer = ({ answer }) => {
    const { languageService: t } = useI18n();

    if (!answer) {
        return <WaitRipple />
    }


    return (
        <div className={['mx-5 d-md-flex', classes['forum-answer']].join(' ')}>
            <div className="d-none d-md-block">
                {answer.Writer.ImageUrl ? <UserImg src={endPoint.API_HOST + answer.Writer.ImageUrl} className={['rounded-circle', 'avatar-img', classes['answer-avatar']].join(' ')} alt={answer.Writer.FullName} /> : <LnIcon className={['rounded-circle', 'avatar-img', classes['answer-avatar']].join(' ')} name="user-icon" />}
            </div>
            <div className={["card  mb-5", classes['answer-card']].join(' ')}>
                <div className={"card-title d-flex justify-content-between "}>
                    <h5 className={"d-flex " + classes['forum-title']}>
                        <div className="d-md-none">
                            {answer.Writer.ImageUrl ? <UserImg src={endPoint.API_HOST + answer.Writer.ImageUrl} className={['rounded-circle', 'avatar-img', classes['answer-avatar']].join(' ')} alt={answer.Writer.FullName} /> : <LnIcon className={['rounded-circle', 'avatar-img', classes['answer-avatar']].join(' ')} name="user-icon" />}
                        </div>
                        <div>
                            {answer.Writer.FullName}
                        </div>
                    </h5>



                    <div className="d-flex">
                        <small className="d-flex flex-row flex-sm-column align-items-end">
                            <div className={((!answer.Edited && answer.Unread) ? " badge-warning badge badge-pill" : "")}>
                                {t.getShortDateTimeString(answer.Created)}
                            </div>
                            {answer.Edited && <div className={"mt-2 d-flex " + (answer.Unread && " badge-warning badge badge-pill")}>
                                <div>{t.getText("edited")}&nbsp;</div>
                                <div>{t.getShortDateTimeString(answer.Edited)}</div>
                            </div>}
                        </small>
                      
                    </div>


                </div>
                <div className="d-flex">
                    {(answer.Attachment)
                        && <div className="mr-4">
                            {
                                answer.Attachment.MediaType === 1 ?
                                    <button type="button" className="btn btn-link" onClick={() => ShowImage(endPoint.API_HOST + answer.Attachment.Url, answer.Attachment.FileName, t)}>
                                        <img src={endPoint.API_HOST + answer.Attachment.ThumbUrl} alt={answer.Attachment.FileName} />
                                    </button>
                                    : <a href={endPoint.API_HOST + answer.Attachment.Url}>
                                        <FileIcon className={[classes['file-icon']].join(' ')} size="512px" name={(answer.Attachment.FileName || '').split('.').pop()} /><br />
                                        {answer.Attachment.FileName}
                                    </a>
                            }
                        </div>}
                    <div className="mb-3 flex-grow-1">
                       <div className="word-break-word card-text max-col-width" dangerouslySetInnerHTML={{ __html: answer.Article }}></div>

                            {(answer.Attachment && answer.Attachment.MediaType === 3) &&
                                <audio className="mt-3" controls={true} src={endPoint.API_HOST + answer.Attachment.Url}></audio>
                            }

                            {(answer.Attachment && answer.Attachment.MediaType === 2) &&
                                <video className="mt-3" controls={true} src={endPoint.API_HOST + answer.Attachment.Url} ></video>
                            }
                    </div>



                </div>

                {answer.Answers && answer.Answers.length > 0 &&
                    <div className="mt-4">
                        {answer.Answers.map(reply => {
                            return <ThreadAnswerReplyCard key={reply.Id} reply={reply} />
                        })}
                    </div>
                }
            </div>


        </div>
    );
};

export default ThreadAnswer;