//@ts-check
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector"
import UserImg from "components/UserImg";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import { endPoint } from "AppConstants";
import { feedDuck } from "./FeedDuck";
import { API_HOST } from "AppConstants";

import FeedCommentCard from "./FeedCommentCard";
import FeedForm from "./FeedForm";
import classes from './feed.module.scss';
import { Confirm, ModalConstants } from "components/OnlineModal";
import { DropdownItem, DropdownMenu } from "reactstrap";
import LnDropDownMenu from "components/LnDropDownMenu";

/**
 * FeedCard
 * @module features/classes/ClassItems/Feed/FeedCard
 * @return an Card with feed detail.
 * @param {{
    post: any,
}} props
 */

const FeedCard = (props) => {
    const { languageService: t } = useI18n();
    /**
     * @type { import('./Feed').FeedPostDto }
     */
    const post = props.post || {};
    const [showForm, toggleShowForm] = useState(false);
    const dispatch = useDispatch();
    const { eventsSinceDate } = useTypedSelector(state => state.classData);
    const canDelete = useTypedSelector(state => state.feed.currentFeed.CanDelete)
    const currentUser = useTypedSelector(state => state.login.user)
    const toggleForm = () => {
        toggleShowForm(!showForm);
    };

    const toggleEditPost = (post) => {
        dispatch(feedDuck.togglePostInEdit(post.Id))
    }

    const handleDelete = async () => {
        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("delete"),
            message: (<div>{t.getText("delete_posting")}</div>),
            languageService: t,
            type: "yes/no",
            okOnEnter: true,
        });

        if (result === ModalConstants.Yes) {
            dispatch(feedDuck.deletePosting(post.Id));
        };
    };

    const onGetAllComments = (postId) => {
        dispatch(feedDuck.getAllComments(postId));
    }

    let imgurl = null;
    if (post.ImageUrl) {
        if (post.ImageUrl.indexOf("http") !== 0) {
            imgurl = API_HOST + post.ImageUrl;
        }
        else {
            imgurl = post.ImageUrl;
        }
    }

    const newpost = currentUser.Id !== post.User.Id && post.PostDate.getTime() > eventsSinceDate.getTime();
    return (
        <div className={["card mb-5", classes['post-card']].join(' ')}>
            <div className="d-flex justify-content-between mr-5">
                <div className="avatar-wrapper">
                    {post.User.ImageUrl ? <UserImg src={endPoint.API_HOST + post.User.ImageUrl} className={['rounded-circle m-4', 'avatar-img'].join(' ')} alt={post.User.FullName} /> : <LnIcon className={['rounded-circle m-4', 'avatar-img'].join(' ')} name="user-icon" />}
                </div>
                <div className="flex-grow-1 pl-4">
                    <div className='d-flex mt-4'>
                        <div className="d-flex flex-wrap flex-fill">
                            <div className="flex-fill">
                                <h5 className={classes['post-title']}>
                                    {post.User.FullName}
                                </h5>
                            </div>
                            <div className="d-flex align-items-start">
                                <small className={"d-flex flex-column align-items-end " + (newpost && " font-weight-bold")}>
                                    <div>
                                        {t.getShortDateTimeString(post.PostDate)}
                                    </div>
                                    {post.Edited && <>
                                        <div>{t.getText("edited")} </div>
                                        <div>{t.getShortDateTimeString(post.Edited)}</div></>}
                                </small>

                                {(post.Editpermission || canDelete) &&
                                    <LnDropDownMenu
                                        direction="down"
                                        caret={false}
                                        toggleComponent={
                                            <button className="edit round" >
                                                <LnIcon className="icon-small"  name="edit-icon" alt="Edit"/>
                                            </button>

                                        }
                                        toogleClassName=""
                                        className="ml-3"
                                        toogleTagName="div"
                                    >
                                        <DropdownMenu right className="" container="body">
                                            <div className="d-flex flex-column">
                                                {post.Editpermission &&
                                                    <DropdownItem onClick={() => toggleEditPost(post)} tag="span" >{t.getText("edit")}</DropdownItem>
                                                }
                                                <DropdownItem onClick={handleDelete} tag="span" >{t.getText("delete")}</DropdownItem>
                                            </div>
                                        </DropdownMenu>
                                    </LnDropDownMenu>
                                }

                            </div>
                        </div>
                        
                    </div>
                    <div className={["mt-3", classes['post-text-wrapper']].join(' ')}>

                        {!post.InEdit &&
                            <div className={"card-text max-col-width mt-3" + (newpost && " first-line-bold")} dangerouslySetInnerHTML={{ __html: post.Text }}></div>
                        }
                        {post.InEdit && <div className="max-col-width mt-3" ><FeedForm simpleUI={true} initialValue={post.Text} postId={post.Id} isComment={false} feedId={post.FeedId} toggleForm={() => toggleEditPost(post)} /></div>}


                        {post.LinkUrl && <div className="word-break-word">
                            <a target="_blank" rel="noopener noreferrer" href={post.LinkUrl}>{post.LinkUrl}</a>
                        </div>}
                        {imgurl && <div className="mt-2">
                            <img alt="" src={imgurl} />
                        </div>}
                        {post.YoutubeEmbedCode && <div className="mt-2" dangerouslySetInnerHTML={{ __html: post.YoutubeEmbedCode }} ></div>}


                        {post.Comments.length ? <div className="mt-5">

                            {post.HasMoreComments && <div className="mb-3 d-flex justify-content-end">
                                <button className="btn btn-small btn-primary" onClick={() => onGetAllComments(post.Id)} >{t.getText("feed.allcomments")}</button>
                            </div>}
                            {post.Comments.map(c => (
                                <FeedCommentCard feedId={post.FeedId} currentUser={currentUser} eventsSinceDate={eventsSinceDate} key={c.Id} Comment={c} canDelete={canDelete} />
                            ))}
                        </div> : <div></div>}

                        {!showForm ? <div className="d-flex justify-content-end mb-3"><button className="btn btn-link btn-small" onClick={() => toggleForm()}>{t.getText('comment')} </button></div> : null}
                        {showForm ? <div className="mt-3"><FeedForm isComment={true} initialValue="" postId={post.Id} feedId={post.FeedId} toggleForm={toggleForm} /></div> : null}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeedCard;