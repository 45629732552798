//@ts-check
import React from "react";
import { useI18n } from "components/lni18n";
import ScrollContent from "components/ScrollContent";
import LnIcon from "components/LnIcon";
import { API_HOST } from "AppConstants";
import useTypedSelector from "utils/useTypedSelector";

const AdminInfo = (props) => {

  const { languageService: t } = useI18n();
  const user = useTypedSelector(state => state.login.user);

  function fel() {
    throw new Error("An error to check");
  }

  return <ScrollContent scrollname="class-myprogress">
    <div className="card-box big-card-box h-100">
      <div className="card-header ln-nostyle d-flex justify-content-between">
        <h3>

          {user && user.IsRoot && <button onClick={fel} className="btn btn-link" ><LnIcon className="header-icon" name="settings" /></button>}

          {!user.IsRoot && <LnIcon className="header-icon" name="settings" />}
          
          Admin
        </h3>
      </div>
      <div className="card-body max-col-width">
        <div className="preserve-white mb-4">
          {t.getText("admin.explain")}
        </div>
        <a href={API_HOST + "/login/redirect/admin"} rel="noopener noreferrer" target="_blank">LäraNära Admin</a>
      </div>
    </div>
  </ScrollContent>
}

export default AdminInfo;
