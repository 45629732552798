//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import Enums from "utils/Enums";
import { lectureDuck } from 'features/course/part/Lectures/LectureDuck';
import { lnDataBase } from 'index';

export const ForumEmailSending =
{
    none: -1,
    both: 0,
    onlyPrimary: 1,
    onlySecondary: 2,
}

export const ForumSortEnum =
{
    LastActivity: 0,
    Title: 1,
    Creator: 2,
    Created: 3,
    ReplyCount: 4,
    UnreadCount: 5,
};

/**
 * @type {import('./Forum').ForumState}
 */
const initialState = {
    classId: null,
    currentForum: null,
    currentThread: null,
    filterType: Enums.FilterType.ByPosts,
    lastFetch: null,
    userId: null,
    inLectureDialog: false,
};

/**
 * @type {import('./Forum').ForumThreadDto}
 */
const threadDefault = {
    Answers: [],
    Article: "",
    Attachment: null,
    Created: null,
    ForumId: null,
    Header: "",
    Id: 0,
    IsModerator: false,
    IsReply: false,
    Unread: false,
    Published: true,
    ReadBy: [],
    Writer: { Id: "id", FullName: "", ImageUrl: null },
    Edited: null,
    CanEdit: false,
    CanDelete: false,
    TestResult: null,
    CanAddAnwser: false,
    ConnectedTest: null,
    TestData: null,
    ConnectedScreenCuePoint: null

}

const forumSlice = createSlice({
    name: 'forum',
    initialState: initialState,
    reducers: {

        /**
        * @param  {  {payload: {classId: string, dto:import('./Forum').ForumPageDto}}} action
        */
        setStartData(state, action) {
            if (action.payload === null) {

                // set to initial state
                for (const key in initialState) {
                    if (initialState.hasOwnProperty(key)) {
                        state[key] = initialState[key];
                    }
                }
                return;
            }

            state.userId = null;
            state.filterType = Enums.FilterType.ByPosts;

            state.currentForum = action.payload.dto;
            state.classId = action.payload.classId;
            state.filterType = Enums.FilterType.ByPosts;
            state.lastFetch = new Date();
        },

        /**
         * @param  {  {payload: import('./Forum').ForumThreadDto}} action
         */
        setThread(state, action) {
            state.currentThread = action.payload;
            if (state.currentForum && action.payload) {
                state.currentForum.Headers.forEach(h => {
                    if (h.Id === action.payload.Id) {
                        h.selected = true;
                        h.UnreadCount = 0;
                        h.HasRead = true;
                    }
                    else {
                        h.selected = false;
                    }
                });
            }
        },

        /**
         * @param  {  {payload: import('./Forum').ForumThreadReadByDto[]}} action
         */
        setReadersOfThread(state, action) {
            if (state.currentThread == null) {
                return;
            }
            state.currentThread.ReadBy = action.payload;
        },

        /**
         * @param  {{payload: number}} action
         */
        setSubscriptionStateOfThread(state, action) {
            if (state.currentForum == null) {
                return;
            }
            state.currentForum.SubscribeTo = action.payload;
        },

        /**
         * @param  {  {payload: import('./Forum').ForumPageDto}} action
         */
        setOtherPageData(state, action) {
            state.currentForum = action.payload;
        },

        /**
         *
         * @param { {payload:  { userId: string, filterType: number }}} action
         */
        setFilterData(state, action) {
            if (state.currentForum != null) {
                if (action.payload) {
                    state.userId = action.payload.userId;
                    state.filterType = action.payload.filterType;
                } else {
                    state.userId = null;
                    state.filterType = Enums.FilterType.ByPosts;
                }
            }
        },

        /**
         *
         * @param { {payload:  import('./Forum').ForumThreadDto}} action
         */
        addNewThread(state, action) {

            /**
             * @type {import('./Forum').ForumThreadHeaderDto}
             */
            const header = {
                Id: action.payload.Id,
                Header: action.payload.Header,
                Writer: action.payload.Writer,
                Created: action.payload.Created,
                LastActivity: new Date(),
                AnswerCount: 0,
                UnreadCount: 0,
                Published: true,
                selected: true,
                HasRead: true,
                Hidden: false

            }

            state.currentForum.Headers.forEach(h => h.selected = false);
            state.currentForum.Headers = [header, ...state.currentForum.Headers];



        },

        /**
         *
         * @param { {payload:  import('./Forum').ForumThreadDto}} action
         */
        updateCurrentThread(state, action) {

            state.currentThread = {
                ...state.currentThread,
                Edited: action.payload.Edited,
                Article: action.payload.Article,
                Attachment: action.payload.Attachment
            };

        },

        /**
         *
         * @param { {payload:  import('./Forum').ForumThreadDto}} action
         */
        updateCurrentThreadAnswer(state, action) {

            state.currentThread.Answers = state.currentThread.Answers.map(a => {
                if (a.Id !== action.payload.Id) {
                    return a;
                }
                else {
                    return {
                        ...a,
                        Edited: action.payload.Edited,
                        Article: action.payload.Article,
                        Attachment: action.payload.Attachment,
                        TestResult: action.payload.TestResult,
                    }
                }
            })

        },

        /**
         *
         * @param { {payload:  import('./Forum').ForumThreadDto}} action
         */
        updateCurrentThreadAnswerReply(state, action) {
            state.currentThread.Answers.forEach(a => {
                a.Answers = a.Answers.map(r => {
                    if (r.Id === action.payload.Id) {
                        if (action.payload.TestResult) {
                            a.TestResult = action.payload.TestResult;
                        }
                        return {
                            ...r, Edited: action.payload.Edited,
                            Article: action.payload.Article,
                        }
                    }
                    return r;
                })
            });
        },

        /**
       *
       * @param { {payload: import('./Forum').NewForumHeadersData}} action
       */
        addFetchedHeaders(state, action) {

            if (!state.currentForum) {
                return;
            }
            if (action.payload.Headers.length > 0) {
                const newIds = action.payload.Headers.map(h => h.Id);
                state.currentForum.Headers = [...action.payload.Headers,
                ...state.currentForum.Headers.filter(f => newIds.indexOf(f.Id) < 0)];
            }
            state.currentForum.LastFetch = action.payload.Epoch;
        },

        /**
        *
        * @param { {payload:  { replyTo: number, data:import('./Forum').ForumThreadDto}}} action
        */
        addNewChildThread(state, action) {

            if (!state.currentThread) {
                return;
            }

            if (state.currentThread.Id === action.payload.replyTo) {
                state.currentThread.Answers = [action.payload.data, ...state.currentThread.Answers]


                const header = state.currentForum.Headers.find(h => h.Id === action.payload.replyTo);
                if (header != null) {
                    header.AnswerCount = header.AnswerCount + 1;
                }

                if (action.payload.data.TestResult && !action.payload.data.IsModerator) {
                    state.currentThread.CanAddAnwser = false;
                }


            }  // reply to an answer
            else if (state.currentThread.Answers && state.currentThread.Answers.length > 0) {
                let answ = state.currentThread.Answers.find(a => a.Id === action.payload.replyTo);
                if (answ) {
                    if (action.payload.data.TestResult) {
                        answ.TestResult = action.payload.data.TestResult;
                    }
                    answ.Answers = [...answ.Answers, action.payload.data]
                }
            }

        },

        /**
        *
        * @param { {payload:  { forumId: string, threadId: number, parentId: number, state: boolean }}} action
        */
        postingPublishChanged(state, action) {
            if (state.currentThread != null) {
                if (state.currentThread.Id === action.payload.threadId) {
                    state.currentThread.Published = action.payload.state;
                }
                else if (state.currentThread.Answers) {
                    if (!action.payload.parentId) {
                        let answ = state.currentThread.Answers.find(a => a.Id === action.payload.threadId);
                        if (answ !== null) {
                            answ.Published = action.payload.state;
                        }
                    }
                    else {
                        let answ = state.currentThread.Answers.find(a => a.Id === action.payload.parentId);
                        if (answ && answ.Answers) {
                            let reply = answ.Answers.find(a => a.Id === action.payload.threadId);
                            if (reply) {
                                reply.Published = action.payload.state;
                            }
                        }
                    }

                }
            }

            const header = state.currentForum.Headers.find(h => h.Id === action.payload.threadId);
            if (header != null) {
                header.Published = action.payload.state;
            }

        },

        /**
       *
       * @param { {payload:  { forumId: string, threadId: number, parentId: number }}} action
       */
        threadIsDeleted(state, action) {
            if (state.currentThread != null) {
                if (state.currentThread.Id === action.payload.threadId) {
                    state.currentThread = null;
                }
                else if (state.currentThread.Answers) {
                    if (!action.payload.parentId) {
                        state.currentThread.Answers = state.currentThread.Answers.filter(a => a.Id !== action.payload.threadId);
                    }
                    else {
                        let answ = state.currentThread.Answers.find(a => a.Id === action.payload.parentId);
                        if (answ && answ.Answers) {
                            answ.Answers = answ.Answers.filter(a => a.Id !== action.payload.threadId);
                        }
                    }
                }
            }

            const header = state.currentForum.Headers.find(h => h.Id === action.payload.threadId);
            if (header != null) {
                state.currentForum.Headers = state.currentForum.Headers.filter(h => h.Id !== action.payload.threadId);
            }

        },

        /**
      *
      * @param { {payload: any}  } action
      */
        updateTestResult(state, action) {

            if (state.currentThread !== null) {
                const answer = state.currentThread.Answers.find(a => a.TestResult?.Id === action.payload.Id);
                if (answer) {
                    answer.TestResult = { ...answer.TestResult, ...action.payload };
                }
            }

        },
        /**
       *
       * @param { {payload: boolean}  } action
       */
        setInLectureDialog(state, action) {
            state.inLectureDialog = action.payload;
        }



    }
});

export const forumService = {

    /**
     *
     * @param { boolean} state
     */
    setInLectureDialog: (state) => (dispatch) => {
        dispatch(forumDuck.setInLectureDialog(state));
    },

    /**
    *
    * @param { { userId: string, filterType: number }} data
    */
    setFilterData: (data) => (dispatch) => {
        dispatch(forumDuck.setFilterData(data));
    },

    updateTestResult: (testData) => (dispatch) => {
        dispatch(forumDuck.updateTestResult(testData));

    },

    /**
    * @param  {{classId: string, dto:import('./Forum').ForumPageDto}} data
    */
    setStartData: (data) => (dispatch) => {
        dispatch(forumDuck.setStartData(data));
    },

    getForumStartDataForClass: async (classId, dispatch, getstate) => {

        dispatch(forumDuck.setThread(null));

        /**
         * @type {import('./Forum').ForumState}
         */
        var state = getstate().forum;

        const oldUserId = state.userId;
        const oldType = state.filterType;

        if (state.currentForum && state.currentForum.Id === classId && oldUserId) {
            dispatch(forumService.filterData(oldUserId, oldType, null));
            return state.currentForum;
        }

        if (state.currentForum && state.currentForum.Id === classId) {
            if (state.currentForum.Page === 1) {
                forumService.checkForNewHeaders(state.currentForum.Id, state.currentForum.LastFetch, dispatch);

            }
            return state.currentForum;
        }

        await dispatch(forumService.setFilterData(null));
        await dispatch(forumDuck.setStartData(null));

        // if( state.pageState && state.pageState.currentForum === classId ){

        // }

        /**
         * @type {{data: import('./Forum').ForumPageDto }}
         */
        const response = await instance.get(endPoint.CLASS_FORUM_START_URL(classId, null, Enums.FilterType.ByPosts));
        if (!response) {
            return null;
        }
        dispatch(forumDuck.setStartData({ classId: classId, dto: response.data }));

        return response.data;

    },


    getForumDataForClassAndCuePoint: async (classId, threadId, dispatch) => {

        await dispatch(forumDuck.setStartData(null));
        await dispatch(forumDuck.setInLectureDialog(true));

        /**
         * @type {{data: import('./Forum').ForumCuePointData }}
         */
        const response = await instance.get(endPoint.CLASS_CUEPOINT_FORUM_START_URL(classId, threadId));
        if (!response) {
            return null;
        }
        await dispatch(forumDuck.setStartData({ classId: classId, dto: response.data.Forum }));

        // just let this happen in the next cycle, to make shure the forum is set
        Promise.resolve().then(() => {
            dispatch(forumDuck.setThread(response.data.Thread));
        });

    },


    getForumStartDataForSubGroup: async (classId, groupId, dispatch, getstate) => {

        dispatch(forumDuck.setThread(null));

        /**
         * @type {import('./Forum').ForumState}
         */
        var state = getstate().forum;

        const oldUserId = state.userId;
        const oldType = state.filterType;

        if (state.currentForum && oldUserId) {
            dispatch(forumService.filterData(oldUserId, oldType, groupId));
            return;
        }

        if (state.currentForum && state.currentForum.Id === groupId) {
            if (state.currentForum.Page === 1) {
                forumService.checkForNewHeaders(groupId, state.currentForum.LastFetch, dispatch);
            }
            return;
        }

        dispatch(forumDuck.setFilterData(null));

        dispatch(forumDuck.setStartData(null));

        /**
         * @type {{data: import('./Forum').ForumPageDto }}
         */
        const response = await instance.get(endPoint.GET_FORUM_FOR_BASEGROUP_URL(classId, groupId, null, Enums.FilterType.ByPosts));
        if (!response) {
            return;
        }
        dispatch(forumDuck.setStartData({ classId: classId, dto: response.data }));

    },

    checkForNewHeaders: async (forumId, lastFetch, dispatch) => {
        /**
         * @type { {data: import('./Forum').NewForumHeadersData} } 
        **/
        const response = await instance.get(endPoint.GET_FORUM_NEW_POSTS_URL(forumId, lastFetch));
        if (response && response.data) {
            dispatch(forumDuck.addFetchedHeaders(response.data));
        }
    },

    getForumStartDataForFeed: async (dispatch) => {

        dispatch(forumDuck.setThread(null));

        await dispatch(forumDuck.setFilterData(null));
        await dispatch(forumDuck.setStartData(null));

        /**
         * @type {{data: import('./Forum').ForumPageDto }}
         */
        const response = await instance.get(endPoint.GET_USER_FEEDBACK_URL());
        if (!response) {
            return null;
        }
        dispatch(forumDuck.setStartData({ classId: null, dto: response.data }));

        return response.data;

    },



    /**
     *
     * @param {-1|1} jump
     */
    getMoreData: (jump) => async (dispatch, getstate) => {

        /**
         * @type {import('./Forum').ForumState}
         */
        var state = getstate().forum;

        /**
         * @type {import('./Forum').ForumPageDto}
         */
        var forum = state.currentForum;

        /**
         * @type {{data: import('./Forum').ForumPageDto }}
         */
        // {forumId:string, page:number, sortBy:number, desc:boolean, userId: null | string, filterType: null | 0 | 1}
        const response = await instance.get(
            endPoint.MORE_FORUM_URL(forum.Id, forum.Page + jump, forum.SortedBy,
                forum.SortedDesc, state.userId, state.filterType));

        if (!response) {
            return;
        }

        dispatch(forumDuck.setOtherPageData(response.data));
    },

    /**
     *
     * @param { number } threadId
     */
    getThreadData: (threadId) => async (dispatch, getstate) => {



        /**
         * @type {import('./Forum').ForumState}
         */
        var { currentForum } = getstate().forum;
        if (currentForum === null) {
            return;
        }

        dispatch(forumDuck.setThread(threadDefault));

        /**
         * @type {{data: import('./Forum').ForumThreadDto }}
         */
        const response = await instance.get(endPoint.GET_THREAD_URL(currentForum.Id, threadId));
        if (!response) {
            return;
        }

        dispatch(forumDuck.setThread(response.data));
    },

    /**
     *
     * @param { string } forumId
     * @param { import('./Forum').ForumThreadFormDto } postdata
     */
    createThread: async (forumId, postdata, dispatch) => {

        const url = endPoint.GET_CREATE_THREAD_URL(forumId);

        const response = await instance.post(url, postdata);
        if (!response) {
            return;
        }
        dispatch(forumDuck.addNewThread(response.data));

    },


    /**
     *
     * @param { string } forumId
     * @param { import('./Forum').ForumThreadFormDto } postdata
     */
    createReply: async (forumId, postdata, dispatch, getState) => {

        const url = endPoint.GET_CREATE_THREAD_URL(forumId);



        const response = await instance.post(url, postdata);
        if (!response) {
            return;
        }

        dispatch(forumDuck.addNewChildThread({ replyTo: postdata.replyTo, data: response.data }));

        /**
         * @type {import('./Forum').ForumState}
         */
        var state = getState().forum;

        if (state.currentThread.ConnectedScreenCuePoint) {
            dispatch(lectureDuck.setCurrentCuePointAsPassed());
        }

    },


    /**
     * 
     * @param { string } forumId 
     * @param { number } threadId
     */
    fetchReaders: (forumId, threadId) => async (dispatch) => {

        const url = endPoint.GET_FORUM_READERS_URL(forumId, threadId);
        const response = await instance.get(url);
        if (!response) {
            return;
        }
        await dispatch(forumDuck.setReadersOfThread(response.data));

    },

    /**
     * 
     * @param { {forumId: string, threadId:number, parentId: number, state: boolean} } params 
     */
    setPostingPublishState: ({ forumId, threadId, parentId, state }) => async (dispatch) => {


        const url = endPoint.GET_FORUM_PUBLISH_URL(forumId, threadId);
        await instance.post(url, { Value: state });
        await dispatch(forumDuck.postingPublishChanged({ forumId, threadId, parentId, state }));
    },


    /**
     * 
     * @param { string } userId 
     * @param { number } filterType
     * @param { string } subGroupId?
     * 
     * 
     */
    filterData: (userId, filterType, subGroupId) => async (dispatch, getState) => {

        /**
         * @type {import('./Forum').ForumState}
         */
        const forumData = getState().forum;
        const oldUserId = forumData.userId;
        const oldType = forumData.filterType;

        if (forumData.currentForum != null) {
            dispatch(forumDuck.setFilterData({ userId: userId, filterType: filterType }));
        }
        /**
         * @type { import('axios').AxiosResponse<import('./Forum').ForumPageDto>}
         */
        let response = null;
        if (oldUserId !== userId || oldType !== filterType) {
            if (subGroupId) {
                const classId = getState().classData.currentClass.Id;
                response = await instance.get(endPoint.GET_FORUM_FOR_BASEGROUP_URL(classId, subGroupId, userId, filterType));
            }
            else {
                response = await instance.get(endPoint.CLASS_FORUM_START_URL(forumData.currentForum.Id, userId, filterType));
            }
            if (!response) {
                return;
            }
            dispatch(forumDuck.setOtherPageData(response.data));
        }
    },


    /**
     * 
     * @param { string } forumId 
     * @param { number } threadId
     * @param { number | undefined } parentId
     */
    deletePosting: (forumId, threadId, parentId) => async (dispatch) => {
        const url = endPoint.GET_THREAD_URL(forumId, threadId);
        await instance.delete(url);
        await dispatch(forumDuck.threadIsDeleted({ forumId, threadId, parentId }));
    },

    /**
     * 
     * @param { string } forumId 
     * @param { string } classId 
     */
    setAllAsRead: (forumId, classId) => async (dispatch) => {


        const url = endPoint.GET_SET_THREAD_AS_READ_URL(forumId);
        const response = await instance.post(url);

        dispatch(forumDuck.setStartData({ classId: classId, dto: response.data }));
    },


    /**
     * 
     * @param { string } forumId 
     * @param { number } state 
     */
    setSubscription: (forumId, state) => async (dispatch) => {

        if (state > -1) {
            const url = endPoint.GET_SET_FORUM_SUBSCRIPTION(forumId, state);
            const response = await instance.post(url);
            if (response) {
                dispatch(forumDuck.setSubscriptionStateOfThread(state));
                return response.data;
            }
        }
        else {
            const url = endPoint.GET_DELETE_FORUM_SUBSCRIPTION(forumId);
            const response = await instance.delete(url);
            if (response) {
                dispatch(forumDuck.setSubscriptionStateOfThread(state));
                return response.data;
            }
        }

        // dispatch(forumDuck.setStartData({ classId: classId, dto: response.data }));
    },


    /**
     *
     * @param { import('./Forum').ForumEditThreadFormDto } postdata
     * @param { 'thread' | 'answer' | 'reply' } postType
     */
    updateArticle: async (postdata, postType, dispatch) => {

        const url = endPoint.GET_UPDATE_POST_URL(postdata.Id);
        /**
         * @type { {data: import('./Forum').ForumThreadDto} }
         */
        const response = await instance.put(url, postdata);
        if (!response) {
            return;
        }

        switch (postType) {
            case 'thread':
                dispatch(forumDuck.updateCurrentThread(response.data));
                break;

            case 'answer':
                dispatch(forumDuck.updateCurrentThreadAnswer(response.data));
                break;
            case 'reply':
                dispatch(forumDuck.updateCurrentThreadAnswerReply(response.data));
                break;
            default:
                break;
        }



    },


    /**
     *
     * @param { string } forumId
     * @returns {Promise<import('types/types').IdAndNameDto[]>}
     */
    getTestsToConnect: async (forumId) => {

        const url = endPoint.GET_TEST_FOR_CONNECTION_URL(forumId);

        /**
         * @type { import('axios').AxiosResponse<import('types/types').IdAndNameDto[]>}
         */

        const response = await instance.get(url);
        if (!response) {
            return null;
        }

        return response.data;
    },

    /**
     * @param { import('./Forum').ForumDraft } data
     */
    saveDraft: async (data) => {
        try {
            const id = data.id;
            //delete data.id;

            var post = await lnDataBase.forumDraft.get(id);
            if (!post) {
                throw new Error("no data with id = " + id);
            }

            // only update date if no file or changing file
            // since temp bucket only keep files for 2 days
            if (!!!data.file || (post.file && post.file.key !== data.file.key)) {
                data.date = new Date();
            }
            return await lnDataBase.forumDraft.update(id, data);
        } catch (err) {
            // swallow it. In case the IndexDB is not available
            return;
        }
    },

    /**
    *
    * @param { {forumId:string, replyTo?: number, userId: string} } query
    * @returns { Promise<import('./Forum').ForumDraft> }
    */
    getDraft: async (query) => {

        try {
            let posts;
            if (!query.replyTo) {
                posts = await lnDataBase.forumDraft
                    .where({ userId: query.userId, forumId: query.forumId }).toArray();
                if (posts) {
                    posts = posts.filter(p => !!!p.replyTo);
                }
            }
            else {
                posts = await lnDataBase.forumDraft
                    .where({ userId: query.userId, forumId: query.forumId, replyTo: query.replyTo }).toArray();
            }

            if (!posts || posts.length === 0) {
                const data = {
                    forumId: query.forumId,
                    replyTo: query.replyTo,
                    article: "",
                    header: "",
                    userId: query.userId,
                    date: new Date()
                }
                const id = await lnDataBase.forumDraft.add(data, null);
                data.id = id;

                return data;
            }
            else {
                return posts[0];
            }
        }
        catch (err) {
            // swallow it. In case the IndexDB is not available
            return {
                forumId: query.forumId,
                replyTo: query.replyTo,
                article: "",
                header: "",
                userId: query.userId,
                date: new Date(),
                id: new Date().getTime(),
            };

        }
    },

    deleteDraft: (id) => {
        try {
            lnDataBase.forumDraft.delete(id);
        }
        catch (err) { }
    }



}

const forumDuck = {
    ...forumSlice.actions
};

export default forumSlice.reducer;