//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable"
import { lectureDuck } from "./LectureDuck";
import useTypedSelector from "utils/useTypedSelector";
import ForumThread from "features/classes/ClassItems/Forum/ForumThread";
import { useEffect } from "react";
import {  forumService } from "features/classes/ClassItems/Forum/ForumDuck";

const ForumThreadInLectureDialog = (props) => {

    const dispatch = useDispatch();
    const { showForumDialog }
        = useTypedSelector(state => state.lecture);


    // clean up at dialog close
    useEffect(() => {
        const d = dispatch;
        return () => {
            d(forumService.setInLectureDialog(false));
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeForumDialog = (postedOk) => {
        dispatch(lectureDuck.setShowForumDialog(false));
        dispatch(forumService.setInLectureDialog(true));
        if( postedOk){
            window.setTimeout(()=> dispatch(lectureDuck.setWantedPlayingState(true)), 20 );
        }
    }


    return (

        <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>
            <Modal container={props.container} role="dialog" isOpen={showForumDialog} onClosed={closeForumDialog} toggle={closeForumDialog} size="lg" backdrop='static'>

                <ModalHeader toggle={closeForumDialog} />
                <ModalBody className={""} tabIndex={-1}>
                    <ForumThread onLeaveThreadClick={closeForumDialog} />
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>
        </Draggable>
    )
}
export default ForumThreadInLectureDialog;
