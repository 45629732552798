//@ts-check
import React from "react";
import classes from './lecture.module.scss';

/**
 * 
 * @param { { className:string, values: { left: string, width: string }[] } }  props
 */
const Segments = (props) => {
    

    return (
        <div className={classes.segments + " " + props.className} >
            { props.values.map((val,i)=>{
                return <div key={i} style={ {left: val.left, width: val.width}  } ></div>
            }) }
        </div>
    )
}

export default Segments;