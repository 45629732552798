//@ts-check
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useTypedSelector from 'utils/useTypedSelector';
import UserImg from "components/UserImg";
import { endPoint } from "AppConstants";
import FileIcon from "components/FileIcons/FileIcon";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import classes from './forum.module.scss';
import { Confirm, ModalConstants } from "components/OnlineModal";
import { forumService } from "./ForumDuck";
import ShowImage from "./ShowImage";
import ForumAnswerReplyCard from "./ForumAnswerReplyCard";
import WaitRipple from "components/WaitRipple";
import ForumSimpleForm from "./ForumSimpleForm";
import EditForumForm from "./EditForumForm";
import LnDropDownMenu from "components/LnDropDownMenu";
import { DropdownItem, DropdownMenu, UncontrolledTooltip } from "reactstrap";
import LnResultIcon from "components/LnResultIcon";

/**
 * ForumAnswerCard
 * @module features/classes/ClassItems/Forum/ForumAnswerCard
 * @return an Card with forum answer/answer detail.
 *
 * @param {{
    answer: import("./Forum").ForumThreadDto,
    isTeacher: boolean
    onCommentTest: Function,
    readonly?: boolean
}} props
 */

const ForumAnswerCard = ({ answer, isTeacher, onCommentTest }) => {
    const dispatch = useDispatch();
    const { languageService: t } = useI18n();
    const { currentForum, currentThread, userId, inLectureDialog } = useTypedSelector(state => state.forum);
    const [showForm, toggleShowForm] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const readonly = inLectureDialog;

    /**
     * @type {{current: HTMLDivElement}}
     */
    const articleDiv = useRef();

    const getArticleText = () => {
        if (articleDiv.current) {
            return articleDiv.current.innerText;
        }

        return null;
    }

    const toggleForm = () => {
        toggleShowForm(old => !old);
    };

    const editAnswer = () => {
        setEditMode(true);
    }

    const setPublished = () => {
        dispatch(forumService.setPostingPublishState({ forumId: currentForum.Id, threadId: answer.Id, parentId: null, state: !answer.Published }));
    };


    const deleteCurrent = async () => {
        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("comment.delete"),
            message: (<div>{t.getText("forum.delete.reply")}</div>),
            languageService: t,
            type: "yes/no"
        });

        if (result === ModalConstants.Yes) {
            dispatch(forumService.deletePosting(currentForum.Id, answer.Id, undefined));
        };
    };


    if (!answer) {
        return <WaitRipple />
    }


    return (
        <div className={['mx-5 d-md-flex', classes['forum-answer']].join(' ')}>
            <div className="d-none d-md-block">
                {answer.Writer.ImageUrl ? <UserImg src={endPoint.API_HOST + answer.Writer.ImageUrl} className={['rounded-circle', 'avatar-img', classes['answer-avatar']].join(' ')} alt={answer.Writer.FullName} /> : <LnIcon className={['rounded-circle', 'avatar-img', classes['answer-avatar']].join(' ')} name="user-icon" />}
            </div>
            <div className={["card  mb-5", classes['answer-card'], (answer.Writer.Id === userId && "bg-primary-lightest p-3")].join(' ')}>
                <div className={"card-title d-flex justify-content-between "}>
                    <h5 className={"d-flex " + classes['forum-title']}>
                        <div className="d-md-none">
                            {answer.Writer.ImageUrl ? <UserImg src={endPoint.API_HOST + answer.Writer.ImageUrl} className={['rounded-circle', 'avatar-img', classes['answer-avatar']].join(' ')} alt={answer.Writer.FullName} /> : <LnIcon className={['rounded-circle', 'avatar-img', classes['answer-avatar']].join(' ')} name="user-icon" />}
                        </div>
                        <div>
                            {answer.Writer.FullName}

                            {answer.TestResult && <div className={isTeacher ? "pointer" : ""} id={"res_" + answer.TestResult.Id} onClick={() => onCommentTest(answer.TestResult.Id)} >
                                <LnResultIcon status={answer.TestResult.Status} className="" />

                                <UncontrolledTooltip placement="auto-start" autohide={false} target={"res_" + answer.TestResult.Id} trigger={'hover'}
                                    delay={{ show: 300, hide: 0 }}>
                                    <div className="d-flex">

                                        <div>
                                            <strong>{answer.TestResult.TestName}</strong>
                                            <div className="small text-secondary">
                                                {answer.TestResult.TestDate && <span >{t.getText("submitted")} {t.getShortDateTimeString(answer.TestResult.TestDate)} </span>}
                                            </div>

                                        </div>


                                    </div>

                                    {answer.TestResult.CommentDate
                                        && <div>
                                            <strong>{t.getText("teachers_comment")}</strong>
                                            <div className="d-flex align-items-center">
                                                <LnResultIcon className="icon-small mr-2" status={answer.TestResult.Status} />
                                                <div>{t.getText(answer.TestResult.Status)}</div>
                                            </div>
                                            <div className="small text-secondary"> {answer.TestResult.Teacher.FullName} {t.getShortDateTimeString(answer.TestResult.CommentDate)} </div>


                                            <span dangerouslySetInnerHTML={{ __html: answer.TestResult.TeachersComment }}></span>
                                        </div>
                                    }

                                </UncontrolledTooltip>

                            </div>
                            }
                        </div>
                    </h5>



                    <div className="d-flex">
                        <small className="d-flex flex-row flex-sm-column align-items-end">
                            <div className={((!answer.Edited && answer.Unread) ? " badge-warning badge badge-pill" : "")}>
                                {t.getShortDateTimeString(answer.Created)}
                            </div>
                            {answer.Edited && <div className={"mt-2 d-flex " + (answer.Unread && " badge-warning badge badge-pill")}>
                                <div>{t.getText("edited")}&nbsp;</div>
                                <div>{t.getShortDateTimeString(answer.Edited)}</div>
                            </div>}
                        </small>
                        <div className="d-flex flex-md-column  flex-row">
                            {!readonly && (answer.IsModerator || answer.CanEdit) &&
                                <LnDropDownMenu
                                    direction="down"
                                    caret={false}
                                    toggleComponent={
                                        <button type="button" className="edit round" title={t.getText("edit")} >
                                            <LnIcon className="icon-small" name="edit-icon" alt="Edit" />
                                        </button>
                                    }
                                    toogleClassName=""
                                    className="ml-3 mb-3"
                                    toogleTagName="div"
                                >
                                    <DropdownMenu right className="" container="body">
                                        <div className="d-flex flex-column">
                                            {answer.CanEdit &&
                                                <DropdownItem onClick={() => editAnswer()} tag="span" >{t.getText("edit")}</DropdownItem>
                                            }
                                            {(answer.IsModerator || answer.CanDelete) &&
                                                <DropdownItem onClick={() => deleteCurrent()} tag="span" >{t.getText("delete")}</DropdownItem>
                                            }

                                        </div>
                                    </DropdownMenu>
                                </LnDropDownMenu>
                            }
                            { !readonly && answer.IsModerator &&
                                <button type="button" onClick={setPublished} className={"ml-3 edit round " + (answer.Published ? "" : "inverse")}>
                                    <LnIcon className="small" name={answer.Published ? "eye-icon" : "eye-icon-off"} />
                                </button>

                            }



                        </div>

                    </div>


                </div>
                <div className="d-flex">
                    {(answer.Attachment)
                        && <div className="mr-4">
                            {
                                answer.Attachment.MediaType === 1 ?
                                    <button type="button" className="btn btn-link" onClick={() => ShowImage(endPoint.API_HOST + answer.Attachment.Url, answer.Attachment.FileName, t)}>
                                        <img src={endPoint.API_HOST + answer.Attachment.ThumbUrl} alt={answer.Attachment.FileName} />
                                    </button>
                                    : <a href={endPoint.API_HOST + answer.Attachment.Url}>
                                        <FileIcon className={[classes['file-icon']].join(' ')} size="512px" name={(answer.Attachment.FileName || '').split('.').pop()} /><br />
                                        {answer.Attachment.FileName}
                                    </a>
                            }
                        </div>}
                    <div className="mb-3 flex-grow-1">
                        {!editMode ? <>
                            <div ref={articleDiv} className="word-break-word card-text max-col-width" dangerouslySetInnerHTML={{ __html: answer.Article }}></div>

                            {(answer.Attachment && answer.Attachment.MediaType === 3) &&
                                <audio className="mt-3" controls={true} src={endPoint.API_HOST + answer.Attachment.Url}></audio>
                            }

                            {(answer.Attachment && answer.Attachment.MediaType === 2) &&
                                <video className="mt-3" controls={true} src={endPoint.API_HOST + answer.Attachment.Url} ></video>
                            }
                        </>
                            :
                            <EditForumForm onLeaveThread={undefined} forumId={currentForum.Id} parentId={currentThread.Id} postType="answer" fileOptions={{
                                imageFileTypes: currentForum.ImageFileTypes,
                                videoFileTypes: currentForum.VideoFileTypes,
                                audioFileTypes: currentForum.AudioFileTypes,
                                AcceptFileTypes: currentForum.AcceptFileTypes,
                                MaxFileSize: currentForum.MaxFileSize
                            }} attachment={answer.Attachment}
                                article={getArticleText()}
                                showFileUpload={true}
                                postid={answer.Id}
                                toggleForm={() => setEditMode(false)} />
                        }


                    </div>



                </div>

                {answer.Answers && answer.Answers.length > 0 &&
                    <div className="mt-4">
                        {answer.Answers.map(reply => {
                            return <ForumAnswerReplyCard key={reply.Id} reply={reply} parent={answer} />
                        })}
                    </div>
                }


                {!readonly && !showForm && answer.Published ? <div className="pl-4 mb-3">
                    <button type="button" className="btn btn-link btn-small" onClick={() => toggleForm()}>{t.getText('reply')} </button></div> : null
                    }
                {showForm && <div>
                    <ForumSimpleForm replyTo={answer.Id} forumId={answer.ForumId} toggleForm={toggleForm} />
                </div>}
            </div>


        </div>
    );
};

export default ForumAnswerCard;