//@ts-check
import React from "react";



/**
 * MailMeta
 * @module components/Mail/MailMeta
 * @return HTML formatted meta info about a mail.
 * @param {{
    mailData:any,
    t: any
}} props
 */
const MailMeta = ({ mailData, t }) => {

    return (
                <div>
                    {mailData.Queued ? <small>{t.getText('mail.was.sent', [ t.getShortDateTimeString(mailData.Queued) , ' '])}</small> : mailData.Created ? <small>{t.getText('was.created', [ t.getShortDateTimeString(mailData.Created) , ' '])}</small> : null}
                </div>
    )

}
export default MailMeta;
