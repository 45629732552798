//@ts-check
import React from "react";
import ScrollContent from 'components/ScrollContent';
import { useI18n } from "components/lni18n";
import WaitRipple from "components/WaitRipple";
import useTypedSelector from "utils/useTypedSelector";
import Link from "redux-first-router-link";
import { UncontrolledTooltip, Progress } from 'reactstrap';
import classes from './myprogress.module.scss';
import ChunkOfNodes from "components/ChunkOfNodes";


const UserWatchedLectures = (props) => {

    const { languageService: t } = useI18n();
    const { userProgress } = useTypedSelector(state => state.user);
    const currentClass = useTypedSelector(state => state.classData.currentClass);



    if (userProgress == null) {
        return <ScrollContent scrollname="class-myprogress">
            <div className="card-box big-card-box">
                <WaitRipple />
            </div>
        </ScrollContent>
    }




    return (
        <div>

            <table className={"mb-4 table table-condensed table-table-bordered " + classes.courseTable}>
                <thead>
                    <tr>
                        <td>
                            <div>{t.getText("theme")}</div>
                        </td>
                        <td>
                            <div>{t.getText("coursepart")}</div>
                        </td>
                        <td>
                            <div>{t.getText("lecture")}</div>
                        </td>

                        <td>
                            <div>&nbsp;</div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        userProgress.Themes.filter(t => t.CourseParts && t.CourseParts.length).map(theme => {
                            const rowspan = theme.CourseParts
                                .map(p => p.WatchedLectures && p.WatchedLectures.length ? p.WatchedLectures.length : 1).reduce((tot, n) => tot + n);
                            const part1 = theme.CourseParts[0];

                            return <ChunkOfNodes key={theme.Id}>
                                <>
                                    <tr >
                                        <td rowSpan={rowspan}>
                                            <div className="text-truncate">{theme.Name}</div>
                                        </td>
                                        <td rowSpan={Math.max(1, part1.WatchedLectures.length)}>
                                            <div className="text-truncate">{part1.Name}</div>
                                        </td>
                                        <LectureRow classid={currentClass.Id} themeid={theme.Id} partid={part1.Id} lect={part1.WatchedLectures[0]} />
                                    </tr>

                                    {part1.WatchedLectures && part1.WatchedLectures.length > 1 && part1.WatchedLectures.slice(1).map(l => {
                                        return <tr key={l.LectureId} >
                                            <LectureRow classid={currentClass.Id} themeid={theme.Id} partid={part1.Id} lect={l} />
                                        </tr>
                                    })}

                                    {theme.CourseParts && theme.CourseParts.length > 1 && theme.CourseParts.slice(1).map((part => {

                                        const lect1 = part.WatchedLectures.length > 0 ? part.WatchedLectures[0] : null;

                                        return <ChunkOfNodes key={part.Id}>
                                            <>
                                                <tr >
                                                    <td rowSpan={Math.max(1, part.WatchedLectures.length)}>
                                                        <div className="text-truncate">{part.Name}</div>
                                                    </td>

                                                    <LectureRow classid={currentClass.Id} themeid={theme.Id} partid={part.Id} lect={lect1} />
                                                </tr>

                                                {part.WatchedLectures && part.WatchedLectures.length > 1 ? part.WatchedLectures.slice(1).map(l => {
                                                    return <tr key={l.LectureId}>
                                                        <LectureRow classid={currentClass.Id} themeid={theme.Id} partid={part.Id} lect={l} />
                                                    </tr>
                                                }) : null}
                                            </>
                                        </ChunkOfNodes>
                                    }))}
                                </>
                            </ChunkOfNodes>
                        })
                    }
                </tbody>
            </table>


        </div>
    )
}

/**
 * 
 * @param {{lect: import('../../Classes').WatchedLectureDto, classid: string, themeid: string, partid:string }} props 
 * @returns 
 */
const LectureRow = ({ lect, classid, themeid, partid }) => {
    return <> <td >

        <div className="text-truncate">
            {lect && <Link to={{
                type: "CLASS_THEME_PART_LECTURE_PLAY", payload: {
                    classid: classid,
                    themeid: themeid,
                    partid: partid,
                    lectureid: lect.LectureId,
                    slide: 1
                }
            }}>{lect.Name}</Link>}
        </div>
    </td>
        <td id={"l" + (lect && lect.LectureId)}>
            {lect && <>
                <div >
                    {lect.Percent > 25 ?
                        <Progress  color={lect.Percent === 100 ? "success" : "warning"} value={lect.Percent} >
                            <span className={ lect.Percent < 100 ? "text-body" : ""}>{lect.Percent}%</span>
                        </Progress>
                        :
                        <Progress  multi>
                            <Progress color="warning"  bar value={lect.Percent}></Progress>
                            <span className="ml-4">{lect.Percent}%</span>
                        </Progress>
                    }
                </div>

                <UncontrolledTooltip placement="right" autohide={false} target={"l" + lect.LectureId} trigger={'hover'}
                    delay={{ show: 100, hide: 0 }}>
                    {lect.Percent} %
                </UncontrolledTooltip>
            </>
            }
        </td>
    </>

}

export default UserWatchedLectures;