//@ts-check
import React, { useEffect, useState } from "react";

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Draggable from "react-draggable"
import { useForm } from "react-hook-form";
import { useDispatch, useStore } from "react-redux";

import FileUpload from "components/FileUpload/FileUpload";
import { useI18n } from "components/lni18n";
import { errorDuck } from "components/ErrorHandler/ErrorHandlerDuck";

import { docBoxDuck } from "./DocBoxDuck";
import { ModalConstants, Confirm } from "components/OnlineModal";

/**
* @param {{
    data:  import('features/General/DocBox/DocBox').DocBoxAddFileDialogData,
    type: 'Start' | 'Shared',
}} props
 */
const FileDialog = ({ data, type }) => {

    const { languageService: t } = useI18n();

    const [dialogData, setDialogData] = useState({
        description: '',
        editedFile: {},
        folderId: null,
        name: '',
        open: false,
        uploadedFile: {}
    })
    const [isSubmitting, setIsSubmitting] = useState(false);

    const dispatch = useDispatch();
    const store = useStore();
    const getState = store.getState;

    const fileOptions = { AcceptFileTypes: "", MaxFileSize: 5242880 };

    const { errors, formState: { isDirty, isValid }, handleSubmit, register, reset, setValue } = useForm({
        defaultValues: { fileDescription: dialogData.description || '', fileId: '' },
        mode: "onChange"
    });


    useEffect(() => {
        let { Description, Folder, Name } = data.file || { Description: null, Folder: null, Name: '' };
        if (!Folder && data.folder) {
            Folder = data.folder.Id;
        }
        setDialogData({
            description: Description,
            editedFile: data.file || {},
            folderId: Folder,
            name: Name,
            open: data.open,
            uploadedFile: {}
        })
        setTimeout(() => {
            reset({ fileDescription: Description, fileId: data.file ? data.file.Id : "" })
        }, 1);
    }, [data, reset]);



    const close = async (checkClose) => {
        if (checkClose && isDirty) {
            const result = await Confirm({
                buttons: [],
                backdrop: 'static',
                class: null,
                title: t.getText("cancel"),
                message: (<div>{t.getText("dirty.continue.without.saving")}</div>),
                languageService: t,
                okOnEnter: true,
                type: "yes/no"
            });

            if (result !== ModalConstants.Yes) {
                return;
            }

        }
        setDialogData({
            description: '',
            editedFile: {},
            folderId: null,
            name: '',
            open: false,
            uploadedFile: {}
        })
    }

    /**
     *
     * @param {{key:string, name:string, size:number, type:string}} f
     */
    const handleFileUploadReady = (f) => {
        setValue('fileId', f.key, { shouldValidate: true });
        setDialogData({
            description: dialogData.description,
            editedFile: data.file || {},
            folderId: dialogData.folderId,
            name: f.name,
            open: dialogData.open,
            uploadedFile: f
        })
    }

    const handleSubmitForm = async (values) => {
        setIsSubmitting(true);
        const postData = {
            Description: values.fileDescription,
            FileId: null,
            Folder: dialogData.folderId,
            Key: dialogData.uploadedFile.key || null,
            Name: dialogData.uploadedFile.name || null
        }
        try {
            if (data.file) {
                postData.FileId = dialogData.editedFile.Id;
                await docBoxDuck.saveEditedFile(dispatch, getState, postData, type);
            } else {
                await docBoxDuck.addFile(dispatch, getState, postData, type);
            }
            setIsSubmitting(false);
            close(false);
        } catch (error) {
            setIsSubmitting(false);
            dispatch(errorDuck.setError({ when: new Date(), header: t.getText('error'), message: t.getText('servererror') + '\n' + error }));
        }

    }


    return <Draggable handle=".modal-header" bounds={{left: -1024, top: 0, right: 1024, bottom: 1024}}>
        <Modal role="dialog" size='lg' isOpen={dialogData.open} backdrop={'static'}>
            <ModalHeader toggle={() => close(true)}>{data.file ? t.getText('change') + ' ' + data.file.Name : t.getText("upload.file")}</ModalHeader>
            <ModalBody tabIndex={-1}>

                <FileUpload className="dox-upload" hideDox={true}
                    imagePreviewOptions={[{ name: "small", maxheight: 100, maxwidth: 100 }]}
                    handleUploadReady={handleFileUploadReady} accept={fileOptions.AcceptFileTypes}
                    languageService={t} maxsize={fileOptions.MaxFileSize} >
                    <form id="fileDescriptionForm" name="fileDescriptionForm" className="mb-5" onSubmit={handleSubmit(handleSubmitForm)} >
                        <input type="text" className="off-screen" name="fileId" ref={register()} />
                        <label htmlFor="feedText">{t.getText('description')}</label>

                        <div className="form-group mb-4"
                        >
                            <textarea ref={register({ required: true })}
                                name="fileDescription"
                                className="form-control"
                                rows={3}
                            />
                            {errors.fileDescription && <p className="errorMessage">{t.getText('required_field')}</p>}
                        </div>
                        <div>
                            {dialogData.name}
                        </div>
                    </form>
                </FileUpload>
            </ModalBody>
            <ModalFooter>
                <button type="submit" disabled={(!dialogData.editedFile.Id && !dialogData.uploadedFile.key)
                    || isSubmitting || !isValid || !isDirty}
                    form="fileDescriptionForm" className="btn btn-primary mr-2" >{t.getText("save.short")}</button>
                <button type="button" className="btn btn-inverse" onClick={() => close(true)}>{t.getText("cancel")}</button>
            </ModalFooter>
        </Modal>
    </Draggable>

}

export default FileDialog;