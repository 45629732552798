// @ts-check
import React, { useEffect } from "react";
import {useI18n} from "components/lni18n";
import { useDispatch } from "react-redux";
import { errorDuck } from "./ErrorHandlerDuck";
import { Alert } from "components/OnlineModal";
import useTypedSelector from "utils/useTypedSelector";

const ErrorHandlerComponent = (props) => {

    const { languageService: t } = useI18n();
    const dispatch = useDispatch();

    const messages = useTypedSelector(state => state.errors.messages);

    const message = messages && messages.length > 0 && messages[0];



    useEffect(() => {
        // error at login. Display as dialog and then reset the error message
        if (message && (message.message || message.htmlMessage)) {

            let messageComponent = <div className="preserve-white">
                {message.message}
            </div>;
            if (message.htmlMessage) {
                messageComponent = <div dangerouslySetInnerHTML={{ __html: message.htmlMessage }} />
            }

            const oktext = message.okText || t.getText("ok");

            Alert({
                backdrop: 'static',
                button: {
                    className: "btn btn-primary",
                    type: "submit",
                    text: oktext
                },
                message: messageComponent,
                title: message.header,
                class: null
            })["finally"](() => // reset message
                dispatch(errorDuck.resetError(message)));
        }
    }, [message, dispatch, t]);

    return <></>


}

export default ErrorHandlerComponent;