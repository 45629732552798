//@ts-check

import React from "react";
import { useDispatch } from "react-redux";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import DateTimePickerDialog from "components/DateTimePicker/DateTimePickerDialog";
import { classesDuck } from "features/classes/ClassesDuck";
import { NavLink } from "redux-first-router-link";
import classes from './dashboard.module.scss';
import useTypedSelector from "utils/useTypedSelector";
import UpdatedFeedPosts from "./UpdatedFeedPosts";
import { uuid } from "uuidv4";
/**
 * EventsUpdateWidget
 * @module features/classes/Dashboard/EventsUpdateWidget
 * @return an LnCard with Class Event Updates.
 * @param {{
    StudentData: import('features/classes/Classes').StudentClassDtoForStudent,
}} props
 */
const EventsUpdateWidget = (props) => {

    const { languageService: t } = useI18n();
    const studentData = props.StudentData;
    const dispatch = useDispatch();
    const eventsSinceDate = useTypedSelector(state => state.classData.eventsSinceDate);
    const currentClass = useTypedSelector(state => state.classData.currentClass);


    const handleDatePickerChange = (d) => {
        console.log(d);
        dispatch(classesDuck.setEventsSinceDate(d));
        classesDuck.getCurrentClass(dispatch, studentData.StudentClass.Id, d);


    }

    let componentList = [];

    if (studentData.Changes) {

        componentList.push(< UpdatedFeedPosts key={"d" + uuid} feedPosts={studentData.Changes.FeedPosts} onlyLast={true} />)

    }


    return (
        <div className={['card-box big-card-box card ln-card mr-5 mb-5', classes['widget-card']].join(' ')}>
            <div className={["card-header", classes['card-header']].join(' ')}>
                <LnIcon className="header-icon" name="event-icon" />
                <span className="mr-2">
                    {t.getText('dashboard.datedelay')}
                </span>
                <DateTimePickerDialog Date={eventsSinceDate} onChange={(d) => handleDatePickerChange(d)} showIcon renderDays={'past'} />
            </div>
            <div className="card-body pt-5">
                {/* eslint-disable-next-line  */}
                {(!studentData.Changes ||
                    ((!studentData.Changes.FeedPosts || studentData.Changes.FeedPosts.length < 1)
                        && (!studentData.Changes.Forums || studentData.Changes.Forums.length < 1)
                        && (!studentData.Changes.ClassFiles || studentData.Changes.ClassFiles.length < 1)
                        && (!studentData.Changes.SubGroups || studentData.Changes.SubGroups.length < 1)

                        && (!studentData.Changes.CourseParts || studentData.Changes.CourseParts.length < 1)))
                    && <div className="preserve-white">{t.getText("dashboard.no.events.explain")}</div>

                }


                {studentData.Changes &&
                    <table key="studdata" className="table table-small">
                        <tbody>
                            {studentData.Changes.FeedPosts.length > 0 &&
                                <tr>
                                    <td>
                                        <NavLink to={{ type: "CLASS_FEED", payload: { classid: currentClass.Id } }}>{t.getText("feed.menuname")}</NavLink>
                                    </td>
                                    <td>
                                        {t.getText("new.messages")}
                                    </td>
                                    <td>
                                        {studentData.Changes.FeedPosts.length}
                                    </td>
                                </tr>
                            }

                            {studentData.Changes.Forums.length > 0 &&
                                <tr>
                                    <td>
                                        <NavLink to={{ type: "CLASS_FORUM", payload: { classid: currentClass.Id } }}>{t.getText("forum")}</NavLink>
                                    </td>
                                    <td>
                                        {t.getText("new.messages")}
                                    </td>
                                    <td>
                                        {studentData.Changes.Forums.length}
                                    </td>
                                </tr>
                            }

                            {studentData.Changes.ClassFiles.length > 0 &&
                                <tr>
                                    <td>
                                        <NavLink to={{ type: "CLASS_FILES", payload: { classid: currentClass.Id } }}>{t.getText("files")}</NavLink>
                                    </td>
                                    <td>
                                        {t.getText("new.class.files")}
                                    </td>
                                    <td>
                                        {studentData.Changes.ClassFiles.length}
                                    </td>
                                </tr>
                            }

                            {studentData.Changes.CourseParts.length > 0 &&
                                <tr>
                                    <td>
                                        <NavLink to={{ type: "CLASS_CHANGES", payload: { classid: currentClass.Id } }}>{t.getText("course.content")}</NavLink>
                                    </td>
                                    <td>
                                        {t.getText("updates")}
                                    </td>
                                    <td>
                                        {studentData.Changes.CourseParts.length}
                                    </td>
                                </tr>
                            }

                            {studentData.Changes.SubGroups.length > 0 &&
                                studentData.Changes.SubGroups.map(sg => <React.Fragment key={sg.Id}>
                                    <tr>
                                        <td colSpan={3} className="font-weight-bold">
                                            {t.getText("subgroup")} {sg.Name}
                                        </td>
                                    </tr>
                                    {sg.Forums && <tr>
                                        <td>
                                            <NavLink to={{ type: "CLASS_SUBGROUP", payload: { classid: currentClass.Id, groupid: sg.Id, type: 'forum' } }}>{t.getText("forum")}</NavLink>
                                        </td>
                                        <td>
                                            {t.getText("new.messages")}
                                        </td>
                                        <td>
                                            {sg.Forums.length}
                                        </td>
                                    </tr>}
                                    </React.Fragment>
                                )
                            }


                        </tbody>
                    </table>}
            </div>
        </div>
    )
}

export default EventsUpdateWidget;