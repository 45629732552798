//@ts-check
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { endPoint } from "AppConstants";
import { useI18n } from "components/lni18n";
import useTypedSelector from "utils/useTypedSelector";
import { feedDuck } from "./FeedDuck";
import classes from './feed.module.scss';
import UserImg from "components/UserImg";
import HtmlTextEditor from "components/TextEditor/HtmlTextEditor";
import { debouncer } from "utils/debouncer";

/**
 * FeedForm
 * @module features/classes/ClassItems/Feed/FeedForm
 * @return a feed form.
 * @param {{
    toggleForm: any,
    postId?:number,
    commentId?: number,
    feedId: string,
    isComment: boolean,
    initialValue: string,
    simpleUI?: boolean
}} props
 */

const FeedForm = (props) => {
    const { languageService: t } = useI18n();
    const dispatch = useDispatch();
    const user = useTypedSelector(state => state.login.user);
    const userImage = endPoint.GET_USER_IMAGE_URL(user.Id);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const toggleForm = props.toggleForm;
    const feedId = props.feedId;
    const { postId, isComment, commentId, simpleUI } = props;
    const debouncerRef = useRef(null);
    const draftIdRef = useRef(null);
    const [initialText, setInitialText] = useState(null);

    const editorSettings = {
        menubar: false,
        plugins: "autolink,paste",
        smart_paste: true,
        paste_data_images: false,
        toolbar: false,
        browser_spellcheck: true,
        contextmenu: false,
        valid_elements: "@[id|data*],-strong/b,-em/i,#p," +
            "-div,br,-span,iframe[src|width|height|allowFullscreen]" +
            ",-a[href|target=_blank],img[src|alt|title|width|height|align|data-id]",
    }

    const { register, handleSubmit, errors, formState, setValue, watch, getValues } = useForm({
        resolver: yupResolver(Yup.object({
            feedText: Yup.string()
                .required(t.getText('servermessage.empty')),
        })),
        defaultValues: { feedText: '' },
        mode: "onBlur"
    });

    const watchAll = watch();

    const { isValid, isDirty } = formState;


    /* At changes */
    useEffect(() => {
        if (debouncerRef.current) {
            debouncerRef.current.debounce();
        }
    }, [watchAll]);

    const upDateDraft = useCallback(() => {

        if (!draftIdRef.current) return;

        const values = getValues();
        /**
         * @type{ import("./Feed").FeedDraft }
         */
        const data = {
            id: draftIdRef.current,
            feedId: feedId,
            commentTo: isComment ? postId : null,
            text: values.feedText,
            userId: user.Id
        }
        feedDuck.saveDraft(data);

    }, [getValues, isComment, postId, user, feedId]);


    /* Start up */

    useEffect(() => {
        const d = new debouncer(() => upDateDraft(), 300);
        debouncerRef.current = d;
        return () => d.clear();
    }, [upDateDraft]);

    /* Start up */
    useEffect(() => {

        if (props.initialValue && props.initialValue !== "") {
            setInitialText(props.initialValue);
            return;
        }

        if (user && feedId) {
            const query = { feedId, userId: user.Id };
            if (isComment) {
                query.commentTo = postId;
            }
            feedDuck.getDraft(query)
                .then(data => {
                    if (data) {
                        // setValue("feedText", data.text);
                        setInitialText(data.text);
                        draftIdRef.current = data.id;
                    }
                });

        }
    }, [setValue, user, feedId, isComment, postId, dispatch, props.initialValue]);

    const handleSubmitForm = async (values) => {
        setIsSubmitting(true);
        try {
            if (isComment) {
                if (!commentId) {
                    await dispatch(feedDuck.createFeedComment({
                        feedId: feedId,
                        postId: postId,
                        text: values.feedText
                    }));

                }
                else {
                    await dispatch(feedDuck.updateFeedComment({
                        dto: {
                            feedId: feedId,
                            postId: postId,
                            text: values.feedText
                        },
                        commentId: commentId
                    }));
                }
            } else {
                if (postId) {
                    await feedDuck.updateFeedPost({ Text: values.feedText }, postId, feedId, dispatch);
                }
                else {
                    await feedDuck.createFeedPost({ Text: values.feedText }, feedId, dispatch);
                }

            }

            if( draftIdRef.current !== null){
                feedDuck.deleteDraft(draftIdRef.current);
            }

        } finally {
            setIsSubmitting(false);
            toggleForm();
        }
    }

    return (
        <div className="d-flex">
            {initialText !== null && <>
                {!simpleUI && <UserImg src={userImage} className={['rounded-circle', 'avatar-img', classes['comment-avatar']].join(' ')} alt={t.getText('user')} />}
                <form className={["mb-5 w-100", (simpleUI ? "" : classes['feed-form'])].join(' ')} onSubmit={handleSubmit(handleSubmitForm)} >
                    {!simpleUI && <label htmlFor="feedText">{t.getText('message')}</label>}

                    <div className="form-group mb-4">

                        <HtmlTextEditor className="bg-white" register={register} setValue={setValue} editorSettings={editorSettings} initialValue={initialText} name="feedText"  ></HtmlTextEditor>

                        {/* <textarea ref={register}
                        onKeyPress={textKeyPress}
                        name="feedText"
                        className="form-control"
                        rows={6}
                    /> */}
                        <ErrorMessage as="div" errors={errors} className="errorMessage" name="feedText" />
                    </div>

                    <div className="mb-4 d-flex justify-content-end">
                        <button className="btn btn-primary btn-small mr-4"
                            disabled={isSubmitting && !isValid && isDirty}
                            type="submit">{t.getText('save.short')}
                        </button>

                        <button className="btn btn-inverse btn-small" type="button" onClick={() => toggleForm()}>{t.getText('cancel')} </button>
                    </div>
                </form>
            </>}
        </div>
    )

}
export default FeedForm;
