//@ts-check
import React from "react";
import UserImg from "components/UserImg";
import { endPoint } from "AppConstants";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import classes from '../forum.module.scss';

/**
 
 * @param {{
    reply: import("../Forum").ForumThreadDto,
}} props
 */

const ThreadAnswerReplyCard = ({ reply }) => {
    const { languageService: t } = useI18n();


    return (
        <div className={['ml-3 mr-1 d-md-flex', classes['forum-answer']].join(' ')}>
            <div className="d-none d-md-block">
                {reply.Writer.ImageUrl ? <UserImg src={endPoint.API_HOST + reply.Writer.ImageUrl} className={'rounded-circle avatar-img small ' + classes['answer-avatar']} alt={reply.Writer.FullName} /> : <LnIcon className={'rounded-circle avatar-img small ' + classes['answer-avatar']} name="user-icon" />}
            </div>
            <div className={["border card  mb-2", classes['answer-card']].join(' ')}>
                <div className={"card-title d-flex justify-content-between "}>
                    <h5 className={"d-flex " + classes['forum-title']}>
                        <div className="d-md-none">
                            {reply.Writer.ImageUrl ? <UserImg src={endPoint.API_HOST + reply.Writer.ImageUrl} className={'rounded-circle avatar-img small ' + classes['answer-avatar']} alt={reply.Writer.FullName} /> : <LnIcon className={'rounded-circle avatar-img small ' + classes['answer-avatar']} name="user-icon" />}
                        </div>
                        {reply.Writer.FullName}

                    </h5>
                    <div className="d-flex">
                        <small className="d-flex flex-row flex-sm-column align-items-end">
                            <div className={"mr-2 " + ((!reply.Edited && reply.Unread) ? " badge-warning badge badge-pill" : "")}>
                                {t.getShortDateTimeString(reply.Created)}
                            </div>
                            {reply.Edited && <div className={"mt-2 d-flex " + (reply.Unread && " badge-warning badge badge-pill")}>
                                <div>{t.getText("edited")}&nbsp;</div>
                                <div>{t.getShortDateTimeString(reply.Edited)}</div>
                            </div>}
                        </small>
                    </div>

                </div>

                <div className="d-flex">
                    <div className="mb-3 flex-grow-1">
                        <div className="word-break-word card-text max-col-width" dangerouslySetInnerHTML={{ __html: reply.Article }}></div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ThreadAnswerReplyCard;