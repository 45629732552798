import React from 'react';
import instance from "./axios";
import { endPoint, release } from "AppConstants";
import { Alert } from "components/OnlineModal";
import languageService from "features/language/languageService";


class SystemInfoService {

    async viewInfo(store): void {
       
       let postData = { ReleaseId: release };

       const { currentClass } = store.getState().classData;
       const { user } = store.getState().login;
        if (currentClass) {
            postData.classId = currentClass.Id;
        }

        let serverReleaseId = null;
        
        try {
            /**
             * @type  {{data: import('../components/Pinger').LoginPingDto}}
             */
            const result = await instance.post(endPoint.PING_URL, postData);
            serverReleaseId = result.data.Release;
        } catch (error) {
            
        }

        const info = `Browser: ${window.navigator.userAgent}
        ApplicationId-Browser: ${release}
        ApplicationId-Server: ${serverReleaseId}
        Language: ${languageService.currentLanguage.DisplayName}
        User: ${user.FullName}, state ${user.UserState}`;
 

       Alert({
        size: "lg",
        backdrop: "static",
        title: languageService.getText("menu.systeminfo"),
        message: <div className='preserve-white'>{info}</div>,
        button: {
            className: "btn btn-primary",
            text: languageService.getText("ok"),
            type: "submit"
        }
    });

    }

    
   
}

const systemInfoService = new SystemInfoService();
export default systemInfoService;