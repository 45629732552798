import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import {shallowEqual} from "react-redux";
import WaitRipple from "components/WaitRipple";

import McTest from "../McTest/McTest";
import QATest from "../QATest/QATest";
import MQATest from "../MQATest/MQATest";
import FileTest from "../FileTest/FileTest";
import ConfirmTest from "../ConfirmTest/ConfirmTest";
import DrawingTest from "../DrawingTest/DrawingTest";

const testViews = {
    multiplechoice: <McTest />,
    multiplechoice2: <McTest />,
    singlequestion: <QATest />,
    selfassessement: <QATest />,
    simpleselfassessement: <QATest />,
    multiplequestion: <MQATest />,
    fileupload: < FileTest />,
    simplyreply: < ConfirmTest/>,
    drawingtest: <DrawingTest />
}

const RunTest = (props) => {

    const { testReady, testType } = useTypedSelector(state => state.course, shallowEqual)

    if (!testReady) {
        return <WaitRipple />;
    }

    return testViews[testType];
}

export default RunTest;
