//@ts-check
import React from "react";
import { useDispatch, shallowEqual } from "react-redux";

import { mcTestDuck } from "./McTestDuck";
import { useI18n } from "components/lni18n";
import useTypedSelector from "utils/useTypedSelector";
import McTestCheckOptions from "./McTestCheckOptions";
import McTestRadioOptions from "./McTestRadioOptions";
import LnIcon from "components/LnIcon";
import LnResultIcon from "components/LnResultIcon";

const MCTestQuestion = (props) => {


    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const { currentTest, currentMCQuestion, studyState } = useTypedSelector(state => state.mctest, shallowEqual);
    const { currentTestId: testId } = useTypedSelector(state => state.course);
    const dispatch = useDispatch();
    const { languageService: t } = useI18n();



    let numOfQuestions = currentMCQuestion.data.QuestionCount;
    let questionIndex = currentMCQuestion.data.Index + 1;

    let textBgClass = "";

    if (currentTest.StudyMode) {

        switch (studyState) {

            case "fail":
                textBgClass = "mt-4 alert alert-danger";
                break;

            case "ok":
            case "submitted":
                textBgClass = "mt-4 alert alert-success";
                break;

            default:
                textBgClass = "mt-4 alert alert-grey";
                break;
        }

    }
    else {
        textBgClass = "mt-4 alert " + (currentMCQuestion.pending ? (currentMCQuestion.WasCorrect ? "alert-success" : "alert-danger") : "alert-grey");
    }


    const submitAnswer = () => {

        const chosenoptions = currentMCQuestion.data.Answers.filter(a => a.Checked === true).map(a => a.Index);

        const payload = {
            classid: currentClass.Id,
            testid: testId,
            sessionid: currentTest.TestSessionID,
            index: currentMCQuestion.data.Index,
            choices: chosenoptions
        }

        dispatch(mcTestDuck.answerMcTest(payload));
    }


    const toNextQuestion = () => {
        dispatch(mcTestDuck.setNextMCQuestion({}));
    }

    let options = null;
    if (currentMCQuestion.data.CanSelectMultipleAnswers) {
        options = <McTestCheckOptions
            currentTest={currentTest}
            currentQuestion={currentMCQuestion}
            toNextQuestion={toNextQuestion} submitAnswer={submitAnswer}
            languageService={t} />
    }
    else {
        options = <McTestRadioOptions
            currentTest={currentTest}
            currentQuestion={currentMCQuestion}
            toNextQuestion={toNextQuestion} submitAnswer={submitAnswer}
            languageService={t} />
    }


    return (

        <div className="max-col-width position-relative">

            <div className={textBgClass + " d-flex justify-content-start"}>

                {currentMCQuestion.pending && (currentMCQuestion.WasCorrect ?
                    <LnIcon className="done-icon-right-top natural" name="checked" />
                    :
                    <LnIcon className="done-icon-right-top natural" name="error" />)
                }
                <div className="d-inline-flex">
                    <strong>
                        {t.getText("question")} {questionIndex}/{numOfQuestions}:
                    </strong>
                </div>
                <div className="ml-2 clearfix" dangerouslySetInnerHTML={{ __html: currentMCQuestion.data.QuestionText }}></div>


            </div>
            <div className="my-2 ml-3">
                {currentMCQuestion.data.CanSelectMultipleAnswers ? t.getText("can.select.multiple.answers") : t.getText("one.option.only")}
            </div>

            <div className="d-flex">
                <div className="p-3">
                    {options}
                </div>

                {currentTest.StudyMode && studyState === "ok" &&
                    <div className="mt-4 ml-4">
                        <div className="alert alert-primary">
                            <LnResultIcon status="approvedwithexcellence" className="mr-3" />
                            <br />
                            {questionIndex < numOfQuestions ? t.getText("fetching.next.question") : t.getText("ready.we.restart.video")}
                        </div>
                    </div>
                }


            </div>


        </div>
    )

}

export default MCTestQuestion