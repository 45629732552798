import React from "react";
import { courseDuck } from "features/course/CourseDuck";
import { useI18n } from "components/lni18n";
import { Progress } from 'reactstrap';
import LnIcon from "components/LnIcon";

const EvalStatistics = (props) => {
    const part = courseDuck.useCurrentPart();
    const { languageService: t } = useI18n();


    if (!part || !part.Eval) {
        return null;
    }

    if (!part.Eval.Submitted) {
        return null;
    }



    return <div className="max-col-width">
        {part.Goals !== null && part.Goals !== "" &&
            <div className="mb-3">
                <h3>
                    {t.getText("coursepart.goals")}
                </h3>
                <div dangerouslySetInnerHTML={{ __html: part.Goals }}></div>
                <hr />
            </div>
        }

        <div className="mb-5">
            <LnIcon name="checked" className="mr-4 icon-medium" />
            {t.getText("evaluation_already_posted")}
            </div>

        {(part.Eval.Stats && part.Eval.Stats.Questions && part.Eval.Stats.Questions.length) ? part.Eval.Stats.Questions.map((q, i) => {

            return (<div key={i} className="mb-4">
                <div className="alert alert-grey d-flex">
                    <div className="mr-3">{(i + 1)}</div>
                    <div className="ml-3" dangerouslySetInnerHTML={{ __html: q.Text }}></div>
                </div>
                {q.AnswerType === "freetext" && q.Answers && q.Answers.length > 0 &&
                    <FreetextAnwsers answers={q.Answers} languageService={t} />}

                {q.AnswerType === "multiplechoice" && q.Numbers != null &&
                    <MCAnwsers question={q} languageService={t} />}
            </div>);

        }) : null}
    </div>
}

const FreetextAnwsers = ({ answers, languageService }) => {

    return answers.map(a => (<div className="ml-3 d-flex">
        <div className="mr-4">
            {languageService.getShortDateString(a.Date)}
        </div>
        <div className="">
            {a.Text}
        </div>
    </div>
    ));
}

const MCAnwsers = ({ question, languageService }) => {

    return <div className="ml-3">
        <div className="mb-3">
            <div>{languageService.getText("evaluation_answers_class")}: {question.ClassAnswerCount}</div>
        </div>

        <div className="mb-3">
            <div>{languageService.getText("evaluation_answers_global")}: {question.TotalAnswerCount}</div>
        </div>

        <div className="mb-3">
            <div >{languageService.getText("evaluation_average_class")}:  {question.Numbers.ClassAverageInPercent}%</div>
            <Progress color="success" value={question.Numbers.ClassAverageInPercent} />
        </div>

        <div className="mb-3">
            <div >{languageService.getText("evaluation_average_global")}:  {question.Numbers.TotalAverageInPercent}%</div>
            <Progress color="success" value={question.Numbers.TotalAverageInPercent} />
        </div>

        <div className="mb-3">
            <div >{languageService.getText("evaluation_median_global")}:  {question.Numbers.TotalMedianInPercent}%</div>
            <Progress color="success" value={question.Numbers.TotalMedianInPercent} />
        </div>
    </div>
}


export default EvalStatistics;