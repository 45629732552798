//@ts-check
import React from "react";
import { LoginDuck } from 'features/login/LoginDuck';
import dispatchService from "./DispatchService";
import instance from "./axios";
import { endPoint, release } from "AppConstants";
import { ModalDialog } from "components/OnlineModal";
import { errorDuck } from "components/ErrorHandler/ErrorHandlerDuck";
import { classesDuck } from "features/classes/ClassesDuck";
import languageService from "features/language/languageService";

/**
 * @typedef {{
    UserId: string,
    IsLoggedIn: boolean,
    SessionTTL: number,
    Release: string,
    SystemNotifications: import('features/classes/Classes').NotificationViewDto[]}
    } LoginPingDto;
*/

const t = languageService;
class PingService {

    timerId = null;
    cdnTimerId = null;
    lastTryCheck = null;
    ttl = null;
    doRun = false;
    cdnUrl = null;



    tryAgain = () => {
        let now = new Date();
        if (this.lastTryCheck === null || (now.getTime() - this.lastTryCheck.getTime() < (1000 * 60 * (this.ttl || 5)))) {
            // try again in 10 sec
            this.timerId = window.setTimeout(this.ping, (1000 * 10));
            this.lastTryCheck = now;
            return true;
        }

        return false;
    }

    startPing() {
        this.stopPing();
        this.doRun = true;
        this.ping(true);
        this.pingCdn();

    }

    stopPing() {

        this.doRun = false;
        if (this.timerId !== null) {
            window.clearTimeout(this.timerId);
            this.timerId = null;
        }

        if (this.cdnTimerId) {
            window.clearTimeout(this.cdnTimerId);
            this.cdnTimerId = null;
        }
    }

    ping = async (delayed) => {
        // checkActivity();

        if (delayed) { // wait 10 sec to make sure all localized text is in place
            this.timerId = window.setTimeout(
                this.ping,
                10000
            );

            return;
        }

        if (this.doRun === false) {
            return;
        }
        let postData = { ReleaseId: release };
        const { currentClass } = dispatchService.store.getState().classData;
        if (currentClass) {
            postData.classId = currentClass.Id;
        }

        /**
         * @type  {{data: LoginPingDto}}
         */
        let result = null;
        try {

            result = await instance.post(endPoint.PING_URL, postData);

        } catch (error) {
            if (this.timerId) {
                window.clearTimeout(this.timerId);
                this.timerId = null;
            }
            if (this.tryAgain()) return;

        }

        const data = result ? result.data : null;

        if (!data) {
            if (this.tryAgain()) return;
        }

        if (!data || data.IsLoggedIn === false) {

            if (this.timerId) {
                window.clearTimeout(this.timerId);
                this.timerId = null;
            }
            dispatchService.dispatch({ type: "LOGIN" });
            return;
        }

        this.lastTryCheck = null;

        if (data.Release
            && (data.Release !== release)
            && (release !== null)) {

            ModalDialog({
                message: <div>
                    <div className="mb-3 preserve-white">
                        {t.getText("web.site.new.release")}
                    </div>
                    <form action={window.location.toString()} method="POST">
                        <button type="submit" className="btn btn-primary" >{t.getText("refresh")}</button>
                    </form>
                </div>,
                languageService: t,
                size: "lg",
                backdrop: 'static'
            });
        }

        if (data.SystemNotifications && data.SystemNotifications.length > 0) {
            data.SystemNotifications.forEach(n => {
                dispatchService.dispatch(errorDuck.setError({ okText: t.getText("ok.understand"), header: n.Header, message: n.Message, when: new Date() }));
            });


            const dtos = data.SystemNotifications.map(n => {
                return { ...n, Html: n.Message, Visible: true, Locked: false, NotificationType: 1, Type: "danger" }
            });

            dispatchService.dispatch(classesDuck.addNotifications(dtos));
        }
        if (this.ttl === null) {
            this.ttl = data.SessionTTL;
        }

        this.timerId = window.setTimeout(
            this.ping,
            (1000 * 60 * data.SessionTTL) / 2
        );
    };

    pingCdn = async () => {

        if (this.doRun === false) {
            return;
        }

        let result = null;
        try {
            result = await instance.get(endPoint.GET_CDN_COOKIE_URL);
        } catch (error) {
            if (this.cdnTimerId) {
                window.clearTimeout(this.cdnTimerId);
                this.cdnTimerId = null;
                return;
            }
        }

        if (result && result.data) {

            const data = result.data;

            // min 1 minute until next call
            const timeOut = Math.max(data.ValidUntil * 1000 - Date.now(), 1000 * 60);
            this.cdnTimerId = window.setTimeout(this.pingCdn, timeOut);

            dispatchService.dispatch(LoginDuck.setCdnUrl(data.Url));
        }


    };



}




const pingService = new PingService();
export default pingService;