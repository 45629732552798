//@ts-check
import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch, useStore } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import { lectureDuck } from "features/course/part/Lectures/LectureDuck";
import classes from "./lecture.module.scss";
import LnIcon from "components/LnIcon";
import { useI18n } from "components/lni18n";
import ReactPlayer from 'react-player/file'
import lecturePlayerService from "./LecturePlayerService";
/**
 * 
 * @param {{ imageUrl: string, html: string, transcript: string, className: string, onReady: Function}} props 
 */
export const ImageSoundPlayer = ({ imageUrl, html, transcript, className, onReady }) => {

    const { showTranscript } = useTypedSelector(state => state.lecture);
    const dispatch = useDispatch();
    const store = useStore();
    const mediaElement = useRef(null);

    /**
     * @type {{current: HTMLDivElement}};
     */
    const imgWrap = useRef(null);

    /**
     * @type {{current: HTMLDivElement}};
     */
    const htmlref = useRef(null);

    /**
     * @type {{current: HTMLImageElement}};
     */
    const imageRef = useRef(null);

    /**
    * @type {{current: HTMLDivElement}};
    */
    const htmlTextWrap = useRef(null);

    const { autoNext, doPlay, volume, playbackRate, currentScreenData } = useTypedSelector(state => state.lecture);

    const { languageService: t } = useI18n();


    const toggleTranscript = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        dispatch(lectureDuck.toggleTranscript());
    }


    const handleProgress = (e) => {

        dispatch(lectureDuck.setScreenTime(e.playedSeconds));
        lectureDuck.handleSegment(dispatch, store.getState, e.playedSeconds);
        //lectureDuck.addToSegments( lastStartTime, e.playedSeconds );
    }

    const handleMediaAtEnd = async () => {
        await handleMediaPause();
        if (autoNext) {
            window.setTimeout(() => lectureDuck.tryShiftScreen(dispatch, store.getState, lectureDuck.screenJumps.next, null), 200);
        }

    }

    const handleMediaPause = async () => {
        await dispatch(lectureDuck.setPlayerRunningState(false));
        await dispatch(lectureDuck.setWantedPlayingState(false));
        await lectureDuck.onStop(dispatch, store.getState);

        const time = getPlayerTime();
        if (time) {
            await lectureDuck.handleSegment(dispatch, store.getState, time);
        }
        await lectureDuck.SaveSegments(dispatch, store.getState, currentScreenData.Id);
        
    }



    const getPlayerTime = () => {
        if (mediaElement && mediaElement.current) {
            const time = mediaElement.current.getCurrentTime();
            if (time != null) {
                return time;
            }
        }

        return 0;
    }

    const handlePlay = () => {
        dispatch(lectureDuck.setPlayerRunningState(true));
    }

    const onSeek = (time) => {
        dispatch(lectureDuck.setRunningSegment({ start: time, end: time }));
    }

    const seekTo = async (fraction, screenType) => {
        if (screenType !== "ImageSound") {
            return;
        }
        const time = getPlayerTime();

        if (time) {
            dispatch(lectureDuck.setScreenTime(time));
            await lectureDuck.handleSegment(dispatch, store.getState, time);
            await lectureDuck.onStop(dispatch, store.getState);
        }

        if (mediaElement && mediaElement.current) {
            mediaElement.current.seekTo(fraction);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }; // , [currentScreen, dispatch, store]);


    const handleresize = useCallback((e) => {
        let h = imgWrap.current.offsetHeight;
        let w = imgWrap.current.offsetWidth;
        if (htmlTextWrap.current) {
            h -= htmlTextWrap.current.offsetHeight;
        }
        imageRef.current.style.maxHeight = h + "px";
        imageRef.current.style.maxWidth = w + "px";

    }, [imgWrap, htmlTextWrap, imageRef]);


    useEffect(() => {
    }, [currentScreenData.audioUrl])


    useEffect(() => {
        if (currentScreenData.screenType !== "Video") {
            handleresize();
        }
    }, [currentScreenData, handleresize])

    useEffect(() => {
        lecturePlayerService.RegisterScreen({ seekTo: seekTo });

        window.addEventListener("resize", handleresize);
        return () => window.removeEventListener("resize", handleresize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div className={[className, classes.imgSoundPlayer, "w-100 h-100", (transcript && showTranscript && classes.withTranscript)].join(" ")}>
        {transcript && !showTranscript &&
            <button title={t.getText("text")} onClick={toggleTranscript} className={["close", classes.icon_with_margin, classes.transcript_Icon].join(" ")}>
                <div className="d-flex align-items-center justify-content-center">
                    <LnIcon name="book-white" />
                </div>
            </button>
        }
        <div className="d-flex flex-column w-100 h-100 align-items-center overflow-hidden" ref={imgWrap}>
            <div className="flex-fill"></div>
            <div className="d-flex flex-column align-items-center">
                <img ref={imageRef} alt="" onLoad={() => onReady()} draggable="false" src={imageUrl} className={(html ? classes.screenImgWithHtml : classes.screenImg)} />
                {html && <div ref={htmlTextWrap} className={classes.htmlForScreen} >
                    <div ref={htmlref} dangerouslySetInnerHTML={{ __html: html }} className="max-col-width" ></div>
                </div>}
            </div>

            <div className="flex-fill"></div>
        </div>


        {transcript && showTranscript && <div className={classes.dTranscript + " card mt-2 ml-3 mr-2"}>
            <div className="card-header d-flex justify-content-end screenTranscript">

                <button onClick={toggleTranscript} type="button" className="close round" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="card-body screenTranscript" dangerouslySetInnerHTML={{ __html: transcript }}></div>

        </div>}
        <div className={"w-100 h-100 d-flex align-items-center off-screen"}>
            <ReactPlayer onEnded={handleMediaAtEnd}
                width="10"
                height="10"
                onSeek={onSeek}
                onPause={handleMediaPause}
                onPlay={handlePlay}
                onProgress={handleProgress} progressInterval={100}
                playing={currentScreenData.audioUrl && currentScreenData.screenType !== "Video" && doPlay}
                controls={false}
                volume={volume / 100}
                url={currentScreenData.audioUrl}
                ref={mediaElement}
                pip={false}
                playbackRate={playbackRate}
                config={
                    {
                        forceAudio: true,
                        attributes: {
                            controlsList: "nodownload",
                            playsInline: true,
                            autoPlay: true
                        },
                        tracks: [],
                        file: {
                            tracks: [],
                        },

                    }} />
        </div>
    </div>
}
export default ImageSoundPlayer;