//@ts-check
import React from "react";
import { useI18n } from "components/lni18n";
import useTypedSelector from "utils/useTypedSelector";
import Draggable from "react-draggable";
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const NetworkReconnect = () => {

  const { languageService: t } = useI18n();
  const { connected } = useTypedSelector(state => state.networkReconnect);

  // const onDismiss = () => {

  // }

  if (connected) {
    return null;
  }

  return <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>
    <Modal size="md" role="alertdialog" isOpen={true}  backdrop={'static'}>
      <ModalHeader className="bg-light" >{t.getText("network.error")}</ModalHeader>
      <ModalBody>
        <div className="preserve-white mb-4"  >
          {t.getText("network.error.message")}
        </div>
        <div className="preserve-white">
        {t.getText("we.are.trying.to.connect")}
        
        
        </div>
      </ModalBody>
      <ModalFooter>
            
        
      </ModalFooter>
    </Modal>
  </Draggable >
}


export default NetworkReconnect;
