//@ts-check
import React from "react";
import { useI18n } from "components/lni18n";
import ScrollContent from "components/ScrollContent";
import LnIcon from "components/LnIcon";
import PersonalDataPolicyContent from "./PersonalDataPolicyContent";

const
    PersonalDataPolicy = () => {

        const { languageService: t } = useI18n();

        const goBack = () => {
            window.history.back();
        }


        return (
            <ScrollContent scrollname="personal-data-policy">
                <div className="card-box big-card-box">
                    <div className="card-header ln-nostyle d-flex justify-content-between">
                        <h3>
                            <LnIcon className="header-icon" name="policy" />
                            {t.getText('personal.data.policy')}
                        </h3>
                        <div>
                            <button id="backfromDataPolicy" type="button" className="btn btn-inverse btn-small mr-4" onClick={() => goBack()}>{t.getText('back')}</button>
                        </div>

                    </div>
                    <div className="card-body pt-5">
                       <PersonalDataPolicyContent />
                    </div>
                </div>
            </ScrollContent>)

    }


export default PersonalDataPolicy;
