//@ts-check
import React, { useCallback, useState } from "react";
import useTypedSelector from "utils/useTypedSelector";
import { classesDuck } from "./ClassesDuck";
import { useI18n } from "components/lni18n";
import ScrollContent from "components/ScrollContent";
import WaitRipple from "components/WaitRipple";


const VerificationForm = (props) => {

    const { languageService: t } = useI18n();
    const [code, setCode] = useState("");

    const [blockResend, setBlockresend] = useState(false);

    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const [token, setToken] = useState(currentClass.VerificationToken);

    const resendVerificationCode = useCallback( async () => {
        setBlockresend(true);
        try {
            const token = await classesDuck.resendVerificationCode();
            if (token) setToken(token);
        }
        finally {
            setBlockresend(false);
        }
    }, [] );

    const verify = async () => {
        const ok = await classesDuck.verifyCode(code, token);
        if (ok) {
            window.location.reload();
        }
    }

    return (

        <ScrollContent scrollname="course-part">
            <div className="card-box big-card-box pb-5">
                <div className="card-box-title card-title">
                    <h2>{t.getText("dashboard.email.verification.header")}</h2>
                </div>
                <div className="card-box-content card-body max-col-width ">


                    <div className="preserve-white">{currentClass.VerificationMessage}</div>
                    <input className="form-control w-50" type="text" onChange={(e) => setCode(e.currentTarget.value)} value={code} name="code" />

                    <div>
                        <button className="my-3 btn btn-primary btn-small" onClick={verify}>
                            {t.getText("verify")} </button>
                    </div>
                    
                    {blockResend && <WaitRipple />}

                    <div className="mt-5">
                        <button disabled={blockResend} className="btn btn-inverse btn-small" onClick={resendVerificationCode}>
                            {t.getText("resend.code")} </button>
                    </div>
                </div>
            </div>
        </ScrollContent>
    )


}


export default VerificationForm;