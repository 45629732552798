//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import useTypedSelector from "utils/useTypedSelector";
import { docBoxDuck } from 'features/General/DocBox/DocBoxDuck';

/**
 * @type {import('./ClassFiles').ClassFilesState}
 */
const initialState = {
    rootFolder: null,
    currentFolderId: null,
    folderPath: [],
    canEdit: false
};

const classFilesSlice = createSlice({
    name: 'classFiles',
    initialState: initialState,
    reducers: {

        /**
         * @param  {  {payload: { canEdit: boolean, folder: import('features/General/DocBox/DocBox').DocBoxFolderDto}}} action
         */
        setStartData(state, action) {
            state.canEdit = action.payload.canEdit;

            state.rootFolder = action.payload.folder;
            state.currentFolderId = action.payload.folder.Id;
            state.folderPath = [{ Id: action.payload.folder.Id, Name: action.payload.folder.Name }]
        },

        /**
        * @param  {  {payload: {id:string}}} action
        */
        setCurrentFolder(state, action) {
            state.currentFolderId = action.payload.id;
            // is this folder in path already (navigating upwards)
            const element = state.folderPath.find(f => f.Id === action.payload.id);
            if (element != null) {
                const index = state.folderPath.indexOf(element);
                if (index === -1) {
                    throw new Error("folder not found");
                }
                state.folderPath = state.folderPath.slice(0, index + 1);
            }
            else {  // adding to the path
                const folder = findFolder(state.rootFolder, action.payload.id);
                if (folder === null) {
                    throw new Error("folder not found with findFolder");
                }

                state.folderPath.push({ Id: folder.Id, Name: folder.Name });
            }
        },


    }
});

/**
 *
 * @param { string } classId
 */
const fetchRootFolder = (classId, isTeacher) => async (dispatch) => {


    if (isTeacher) {
        const url = endPoint.GET_DOCBOX_FOR_CLASSTEACHER_URL(classId);
    /**
         * @type {{data: import('features/General/DocBox/DocBox').FileListForTeacherDto}} reponse
         */
     const response = await instance.get(url);
     if (!response) {
         return;
     }
     dispatch(classFilesDuck.setStartData({ canEdit: true, folder: response.data.RootFolder}));

     // for edit
     dispatch(docBoxDuck.setStartData({ setGuiState: true, data: {...response.data.RootFolder, CurrentFolder: null} }));
    }
    else {

        const url = endPoint.GET_DOCBOX_FOR_CLASS_URL(classId);
        /**
         * @type {{data: import('features/General/DocBox/DocBox').DocBoxFolderDto}} reponse
         */
        const response = await instance.get(url);
        if (!response) {
            return;
        }
        dispatch(classFilesDuck.setStartData({ canEdit: false, folder: response.data}));
    }
}

/**
 * @return {import('features/General/DocBox/DocBox').DocBoxFolderDto}
 */
const useCurrentFolder = () => {

    const state = useTypedSelector(state => state.classFiles);
    if (state.rootFolder === null || state.currentFolderId === null) {
        return null;
    }

    return findFolder(state.rootFolder, state.currentFolderId)

}

/**
 *
 * @param {import('features/General/DocBox/DocBox').DocBoxFolderDto} folder
 * @param {string} folderId
 */
const findFolder = (folder, folderId) => {
    if (folder.Id === folderId) {
        return folder;
    }

    if (folder.Children && folder.Children.length > 0) {

        for (let i = 0; i < folder.Children.length; i++) {
            if (folder.Children[i].Id === folderId) {
                return folder.Children[i];
            }
        }

        for (let i = 0; i < folder.Children.length; i++) {
            const f = findFolder(folder.Children[i], folderId);
            if (f !== null) {
                return f;
            }
        }
    }
    return null;
}


export const classFilesDuck = { ...classFilesSlice.actions, fetchRootFolder, useCurrentFolder };
export default classFilesSlice.reducer;