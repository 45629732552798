//@ts-check
import React from "react";
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import { QATestDuck } from "../QATest/QATestDuck"
import { MQATestDuck } from "../MQATest/MQATestDuck"
import { mcTestDuck } from "../McTest/McTestDuck"
import { fileTestDuck } from "../FileTest/FileTestDuck"
import { confirmTestDuck } from "../ConfirmTest/ConfirmTestDuck"
import { drawingTestDuck } from "../DrawingTest/DrawingTestDuck"
import { courseDuck } from "features/course/CourseDuck"
import { classesDuck } from "features/classes/ClassesDuck";
import dispatchService from "utils/DispatchService";
import { lectureDuck } from "../Lectures/LectureDuck";
import { TestNotReadyStatuses } from "./TestEnums";
import { Confirm, ModalConstants } from "components/OnlineModal";
import languageService from "features/language/languageService";


class TestService {

    /**
     * 
     * @param {{dispatch: any, classid: string, testid: string, studyMode?: boolean  }} payload 
     */
    getTestWithoutTypename = async (payload) => {

        const { dispatch, classid, testid, studyMode } = payload;
        /**
         * @type {import("axios").AxiosResponse<string>}
         */
        const response = await instance.get(endPoint.GET_TEST_TYPENAME_URL(classid, testid));
        if (response && response.data) {
            this.getTest(dispatch, { testtype: response.data, classid, testid, studyMode });

            return response.data;
        }
        return null;
    }

    /**
     * 
     * @param {any} dispatch 
     * @param {{testtype: string, classid: string, testid: string, studyMode?: boolean }} payload 
     */
    getTest = async (dispatch, payload) => {

        const url = endPoint.GET_START_TEST_URL(payload.classid, payload.testtype, payload.testid);

        try {
            /**
             * @type {{data: import('./Test').StartTestDto<any>}}
             */
            const response = await instance.get(url);
            if (!response) {
                return;
            }

            await dispatch(courseDuck.setCurrentTest({ testType: payload.testtype, startdata: response.data }));


            switch (payload.testtype) {
                case "multiplechoice":
                case "multiplechoice2":
                    response.data.Data.StudyMode = payload.studyMode;
                    response.data.Data.ClassId = payload.classid;
                    response.data.Data.TestId = payload.testid;
                    dispatch(mcTestDuck.setCurrentMcTest(response.data.Data));
                    break;
                case "selfassessement":
                case "simpleselfassessement":
                case "singlequestion":
                    dispatch(QATestDuck.setCurrentQATest(response.data));
                    break;

                case "multiplequestion":
                    dispatch(MQATestDuck.setCurrentMQATest(response.data.Data));
                    break;
                case "fileupload":
                    dispatch(fileTestDuck.setCurrentFileTest(response.data.Data));
                    break;
                case "simplyreply":
                    dispatch(confirmTestDuck.setCurrentConfirmTest(response.data.Data));
                    break;
                case "drawingtest":
                    dispatch(drawingTestDuck.setCurrentTest(response.data.Data));
                    break;

                default:
                    break;
            }

            dispatch(courseDuck.setTestReadyToView({ state: true }));


        } catch (error) {
            this.handleErrors(error);
        }
    }
    /**
     * 
     * @param {{classId: string,  testId: string}} payload 
     */
    updateTestStatus = async (payload, dispatch) => {

        const url = endPoint.GET_TEST_STATUS_URL(payload.classId, payload.testId);

        try {

            /**
             * @type {{data: {TestStatus:string, PartStatus: string, PartId: string}}}
             */
            const response = await instance.get(url);
            if (!response) {
                return;
            }

            dispatch(courseDuck.setTestStatus({ testId: payload.testId, status: response.data.TestStatus }));
            dispatch(courseDuck.setStatusOnPart({ partId: response.data.PartId, status: response.data.PartStatus }));
            dispatch(classesDuck.setStatusInCurrentClass({ partId: response.data.PartId, status: response.data.PartStatus }));
            if (dispatchService.store.getState().course.testInDialog) {


                if (!TestNotReadyStatuses.find(ts => ts === response.data.TestStatus)) {
                    dispatch(lectureDuck.setCurrentCuePointAsPassed());
                }
            }


        } catch (error) {
            this.handleErrors(error);
        }
    }

    /**
     * close the current test view
     */
    closeTest = (classId, themeId, partId) => {

        /**
         * @type {import("../McTest/McTest").McTestState}
         */
        const mcState = dispatchService.store.getState().mctest;
        
        
        if (mcState.currentTest && mcState.dirty) {
            
            const result = Confirm({
                buttons: [],
                backdrop: 'static',
                class: null,
                title: '',
                message: <div>{languageService.getText("test.warning.close_without_submit")}</div>,

                languageService: languageService,
                type: "yes/no",
                okOnEnter: true
            });

            result.then(res => {
                if (res === ModalConstants.Yes) {
                    dispatchService.dispatch(mcTestDuck.setDirtyState({state: false}));
                    dispatchService.dispatch(mcTestDuck.userClosedTest({
                        classid: classId,
                        testid: mcState.currentTest.TestId,
                        session: mcState.currentTest.TestSessionID
                    }));

                    this.doCloseTest(classId, themeId, partId);
                }
            });


        }
        else {
            this.doCloseTest(classId, themeId, partId);
        }
    }

    doCloseTest = (classId, themeId, partId) => {

        dispatchService.dispatch(mcTestDuck.setCurrentMcTest(null));
        dispatchService.dispatch(courseDuck.setCurrentTest(null));
        dispatchService.dispatch(courseDuck.setTestReadyToView({ state: false }));

        if (dispatchService.store.getState().course.testInDialog) {
            dispatchService.store.dispatch(lectureDuck.setShowTestDialog(false));
        }
        else {
            dispatchService.dispatch({ type: "CLASS_THEME_PART", payload: { classid: classId, themeid: themeId, partid: partId, type: "test" } });
        }
    }

    handleErrors = (error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error);
        }
        console.log(error.config);

    }
}
const testService = new TestService();
export default testService;

