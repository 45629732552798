//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import UserImg from "components/UserImg";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import { endPoint } from "AppConstants";
import { feedDuck } from "./FeedDuck";
import { Confirm, ModalConstants } from "components/OnlineModal";
import classes from './feed.module.scss';
import useTypedSelector from "utils/useTypedSelector";
import FeedForm from "./FeedForm";
import LnDropDownMenu from "components/LnDropDownMenu";
import { DropdownItem, DropdownMenu } from "reactstrap";

/**
 * FeedCommentCard
 * @module features/classes/ClassItems/Feed/FeedCommentCard
 * @return an Card with feed comment detail.
 * @param {{
    Comment: import("./Feed").FeedCommentDto,
    canDelete: boolean,
    eventsSinceDate: Date,
    currentUser: {Id: string},
    feedId: string,

}} props
 */

const FeedCommentCard = ({ Comment, canDelete, eventsSinceDate, currentUser, feedId }) => {
    const { languageService: t } = useI18n();
    const dispatch = useDispatch();
    const { userId } = useTypedSelector(state => state.feed);

    const handleDelete = async () => {
        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("comment.delete"),
            message: (<div>{t.getText("delete_general_confirm")}</div>),
            languageService: t,
            type: "yes/no"
        });

        if (result === ModalConstants.Yes) {
            dispatch(feedDuck.deleteComment(Comment.FeedPostId, Comment.Id));
        };
    };

    /**
     * 
     * @param {import("./Feed").FeedCommentDto} com 
     */
    const toggleEditComment = (com) => {
        dispatch(feedDuck.toggleCommentInEdit({ commentId: com.Id, postId: com.FeedPostId }))
    }

    const newpost = currentUser.Id !== Comment.User.Id && Comment.PostDate.getTime() > eventsSinceDate.getTime();
    return (
        <div className={['d-flex', classes['feed-comment']].join(' ')}>
            <div className="d-none d-md-block">
                {Comment.User.ImageUrl ? <UserImg src={endPoint.API_HOST + Comment.User.ImageUrl} className={['rounded-circle', 'avatar-img', classes['comment-avatar']].join(' ')} alt={Comment.User.FullName} /> : <LnIcon className={['rounded-circle', 'avatar-img', classes['comment-avatar']].join(' ')} name="user-icon" />}
            </div>
            <div className={["card  mb-5", classes['comment-card'], Comment.User.Id === userId && "bg-primary-lightest p-3"].join(' ')}>
                <div className="d-flex">
                    <div className="d-flex flex-wrap flex-fill">
                        <div className="flex-fill">
                            <h5 className={"d-flex " + classes['post-title']}>
                                <div className="d-md-none">
                                    {Comment.User.ImageUrl ? <UserImg src={endPoint.API_HOST + Comment.User.ImageUrl} className={['rounded-circle', 'avatar-img', classes['comment-avatar']].join(' ')} alt={Comment.User.FullName} /> : <LnIcon className={['rounded-circle', 'avatar-img', classes['comment-avatar']].join(' ')} name="user-icon" />}
                                </div>
                                {Comment.User.FullName}
                            </h5>
                        </div>
                        <div className="d-flex align-items-start">

                            <small className={"d-flex flex-row flex-md-column align-items-end " + (newpost && " font-weight-bold")}>
                                <div>
                                    {t.getShortDateTimeString(Comment.PostDate)}
                                </div>
                                {Comment.Edited && <>
                                    <div>&nbsp;{t.getText("edited")}&nbsp;</div>
                                    <div>{t.getShortDateTimeString(Comment.Edited)}</div></>}
                            </small>

                            {(Comment.Editpermission || canDelete) &&
                                <LnDropDownMenu
                                    direction="down"
                                    caret={false}
                                    toggleComponent={
                                        <button className="edit round" >
                                            <LnIcon className="icon-small" name="edit-icon" alt="Edit" />
                                        </button>
                                    }
                                    toogleClassName=""
                                    className="ml-3"
                                    toogleTagName="div"
                                >
                                    <DropdownMenu right className="" container="body">
                                        <div className="d-flex flex-column">
                                            {Comment.Editpermission &&
                                                <DropdownItem onClick={() => toggleEditComment(Comment)} tag="span" >{t.getText("edit")}</DropdownItem>
                                            }
                                            <DropdownItem onClick={handleDelete} tag="span" >{t.getText("delete")}</DropdownItem>
                                        </div>
                                    </DropdownMenu>
                                </LnDropDownMenu>
                            }
                        </div>
                    </div>
                </div>
                {!Comment.InEdit && <div className={'card-text max-col-width ' + classes['post-text'] + (newpost && " first-line-bold")} dangerouslySetInnerHTML={{ __html: Comment.Text }}></div>}
                {Comment.InEdit && <div className="max-col-width mt-3">
                    <FeedForm simpleUI={true} initialValue={Comment.Text} postId={Comment.FeedPostId}
                        commentId={Comment.Id} isComment={true} feedId={feedId} toggleForm={() => toggleEditComment(Comment)} />
                </div>}
            </div>
        </div>
    );
};

export default FeedCommentCard;