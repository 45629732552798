import React from "react";

const LnResultIcon = (props) => {

    if (!props.status)
        return <span></span>

    return <img className={'icon ' + props.className } src={ "/imgs/status/" + props.status + ".svg"} alt={props.status}/>

}


export default LnResultIcon;