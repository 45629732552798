//@ts-check
import React,{useState} from "react";
import WaitRipple from "components/WaitRipple"
let StudentsActivity = null;

const ActivityStub = (props) => {

    const [ready, setReady ] = useState(false);

    import("teacherFeatures").then(features => {
        StudentsActivity = features.StudentsActivity
        setReady(true);
    });

    if (!ready) {
        return <WaitRipple />;
    }
    else {
        return <StudentsActivity />
    }
}

export default ActivityStub;
