//@ts-check
import React, { useState, useCallback } from "react";
import { useI18n } from "components/lni18n";
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { endPoint } from "AppConstants";
import UserImg from "components/UserImg";

/**
 * 
 * @param {{ className?: string, userSearch: (query)=> Promise<{Mail: string, Initials: string, Id: string, FullName: string}[]>, onUserSelected: (user: {Mail: string, Initials: string, Id: string, FullName: string})=>void  }} props
 * @returns 
 */
const UserAsyncFind = ({ userSearch, onUserSelected, className }) => {

    const [userSearchIsLoading, setUserSearchIsLoading] = useState(false);
    const [userSelected, setUserSelected] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const { languageService: t } = useI18n();

    const handleUserFilterBy = () => true;

    const handleUserChange = (e) => {
        setUserSelected(e);
        onUserSelected(e[0]);
    }

    const handleUserSearch = useCallback(async (query) => {

        setUserSearchIsLoading(true);

        const options = await userSearch(query);

        setUserOptions(options);
        setUserSearchIsLoading(false);

    }, [userSearch]);

    const renderUserMenuItemChildren = (option, props, index) => {
        const userImage = endPoint.GET_USER_IMAGE_URL(option.Id);
        return [
            <div key="user" className="d-flex">
                <UserImg src={userImage} className={['rounded-circle', 'avatar-img', 'mr-4'].join(' ')} alt={t.getText('user')} />
                <div className="d-flex flex-column">
                    <span>
                        <Highlighter search={props.text}>
                            {option.FullName}
                        </Highlighter>
                    </span>
                    <small>
                        <Highlighter search={props.text}>
                            {option.Mail}
                        </Highlighter>
                    </small>
                </div>
            </div>
        ];
    }

    return <AsyncTypeahead
        className={className}
        highlightOnlyResult={true}
        filterBy={handleUserFilterBy}
        id="user-select"
        isLoading={userSearchIsLoading}
        labelKey={(option) => `${option.FullName} - ${option.Mail}`}
        minLength={3}
        multiple={false}
        onChange={handleUserChange}
        onSearch={handleUserSearch}
        options={userOptions}
        placeholder={t.getText('user')}
        renderMenuItemChildren={renderUserMenuItemChildren}
        selected={userSelected}

    />
}

export default UserAsyncFind;
