//@ts-check
import axios from 'axios';
import { endPoint } from "AppConstants";
import { errorDuck } from "components/ErrorHandler/ErrorHandlerDuck";
import LanguageService from "features/language/languageService";
import dispatchService from 'utils/DispatchService';
import { networkReconnectDuck } from "../features/network/NetworkReconnectDuck"
import pingService from './PingService';
import { LoginDuck } from 'features/login/LoginDuck';

const instance = axios.create({
    baseURL: endPoint.API_URL,
    withCredentials: true
});



// eslint-disable-next-line
const isDateRegEx = /^\d{4}-(0[1-9]|1[0-2])-([12]\d|0[1-9]|3[01])([T\s](([01]\d|2[0-3])\:[0-5]\d|24\:00)(\:[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3])\:?([0-5]\d)?)?)$/;

const convertDates = (obj) => {
    const type = typeof obj;
    switch (type) {
        case 'string':
            return convertOneDate(obj);

        case 'object':
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    obj[key] = convertDates(obj[key]);
                }
            }

            return obj;

        default:
            return obj;
    }

}

const convertOneDate = (s) => {

    if (isDateRegEx.test(s)) {
        return new Date(s);
    }
    return s;
}

export function setAxiosDispatcher(dispatch) {

    // instance.interceptors.request.use(function (request) {
    //     console.log(request);
    //     return request;
    // });

    instance.interceptors.response.use(function (response) {

        response.data = convertDates(response.data);
        return response;
    }, function (error) {

        let processedError = error;

        if (!error.response) {

            dispatch(networkReconnectDuck.setNetworkState(false));
            return Promise.resolve(null);
        }


        if (error.response) {
            if (error.response.status === 401) {
                const { user, loggedIn } = dispatchService.getState().login;
                if (user && loggedIn) {

                 
                    dispatchService.dispatch(LoginDuck.setRelogin(true));
                    pingService.stopPing();

                    return null;
                }
                else {
                    pingService.stopPing();
                    dispatch({ type: "LOGIN" });
                }

                return null;
            }

            if (error.response.status > 401) {

                let html = null;
                let errorText = null;
                let header = LanguageService.getText("error");
                let message = {
                    when: new Date(),
                    header: header,
                    message: LanguageService.getText("servererror", ""),
                    htmlMessage: html
                };


                switch (error.response.status) {
                    case 403:
                        message = {
                            when: new Date(),
                            header: LanguageService.getText("error"),
                            message: LanguageService.getText("no.permission"),
                            htmlMessage: null,
                        };
                        break;
                    case 500:
                        if (error.response.data && error.response.data.Message) {
                            errorText = error.response.data.Message;
                            if (LanguageService.haveKey(errorText)) {
                                errorText = LanguageService.getText(errorText);
                            }
                        }
                        else {
                            errorText = LanguageService.getText("servererror", errorText)
                            header = LanguageService.getText("fatalerror");
                        }
                        message = {
                            when: new Date(),
                            header: header,
                            message: errorText,
                            htmlMessage: null
                        };
                        break;
                    default:
                        break;
                }
                dispatch(errorDuck.setError(message));
                return;
            }
        }
        return Promise.reject(processedError);
    });
}


export default instance;