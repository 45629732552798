//@ts-check
import React from "react";
import { UncontrolledCollapse, CardBody, Card } from 'reactstrap';

import { ModalDialog } from "components/OnlineModal";
import LnIcon from "components/LnIcon";
import classes from './timetable.module.scss';


/**
 * 
 * @param {import('./TimeTable').eventDto} data 
 * @param {Function} goElsewhere 
 * @param {import('features/language/languageService').LanguageService} languageService 
 * @param {boolean} preventEdit
 */
const EventDetail = async (data, goElsewhere, languageService, preventEdit) => {

    const t = languageService;

    let notes = "";
    if (data.Notes && data.Notes !== "") {
        notes = data.Notes;
    }

    try {
        const spanDays = t.getShortDateString(data.StartDate) !== t.getShortDateString(data.EndDate);

        const modal = ModalDialog({
            bodyClass: 'calendar-detail-modal-body',
            buttons: [{
                className: 'btn btn-primary btn-small',
                text: t.getText('close'),
                type: 'dismiss'
            }],
            message: (<>
                <div className="d-flex justify-content-between mb-3">
                    <small>
                        <LnIcon className="mr-4" name="calendar-icon" />
                        <span>
                            {spanDays ? t.getShortDateTimeString(data.StartDate) + ' - ' + t.getShortDateTimeString(data.EndDate) : t.getShortDateTimeString(data.StartDate) + ' - ' + t.getTimeString(data.EndDate)}
                        </span>
                    </small>
                </div>
                <div className="mb-3">
                    <strong>{t.getText('notes')}</strong>
                    <div dangerouslySetInnerHTML={{ __html: notes }} ></div>
                </div>
                <div className="mb-3">
                    <strong>{t.getText('place')}</strong><br />
                    {data.Rooms.map((i) => (<div key={i.Id}>
                        <button className="btn btn-link" id={'room-toggler-' + i.Id}>
                            {i.Name}
                        </button>
                        <UncontrolledCollapse toggler={'#room-toggler-' + i.Id}>
                            <Card>
                                <CardBody>
                                    <dl className={['row', classes['dl-row']].join(' ')}>
                                        <dt className="col-sm-4">{t.getText('address')}</dt>
                                        <dd className="col-sm-8">{i.Address}</dd>

                                        {i.Description ? <><dt className="col-sm-4">{t.getText('description')}</dt>
                                            <dd className="col-sm-8">
                                                <p>{i.Description}</p>
                                            </dd></> : null}

                                        <dt className="col-sm-4">{t.getText('roomtype')}</dt>
                                        <dd className="col-sm-8">{i.Type}</dd>

                                        <dt className="col-sm-4 text-truncate">{t.getText('numberofseats')}</dt>
                                        <dd className="col-sm-8">{i.Seats}</dd>

                                        <dt className="col-sm-4">{t.getText('fordisabled')}</dt>
                                        <dd className="col-sm-8">
                                            {i.ForDisabled ? t.getText('yes') : t.getText('no')}
                                        </dd>
                                    </dl>
                                </CardBody>
                            </Card>
                        </UncontrolledCollapse>
                    </div>))}
                    {!!data.RoomNotes && <div className="preserve-white">{data.RoomNotes}</div>}

                </div>
                <div className="mb-3">
                    <strong>{t.getText('teachers')}</strong><br />
                    {data.Teachers.map((te) => (<div key={te.Id}>
                        <button className="btn btn-link" id={'teacher-toggler-' + te.Id}>
                            {te.FullName}

                        </button>
                        <UncontrolledCollapse toggler={'#teacher-toggler-' + te.Id}>
                            <Card>
                                <CardBody>
                                    <button className={['btn btn-link mr-4', classes['view-link']].join(' ')} onClick={() => onGoToTeacher(te.Id)} title={t.getText('view')}><LnIcon className="btn-icon" name="user-icon" /></button>

                                    <dl className={['row', classes['dl-row']].join(' ')}>
                                        <dt className="col-sm-4">{t.getText('email')}</dt>
                                        <dd className="col-sm-8">{te.Email}</dd>
                                        <dt className="col-sm-4">{t.getText('tele')}</dt>
                                        {te.Tele1 ? <dd className="col-sm-8"><a href={"tel:" + te.Tele1}>{te.Tele1}</a></dd> : null}

                                    </dl>

                                </CardBody>
                            </Card>
                        </UncontrolledCollapse>
                    </div>))}
                    {!!data.TeacherNotes && <div className="preserve-white">{data.TeacherNotes}</div>}
                </div>
                <div className="d-flex flex-column justify-content-between">
                    <div className="mb-3">
                        <strong>{t.getText('class')}</strong><br />
                        <button className="btn btn-link" onClick={() => onGoToClass()}>{data.StudentClass.Name}</button>
                    </div>
                </div>

                {!preventEdit && data.Editable && <>
                <button onClick={() => modal.close("edit")} className="btn btn-primary">{t.getText("edit")}</button>
                <button onClick={() => modal.close("copy")} className="ml-4 btn btn-primary">{t.getText("copy")}</button>
                </>}

            </>),
            title: data.Title
        });

        const onGoToClass = () => {
            goElsewhere({ classid: data.StudentClass.Id }, 'class');
            modal.close(undefined);
        };
        const onGoToTeacher = (id) => {
            goElsewhere({ classid: data.StudentClass.Id, userid: id }, 'teacher');
            modal.close(undefined);
        };

        return modal.promise;


    } catch (error) {
        console.error(error)
    }

}

export default EventDetail;