//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import classes from './layout.module.scss';
import UserMenu from "./UserMenu";
import LanguageMenu from "components/LanguageMenu/LanguageMenu";
import useTypedSelector from "utils/useTypedSelector";
import { layoutDuck } from "features/layout/LayoutDuck";
import SearchMenu from "./SearchMenu"
import LnIcon from "components/LnIcon";
import Link from "redux-first-router-link";
import ClassesMenuStudent from "./ClassesMenuStudent";
import ClassesMenuTeacher from "./ClassesMenuTeacher";


const SiteHeader = (props) => {

  const dispatch = useDispatch();
  const currentClass = useTypedSelector(state => state.classData.currentClass);
  const { classes: userClasses, teacherData } = useTypedSelector(state => state.classData);
  const sideBarForced = useTypedSelector(state => state.layout.sideBarForced);
  const user = useTypedSelector(state => state.login.user);

  const toggleSideBar = () => {
    dispatch(layoutDuck.toggleSideBarState({}));
  }

  let classesMenu;
  if (teacherData) {
    classesMenu = <ClassesMenuTeacher currentClass={currentClass} teacherData={teacherData} />
  }
  else {
    classesMenu = <ClassesMenuStudent currentClass={currentClass} userClasses={userClasses} />;
  }



  const rightMenuItems = () => {

    const items = [];

    if (user.UserState > 1) {
      items.push((<div key="admin" className={classes.admin + " text-white mr-4 pointer"} onClick={() => dispatch({ type: 'ADMIN' })}>
        <LnIcon name="settings" className="filter-white " />
      </div>));
    }

    if ( !user.SessionLocked && currentClass && currentClass.DashBoardData) {

      const goNotifications = () => {
        dispatch({
          type: 'CLASS_NOTIFICATIONS',
          payload: { classid: currentClass.Id }
        });
      }



      items.push((<div key="notif" className={classes["notification"]} onClick={goNotifications}>
        <div className={classes.dropDownItem + " btn btn-minimal pointer"} >
          <LnIcon name="notification-icon" />
          {currentClass.DashBoardData.NumNewNotifications > 0 &&
            <span className={classes["notification-badge"]}>{currentClass.DashBoardData.NumNewNotifications}</span>
          }
        </div>
      </div>));
    }

    items.push((
      <SearchMenu key="search" />
    ));

    items.push((
      <div key="lang" className={classes["country-dropdown-list"]}>
        <LanguageMenu caretClass={"white-caret"} />
      </div>
    ));

    items.push(<UserMenu key="user" />);

    return items;

  };


  return (
    <div className={[classes.siteHeader, 'w-100 d-print-none'].join(' ')}>

      <button className={"btn btn-link " + classes.showSidebar + (sideBarForced ? "" : " d-lg-none")} onClick={() => toggleSideBar()}>
        <i className="white-caret-point-right" />
      </button>



      <div className={"w-100  px-5 pt-4  position-relative d-flex flex-wrap"} >


        <div className="d-flex">
          <Link to={{ type: 'CLASS', payload: { classid: user.CurrentClass } }} className={"ml-5 " + (sideBarForced ? "" : "d-lg-none")} >
            <LnIcon name="home" className="icon-medium" />
          </Link>

          <div className={"d-flex flex-column "}>
            {classesMenu}
          </div>
        </div>

        <div className={classes.headerRight + " ml-auto"}>
          <div className={"d-flex " + classes.rightMenu}>
          {rightMenuItems()}
          </div>
        </div>


      </div>
    </div>
  );
};

export default SiteHeader;