//@ts-check
import React from "react";
import { endPoint } from "AppConstants";
import classes from "./ImagePicker.module.scss";

/**
 * 
 * @param {{images: import("./ImagePicker_types").ImagePickerImageDto[], onClick: Function}} props 
 * @returns 
 */
const ImageList = ({images, onClick}) => {


    return  <div className={ classes.imgList} >
    {images.map(i => {
      return <div key={i.ImageId} onClick={()=>onClick( i.ImageId )} className={"float-left  m-2 pointer " + classes.thumbBox} id={"img" + i.ImageId}>
        <img src={endPoint.GET_IMAGE_THUMB_URL(i.ImageId)} alt="" />

       

      </div>
    })}
  </div>
}

export default ImageList;
