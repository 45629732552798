//@ts-check

import React from "react";
import { redirect } from 'redux-first-router';
import Link from "redux-first-router-link";
import { useDispatch } from "react-redux";
import { timetableDuck } from "../ClassItems/TimeTable/TimeTableDuck";
import dayjs from 'dayjs';
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import EventDetail from "features/classes/ClassItems/TimeTable/EventDetail";

import classes from './dashboard.module.scss';

/**
 * TimeTableWidget
 * @module features/classes/Dashboard/TimeTableWidget
 * @return an LnCard with Class Time Table.
 * @param {{
    DivHeight?: string,
    ComingEvents: any,
    StudentClass: any,
}} props
 */

const TimeTableWidget = (props) => {

    const { languageService: t } = useI18n();


    const dispatch = useDispatch();

    let comingEvents = [...props.ComingEvents];
    const studentClass = props.StudentClass;

    const divCLasses = ['card-box big-card-box card ln-card mr-5 mb-5', classes['widget-card'], classes['time-table-card']];

    if (comingEvents.length > 8) {
        comingEvents = comingEvents.splice(0, 7);
        comingEvents.push({ id: 'more', title: t.getText('more.calendar.events') })
    }

    if (props.DivHeight) {
        divCLasses.push(classes[props.DivHeight]);
    }
    // TODO add language string for Time Table


    const goElsewhere = (params, route) => {
        const routes = {
            timetable: { type: "CLASS_TIMETABLE", payload: { classid: params.classid } },
            class: { type: "CLASS", payload: { classid: params.classid } },
            //teacher: { type: "CLASS_INFO", payload: { classid: params.classid, userid: params.userid } }
            teacher: { type: "GENERAL_PERSONAL_SETTINGS", payload: { userid: params.userid, type: "personal" } }
        };
        dispatch(
            redirect(routes[route])
        );
    }

    const handleEventClicked = async (event) => {
        /**
        * @type {import('../ClassItems/TimeTable/TimeTable').eventDto }
         * 
         */
        var data = await dispatch(timetableDuck.getCurrentEvent(event.id))
        await EventDetail(data, goElsewhere, t, true)

        dispatch(timetableDuck.setCurrentEvent(null));
        dispatch(timetableDuck.setCheckData(null));


    }


    return (
        <>
            <div className={divCLasses.join(' ')}>
                <div className={["card-header d-flex justify-content-between", classes['card-header']].join(' ')}>
                    <span>
                        <LnIcon className="header-icon" name="time-table-icon" />
                        {t.getText('timetable')}
                    </span>
                    <span>
                        <Link to={{
                            type: 'CLASS_TIMETABLE',
                            payload: { classid: studentClass.Id }
                        }} className="card-header-link">
                            {t.getText('view')}
                        </Link>
                    </span>
                </div>
                <div className="card-body pt-5">
                    <div className="row pl-3">
                        {comingEvents.map(event => {

                            const mStart = dayjs(event.start);

                            return (
                                <div key={event.id} className={["col-sm-6 col-12 d-flex flex-row align-items-center pointer", classes['event-item']].join(' ')} title={event.description}
                                    onClick={() => { handleEventClicked(event) }}>
                                    {event.id !== 'more' ? <div className={["badge badge-success mr-2", classes['event-badge']].join(' ')}>

                                        <strong>{mStart.format("D")}</strong>
                                        <br />{mStart.format("MMM")}</div> : null}
                                    <div className="text-text-truncate">{event.title}</div>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>

        </>
    )
}

export default TimeTableWidget;