//@ts-check
import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";

import classes from "./user.module.scss";
import { Progress } from "reactstrap";
import { TestResultStatus } from "features/course/part/test/TestEnums";


const AllResultsTable = ({ t, hideTests, hideLectures, hideFiles, hideLinks }) => {

    const { userProgress } = useTypedSelector(state => state.user);

    if (userProgress == null) {
        return <WaitRipple />
    }

    const approvedValues = ['approvedbyteacherbutcanbebetter', 'approvedbyteacher', 'approvedwithexcellence'];
    const failedValues = 'failed';
    const followupneeded = 'followupneeded';
    const incompleteValues = ['incomplete'];
    const passedValues = ['confirmed', 'passed', 'recievedbyteacher', 'underreview'];

    const lecturesDisplay = (lectures) => {
        if (!lectures || lectures.length === 0) {
            return null;
        }
        return (
            <>
                {lectures.map((l) => {
                    return (
                        <Progress color={l.Watched ? "" : "warning"} key={l.LectureId} className={[classes.progress].join(' ')} value={l.Percent} />
                    )
                })}
            </>
        )
    }

    const makeProgress = (items) => {

        if (!items || items.length === 0) return null;
        let perc = Math.round((items.filter(i => i.IsTouched).length / items.length) * 100);
        return <Progress color={perc === 100 ? "" : "warning"} key="items" className={[classes.progress].join(' ')} value={perc} />
    }



    const testsDisplay = (tests) => {
        const testCount = tests.length;
        if (testCount === 0) return null;

        const approvedTests = tests.filter((test) =>
            approvedValues.indexOf(test.Status) > -1
        ).length / testCount * 100;

        const failedTests = tests.filter((test) =>
            failedValues === test.Status 
        ).length / testCount * 100;

        const incompleteTests = tests.filter((test) =>
            incompleteValues.indexOf(test.Status) > -1 || !test.Status
        ).length / testCount * 100;

        const passedTests = tests.filter((test) =>
            passedValues.indexOf(test.Status) > -1
        ).length / testCount * 100;

        const followupTests = tests.filter((test) =>
            followupneeded === test.Status
        ).length / testCount * 100;


        return (
            <Progress className={classes.progress} multi>
                <Progress bar className={TestResultStatus.Incomplete} value={incompleteTests} />
                <Progress bar className={TestResultStatus.FollowUpNeeded} value={followupTests} />
                <Progress bar className={TestResultStatus.Passed} value={passedTests} />
                <Progress bar className={TestResultStatus.Failed} value={failedTests} />
                <Progress bar className={TestResultStatus.ApprovedByTeacher} value={approvedTests} />
            </Progress>

        )
    }

    const createHeaders = () => {
        const headers = [];
        if (!hideTests) headers.push(
            <th className="th-clean">
                {t.getText('progress.test')}
            </th>
        );
        if (!hideLectures) headers.push(
            <th className="th-clean">
                {t.getText('total.watched')}
            </th>
        );
        if (!hideFiles) headers.push(
            <th className="th-clean">
                {t.getText('files')}
            </th>
        );
        if (!hideLinks) headers.push(
            <th className="th-clean">
                {t.getText('links')}
            </th>
        )
        return headers;
    }

    /**
     * 
     * @param {ClassProgressCoursePartDto} c 
     * @param {number} i 
     */
    const createRow = (c, i) => {
        const row = [];
        if (!hideTests) row.push(
            <td className={classes.tdContent}>
                <label className="td-label"> {t.getText('progress.test')}</label>
                <div className="w-100">
                    {testsDisplay(c.Tests)}
                </div>
            </td>
        );
        if (!hideLectures) row.push(
            <td className={classes.tdContent}>
                <label className="td-label">{t.getText('total.watched')}</label>
                <div className="w-100">
                    {lecturesDisplay(c.WatchedLectures)}

                </div>
            </td>

        );
        if (!hideFiles) row.push(
            <td className={classes.tdContent}>
                <label className="td-label">{t.getText('files')}</label>
                <div className="w-100">
                    {makeProgress(c.DowloadedFiles)}

                </div>
            </td>
        );
        if (!hideLinks) row.push(
            <td className={classes.tdContent}>
                <label className="td-label">{t.getText('links')}</label>
                <div className="w-100">
                    {makeProgress(c.OpenedLinks)}

                </div>
            </td>
        )
        return row;
    }

    return (

        <table className="table ln-table-responsive table-hover table-sm w-100">
            <thead>
                <tr>
                    <th className="th-clean">
                        {t.getText('theme')}
                    </th>
                    <th className="th-clean">
                        {t.getText('coursepart')}
                    </th>
                    {createHeaders()}
                </tr>
            </thead>
            <tbody>
                {userProgress.Themes.map((te) => {
                    return (
                        te.CourseParts.map((c, i) => {
                            return (
                                <tr key={String(c.Id)} className={i === 0 ? classes.firstRow : classes.subRow}>
                                    <td className={["text-nowrap", classes.tdContent, classes.tdTheme].join(' ')}>
                                        <label className="td-label">{t.getText('theme')}</label>
                                        {i === 0 ? te.Name : ' '}
                                    </td>
                                    <td className={classes.tdContent}>
                                        <label className="td-label">{t.getText('coursepart')}</label>
                                        <div className={classes.coursePartValue}>
                                            {c.Name}
                                        </div>

                                    </td>

                                    {createRow(c, i)}



                                </tr>

                            )
                        })
                    )
                }
                )}
            </tbody>
        </table>
    )
}


export default AllResultsTable;

