//@ts-check
import React, { useEffect } from "react";
import { useDispatch, shallowEqual } from "react-redux";
import classes from "./QATest.module.scss";
import TestTop from "../test/TestTop";
import { QATestDuck } from "./QATestDuck";
import { useI18n } from "components/lni18n";
import QAQuestion from "./QAQuestion";
import TestSubmitted from "../test/TestSubmitted";
import { courseDuck } from "features/course/CourseDuck";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";
import DebouncedButton from "components/DebounceButton";


const QATest = props => {

    const currentTheme = courseDuck.useCurrentTheme();
    const currentPart = courseDuck.useCurrentPart();
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const { currentTest, showSubmit, canSubmit, savingToserver } = useTypedSelector(state => state.qatest, shallowEqual);
    const currentBaseTest = courseDuck.useCurrentTest();
    const dispatch = useDispatch();
    const { languageService: t } = useI18n();

    useEffect(() => {
        const disp = dispatch;
        return () => disp(QATestDuck.clearOnExit())
    }, [dispatch])

    const submitAnswer = (saveonly) => {
        dispatch(QATestDuck.submitQATest({
            classid: currentClass.Id,
            testid: currentBaseTest.TestId,
            saveonly: saveonly
        }));
    }


    const handleInputEvent = data => {
        const payload = {
            ...data,
            classid: currentClass.Id,
            testid: currentTest.TestId
        };

        dispatch(QATestDuck.setQuestionReply(payload));
    }

    let index = 0;

    if (!currentBaseTest) {
        return <WaitRipple />
    }

    return <div className={classes.qatest}>

        <TestTop languageService={t} classId={currentClass.Id} themeId={currentTheme.Id} partId={currentPart.Id} ></TestTop>
        <TestSubmitted status={currentBaseTest.Status} languageService={t} />



        <div className="max-col-width">

            {currentBaseTest.StudentInstructions
                && <div className="alert border-primary clearfix mb-3 mt-3"
                    dangerouslySetInnerHTML={{ __html: currentBaseTest.StudentInstructions }} >
                </div>}
                
            {currentTest &&
                <div className="mb-4" >
                    {currentTest.Data.Questions.map(q => {
                        return <QAQuestion autoFocus={q.Edit} languageService={t} dispatchEvent={handleInputEvent} index={index} key={index++} question={q} />
                    })}
                </div>
            }

            <div className="position-relative w-50">
                {savingToserver && <WaitRipple />}
            </div>

            {showSubmit && <DebouncedButton disabled={!canSubmit || savingToserver} onClick={() => submitAnswer(false)} className="btn btn-primary mr-2" >{t.getText("submit")}</DebouncedButton>}
        </div>

    </div>

}


export default QATest;

