//@ts-check
import React from "react";
import { shallowEqual } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import makeMenu from "./makePartMenu";
import WaitRipple from "components/WaitRipple";
import ScrollContent from 'components/ScrollContent';
import PartInfo from "./PartInfo";
import Lectures from "./Lectures/Lectures";
import Files from "./Files/Files";
import Links from "./Links/Links";
import Tests from "./Tests/Tests";
import Evals from "./Evaluations/Evals";
import RunTest from "./test/RunTest";
import { courseDuck } from "features/course/CourseDuck";
import { useI18n } from "components/lni18n";
import LecturePlayer from "./Lectures/LecturePlayer";
import { API_URL } from "AppConstants";



const Part = (props) => {

    const { partTab, currentPartId } = useTypedSelector(state => state.course, shallowEqual);
    const currentTheme = courseDuck.useCurrentTheme();
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const location = useTypedSelector(state => state.location);
    const currentPart = courseDuck.useCurrentPart();
    const { languageService: t } = useI18n();


    const partTabs = {
        INTRO: <PartInfo />,
        LECTURE: <Lectures />,
        FILE: <Files />,
        LINK: <Links languageService={t} />,
        TEST: <Tests />,
        RUNTEST: <RunTest />,
        EVAL: <Evals />
    }

    const openAuthor = () => {
        const url = `${API_URL}/class/${currentClass.Id}/coursepart/${currentPart.Id}/AuthorEdit`;
        window.open(url, "_blank");
    }

    /**
     * @type {JSX.Element[]}
     */
    let menuReactItems = null;
    if (currentPartId) {
        menuReactItems = makeMenu({ part: currentPart, partTab, currentClass, location, currentTheme, languageService: t });
    }


    if (!currentPartId || !currentPart) {
        return (
            <ScrollContent scrollname="course-part">
                <div className="card-box-title card-title">
                    &nbsp;
                </div>
                <div className="card-box-content card-body">
                    <WaitRipple></WaitRipple>
                </div>
            </ScrollContent>
        );
    }

    return (
        <>
            <ScrollContent scrollname="course-part">
                <div className="card-box big-card-box">
                    <div className="card-box-title card-title">
                        <h3>{currentPart.Name}</h3>
                        {currentPart.UserMayEdit &&
                            <button onClick={() => openAuthor()} className="btn btn-primary" >{t.getText("edit")}</button>
                        }
                    </div>
                    <div className="card-box-content card-body">
                        <div className="mb-5 d-print-none">
                            <div className="custom-tab-heading-list" >
                            {menuReactItems}
                            </div>
                        </div>
                        <main className="">
                            {partTabs[partTab] || null}
                        </main>
                    </div>
                </div>
            </ScrollContent>
            {location.type === 'CLASS_THEME_PART_LECTURE_PLAY' && <LecturePlayer />}
        </>
    );
}


export default Part;