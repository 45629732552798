//@ts-check
import React from "react";
import { shallowEqual } from "react-redux";

import MCTestQuestion from "./MCTestQuestion";
import { courseDuck } from "features/course/CourseDuck";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";
import testService from "../test/TestService";
import { useEffect } from "react";

const McTestStudy = (props) => {

    const { currentMCQuestion, studyState } = useTypedSelector(state => state.mctest, shallowEqual);
    const currentBaseTest = courseDuck.useCurrentTest();

    useEffect(()=>{
        if (studyState === "submitted") {
            window.setTimeout(()=>testService.closeTest( null, null, null ), 10);
        }    
    }, [studyState])

    if (!currentBaseTest || !currentMCQuestion) {
        return <WaitRipple />
    }

    if (studyState === "submitted") {
        return  null; //<div className="alert alert-success" dangerouslySetInnerHTML={{ __html: currentMCQuestion.Result.Message }} ></div>
    }

    return <>

       <h4>{currentBaseTest.Name}</h4> 

        {/* if we have data show the question */}
        {currentMCQuestion.data && <MCTestQuestion />}
    </>

}
export default McTestStudy;
