//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import ScrollContent from 'components/ScrollContent';
import { useI18n } from "components/lni18n";
import WaitRipple from "components/WaitRipple";
import useTypedSelector from "utils/useTypedSelector";
import Link from "redux-first-router-link";
import LnIcon from "components/LnIcon";
import classes from './myprogress.module.scss';
import ThemeGrade from "./ThemeGrade";


const PersonalPlan = (props) => {

    const dispatch = useDispatch();
    const { languageService: t } = useI18n();
    const { userProgress } = useTypedSelector(state => state.user);
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const lastFeature = useTypedSelector(state => state.layout.lastFeature);


    if (userProgress == null) {
        return <ScrollContent scrollname="class-myprogress">
            <div className="card-box big-card-box">
                <WaitRipple />
            </div>
        </ScrollContent>
    }

    const goBack = () => {
        if (lastFeature == null) {
            window.history.back();
        }
        else {
            dispatch({ type: lastFeature.type, payload: lastFeature.payload });
        }
    }

    /**
     * 
     * @param {Date} d 
     */
    const getstartDate = (d) => {
        // if date is DateTime.Max.
        // if so (Year is BIG) show class start date
        if (d.getFullYear() > 3000) {
            return t.getMediumDateString(currentClass.DashBoardData.StudentData.StudentClass.StartDate);
        }
        else {
            return t.getMediumDateString(d);
        }
    }

    return (
        <ScrollContent scrollname="class-myplan">
            <div className="card-box big-card-box">
                <div className="card-header ln-nostyle d-flex justify-content-between">
                    <div className="d-flex">
                        <LnIcon className="header-icon" name="note-icon" />
                        <div>
                            <h3>

                                {t.getText('studentclassplan.label')}
                            </h3>
                            <div className={classes.oneClass}>{currentClass.Name}</div>
                        </div>

                    </div>


                    <div>
                        <button id="backFromPersonalPlan" type="button" className="btn btn-inverse btn-small mr-4" onClick={() => goBack()}>{t.getText('back')}</button>
                    </div>
                </div>

                <div className="card-body pt-4">

                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <table className="table table-responsive-sm table-sm">

                                <tbody>
                                    {userProgress.Themes.map((theme) => {
                                        return (
                                            theme.CourseParts.map((part, i) => {
                                                return (
                                                    <tr key={String(part.Id)} className={i === 0 ? classes.firstRow : classes.subRow}>
                                                        <td className="text-nowrap">
                                                            {i === 0 && (
                                                                <div className="mb-3">
                                                                    <div className="mb-2">
                                                                        <strong>
                                                                            {theme.Running ?
                                                                                <Link className="link-nocolor" to={{ type: "CLASS_THEME", payload: { classid: currentClass.Id, themeid: theme.Id } }}>{theme.Name}</Link>
                                                                                :
                                                                                <span>{theme.Name}</span>
                                                                            }
                                                                        </strong>
                                                                    </div>
                                                                    {theme.ThemeGrade && <ThemeGrade grade={theme.ThemeGrade} languageService={t} />}
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <div className="d-flex flex-column">
                                                                {part.Running ?
                                                                    <Link className="link-nocolor" to={{
                                                                        type: "CLASS_THEME_PART",
                                                                        payload:
                                                                        {
                                                                            classid: currentClass.Id,
                                                                            themeid: theme.Id,
                                                                            partid: part.Id,
                                                                            type: "intro"
                                                                        }
                                                                    }}
                                                                    >{part.Name}</Link>
                                                                    :
                                                                    <span>{part.Name}</span>
                                                                }

                                                                {part.RunningDates &&
                                                                    <div className="small">{t.getText("your.access.to.course.content")}
                                                                        &nbsp;
                                                                        <strong>
                                                                            {getstartDate(part.RunningDates.Start)} &#8594;
                                                                            {part.RunningDates.End && <>{t.getMediumDateString(part.RunningDates.End)}</>}
                                                                        </strong>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>

        </ScrollContent>
    )
}

export default PersonalPlan;