import React from "react";
import { DropdownItem, DropdownMenu } from 'reactstrap';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import classes from './LanguageMenu.module.scss';
import LnDropDownMenu from "components/LnDropDownMenu";
import { changeLang } from "features/language/languageService";

const LanguageMenu = (props) => {

    const { languages, currentLang } = useSelector(state => state.text, shallowEqual);
    const dispatch = useDispatch();
    const caretClass = [classes.caret ,  props.caretClass || "white-caret"].join(" ");
    
    if( !currentLang){
        return null;
    }

    return <LnDropDownMenu
        caret={false}
        toggleComponent={
            <>
                <img
                    src={currentLang.flag}
                    className="mr-2"
                    alt={currentLang.DisplayName}
                />{" "}
                <span className={caretClass}></span>
            </>
        }
        toogleClassName={[classes.flag, classes.toggle, "btn btn-minimal pointer"].join(" ")}
        toogleTagName="div"
    >
        <DropdownMenu className={classes.dropDownMenu} right container="body">
            {languages.map(f => (
                <DropdownItem
                    key={f.Id}
                    onClick={() => dispatch(changeLang(f.Id))}
                    className={classes.flagItem}
                    tag="div"
                >
                    <img src={f.flag} alt={f.DisplayName} />
                    &nbsp;{f.DisplayName}
                </DropdownItem>
            ))}
        </DropdownMenu>
    </LnDropDownMenu>
}

export default LanguageMenu;