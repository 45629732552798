//@ts-check

import React from "react";
import ScrollContent from 'components/ScrollContent';
import useTypedSelector from "utils/useTypedSelector";
import NotificationsWidget from './NotificationsWidget';
import VerifyEmail2Widget from './VerifyEmail2Widget';
import ClassesWidget from "./ClassesWidget";
import CurrentCourseWidget from "./CurrentCourseWidget";

const SchoolDashboard = () => {

    const { currentClass, classes: userClasses } = useTypedSelector(state => state.classData);
    const user = useTypedSelector(state => state.login.user);

    const availableWidgets = {
        MessagesWidget: () => <NotificationsWidget classId={currentClass.Id} notifications={currentClass.notifications} CanEdit={currentClass.CanEditNotifications} />,
        ClassesWidget: () => <ClassesWidget Data={userClasses} unitId={user.UnitId} />
    };

    let shownWidgets = [availableWidgets.ClassesWidget()];

    if (user.Email2 && user.Mail2Verified === false) {
        shownWidgets.push(<VerifyEmail2Widget />);
    }

    return (
        <ScrollContent scrollname="class-dashboard" >
            <CurrentCourseWidget Data={currentClass} />
            <ClassesWidget Data={userClasses} unitId={user.UnitId} />
        </ScrollContent>
    )
}

export default SchoolDashboard;
