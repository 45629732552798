//@ts-check

import React from "react";
import Link from "redux-first-router-link";

import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";

import NotificationItem from './NotificationItem';
import classes from './dashboard.module.scss';

/**
 * MessagesWidget
 * @module features/classes/Dashboard/MessagesWidget
 * @return an LnCard with Class Messages.
 * @param {{
    notifications: any,
    classId: string,
    CanEdit: boolean
}} props
 */
const NotificationsWidget = (props) => {

    const { languageService: t } = useI18n();
    const { notifications, classId, CanEdit } = props;

    const nTClass = (type) => { // notificationTypeClass
        const _type = String(type);
        return {
            '1': 'notification-type-system',
            '2': 'notification-type-school-unit',
            '3': 'notification-type-class-unit'
        }[_type] || 'notification-type';
    }



    return (
        <div className={['card-box big-card-box card ln-card mr-5 mb-5', classes['widget-card']].join(' ')}>
            <div className={["card-header d-flex justify-content-between", classes['card-header']].join(' ')}>
                <span>
                    <LnIcon className="header-icon" name="bell-icon" />
                    {t.getText('messages')}
                </span>
                <span>
                    <Link to={{
                        type: 'CLASS_NOTIFICATIONS',
                        payload: { classid: classId }
                    }} className="card-header-link">
                        {t.getText('view')}
                    </Link>

                </span>
            </div>
            <div className="card-body pt-5">

                {notifications && notifications.length > 0 ? <ul className="list-group list-group-flush">
                    {notifications.filter(n => n.Visible).map((i) => {
                        return (
                            <li key={i.Id} className="list-group-item">
                                <NotificationItem classId={classId} canEdit={CanEdit && i.Type === 'studentclass'} item={i} nTClass={nTClass} />
                            </li>
                        )
                    })}
                </ul>
                    :
                    <div>
                        <p>{t.getText("no.messages")}</p>
                        {CanEdit &&
                            <p>
                                <Link to={{
                                    type: 'CLASS_NOTIFICATIONS_EDIT',
                                    payload: { classid: classId }
                                }} className="card-header-link">
                                    {t.getText('edit')}
                                </Link>
                            </p>
                        }

                    </div>
                }

            </div>

            <div className="card-footer">
                <a href="https://static.laranara.se/video/lndemo.mp4" rel="noopener noreferrer" target="_blank" >{t.getText("demo.video")}</a>
            </div>

        </div>
    )
}

export default NotificationsWidget;