//@ts-check
import React, { useRef, useEffect, useState } from "react";
import { useStore } from "react-redux";

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import screenfull from "screenfull";
import { useDispatch } from "react-redux";
import { useI18n } from "components/lni18n";
import classes from './lecture.module.scss';
import LnIcon from "components/LnIcon";
import { lectureDuck } from "features/course/part/Lectures/LectureDuck";
import useTypedSelector from "utils/useTypedSelector";
import lecturePlayerService from "./LecturePlayerService";
import thumbnailService from "./ThumbnailService";
import ScreensDialog from "./ScreensDialog";
import PlayProgress from "./PlayProgress";
import SimplePlayProgress from "./SimplePlayProgress";

import browserService from "utils/BrowserService"
import { useCallback } from "react";
import { Confirm, ModalConstants } from "components/OnlineModal";

const LectureControls = (props) => {

    const dispatch = useDispatch();
    let hasMedia = false;

    const store = useStore()

    const canFullScreen = browserService.canSetFullScreen();

    const [showBubble, setShowBubble] = useState({ id: null, show: false });


    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const { controlsState: { fullscreen, visible }, currentLecture,
        currentScreen, mediaData, volume, autoNext, autoRepeat, isPlaying, playbackRate,
        stoppedAtCuePoint, showNextPopup } = useTypedSelector(state => state.lecture);

    const { currentThemeId, currentPartId } = useTypedSelector(state => state.course);
    const { languageService: t } = useI18n();

    const showVolumeControl = browserService.canSetAudioVolume();

    const speeds = [0.5, 0.75, 0.8, 1, 1.2, 1.25, 1.3, 1.5, 2];

    /**
     * @type { {current: HTMLDivElement} }
     */
    const thumbnailRef = useRef(null);

    const numOfScreens = currentLecture.Screens.length;

    if (mediaData) {

        hasMedia = currentScreen?.MediaUrls && currentScreen.MediaUrls?.length > 1;
    }

    /**
     * On every screen start reset thumbnails to off-screen
     */
    useEffect(() => {
        if (thumbnailRef.current == null) {
            return;
        }
        thumbnailRef.current.classList.add("off-screen");
    }, [currentScreen]);

    /**
    * on every screen show cue points info 
    */
    useEffect(() => {
        let timerId = undefined;
        if (currentScreen && currentScreen.currentCuePoint && (!showBubble || showBubble.id !== currentScreen.Id)) {
            setShowBubble({ id: currentScreen.Id, show: true });
            timerId = window.setTimeout(() => setShowBubble(s => { return { ...s, show: false } }), 10000);
        }
        else {
            setShowBubble({ id: currentScreen?.Id, show: false });
        }

        return () => { window.clearTimeout(timerId); }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentScreen, setShowBubble]);

    const quitLecture = useCallback(async () => {

        if (mediaData.Duration > 0) {
            await lectureDuck.onStop(dispatch, store.getState);
            lectureDuck.SaveSegments(dispatch, store.getState, currentScreen.Id);
        }

        function doQuit() {


            window.setTimeout(() => dispatch({
                type: 'CLASS_THEME_PART',
                payload: { classid: currentClass.Id, themeid: currentThemeId, partid: currentPartId, type: "lecture" }
            }), 2);
        }

        // @ts-ignore
        if (screenfull.isFullscreen) {
            // @ts-ignore
            screenfull.exit();
        }

        if (currentScreen && currentScreen.CuePoints && currentScreen.CuePoints.find(cp => !cp.Passed)) {
            dispatch(lectureDuck.setWantedPlayingState(false));

            Confirm({
                buttons: [],
                backdrop: true,
                class: null,
                title: "",
                message: (<div className="preserve-white">{t.getText("lecture.cuepoints.left")}</div>),
                languageService: t,
                okOnEnter: true,
                type: "yes/no"
            }).then(res => {
                if (res === ModalConstants.Yes) {
                    doQuit();
                }
            });

        }
        else {
            doQuit();
        }
    }, [t, currentScreen, currentClass.Id, currentPartId, currentThemeId, dispatch, mediaData.Duration, store.getState]);

    lecturePlayerService.RegisterControls({
        Quit: quitLecture
    });



    const shiftScreen = (ev, jumpType) => {
        lectureDuck.tryShiftScreen(dispatch, store.getState, jumpType, null);
        ev.currentTarget.blur();

    }

    const handleProgressClick = (e) => {

        const pos = (e.clientX - e.currentTarget.offsetLeft) / e.currentTarget.offsetWidth;

        lecturePlayerService.SeekTo(pos, currentScreen.ScreenType);

    }

    const handleProgressMouseMove = (e) => {
        if (mediaData && mediaData.Duration && mediaData.Duration > 0) {
            const time = mediaData.Duration * (e.clientX - e.target.offsetLeft) / e.target.offsetWidth;

            /* *
            * @type {import('./lectures').ThumbnailItem ) }
             */
            const thumbItem = thumbnailService.GetItem(time);
            const posLeft = e.clientX - (e.clientX - e.target.offsetLeft) / e.target.offsetWidth * 100
            setThumbNail(thumbItem, posLeft);
        }
    }

    const closeTooltip = () => {
        dispatch(lectureDuck.setShowNextPopup(false));
    }

    const setThumbNail = (thumbItem, imgpos) => {
        if (thumbItem != null) {
            thumbnailRef.current.classList.remove("off-screen");

            /**
             * @type {HTMLImageElement}
             */
            // @ts-ignore
            const image = thumbnailRef.current.firstElementChild;

            image.src = thumbItem.url;
            image.style.left = -thumbItem.x + "px";
            image.style.top = -thumbItem.y + "px";
            thumbnailRef.current.style.width = thumbItem.w + "px";
            thumbnailRef.current.style.height = thumbItem.h + "px";
            thumbnailRef.current.style.left = imgpos + "px";
        }
        else {
            thumbnailRef.current.classList.add("off-screen");
        }
    }


    const handlePlayPauseClick = (e) => {
        dispatch(lectureDuck.togglePlayingState());
        e.currentTarget.blur();

    }


    const handleProgressEnter = () => {
        thumbnailRef.current.classList.remove("off-screen");
    }

    const handleProgressLeave = () => {
        setThumbNail(null);
    }

    const getTouchOffset = (event) => {
        var el = event.target,
            x = 0;

        while (el && !isNaN(el.offsetLeft)) {
            x += el.offsetLeft - el.scrollLeft;
            el = el.offsetParent;
        }

        return event.clientX - x;
    };

    const handleTouchMove = (event) => {
        var touches = event.touches;
        var touchX = getTouchOffset(touches[0]);
        var time = touchX * mediaData.Duration / event.target.offsetWidth;
        const thumbItem = thumbnailService.GetItem(time);
        setThumbNail(thumbItem, touchX);
    }

    const handleTouchEnd = () => {
        setThumbNail(null);
    }

    const thumbsButton = useRef(null);
    const showThumbs = () => {
        lecturePlayerService.HandleControlsMenuShowing(true);
        dispatch(lectureDuck.toggleShowScreensList());

        // no focus to prevent future key events
        thumbsButton.current.blur();
    }

    const handleVolumeChange = (ev) => {
        dispatch(lectureDuck.setVolume(ev.target.value));
    }

    const setAutoNext = (e) => {
        dispatch(lectureDuck.toggleAutoNext());
        e.currentTarget.blur();
    }

    const setAutoRepeat = (e) => {
        dispatch(lectureDuck.toggleAutoRepeat());
        e.currentTarget.blur();
    }

    const setPlaybackRate = (e, num) => {
        dispatch(lectureDuck.setPlaybackRate(num));
    }

    return (
        <  >
            {!visible && hasMedia &&
                <SimplePlayProgress />
            }
            <div id="lnlectcontrols"
                className={(visible ? classes.fadeIn : classes.fadeOut) + " " + classes.controls + " "} color="primary" >

                {showBubble && showBubble.show &&
                    <div className="p-3 lectureBubble bubble dropdown-menu dropdown-menu-right show d-flex" x-placement="top-end">

                        <div className="preserve-white">
                            {t.getText("lecture.cuepoint.warning")}
                        </div>
                        <LnIcon name="position" className="filter-red" />
                    </div>
                }

                {props.children}
                <div className={(hasMedia ? " " : "invisible") + "  d-flex justify-content-between align-items-center"}>
                    <small>{t.gethhmmss(mediaData.Time || 0)}</small>
                    <div className="flex-grow-1 ml-2 mr-2 pt-2 pb-2 pointer"
                        onClick={handleProgressClick}
                        onMouseMove={handleProgressMouseMove}
                        onMouseEnter={handleProgressEnter}
                        onMouseLeave={handleProgressLeave}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                        onTouchEndCapture={handleTouchEnd}
                    >
                        <PlayProgress />

                    </div>
                    <small>{t.gethhmmss(mediaData.Duration || 0)}</small>
                    <div className={classes.progressThumbnail} ref={thumbnailRef}>
                        <img src="" alt="" />
                    </div>
                </div>

                <div className="">

                    <div className={classes.buttonsbar + " mx-auto mt-2 pb-1 pl-3 pr-3 d-flex align-items-center justify-content-between"}>



                        <button title={t.getText("close")} className="btn btn-link " onClick={() => quitLecture()}>
                            <LnIcon name="off" />
                        </button>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className={classes.numbers + " small"}>{(currentScreen?.Order + 1)}/{numOfScreens}</div>
                            <div className={classes.divider + " d-none d-sm-block"}>&nbsp;</div>
                            <button title={t.getText("first")} disabled={currentLecture.AtFirstScreen} className="btn btn-link ml-xs-2" onClick={(e) => shiftScreen(e, lectureDuck.screenJumps.first)}>
                                <LnIcon name="first" />
                            </button>
                            <button title={t.getText("previous")} disabled={currentLecture.AtFirstScreen} className="btn btn-link " onClick={(e) => shiftScreen(e, lectureDuck.screenJumps.prev)}>
                                <LnIcon name="previous-2" />
                            </button>
                            <button title={t.getText("play")} disabled={!hasMedia || stoppedAtCuePoint} className={classes.roundButton + " btn btn-link d-flex align-items-center"} onClick={handlePlayPauseClick}>
                                {isPlaying ? <LnIcon name="pause" /> : <LnIcon name="play" />}
                            </button>
                            <button id="nextscreen" title={t.getText("next")} disabled={currentLecture.AtLastScreen} className="btn btn-link " onClick={(e) => shiftScreen(e, lectureDuck.screenJumps.next)}>
                                <LnIcon name="next-2" />
                            </button>
                            <button title={t.getText("last")} disabled={currentLecture.AtLastScreen} className="btn btn-link " onClick={(e) => shiftScreen(e, lectureDuck.screenJumps.last)}>
                                <LnIcon name="last" />
                            </button>



                            <div className={classes.divider + " d-none d-sm-block"}>&nbsp;</div>
                            {showVolumeControl &&
                                <UncontrolledDropdown onClick={() => lecturePlayerService.HandleControlsMenuShowing(true)}>
                                    <DropdownToggle tag="button" className="btn btn-link mr-xs-2">
                                        <LnIcon name="volume" title={t.getText("volume")} />
                                    </DropdownToggle>
                                    <DropdownMenu className={classes.volume} >
                                        <div className="d-flex">
                                            <LnIcon name="volume-low" />
                                            <input type="range" min={0} max={100} value={volume} onChange={handleVolumeChange} />
                                            <LnIcon name="volume" />
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            }
                            {canFullScreen &&
                                <button title={t.getText("full.screen")} className="btn btn-link " onClick={props.toggleFullScreen}>
                                    <LnIcon name={fullscreen ? "fullscreen-exit" : "fullscreen"} className="" />
                                </button>
                            }

                            <UncontrolledDropdown onClick={() => lecturePlayerService.HandleControlsMenuShowing(true)}>
                                <DropdownToggle tag="button" className="btn btn-link mr-xs-2">
                                    <LnIcon name="more" className="filter-white" title={t.getText("settings")} />
                                </DropdownToggle>
                                <DropdownMenu  >
                                    <div className="d-flex flex-column justify-content-center">

                                        <UncontrolledDropdown >
                                            <DropdownToggle title={t.getText("playback.speed")} tag="button" className="btn btn-link mr-xs-2">

                                                <LnIcon name="hare" className="filter-white" />
                                            </DropdownToggle>
                                            <DropdownMenu className="w-auto" >
                                                <div className={"d-flex flex-column justify-content-center " + classes.speed}>
                                                    <LnIcon name="turtle" className="icon-small filter-white" />
                                                    {speeds.map((s) => (

                                                        <button key={s} onClick={(e) => setPlaybackRate(e, s)} className={"btn btn-link d-flex align-items-center justify-content-center " + classes.speeds + " " + (playbackRate === s ? classes.speedButton : "")} >
                                                            <span>{Math.round(s * 100)}%</span>
                                                        </button>

                                                    ))}
                                                    <LnIcon name="hare" className="icon-small filter-white" />
                                                </div>

                                            </DropdownMenu>
                                        </UncontrolledDropdown>

                                        {!autoNext && currentScreen?.ScreenType === "Video" &&
                                            <button className="mt-3 btn btn-link" onClick={setAutoRepeat} title={t.getText("repeat")} >
                                                <LnIcon name="repeat" className={autoRepeat ? "filter-green" : ""} />
                                            </button>
                                        }

                                        {(currentLecture.AllViewed || currentLecture.AlwaysAllowAutoplay) && !autoRepeat &&
                                            <button className="mt-3 btn btn-link " onClick={setAutoNext} title={t.getText("lecture.autoplay.next")}>
                                                <svg version="1.1" viewBox="0 0 16 18" width="2.8rem" height="2.8rem">
                                                    <path className={[classes.autoNext, autoNext ? classes.green : ""].join(" ")} d="m15.333 8.3906-13.578-7.278a0.838 0.78778 0 0 0-1.255 0.67309v14.571a0.838 0.78778 0 0 0 1.255 0.67309l13.578-7.278a0.834 0.78402 0 0 0 0-1.3612z" fill="#fff" fillRule="evenodd" opacity="1" stroke="#fff" strokeWidth="1px" />
                                                </svg>
                                            </button>

                                        }
                                        <a className="btn btn-link mt-3" rel="noopener noreferrer" href={window.location.pathname} target="_blank" title={t.getText("open.in.new.window")} >
                                            <LnIcon name="newwindow" />
                                        </a>

                                    </div>
                                </DropdownMenu>
                            </UncontrolledDropdown>


                        </div>

                        <button title={t.getText("screen.miniatures")} ref={thumbsButton} className="btn btn-link" onClick={showThumbs}>
                            <LnIcon name="slides-stack" />
                        </button>
                    </div>


                </div>

            </div>
          

            <Popover
                placement="top"
                isOpen={showNextPopup}
                target="#nextscreen"
                toggle={closeTooltip}
            >
                <PopoverHeader className="px-3 d-flex">
                    <div>{t.getText("continue")}</div>
                    <button onClick={closeTooltip} className="ml-auto btn btn-link">x</button>

                </PopoverHeader>
                <PopoverBody className="px-3 pb-3">
                    {t.getText("lecture.continue.on.next")}
                </PopoverBody>
            </Popover>


            <ScreensDialog container="#lnlectcontrols" />

        </>
    )
}


export default LectureControls;