// @ts-check
import React, { useState } from 'react';
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import LnIcon from "components/LnIcon";
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'assets/scss/_datepicker.scss';
import { useI18n } from "components/lni18n";
import useTypedSelector from "utils/useTypedSelector";
import 'dayjs/locale/sv';
import 'dayjs/locale/da';
import 'dayjs/locale/en-gb';
import TimePicker from "./TimePicker";

/**
 * DateTimePicker
 * @module components/DateTimePicker/DateTimePicker
 * @return a Date selected by the user.
 * @param {{
    StartDate:  Date,
    EndDate: Date,
    DatePickerId?: string,
    onChange: Function,
    showIcon?: boolean,
    showMonthDropdown?: boolean,
    showYearDropdown?: boolean,
    showTimeSelect?: boolean,
    showWeekNumbers?: boolean,
    timeCaption?: string, // time
    timeFormat?: string, // HH:mm
    timeIntervals?: number, // {15}
    renderDays: 'all' | 'past' | 'future',
    lockStartDate?:boolean

}} props
 */
const DateSpanPicker = (props) => {


    const initialState = {
        from: props.StartDate,
        to: props.EndDate,
    };
    const [state, setState] = useState(initialState);
    const { languageService: t } = useI18n();
    const [show, setShowDialog] = useState(undefined)
    const [month, setMonth] = useState(props.StartDate);
    const [dropDownDate, setDropDownDate] = useState(props.StartDate);

    const currentLang = useTypedSelector(state => state.text.currentLang);

    const [startTime, setStartTime] = useState(dayjs(props.StartDate));
    const [endTime, setEndTime] = useState(dayjs(props.EndDate));


    dayjs.extend(localeData);
    const globalLocaleData = dayjs.localeData()
    const localMonths = globalLocaleData.months();
    const localDaysShort = globalLocaleData.weekdaysShort();
    const localDays = globalLocaleData.weekdays();
    const firstDayOfWeek = globalLocaleData.firstDayOfWeek();


    // useEffect(() => {
    //     if (props.showTimeSelect) {
    //         setStartTime(startTime.hour(0).minute(0));
    //         setEndTime(endTime.hour(23).minute(59));
    //     }
    //     // eslint-disable-next-line 
    // }, []);



    const setstartMonth = (index) => {
        const d = dayjs(dropDownDate).month(index).toDate();
        setDropDownDate(d)
        setMonth(d);
    }

    const setstartYear = (y) => {
        const d = dayjs(dropDownDate).year(y).toDate();
        setDropDownDate(d)
        setMonth(d);

    }

    const setToday = () => {
        setDropDownDate(new Date());
        setMonth(new Date())
    }


    const handleResetClick = () => {

        const start = props.lockStartDate ? props.StartDate : null;

        setState({ from: start, to: null });
    }


    const handleDayClick = (day) => {

        const range = DateUtils.addDayToRange(day, state);

        if( props.lockStartDate){
            range.from = props.StartDate;
        }

        setState({
            from: adjustTime(range.from, startTime),
            to: adjustTime(range.to, endTime)
        });
    }

    const adjustTime = (date, mom) => {
        if (!date) {
            return null;
        }

        return dayjs(date).hour(mom.hour()).minute(mom.minute()).toDate();
    }


    let today = dayjs().hour(0).minute(1);

    let disabledDays = (day) => false;
    if (props.renderDays === 'past') {

        disabledDays = (day) => {
            return today.isBefore(day, 'day');
        }
    }
    if (props.renderDays === 'future') {
        disabledDays = (day) => {
            return today.isAfter(day, 'day');
        }
    }

    if (props.lockStartDate ) {
        disabledDays = (day) => {
            return dayjs(props.StartDate).isAfter(day, 'day');
        }
    }



    const years = [];
    const startYear = (new Date()).getFullYear() - 10;
    for (let i = startYear; i <= (startYear + 20); i += 1) {
        years.push(i);
    }

    const handleMonthChange = (month) => {
        setDropDownDate(month);
        setMonth(month);
    }

    const closeAndSetDate = () => {
        setShowDialog(false);
        props.onChange(state);

    }

    const Navbar = (prps) => {
        return <div className="mt-3 d-flex justify-content-between">
            <button className="btn btn-link" onClick={() => prps.onPreviousClick()} ><LnIcon name="previous" className="mr-2" /> {t.getText("previous")} </button>
            <button className="btn btn-link" onClick={() => prps.onNextClick()} >{t.getText("next")}<LnIcon className="ml-2" name="next" /></button>
        </div>
    }

    const { from, to } = state;
    const modifiers = { start: from, end: to };


    const handleStartTime = (t) => {
        const d = dayjs(state.from).hour(t.hour).minute(t.minute);
        setStartTime(d);
        setState({ ...state, from: d.toDate() });
    }
    const handleEndTime = (t) => {
        const d = dayjs(state.to).hour(t.hour).minute(t.minute);
        setEndTime(d);
        setState({ ...state, to: d.toDate() });

    }

    const showPicker = () => {
        setState({
            from: props.StartDate,
            to: props.EndDate
        });

        setShowDialog(true);
    }

    const isValid = dayjs(state.to).isAfter(dayjs(state.from));

    return (<>
        <button className="btn btn-link px-3" type="button" onClick={() => showPicker()} >

            {!dayjs(props.StartDate).isSame(dayjs(props.EndDate), "date") ? (

                <div className="d-flex justify-content-center align-items-center pl-2">

                    <div className="d-flex justify-content-start flex-column mr-2">
                        <span >{t.getText("startdate")}</span>
                        {props.showTimeSelect ?
                            <span className="white-space-nowrap">{t.getShortDateTimeString(props.StartDate)}</span> :
                            <span className="white-space-nowrap">{t.getShortDateString(props.StartDate)}</span>
                        }

                    </div>
                    {props.showIcon && <LnIcon className="ml-3 icon-medium" name="datepicker-icon" />}
                    <div className="d-flex flex-column ml-3">
                        <span>{t.getText("enddate")}</span>
                        {props.showTimeSelect ?
                            <span className="white-space-nowrap">{t.getShortDateTimeString(props.EndDate)}</span> :
                            <span className="white-space-nowrap">{t.getShortDateString(props.EndDate)}</span>
                        }
                    </div>
                    <div>{props.children}</div>
                </div>)
                : (
                    <div className="d-flex justify-content-center align-items-start pl-2 mt-3">
                        <div className="mr-2">
                            <div >{t.getText("date")}</div>
                            <div className="white-space-nowrap">{t.getShortDateString(props.StartDate)}</div>
                        </div>

                        {props.showTimeSelect && <>
                            <div className="mr-2">
                                <div >{t.getText("start.time")}</div>
                                <div className="white-space-nowrap">{t.getTimeString(props.StartDate)}</div>
                            </div>

                            <div className="mr-2">
                                <div >{t.getText("end.time")}</div>
                                <div className="white-space-nowrap">{t.getTimeString(props.EndDate)}</div>
                            </div>
                        </>}
                        {props.showIcon && <LnIcon className="ml-3 mt-1 icon-medium" name="datepicker-icon" />}
                        <div>{props.children}</div>
                    </div>
                )}

        </button>

        <Modal backdrop="static" role="dialog" isOpen={show} toggle={() => setShowDialog(false)} size="lg" >
            <ModalBody tabIndex={-1}>
                {(props.showMonthDropdown || props.showMonthDropdown) &&
                    <div className="d-flex justify-content-center">
                        {props.showMonthDropdown &&
                            <UncontrolledDropdown >
                                <DropdownToggle caret className="btn btn-primary btn-small">
                                    {dayjs(dropDownDate).format("MMM")}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {localMonths.map((m, i) => <DropdownItem className={i === dropDownDate.getMonth() ? "font-weight-bold text-success" : ""} key={i} onClick={() => setstartMonth(i)} >{m}</DropdownItem>)}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        }

                        {props.showYearDropdown &&
                            <UncontrolledDropdown className="ml-5">
                                <DropdownToggle caret className="btn btn-primary btn-small">
                                    {dayjs(dropDownDate).format("YYYY")}
                                </DropdownToggle>
                                <DropdownMenu >
                                    {years.map((y) => <DropdownItem className={y === today.year() ? "font-weight-bold text-success" : ""} key={y} onClick={() => setstartYear(y)} >{y}</DropdownItem>)}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        }


                    </div>
                }

                <div className="d-flex m-auto justify-content-center">

                    <DayPicker
                        className="range"
                        onDayClick={(d) => handleDayClick(d)}
                        initialMonth={state.from}
                        numberOfMonths={window.outerWidth < 900 ? 1 : 2}
                        showOutsideDays={true}
                        showWeekNumbers={props.showWeekNumbers}
                        selectedDays={[state.from, { from: state.from, to: state.to }]}
                        disabledDays={disabledDays}
                        month={month}
                        locale={currentLang.Short}
                        navbarElement={<Navbar />}
                        modifiers={modifiers}
                        //onDayMouseEnter={handleDayMouseEnter}
                        onMonthChange={handleMonthChange}
                        months={localMonths}
                        weekdaysLong={localDays}
                        weekdaysShort={localDaysShort}
                        firstDayOfWeek={firstDayOfWeek}
                    />
                </div>


                <div className="d-flex justify-content-center align-items-center flex-wrap">
                    {!state.from && !state.to && t.getText('set.startdate')}
                    {state.from && !state.to && t.getText('set.enddate')}
                    {state.from &&
                        state.to &&
                        <div className="d-flex">

                            <div className="d-flex flex-wrap">

                                {!dayjs(state.from).isSame(dayjs(state.to), "date") ? (<>
                                    <div className="d-flex flex-column mr-5 mb-3">
                                        <span >{t.getText("startdate")}</span>
                                        <div className="d-flex align-items-center">
                                            {!props.showTimeSelect ?
                                                <span >{t.getShortDateString(state.from)}</span>
                                                : (!props.lockStartDate ? 
                                                <TimePicker onTimeChanged={handleStartTime} toggleText={t.getShortDateTimeString(state.from)} startTime={startTime} />
                                                : <span >{t.getShortDateTimeString(state.from)}</span>)
                                            }
                                        </div>

                                    </div>
                                    <div className="d-flex flex-column mr-5">
                                        <span >{t.getText("enddate")}</span>
                                        <div className="d-flex align-items-center">
                                            {!props.showTimeSelect ?
                                                <span>{t.getShortDateString(state.to)}</span>
                                                :
                                                <TimePicker onTimeChanged={handleEndTime} toggleText={t.getShortDateTimeString(state.to)} startTime={endTime} />
                                            }
                                        </div>
                                    </div>
                                </>)
                                    :
                                    (
                                        <div className="d-flex justify-content-start align-items-start pl-2 flex-wrap">
                                            <div className="d-flex flex-column mr-2 ">
                                                <span >{t.getText("date")}</span>
                                                <span >{t.getShortDateString(state.from)}</span>
                                            </div>

                                            {props.showTimeSelect && <div className="d-flex">
                                                <div className="d-flex flex-column mr-2">
                                                    <span >{t.getText("start.time")}</span>
                                                    { !props.lockStartDate ?  
                                                    <TimePicker onTimeChanged={handleStartTime}
                                                        toggleText={t.getTimeString(state.from)} startTime={startTime} />
                                                        :
                                                        <span>{startTime.format("HH:mm")}</span>
                                                    }

                                                </div>

                                                <div className="d-flex flex-column mr-2">
                                                    <span >{t.getText("end.time")}</span>
                                                    <TimePicker onTimeChanged={handleEndTime}
                                                        toggleText={t.getTimeString(state.to)} startTime={endTime} />
                                                </div>
                                            </div>}
                                        </div>


                                    )}
                            </div>



                            <div>
                                <button className={'btn btn-small btn-primary ml-3 mb-4'} onClick={() => setToday()}>{t.getText("today")}</button>
                                <button className="ml-3 btn btn-small btn-inverse" onClick={handleResetClick}>
                                    {t.getText("reset")}
                                </button>
                            </div>
                        </div>
                    }
                </div>


            </ModalBody>
            <ModalFooter className="d-flex">


                {!isValid && <div className="small alert alert-warning preserve-white">{t.getText("correct_eventdates_alert")}</div>}
                <button className={'mr-auto btn  btn-inverse  ml-2'} onClick={() => setShowDialog(false)}>{t.getText("cancel")}</button>
                <button disabled={!isValid} className={'btn btn-primary '} onClick={closeAndSetDate}>{t.getText("select")}</button>
            </ModalFooter>
        </Modal>
    </>

    );

}

export default DateSpanPicker;

