//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import { LoginDuck } from "features/login/LoginDuck";
import dayjs from "dayjs";
import DispatchService from "utils/DispatchService"
import { errorDuck } from "components/ErrorHandler/ErrorHandlerDuck";
import languageService from "features/language/languageService";
import { Alert } from 'components/OnlineModal';

/**
 * @type {import('./User').UserState}
 */
const initialState = {
    userData: null,
    sessionData: null,
    userProgress: null,
    policyData: null
};

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {

        /**
        * @param  {  {payload: import('./User').UserDetailDto}} action
        */
        setUserData(state, action) {

            state.userData = action.payload;
        },

        /**
       * @param  {  {payload: import('./User').PolicyData}} action
       */
        setPolicyData(state, action) {

            state.policyData = action.payload;
        },

        /**
        * @param  {  {payload: import('./User').UserLoggedInDto}} action
        */
        setSessionData(state, action) {
            state.sessionData = action.payload;
        },

        /**
        * @param  {  {payload: import('features/classes/Classes').ClassProgressDto}} action
        */
        setProgressData(state, action) {
            state.userProgress = action.payload;
        },

        /**
        */
        setPolicyApproved(state) {
            if (state.policyData) {
                state.policyData.HasApprovedPolicy = true;
            }
        },

        /**
        */
        setCookiesApproved(state) {
            if (state.policyData) {
                state.policyData.HasApprovedCookies = true;
            }
        },

        /**
         * @param  {  {payload: import('./User').UserDetailChangeInfoResultDto}} action
         */
        updateLocalUserData(state, action) {

            if (state.userData) {
                state.userData.Info.Email = action.payload.Email;
                state.userData.Info.Email2 = action.payload.Email2;
            }
        },

        /**
         * @param  {  {payload: "Email"|"Email2"}} action
         */
        verifyLocalEmail(state, action) {

            if (state.userData) {
                state.userData.Info[action.payload].Verified = true;
            }
        },




    }
});



const getUserData = async (userId, dispatch, doNotSetNull) => {

    if (!doNotSetNull) {
        dispatch(userDuck.setUserData(null));
    }

    /**
     * @type {{data: import('./User').UserDetailDto }}
     */
    var response = await instance.get(endPoint.GET_USER_DATA_URL(userId));
    if (!response) {
        return;
    }

    var data = response.data;
    dispatch(userDuck.setUserData(data));
}

/**
 * 
 * @param {string} userId 
 * @param {Date?} startdate 
 * @param {Date?} enddate 
 * @param {Function} dispatch 
 */
const getSessionData = async (userId, startdate, enddate, dispatch) => {

    if (!startdate) {
        startdate = dayjs().add(-30, "d").toDate();
        enddate = new Date();
    }

    /**
     * @type {{data: import('./User').UserLoggedInDto }}
     */
    const response = await instance.get(endPoint.GET_USER_SESSIONS_URL(userId, startdate, enddate));
    if (!response) {
        return;
    }
    dispatch(userDuck.setSessionData(response.data));
}

// /**
//  * 
//  * @param {string?} classId 
//  * @param {Function} dispatch 
//  */
// const getUserResults = async (classId, dispatch) => {

//     if (classId == null) {
//         /**
//          * @type { {data: string}}
//          */
//         const resp = await instance.get(endPoint.USER_CURRENT_CLASSID_URL);
//         if (!resp) {
//             return;
//         }
//         classId = resp.data;
//     }

//     /**
//      * @type {{data: import('features/classes/Classes').ClassProgressDto }}
//      */
//     const response = await instance.get(endPoint.GET_CURRENT_USER_PROGRESS_URL(classId));
//     if (!response) {
//         return;
//     }
//     dispatch(userDuck.setProgressData(response.data));
// }


/**
 * 
 * @param {string?} classId 
 * @param {Function} dispatch 
 */
const getUserProgress = async (userid, classId, dispatch) => {

    if (!classId) {
        return;
    }

    /**
     * @type {{data: import('features/classes/Classes').ClassProgressDto }}
     */
    const response = await instance.get(endPoint.GET_USER_PROGRESS_URL(userid, classId));
    if (!response) {
        return;
    }
    dispatch(userDuck.setProgressData(response.data));
}

const isUnique = async (propname, value, userid) => {
    // /api/user/Property/{propname}/{value}/check
    const url = endPoint.GET_USER_ISUNIQUE_URL(propname, value, userid);
    try {
        const result = await instance.get(url);
        return result && result.data && result.data.Status ? result.data.Status : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}



/**
 *
 * @param {import('./User').UserDetailChangeInfoBodyDto } postData
 */
const saveUserData = (userId, postData) => async (dispatch) => {

    const url = endPoint.GET_USER_SAVE_DATA_URL(userId);
    /**
     * @type {{data: import('./User').UserDetailChangeInfoResultDto }} response
     */
    const response = await instance.put(url, postData);
    if (response && response.data) {
        userDuck.getUserData(userId, dispatch, true)
    }
}

const saveUserPassword = async (userId, postData, dispatch) => {

    const url = endPoint.GET_USER_SAVE_PASSWORD_URL(userId);
    /**
     * @type {{data: import('./User').UserDetailDto }} response
     */
    await instance.post(url, postData);
}

const uploadAvatar = (userId, fileData) => async (dispatch) => {
    const url = endPoint.GET_USER_IMAGE_URL(userId);
    await instance.post(url, fileData);
    //userDuck.getUserData(userId, dispatch);
    dispatch(LoginDuck.changedUserImage(userId));
}

/**
 * 
 * @param {string} userId 
 * @param {string} password 
 */
const deleteAvatar = (userId, password) => async (dispatch) => {
    const url = endPoint.GET_USER_IMAGE_DELETE_URL(userId);
    await instance.post(url, {
        Password: password
    });
    dispatch(LoginDuck.changedUserImage(userId));
}
/**
 *
 * @param { string } text
 * @param {  'zip' | 'office' | 'onetimepw' | 'switchOnly' } param
 * @param { any } value
 */
const getUsers = async (text, param, value) => {
    try {
        const url = endPoint.GET_USER_FIND_URL(text, param, value);
        const response = await instance.get(url);
        return (response && response.data) || [];
    } catch (error) {
        DispatchService.dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

const updateTestEmailSettings = async (userid, state) => {
    const url = endPoint.GET_USER_TEST_EMAIL_SETTINGS_URL(userid, state);
    await instance.put(url);
}

const updateContentChangedEmailSettings = async (userid, state) => {
    const url = endPoint.GET_USER_CONTENT_CHANGED_EMAIL_SETTINGS_URL(userid, state);
    await instance.put(url);
}

const getPolicyData = (userId) => async (dispatch) => {

    dispatch(userDuck.setPolicyData(null));
    const url = endPoint.GET_USER_POLICY_TEXT_URL(userId);
    /**
     * @type { {data: import('./User').PolicyData}}
     */

    var response = await instance.get(url);
    if (response && response.data) {
        dispatch(userDuck.setPolicyData(response.data));
    }
}

const approveCookies = (userId) => async (dispatch) => {

    const url = endPoint.GET_USER_COOKIE_APPROVE_URL(userId);
    /**
     * @type { {data: import('./User').PolicyData}}
     */

    var response = await instance.put(url, null);
    if (response && response.data) {
        dispatch(userDuck.setCookiesApproved());
    }
}

const approvePolicy = (userId) => async (dispatch) => {

    const url = endPoint.GET_USER_POLICY_APPROVE_URL(userId);
    /**
     * @type { {data: import('./User').PolicyData}}
     */

    var response = await instance.put(url, null);
    if (response && response.data) {
        dispatch(userDuck.setPolicyApproved());
    }
}

/**
*
* @param { string } userId
* @param {  number } type
*/
const sendVerifyEmail = (userId, type) => async (dispatch) => {


    /**
     * @type {{data:{Value: string}}}
     */
    const result = await instance.post(endPoint.GET_USER_EMAIL_VERIFY_URL(userId, type), null);

    if (result && result.data) {
        Alert({
            backdrop: true,
            title: languageService.getText('dashboard.email.verification.header'),
            message: result.data.Value,
            button: {
                className: 'btn btn-primary',
                text: languageService.getText("ok"),
                type: "submit"
            }
        });
    }


}

/**
*
* @param { string } userId
* @param {  1|2 } type
*/
const verifyEmail = (userId, type) => async (dispatch) => {


    instance.post(endPoint.GET_USER_VERIFY_EMAIL_NOW_URL(userId, type), null)
        .then((result) => {
            if (result && result.data) {
                switch (type) {
                    case 1:
                        dispatch(userDuck.verifyLocalEmail("Email"));
                        break;
                    case 2:
                        dispatch(userDuck.verifyLocalEmail("Email2"));
                        break;
                    default:
                        break;
                }
            }
        });


}



export const userDuck = {
    ...userSlice.actions, getUserData, isUnique, getUsers, updateEmailSettings: updateTestEmailSettings, updateContentChangedEmailSettings,
    saveUserData, saveUserPassword, uploadAvatar, deleteAvatar, getSessionData, getUserProgress,
    GetPolicyData: getPolicyData, approvePolicy, approveCookies, sendVerifyEmail, verifyEmail
};
export default userSlice.reducer;