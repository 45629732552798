// @ts-check
import React, { useCallback } from "react";
import { LoginDuck } from "./LoginDuck";
import useTypedSelector from "utils/useTypedSelector";
import { useI18n } from "components/lni18n";
import classes from './login.module.scss';
import { useDispatch } from "react-redux";
import { useStore } from "react-redux";

const VerifyEmail = (props) => {

    const { languageService: t } = useI18n();
    const { verifyEmailData } = useTypedSelector(state => state.login);
    const dispatch = useDispatch();
    const store = useStore()


    useCallback(() => {
        const d = dispatch;
        dispatch(LoginDuck.setVerifyEmailData(null));
        return () => d(LoginDuck.setVerifyEmailData(null));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const doVerify = ()=>{
        const {
            location: { payload: { ticket, stamp, signature } },
        } = store.getState();

        dispatch(LoginDuck.GetVerifyEmailData({ signature, stamp, ticket }));
    }
    
    return <div className="h-100">

        <div className={"d-flex mt-5 align-items-center justify-content-center "}>
            <div className={classes.forms + " bg-white rounded px-5 pt-4 pb-5 pb-5"}>
                <img src="/imgs/logo.svg" alt="logo" className="mb-4" />

                {!verifyEmailData && <>
                    <h3 className="mb-5">{t.getText("dashboard.email.verification.header")}</h3>
                    <div>
                        <button className="btn btn-primary" onClick={doVerify}>{t.getText("verify")}</button>
                    </div>
                </>}

                {verifyEmailData && <>

                    {verifyEmailData.Error ? <div className="my-5">
                        <h3>{t.getText("error")}</h3>
                        <div dangerouslySetInnerHTML={{ __html: verifyEmailData.Error }}></div>
                    </div> :
                        <>

                            <h3 className="mb-5">{t.getText("email.verify.success.header")}</h3>
                            <div className="mt-2 mb-5 preserve-white">
                                {t.getText("email.verify.success.message")}
                            </div>

                            <div>
                                <a href="/" className="btn btn-primary">{t.getText("continue.to.laranara")}</a>
                            </div>

                        </>
                    }
                </>}
            </div>
        </div>
    </div>



}

export default VerifyEmail;
