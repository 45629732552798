// @ts-check
import React, { useEffect } from "react";
import { useDispatch, shallowEqual } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import { useI18n } from "components/lni18n";
import LoginMain from "./loginMain";
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import { Alert } from "components/OnlineModal";
import { LOGO } from "assets/images";
import { LoginDuck } from "./LoginDuck";
import WaitRipple from "components/WaitRipple";


const LoginComponent = props => {

    const { languageService: t } = useI18n();
    const dispatch = useDispatch();
    const {
        errorState,
        isLoading,
        isLoggingIn,
        loginData,
        loggedIn,
        loginGuiState,
        user,
    } = useTypedSelector(state => state.login, shallowEqual);


    useEffect(() => {
        // error at login. Display as dialog and then reset the error message
        if (errorState) {
            Alert({
                backdrop: 'static',
                button: {
                    className: "btn btn-primary",
                    type: "submit",
                    text: t.getText("ok")
                },
                message: <div className="preserve-white" dangerouslySetInnerHTML={{ __html: errorState.message }}>
                </div>,
                title: errorState.header || t.getText("wronglogin"),
                class: null
            }).finally(() => // reset message
                dispatch(LoginDuck.resetError(errorState)));
        }
    }, [errorState, dispatch, t]);



    const currentClass = useTypedSelector(state => state.classData.currentClass);

    const handleSendPasswordMail = async data => {
        if (!data.Identifier || data.Identifier === "") {
            return;
        }

        dispatch(LoginDuck.setLoadingState(true));
        const val = { Value: data.Identifier };
        let dialogText;
        try {
            const result = await instance.post(endPoint.FORGOT_PASSWORD_URL, val);
            if (!result) {
                return;
            }
            dialogText = result.data.Message;

            dispatch(LoginDuck.setGuiState({ ...loginGuiState, Identifier: data.Identifier, Password: "" }));

        } catch (error) {
            dialogText = <div><span className="text-danger">{t.getText("error")}</span>
                <span>{error.message}</span>
            </div>;
        }

        await Alert({
            backdrop: "static",
            title: t.getText("passwd"),
            message: dialogText,
            button: {
                className: "btn btn-primary",
                text: t.getText("ok"),
                type: "submit"
            }
        });

        dispatch(LoginDuck.setLoadingState(false));
    };


    if (loggedIn && user && currentClass != null) {
        dispatch(LoginDuck.setRedirectUrl(null));
    }

    if (loginData == null) {
        return <div className="pt-5 ">
            <img alt="" className="small w-50 mx-auto d-block" src={LOGO} />
            <form action="/" method="POST">
                <button type="submit" className="btn btn-primary d-block mt-5 mx-auto" >{t.getText("refresh")}</button>
            </form>

            <WaitRipple></WaitRipple>
        </div>
    }

    return (
        <>
            <LoginMain
                isLoading={isLoading || isLoggingIn}
                federatedTextData={loginGuiState.federatedTextData}
                handleSendPasswordMail={handleSendPasswordMail}
            />
        </>
    );
};

export default LoginComponent;
