//@ts-check

const FilterType = {
    ByPosts:0,
    ByComments: 1,
}


const  Enums = {FilterType};

export default Enums;
