//@ts-check
import React, { useState, useRef, useCallback } from "react";
import instance from "utils/axios";
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import LnIcon from "components/LnIcon";
import { useI18n } from "components/lni18n";


const PermissionsTypeahead = ({ queryUrlgetter, itemChosen }) => {

    const ref = useRef();
    const { languageService: t } = useI18n();
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [searchResult, setSearchResult] = useState([]);

    const handleSearch = useCallback(async (query) => {

        setSearchIsLoading(true);

        try {

            /**
             * @type {{data: import('./Permissions').UsersAndGroupsDto}}
             */
            const response = await instance.get(queryUrlgetter(query));
            if( !response){
                return;
            }
            const groups = response.data.Groups.map((g) => ({
                ...g, type: "group"
            }));

            const users = response.data.Users.map((u) => ({
                ...u, type: "user"
            }));

            const options = [...users, ...groups];
            setSearchResult(options);
        } finally {
            setSearchIsLoading(false);
        }
    // eslint-disable-next-line 
    }, []);

    const handleItemChosen = (e) => {

        if (e && e.length > 0 && e[0].Id) {
            itemChosen(e[0]);
        }

        setSearchResult([]);
        if (ref && ref.current) {
            // @ts-ignore
            ref.current.clear();
        }

    }


    const renderItems = (option, props, index) => {
        return [
            <div key="Receiver" className="d-flex">
                <LnIcon name={option.type} className="more" />
                <div className="ml-2">
                    <span>
                        <Highlighter search={props.text}>
                            {option.Name}
                        </Highlighter>
                    </span>

                </div>
            </div>
        ];
    }

    return <AsyncTypeahead
        highlightOnlyResult={true}
        id="permissions-select"
        isLoading={searchIsLoading}
        labelKey="Name"
        filterBy={()=>true}
        minLength={3}
        multiple={false}
        onChange={handleItemChosen}
        onSearch={handleSearch}
        options={searchResult}
        placeholder={t.getText("name")}
        renderMenuItemChildren={renderItems}
        selected={null}

        ref={ref}
    />

}


export default PermissionsTypeahead;