//@ts-check
import React, { useCallback } from "react";
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import { useI18n } from "components/lni18n";
import UserImg from "components/UserImg";
import { endPoint } from "AppConstants";
import Enums from "utils/Enums";

/**
 * 
 * @param {{ enabled: boolean, filterType:number, filterTypeChanged: (id:number)=>void,  userChanged: (us:import("features/User/User").UserInfoCellDto)=>void, 
 *  selected: import("features/User/User").UserInfoCellDto,  users: import("features/User/User").UserInfoCellDto[], className: string, subGroupId? :string}} props  
 */
const GetStudentsForFilter = ({ selected, enabled, filterType, users, userChanged, filterTypeChanged, className }) => {

    const { languageService: t } = useI18n();

    const userClicked = useCallback((us) => {


        if (us && us.length > 0) {
            userChanged(us[0]);
        }
        else {
            userChanged(null);
        }
    },[userChanged]);

   
    const renderUsers = (option, props, index) => {
        const userImage = endPoint.API_HOST + option.ImageUrl;
        return [
            <div key="Receiver" className="d-flex">
                <UserImg src={userImage} className={['rounded-circle', 'avatar-img', 'mr-4'].join(' ')} alt={t.getText('user')} />
                <div className="d-flex flex-column">
                    <span>
                        <Highlighter search={props.text}>
                            {option.FullName}
                        </Highlighter>
                    </span>
                    <small>
                        <Highlighter search={props.text}>
                            {option.Mail}
                        </Highlighter>
                    </small>
                </div>
            </div>
        ];
    }

    return <div className={className}>
        <div>
            {t.getText("filter.on.person")}
        </div>
        <div>
            {users && <Typeahead
                highlightOnlyResult={true}
                id="receiver-select"
                labelKey={(option) => `${option.FullName} - ${option.Mail}`}
                minLength={0}
                multiple={false}
                placeholder={t.getText('name')}
                onChange={(us) => userClicked(us)}
                renderMenuItemChildren={renderUsers}
                positionFixed={false}
                options={users}
                selected={selected ? [selected] : []}

                clearButton
            />
            }

        </div>
        <div className="mt-2">

            <div className="btn-group" role="group" aria-label="Basic example">
                <button disabled={!enabled} onClick={() => filterTypeChanged(Enums.FilterType.ByPosts)} type="button" className={(filterType === Enums.FilterType.ByPosts ? "btn-primary" : "btn-inverse") + " btn btn-small"}>{t.getText("posting")}</button>
                <button disabled={!enabled} onClick={() => filterTypeChanged(Enums.FilterType.ByComments)} type="button" className={(filterType === Enums.FilterType.ByComments ? "btn-primary" : "btn-inverse") + " btn btn-small"}>{t.getText("comment")}</button>
            </div>

        </div>

    </div>
}


export default GetStudentsForFilter;
