//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import useTypedSelector from "utils/useTypedSelector";
import { TestNotReadyStatuses, TestResultStatus } from "features/course/part/test/TestEnums";
/**
 * @type import('./Course').CourseState
 */
const initialState = {
    currentCourse: null,
    currentPartId: null,
    currentTestId: null,
    currentThemeId: null,
    partTab: null,
    testReady: false,
    testType: null,
    testInDialog: false,
    currentTest: null
};

const doSetCurrentTheme = (state, themeid) => {
    if (themeid === null || state.currentCourse === null) {
        state.currentThemeId = null;
        return;
    }

    if (state.currentCourse.Themes.find(t => t.Id === themeid) === null) {
        throw new Error("no theme with ID " + themeid);
    }
    state.currentThemeId = themeid;
}

const courseSlice = createSlice({
    name: 'course',
    initialState: initialState,
    reducers: {

        /**
     * @param  {  {payload: { themeId: string,  courseData: { Themes: (import('./Course').ThemeDto)[]}}}} action
     */
        setCurrentCourse(state, action) {
            state.currentCourse = action.payload.courseData;
            if (action.payload.themeId) {
                doSetCurrentTheme(state, action.payload.themeId);
            }
        },

        /**
         *
         * @param {{payload: string}} action
         */
        setCurrentTheme(state, action) {
            doSetCurrentTheme(state, action.payload);
        },

        /**
         *
         * @param {{payload: boolean}} action
         */
        setTestInDialog(state, action) {
            state.testInDialog = action.payload;
        },

        /**
         *
         * @param {{payload: import('./Course').CoursePartDetailDto}} action
         */
        setCurrentPart(state, action) {

            if (action.payload === null) {
                state.currentPartId = null;
                state.currentTestId = null;
                return;
            }

            // check if we have that part in here
            const { partIndex, themeIndex } = getPartAndThemeNumbers(state, action.payload.Id);

            if (partIndex === null) {
                return;
            }

            if (action.payload != null
                && action.payload.FileGroups
                && action.payload.FileGroups.length > 0
                && action.payload.AllFiles === undefined) {

                action.payload.AllFiles = getSortedFiles(action.payload.FileGroups);
            }

            const imgUrl = state.currentCourse.Themes[themeIndex].CourseParts
                && state.currentCourse.Themes[themeIndex].CourseParts[partIndex]
                && state.currentCourse.Themes[themeIndex].CourseParts[partIndex].ImageUrl;

            state.currentCourse.Themes[themeIndex].CourseParts[partIndex] = { ...action.payload, ImageUrl: imgUrl };
            state.currentPartId = action.payload.Id;
            state.currentTestId = null;
            state.testReady = false;
        },


        /**
         *
         * @param {{payload: {testType:string, startdata : import('./part/test/Test').StartTestDto<any>}}} action
         */
        setCurrentTest(state, action) {

            if (action.payload === null) {
                state.currentTestId = null;
                state.currentTest = null;
                return;
            }

            const testId = action.payload.startdata.TestId;

            const { partIndex, themeIndex } = getPartAndThemeNumbers(state, state.currentPartId);

            if (partIndex === null || themeIndex === null) {
                throw new Error("Cannot find part or theme");
            }

            const part = state.currentCourse.Themes[themeIndex].CourseParts[partIndex];
            if (part === null) {
                throw new Error("could not find part id " + partIndex);
            }


            state.currentTestId = testId;
            state.testType = action.payload.testType;
            state.currentTest = { ...action.payload.startdata, Data: null, Type: action.payload.testType };

            if (part.Tests) {
                const testIndex = part.Tests.map(t => t.Id).indexOf(testId);
                if (testIndex === -1) {
                    return;
                }

                if (action.payload.startdata.UpdatedTestDescription) {
                    state.currentCourse.Themes[themeIndex].CourseParts[partIndex].Tests[testIndex].Description
                        = action.payload.startdata.UpdatedTestDescription;
                }
            }
        },

        /**
         *
         * @param {{payload: {state: boolean}}} action
         */
        setTestReadyToView(state, action) {
            state.testReady = action.payload.state;
        },



        /**
       *
       * @param {{payload: {testId:string, status: string}}} action
       */
        setTestStatus(state, action) {
            const part = getCurrentPart(state);
            const test = part.Tests.find(t => t.Id === action.payload.testId);

            if (test && test.Id === action.payload.testId) {
                test.Status = action.payload.status;
                if (test.ConnectedLecture) {
                    if (!TestNotReadyStatuses.find(ts => ts === action.payload.status)) {
                        test.ConnectedLecture.Ready = true;
                    }
                }
            }

            if (state.currentTest) {
                state.currentTest.Status = action.payload.status;
            }
        },

        /**
         *
         * @param {{payload: string}} action
         */
        setPartTab(state, action) {
            state.partTab = action.payload;
        },

        /**
        * @param  {  {payload: { partId:string, status:string}}} action
        */
        setStatusOnPart(state, action) {

            const part = getCurrentPart(state);
            if (part != null) {
                part.Status = action.payload.status;
            }

        },
        /**
        * @param  {  {payload: { partId:string, lectureId:string}}} action
        */
        setLectureWatched(state, action) {

            const part = getCurrentPart(state);
            if (part != null && part.Id === action.payload.partId) {
                const lecture = part.Lectures.find(l => l.Id === action.payload.lectureId)
                if (lecture) {
                    lecture.Watched = true;
                }
            }
        },

        /**
        * @param  {  {payload: { fileId:string}}} action
        */
        setFileViewed(state, action) {
            const part = getCurrentPart(state);
            var file = part.AllFiles.find(f => f.Id === action.payload.fileId);
            if (file) {
                file.Viewed = true;
            }

            part.FileGroups.forEach(g => {
                if (g.Files) {
                    file = g.Files.find(f => f.Id === action.payload.fileId)
                    if (file) {
                        file.Viewed = true;
                    }
                }
            })
        },

        /**
        * @param  {  {payload: { linkId:string}}} action
        */
        setLinkViewed(state, action) {
            const part = getCurrentPart(state);
            part.LinkGroups.forEach(g => {
                const l = g.Links.find(l => l.Id === action.payload.linkId);
                if (l) {
                    l.Viewed = true;
                }
            });


        },


        /**
       * @param  {  {payload: string}} action
       */
        setCurrentPartWatched(state, action) {

            const part = getCurrentPart(state);
            if (part != null && part.Id === action.payload) {
                part.Watched = true;

                part.Tests.filter(t => !t.CanStartTest).forEach(t => t.CanStartTest = true);

                if (!part.Tests || part.Tests.length === 0) {
                    part.Status = "approvedbyteacher";
                }
            }



        },

        /**
        * @param  {  {payload: { questionId: number, answerNumber: number }}} action
        */
        setEvalMcAnswerForCurrentPart(state, action) {
            const part = getCurrentPart(state);
            if (part != null) {
                const question = part.Eval.Questions.find(q => q.Id === action.payload.questionId);
                if (question == null) {
                    throw Error("No question found");
                }
                if (question.Answers == null) {
                    throw Error("No mc answers");
                }
                question.Answers.forEach(a => a.Checked = a.Id === action.payload.answerNumber);
            }
            part.Eval.CanSubmit = canSubmitEval(part.Eval);
        },

        /**
        * @param  {  {payload: { questionId: number, text: string }}} action
        */
        setEvalTextAnswerForCurrentPart(state, action) {

            const part = getCurrentPart(state);
            if (part != null) {
                const question = part.Eval.Questions.find(q => q.Id === action.payload.questionId);
                if (question == null) {
                    throw Error("No question found");
                }
                question.TextAnswer = action.payload.text;
            }

            part.Eval.CanSubmit = canSubmitEval(part.Eval);
        },

        /**
       * @param  {  {payload: import('./Course').EvalInfo}} action
       */
        updateEvalData(state, action) {

            const part = getCurrentPart(state);
            if (part != null) {
                part.Eval = action.payload;
            }
        },

        /**
     * @param  {  {payload: string}} action
     */
        clearTestResult(state, action) {

            const part = getCurrentPart(state);
            if (part != null) {
                let test = part.Tests.find(t => t.Id === action.payload);
                if (test) {
                    test.ResultId = null;
                    test.Status = TestResultStatus.NotSet;
                }
            }
        },
    }
})
/**
 * 
 * @param {import('./Course').EvalInfo} evals 
 */
const canSubmitEval = (evals) => {

    let cansubmit = true;
    evals.Questions.forEach((q) => {
        if (q.AnswerType === "freetext" && !(q.TextAnswer && q.TextAnswer.length)) {
            cansubmit = false;
        }

        if (q.AnswerType === "multiplechoice") {
            const checked = q.Answers.find(a => a.Checked === true);
            if (!checked) {
                cansubmit = false;
            }
        }
    });

    return cansubmit;
}


/**
 *
 * @param {*} state
 * @param {*} wantedPartId
 * @return {{partIndex: number, themeIndex: number}}
 */
const getPartAndThemeNumbers = (state, wantedPartId) => {
    if (wantedPartId === null) {
        throw new Error("wantedPartId cannot be null");
    }
    let partIndex = null;
    let themeIndex = null;
    if (state.currentThemeId && state.currentCourse && state.currentCourse.Themes) {
        themeIndex = state.currentCourse.Themes
            .map(t => t.Id).indexOf(state.currentThemeId);

        if (state.currentCourse.Themes[themeIndex]) {
            partIndex = state.currentCourse.Themes[themeIndex].CourseParts
                .map(p => p.Id).indexOf(wantedPartId);
        }
    }

    return { partIndex, themeIndex };
}

const getSortedFiles = (fileGroups) => {
    let files = [];
    fileGroups.forEach(g => {
        g.Files.map(f => files.push(f))
    });
    files = files.sort((f1, f2) => {
        if (f1.LastModified === f2.LastModified) {
            return 0;
        }
        return f1.LastModified < f2.LastModified ? 1 : -1;
    })

    return files;
}

export const getCoursePart = async (dispatch, payload) => {

    try {
        var response = await instance.get(endPoint.GET_PART_URL(payload.classId, payload.partId));
        if (!response) {
            return;
        }
        dispatch(courseDuck.setCurrentPart(response.data));

    } catch (error) {
        handleErrors(error);
    }
}


// Hooks  *********************************************

const useCurrentTheme = () => {
    const currentTheme = useTypedSelector(state => {

        if (state.course && state.course.currentThemeId && state.course.currentCourse && state.course.currentCourse.Themes) {
            return state.course.currentCourse.Themes.find(t => t.Id === state.course.currentThemeId)
        }
        return null;
    });
    return currentTheme;
}

const useCurrentPart = () => {
    return useTypedSelector(state => {
        return courseDuck.getCurrentPart(state.course);
    });


}

/**
 * @return {import('features/course/part/test/Test').StartTestDto<any> }
 */
const useCurrentTest = () => {
    const { currentTest } = useTypedSelector(state => state.course);
    return currentTest;
}

/**
 *
 * @param {import('./Course').CourseState} state
 * @return {import('./Course').TestInfo}
 *
 */
const getCurrentTestInPartData = (state) => {

    /**
    * @return {{part: import('./Course').CoursePartDetailDto, currentTestId : string}}
    * @param {import('./Course').CourseState} courseState
    */
    const getIds = (courseState) => {
        const part = courseDuck.getCurrentPart(courseState);
        const currentTestId = courseState.currentTest.TestId;

        return { part, currentTestId };
    }

    const { part, currentTestId } = getIds(state);

    if (!part || !part.Tests) {
        return null;
    }

    // @ts-ignore
    return part.Tests.find(t => t.Id === currentTestId);
}

/**
 *
 * @return {import('./Course').CoursePartDetailDto}
 */
const getCurrentPart = (state) => {
    if (state && state.currentThemeId && state.currentPartId && state.currentCourse && state.currentCourse.Themes) {
        const theme = state.currentCourse.Themes.find(t => t.Id === state.currentThemeId)
        return theme.CourseParts.find(p => p.Id === state.currentPartId);
    }
    return null;
}
/**
 * 
 * @param {Function} dispatch 
 * @param {import('./Course').CoursePartDetailDto} part 
 */
const submitEval = async (dispatch, part, classId) => {

    const data = {
        Questions:
            part.Eval.Questions.map(q => {

                /**
                 * @type{import('./Course').CoursePartEvalQuestionFormDto}
                 */
                let answ = {
                    Id: q.Id,
                    AnswerType: q.AnswerType,
                    Type: q.Type,
                    Answer: null
                }

                if (q.AnswerType === "freetext") {
                    answ.Answer = q.TextAnswer;
                }
                if (q.AnswerType === "multiplechoice") {
                    const usersAnsw = q.Answers.find(a => a.Checked);
                    if (usersAnsw === null) {
                        throw Error("no answer found");
                    }
                    answ.Answer = usersAnsw.Id;
                }
                return answ;
            })
    };

    /**
     * @type{{data: import('./Course').EvalInfo}}
     */
    const response = await instance.post(endPoint.SUBMIT_EVAL_URL(classId, part.Id), data);
    if (!response) {
        return;
    }
    await dispatch(courseDuck.updateEvalData(response.data));



}

/**
 * @param {number} resultId
 * @param {string} classId
 * @param {string} testId
 */
const deleteResultForCurrentUserInCurrentPart = (classId, resultId, testId) => async (dispatch, getState) => {
    await instance.delete(endPoint.GET_DELETE_TEST_RESULT_URL(classId, resultId));
    dispatch(courseDuck.clearTestResult(testId))
}



//   Errors *******************************************

const handleErrors = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    console.log(error.config);

}


//  Exports ********************************************

export const courseDuck = {
    ...courseSlice.actions, getCoursePart, useCurrentTheme, useCurrentPart,
    getCurrentPart, useCurrentTest, getCurrentTestInPartData, submitEval, deleteResultForCurrentUserInCurrentPart
}

//export const { setCurrentTheme, setCurrentPart, setPartTab, setCurrentCourse, setStatusOnPart } = courseSlice.actions;
export default courseSlice.reducer

