//@ts-check

import React from "react";
import Link from "redux-first-router-link";
import {useDispatch} from "react-redux";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import { ModalDialog } from "components/OnlineModal.js";
import classes from './dashboard.module.scss';
import {classesDuck} from '../ClassesDuck';

/**
 * MessageListItem
 * @module features/classes/Dashboard/MessageListItem
 * @return a Message summary for use in listing.
 * @param {{
    item: any,
    nTClass: any,
    canEdit: boolean,
    classId: string
}} props
 */
const NotificationItem = (props) => {

    const { languageService: t } = useI18n();
    const dispatch = useDispatch();


    const item = props.item || {};

    const itemClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        ModalDialog({
            bodyClass: [classes['message-modal-body'], classes[item.DisplayType]].join(' '),
            buttons: [{
                className: 'btn btn-primary btn-small',
                text: t.getText('close'),
                type: 'dismiss'
            }],

            message: (<>
                <div className="d-flex justify-content-between">
                    <small>
                        <LnIcon className="mr-4" name="calendar-icon" />
                        {t.getShortDateString(item.StartDate)}

                    </small>
                    <span className={['badge badge-pill', classes[props.nTClass(item.NotificationType)]].join(' ')}>{item.Author && item.Author.FullName}</span>
                </div>
                <h3 className="mt-3">{item.Header}</h3>
                <div className={['mt-2', classes['full-message-text']].join(' ')} dangerouslySetInnerHTML={{ __html: item.Html }}>
                </div>
            </>),
            title: item.Title,
            size: "lg"
        }).promise.then(()=>{
            dispatch(classesDuck.setNotificationAsRead(props.item.Id))
        })

    }

    return (
        <div className={[classes['message-item'], classes[item.DisplayType]].join(' ')}>
            <div className="d-flex justify-content-between">
                <small>
                    <LnIcon className="mr-4 icon-medium" name="calendar-icon" />
                    {t.getShortDateString(item.StartDate)}
                    { item.NewForUser &&  <div className="ml-4 rounded-circle d-inline-block bg-warning p-2 px-3">
                         <LnIcon  name="bell-icon" className="icon-small filter-white" />
                        </div> }

                </small>

                <div className={['badge badge-pill', classes[props.nTClass(item.NotificationType)]].join(' ')}>{item.Author.FullName}</div>
            </div>
            <div className="pointer" >
                <div><b onClick={(e) => itemClick(e)}>{item.Header}</b> { props.item.Type === "studentclass" && props.canEdit && <Link className="ml-2 btn btn-link" to={{
                    type: 'CLASS_NOTIFICATIONS_EDIT_NOTIFICATION',
                    payload: { classid: props.classId , id: props.item.Id }
                }}>
                    <LnIcon name="edit" className="small" />
                </Link>} </div>
                <div onClick={(e) => itemClick(e)} className={['mt-1', classes['message-text']].join(' ')} dangerouslySetInnerHTML={{ __html: item.Message }}></div>
            </div>

        </div>

    )
}

export default NotificationItem;