import React, { useState } from 'react';

import {
    Card, CardBody, CardHeader, Collapse
} from 'reactstrap';

import LnIcon from "components/LnIcon";

import classes from './lncard.module.scss';

/**
 * LnCollapseGroup
 * @module components/LnCard/LnCollapseGroup
 * @return a card with a CollapseCard.
 *
 * @param {object} props keys: group, children & isOpen
 */
const LnCollapseGroup = (props) => {

    const g = props.group

    const [isOpen, setIsOpen] = useState(props.isOpen || false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Card>
            <CardHeader className={classes[ 'ln-group-card-header' ] + " bg-light"} onClick={toggle}>
                <h3>{g.Name}</h3>
                {isOpen ? <LnIcon className={[ classes[ 'toggle-icon' ], 'natural' ].join(' ')} name="minus" /> : <LnIcon className={[ classes[ 'toggle-icon' ], 'natural' ].join(' ')} name="plus" />}
            </CardHeader>
            <CardBody>
                <Collapse isOpen={isOpen}>
                    {props.children}
                </Collapse>
            </CardBody>
        </Card>


    )

}

export { LnCollapseGroup };
