//@ts-check
import React, { useCallback } from "react";
import { useI18n } from "components/lni18n";
import {Modal, ModalHeader, ModalBody } from 'reactstrap';
import Draggable from "react-draggable"
import useTypedSelector from "utils/useTypedSelector";
import classes from "./ImagePicker.module.scss";
import Preview from "./Preview";



const ImageAlignDialog = ({ imagePickerService }) => {

  const { languageService: t } = useI18n();
  const { alignData, imageAlign } = useTypedSelector(state => state.imagepicker);

  const onSubmitImg = useCallback(() => {
    imagePickerService.onSubmit({ ...alignData, imageAlign: imageAlign });
  }, [imageAlign, alignData, imagePickerService]);

  if( !alignData){
    return null;
  }

  return <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>

    <Modal size="lg" role="dialog" isOpen={alignData && alignData.modalOpen} toggle={() => imagePickerService.submitBrooker()} className={classes.imgDialog + " draggable-dialog"} backdrop={'static'}>
      <ModalHeader className={""} toggle={() => imagePickerService.submitBrooker()}>{t.getText("image")}</ModalHeader>
      <ModalBody className={""} tabIndex={-1}>
        <Preview onSubmit={onSubmitImg} src={alignData.url} />
      </ModalBody>
    </Modal>
  </Draggable>

}

export default ImageAlignDialog;
