//@ts-check
import { getLanguages, loadStrings } from "features/language/languageService";
import { LoginDuck } from "features/login/LoginDuck";
import { configDuck } from "utils/Config/ConfigDuck";
import dayjs from "dayjs";


const bootstrap = async (dispatch, lang, user, routeToLoginIfNoUser) => {


    const configPromise = configDuck.getConfigs(dispatch);

    try {
        const localtime = dayjs().format("YYYY-MM-DDTHH:mm:ssZ");
        const loginData = { Identifier: null, Password: null, Federated: false, FinalDomain: "", LocalTime: localtime };
        user = await dispatch(LoginDuck.loginAtServer(loginData));


        if ( routeToLoginIfNoUser && !user) {
            dispatch({ type: "LOGIN" });
        }

    } catch (error) {
        dispatch({ type: "LOGIN" });
        return;
    }


    if (lang != null)  // loaded from local store
    {
        getLanguages(dispatch);
        loadStrings(lang.Id, dispatch);
        LoginDuck.loadLoginData(lang.Id, dispatch);
        return;
    }

    // no state: first get languages and see whitch language the server set as current
    let langId = await getLanguages(dispatch);
    await Promise.all([loadStrings(langId, dispatch), LoginDuck.loadLoginData(langId, dispatch), configPromise]);
}



export default bootstrap;