//@ts-check
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useStore } from "react-redux";
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import classes from "./lecture.module.scss";
import { lectureDuck } from "features/course/part/Lectures/LectureDuck";
import thumbnailService from "./ThumbnailService";
import ImageSoundPlayer from "./ImageSoundPlayer";
import Draggable from "react-draggable"
import VideoPlayer from "./VideoPlayer";
import WaitRipple from "components/WaitRipple";
import lecturePlayerService from "./LecturePlayerService";

const dummyAudioUrl =  "data:audio/mp3;base64,/+MYxAAAAANIAAAAAExBTUUzLjk4LjIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA";
const dummyVideoUrl = "data:video/mp4;base64,AAAAIGZ0eXBpc29tAAACAGlzb21pc28yYXZjMW1wNDEAAAAIZnJlZQAAAAhtZGF0AAAA1m1vb3YAAABsbXZoZAAAAAAAAAAAAAAAAAAAA+gAAAAAAAEAAAEAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIAAABidWR0YQAAAFptZXRhAAAAAAAAACFoZGxyAAAAAAAAAABtZGlyYXBwbAAAAAAAAAAAAAAAAC1pbHN0AAAAJal0b28AAAAdZGF0YQAAAAEAAAAATGF2ZjU3LjQxLjEwMA==";

const Screen = (props) => {

    const { currentLecture, currentScreen, changeDirection, autoNext,
        autoNextTimerId, currentScreenData } = useTypedSelector(state => state.lecture);

    const dispatch = useDispatch();
    let [xpos, setxpos] = useState(0);
    let [changeScreen, setChangeScreen] = useState(false);

    /**
     * 
     * @param {import('./Lectures').CurrentScreen} data 
     */
    const setScreenData = useCallback((data) => {
        dispatch(lectureDuck.setCurrentScreenData(data));
    }, [dispatch]);

    useEffect(() => {
        setxpos(0);
        // TODO: put something here (I guess) to hide iOS safarit toolbar
        // window.scrollTo(0, 1);  // Does not work
    }, [currentScreenData]);


    /**
     * @type {{current: HTMLDivElement }}
     */
    const mediaWrapper = useRef(null);

    const store = useStore();

    let startDragX = -1;



    const setNewScreenData = useCallback((screen) => {
        if (screen === null) {
            setScreenData({
                Id: null,
                imgUrl: "",
                videoUrl: dummyVideoUrl,
                audioUrl: dummyAudioUrl,
                DurationInSeconds: 0,
                html: null,
                transcript: null,
                screenType: null
            });

            return;
        }
        const img = screen.Images.find(i => i.Hires);
        let mediaUrl = null;
        if (screen.MediaUrls && screen.MediaUrls.length > 1) {
            mediaUrl = screen.MediaUrls[1].Url;
        }

        setScreenData({
            Id: screen.Id,
            imgUrl: img.Url,
            videoUrl: screen.ScreenType === "Video" ? mediaUrl : dummyVideoUrl,
            audioUrl: screen.ScreenType !== "Video" ? mediaUrl || dummyAudioUrl : dummyAudioUrl,
            DurationInSeconds: screen.DurationInSeconds,
            html: screen.Html,
            transcript: screen.Transcript,
            screenType: screen.ScreenType
        });
    }, [setScreenData]);

    const slideOutMediaWrapper = useCallback((css) => {
        return new Promise((resolve, reject) => {
            if (mediaWrapper.current == null) {
                resolve();
                return;
            }
            const wrapperSlideEnd = () => {
                mediaWrapper.current.removeEventListener("animationend", wrapperSlideEnd, false);
                setNewScreenData(null);
                window.setTimeout(() => resolve(), 1);
            }
            mediaWrapper.current.addEventListener("animationend", wrapperSlideEnd, false);
            mediaWrapper.current.classList.add(css);
        });
    }, [setNewScreenData]);



    const shiftScreen = useCallback((cssOut, cssIn, screen) => {

        setChangeScreen(true);

        if (mediaWrapper.current == null) {
            return;
        }

        const slidePromise = cssOut != null ? slideOutMediaWrapper(cssOut) : new Promise((res, rej) => {
            res();
        });

        if (screen.ScreenType === "Video") {
            thumbnailService.Init(screen.MediaUrls[0].ThumbnailsUrl);
        }

        Promise.all([slidePromise])
            .then(() => {

                setNewScreenData(screen);

                if (mediaWrapper.current && mediaWrapper.current.classList) {
                    mediaWrapper.current.classList.remove(cssOut);
                }

                if (cssIn != null) {
                    dispatch(lectureDuck.setWantedPlayingState(true));
                    slideInMediaWrapper(cssIn)
                }
                else {
                    dispatch(lectureDuck.setWantedPlayingState(true));
                }


            })["finally"](() => {
                if (mediaWrapper.current && mediaWrapper.current.classList) {
                    mediaWrapper.current.classList.remove(cssOut);
                }

            });
    }, [mediaWrapper, dispatch, setNewScreenData, slideOutMediaWrapper]);




    const slideInMediaWrapper = (css) => {
        return new Promise((resolve, reject) => {
            const wrapperSlideInEnd = () => {
                if (mediaWrapper.current) {
                    mediaWrapper.current.removeEventListener("animationend", wrapperSlideInEnd, false);
                    mediaWrapper.current.classList.remove(css);
                }
                resolve();
            }
            if (mediaWrapper.current) {
                mediaWrapper.current.addEventListener("animationend", wrapperSlideInEnd, false);
                mediaWrapper.current.classList.add(css);
            }
        });
    }



    /* screen has changed */
    useEffect(() => {
        if (!!!currentScreen) {
            return;
        }

        // if there is just a minor change in the screen (eg. updated cuepoints), we don't need to do anything
        if( !!currentScreenData &&  currentScreen.Id === currentScreenData.Id){
            return;
        }

        const { outCss, inCss } = changeDirection === null ? { outCss: null, inCss: null } :
            changeDirection === "fromLeft" ? { outCss: "slideOutToLeft", inCss: "slideInFromRight" } : { outCss: "slideOutToRight", inCss: "slideInFromLeft" }

        thumbnailService.Init(null);
        shiftScreen(outCss, inCss, currentScreen);


        if (autoNextTimerId > 0) {
            window.clearTimeout(autoNextTimerId);
            dispatch(lectureDuck.setAutoNextTimerId(0));
        }

        let autoGoNext = autoNext;
        if (autoNext && currentLecture.Screens.length <= currentScreen.Order + 1) {
            dispatch(lectureDuck.toggleAutoNext());
            autoGoNext = false;
        }

        if ( !currentScreen.Watched && (!currentScreen.MediaUrls || currentScreen.MediaUrls.length === 0)) {
            lectureDuck.saveSilentScreen(dispatch, currentScreen.Id);

            if (autoGoNext) {

                const timerId = window.setTimeout(() => {
                    if (autoNext) {
                        dispatch(lectureDuck.setAutoNextTimerId(0));
                        lectureDuck.tryShiftScreen(dispatch, store.getState, lectureDuck.screenJumps.next, null)
                        //.catch(() => dispatch(lectureDuck.toggleAutoNext()));
                    }
                }, 5000);
                dispatch(lectureDuck.setAutoNextTimerId(timerId));

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentScreen]);

    const dragstart = (e, data) => {
        startDragX = data.lastX;
    }

    const dragend = (e, data) => {
        const delta = data.lastX - startDragX;
        setxpos(data.lastX);

        if (handleDrag(e, delta) === false) {
            setxpos(0);
        };
    }

    const handleDrag = (e, delta) => {
        if (Math.abs(delta) > 25) {
            e.preventDefault();
            e.stopPropagation();
            dispatch(lectureDuck.setWantedPlayingState(false));
            if (delta < 0) {
                return lectureDuck.tryShiftScreen(dispatch, store.getState, lectureDuck.screenJumps.next, null);
            }
            else {
                return lectureDuck.tryShiftScreen(dispatch, store.getState, lectureDuck.screenJumps.prev, null)
            }
        }
        else {
            lecturePlayerService.HandleMouseDown();
            return false;
        }
    }

    return (<>
        { changeScreen && <WaitRipple />}
        <div ref={mediaWrapper} className={[classes.screen, props.className].join(" ")} >



            <Draggable cancel="button, .screenTranscript" onStart={dragstart} onStop={dragend} axis="x" position={{ x: xpos, y: 0 }} bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>
                <div className="w-100 h-100">
                    <ImageSoundPlayer
                        imageUrl={currentScreenData.imgUrl}
                        className={currentScreenData.screenType !== "ImageSound" ? "off-screen" : ""}
                        html={currentScreenData.html}
                        transcript={currentScreenData.transcript}
                        onReady={() => setChangeScreen(false)} />


                    <VideoPlayer onReady={() => setChangeScreen(false)} className="" />
                </div>
            </Draggable>

        </div>
    </>
    )
}


export default Screen;