import { POINTRADIUS, LINEWIDTH, SPLINETENSION } from "./DrawingTestService";

/**
 * 
 * @param {any} context
 * @param {number} width 
 * @param {number} height 
 * @param { import('./DrawingTest').DrawingState } model 
 * @param {boolean} disabledCurve 
 * @param {boolean | undefined} noErase 
 */
export const CanvasDraw = (context, width, height, model, disabledCurve, noErase) => {

    if (!noErase) {
        context.clearRect(0, 0, width, height);
    }


    if (!model || !model.Points)
        return;

    drawSpline(context, model.Points, model.Closed, model.Color);

    // Don't draw the points if the canvas is disabled.
    if (!disabledCurve && model.DrawHandles) {
        for (var j = 0; j < model.Points.length; j++) {
            var point = model.Points[j];

            var focus = model.Points === model.Selected;

            drawPoint(context, point.X, point.Y, POINTRADIUS, model.Color, focus);
        }
    }

}

function drawPoint(ctx, x, y, r, fill, focus) {
    ctx.save();

    ctx.lineWidth = 1;
    ctx.strokeStyle = convertHexToRgba("#000000", 1.0); // black

    if (focus) {
        ctx.fillStyle = convertHexToRgba(fill, 0.7);
    } else {
        ctx.fillStyle = convertHexToRgba(fill, 0.3);
    }

    ctx.beginPath();
    ctx.arc(x, y, r, 0.0, 2 * Math.PI, false);
    ctx.closePath();

    ctx.fill();
    ctx.stroke();

    ctx.restore();
}

function drawSpline(ctx, points, closed, color) {
    var arr = [];
    for (var i = 0; i < points.length; i++) {
        arr.push(points[i].X);
        arr.push(points[i].Y);
    }

    _drawSpline(ctx, arr, closed, color);
}

// Copied from the old implementation (plus some superficial changes to get it working here).
function _drawSpline(ctx, pts, closed, color) {

    ctx.lineWidth = LINEWIDTH;
    ctx.save();

    var cp = [];   // array of control points, as x0,y0,x1,y1,...
    var n = pts.length;

    ctx.strokeStyle = convertHexToRgba(color, 0.9);


    if (closed) {
        //   Append and prepend knots and control points to close the model
        pts.push(pts[0], pts[1], pts[2], pts[3]);
        pts.unshift(pts[n - 1]);
        pts.unshift(pts[n - 1]);
        for (let i = 0; i < n; i += 2) {
            cp = cp.concat(_getControlPoints(pts[i], pts[i + 1], pts[i + 2], pts[i + 3], pts[i + 4], pts[i + 5], SPLINETENSION));
        }
        cp = cp.concat(cp[0], cp[1]);
        for (let i = 2; i < n + 2; i += 2) {
            ctx.beginPath();
            ctx.moveTo(pts[i], pts[i + 1]);
            ctx.bezierCurveTo(cp[2 * i - 2], cp[2 * i - 1], cp[2 * i], cp[2 * i + 1], pts[i + 2], pts[i + 3]);
            ctx.stroke();
            ctx.closePath();

        }
    } else {
        // Draw an open model, not connected at the ends
        for (var i = 0; i < n - 4; i += 2) {
            cp = cp.concat(_getControlPoints(pts[i], pts[i + 1], pts[i + 2], pts[i + 3], pts[i + 4], pts[i + 5], SPLINETENSION));
        }
        if (pts.length === 4) {
            ctx.beginPath();
            ctx.moveTo(pts[0], pts[1]);
            ctx.lineTo(pts[2], pts[3]);
            ctx.stroke();
            ctx.closePath();
        }
        else {
            for (let i = 2; i < pts.length - 5; i += 2) {
                ctx.beginPath();
                ctx.moveTo(pts[i], pts[i + 1]);
                ctx.bezierCurveTo(cp[2 * i - 2], cp[2 * i - 1], cp[2 * i], cp[2 * i + 1], pts[i + 2], pts[i + 3]);
                ctx.stroke();
                ctx.closePath();

            }
            //  For open models the first and last arcs are simple quadratics.
            ctx.beginPath();
            ctx.moveTo(pts[0], pts[1]);
            ctx.quadraticCurveTo(cp[0], cp[1], pts[2], pts[3]);
            ctx.stroke();
            ctx.closePath();

            ctx.beginPath();
            ctx.moveTo(pts[n - 2], pts[n - 1]);
            ctx.quadraticCurveTo(cp[2 * n - 10], cp[2 * n - 9], pts[n - 4], pts[n - 3]);
            ctx.stroke();
            ctx.closePath();

        }
    }
    ctx.restore();
}

// Copied from the old implementation.
function _getControlPoints(x0, y0, x1, y1, x2, y2, t) {
    //  x0,y0,x1,y1 are the coordinates of the end (knot) pts of this segment
    //  x2,y2 is the next knot -- not connected here but needed to calculate p2
    //  p1 is the control point calculated here, from x1 back toward x0.
    //  p2 is the next control point, calculated here and returned to become the 
    //  next segment's p1.
    //  t is the 'tension' which controls how far the control points spread.

    //  Scaling factors: distances from this knot to the previous and following knots.
    var d01 = Math.sqrt(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2));
    var d12 = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

    var fa = t * d01 / (d01 + d12);
    var fb = t - fa;

    var p1x = x1 + fa * (x0 - x2);
    var p1y = y1 + fa * (y0 - y2);

    var p2x = x1 - fb * (x0 - x2);
    var p2y = y1 - fb * (y0 - y2);

    return [p1x, p1y, p2x, p2y]
}

function convertHexToRgba(hex, opacity) {
    opacity = opacity || 1.0;

    var h = hex.replace("#", "");

    var r, g, b;
    if (h.length === 6) {
        r = parseInt(h.substring(0, 2), 16);
        g = parseInt(h.substring(2, 4), 16);
        b = parseInt(h.substring(4, 6), 16);
    } else {
        // console.log("ERROR: Invalid hex string: " + hex);
    }

    return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
}