/* eslint-disable */
//@ts-check
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";
import { NavLink, TabContent, TabPane, Progress } from "reactstrap";
import { useI18n } from "components/lni18n";
import { docBoxDuck } from "./DocBoxDuck";
import { endPoint } from "AppConstants";
import LnIcon from "components/LnIcon";
import FileIcon from "components/FileIcons/FileIcon";
import classes from './DocBox.module.scss';



const DocBoxFilePicker = ({ choseHandler }) => {

    const dispatch = useDispatch();
    const { languageService: t } = useI18n();
    const docbox = useTypedSelector(state => state.docbox);

    const [chosenFile, setChosenFile] = useState(null);

    useEffect(() => {
        docBoxDuck.getStartDataForDialog(dispatch);
    }, [])

    const setFolder = (folderId) => {
        setChosenFile(null);
        dispatch(docBoxDuck.setCurrentFolderFileDialog({ id: folderId }));
    }

    const toggleTab = (type) => {

        setChosenFile(null);

        switch (type) {
            case "Start":
                docBoxDuck.getStartDataForDialog(dispatch);
                break;
            case "Shared":
                docBoxDuck.getSharedDataForDialog(dispatch);
                break;
            default:
                break;
        }
    }

    let root = null;
    if (docbox.filePickerData && docbox.filePickerData.currentRoot) {
        root = docbox.filePickerData[docbox.filePickerData.currentRoot];
    }

    const { folder: currentFolder, path: breadCrumbs } = docBoxDuck.useCurrentFolder(root);


    if (root == null) {
        return <WaitRipple />;
    }

    const activeTab = docbox.filePickerData.currentRoot;

    return (<>
        <div className="mb-4">
            <nav className={"custom-tab-heading-list w-100 position-relative d-flex"}  >
                <NavLink
                    key="Start"
                    className={["pointer", activeTab === 'Start' ? 'active ' : ''].join(' ')}
                    onClick={() => { toggleTab('Start'); }}>
                    {t.getText("docbox.my")}
                </NavLink>
                <NavLink
                    key="Shared"
                    className={["pointer", activeTab === 'Shared' ? 'active ' : ''].join(' ')}
                    onClick={() => { toggleTab('Shared'); }}>
                    {t.getText("docbox.shared")}
                </NavLink>
            </nav>
        </div>

        <div className="alert alert-success clearfix">
            {chosenFile ? <div className="mb-4">
                <span>{chosenFile.Name}</span>
                <button onClick={() => choseHandler(chosenFile)} className="btn btn-small btn-primary float-right">{t.getText("select.this.file")}</button>
            </div>
                : <div className="mb-4">{t.getText("choose.file")}</div>}
        </div>

        <div className={["card-header ln-nostyle d-flex justify-content-between", classes.noSidePadding].join(' ')}>
            <h3>
                {breadCrumbs.map(f => {
                    {/*  bread crumbs */ }
                    if (f.Id === root.CurrentFolder) {
                        return <small key={f.Id}>{f.Name}</small>
                    }
                    else {
                        return <small key={f.Id}><button className="btn btn-link" onClick={() => setFolder(f.Id)} >{f.Name}</button> <LnIcon className="icon-small" name="right-arrow" /> </small>
                    }
                })}
            </h3>

        </div>
        <div className={["card-body pt-3", classes.noSidePadding].join(' ')}>
            <table className="table ln-table-responsive table-hover">
                <thead>
                    <tr role="row">
                        <th role="columnheader" className="th-clean">
                            {t.getText('name')}
                        </th>
                        <th role="columnheader" className="th-clean">
                            {t.getText('description')}
                        </th>
                        <th role="columnheader" className="d-none d-md-table-cell th-clean">
                            {t.getText('date.created')}
                        </th>
                        <th role="columnheader" className="th-clean">
                            {t.getText('size')}
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {(currentFolder.Children && (currentFolder.Children.length > 0)) ?
                        currentFolder.Children.map(f => { // Folders
                            return (
                                <tr role="row" key={f.Id}>
                                    <td onClick={() => setFolder(f.Id)}>
                                        <label className="td-label"><LnIcon className={[classes.fileIcon].join(' ')} name="dox-icon" /></label>
                                        <LnIcon className={["mr-4", classes.smallHide, classes.fileIcon].join(' ')} name="dox-icon" />
                                        <button type="button" className="btn btn-link" >{f.Name}</button>

                                    </td>
                                    <td className="d-flex justify-content-end">
                                        {f.Shared && <LnIcon className={["mr-3 d-none d-md-inline-block pointer"].join(' ')} name="group" />}
                                    </td>
                                    <td className={["d-none d-md-table-cell", classes.smallHide].join(' ')}>
                                        &nbsp;

                                    </td>
                                    <td>
                                        <label className="td-label">{t.getText('size')}</label>
                                        {f.Size}
                                    </td>

                                </tr>
                            )
                        }) : null}
                    {(currentFolder.Files && (currentFolder.Files.length > 0)) ?
                        currentFolder.Files.map(f => { // Files
                            return (
                                <tr key={f.Id} onClick={() => setChosenFile(f)} className="pointer">
                                    <td>
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex">
                                                <div className="custom-control custom-radio" >
                                                    <input
                                                        checked={chosenFile ? chosenFile.Id === f.Id : false}
                                                        type="radio"
                                                        name="theFile"
                                                        className="custom-control-input"
                                                        id={"id" + f.FileId}
                                                        onChange={(e) => { }}

                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={"id" + f.FileId}
                                                    ></label>
                                                </div>
                                                <div>
                                                    <FileIcon className={[classes.fileIcon].join(' ')} size="48px" name={(f.Name || '').split('.').pop()} />
                                            &nbsp;
                                            {f.Name}
                                                </div>
                                            </div>
                                            <a title={t.getText("download")} onClick={(e) => e.stopPropagation()} href={endPoint.API_HOST + f.Url} target="_blank" rel="noopener noreferrer">
                                                <LnIcon name="download" />
                                            </a>
                                        </div>


                                    </td>
                                    <td>
                                        <label className="td-label">{t.getText('description')}</label>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                {f.Description}
                                            </div>
                                            {f.Shared && <LnIcon className={["mr-3 d-none d-md-inline-block pointer"].join(' ')} name="group" />}
                                        </div>

                                    </td>
                                    <td className="d-none d-md-table-cell">
                                        <label className="td-label">{t.getText('date.created')}</label>
                                        {t.getShortDateString(f.Created)}
                                    </td>
                                    <td>
                                        <label className="td-label">{t.getText('size')}</label>
                                        {f.Size}
                                    </td>

                                </tr>
                            )
                        }) : null
                    }
                </tbody>
            </table>
        </div>


    </>
    )


}



export default DocBoxFilePicker;