import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { LoginDuck } from 'features/login/LoginDuck';
import './assets/scss/main.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';
import languageService from "./features/language/languageService";
import dispatchService from "./utils/DispatchService";
import { setAxiosDispatcher } from "./utils/axios";
import configureAppStore from "./configStore";
import 'dayjs/locale/sv';
import 'dayjs/locale/da';
import navigationBlocker from "./utils/NavigationBlocker";

import * as Sentry from "@sentry/react";
import configDB from 'configDB';


if (process.env.NODE_ENV === "production") {
  Sentry.init({
    
    dsn: "https://5b9e34bde391df8c86b07d0c9d9e3ca6@o4507208376320000.ingest.de.sentry.io/4507208378548304",
    integrations: [
      Sentry.browserTracingIntegration()
    ],
    tracesSampleRate: 0.5,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded", /^No error$/,
      /__show__deepen/,
      /_avast_submit/,
      /Access is denied/,
      /anonymous function: captureException/,
      /Blocked a frame with origin/,
      /can't redefine non-configurable property "userAgent"/,
      /change_ua/,
      /console is not defined/,
      /cordova/,
      /DataCloneError/,
      /Error: AccessDeny/,
      /event is not defined/,
      /feedConf/,
      /ibFindAllVideos/,
      /myGloFrameList/,
      /SecurityError/,
      /MyIPhoneApp/,
      /snapchat.com/,
      /vid_mate_check is not defined/,
      /win\.document\.body/,
      /window\._sharedData\.entry_data/,
      /window\.regainData/,
      /ztePageScrollModule/
    ],

  });
}


const store = configureAppStore();

export const lnDataBase = configDB();



navigationBlocker.init(store);

let redirPath = window.location.pathname;
if (store.getState().login.loggedIn || redirPath === "/") {

  redirPath = null;
}
store.dispatch(LoginDuck.setRedirectUrl(redirPath));

// the lookup of text in the i18n dictionary
languageService.registerStore(store);

// Make axios able to dispatch actions into Redux
setAxiosDispatcher(store.dispatch);

// set up dispatch service with it's store.
dispatchService.SetStore(store);

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
