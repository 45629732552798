//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import { Collapse, CardBody, Card, CardHeader } from "reactstrap";
import LnResultIcon from "components/LnResultIcon";
import classes from "./sidebarmenu.module.scss";
import { BOOK, BOOK_GREEN, BOOK_YELLOW, RIGHTARROW } from "assets/images";
import { courseDuck } from "features/course/CourseDuck";
import { layoutDuck } from "features/layout/LayoutDuck";
import useTypedSelector from "utils/useTypedSelector";
import MyGroup from "./MyGroup";
import { TestResultStatus } from "features/course/part/test/TestEnums";


const SideBarMenu = () => {
    const currentTheme = courseDuck.useCurrentTheme();
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const currentPartId = useTypedSelector(state => state.course.currentPartId);
    const sideBarForced = useTypedSelector(state => state.layout.sideBarForced);

    const dispatch = useDispatch();

    const goToTheme = themeId => {
        if (themeId != null) {

            dispatch(
                {
                    type: "CLASS_THEME",
                    payload: {
                        classid: currentClass.Id,
                        themeid: themeId
                    }
                });
        } else {
            dispatch(
                {
                    type: "CLASS",
                    payload: { classid: currentClass.Id }
                }
            );
        }
    };

    const goToPart = partId => {
        if (currentTheme != null) {

            if (sideBarForced) {
                dispatch(layoutDuck.toggleSideBarState({}));
            }

            dispatch(
                {
                    type: "CLASS_THEME_PART",
                    payload: {
                        classid: currentClass.Id,
                        themeid: currentTheme.Id,
                        partid: partId,
                        type: "intro"
                    }
                });
        }
    };

    const themeClick = (id) => {

        if (currentTheme == null || currentTheme.Id !== id) {
            goToTheme(id);
        }
        else {
            if (currentPartId) {
                goToTheme(currentTheme.Id);
            }
            else {
                goToTheme(null);
            }
        }
    }

    if (currentClass == null || currentClass.Themes == null) {
        return <div></div>
    }

    const themeImage = (theme)=>{
        switch (theme.Status) {
            case TestResultStatus.ApprovedByTeacher:
                return BOOK_GREEN;
                
            case TestResultStatus.Passed:
                return BOOK_YELLOW;

            default:
                return BOOK;
        }
    }

    let output = currentClass.Themes.map(t => {

        const selected = currentTheme != null && currentTheme.Id === t.Id;

        return (

            <Card key={t.Id} className={[classes.theme, selected ? classes.selected + " border border-primary" : null].join(' ')}>
                <CardHeader className={classes.header + " px-1 py-0"} >
                    <button className="btn btn-link w-100 d-flex my-2 py-1 pl-3 pr-0" onClick={() => themeClick(t.Id)} >
                        <img src={themeImage(t)} alt="" className="mr-2 mt-2" />
                        <div className={classes.themename + " flex-grow-1 word-break-word"} >{t.Name}</div>
                        <img src={RIGHTARROW} alt="" className={["mx-1 mt-2", classes.indicator, selected ? classes.down : null].join(' ')} />
                    </button>
                </CardHeader>
                <Collapse isOpen={selected}>
                    <CardBody className={classes.parts}>
                        {
                            t.CourseParts.map((p) => (
                                <button className={[(currentPartId === p.Id ? "selected" : ""), classes.part, "btn btn-link w-100"].join(' ')} key={p.Id} onClick={() => goToPart(p.Id)}>
                                    {(p.Status !== TestResultStatus.NotSet) && <LnResultIcon status={p.Status} className={classes["status-icon"]} />}
                                    <div className={"word-break-word " + classes.partname}>{p.Name}</div>

                                </button>))
                        }
                    </CardBody>
                </Collapse>
            </Card >
        )
    });


    return <nav aria-live="polite" >
        <MyGroup resetTheme={() => goToTheme(null)} />
        {output}
    </nav>;





}

export default SideBarMenu;