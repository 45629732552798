//@ts-check
import React, { useState } from "react";
import { useI18n } from "components/lni18n";
import useTypedSelector from "utils/useTypedSelector";

import WaitRipple from "components/WaitRipple";
import { useDispatch } from "react-redux";
import { userDuck } from "features/User/UserDuck";

const
    PersonalDataPolicyContent = () => {

        const { languageService: t } = useI18n();
        const { policyData } = useTypedSelector(state => state.user);
        const { user } = useTypedSelector(state => state.login);
        const [tab, setTab] = useState('policy');
        const dispatch = useDispatch();

        if (!policyData) {
            return <WaitRipple />
        }

        function approveCookies() {
            dispatch(userDuck.approveCookies(user.Id));
        }

        function approvePolicy() {
            dispatch(userDuck.approvePolicy(user.Id));
        }

        return (
            <div>
                <nav className="custom-tab-heading-list w-100 position-relative d-flex overflow-hidden"  >
                    <button onClick={() => setTab('policy')} className={tab === 'policy' ? "active" : ""}>
                        {t.getText("personal.data.policy")}
                    </button>

                    <button onClick={() => setTab('cookies')} className={tab === 'cookies' ? "active" : ""}>
                        {t.getText("cookies")}
                    </button>

                </nav>
                <div className="mt-4 max-col-width">
                    {tab === 'cookies' && <div>
                        {policyData.HasApprovedCookies ?
                            <div className="alert alert-success">
                                {t.getText("you.have.accepted")}
                            </div>
                            :
                            <div className="d-flex">
                                <button onClick={approveCookies} className="btn btn-primary mb-3">{t.getText("i.agree")}</button>
                                <div className="preserve-white ml-4">{t.getText("please.approve.policy")}</div>
                            </div>

                        }
                        <div dangerouslySetInnerHTML={{ __html: policyData.CookieText }} ></div>
                    </div>}

                    {tab === 'policy' && <div>
                        {policyData.HasApprovedPolicy ?
                            <div className="alert alert-success">
                                {t.getText("you.have.accepted")}
                            </div>
                            :
                            
                            <div className="d-flex">
                                <button onClick={approvePolicy} className="btn btn-primary mb-3">{t.getText("i.agree")}</button>
                                <div className="preserve-white ml-4">{t.getText("please.approve.policy")}</div>
                            </div>

                        }
                        <div dangerouslySetInnerHTML={{ __html: policyData.PolicyText }} ></div>
                    </div>}
                </div>



            </div>
        )

    }


export default PersonalDataPolicyContent;
