//@ts-check
import React from "react";
import ScrollContent from "components/ScrollContent";
import FeedView from "./FeedView";


const ClassFeed = () => {

    return <ScrollContent scrollname="class-feed">
        <div className="card-box big-card-box">
            <FeedView />
        </div>
    </ScrollContent>

}


export default ClassFeed;
