//@ts-check
import React, { useCallback, useEffect, useState } from "react";
import { useI18n } from "components/lni18n";
import { Form, FormGroup, CustomInput, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import ReceiversText from "./ReceiversText";
import Draggable from "react-draggable";
import classes from './classmail.module.scss';

/**
 * ReceiverModal
 * @module features/classes/ClassItems/ClassMail/ReceiverModal
 * @return a modal with list to select Mail receivers.
 * @param {{
    ReturnListHandler: Function,
    userLists: {Students: import("./ClassMail").MailReceiver[], Teachers: import("./ClassMail").MailReceiver[]},
    isTeacher: boolean
}} props
 */
const ReceiverModal = ({ userLists, ReturnListHandler, isTeacher }) => {

    const { languageService: t } = useI18n();

    const handleCheckboxChange = (event, receiver) => {
        receiver.Checked = event.target.checked;
        const newList = {
            Students: [...userList.Students],
            Teachers: [...userList.Teachers]
        }
        setUserList(newList);
        prepSelected(newList);

    }

    const handleCheckboxSave = () => {
        setRestoreList(false);
        ReturnListHandler(userList);
        prepSelected(userList);
        setModal(false);
    }

    const handleClosed = (restore) => {
        if (restore) {
            setUserList(userLists);
            prepSelected(userLists);
            setRestoreList(true);
        }
    }
    const prepSelected = (lists) => {
        const selectedTeachers = lists.Teachers.filter(r => r.Checked);
        const selectedStudents = lists.Students.filter(r => r.Checked);
        setSelectedReceivers({ Students: selectedStudents, Teachers: selectedTeachers })
    }

    const selectedCount = (list) => {
        return list.filter(l => l.Checked && (l.Archived === false || showArchived === true)).length;
    }

    const selectReceivers = () => {
        setModal(true);
    }

    /**
     * 
     * @param {any} event 
     * @param {import("./ClassMail").MailReceiver[]} list 
     */
    const toggleCheckboxes = (event, list) => {
        const numChecked = list.filter(l => l.Checked).length;
        if (numChecked > 0 && numChecked === selectedCount(list)) {
            list.forEach(r => {
                r.Checked = false;
            });
            //event.target.value = true;
            event.target.checked = false;
        } else {
            list.forEach(r => {
                if (r.Archived === false || showArchived === true) {
                    r.Checked = true;
                }
            });
            //event.target.value = false;
            event.target.checked = true;
        }
        const newList = {
            Students: [...userList.Students],
            Teachers: [...userList.Teachers]
        }
        setUserList(newList);
        prepSelected(newList);
    }

    const toggleModal = () => setModal(!modal);
    const [showArchived, setShowArchived] = useState(false);
    const [userList, setUserList] = useState(userLists);
    const [modal, setModal] = useState(false);
    const [restoreList, setRestoreList] = useState(true);
    const [selectedReceivers, setSelectedReceivers] = useState({ Students: userList.Students.filter(r => r.Checked), Teachers: userList.Teachers.filter(r => r.Checked) });

    const toggleShowArchived = useCallback((e) => {
        setShowArchived(e.target.checked);
        if (!e.target.checked) {
            userList.Students.forEach(u => {
                if (u.Archived) {
                    u.Checked = false;
                }
            });
            const newList = {
                Students: [...userList.Students],
                Teachers: [...userList.Teachers]
            }
            setUserList(newList);
            prepSelected(newList);
        }

    }, [setShowArchived, userList]);


    useEffect(() => {
        if (userLists.Students.filter(u => u.Archived && u.Checked).length > 0) {
            toggleShowArchived({ target: { checked: true } });
        }
    }, [userLists, toggleShowArchived])

    return (<>
        <div className="d-flex align-items-center mb-4">
            <button className="btn btn-inverse btn-small mr-4" type="button" onClick={() => selectReceivers()}>{t.getText('mail.reciever')}
            </button>
            <small className="d-flex flex-column"><ReceiversText userList={selectedReceivers} t={t} />
            </small>
        </div>
        <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>
            <Modal role="dialog" isOpen={modal} toggle={toggleModal} onClosed={() => handleClosed(restoreList)} backdrop='static'>
                <ModalHeader toggle={toggleModal}>{t.getText('select')}</ModalHeader>
                <ModalBody tabIndex={-1}>
                    <div className="d-flex justify-content-between">
                        <Form>
                            <FormGroup>
                                <strong><CustomInput type="checkbox" className="mb-2" id="teachersCheckAll" label={t.getText('teachers')} onChange={(e) => { toggleCheckboxes(e, userList.Teachers) }} 
                                    checked={selectedReceivers.Teachers.length === userLists.Teachers.length} />
                                </strong>
                                <ul className="list-unstyled ml-4">
                                    {userList.Teachers.map(r => {
                                        return (
                                            <li className="mb-2" key={r.Receiver.Id}>
                                                <CustomInput type="checkbox" id={'T_' + r.Receiver.Id} value={r.Receiver.Id} checked={r.Checked} label={r.Receiver.FullName + ' - ' + r.Receiver.Mail} onChange={(e) => { handleCheckboxChange(e, r) }} />

                                            </li>

                                        )
                                    })}
                                </ul>
                            </FormGroup>
                            <FormGroup>
                                <div className="my-3">
                                    <strong>
                                        <CustomInput type="checkbox" className="mb-2" id="studentsCheckAll" 
                                            label={t.getText('students')} onChange={(e) => { toggleCheckboxes(e, userList.Students) }} 
                                            checked={selectedReceivers.Students.length === userLists.Students.filter( u=>!u.Archived|| showArchived ).length} inline />
                                    </strong>
                                    {isTeacher &&
                                        <CustomInput type="checkbox" className="ml-4" id="showArchived" label={t.getText('show.archived.users')} onChange={toggleShowArchived} checked={showArchived} inline />
                                    }
                                </div>
                                <ul className="list-unstyled ml-4">
                                    {userList.Students.filter(r => !r.Archived || r.Archived === showArchived).map(r => {
                                        return (
                                            <li className={"mb-2 " + (r.Archived ? classes.bgArchived : "")} key={r.Receiver.Id}>
                                                <CustomInput type="checkbox" id={'S_' + r.Receiver.Id} value={r.Receiver.Id} checked={r.Checked} label={r.Receiver.FullName + ' - ' + r.Receiver.Mail} onChange={(e) => { handleCheckboxChange(e, r) }} />

                                            </li>

                                        )
                                    })}
                                </ul>
                            </FormGroup>

                        </Form>

                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-inverse btn-small" onClick={() => toggleModal()}>{t.getText('cancel')} </Button>
                    <Button className={['ml-2', 'btn btn-primary btn-small'].join(' ')} onClick={() => handleCheckboxSave()}>{t.getText('ok')}</Button>
                </ModalFooter>
            </Modal>
        </Draggable>

    </>)
}
export default ReceiverModal;
