// @ts-check
import React, { useCallback, useState } from "react";
import {LoginDuck } from "./LoginDuck";
import useTypedSelector from "utils/useTypedSelector";
import { useI18n } from "components/lni18n";
import LanguageMenu from "components/LanguageMenu/LanguageMenu";
import WaitRipple from "components/WaitRipple";
import classes from './login.module.scss';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import { ErrorMessage } from '@hookform/error-message';
import * as Yup from "yup";
import { CustomInput } from "reactstrap";

const SetNewPassword = (props )=> {

    const { languageService: t } = useI18n();
    const { newPasswordData, loginData } = useTypedSelector(state => state.login);
    const [showpw, setShowpw] = useState(false);
    const [result, setResult] = useState(null);

    const schema = Yup.object().shape({
        Mail: Yup.string().email(t.getText("bad_email_adress"))
            .required(t.getText("required_field")),
        Passwd: Yup.string()
            .min(5, t.getText("toshortpasswd"))
            .test('not_simple', t.getText("password.too.simple"), (v) => !v.match(/(.)\1\1\1\1/i))
            .required(t.getText("required_field")),
        Passwd2: Yup.string()
            .oneOf([Yup.ref('Passwd'), null], t.getText("samepasswordinfieds"))
            .required(t.getText("required_field"))
    });


    const { register, handleSubmit, errors, formState } = useForm({
        resolver: yupResolver(schema),
        mode: "onBlur"
    });


    const change = useCallback(async (d) => {
        if (formState.isValid) {
            const data = { ...d, ...newPasswordData };
            const res = await LoginDuck.TryChangePassord(data);
            if (res && res.Result === true) {
                setResult({ type: "primary" })
            }
            else {
                setResult({ type: "danger", error: res.Error })
            }
        }

    }, [formState, newPasswordData]);

    if (!newPasswordData) {
        return <WaitRipple></WaitRipple>
    }

    return <div className="h-100">

        <div className={classes.newPassword + " d-flex align-items-center"} style={{ backgroundImage: `url(${loginData.MainImageUrl}` }}>
            <div className={classes.forms + " bg-white rounded px-5 py-5 ml-5"}>
                <img src="/imgs/logo.svg" alt="logo" className="mb-4" />


                {newPasswordData.Error ? <div className="my-5">
                    <h3>{t.getText("error")}</h3>
                    <div dangerouslySetInnerHTML={{ __html: newPasswordData.Error }}></div>
                </div> :
                    <>
                        <div className="float-right">
                            <LanguageMenu caretClass="dark-caret"></LanguageMenu>
                        </div>


                        <h3 className="mb-5">{t.getText("new_passwd")}</h3>
                        <h4>{newPasswordData.UserName}</h4>
                        <div className="mt-2 mb-5 preserve-white">
                            {t.getText("new.passwd.explain")}
                        </div>
                        {result &&

                            <div className={"my-4 alert alert-" + result.type}>
                                {result.type === "danger" && result.error}
                                {result.type !== "danger" && <> <div>
                                    {t.getText("password.changed")}
                                </div>
                                    <a href="/" className="my-3 btn btn-primary" >{t.getText("login")}</a>
                                </>
                                }
                            </div>
                        }

                        <form onSubmit={handleSubmit((d) => change(d))}>
                            <div className="mb-3">
                                <label>{t.getText("your.email")}</label>
                                <input type="email" className="form-control" name="Mail" ref={register} />
                                <ErrorMessage className="errorMessage" as="div" errors={errors} name="Mail" />
                            </div>

                            <div className="mb-3">
                                <label>{t.getText("passwd")}</label>
                                <input type={showpw ? "text" : "password"} className="form-control" name="Passwd" ref={register} />
                                <ErrorMessage as="div" className="errorMessage" errors={errors} name="Passwd" />
                            </div>

                            <div className="mb-3">
                                <label>{t.getText("passwd2")}</label>
                                <input type={showpw ? "text" : "password"} className="form-control" name="Passwd2" ref={register} />
                                <ErrorMessage as="div" className="errorMessage" errors={errors} name="Passwd2" />
                            </div>

                            <div className="mb-5">
                                <CustomInput checked={showpw} id="togglepw" type="checkbox" label={t.getText("show.password")} onChange={() => setShowpw(old => !old)} />
                            </div>

                            <div className="mb-3">
                                <button disabled={!formState.isValid} type="submit" className="btn btn-primary " >{t.getText("change")}</button>
                            </div>
                        </form>
                    </>
                }

            </div>
        </div>
    </div>



}

export default SetNewPassword;
