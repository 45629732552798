//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import { UserMode } from "features/User/user.enums";
import { TestResultStatus } from "../test/TestEnums";
import { useI18n } from "components/lni18n";
import classes from './tests.module.scss';
import { courseDuck } from "features/course/CourseDuck";
import { Confirm, ModalConstants } from "components/OnlineModal";
import StartTestLink from "./StartTestLink";
import DisabledTest from "./DisabledTest";
import StudyTest from "./StudyTest";
/**
 *
 * @param {{test: import('../../Course').TestInfo, user: import('features/User/User').CurrentUser,
    classId: string, themeId: string, partId: string, isTeacher: boolean}} props
 */
const TestLink = ({ test, classId, themeId, partId, user, isTeacher }) => {

    const dispatch = useDispatch();
    const { languageService: t } = useI18n();

    const deleteResult = async () => {
        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("delete.submitted.result"),
            message: <div className="preserve-white">{t.getText("teacher.delete.testresult")}</div>,
            languageService: t,
            type: "yes/no"            
        });
        
        if (result === ModalConstants.Yes) {            
            dispatch(courseDuck.deleteResultForCurrentUserInCurrentPart(classId, test.ResultId, test.Id))
        };
        
    }


    return (
        <div key={test.Id} className={[classes['test-listing'], 'mb-3 d-flex justify-content-between'].join(' ')}>
            
            { ( !isTeacher && test.ConnectedLecture && !test.ConnectedLecture.Ready) ? <StudyTest classId={classId} themeId={themeId} partId={partId} test={test} /> 
                : (
                    test.CanStartTest ? 
                        <StartTestLink classId={classId} themeId={themeId} partId={partId} test={test} />
                        :
                        <DisabledTest test={test} />
                )
            
        }

            {(test.Status !== TestResultStatus.NotSet && user.UserState !== UserMode.student) &&
                <button onClick={deleteResult} className="btn btn-link">
                    {t.getText("delete.submitted.result")}
                </button>}

        </div>)

}

export default TestLink;

