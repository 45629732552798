//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import Enums from "utils/Enums";
import { lnDataBase } from 'index';

/**
 * @type {import('./Feed').FeedState}
 */
const initialState = {
    currentFeed: null,
    filterType: Enums.FilterType.ByPosts,
    userId: null,
};

const feedSlice = createSlice({
    name: 'feed',
    initialState: initialState,
    reducers: {

        /**
        * @param  {  {payload: import('./Feed').FeedDto}} action
        */
        setStartData(state, action) {
            state.currentFeed = action.payload;
        },


        /**
         * @param  {  {payload: import('./Feed').FeedDto}} action
         */
        setMoreData(state, action) {
            state.currentFeed.Posts = [...state.currentFeed.Posts, ...action.payload.Posts];
            state.currentFeed.LastCheck = action.payload.LastCheck;
            state.currentFeed.HasMorePosts = action.payload.HasMorePosts;
        },

        /**
         * @param  {  {payload: import('./Feed').FeedPostDto}} action
         */
        addNewFeed(state, action) {
            state.currentFeed.Posts = [action.payload, ...state.currentFeed.Posts];
        },

        /**
       * @param  {  {payload: import('./Feed').FeedPostDto}} action
       */
        updateFeed(state, action) {
            const post = state.currentFeed.Posts.find(p => p.Id === action.payload.Id);

            if (post !== null) {
                post.Text = action.payload.Text;
                post.Edited = action.payload.Edited;
            }
        },



        /**
        * @param  {  {payload: import('./Feed').FeedCommentDto}} action
        */
        addNewComment(state, action) {
            const post = state.currentFeed.Posts.find(p => p.Id === action.payload.FeedPostId);

            if (post === null) {
                return;
            }
            post.Comments = [...post.Comments, action.payload];
        },

        /**
       * @param  {  {payload: import('./Feed').FeedCommentDto}} action
       */
        updateComment(state, action) {
            const post = state.currentFeed.Posts.find(p => p.Id === action.payload.FeedPostId);

            if (post === null) {
                return;
            }
            const comment = post.Comments.find(c => c.Id === action.payload.Id);
            if (comment) {
                comment.Text = action.payload.Text;
                comment.Edited = action.payload.Edited;
            }
        },

        /**
         *
         * @param { {payload:  number}} action
         */
        feedPostingDeleted(state, action) {
            if (state.currentFeed != null) {
                state.currentFeed.Posts = state.currentFeed.Posts.filter(a => a.Id !== action.payload);
            }

        },

        /**
        *
        * @param { {payload:  {postId: number, commentId: number}}} action
        */
        feedCommentDeleted(state, action) {
            if (state.currentFeed != null) {
                const post = state.currentFeed.Posts.find(a => a.Id === action.payload.postId);
                if (post) {
                    post.Comments = post.Comments.filter(c => c.Id !== action.payload.commentId);
                }
            }
        },

        /**
        *
        * @param { {payload:  {postId: number, comments: import('./Feed').FeedCommentDto[]}}} action
        */
        setCommentsToPost(state, action) {
            if (state.currentFeed != null) {
                const post = state.currentFeed.Posts.find(a => a.Id === action.payload.postId);
                if (post) {
                    post.Comments = action.payload.comments;
                    post.HasMoreComments = false;
                }
            }
        },

        /**
        *
        * @param { {payload:  { userId: string, filterType: number }}} action
        */
        setFilterData(state, action) {
            if (state.currentFeed != null) {
                if (action.payload) {
                    state.userId = action.payload.userId;
                    state.filterType = action.payload.filterType;
                } else {
                    state.userId = null;
                    state.filterType = Enums.FilterType.ByPosts;
                }
            }
        },

        /**
         *
         * @param { {payload: number }} action
         */
        togglePostInEdit(state, action) {
            if (state.currentFeed != null) {
                const post = state.currentFeed.Posts.find(p => p.Id === action.payload);
                if (post) {
                    post.InEdit = !!!post.InEdit;
                }
            }
        },

        /**
         *
         * @param { {payload: {commentId: number, postId: number} }} action
         */
        toggleCommentInEdit(state, action) {
            if (state.currentFeed != null) {
                const post = state.currentFeed.Posts.find(p => p.Id === action.payload.postId);
                if (post) {
                    const comment = post.Comments.find(c => c.Id === action.payload.commentId);
                    if (comment) {
                        comment.InEdit = !!!comment.InEdit;
                    }
                }
            }
        },


    }
});

/**
 *
 * @param {import('./Feed').FeedPostFormDto } feedPostDto
 * @param { string } feedId
 */
const createFeedPost = async (feedPostDto, feedId, dispatch) => {
    const url = endPoint.CLASS_CREATE_FEED_URL(feedId);
    /**
     * @type {{data: import('./Feed').FeedPostDto}} reponse
     */
    const response = await instance.post(url, feedPostDto);
    if (response) {
        dispatch(feedDuck.addNewFeed(response.data));
    }

}

/**
 *
 * @param {import('./Feed').FeedPostFormDto } feedPostDto
 * @param { string } feedId
 * @param { number } postId
 */
const updateFeedPost = async (feedPostDto, postId, feedId, dispatch) => {
    const url = endPoint.CLASS_UPDATE_FEED_URL(feedId, postId);
    /**
     * @type {{data: import('./Feed').FeedPostDto}} reponse
     */
    const response = await instance.put(url, feedPostDto);
    if (response) {
        dispatch(feedDuck.updateFeed(response.data));
    }

}


/**
 *
 * @param {import('./Feed').FeedCommentFormDto } postData
 */
const createFeedComment = (postData) => async (dispatch) => {

    const url = endPoint.CLASS_CREATE_FEED_COMMENT_URL(postData.feedId, postData.postId);
    /**
     * @type {{data: import('./Feed').FeedCommentDto}} response
     */
    const response = await instance.post(url, { Value: postData.text });
    if (response) {
        dispatch(feedDuck.addNewComment(response.data));
    }
}


/**
 *
 * @param {{dto:import('./Feed').FeedCommentFormDto, commentId: number} } postData
 */
const updateFeedComment = (postData) => async (dispatch) => {

    const url = endPoint.CLASS_UPDATE_FEED_COMMENT_URL(postData.commentId, postData.dto.feedId, postData.dto.postId);
    /**
     * @type {{data: import('./Feed').FeedCommentDto}} response
     */
    const response = await instance.put(url, { Value: postData.dto.text });
    if (response) {
        dispatch(feedDuck.updateComment(response.data));
    }
}


const getFeedStartDataForClass = classId => async (dispatch, getstate) => {
    /**
     * @type {import('./Feed').FeedState}
     */
    var state = getstate().feed;
    /**
     * @type {{data: import('./Feed').FeedDto }}
     */
    const response = await instance.get(endPoint.CLASS_FEED_START_URL(classId, state.userId, state.filterType));
    if (!response) {
        return;
    }
    dispatch(feedDuck.setStartData(response.data));
}


const getFeedStartDataForSubGroup = (classId, groupId) => async (dispatch, getstate) => {
    /**
     * @type {import('./Feed').FeedState}
     */
    var state = getstate().feed;
    /**
     * @type {{data: import('./Feed').FeedDto }}
     */
    const response = await instance.get(endPoint.GET_FEED_FOR_BASEGROUP_URL(classId, groupId, state.userId, state.filterType));
    if (!response) {
        return;
    }

    dispatch(feedDuck.setStartData(response.data));
}


const getMoreData = postId => async (dispatch, getstate) => {
    /**
     * @type {import('./Feed').FeedState}
     */
    var state = getstate().feed;
    /**
     * @type {{data: import('./Feed').FeedDto }}
     */
    const response = await instance.get(endPoint.CLASS_MORE_FEED_URL(state.currentFeed.Id, postId, state.userId, state.filterType));
    if (!response) {
        return;
    }
    dispatch(feedDuck.setMoreData(response.data));
}


const getAllComments = postId => async (dispatch, getstate) => {
    /**
     * @type {import('./Feed').FeedState}
     */
    var state = getstate().feed;
    /**
     * @type {{data: import('./Feed').FeedCommentDto[] }}
     */
    const response = await instance.get(endPoint.FEED__ALL_COMMENTS_URL(state.currentFeed.Id, postId));
    if (!response) {
        return;
    }
    dispatch(feedDuck.setCommentsToPost({ postId: postId, comments: response.data }));
}

const deletePosting = postId => async (dispatch, getstate) => {
    /**
     * @type {import('./Feed').FeedState}
     */
    var state = getstate().feed;
    await instance.delete(endPoint.CLASS_GET_ONE_FEEDPOST_URL(state.currentFeed.Id, postId));
    dispatch(feedDuck.feedPostingDeleted(postId));
}

const deleteComment = (postId, commentId) => async (dispatch, getstate) => {
    /**
     * @type {import('./Feed').FeedState}
     */
    var state = getstate().feed;
    await instance.delete(endPoint.CLASS_GET_ONE_FEEDCOMMENT_URL(state.currentFeed.Id, postId, commentId));
    dispatch(feedDuck.feedCommentDeleted({ postId: postId, commentId: commentId }));
}



/**
 * 
 * @param { string } userId 
 * @param { number } filterType
 * @param { string } subGroupId?
 * 
 * 
 */
const filterData = (userId, classId, filterType, subGroupId) => async (dispatch, getState) => {

    /**
     * @type {import('./Feed').FeedState}
     */
    const feedData = getState().feed;
    const oldUserId = feedData.userId;
    const oldType = feedData.filterType;

    if (feedData.currentFeed != null) {
        dispatch(feedDuck.setFilterData({ userId: userId, filterType: filterType }));
    }
    /**
     * @type { import('axios').AxiosResponse<import('./Feed').FeedDto>}
     */
    let response = null;
    if (oldUserId !== userId || oldType !== filterType) {
        if (subGroupId) {
            const classId = getState().classData.currentClass.Id;
            response = await instance.get(endPoint.GET_FEED_FOR_BASEGROUP_URL(classId, subGroupId, userId, filterType));
        }
        else {
            response = await instance.get(endPoint.CLASS_FEED_START_URL(classId, userId, filterType));
        }
        if (!response) {
            return;
        }
        dispatch(feedDuck.setStartData(response.data));
    }
}

/**
 * 
 * @param { import('./Feed').FeedDraft } data 
 * 
 * 
 */
const saveDraft = (data) => {
    try {
        const id = data.id;
        delete data.id;
        lnDataBase.feedDraft.update(id, data);
    }
    catch (err) { }
};

/**
*
* @param { {feedId:string, commentTo?: number, userId: string} } query
* @returns { Promise<import('./Feed').FeedDraft> }
*/
const getDraft = async (query) => {
    try {
        let posts;
        if (!query.commentTo) {
            posts = await lnDataBase.feedDraft
                .where({ userId: query.userId, feedId: query.feedId }).toArray();
            if (posts) {
                posts = posts.filter(p => !!!p.commentTo);
            }
        }
        else {
            posts = await lnDataBase.feedDraft
                .where({ userId: query.userId, feedId: query.feedId, commentTo: query.commentTo }).toArray();
        }

        if (!posts || posts.length === 0) {
            const data = {
                feedId: query.feedId,
                commentTo: query.commentTo,
                text: "",
                userId: query.userId,
                date: new Date()
            }
            const id = await lnDataBase.feedDraft.add(data, null);
            data.id = id;

            return data;
        }
        else {
            return posts[0];
        }
    } catch (err) {
        return {
            feedId: query.feedId,
            commentTo: query.commentTo,
            text: "",
            userId: query.userId,
            date: new Date(),
            id : 1
        }
    }
}

const deleteDraft = (id) => {
    try {
        lnDataBase.feedDraft.delete(id);
    }
    catch (err) { }
};

export const feedDuck = {
    ...feedSlice.actions, getFeedStartDataForClass, getFeedStartDataForSubGroup,
    getMoreData, createFeedComment, createFeedPost, deleteComment, deletePosting, getAllComments,
    filterData, updateFeedComment, updateFeedPost, saveDraft, getDraft, deleteDraft
};
export default feedSlice.reducer;