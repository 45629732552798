//@ts-check
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import reactModal from 'utils/react-modal';
import Draggable from "react-draggable"
import { v4 as uuidv4 } from 'uuid'


export const ModalConstants = {
    No: 1,
    Yes: 2,
    Ok: 3,
    Cancel: 4
}


/**
 * @typedef { {
    className: string,
    text: String,
    type: 'submit' | 'dismiss',
    result?: any }
    }

    DialogButton
 */



/**
 *
 * @param {{
    backdrop: boolean | 'static',
    button: DialogButton,
    class?: string,
    message:string | import('react').ReactChild,
    title: string,
    size?:  'sm' | 'lg' | 'md'
}} props
 */
const Alert = (props) => {

    let message = null;
    let closeCallback = null;

    if (typeof props.message == "string") {
        message = <div dangerouslySetInnerHTML={{ __html: props.message }}></div>;
    }
    else {
        message = props.message;
    }

    const size = props.size || 'md';

    const getClosingCallback = (func) => {
        closeCallback = func;
    }

    const keyHandler = (e) => {
        if (e.key === "Enter") {
            closeCallback();
        }
    }

    let options = {
        getCloseCallback: getClosingCallback,
        keyHandler: keyHandler
    };

    return reactModal(({ show, onSubmit, onDismiss }) =>
        <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>
            <Modal size={size} role="alertdialog" isOpen={show} toggle={onDismiss} className={props.class} backdrop={props.backdrop}>
                <ModalHeader className="bg-light" toggle={onDismiss}>{props.title}</ModalHeader>
                <ModalBody>
                    {message}
                </ModalBody>
                <ModalFooter>
                    <Button className={props.button.className} onClick={() => onSubmit('OK')}>{props.button.text}</Button>
                </ModalFooter>
            </Modal>
        </Draggable >
        , options);
}

/**
 *
 * @param { {
    backdrop: boolean | 'static',
    buttons: DialogButton[],
    class: string,
    languageService: import('features/language/languageService').LanguageService,
    message:string | import('react').ReactChild,
    title: string,
    okOnEnter?: boolean,
    type: "ok/cancel" | "yes/no" | null
}} props
 */
const Confirm = async (props) => {

    let closeCallback = null;

    const getClosingCallback = (func) => {
        closeCallback = func;
    }


    let options = { getCloseCallback: getClosingCallback };

    let types = { submit: "Ok", dismiss: "Cancel" };
    if (props.type === "yes/no") {
        types = { submit: "Yes", dismiss: "No" };
    }

    const keyHandler = (e) => {
        if (e.key === "Enter") {
            closeCallback(ModalConstants[types.submit]);
        }
    }

    if (props.okOnEnter === true) {
        options.keyHandler = keyHandler;
    }

    return reactModal(({ show, onSubmit, onDismiss }) =>
        <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>
            <Modal role="alertdialog" isOpen={show} toggle={onDismiss} className={props.class} backdrop={props.backdrop}>
                <ModalHeader toggle={() => onDismiss(ModalConstants.Cancel)}>{props.title}</ModalHeader>
                <ModalBody>
                    {props.message}
                </ModalBody>
                <ModalFooter>
                    {
                        props.buttons && props.buttons.map((b) => {
                            var callBack = () => onDismiss(ModalConstants.Cancel);
                            if (b.type === 'submit') {
                                callBack = () => onSubmit(ModalConstants.Ok);
                            }
                            return <><Button className={b.className} onClick={callBack}>{b.text}</Button>&nbsp;</>
                        })
                    }
                    <Button className="btn btn-primary" onClick={() => onSubmit(ModalConstants[types.submit])}>{props.languageService.getText(types.submit.toLowerCase())}</Button>&nbsp;
                <Button className="btn btn-inverse" onClick={() => onDismiss(ModalConstants[types.dismiss])}>{props.languageService.getText(types.dismiss.toLowerCase())}</Button>
                </ModalFooter>
            </Modal>
        </Draggable>

        , options

    );
}


/**
 *
 * @param { {
    backdrop?: boolean | 'static',
    bodyClass?: string,
    buttons?: DialogButton[],
    class?: string,
    headerClass?: string,
    languageService?: import('features/language/Language').LanguageService,
    message:string | import('react').ReactChild | import('react').ReactChildren,
    title?: string,
    size?: string
}} props
 */
const ModalDialog = (props) => {

    let buttonId = 0;
    let closeCallback = null;

    const getClosingCallback = (func) => {
        closeCallback = func;
    }

    const promise = reactModal(({ show, onSubmit, onDismiss }) =>
        <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }} >
            <Modal size={props.size} role="dialog" isOpen={show} toggle={onDismiss} className={props.class + " draggable-dialog"} backdrop={props.backdrop}>
                <ModalHeader className={props.headerClass} toggle={() => onDismiss(ModalConstants.Cancel)}>{props.title}</ModalHeader>
                <ModalBody className={props.bodyClass} tabIndex={-1}>
                    {props.message}
                </ModalBody>
                <ModalFooter>
                    {
                        props.buttons && props.buttons.map((b) => {
                            var callBack = () => onDismiss(ModalConstants.Cancel);
                            if (b.type === 'submit') {
                                callBack = () => onSubmit(ModalConstants.Ok);
                            }
                            if( b.result !== undefined){
                                callBack = () => onSubmit(b.result);
                            }
                            return <Button key={buttonId++} className={['ml-2', b.className].join(' ')} onClick={callBack}>{b.text}</Button>
                        })
                    }
                    {(!props.buttons || props.buttons.length === 0) && <Button className="btn btn-primary" onClick={() => onSubmit(ModalConstants.Ok)}>{props.languageService.getText("ok")}</Button>}
                </ModalFooter>
            </Modal>
        </Draggable>
        , { getCloseCallback: getClosingCallback }

    );

    return {
        promise,
        close: (res) => closeCallback(res)
    };
}

/**
 *
 * @param { {
    backdrop?: boolean | 'static',
    bodyClass?: string,
    class?: string,
    headerClass?: string,
    languageService?: import('features/language/languageService').LanguageService,
    message:string | null | import('react').ReactChild | import('react').ReactChildren,
    title?: string,
    fields: { name: string, type: string , label:string,  placeholder: string, noautocomplete?: boolean, value?: string }[],
    closeOnEnter?: boolean,
    size?:  'sm' | 'lg' | 'md'
}} props
 */
const Input = (props) => {

    let closeCallback = null;

    const getClosingCallback = (func) => {
        closeCallback = func;
    }

    const size = props.size || 'md';

    const values = {};
    let rndNames = {};
    const fields = props.fields.map(f => {
        let field = { ...f };
        if (f.noautocomplete === true) {
            const key = "f" + uuidv4();
            rndNames[key] = f.name;
            field.name = key;
        }
        if (f.value) {
            values[field.name] = f.value;
        }

        return field;
    });


    const handleOnChange = (e) => {
        const key = rndNames[e.target.name] || e.target.name;
        values[key] = e.target.value;
    }

    const close = () => {
        closeCallback(values);
    }

    const keyHandler = (e) => {
        if (e.key === "Enter") {
            close();
        }
    }

    let options = { getCloseCallback: getClosingCallback };
    if (props.closeOnEnter === true) {
        options.keyHandler = keyHandler;
    }


    const promise = reactModal(({ show, onSubmit, onDismiss }) =>
        <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>
            <Modal size={size} role="dialog" isOpen={show} toggle={onDismiss} className={props.class} backdrop={props.backdrop}>
                <ModalHeader className={props.headerClass} toggle={() => onDismiss(ModalConstants.Cancel)}>{props.title}</ModalHeader>
                <ModalBody className={props.bodyClass} tabIndex={-1}>
                    <div className="mb-4">{props.message}</div>

                    {fields.map((f, index) => {
                        return (
                            <div className="mb-4" key={index}>
                                {f.label && <> <label> {f.label}</label>
                                <br /> </>
                                }

                                {f.type !== "textarea" ? <input className="form-control" type={f.type} onChange={handleOnChange} name={f.name} placeholder={f.placeholder} defaultValue={f.value || ''} />
                                    :
                                    <textarea rows={4} className="form-control" onChange={handleOnChange} name={f.name} placeholder={f.placeholder} defaultValue={f.value || ''} />
                                }

                            </div>)
                    })}

                </ModalBody>
                <ModalFooter>
                    <Button key={"btnok"} className="btn btn-primary" onClick={() => close()}>{props.languageService.getText("ok")}</Button>
                    <Button key={"btncancel"} className="btn btn-inverse" onClick={() => onDismiss()}>{props.languageService.getText("cancel")}</Button>
                </ModalFooter>
            </Modal>
        </Draggable>
        , options
    );

    return {
        promise,
        close: (res) => closeCallback(res)
    };
}

export { Alert, Confirm, ModalDialog, Input };