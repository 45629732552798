//@ts-check
import React, { useState } from "react";
import { useI18n } from "components/lni18n";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Draggable from "react-draggable"

import LnIcon from "components/LnIcon";

import classes from '../classmail.module.scss';
import useTypedSelector from "utils/useTypedSelector";
import RecieversResendList from "./RecieversResendList";
import RecieversList from "./RecieversList";

/**
 * DetailReceiverModal
 * @module features/classes/ClassItems/ClassMail/DetailReceiverModal
 * @return a modal with list of Mail receivers.
 * @param {{
    mailData: import("../ClassMail").BulkMailProjectDetailDto,
}} props
 */
const DetailReceiverModal = ({ mailData }) => {

    const { languageService: t } = useI18n();
    const user = useTypedSelector(state => state.login.user);

    const toggleModal = () => setModal(m => !m);

    const [modal, setModal] = useState(false);

    const iconTypes = {
        opened: <LnIcon className={["mr-4 filter-primary", classes.receiverIcon].join(' ')} name="email-read-icon" />,
        sent: <LnIcon className={["mr-4", classes.receiverIcon].join(' ')} name="email-unread-icon" />,
        unsent: <LnIcon className="mr-4" name="spinner" />,
        errorsending: <LnIcon className={["mr-4 filter-red", classes.receiverIcon].join(' ')} name="email-error-icon" />,
        notInSendList: <div className={["mr-4 icon d-inline-block", classes.receiverIcon].join(' ')} >&nbsp;</div>,
    };

 

    return (<>
        <button className="btn btn-inverse btn-small"
            onClick={() => toggleModal()}
            type="button">{t.getText('mail.reciever')}
        </button>
        <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>
            <Modal role="dialog" isOpen={modal} toggle={toggleModal} backdrop='static'>
                <ModalHeader toggle={toggleModal}>{t.getText('mail.reciever')}</ModalHeader>
                <ModalBody tabIndex={-1}>
                    {user.UserState > 0 && <RecieversResendList mailData={mailData} iconTypes={iconTypes} />}
                    {user.UserState === 0 && <RecieversList mailData={mailData} iconTypes={iconTypes} />}
                </ModalBody>
                <ModalFooter>

                    <div>
                        <Button className="btn btn-inverse btn-small" onClick={() => toggleModal()}>{t.getText('close')} </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </Draggable>

    </>)
}
export default DetailReceiverModal;
