//@ts-check
import React, { useEffect, useRef } from "react";
import { mcTestDuck } from "./McTestDuck";
import { useDispatch } from "react-redux";
import WaitRipple from "components/WaitRipple";

/**
 * 
 * @param {{currentTest: import("./McTest").MCTestStartDto, 
 *      currentQuestion: import("./McTest").MCTestQuestionData,
 *      toNextQuestion: ()=>void, 
 *      submitAnswer: ()=>void, 
 *      languageService: any }} param0 
 */
const McTestRadioOptions = ({ currentTest, currentQuestion, toNextQuestion, submitAnswer, languageService }) => {

    const t = languageService;

    const dispatch = useDispatch();
    let firstControl = useRef(null);
    useEffect(() => {
        if (firstControl.current != null && firstControl.current.children) {
            // focus on first input control
            firstControl.current.children[0].focus();
        }
    })

    const disabled = (currentTest.StudyMode && currentQuestion.pending && !currentQuestion.WasCorrect)
        || (!currentTest.StudyMode && currentQuestion.pending);

    const handleChange = (i, e) => {
        window.setTimeout(() => {
            dispatch(mcTestDuck.handleAnswerChecked({ index: i, state: true }));
        }, 1)
    }

    return <>
        {currentQuestion.data.Answers.map((a, i) => {
            return (
                <div ref={el => i === 0 ? (firstControl.current = el) : null}
                    key={a.Index}
                    className="custom-control custom-radio mb-4"
                >
                    <input
                        disabled={disabled}
                        checked={a.Checked || false}
                        type="radio"
                        name="answ"
                        className="custom-control-input"
                        id={"id_" + a.Index}
                        onChange={(e) => handleChange(a.Index, e.target)}
                    />

                    <label
                        className="custom-control-label pt-1"
                        htmlFor={"id_" + a.Index}
                        dangerouslySetInnerHTML={{ __html: a.Text }}
                    ></label>
                </div>
            );

        })}

        <div className="position-relative w-50">
            {currentQuestion.savingToServer && <WaitRipple />}
        </div>

        {!currentTest.StudyMode &&
            <div className="mt-2" >
                <button type="button" disabled={!currentQuestion.data.CanPost || currentQuestion.pending || currentQuestion.savingToServer} onClick={submitAnswer} className="btn btn-primary mr-2" >{t.getText("save.short")}</button>
                {currentQuestion.pending ? <button type="button" onClick={() => toNextQuestion()} className="btn btn-inverse btn-primary mr-2" >{t.getText("next")}</button> : null}
            </div >
        }



    </>

}

export default McTestRadioOptions;