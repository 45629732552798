//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import dayjs from "dayjs";
import languageService from "features/language/languageService";


/**
 * @type {import('./TimeTable').TimeTableState}
 */
const initialState = {
    defaultDate: new Date(),
    defaultView: "listAllForClass",
    allMyCourses: true,
    startdata: null,
    currentEvent: null,
    showEditor: false,
    editorData: null,
    checkData: null,
    quickCheckData: null,
    userDefaultTimes: { start: { hours: 9, minutes: 0 }, end: { hours: 11, minutes: 0 } },
    savingEvent: false,
    showDefaultTimesEditor: false,
    selectedDates: null,
    teacherOnly: false,
    allForUnit: false
};

const timetableSlice = createSlice({
    name: 'timetable',
    initialState: initialState,
    reducers: {

        /**
        * @param  {  {payload: import('./TimeTable').TimeTableStartData}} action
        */
        setStartData(state, action) {
            state.startdata = action.payload;
            state.checkData = null;
            state.editorData = null;
            state.selectedDates = null;
        },

        /**
        * @param  {  {payload: boolean}} action
        */
        setSavingEvent(state, action) {
            state.savingEvent = action.payload;
        },

        /**
        * @param  {  {payload: import('./TimeTable').DefaultTimesFullCalenderDto}} action
        */
        setDefaultUserTimes(state, action) {
            state.userDefaultTimes = action.payload
        },



        /**
         *
         * @param {{payload:{ date: Date, view: string }}} action
         */
        setCalenderState(state, action) {
            state.defaultView = action.payload.view;
            state.defaultDate = action.payload.date;
        },

        /**
         *
         * @param {{payload:boolean}} action
         */
        toggleAllMyCourses(state) {
            state.allMyCourses = !state.allMyCourses;
            state.allForUnit = false;
        },

        /**
         *
         * @param {{payload:boolean}} action
         */
        setTeacherOnly(state, action) {
            state.teacherOnly = action.payload;
        },

        /**
         *
         * @param {{payload:boolean}} action
         */
        setAllForUnit(state, action) {
            state.allForUnit = action.payload;
            state.allMyCourses = false;
            state.teacherOnly = false;
        },


        /**
         *
         * @param {{payload:import('./TimeTable').eventDto}} action
         */
        setCurrentEvent(state, action) {
            state.currentEvent = action.payload;
        },

        /**
        *
        * @param {{payload:boolean}} action
        */
        setShowEditor(state, action) {
            state.showEditor = action.payload;
        },

        /**
       *
       * @param {{payload:boolean}} action
       */
        setShowDefaultTimesEditor(state, action) {
            state.showDefaultTimesEditor = action.payload;
        },

        /**
      *
      * @param {{payload:{start:Date, end:Date}}} action
      */
        setSelectedDates(state, action) {
            state.selectedDates = action.payload;
        },



        /**
       *
       * @param {{payload:import('./TimeTable').ClassTimetableEditorInfoDto}} action
       */
        setEditorData(state, action) {
            state.editorData = action.payload;
        },

        /**
       *
       * @param {{payload:import('./TimeTable').EventCheckDto}} action
       */
        setCheckData(state, action) {
            state.checkData = action.payload;
        },

        /**
      *
      * @param {{payload:import('./TimeTable').ExtendedEventCheckDto}} action
      */
        setQuickCheckData(state, action) {
            state.quickCheckData = action.payload;
        },

        /**
       *
       * @param {{payload:{id: number, data:import('./TimeTable').EventFormDto}}} action
       */
        currentEventUpdated(state, action) {
            if (state.currentEvent) {
                if (state.currentEvent.Id !== action.payload.id) {
                    state.currentEvent.Id = action.payload.id;
                }

                state.currentEvent.Title = action.payload.data.Title;
                state.currentEvent.Notes = action.payload.data.Notes;
                state.currentEvent.RoomNotes = action.payload.data.RoomNotes;
                state.currentEvent.TeacherNotes = action.payload.data.TeacherNotes;

                if (state.editorData) {
                    if (action.payload.data.TeacherIds && action.payload.data.TeacherIds.length > 0) {

                        const teachers = action.payload.data.TeacherIds.map(id => {
                            const us = state.editorData.Teachers.find(te => te.Id === id);
                            return {
                                Id: us.Id,
                                FullName: us.FullName,
                                Email: us.Mail,
                                Tele1: null,
                                Tele2: null,
                            }
                        });

                        state.currentEvent.Teachers = teachers;
                    }
                    else {
                        state.currentEvent.Teachers = [];
                    }

                    if (action.payload.data.RoomIds && action.payload.data.RoomIds.length > 0) {
                        const rooms = action.payload.data.RoomIds.map(id => {
                            return state.editorData.Rooms.find(ro => ro.Id === id);
                        });

                        state.currentEvent.Rooms = rooms;
                    }
                    else {
                        state.currentEvent.Rooms = [];
                    }
                }

            }
        },
    }
});

const getCurrentEvent = (eventId) => async dispatch => {
    /**
     * @type {{data: import('./TimeTable').eventDto }}
     */
    const response = await instance.get(endPoint.TIMETABLE_GET_EVENT_DETAILS_URL(eventId));

    if (!response || !response.data) {
        return null;
    }
    const data = response.data;

    data.StartDate = new Date(data.StartDate);
    data.EndDate = new Date(data.EndDate);

    await dispatch(timetableDuck.setCurrentEvent(data));

    return data;
}


const getTimetableStartData = classId => async dispatch => {
    /**
     * @type {{data: import('./TimeTable').TimeTableStartData }}
     */
    const response = await instance.get(endPoint.CLASS_START_TIMETABLE_URL(classId));

    if (!response || !response.data) {
        return null;
    }

    dispatch(timetableDuck.setStartData(response.data));
    return response.data;
}

const startEdit = (classId) => async dispatch => {
    /**
     * @type {{data: import('./TimeTable').ClassTimetableEditorInfoDto }}
     */
    const response = await instance.get(endPoint.CLASS_EDIT_EVENT_DATA_URL(classId));
    if (!response || !response.data) {
        return null;
    }
    dispatch(timetableDuck.setEditorData(response.data));
    dispatch(timetableDuck.setShowEditor(true));
}

/**
 * 
 * @param {import('./TimeTable').EventCheckFormDto} eventCheckData 
 */
const checkEvent = (eventCheckData) => async dispatch => {
    /**
     * @type {{data: import('./TimeTable').EventCheckDto }}
     */
    const response = await instance.post(endPoint.TIMETABLE_GET_CHECK_EVENT_URL, eventCheckData);
    if (response && response.data) {
        if ((response.data.BookedRooms !== null && response.data.BookedRooms.length > 0) || (response.data.BookedTeachers !== null && response.data.BookedTeachers.length > 0)) {
            dispatch(timetableDuck.setCheckData(response.data))
        } else {
            dispatch(timetableDuck.setCheckData(null));
        }
    }
}

/**
 * 
 * @param {import('./TimeTable').EventFormDto} eventData 
 */
const saveEvent = (eventData) => async (dispatch, getState) => {


    let dummy = { ...getState().timetable.currentEvent, Id: -1, Notes: null };
    dummy.Id = -1;
    dummy.Notes = null;

    dispatch(timetableDuck.setSavingEvent(true));

    try {
        /**
         * @type {{data: import('./TimeTable').SaveEventResponse }}
         */
        const response = await instance.post(endPoint.TIMETABLE_GET_POST_EVENT_URL, eventData);
        if (response && response.data) {
            const saveResponse = response.data;
            dispatch(timetableDuck.setCurrentEvent(dummy));

            const prom = new Promise((resolve, reject) => {
                window.setTimeout(() => resolve("ok"), 2);
            });

            await prom;
            await dispatch(timetableDuck.getCurrentEvent(saveResponse.Id));
            dispatch(timetableDuck.setSavingEvent(false));


        }
    } catch {
        dispatch(timetableDuck.setSavingEvent(false));
    }
}

/**
 * 
 * @param {import('@fullcalendar/core').EventApi} eventApi 
 */
const updateEvent = (eventApi) => async dispatch => {

    /**
     * @type {{data: import('./TimeTable').eventDto }}
     */
    const responsedetail = await instance.get(endPoint.TIMETABLE_GET_EVENT_DETAILS_URL(eventApi.id));
    if (!responsedetail || !responsedetail.data) {
        return null;
    }
    const data = responsedetail.data;

    data.StartDate = eventApi.start;
    data.EndDate = eventApi.end;

    const eventData = {
        Id: data.Id,
        Title: data.Title,
        Notes: data.Notes,
        StartDate: data.StartDate,
        EndDate: data.EndDate,
        RoomNotes: data.RoomNotes,
        TeacherNotes: data.TeacherNotes,
        ClassId: data.StudentClass.Id,
        RoomIds: data.Rooms ? data.Rooms.map(r => r.Id) : null,
        TeacherIds: data.Teachers ? data.Teachers.map(r => r.Id) : null,
        CheckDoubleBookings: true

    }

    /**
     * @type {{data: import('./TimeTable').SaveEventResponse }}
     */
    const response = await instance.post(endPoint.TIMETABLE_GET_POST_EVENT_URL, eventData);
    if (response && response.data && response.data.EventCheckDto) {
        if ((response.data.EventCheckDto.BookedRooms && response.data.EventCheckDto.BookedRooms.length)
            || (response.data.EventCheckDto.BookedTeachers && response.data.EventCheckDto.BookedTeachers.length)) {
            dispatch(timetableDuck.setQuickCheckData({ ...response.data.EventCheckDto, Event: data }));
        }
    }



}

/**
 * 
 * @param {number} eventId 
 */
const deleteEvent = (eventId) => async dispatch => {
    await instance.delete(endPoint.TIMETABLE_DELETE_EVENT_URL(eventId));
    dispatch(timetableDuck.setCurrentEvent(null));
}


const getDefaultUserTimes = () => async (dispatch) => {

    /**
     * @type {{data: import('./TimeTable').DefaultTimesFullCalenderDto }}
     */
    const response = await instance.get(endPoint.TIMETABLE_GET_USER_DEFAULT_TIMES_URL);
    if (response && response.data) {
        dispatch(timetableDuck.setDefaultUserTimes(response.data))
        return response.data;
    }

    return null;
}

/**
 * @param {import('./TimeTable').DefaultTimesFullCalenderDto } data
 */

const updateDefaultUserTimes = (data) => async (dispatch) => {

    /**
     * @type {{data: import('./TimeTable').DefaultTimesFullCalenderDto }}
     */
    const response = await instance.put(endPoint.TIMETABLE_GET_USER_DEFAULT_TIMES_URL, data);
    if (response && response.data) {
        dispatch(timetableDuck.setDefaultUserTimes(response.data))
    }
}

/**
 * 
 * @param {string} classId 
 * @param {string} className 
 * @param {boolean} copyCurrent 
 * @returns 
 */
const newEvent = (classId, className, copyCurrent) => async (dispatch, getState) => {


    /**
     * @type {import('./TimeTable').TimeTableState}
     */
    var timeTableState = getState().timetable;

    let defaultDates = timeTableState.selectedDates || null;

    if (!defaultDates) {
        /**
         * @type {import('./TimeTable').DefaultTimesFullCalenderDto }
         */
        const times = timeTableState.userDefaultTimes || await dispatch(timetableDuck.getDefaultUserTimes());
        defaultDates = {
            start: dayjs().hour(times.start.hours).minute(times.start.minutes).toDate(),
            end: dayjs().hour(times.end.hours).minute(times.end.minutes).toDate()
        };
    }

    let eventDto;

    if (copyCurrent) {
        eventDto = {
            ...timeTableState.currentEvent, Id: 0,
            Title: `${languageService.getText("a.copy")} :  ${timeTableState.currentEvent.Title}`
        }
    }
    else {

        eventDto = {
            Id: 0,
            Title: "",
            Notes: "",
            StudentClass: { Id: classId, Name: className },
            StartDate: defaultDates.start,
            EndDate: defaultDates.end,
            Rooms: [],
            RoomNotes: null,
            Teachers: [],
            Duration: null,
            TeacherNotes: null,
            Editable: true,
        }
    }

    await dispatch(timetableDuck.setSelectedDates(null));
    await dispatch(timetableDuck.setCurrentEvent(eventDto));
}

export const timetableDuck = {
    ...timetableSlice.actions, getTimetableStartData, deleteEvent, updateEvent,
    getCurrentEvent, startEdit, checkEvent, saveEvent, getDefaultUserTimes, newEvent, updateDefaultUserTimes
};

export default timetableSlice.reducer;