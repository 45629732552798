//@ts-check
import React, { useEffect } from "react";
import { useDispatch, useStore } from "react-redux";

import { Confirm, ModalConstants } from "components/OnlineModal";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import WaitRipple from "components/WaitRipple";
import ScrollContent from "components/ScrollContent";
import useTypedSelector from "utils/useTypedSelector";

import { singleMailDuck } from "./SingleMailDuck";

import SingleMailForm from "./SingleMailForm";
import MailHeaders from "components/Mail/MailHeaders";
import SingleMailDetail from "./SingleMailDetail";

import classes from './singlemail.module.scss';

const SingleMail = () => {

    const dispatch = useDispatch();
    const store = useStore();
    const {mailLists, currentProject} = useTypedSelector(state => state.singleMail);
    const { type: route, payload: {  listtype } } = useTypedSelector(state => state.location);
    const {  languageService: t } = useI18n();

    // clean up at unload of component
    useEffect(() => {

        return () => {
            dispatch(singleMailDuck.setStartData(null));
        };
    }, [dispatch])

    const closeForm =() => {
        dispatch({ type: "GENERAL_SINGLE_MAIL", payload: { listtype: listtype} });


    }

    /**
     * @param {number} id
     */
    const handleDelete = async (id) => {
        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("delete"),
            message: (<div>{t.getText("delete_general_confirm")}</div>),
            languageService: t,
            type: "yes/no",
            okOnEnter: true
        });

        if (result === ModalConstants.Yes) {
            await dispatch(singleMailDuck.deleteMail(id));
            closeForm();
        };
    }

    const newMail = async () => {

        const _mailid = await singleMailDuck.newMail( dispatch, store.getState, null);
        dispatch({ type: "GENERAL_SINGLE_MAIL_EDIT" , payload: {mailid: _mailid, listtype: 'draft'}});

    }

    const handleClick = (mailid) => {
        dispatch({ type: "GENERAL_SINGLE_MAIL_EDIT" , payload: {mailid: mailid, listtype: listtype}});
    }


    const changeTab = (type)=>{
        dispatch({ type: "GENERAL_SINGLE_MAIL", payload: { listtype: type } });
    }


    if (mailLists === null) {
        return <ScrollContent scrollname="single-mail"><WaitRipple /></ScrollContent>
    }

    if (route === "GENERAL_SINGLE_MAIL" || route === "GENERAL_SINGLE_MAIL_EDIT") {

        if (mailLists === null) {
            return <ScrollContent scrollname="single-mail"><WaitRipple /></ScrollContent>
        }

        return (
            <ScrollContent scrollname="single-mail">
                <div className="card-box big-card-box">
                    <div className="card-header ln-nostyle d-flex justify-content-between">
                        <h3>
                            <LnIcon className="header-icon" name="email-icon" />
                            {t.getText('personal.email')}
                        </h3>
                        <button type="button" className="btn btn-primary btn-small" onClick={() => newMail()}>{t.getText("mail.single.new")}</button>
                    </div>
                    <div className="card-body pt-5">
                        <div className="row">
                        <div className={["", (route === "GENERAL_SINGLE_MAIL_EDIT" ? 'd-none  d-md-block col-sm-12 col-md-4 col-lg-4 col-xl-3' : 'col-sm-12 col-md-4 col-lg-4 col-xl-3')].join(' ')}>
                                <MailHeaders listtype={listtype} changeTab={(tab)=>changeTab(tab)} handleClick={handleClick} handleDelete={handleDelete}  currentProject={currentProject} mailLists={mailLists} />
                            </div>

                            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-9">
                                {(route !== "GENERAL_SINGLE_MAIL_EDIT") && <div className={classes['email-single-explain']}>
                                <p dangerouslySetInnerHTML={{ __html: t.getText('email.single.explain.html') }}></p>
                                </div>}
                                {
                                    (route === "GENERAL_SINGLE_MAIL_EDIT") && !currentProject && <WaitRipple />}
                                {
                                    (route === "GENERAL_SINGLE_MAIL_EDIT") && currentProject && currentProject.Queued && <SingleMailDetail mailData={currentProject} closeForm={closeForm} handleDelete={handleDelete} />}
                                {
                                    (route === "GENERAL_SINGLE_MAIL_EDIT") && currentProject && !currentProject.Queued && <SingleMailForm mailData={currentProject} closeForm={closeForm} handleDelete={handleDelete} />}
                            </div>
                        </div>

                    </div>
                </div>
            </ScrollContent>)
    }
}


export default SingleMail;
