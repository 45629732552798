//@ts-check
import React from "react";
import WaitRipple from "components/WaitRipple";
import SingleMail from "features/General/SingleMail/SingleMail";
import UserData from "features/User/UserData";
import useTypedSelector from "utils/useTypedSelector";
import PersonalDataPolicy from "./PersonalDataPolicy/PersonalDataPolicy";
import CourseOverview from "./CourseOverview/CourseOverview";
import DocBox from "./DocBox/DocBox";
import SwitchUser from "features/User/SwitchUser";
import TeacherClasses from "./TeacherOverView/TeacherClasses";
import Feedback from "features/Feedback/Feeback";

const GeneralRoot = props => {
    

    const route = useTypedSelector(state => state.location.type);

    const routes = {
        GENERAL_SINGLE_MAIL: <SingleMail />,
        GENERAL_SINGLE_MAIL_EDIT: <SingleMail />,
        GENERAL_PERSONAL_SETTINGS: <UserData />,
        GENERAL_PERSONAL_DATA_POLICY: <PersonalDataPolicy />,
        GENERAL_COURSE_OVERVIEW: <CourseOverview />,
        GENERAL_SWITCH_USER: <SwitchUser />,
        GENERAL_DOCBOX: <DocBox />,
        GENERAL_TEACHER_OVERVIEW: <TeacherClasses />,
        GENERAL_FEEDBACK_FORUM: <Feedback />,
        GENERAL_FEEDBACK_THREAD: <Feedback />
    };

    let content = routes[route];
    if (!content) {
        content = <WaitRipple />
    }

    return content;
};

export default GeneralRoot;
