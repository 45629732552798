import React from "react";
import TestStatusIcon from "components/TestStatusIcon";
import { TestResultStatus } from "../test/TestEnums";
import testService from "../test/TestService";

const McTestNoData = ({ reloadTestHandler, showHistoryDialog, submittestToServer, testName, currentQuestion, currentTest,
    currentClass, currentTheme, currentPart, languageService }) => {

    const t = languageService;

    const closeTest = () => {
        testService.closeTest(currentClass.Id, currentTheme.Id, currentPart.Id);
    }

    if (currentQuestion.Result == null) {

        return (
            <div className="row mt-3">
                <div className="col-sm-7 ">
                    <div className="alert alert-danger " role="alert">
                        <div>
                            <strong>{testName}</strong>
                        </div>

                        <div className="d-flex flex-row mt-3">
                            <div>{currentTest.Error}</div>
                        </div>
                    </div>
                </div>


            </div>);
    }
    

    let icon = null;
    let messageClass;
    if (currentQuestion.Result != null) {
        
        
        if (currentQuestion.Result.StudyMode
            || currentQuestion.Result.CanSubmit) {
            if (!currentTest.Status || currentTest.Status === TestResultStatus.NotSet || currentTest.Status === TestResultStatus.Incomplete) {
                messageClass = "alert max-col-width alert-success"
            }
            else {
                messageClass = "alert max-col-width alert-info"
            }
        }
        else {
            messageClass = "alert max-col-width alert-info"
        }

        
        return (<>

            {!currentQuestion.Result.StudyMode &&
                <div className="row mb-4 mt-3">
                    <div className="col d-flex align-items-start">
                        <div className="mr-2">
                            <TestStatusIcon status={currentTest.Status} />
                        </div>


                        <div className="mt-1">
                            <strong>{currentTest.Name}</strong>
                        </div>
                    </div>
                </div>
            }

            <div className={messageClass}>
                {icon}
                <div dangerouslySetInnerHTML={{ __html: currentQuestion.Result.Message }} ></div>
            </div>

            {!currentQuestion.Result.StudyMode &&
                <button className="btn btn-inverse btn-primary mt-2 mr-3" onClick={() => reloadTestHandler()} >
                    {t.getText("test.retry")}
                </button>
            }

            {currentQuestion.Result.StudyMode &&
                <button onClick={closeTest} className="btn btn-primary mt-2" >
                    {t.getText("close")}
                </button>
            }
            {/* else */}
            {!currentQuestion.Result.StudyMode && currentQuestion.Result.CanSubmit &&
                <button onClick={submittestToServer} className="btn btn-primary mt-2" >
                    {t.getText("submit")}
                </button>
            }

        </>);
    }
}



export default McTestNoData;