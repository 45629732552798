//@ts-check
import React from "react";
import { ModalDialog } from "components/OnlineModal.js";


const ShowImage = (url, title,t)=>{
    ModalDialog({
        size: "lg",
        bodyClass: 'modal-body',
        buttons: [{
            className: 'btn btn-primary btn-small',
            text: t.getText('close'),
            type: 'dismiss'
        }],
        headerClass: 'modal-header-default',
        message: <img alt="" style={{margin:'auto'}} src={url} />,
        title: title
    });
}


export default ShowImage;