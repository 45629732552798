//@ts-check
import React, { useState } from "react";

import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";

import classes from './mail.module.scss';

/**
 *
 * @param {{
    currentProject:any,
    mailList:any[],
    handleClick:Function,
    handleDelete:Function
}} props
 */
const MailHeaderListing = ({ currentProject, mailList, handleClick, handleDelete }) => {

    const { languageService: t } = useI18n();

    const [filterBy, setFilterBy] = useState('');
    const [emails, setEmails] = useState(mailList);

    const iconTypes = {
        opened: <LnIcon className={["mr-2 filter-green", classes['receiver-icon']].join(' ')} name="email-read-icon" />,
        sent: <LnIcon className={["mr-2", classes['receiver-icon']].join(' ')} name="email-unread-icon" />,
        unsent: <LnIcon className={["mr-2", classes['spinner-icon']].join(' ')} name="spinner" />,
        errorsending: <LnIcon className={["mr-4", classes['receiver-icon']].join(' ')} name="error" />
    };

    const filter = (e) => {
        const t = e.target.value;
        setFilterBy(t);

        if (!t) {
            setEmails(mailList);
            return;
        }

        const filtertext = filterBy.toLocaleLowerCase();
        const filtered = mailList.filter(m => ( (m.MailSubject + m.Note + m.Receiver?.Mail).toLowerCase().includes(filtertext)) );
        setEmails(filtered);
    }


    return (<>
        <div className="input-group pl-2 pr-2 mb-3">
            <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                    <LnIcon className={[' small', classes['icon']].join(' ')} name="search-icon" />
                </span>
            </div>
            <input type="search" className="form-control" placeholder={t.getText('filter')} aria-label={t.getText('filter')} onChange={filter} />
        </div>
        {emails.map(m => {
            return (
                <div className={["position-relative d-flex flex-column pl-4 pr-4 pb-2 pt-2", m.Status === 'opened' ? "bg-primary-lightest" : "", currentProject && currentProject.Id === m.Id ? 'list-item-active' : ''].join(' ')} key={m.Id}>
                    <button type="button" className={classes.deleteButton + " btn btn-link"} title={t.getText('delete')} onClick={() => handleDelete(m.Id)}>
                        <LnIcon className={['small', classes['icon']].join(' ')} name="delete" /></button>
                    <div className="d-flex flex-row w-100">
                        {m.Status && (iconTypes[m.Status] || <LnIcon className={["mr-2", classes['spinner-icon']].join(' ')} name="spinner" />)}

                        <div className="d-flex flex-column w-100">
                            <div className="font-weight-bold word-break-word" onClick={() => handleClick(m.Id)}>
                                {m.MailSubject.trim() || t.getText('notset')}
                            </div>
                            <div className="d-flex flex-column pointer word-break-word" onClick={() => handleClick(m.Id)}>
                                {m.Note && m.Note !== m.MailSubject ? <small>
                                    {m.Note}
                                </small> : null}
                                {m.Receiver && m.Receiver.Id ? <small>
                                    {m.Receiver.FullName + ' - ' + m.Receiver?.Mail}
                                </small> : null}
                                <small>
                                    {t.getShortDateTimeString(m.Queued || m.Created)}
                                </small>
                            </div>

                        </div>
                    </div>
                </div>)
        })}
    </>)

}

export default MailHeaderListing;
