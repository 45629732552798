//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";
import { useI18n } from "components/lni18n";
import ScrollContent from "components/ScrollContent";
import { NavLink, TabContent, TabPane, Progress } from "reactstrap";
import DocBoxRoot from "./DocBoxRoot";

import classes from './DocBox.module.scss';

const DocBox = (props) => {

    const dispatch = useDispatch();
    const activeTab = useTypedSelector(state => state.location.payload.type);
    const docbox = useTypedSelector(state => state.docbox);
    const lastFeature = useTypedSelector(state => state.layout.lastFeature);
    const { currentLanguage, languageService: t } = useI18n();

    /**
     * @type {import('features/General/DocBox/DocBox').DocBoxRootFolderDto}
     */
    const root = docbox[activeTab];


    if (root == null) {
        return <ScrollContent scrollname="docbox">
            <div className="card-box big-card-box">
                <WaitRipple />
            </div>
        </ScrollContent>
    }

    const navItems = [
        <NavLink
            key="Start"
            className={[activeTab === 'Start' ? 'active ' : ''].join(' ')}
            onClick={() => { toggleTab('Start'); }}
        >
            {t.getText("docbox.my")}
        </NavLink>,
        <NavLink
            key="Shared"
            className={[activeTab === 'Shared' ? 'active ' : ''].join(' ')}
            onClick={() => { toggleTab('Shared'); }}
        >
            {t.getText("docbox.shared")}
        </NavLink>,
        <NavLink
            key="Trash"
            className={[activeTab === 'Trash' ? 'active ' : ''].join(' ')}
            onClick={() => { toggleTab('Trash'); }}
        >
            {t.getText("wastebasket")}
        </NavLink>,
        <NavLink
            key="SharedTrash"
            className={[activeTab === 'SharedTrash' ? 'active ' : ''].join(' ')}
            onClick={() => { toggleTab('SharedTrash'); }}
        >
            {t.getText("docbox.shared.trash")}
        </NavLink>
    ];

    const getManual = () => {
        if (currentLanguage.Short === 'sv') {
            window.open('https://static.laranara.se/manuals/Dox_sv.pdf');

        } else {
            window.open('https://static.laranara.se/manuals/Dox_en.pdf');
        }
    }

    const goBack = () => {
        if (lastFeature == null) {
            window.history.back();
        }
        else {
            dispatch({ type: lastFeature.type, payload: lastFeature.payload });
        }
    }

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            dispatch({ type: "GENERAL_DOCBOX", payload: { type: tab } })
        }
    }



    return (
        <ScrollContent scrollname="docbox">
            <div className="card-box big-card-box">
                <div className={["card-header ln-nostyle d-flex justify-content-between", classes.noSidePadding].join(' ')}>
                    <h3>
                        {t.getText('docbox.menu')}
                    </h3>
                    {(root.UsedStorage) && <div className="d-flex flex-column" title={root.Message}>
                        <span className={classes.storageLabel}>
                            <span className={classes.smallHide}>{t.getText('used.storage')}:</span>
                            {root.UsedStorage}/{root.MaxStorage}
                        </span>
                        <Progress value={root.PercentageUsed} max="100" />

                    </div>}
                    <div className={["text-right", classes.headerBtnWrapper].join(' ')}>
                        <button type="button" className="btn btn-inverse btn-small mb-2" onClick={() => getManual()}>{t.getText('manual')}</button>
                        <button id="backfromDocBox" type="button" className="btn btn-inverse btn-small ml-4" onClick={() => goBack()}>{t.getText('back')}</button>
                    </div>
                </div>


                <div className={["card-body pt-5", classes.noSidePadding].join(' ')}>
                    <div className="mb-3 custom-tab-heading-list">
                        {navItems}
                    </div>

                    <TabContent className={classes['tab-content']} activeTab={activeTab}>
                        <TabPane tabId="Start">
                            <DocBoxRoot type="Start" />
                        </TabPane>
                        <TabPane tabId="Shared">
                            <DocBoxRoot type="Shared" />
                        </TabPane>
                        <TabPane tabId="Trash">
                            <DocBoxRoot type="Trash" />
                        </TabPane>
                        <TabPane tabId="SharedTrash">
                            <DocBoxRoot type="SharedTrash" />
                        </TabPane>
                    </TabContent>
                </div>
            </div>

        </ScrollContent>)



}


export default DocBox;
