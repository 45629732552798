//@ts-check
import React from "react";
import { useI18n } from "components/lni18n";
import MailMeta from "components/Mail/MailMeta";
import ReceiversText from "./ReceiversText";
import { UncontrolledAlert } from 'reactstrap';
import { endPoint } from "AppConstants";
import FileIcon from "components/FileIcons/FileIcon";
import DetailReceiverModal from "./RecieverDetails/DetailReceiverModal";

import classes from './classmail.module.scss';

/**
 * ClassMailDetail
 * @module features/classes/ClassItems/ClassMail/ClassMailDetail
 * @return a detail view of a mail.
 * @param {{
    mailData: import("./ClassMail").BulkMailProjectDetailDto,
    handleDelete: Function,
    closeForm: Function,
}} props
 */
const ClassMailDetail = ({ mailData, handleDelete, closeForm }) => {

    const {  languageService: t } = useI18n();

    const selectedTeachers = mailData.Teachers.filter(r => r.Checked);
    const selectedStudents = mailData.Students.filter(r => r.Checked);
    const selectedReceivers = { Students: selectedStudents, Teachers: selectedTeachers };

    return (<>
        <div className="d-flex mb-4">
            <div className="">
                <div className={[ classes[ 'detail-label' ] ].join(' ')}>
                    {t.getText('mail.reciever')}
                </div>

                <ReceiversText userList={selectedReceivers} t={t} />
            </div>
            <div className="ml-auto">
               <DetailReceiverModal mailData={mailData} />
            </div>

           

        </div>

        {mailData.JobErrors &&  mailData.JobErrors.length > 0 && (
                <UncontrolledAlert color="danger" className="mb-4">
                    <strong>{t.getText("mail.sending.error")}</strong>
                    {mailData.JobErrors.map( (e,i)=> (
                        <div className="row mb-3" key={i}>
                            <div className="overflow-hidden col-sm-4">{e.MailAddress}</div>
                            <div className="col-sm-8 preserve-white">{e.Errors}</div> 
                        </div>) )}
                </UncontrolledAlert>
            )}

        <div className="row">
            <div className={[ "col-sm-12 mb-4" ].join(' ')}>
                <div className={[ "card p-4" ].join(' ')}>
                    <h3>
                        {mailData.MailSubject}
                    </h3>
                    <MailMeta mailData={mailData} t={t} />
                    <hr />
                    <div>
                        <p dangerouslySetInnerHTML={{ __html: mailData.MailBody }}></p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className={[ "col-sm-12 mb-4", "d-flex flex-column" ].join(' ')}>
                {mailData.Attachments.map(f => {
                    return (
                        <a key={f.Id} className="mb-4" href={endPoint.API_HOST + f.Url}>
                            <FileIcon className={[ classes[ 'file-icon' ] ].join(' ')} size="512px" name={(f.Name || '').split('.').pop()} />
                            <span>
                                {f.Name}

                            </span>
                        </a>
                    )
                })}
            </div>
        </div>
        <div className="row">
            <div className={[ "col-sm-12", classes[ 'detail-label' ] ].join(' ')}>
                {t.getText('notes')} <br /><small>{t.getText('mail.notes.not.in.mail')}</small>
            </div>
        </div>
        <div className="row">
            <div className={[ "col-sm-12 mb-5" ].join(' ')}>
                {mailData.Note}
            </div>
        </div>
        <div className={["pb-4 pt-4 border-top", classes['sticky-footer']].join(' ')}>
            <div className="mb-4 d-flex justify-content-between">
            <button className="btn btn-inverse btn-small"
                    onClick={() => closeForm()}
                    type="button">{t.getText('close')}
                </button>
                <button className="btn btn-inverse btn-danger btn-small ml-5" type="button" onClick={() => handleDelete(mailData.Id)}>{t.getText('delete')} </button>
            </div>
            <div className="mb-4 d-flex justify-content-start">
            {mailData.Queued && mailData.AutoDelete && mailData.TimeToLive ? <small><strong>{t.getText('mail.autodelete', [ t.getShortDateTimeString(mailData.TimeToLive) ])}</strong></small> : null}
        </div>
        </div>
    </>)

}
export default ClassMailDetail;
