//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import { useI18n } from "components/lni18n";
import useTypedSelector from "utils/useTypedSelector";
import { imagePickerDuck } from "./ImagePickerDuck";
import ImageList from "./ImageList";
import SelectedImage from "./SelectedImage"
import ImageUpload from "./ImageUpload";
import ImageExternUrl from "./ImageExternUrl";
import { endPoint } from "AppConstants"

/**
 * 
 * @param {{onSubmit: Function}} props
 */
const ImagePicker = ({ onSubmit }) => {

  const dispatch = useDispatch();
  const { languageService: t } = useI18n();
  const { filteredImages, imageData, tab, selectedImage } = useTypedSelector(state => state.imagepicker);
  const { user: currentUser } = useTypedSelector(state => state.login);


  

  if (!imageData) {
    return null;
  }


  /**
   * 
   * @param {'browse' | 'selected' | 'web' | 'upload'} tab 
   */
  const setTab = (tab) => {
    dispatch(imagePickerDuck.setTab(tab));
  }

  const menuItems = [

    <button className={tab === "browse" ? "active" : ""} key="browse" onClick={() => setTab("browse")} >
      {t.getText("browse")}
    </button>,


    <button className={tab === "web" ? "active" : ""} key="web" onClick={() => setTab("web")} >
      {t.getText("url")}
    </button>,

    <button className={tab === "upload" ? "active" : ""} key="upload" onClick={() => setTab("upload")} >
      {t.getText("addpict")}
    </button>,

    <button disabled={selectedImage === null} className={tab === "selected" ? "active" : ""} key="selected" onClick={() => setTab("selected")} >
      {t.getText("insertimage")}
    </button>


  ];

  const setSelectedImage = (id) => {
    dispatch(imagePickerDuck.setCurrentImage(null));
    dispatch(imagePickerDuck.setSelectedImage(id));
    dispatch(imagePickerDuck.getImageInfo(id, currentUser.Id));
    dispatch(imagePickerDuck.setTab('selected'));
  }

  const handleUserChange = (event) => {
    dispatch(imagePickerDuck.setSelectedUser(event.target.value));
  }

  /**
   * 
   * @param {{ imageAlign: string, selectedImage: string | null, selectedUrl: string | null }} data 
   */
  const onSubmitImage = (data) => {
    let imageData = {
      imageAlign: data.imageAlign,
      imageId: data.selectedImage
    }
    if (data.selectedImage) {
      imageData.url = endPoint.GET_IMAGE_URL(data.selectedImage);
    } else if (data.selectedUrl) {
      imageData.url = endPoint.GET_IMAGE_PROXY_URL(data.selectedUrl);
    }
    else {
      throw new Error("no image at all...!");
    }

    onSubmit(imageData);
  }

  return <div>
    <div className="custom-tab-heading-list">
      {menuItems}
    </div>


    {tab === 'browse' && <div className="mt-4">

      <select className="col-12 col-sm-6  form-control" id="classSelect" defaultValue={""} onChange={(e) => handleUserChange(e)}>
        <option key={'all'} value={""}>{t.getText("images.all")}</option>
        <option key={'mine'} value={currentUser.Id}>{t.getText("images.mine")}</option>
        {imageData.Users.map((u) => {
          return (
            <option key={u.Id} value={u.Id}>{u.FullName}</option>
          )
        })}
      </select>

      <ImageList images={filteredImages} onClick={setSelectedImage} /></div>
    }

    {tab === 'selected' && <SelectedImage onSubmit={onSubmitImage} />}
    {tab === 'upload' && <ImageUpload />}
    {tab === 'web' && <ImageExternUrl onSubmit={onSubmitImage} />}


  </div>
}

export default ImagePicker;
