//@ts-check
import React from "react";
import { useI18n } from "components/lni18n";
import { Progress } from "reactstrap";
import { endPoint } from "AppConstants";

import ScrollContent from "components/ScrollContent";
import WaitRipple from "components/WaitRipple";
import Link from "redux-first-router-link";

import { ModalDialog } from "components/OnlineModal";
import LnIcon from "components/LnIcon";
import LnResultIcon from "components/LnResultIcon";
import useTypedSelector from "utils/useTypedSelector";
import { TestResultStatus } from "features/course/part/test/TestEnums";

const CourseOverview = () => {

    const { languageService: t } = useI18n();
    const data = useTypedSelector(state => state.classData.classOverview);
    const user = useTypedSelector(state => state.login.user);


    const goBack = () => {
        window.history.back();
    }

    if (!data) {
        return (
            <ScrollContent scrollname="personal-data-policy">
                <div className="card-box big-card-box">
                    <WaitRipple />
                </div>
            </ScrollContent>

        )
    }

    const displayApproved = (data) => {
        ModalDialog({
            bodyClass: 'modal-body',
            buttons: [{
                className: 'btn btn-primary btn-small',
                text: t.getText('close'),
                type: 'dismiss'
            }],
            headerClass: 'modal-header-default',
            message: (<div className="">
                <strong>{data.Approved.By.FullName}</strong><br />
                <em>{t.getShortDateString(data.Approved.On)}</em>
            </div>),
            title: t.getText('approvedby')
        })
    }


    return <ScrollContent scrollname="course-overview">
        <div className="card-box big-card-box">
            <div className="card-header ln-nostyle d-flex justify-content-between">
                <h3>
                    <LnIcon className="header-icon" name="eye-icon" />
                    {t.getText('course.summary')} 
                </h3>
                <div>
                    <button id="backfromCourseOverView" type="button" className="btn btn-inverse btn-small mr-4" onClick={() => goBack()}>{t.getText('back')}</button>
                </div>
            </div>

            <div className="card-body pt-5">
                <table className="table table-small ln-table-responsive">
                    <thead>
                        <tr role="row">
                            <th role="columnheader" className="th-clean">
                                {t.getText("name")}
                            </th>
                            <th role="columnheader" className="th-clean">
                                {t.getText("startdate")}
                            </th>
                            <th role="columnheader" className="th-clean">
                                {t.getText("enddate")}
                            </th>
                            <th role="columnheader" className="th-clean">
                                {t.getText("finished.tests")}
                            </th>
                            <th role="columnheader" className="th-clean text-center">
                                {t.getText(TestResultStatus.ApprovedByTeacher)}
                            </th>
                            <th role="columnheader" className="th-clean text-center">
                                {t.getText("diploma")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(d => {

                            return <tr role="row" key={d.Class.Id}>
                                <td>
                                    <label className="td-label">{t.getText('name')}</label>
                                    <Link className="px-4 pt-2 pb-3" to={{ type: "CLASS", payload: { classid: d.Class.Id } }}>
                                            {d.Class.Name}
                                    </Link>
                                    
                                </td>
                                <td>
                                    <label className="td-label">{t.getText('startdate')}</label>
                                    {t.getShortDateString(d.Class.StartDate)}
                                </td>
                                <td>
                                    <label className="td-label">{t.getText('enddate')}</label>
                                    {t.getShortDateString(d.Class.EndDate)}
                                </td>
                                <td>
                                    <label className="td-label">{t.getText('finished.tests')}</label>
                                    <span className="d-flex align-items-center">
                                        <Progress className="w-100 mr-4" color="success" value={Math.round(100 * d.Tests.Ready / d.Tests.Total)}></Progress>
                                        <span>
                                            {Math.round(100 * d.Tests.Ready / d.Tests.Total)}%

                                        </span>
                                    </span>
                                </td>
                                <td>
                                    <label className="td-label">{t.getText('approvedbyteacher')}</label>
                                    {d.Approved.Status ? <span className="d-flex justify-content-center"><button type="button" className="btn btn-link btn-small" onClick={() => displayApproved(d)}><LnResultIcon status={TestResultStatus.ApprovedByTeacher} /></button></span> : " "}
                                </td>
                                <td>
                                    <label className="td-label">{t.getText('diploma')}</label>
                                    {d.Approved.Status && d.HasDiplomaTmplt ? <span className="d-flex justify-content-center">
                                        <a href={endPoint.API_HOST + endPoint.GET_CLASS_CERTIFICATE_URL(user.Id, d.Class.Id)} target="_blank" rel="noopener noreferrer">
                                            <LnIcon name="course-certi" />
                                        </a>
                                    </span> : " "}
                                </td>
                            </tr>

                        })}
                    </tbody>
                </table>
            </div>

        </div>
    </ScrollContent>

}


export default CourseOverview