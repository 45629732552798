//@ts-check
import React, { useEffect, useCallback, useRef } from "react";
import { useI18n } from "components/lni18n";
import { CanvasDraw } from "./CanvasDraw";
import drawingTestService from "./DrawingTestService";
import LnIcon from "components/LnIcon";
import useTypedSelector from "utils/useTypedSelector";

/**
 * 
 * @param {{fileUrl : string, state: import('./DrawingTest').DrawingState,
 *  readOnly: boolean, handleCurveClosed: Function, children: any}} props 
 */
const DrawingTestCanvas = ({ fileUrl, state, readOnly, handleCurveClosed, children }) => {

    const { languageService: t } = useI18n();
    const testState = useTypedSelector(state => state.drawingTest);

    /**
     * @type {{current: HTMLCanvasElement} }
     */
    let canvas = useRef();

    /**
     * @type {{current: HTMLDivElement} }
     */
    let canvasWrap = useRef();

    useEffect(() => {
        drawingTestService.setState(state);
    // eslint-disable-next-line 
    }, [])


    const mouseDown = useCallback((e) => {

        if (e.which !== 1) {
            return false;
        }
        const pt = getPos(e);
        const model = drawingTestService.handleMousePress(pt);
        if (model) {
            const canvas = e.target;
            const context2 = canvas.getContext("2d");
            CanvasDraw(context2, canvas.width, canvas.height, model, readOnly, false);
        }
    }, [readOnly]);

    const mouseUp = useCallback((e) => {

        if (e.which !== 1) {
            return false;
        }
        const { model, closedStateChanged } = drawingTestService.handleMouseUp();
        if (model) {
            const canvas = e.target;
            const context2 = canvas.getContext("2d");
            CanvasDraw(context2, canvas.width, canvas.height, model, readOnly, false);
            if (closedStateChanged) {
                handleCurveClosed(model.Closed, model.Points);
            }

        }
    }, [readOnly, handleCurveClosed]);

    const mouseMove = useCallback((e) => {

        if (e.which !== 1) {
            return false;
        }
        const pt = getPos(e);
        const model = drawingTestService.handleMouseMove(pt);
        if (model) {
            const canvas = e.target;
            const context2 = canvas.getContext("2d");
            CanvasDraw(context2, canvas.width, canvas.height, model, readOnly, false);
        }
    }, [readOnly]);

    const mouseOut = useCallback((e) => {
        const pt = getPos(e);
        const model = drawingTestService.handleMouseOut(pt);
        if (model) {
            const canvas = e.target;
            const context2 = canvas.getContext("2d");
            CanvasDraw(context2, canvas.width, canvas.height, model, readOnly, false);
        }
    }, [readOnly]);

    const doubleClick = useCallback((e) => {
        const model = drawingTestService.handleDblClick();
        if (model) {
            const canvas = e.target;
            const context2 = canvas.getContext("2d");
            CanvasDraw(context2, canvas.width, canvas.height, model, readOnly, false);
            handleCurveClosed(true, model.Points);
        }
    }, [readOnly, handleCurveClosed]);

    const focus = useCallback((e) => {
        const model = drawingTestService.setHandlesState(true);
        if (model) {
            const canvas = e.target;
            const context2 = canvas.getContext("2d");
            CanvasDraw(context2, canvas.width, canvas.height, model, readOnly, false);
        }
    }, [readOnly]);

    const blur = useCallback((e) => {
        const model = drawingTestService.setHandlesState(false);
        if (model) {
            const canvas = e.target;
            const context2 = canvas.getContext("2d");
            CanvasDraw(context2, canvas.width, canvas.height, model, readOnly, false);
        }
    }, [readOnly]);

    useEffect(() => {
        if (canvas != null && canvasWrap != null && canvasWrap.current) {
            var img = new Image();
            img.onload = () => {
                canvas.current.width = img.width;
                canvas.current.height = img.height;
                canvasWrap.current.style.background = 'url(' + fileUrl + ') no-repeat';
                const model = drawingTestService.getModel();
                if (model.Points && model.Points.length > 0) {
                    const context2 = canvas.current.getContext("2d");
                    // model.Closed = true;
                    CanvasDraw(context2, canvas.current.width, canvas.current.height, model, readOnly, false);
                }
            }
            img.src = fileUrl;
            const currentCanvas = canvas.current;
            if (!readOnly) {
                canvas.current.addEventListener('dblclick', doubleClick);
                canvas.current.addEventListener('mousedown', mouseDown);
                canvas.current.addEventListener('mouseup', mouseUp);
                canvas.current.addEventListener('mousemove', mouseMove);
                canvas.current.addEventListener('mouseout', mouseOut);
                canvas.current.addEventListener('focus', focus);
                canvas.current.addEventListener('blur', blur);

                return () => {
                    currentCanvas.removeEventListener('mousedown', mouseDown);
                    currentCanvas.removeEventListener('mouseup', mouseUp);
                    currentCanvas.removeEventListener('mousemove', mouseMove);
                    currentCanvas.removeEventListener('mouseout', mouseOut);
                    currentCanvas.removeEventListener('focus', focus);
                    currentCanvas.removeEventListener('blur', blur);
                    currentCanvas.removeEventListener('dblclick', doubleClick);
                }
            }
        }
    }, [canvas, canvasWrap, blur, doubleClick, fileUrl, focus, mouseDown, mouseMove, mouseUp, mouseOut, readOnly]);

    function getPos(event) {
        const rect = event.target.getBoundingClientRect();
        return {
            X: event.clientX - rect.left,
            Y: event.clientY - rect.top,
        }
    }

    const handleErase = useCallback(() => {
        const model = drawingTestService.erasePath();
        if (model) {
            const context2 = canvas.current.getContext("2d");
            CanvasDraw(context2, canvas.current.width, canvas.current.height, model, readOnly, false);
        }
        handleCurveClosed(false, null);
    // eslint-disable-next-line 
    }, []);

    return (
        <div>
            <div className="mt-3">
                <div ref={canvasWrap} className="position-relative">
                    <canvas tabIndex={-1} ref={canvas} />
                    {testState.splineOk && <LnIcon className="done-icon-left-top natural" name="checked" />}
                </div>
                <div >
                    {children}
                    {!readOnly && <button onClick={handleErase} className="btn btn-inverse  mr-2" >{t.getText("drawingtestadmin.btnerase")}</button>}

                </div>
            </div>
        </div>)
}


export default DrawingTestCanvas;

