//@ts-check
import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import Link from "redux-first-router-link";
import classes from './layout.module.scss';
import { useDispatch } from "react-redux";
import { layoutDuck } from "features/layout/LayoutDuck";
import { UserMode } from "features/User/user.enums";

const SideBar = (props) => {

    const user = useTypedSelector(state => state.login.user);
    const sideBarForced = useTypedSelector(state => state.layout.sideBarForced);
    const dispatch = useDispatch();

    const action = user.CurrentClass ? { type: 'CLASS', payload: { classid: user.CurrentClass } } 
        : (user.UserState > UserMode.teacher ? { type: 'ADMIN'} : { type: 'ROOT'} )



    return (
        <>
            <div className={["d-print-none", classes.sideBar, sideBarForced ? classes.forcedState : ""].join(' ')} >
                <div role="button" className={"pointer " + classes.sidebarClose} onClick={() => dispatch(layoutDuck.toggleSideBarState({}))} >
                    <i className="dark-caret-point-left m-auto"></i>
                </div>
                <Link to={action} className="logoBox">
                    
                </Link>
                
                <div className={classes.menuBox}>
                    {props.children}
                </div>

            </div>
            <div className={"d-print-none " + (sideBarForced ? classes.sidebarOverlay : "")} onClick={() => dispatch(layoutDuck.toggleSideBarState({}))} ></div>
        </>);

}



export default SideBar;