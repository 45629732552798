//@ts-check
import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Draggable from "react-draggable"
import useTypedSelector from "utils/useTypedSelector";
import { useCallback } from "react";
import { permissionsDuck } from "features/Permissions/PermissionDuck";
import { useDispatch } from "react-redux";
import PermissionRow from "./PermissionRow";
import { useI18n } from "components/lni18n";
import PermissionsTypeahead from "./PermissionsTypeahead";

// ["read", "edit", "delete", "owner", "admin"]
/**
 * 
 * @param {{  showData: import('./Permissions').DialogData, 
            urlCreator: import('./Permissions').PermissionsUrlCreator }} props 
 */
const PermissionsDialog = ({ showData, urlCreator }) => {

    const dispatch = useDispatch();
    const permissions = useTypedSelector(state => state.permissions);
    const [open, setOpen] = useState(false);


    const closeHandler = useCallback(() => {
        setOpen(false);
        showData.onClose({
            type: showData.type,
            objectId: showData.objectId,
            haveSubject: (permissions.subjects.Users.length + permissions.subjects.Groups.length > 0)
        });
    }, [permissions, showData]);

    useEffect(() => {
        setOpen(showData.open);
        if (showData.open) {
            permissionsDuck.getListData(dispatch, urlCreator.GetstartUrl(showData.objectId, showData.type));
        }
    }, [showData, dispatch, urlCreator]);

    const { languageService: t } = useI18n();


    if (!permissions || !permissions.subjects) {
        return null;
    }

    /**
     * @param { import('types/types').IdAndNameAndTypeDto } item
     */
    const itemChosen = (item) => {

        /**
         * @type import('features/Permissions/Permissions').SetPermissionDto
         */
        const data = {
            ObjectId: showData.objectId, ObjectType: showData.type,
            Flag: 'read', UserOrGroupId: item.Id,
            Value: true, SetForAllsubItems: showData.setForallSubitems
        };
        const url = urlCreator.SetPermissionUrl()
        permissionsDuck.addPermission(dispatch, url, data, item.type);
    }

    const onChange = (e, userOrGroupId, subjectType, name, deleteRow) => {

        /**
         * @type import('features/Permissions/Permissions').SetPermissionDto
         */
        const data = {
            ObjectId: showData.objectId, ObjectType: showData.type,
            Flag: name, UserOrGroupId: userOrGroupId,
            Value: e.target.checked, SetForAllsubItems: showData.setForallSubitems
        };

        if (deleteRow === true) {
            permissionsDuck.deletePermission(dispatch, urlCreator.DeletePermissionUrl(), data, subjectType);
        }
        else {
            permissionsDuck.setPermission(dispatch, urlCreator.SetPermissionUrl(), data, subjectType);
        }

    }

    let index = 0;

    return (
        <Draggable handle=".modal-header" bounds={{left: -1024, top: 0, right: 1024, bottom: 1024}}>
            <Modal role="dialog" size='lg' isOpen={open} toggle={() => closeHandler()} backdrop={'static'}>
                <ModalHeader toggle={closeHandler}>{t.getText("permissions")}</ModalHeader>
                <ModalBody tabIndex={-1}>

                    <div className="form-group mb-5">
                        <label htmlFor="receiver-select">{t.getText('add')}</label>
                        <PermissionsTypeahead itemChosen={itemChosen} queryUrlgetter={(text) => urlCreator.GetSearchUrl(text, showData.type, showData.objectId)} />
                    </div>

                    {permissions.subjects.Users.map((s) => (
                        <PermissionRow subjectType={'user'} onChange={onChange} key={index++} setFlags={showData.setFlags} permission={s} />
                    ))
                    }

                    {permissions.subjects.Groups.map((s) => (
                        <PermissionRow subjectType={'group'} onChange={onChange} key={index++} setFlags={showData.setFlags} permission={s} />
                    ))
                    }


                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={() => closeHandler()}>{t.getText("close")} </button>
                </ModalFooter>
            </Modal>
        </Draggable>
    )


}


export default PermissionsDialog;