// @ts-check
import React from 'react';
// eslint-disable-next-line
import dayjs from "dayjs";
import LnIcon from "components/LnIcon";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import 'assets/scss/_datepicker.scss';
import TimeKeeper from 'react-timekeeper';
import { useI18n } from "components/lni18n";
/**
 * DateTimePicker
 * @module components/DateTimePicker/DateTimePicker
 * @return a Date selected by the user.
 * @param {{
    toggleText: string | React.ReactElement | React.ReactFragment,
    startTime: dayjs.Dayjs,
    onTimeChanged: Function

}} props
 */
const TimePicker = ({ toggleText, startTime, onTimeChanged }) => {

    const { languageService: t } = useI18n();

    return <UncontrolledDropdown direction="up" >
        <DropdownToggle tag="button" className="btn btn-link d-flex align-items-center"  >
            {toggleText}<LnIcon name="time" className="ml-3 small" />
        </DropdownToggle>
        <DropdownMenu>
            <DropdownItem tag="div">
                <button type="button" className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
            </DropdownItem>
            <TimeKeeper
                time={startTime.format("HH:mm")}
                onChange={(newTime) => onTimeChanged(newTime)}
                hour24Mode={t.localeUses24HourTime()}
                switchToMinuteOnHourSelect={true}
                coarseMinutes={5}
                forceCoarseMinutes={true}
            />
        </DropdownMenu>
    </UncontrolledDropdown>



}


export default TimePicker;