//@ts-check
import React from "react";
import { shallowEqual } from "react-redux";
import { UncontrolledAlert } from 'reactstrap';
import { useI18n } from "components/lni18n";
import TestTop from "../test/TestTop";
import { courseDuck } from "features/course/CourseDuck";
import useTypedSelector from "utils/useTypedSelector";
import { TestResultStatus } from "../test/TestEnums"


const McTestTop = (props) => {

    const currentPart = courseDuck.useCurrentPart();
    const currentTheme = courseDuck.useCurrentTheme();
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const { currentTest, currentMCQuestion } = useTypedSelector(state => state.mctest, shallowEqual);
    const currentBaseTest = courseDuck.useCurrentTest();
    const { Status: status } = currentBaseTest;
    const { languageService: t } = useI18n();


    return (
        <>
            {currentMCQuestion.data && currentMCQuestion.data.Index === 0 && currentTest.Tries != null
                && <UncontrolledAlert color="warning" >
                    {currentTest.Tries.Message}
                </UncontrolledAlert >
            }

            {(status === TestResultStatus.Passed ||
                status === TestResultStatus.RecievedByTeacher ||
                status === TestResultStatus.ApprovedByTeacherButCanbeBetter ||
                status === TestResultStatus.ApprovedByTeacher ||
                status === TestResultStatus.UnderReview)
                && <UncontrolledAlert color="success" >
                    {t.getText("mc.test.submitted")}
                </UncontrolledAlert >
            }

            <TestTop languageService={t} classId={currentClass.Id}
                themeId={currentTheme.Id} partId={currentPart.Id}  >
                {props.children}
            </TestTop>

            {currentBaseTest.StudentInstructions
                && <div className="alert border-primary max-col-width clearfix mt-3"
                    dangerouslySetInnerHTML={{ __html: currentBaseTest.StudentInstructions }} >
                </div>}

        </>)

}



export default McTestTop;