//@ts-check
import { createSlice } from '@reduxjs/toolkit'

/**
 * @type {  import('./Layout').LayoutState }
 */
const initialState = {
    sideBarForced: false,
    lastFeature: null
};

const LayoutSlice = createSlice({
    name: 'layout',
    initialState: initialState,
    reducers: {

        /**
         * @param {{payload: {}}} action
         */
        toggleSideBarState(state, action) {
            state.sideBarForced = !state.sideBarForced;
        },

        /**
         * @param {{ payload: import('./Layout').routeState}} action
         */
        setLastFeature(state, action) {
            state.lastFeature = action.payload;
        },


      
    }
});

export const  layoutDuck = { ...LayoutSlice.actions }

export default LayoutSlice.reducer;