//@ts-check
import React from "react";
import LnResultIcon from "components/LnResultIcon";
import classes from './tests.module.scss';
import LnIcon from "components/LnIcon";
import { useI18n } from "components/lni18n";


function DisabledTest ({test}){

    const { languageService: t } = useI18n();

    return  <div className={[classes['test-list-item'], 'mb-3'].join(' ')}>
  <div className={classes['icon-text']}>
      <LnResultIcon className="mr-3" status={test.Status} />
      {test.Name}
      <LnIcon name="locked" className="ml-3 icon-small" />

  </div>
  <div>
      <small className="text-muted">
          {t.getText("fulfill.lectures.bofore.test")}
      </small>
  </div>

</div>

}

export default DisabledTest