//@ts-check
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { anonPaths } from "AppConstants"
import { LOGO } from "assets/images";
import Loader from "./components/loader";
import useTypedSelector from "utils/useTypedSelector";
import LoginComponent from "./features/login/LoginComponent";
import bootstrap from "./utils/bootstrapper";
import StudentRoot from "./features/classes/StudentRoot";
import GeneralRoot from "./features/General/GeneralRoot";
import CdnCookiePinger from "components/CdnCookiePinger";
import SearchForm from "./features/search/SearchForm";
import NotFound from "features/layout/NotFound";
import Root from "features/Root";
import SetNewPassword from "features/login/SetNewPassword";
import AdminInfo from "features/admin/AdminInfo";
import SideBar from "features/layout/SideBar";
import SideBarMenu from "features/classes/SideBarMenu/SideBarMenu";
import ContentWrapper from "features/layout/ContentWrapper";
import SiteHeader from "features/layout/SiteHeader";
import ErrorHandlerComponent from "components/ErrorHandler/ErrorHandlerComponent";
import NetworkReconnect from "features/network/NetworkReconnect";
import VerifyEmail from "features/login/VerifyEmail";
import Register from "features/login/Register";
import ReLogin from "features/login/ReLogin";


const App = props => {
    const [ready, setReady] = useState(false);
    const dispatch = useDispatch();

    const languages = useTypedSelector(state => state.text.languages);
    const user = useTypedSelector(state => state.login.user);
    const route = useTypedSelector(state => state.location.type);


    useEffect(() => {

        if (process.env.NODE_ENV !== "production") {
            window.document.title = "DEV " + window.document.title;
        }

        let routeToLoginIfNoUser = !anonPaths.find( p => route.indexOf(p) > -1);


        if (ready === true) {
            return;
        }
        async function bootUp() {


            const lang = languages
                ? languages.find(l => l.IsCurrent === true && l.Short.indexOf("x") < 0)
                : null;
            await bootstrap(dispatch, lang, user, routeToLoginIfNoUser);
            setReady(true);
        }

        bootUp();



        // eslint-disable-next-line 
    }, []);

    let rootPath = null;
    let standAlone = false;

    if (route.indexOf("ROOT") > -1) {
        rootPath = <Root />
        standAlone = true;
    }

    if (route.indexOf("CLASS") > -1) {
        rootPath = <StudentRoot />
    }

    if (route.indexOf("NOT_FOUND") > -1) {
        rootPath = <NotFound />
        standAlone = true;
    }
    
    if (route === "LOGIN") {
        rootPath = <LoginComponent />
        standAlone = true;
    }

    if (route === "LOGIN_NEW_PASSWD") {
        rootPath = <SetNewPassword />
        standAlone = true;
    }

    if (route === "LOGIN_VERIFY_EMAIL") {
        rootPath = <VerifyEmail />
        standAlone = true;
    }

    if (route === "LOGIN_REGISTER") {
        rootPath = <Register />
        standAlone = true;
    }

    if (route === "LOGIN_REGISTER_DATAPOLICY") {
        rootPath = <Register />
        standAlone = true;
    }

    if (route.indexOf("GENERAL_") > -1) {
        rootPath = <GeneralRoot />
    }

    if (route.indexOf("ADMIN") > -1) {
        rootPath = <AdminInfo />
    }


    if (ready === false) {
        return <Loader />;
    } else {

        if (!user || standAlone) {
            return rootPath;
        }

        return (
            <>
                <ErrorHandlerComponent />

                <SideBar logo={LOGO}>
                    <SideBarMenu />
                </SideBar>

                <ContentWrapper>
                    <SiteHeader />
                    {rootPath}
                </ContentWrapper>

                <CdnCookiePinger />

                <SearchForm />
                <NetworkReconnect />
                
                <ReLogin />
            </>
        );
    }
};

export default App;
