//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import { useI18n } from "components/lni18n";
import WaitRipple from "components/WaitRipple";
import ScrollContent from "components/ScrollContent";
import useTypedSelector from "utils/useTypedSelector";
import LnIcon from "components/LnIcon";
import { TabContent, TabPane } from "reactstrap";
import { NavLink } from "redux-first-router-link";
import { UserMode } from "features/User/user.enums";
import UserDataGeneral from "./UserDataGeneral";
import UserOnlineActivities from "./UserOnlineActivities";
import UserTestsWatchedLectures from "./UserTestsWatchedLectures";
import UserEmailSettings from "./UserEmailSettings";
import RootInfo from "./RootInfo";

const UserData = () => {

    const dispatch = useDispatch();
    const { userData } = useTypedSelector(state => state.user);
    const { user: currentUser } = useTypedSelector(state => state.login);
    const lastFeature = useTypedSelector(state => state.layout.lastFeature);
    const { languageService: t } = useI18n();
    const activeTab = useTypedSelector(state => state.location.payload.type);



    if (userData == null) {
        return <ScrollContent scrollname="user-data"><WaitRipple /></ScrollContent>
    }

    const navItems = [
        <NavLink
            key="personal"
            className={['white-space-nowrap', activeTab === 'personal' ? 'active ' : ''].join(' ')}
            to={{
                type: 'GENERAL_PERSONAL_SETTINGS',
                payload: { userid: userData.Id, type: "personal" }
            }}
        >
            {t.getText("general")}
        </NavLink>
    ];

    if (currentUser.Id === userData.Id || currentUser.UserState > UserMode.student) {

        navItems.push(<NavLink
            key="emailsettings"
            className={['white-space-nowrap', activeTab === 'emailsettings' ? 'active ' : ''].join(' ')}
            to={{
                type: 'GENERAL_PERSONAL_SETTINGS',
                payload: { userid: userData.Id, type: "emailsettings" }
            }}
        >
            {t.getText('email.settings')}
        </NavLink>);

        navItems.push(
            <NavLink
                key="sessions"
                className={['white-space-nowrap', activeTab === 'sessions' ? 'active ' : ''].join(' ')}
                to={{
                    type: 'GENERAL_PERSONAL_SETTINGS',
                    payload: { userid: userData.Id, type: "sessions" }
                }}
            >
                {t.getText("online")}
            </NavLink>);


        navItems.push(<NavLink
            key="progress"
            className={['white-space-nowrap', activeTab === 'progress' ? 'active ' : ''].join(' ')}
            to={{
                type: 'GENERAL_PERSONAL_SETTINGS',
                payload: { userid: userData.Id, type: "progress" }
            }}
        >
            {t.getText("results")}
        </NavLink>);

        if (userData.Root) {

            navItems.push(<NavLink
                key="root"
                className={['white-space-nowrap', activeTab === 'root' ? 'active ' : ''].join(' ')}
                to={{
                    type: 'GENERAL_PERSONAL_SETTINGS',
                    payload: { userid: userData.Id, type: "root" }
                }}
            >
                Root
            </NavLink>);
        }

    }



    const goBack = () => {
        if (lastFeature == null) {
            window.history.back();
        }
        else {
            dispatch({ type: lastFeature.type, payload: lastFeature.payload });
        }

    }

    return (
        <ScrollContent scrollname="user-data">
            <div className="card-box big-card-box">
                <div className="card-header ln-nostyle d-flex justify-content-between">
                    <h3>
                        <LnIcon className="header-icon" name="info-icon" />
                        {userData.FullName}
                    </h3>
                    <div>
                        <button id="backFromUserData" type="button" className="btn btn-inverse btn-small mr-4" onClick={() => goBack()}>{t.getText('back')}</button>
                    </div>
                </div>
                <div className="card-body pt-5">
                    <div className="mb-3 custom-tab-heading-list">
                        {navItems}
                    </div>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="personal">
                            <UserDataGeneral userData={userData} t={t} />
                        </TabPane>
                        <TabPane tabId="emailsettings">
                            {activeTab === 'emailsettings' && <UserEmailSettings currentUser={currentUser} userData={userData} t={t} />}
                        </TabPane>
                        <TabPane tabId="sessions">
                            {activeTab === 'sessions' && <UserOnlineActivities userData={userData} t={t} />}
                        </TabPane>

                        <TabPane tabId="progress">
                            {activeTab === 'progress' && <UserTestsWatchedLectures userData={userData} t={t} />}
                        </TabPane>
                        {userData.Root && <TabPane tabId="root">
                            {activeTab === 'root' && <RootInfo userData={userData} t={t} />}
                        </TabPane>
                        }
                    </TabContent>
                </div>
            </div>
        </ScrollContent>)

}


export default UserData;