//@ts-check
import React,{useState} from "react";
import WaitRipple from "components/WaitRipple"
let EditNotifications = null;

const EditNotificationsStub = (props) => {

    const [ready, setReady ] = useState(false);
    
    import("teacherFeatures").then(features => {
        EditNotifications = features.EditNotifications;
        setReady(true);
    });

    if (!ready) {
        return <WaitRipple />;
    }
    else {
        return <EditNotifications />
    }
}

export default EditNotificationsStub;
