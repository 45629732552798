//@ts-check
import React from "react";
import Link from "redux-first-router-link";
import LnResultIcon from "components/LnResultIcon";
import { browserLanguage } from "AppConstants";
import classes from './tests.module.scss';
import LnIcon from "components/LnIcon";


function StartTestLink({classId, themeId, partId, test }){


    return <Link to={{
        type: 'CLASS_THEME_PART_TEST',
        payload: {
            classid: classId,
            themeid: themeId,
            partid: partId,
            type: "runtest",
            testid: test.Id,
            testtype: test.Type
        }
    }} >
        <div className={[classes['test-list-item'], 'mb-3'].join(' ')}>
            <div className={classes['icon-text']}>
                <LnResultIcon className="mr-3" status={test.Status} /> {test.Name}

            </div>
            {test.Description && <div className="d-flex">
                <LnIcon name="alert" className="icon-small mr-3" />
                <small className="preserve-white" dangerouslySetInnerHTML={{ __html: test.Description }} >
                </small></div>}
            {test.SubmitDate && <div>
                <small className="text-muted">{new Date(test.SubmitDate).toLocaleDateString(browserLanguage, {
                    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
                    hour: 'numeric', minute: 'numeric'
                })}</small></div>}

        </div>
    </Link>

}

export default StartTestLink;