//@ts-check
import TestService from "../test/TestService";
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import dispatchService from "utils/DispatchService";
import { TestResultStatus } from "../test/TestEnums";
import { errorDuck } from "components/ErrorHandler/ErrorHandlerDuck";
import languageService from "features/language/languageService";

/**
 * @type import('./QATest').QATestState
 */
const initialState = {
    currentTest: null,
    showSubmit: false,
    canSubmit: false,
    makeStatusUpdate: false,
    timerId: -1,
    savingToserver: false,
};


const qaTestSlice = createSlice({
    name: 'qatest',
    initialState: initialState,
    reducers: {

        /**
         * 
         * @param {{payload: import('../test/Test').StartTestDto<import('./QATest').QATestData>}} action 
         */
        setCurrentQATest(state, action) {

            state.currentTest = action.payload;
            if (action.payload == null) {
                state.showSubmit = false;
            }
            else {

                var lastQuestion = state.currentTest.Data.Questions.slice(-1).pop();
                state.canSubmit = lastQuestion.Edit === true && lastQuestion.Reply && lastQuestion.Reply.length > 2;
                state.showSubmit = lastQuestion.Edit;
            }
            state.timerId = -1;
        },

        /**
        * 
        * @param {{payload: boolean}} action 
        */
        setUpdateFlag(state, action) {
            state.makeStatusUpdate = action.payload;
        },

        /**
       * 
       * @param {{payload: number}} action 
       */
        setTimerId(state, action) {
            state.timerId = action.payload;
        },


        /**
         * 
         * @param {{payload:any}} action 
         */
        resetQATest(state, action) {
            state.currentTest = null;
            state.showSubmit = false;
            state.canSubmit = false;
            state.makeStatusUpdate = false;
            state.timerId = -1;
        },


        setQuestionReply(state, action) {

            let question = state.currentTest.Data.Questions[action.payload.index];
            if (question.Edit === true) {

                if (question.Reply == null && state.makeStatusUpdate == null) { // first time to update state for this question
                    state.makeStatusUpdate = true
                }

                question.Reply = action.payload.reply;

                state.canSubmit = question.Reply && question.Reply.length > 2;


                if (action.payload.reply.length > 2) {
                    state.timerId = saveQaAnswerAtServer(state, {
                        qnum: action.payload.index,
                        reply: action.payload.reply,
                        classid: action.payload.classid,
                        testid: action.payload.testid
                    });
                }
            }
        },


        setQATestSubmitted(state, action) {

            for (let i = 0; i < state.currentTest.Data.Questions.length; i++) {
                const element = state.currentTest.Data.Questions[i];
                element.Edit = false;
            }

            if (action.payload.Status === TestResultStatus.Passed) {

                state.currentTest.Status = TestResultStatus.Passed;
                state.showSubmit = false;
                state.currentTest.SubmitDate = new Date();
            }
            else if (action.payload.NextQuestion != null) {
                action.payload.NextQuestion.Edit = true;
                state.currentTest.Data.Questions.push(action.payload.NextQuestion);
            }
        },

        /**
      * 
      * @param {{payload: boolean}} action 
      */
        setSavingToserver(state, action) {
            state.savingToserver = action.payload;
        },
    }
});

/**
 * 
 * @param {import('./QATest').QATestState} state 
 * @param {any} data 
 * @return {number}
 */
const saveQaAnswerAtServer = (state, data) => {



    if (state.timerId != null) {
        window.clearTimeout(state.timerId);
    }
    const makeupdate = state.makeStatusUpdate;

    const timerId = window.setTimeout(() => {
        const submitData = {
            QuestionNum: data.qnum,
            Answer: data.reply,
            Saveonly: true
        }

        const url = endPoint.GET_SUBMIT_QATEST_URL(data.classid, data.testid);
        instance.post(url, submitData);


        if (makeupdate === true) {
            dispatchService.dispatch(QATestDuck.setUpdateFlag(false));
            TestService.updateTestStatus({ classId: data.classid, testId: data.testid }, dispatchService.dispatch);
        }

    }, 3000);

    return timerId;
}

/**
 * Call this on component unload
 */
const clearOnExit = () => (dispatch, getstate) => {

    /**
     * @type import('./QATest').QATestState
     */
    const state = getstate().qatest;
    if (state.timerId > -1) {
        window.clearTimeout(state.timerId);
        dispatch(QATestDuck.setTimerId(-1));
    }
}

/**
 * @param {{classid:string, testid:string, saveonly:boolean}} payload 
 * @returns (dispatch: ()=>void, getstate: ()=> LnState )
 * 
 */
export const submitQATest = (payload) => async (dispatch, getstate) => {


    if (!payload.saveonly) {
        dispatch(QATestDuck.setSavingToserver(true));
    }

    /**
     * @type import('./QATest').QATestState
     */
    const state = getstate().qatest;
    if (state.timerId > -1) {
        window.clearTimeout(state.timerId);
        dispatch(QATestDuck.setTimerId(-1));
    }

    /**
     * @type import('./QATest').QAQuestionDto[]
     */
    const questions = state.currentTest.Data.Questions;
    const qnum = questions.length - 1;

    if (payload.saveonly === false && qnum === 1) {

        // Check if student has copied expert's answer
        
        const el = document.createElement("div");
        el.innerHTML = questions[1].Question;
        const t = el.innerText;
        el.remove();
        const q = t.substring(2, t.length - 4);
        const len = t.length;
        

        if ( !!questions[1].Reply &&  questions[1].Reply.length < len + 5 && questions[1].Reply.indexOf(q) > -1 ) {

            dispatch(errorDuck.setError({
                okText: languageService.getText("ok.understand"),
                header: languageService.getText("error"),
                message: languageService.getText("qatest.no.duplicate"),
                when: new Date()
            }));

            

            dispatch(QATestDuck.setSavingToserver(false));
            return;
        }
    }

    const submitData = {
        QuestionNum: qnum,
        Answer: questions[qnum].Reply,
        Saveonly: payload.saveonly
    }
    const url = endPoint.GET_SUBMIT_QATEST_URL(payload.classid, payload.testid);

    try {
        const result = await instance.post(url, submitData);
        if (!result) {
            return;
        }
        if (payload.saveonly === false) {
            dispatch(QATestDuck.setQATestSubmitted(result.data));
        }

        TestService.updateTestStatus({ classId: payload.classid, testId: payload.testid }, dispatch);


    } catch (error) {
        handleErrors(error);
    }
    finally {
        dispatch(QATestDuck.setSavingToserver(false));
    }


}

const handleErrors = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    console.log(error.config);

}

export const QATestDuck = { ...qaTestSlice.actions, submitQATest, clearOnExit }

export default qaTestSlice.reducer