//@ts-check
import React, { useEffect, useState } from "react";
import ScrollContent from 'components/ScrollContent';
import { useI18n } from "components/lni18n";
import WaitRipple from "components/WaitRipple";
import useTypedSelector from "utils/useTypedSelector";
import Link from "redux-first-router-link";
import LnIcon from "components/LnIcon";
import LnResultIcon from "components/LnResultIcon";
import { CustomInput } from 'reactstrap';
import classes from './myprogress.module.scss';
import ThemeGrade from "./ThemeGrade";
import { Alert } from "components/OnlineModal";
import DateTimePickerDialog from "components/DateTimePicker/DateTimePickerDialog";
import { endPoint } from "AppConstants";


const UserTestResults = (props) => {

    const { languageService: t } = useI18n();
    const { userProgress } = useTypedSelector(state => state.user);
    const currentClass = useTypedSelector(state => state.classData.currentClass);


    const [filterData, setFilterData] = useState({ 
        filter: false, 
        date: currentClass.DashBoardData.Start, 
        dateValue: currentClass.DashBoardData.Start.getTime() 
    });

    const toggleFilter = () => {
        setFilterData(v => { return { ...v, filter: !v.filter } })
    }

    const setFilterDate = (d) => {
        setFilterData(v => { return { ...v, filter: true, date: d, dateValue: d.getTime() } })
    }

    useEffect(() => {
        let d = currentClass.DashBoardData.Start;
        d.setHours(0, 0, 0);
        setFilterData({ filter: false, date: d, dateValue: d.getTime() });
        // eslint-disable-next-line
    }, [])

    if (userProgress == null) {
        return <ScrollContent scrollname="class-myprogress">
            <div className="card-box big-card-box">
                <WaitRipple />
            </div>
        </ScrollContent>
    }


    const legendDisplay = (className, label) => {
        return (
            <div key={label} className="col-5 col-sm-3 mb-4 d-flex">
                <LnResultIcon className="mr-2 icon-medium" status={className} />
                <small>{label}</small>
            </div>
        )
    }


    const openNote = (note) => {
        Alert({
            backdrop: true,
            title: t.getText('notes'),
            message: <div className={classes.studentNotes} dangerouslySetInnerHTML={{ __html: note }}></div>,
            button: {
                className: 'btn btn-primary',
                text: t.getText("ok"),
                type: "submit"
            }
        })
    }


    return (
        <div>
            <div className="row  mb-4">

                {userProgress.TestStatuses.map(st => {
                    return legendDisplay(st.Key, t.getText(st.Key))
                })}

            </div>
            <div className={"alert mb-3 d-flex flex-wrap align-content-center " + (filterData.filter ? "alert-danger" : "alert-primary")}>
                <CustomInput className="mr-3" id="filterOnDates" type="switch" onChange={() => toggleFilter()}
                    checked={filterData.filter}
                    label={<span>{t.getText("testresults.show.only.commented.since")}</span>} />
                <DateTimePickerDialog showTimeSelect={true} Date={filterData.date} showIcon onChange={(d) => setFilterDate(d)} renderDays={'past'} />
            </div>

            {userProgress.Themes.find(t => t.ThemeGrade) && <div className="mb-4">
                <a href={endPoint.API_HOST + `/grades/${userProgress.Student.Id}/${userProgress.StudentClass.Id}/get`} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-small" >{t.getText("grades.as.pdf")}</a>
            </div>}

            <div className="row mb-4">
                <div className="col-sm-12">
                    <table className="table table-responsive-sm table-sm">

                        <tbody>
                            {userProgress.Themes.map((theme) => {
                                return (
                                    theme.CourseParts.map((part, i) => {

                                        return (
                                            <tr key={String(part.Id)} className={i === 0 ? classes.firstRow : classes.subRow}>
                                                <td>
                                                    {i === 0 && (
                                                        <div className="mb-3">
                                                            <div className="mb-2">
                                                                <strong>
                                                                    {theme.Running ? <Link className="link-nocolor" to={{ type: "CLASS_THEME", payload: { classid: currentClass.Id, themeid: theme.Id } }}>{theme.Name}</Link>
                                                                        : <span>{theme.Name}</span>
                                                                    }
                                                                </strong>
                                                            </div>
                                                            {theme.ThemeGrade && <ThemeGrade grade={theme.ThemeGrade} languageService={t} />}
                                                        </div>
                                                    )}
                                                </td>
                                                <td>
                                                    <div className="row">
                                                        <div className="d-flex flex-column col-sm-6 mb-4">
                                                            {part.Running ?
                                                                <Link className="link-nocolor" to={{
                                                                    type: "CLASS_THEME_PART",
                                                                    payload:
                                                                    {
                                                                        classid: currentClass.Id,
                                                                        themeid: theme.Id,
                                                                        partid: part.Id,
                                                                        type: "intro"
                                                                    }
                                                                }}
                                                                >{part.Name}</Link>
                                                                : <span>{part.Name}</span>
                                                            }
                                                            {part.Notes && <button onClick={() => openNote(part.Notes)} title={t.getText("notes")} className="btn btn-link d-flex align-items-center">
                                                                <LnIcon name="edit" className="icon-small mr-3" /> {t.getText("notes")}
                                                            </button>}



                                                            {!part.SubmittedEval && <small>{t.getText('no.evaluation')}</small>}
                                                            {part.RunningDates &&
                                                                <div className="small">{t.getText("your.access.to.course.content")}
                                                                    <br />
                                                                    {t.getMediumDateString(part.RunningDates.Start)} &#8594;
                                                                    {part.RunningDates.End && <>{t.getMediumDateString(part.RunningDates.End)}</>}
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="col-sm-6">

                                                            { (!part.Tests || part.Tests.length === 0 ) && t.getText("no_test_in_subsection") }

                                                            {
                                                                part.Tests
                                                                    .filter(t => !filterData.filter || (t.CommentDate && t.CommentDate.getTime() > filterData.dateValue))
                                                                    .map(test => (
                                                                        <div className={classes.testCell + " mb-4"} key={test.Id}>
                                                                            <LnResultIcon className="mr-3" status={test.Status} />
                                                                            {part.Running && test.CanStartTest ?
                                                                                <Link className="link-nocolor" to={
                                                                                    {
                                                                                        type: "CLASS_THEME_PART_TEST",
                                                                                        payload: {
                                                                                            classid: currentClass.Id,
                                                                                            themeid: theme.Id,
                                                                                            partid: part.Id,
                                                                                            testid: test.Id,
                                                                                            testtype: test.Type
                                                                                        }
                                                                                    }}
                                                                                >{test.Name}</Link>
                                                                                :
                                                                                <span title={t.getText("fulfill.lectures.bofore.test")}>
                                                                                    <span>{test.Name}</span>
                                                                                    <LnIcon name="locked" className="ml-3 icon-small" />
                                                                                </span>
                                                                            }

                                                                        </div>
                                                                    ))}
                                                        </div>

                                                    </div>


                                                </td>


                                            </tr>

                                        )
                                    })
                                )

                            }
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default UserTestResults;