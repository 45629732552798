//@ts-check

import React from "react";
import { endPoint } from "AppConstants";
import instance from "utils/axios";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import classes from './dashboard.module.scss';
import { Input, Alert } from 'components/OnlineModal';
import Link from "redux-first-router-link";

/**
 * FeedbackWidget
 * @module features/classes/Dashboard/FeedbackWidget
 * @return an LnCard with info and a dialog to submit feedback from user.
 * @param { { data: import("../Classes").FeebackData }} props
 */
const FeedbackWidget = ({ data }) => {

    const { languageService: t } = useI18n();


    const feedback = async () => {

        const dialogresult = await Input({
            closeOnEnter: false,
            fields: [{ name: "feedback", type: "textarea", label: "", placeholder: t.getText("your.comment") }],
            languageService: t,
            message: <div className="preserve-white">{t.getText("feedback.info")}</div>,
            title: t.getText("feedback"),
            size: "lg"
        }).promise;

        if (!dialogresult || !dialogresult.feedback) {
            return;
        }
        const data = { Value: dialogresult.feedback + "\r\n\r\n" + window.navigator.userAgent }
        await instance.post(endPoint.GET_POST_FEEDBACK_URL(), data);

        Alert({
            backdrop: true,
            title: t.getText("tele"),
            message: <div className="preserve-white"> {t.getText("feedback.thank.you")}</div>,
            button: {
                className: "btn btn-primary",
                text: t.getText("ok"),
                type: 'submit'
            }
        });


    }

    return (
        <div className={['card-box big-card-box card ln-card mr-5 mb-5', classes['widget-card'], classes['my-progress']].join(' ')}>
            <div className={["card-header ", classes['card-header']].join(' ')}>
                <span>
                    <LnIcon className="header-icon" name="ear" />
                    {t.getText('feedback')}
                </span>


            </div>
            <div className="card-body pt-5">
                <div className="preserve-white">
                    {t.getText("feedback.dashboardtext")}
                </div>
                <div className="mt-2 d-flex justify-content-between">
                    <button onClick={feedback} className="btn btn-primary btn-small" >{t.getText("feedback")}</button>
                    {data && data.HaveFeeback && <div>
                        {data.NumNewPosts > 0 && <span className="badge-warning badge badge-pill fixedSize mr-3" >{data.NumNewPosts}</span>}
                        <Link className="btn btn-primary btn-small" to={{ type: 'GENERAL_FEEDBACK_FORUM' }}>{t.getText("feedback.submitted")}</Link>
                        
                    </div>}



                </div>
                <div className="mt-2">
                    <a href="https://static.laranara.se/video/lndemo.mp4" rel="noopener noreferrer" target="_blank" >{t.getText("demo.video")}</a>
                </div>
            </div>
        </div>
    )
}

export default FeedbackWidget;