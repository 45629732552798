//@ts-check
import React from "react";
import { useI18n } from "components/lni18n";
import ScrollContent from "components/ScrollContent";
import WaitRipple from "components/WaitRipple";

import LnIcon from "components/LnIcon";
import useTypedSelector from "utils/useTypedSelector";
import TeacherMiniDashboard from "features/classes/Dashboard/TeacherMiniDashboard";
import { useState } from "react";

const TeacherClasses = () => {

    const { languageService: t } = useI18n();
    const {teacherClasses: data, eventsSinceDate} = useTypedSelector(state => state.classData);

    const [filter, setFilter] = useState("");


    if (!data) {
        return (
            <ScrollContent scrollname="personal-data-policy">
                <div className="card-box big-card-box">
                    <WaitRipple />
                </div>
            </ScrollContent>

        )
    }


    return <ScrollContent scrollname="course-overview">
        <div className="card-box big-card-box">
            <div className="card-header ln-nostyle">
                <h3 className="d-flex">
                    <LnIcon className="header-icon" name="progress-icon" />
                    {t.getText('course.summary')}
                    <input onChange={(e)=>setFilter(e.target.value.toLowerCase())} placeholder={t.getText("search")} className="ml-5 w-25  form-control form-control-sm" type="text" value={filter} />
                </h3>
                
            </div>

            <div className="pl-3 pt-3 max-col-width">
                {data.filter(d => d.StudentClass.Name.toLowerCase().indexOf(filter) > -1 ).map(cl => <TeacherMiniDashboard key={cl.StudentClass.Id}  since={eventsSinceDate} data={cl} />)}

            </div>

        </div>
    </ScrollContent>

}


export default TeacherClasses