export const UserMode = {
    student: 0,
    teacher: 1,
    unitadmin: 2,
    unitroot: 3,
    root: 4
}



export const StudentNotesState = {
    DoNotShow: "DoNotShow",
    NoNotes: "NoNotes",
    HasReadNotes: "HasReadNotes",
    HasUnreadNotes: "HasUnreadNotes"
}

export const SignOnReplyStatus = {
    ok : 0,
    error : 1,
    fullCanBeReserve : 2,
    fullCanNotBeReserve : 3,
    UserExists : 4,
    UserExistsAndInClass: 5,
    UserExistsAndHaveNotLoggedIn:6
  }

  export const SignOnDataStatus = {
    showform : 0,
    registrationReady: 1
  }