//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import DateSpanPicker from "components/DateTimePicker/DateSpanPicker";
import WaitRipple from "components/WaitRipple";
import LnIcon from "components/LnIcon";
import { useI18n } from "components/lni18n";

import { userDuck } from "./UserDuck";

import classes from "./user.module.scss";

const UserOnlineActivities = ({ userData }) => {

    const dispatch = useDispatch();
    const { languageService: t } = useI18n();

    const { sessionData } = useTypedSelector(state => state.user);

    if (sessionData == null) {
        return <WaitRipple />
    }


    const handleDatePickerChange = (dates) => {

        userDuck.getSessionData(userData.Id, dates.from, dates.to, dispatch);
    }

    const headerCol = (label) => {
        return (
            <th className="th-clean">
                <div className="d-flex flex-column">
                    <div className="font-weight-normal">
                        <small className={classes.headerTimeLabel}>{label}</small>
                    </div>
                    <div className={classes.thTickWrapper}>
                        <div className={[classes.thTick, classes.first].join(' ')}></div>
                        <div className={[classes.thTick, classes.middle].join(' ')}></div>
                        <div className={[classes.thTick, classes.last].join(' ')}></div>
                    </div>

                </div>
            </th>
        )
    }
    return (
        <div className="pt-4 mt-5">
            <div className="row mb-4">
                <div className="col-sm-12 d-flex">
                    <div className={["mr-5", classes.DatePickerWrapper].join(' ')}>
                        <DateSpanPicker showTimeSelect StartDate={new Date(sessionData.Start)} EndDate={new Date(sessionData.End)}

                            onChange={(dates) => handleDatePickerChange(dates)}
                            showIcon
                            showWeekNumbers
                            showMonthDropdown
                            showYearDropdown
                            renderDays="past"
                        />
                    </div>

                </div>
            </div>
            <div className="row mb-4">
                <div className="col-sm-12">
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th className={['th-clean', classes.thDate].join(' ')}>&nbsp;</th>
                                {headerCol(' 0')}
                                {headerCol(' 3')}
                                {headerCol(' 6')}
                                {headerCol(' 9')}
                                {headerCol('12')}
                                {headerCol('15')}
                                {headerCol('18')}
                                {headerCol('21')}
                            </tr>
                        </thead>
                        <tbody>
                            {sessionData.Days.map((d) => {
                                return (
                                    <tr key={String(d.Day)} className={!d.Sessions.length ? 'bg-light' : ''}>
                                        <td className="text-nowrap">
                                            <div className="d-flex justify-content-between">
                                                <div className={classes.onlineDay}>
                                                    {t.getShortDateString(d.Day)}

                                                </div>
                                                {d.Sessions.length && d.Sessions[0].ConnectedToPrevDay ? <LnIcon name="link-icon" className="icon-xs mt-1" /> : null}
                                            </div>
                                        </td>
                                        <td colSpan={8} className={classes.tdCenter}>
                                            <div className={classes.onlineWrapper}>
                                                {d.Sessions.map((s) => {
                                                    return (
                                                        <div title={t.getTimeString(s.Start) + " - " + t.getTimeString(s.End)} key={String(s.Start)} className={"pointer " + classes.onlineBar} style={{
                                                            left: s.StartInPercent + '%', width: s.DurationInPercent + '%'
                                                        }}>
                                                            <div className={"card " + classes.onlineBarText}>
                                                                <div className="card-body">{t.getTimeString(s.Start) + " - " + t.getTimeString(s.End)}</div>
                                                            </div>
                                                        </div>

                                                    )
                                                })}

                                            </div>
                                        </td>
                                    </tr>
                                )

                            }
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )

}


export default UserOnlineActivities;

