//@ts-check
import React from "react";
import { endPoint } from "AppConstants";
import LnIcon from "components/LnIcon";
import { useI18n } from "components/lni18n";

/**
 * 
 * @param {{ 
 *      files: import('./FileTest').FileTestStateFileDto[] ,
 *      languageService: any, 
 *      header: string,
 *      className?: string,
 *      canDelete: boolean,
 *      handleDelete: (id:string)=>void} } props,
 
 */
const UploadedFileList = (props) => {

    const { languageService: t } = useI18n();
    if (!props.files || props.files.length === 0) {
        return null;
    }

    return (
        <>
            <table className="table table-condensed">
                <thead>
                    <tr>
                        <th className="border-0" colSpan={3}>
                            {props.header}
                        </th>
                    </tr>
                    <tr>
                        <th className="border-0">
                            {t.getText("file")}
                        </th >
                        <th className="border-0">
                            {t.getText("date")}
                        </th>
                        <th className="border-0"></th>
                    </tr>

                </thead>
                <tbody>
                    {props.files.map(f => {
                        return <tr key={f.Id}>
                            <td>
                                <a href={endPoint.API_HOST + f.Url} target="_blank" rel="noopener noreferrer">{f.Name}</a>
                            </td>
                            <td>
                                {t.getShortDateTimeString(f.Created)}
                            </td>
                            <td>
                                {props.canDelete &&
                                    <button onClick={() => props.handleDelete(f.Id)} className="btn btn-link"><LnIcon className="natural" name="delete" /></button>
                                }
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </>
    )


}

export default UploadedFileList;