//@ts-check

import React from "react";

import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";

import classes from './dashboard.module.scss';

/**
 * SelfStudyCourseAccessWidget
 * @module features/classes/Dashboard/CourseAccessWidget
 * @return an LnCard with Course Acces info.
 * @param {{
    DivHeight?: string,
    StudentData: import("../Classes").StudentClassDtoForStudent,
}} props
 */
const SelfStudyCourseAccessWidget = (props) => {

    const { languageService: t } = useI18n();
    const studentClass = props.StudentData.StudentClass;
    
    const divClasses = ['card-box big-card-box card ln-card mr-5 mb-5', classes['widget-card']];
    if (props.DivHeight) {
        divClasses.push(classes[props.DivHeight]);
    }

    return (
        <div className={divClasses.join(' ')}>
            <div className={["card-header", classes['card-header']].join(' ')}>
                <LnIcon className="header-icon" name="note-icon" />
                {t.getText('your.course.access', "")}
            </div>
            <div className="card-body pt-5 container">
                <div className="">
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-row mb-4">
                            <LnIcon className="header-icon mr-3" name="pc-icon" />
                            <span className="text-nowrap">{t.getMediumDateString(studentClass.ClassStartDate)}</span>
                                &nbsp;{t.getText('to').toLowerCase()}&nbsp;<span className="text-nowrap">
                                {t.getMediumDateString(studentClass.ClassEndDate)}
                            </span>
                        </div>
                        <span>

                        </span>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default SelfStudyCourseAccessWidget;