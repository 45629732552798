//@ts-check
import React, { useEffect } from "react";
import { useDispatch, shallowEqual } from "react-redux";
import classes from "../QATest/QATest.module.scss";
import { MQATestDuck } from "./MQATestDuck";
import { useI18n } from "components/lni18n";
import QAQuestion from "../QATest/QAQuestion";
import TestSubmitted from "../test/TestSubmitted";
import TestTop from "../test/TestTop";
import { courseDuck } from "features/course/CourseDuck";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";
import DebouncedButton from "components/DebounceButton";


const MQATest = props => {

    const { languageService: t } = useI18n();
    const currentTheme = courseDuck.useCurrentTheme();

    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const currentPart = courseDuck.useCurrentPart();
    const { currentTest, canSubmit, showSubmit, savingToserver } = useTypedSelector(state => state.mqatest, shallowEqual);
    const currentBaseTest = courseDuck.useCurrentTest();
    const testId = currentBaseTest.TestId;

    const dispatch = useDispatch();


    useEffect(() => {
        // clean up => stop save timer
        return () => MQATestDuck.clearOnExit();
    }, [])

    const submitAnswer = (saveonly) => {
        dispatch(MQATestDuck.submitMQATest({
            classid: currentClass.Id,
            testid: testId,
            saveonly: saveonly
        }));
    }


    const handleInputEvent = data => {
        /**
         * @type {{index: number, reply: string, classid: string, testid: string}}
         */
        const payload = {
            ...data,
            classid: currentClass.Id,
            testid: testId
        };


        dispatch(MQATestDuck.setQuestionReply(payload));
    }

    if (!currentBaseTest || !currentTest) {
        return <WaitRipple />
    }

    let index = 0;
    return <div className={classes.qatest}>

        <TestTop languageService={t} classId={currentClass.Id} themeId={currentTheme.Id} partId={currentPart.Id} ></TestTop>

        <div className="row">
            <div className="col-lg-9"><TestSubmitted status={currentBaseTest.Status} languageService={t} />
            </div></div>

        <div>
            <div className="max-col-width">
                <div className="mb-4" >
                    {currentBaseTest.StudentInstructions
                        && <div className="alert border-primary"
                            dangerouslySetInnerHTML={{ __html: currentBaseTest.StudentInstructions }} >
                        </div>}

                    {currentTest.Questions.map(q => {
                        return <QAQuestion autoFocus={false} languageService={t} dispatchEvent={handleInputEvent} index={index} key={index++} question={q} />
                    })}
                </div>

                <div className="position-relative w-50">
                    {savingToserver && <WaitRipple />}
                </div>

                {showSubmit && <DebouncedButton disabled={!canSubmit || savingToserver} onClick={() => submitAnswer(false)} className="btn btn-primary mr-2" >{t.getText("submit")}</DebouncedButton>}
            </div>
        </div>
    </div>
}
export default MQATest;

