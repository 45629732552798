//@ts-check
import React from "react";
import { ErrorMessage } from '@hookform/error-message';
import classes from './user.module.scss';

/**
     * @param {{ readOnly: boolean, label: any, type:string, as: string, defaultValue: string, name:string, errors: any, refToHook: import("react").LegacyRef<any>}} props
    */
const FormTextInput = ({ as, label, name, errors, refToHook, defaultValue, type, ...props }) => {

    return (
        <div className="form-group">

            {props.readOnly && <>
                <div className="font-weight-bold small" >{label}</div>
                <div className="preserve-white">{defaultValue}</div>
                
                {/* needed to make the data validation etc work in react-form */}
                <input type="hidden" name={name} ref={refToHook} defaultValue={defaultValue} />
            </>}

            {!props.readOnly && (<>
                <div className="mb-2" >{label}</div>
                {as === "textarea" ?
                    <textarea defaultValue={defaultValue} ref={refToHook} name={name} className="form-control" {...props} /> :
                    <input defaultValue={defaultValue} ref={refToHook} type={type} name={name} className={(errors[name] ? classes.errorInput : "") + " form-control"} {...props} />
                }

                <ErrorMessage as="div" errors={errors} name={name} className={classes.errorMess} />
            </>)
            }



        </div>
    );
};


export default FormTextInput;