//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import WaitRipple from "components/WaitRipple";
import PartList from "features/course/PartList/PartList";
import Part from "features/course/part/Part";
import ClassMembers from "./ClassItems/Members/ClassMembers";
import MyProgress from "./ClassItems/MyProgress/MyProgress";
import PersonalPlan from "./ClassItems/MyProgress/PersonalPlan";
import NotificationList from "./ClassItems/Notifications/NotificationList";
import EditNotificationsStub from "./ClassItems/Notifications/EditNotificationsStub";
import TimeTableLoader from "./ClassItems/TimeTable/TimeTableLoader";
import SubGroup from "./ClassItems/SubGroup/SubGroup";
import ClassFeed from "./ClassItems/Feed/ClassFeed";
import ClassMail from "./ClassItems/ClassMail/ClassMail";
import SingleMail from "features/General/SingleMail/SingleMail";
import ClassFiles from "./ClassItems/ClassFiles/ClassFiles";
import ClassChanges from "./ClassChanges/ClassChanges";
import PrintLecture from "../course/part/Lectures/PrintLecture";
import Dashboard from "./Dashboard/Dashboard";
import useTypedSelector from "utils/useTypedSelector";
import ForumForClass from "./ClassItems/Forum/ForumForClass";
import StudentsActivityStub from "./ClassItems/StudentsActivityStub"
import EvalSummaryStub from "./ClassItems/EvalSummaryStub";
import FinishedClasses from "features/layout/FinishedClasses";
import StudentsTableStub from "./ClassItems/StudentsTableStub";
import SubscriptionsStub from "./ClassItems/SubscriptionsStub";
import EditClassFiles from "features/General/DocBox/EditClassFiles";
import SchoolDashboard from "./Dashboard/SchoolDashboard";
import { useI18n } from "components/lni18n";
import AttendanceStub from "./ClassItems/AttendanceStub";
import VerificationForm from "./VerificationForm";

const StudentRoot = props => {
    const dispatch = useDispatch();
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const route = useTypedSelector(state => state.location.type);
    const { languageService: t } = useI18n();

    if( currentClass && currentClass.UserVerificationNeeded){
        return <VerificationForm />;
    }

    if ( !currentClass || !(currentClass.DashBoardData)) {
        return <WaitRipple text={t.getText("getting.courses")}  />;
    }

    const classRoutes = {
        CLASS: ()=> currentClass.DashBoardData.StudentData.CourseType !== 4 ?  <Dashboard /> : <SchoolDashboard />,
        CLASS_INFO: ()=> <ClassMembers />,
        CLASS_FEED: ()=> <ClassFeed />,
        CLASS_FORUM: ()=> <ForumForClass />,
        CLASS_FILES: ()=> <ClassFiles />,
        CLASS_FILES_EDIT: ()=> <EditClassFiles />,
        CLASS_CHANGES: ()=> <ClassChanges />,
        CLASS_SUBGROUP: ()=> <SubGroup />,
        CLASS_SUBGROUP_FORUM_THREAD: ()=> <SubGroup />,
        CLASS_SUBGROUP_MAIL: ()=> <SubGroup />,
        CLASS_SUBGROUP_MAIL_EDIT: ()=> <SubGroup />,
        CLASS_FORUM_THREAD: ()=> <ForumForClass />,
        CLASS_TIMETABLE: ()=> <TimeTableLoader />,
        CLASS_MAIL: ()=> <ClassMail />,
        CLASS_MAIL_EDIT: ()=> <ClassMail />,
        CLASS_SINGLE_MAIL: ()=> <SingleMail
            navigateToListHandler={() => dispatch({ type: "CLASS_SINGLE_MAIL", payload: { classid: currentClass.Id } })}
            navigateToEditHandler={(mailid) => dispatch({ type: "CLASS_SINGLE_MAIL_EDIT", payload: { classid: currentClass.Id, mailid: mailid } })}
        />,
        CLASS_SINGLE_MAIL_EDIT: ()=> <SingleMail
            navigateToListHandler={() => dispatch({ type: "CLASS_SINGLE_MAIL", payload: { classid: currentClass.Id } })}
            navigateToEditHandler={(mailid) => dispatch({ type: "CLASS_SINGLE_MAIL_EDIT", payload: { classid: currentClass.Id, mailid: mailid } })}
        />,
        CLASS_NOTIFICATIONS: ()=> <NotificationList />,
        CLASS_NOTIFICATIONS_EDIT: ()=> <EditNotificationsStub />,
        CLASS_NOTIFICATIONS_EDIT_NOTIFICATION: ()=> <EditNotificationsStub />,
        CLASS_PROGRESS: ()=> < MyProgress />,
        CLASS_PERSONAL_PLAN: ()=> <PersonalPlan />,
        CLASS_FULL_PROGRESS: ()=> <StudentsActivityStub />,
        CLASS_FULL_PROGRESS_PARTIAL: ()=> <StudentsActivityStub />,
        CLASS_EVAL_SUMMARY: ()=> <EvalSummaryStub />,
        CLASS_LIST_FINISHED: ()=> <FinishedClasses />,
        CLASS_STUDENTS_TABLE:()=> <StudentsTableStub />,
        CLASS_SUBSCRIPTION: ()=><SubscriptionsStub />,
        CLASS_ATTENDANCE: ()=> <AttendanceStub />,

        CLASS_THEME: ()=> <PartList />,
        CLASS_THEME_PART: ()=> <Part />,
        CLASS_THEME_PART_TEST: ()=> <Part />,
        CLASS_THEME_PART_LECTURE_PLAY: ()=> <Part />,
        CLASS_THEME_PART_LECTURE_PRINT: ()=> <PrintLecture />,
    };


    // if( route === "CLASS_THEME_PART_LECTURE_PLAY"){
    //     return <LecturePlayer />;
    // }



    return classRoutes[route]();
           
};

export default StudentRoot;

