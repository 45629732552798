//@ts-check
import React from "react";
import useTypedSelector from "utils/useTypedSelector";

const CdnCookiePinger = props => {

    const {cdnUrl} = useTypedSelector(state => state.login);

    return <>
        <iframe title="cdn" className="d-none" src={cdnUrl}></iframe>
    </>;
};

export default CdnCookiePinger;
