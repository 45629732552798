//@ts-check
import React,{useState} from "react";
let TestCommentDialog = null;


const ForumTestCommentStub = ({ onDismiss, show }) => {

    
    const [ready, setReady ] = useState(false);

    import("../../../../teacherFeatures").then(features => {
        TestCommentDialog = features.TestCommentDialog;
        setReady(true);
    });

    if (!ready) {
        return <div />;
    }
    else {
        return <TestCommentDialog show={show} onDismiss={() => onDismiss()} />
    }
}

export default ForumTestCommentStub;
