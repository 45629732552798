//@ts-check
import React from "react";
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import { useDispatch } from "react-redux";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import { endPoint } from "AppConstants";

import FileIcon from "components/FileIcons/FileIcon";
import classes from './files.module.scss';
import { courseDuck } from "features/course/CourseDuck";



/**
 * FileCard
 * @module features/course/part/Files/FileCard
 * @return an LnCard with file info.
 *
 * @param {{file: import('../../Course').FileInfo, isNew: boolean}} props
 */
const FileCard = ({ file, isNew }) => {

    const { languageService: t } = useI18n();
    const dispatch = useDispatch();


    const fileOpened = () => {
        dispatch(courseDuck.setFileViewed({ fileId: file.Id }));
    }

    /* File params
        Id: "5e0d4e14-d275-4833-8381-720a21c2ce59"
        Name: "plane.jpg"
        Description: "plane.jpg"
        Url: "/api/class/3dde56bd-df37-4673-83ed-3b09b6aaaea7/coursepart/696c459f-9459-47ee-bfd1-805594bf580d/file/5e0d4e14-d275-4833-8381-720a21c2ce59"
        GroupName: "Grupp"
        LastModified: "2016-08-09T05:03:59Z"
        Viewed: false

    */
    return (
        <Card id={"c_" + file.Id} key={file.Id} className={"m-3 p-2 " + classes.listCard}>
            <CardBody className="small ln-card-body pointer p-2 text-center">
            <div className="d-flex align-content-center justify-content-center position-relative">
                {isNew &&
                    <LnIcon name="bell-icon" className="icon-small icon-right-top filter-green" />
                }
                <FileIcon className={[classes['file-icon']].join(' ')} size="512px" name={(file.Name || '').split('.').pop()} />
                
            </div>
            
            <div className={"font-weight-bold " + classes.lineClamp} dangerouslySetInnerHTML={{ __html: file.Description }}></div>
                <div className={classes.fileInfo}>
                    <span className="card-title">{file.Name}</span>
                </div>
                <span >{t.getMediumDateString(file.LastModified)}</span>
                <br />
                <a target="_blank" rel="noopener noreferrer" onClick={fileOpened} className="" href={endPoint.API_HOST + file.Url}>{t.getText('download')}</a>

            </CardBody>

            {file.Viewed && <LnIcon className="done-icon natural" name="checked" />}

            <UncontrolledTooltip placement="right" target={'c_' + file.Id} trigger={'hover'}>
                <strong>{file.Name}</strong>
                <div dangerouslySetInnerHTML={{ __html: file.Description }}></div>
            </UncontrolledTooltip>

        </Card>
    )
}

export default FileCard;
