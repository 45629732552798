//@ts-check
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";
import { useI18n } from "components/lni18n";
import ScrollContent from "components/ScrollContent";
import { classFilesDuck } from "./ClassFilesDuck";
import { endPoint } from "AppConstants";
import LnIcon from "components/LnIcon";
import FileIcon from "components/FileIcons/FileIcon";
import dayjs from "dayjs";
import classes from './classfiles.module.scss';

const ClassFiles = () => {

    const dispatch = useDispatch();
    const { currentFolderId, folderPath, canEdit } = useTypedSelector(state => state.classFiles);
    const { eventsSinceDate, currentClass } = useTypedSelector(state => state.classData);
    const { languageService: t } = useI18n();
    const currentFolder = classFilesDuck.useCurrentFolder();

    const setFolder = (folderId) => {
        dispatch(classFilesDuck.setCurrentFolder({ id: folderId }));
    }

    const folderList =  useMemo(()=>{
        if( !currentFolder || !currentFolder.Children){
            return null;
        }
        return currentFolder.Children.filter(f=> !f.Dimmed );
    }, [currentFolder]);

    const fileList =  useMemo(()=>{
        if( !currentFolder || !currentFolder.Files){
            return null;
        }
        return currentFolder.Files.filter(f=> !f.Dimmed );
    }, [currentFolder]);


    if (currentFolder == null) {
        return <ScrollContent scrollname="class-mail">
            <div className="card-box big-card-box">
                <WaitRipple />
            </div>
        </ScrollContent >
    }

    const changesDate = dayjs(eventsSinceDate);

    const onEditFiles = ()=>{
        dispatch( { type: "CLASS_FILES_EDIT", payload:{ classid: currentClass.Id} } );
    }
    

    return (
        <ScrollContent scrollname="class-mail">
            <div className="card-box big-card-box">
                <div className="card-header ln-nostyle d-flex justify-content-between">
                    <h3>
                        <LnIcon className="header-icon" name="dox-icon" />
                        {folderPath.map(f => {

                            if (f.Id === currentFolderId) {
                                return <span key={f.Id}>{f.Name}</span>
                            }
                            else {
                                return <span key={f.Id}><button className="btn btn-link" onClick={() => setFolder(f.Id)} >{f.Name}</button> <LnIcon name="right-arrow" className="mb-1 icon-small" /> </span>
                            }
                        })}
                    </h3>
                    {canEdit && <button onClick={ onEditFiles } className="btn btn-primary btn-small">{t.getText("edit")}</button>}
                </div>
                <div className="card-body pt-5">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>
                                    {t.getText('name')}
                                </th>
                                <th>
                                    {t.getText('description')}
                                </th>
                                <th className="d-none d-md-table-cell">
                                    {t.getText('date.created')}
                                </th>
                                <th>
                                    {t.getText('size')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(folderList && (folderList.length > 0)) ?
                                folderList.map(f => { // Folders
                                    return (
                                        <tr key={f.Id}>
                                            <td onClick={() => setFolder(f.Id)}>
                                                <LnIcon className={["mr-4 ", classes['file-icon']].join(' ')} name="dox-icon" />
                                                <button type="button" className="btn btn-link" >{f.Name}</button>
                                            </td>
                                            <td>
                                                &nbsp;
                                            </td>
                                            <td className="d-none d-md-table-cell">
                                                &nbsp;
                                            </td>
                                            <td>
                                                {f.Size}
                                            </td>
                                        </tr>
                                    )
                                }) : null}
                            {(fileList && (fileList.length > 0)) ?
                                fileList.map(f => { // Files

                                    return (
                                        <tr key={f.Id}>
                                            <td className="position-relative">

                                                <a href={endPoint.API_HOST + f.Url} rel="noopener noreferrer" target="_blank"><FileIcon className={["mr-4", classes['file-icon']].join(' ')} size="512px" name={(f.Name || '').split('.').pop()} /></a>
                                                {dayjs(f.Created).isAfter(changesDate, 'day') &&
                                                    <LnIcon name="bell-icon" className="icon-small  filter-green mr-2" />
                                                }
                                                <a href={endPoint.API_HOST + f.Url} rel="noopener noreferrer" target="_blank">{f.Name}</a>
                                            </td>
                                            <td>
                                                {f.Description}
                                            </td>
                                            <td className="d-none d-md-table-cell">
                                                {t.getShortDateString(f.Created)}
                                            </td>
                                            <td>
                                                {f.Size}
                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </ScrollContent>)



}


export default ClassFiles;
