//@ts-check
import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import ScrollContent from 'components/ScrollContent';
import { useI18n } from "components/lni18n";
import CourseChangeItem from "./CourseChangeItem";



const ClassChanges = (props) => {

    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const studentData = currentClass.DashBoardData.StudentData;
    const { languageService: t } = useI18n();



    return (
        <ScrollContent scrollname="course-part">
            <div className="card-box big-card-box">
                <div className="card-box-title card-title">
                    <h3>{t.getText("updates")}</h3>
                </div>
                <div className="card-box-content card-body">
                    {studentData.Changes.CourseParts.map((part) => {
                        return <CourseChangeItem partData={part} classId={currentClass.Id} />
                    })}

                </div>
            </div>
        </ScrollContent>
    );
}


export default ClassChanges;