

const filterType = /^(?:image\/bmp|image\/cis-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x-cmu-raster|image\/x-cmx|image\/x-icon|image\/x-portable-anymap|image\/x-portable-bitmap|image\/x-portable-graymap|image\/x-portable-pixmap|image\/x-rgb|image\/x-xbitmap|image\/x-xpixmap|image\/x-xwindowdump)$/i;

/**
 * 
 * @param {File} file 
 * @param {{name: string, maxwidth: number, maxheight: number }[]} options
 * @returnss {Promise.<any>}
 */
const ImagePreview = async (file, options) => {

    if (!filterType.test(file.type)) {
        return null;
    }

    if (!options || options.length === 0) {
        options = [{ name: "thumb", maxheight: 200, maxwidth: 200 }];
    }

    var fileReader = new FileReader();

    /**
     * 
     * @param {HTMLImageElement} image 
     * @param {number} maxwidth 
     * @param {number} maxheight 
     * @returns { import("./FileUploader").imagePreviewData}
     */
    const shrinkImage = (image, maxwidth, maxheight) => {
        if (!image || !maxwidth || !maxheight) {
            return null;
        }

        let retVal = {
            originalWidth : image.width,
            originalHeight: image.height,
            src : null,
            width: 0,
            height: 0
        }

        var scale = image.height / image.width;
        var height = maxheight;
        var width = maxwidth;

        if (image.height <= maxheight && image.width <= maxwidth) {
            height = image.height;
            width = image.width;
        }
        else{
            if (scale > 1 && image.height > maxheight) { // standing up
                width = Math.round(height / scale);
            }

            if (scale < 1 && image.width > maxwidth) {
                height = Math.round(width * scale);
            }
        }

        retVal.height = height;
        retVal.width = width;

        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;
        context.drawImage(image,
            0, 0, image.width, image.height, 0, 0, width, height);

        retVal.src = canvas.toDataURL();
        return retVal;
    }


    const promise = new Promise((resolve, reject) => {
        fileReader.onload = (ev) => resolve(ev.target.result);
        fileReader.readAsDataURL(file);
    });


    /**
     * 
     * @param {string} fileUrl 
     * @returns { import("@reduxjs/toolkit").Dictionary<import("./FileUploader").imagePreviewData> }
     */
    const createData = (fileUrl) => {

        return new Promise((resolve, reject) => {
            try {
                var image = new Image();
                image.onload = () => {
                    let result = {};
                    options.forEach(o => {
                          result[o.name] = shrinkImage(image, o.maxwidth, o.maxheight);
                    });
                    resolve(result);
                }
                image.src = fileUrl;
            } catch (error) {
                reject(error);
            }
        });
    }

    const fileUrl = await promise;

    const result = await createData(fileUrl);

    return result;

}


export default ImagePreview