//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import { errorDuck } from "components/ErrorHandler/ErrorHandlerDuck";

/**
 * @type {import('./Search').SearchState}
 */
const initialState = {
    searchText: null,
    lastSearchText: null,
    history: [],
    page: 1,
    result: null,
    showSearch: false
};

const searchSlice = createSlice({
    name: 'search',
    initialState: initialState,
    reducers: {

        /**
        * @param  {  {payload: string}} action
        */
        setSearchText(state, action) {
            state.searchText = action.payload;
        },

        /**
        * @param  {  {payload: string}} action
        */
        setLastSearchText(state, action) {
            state.lastSearchText = action.payload;
        },

        /**
       * @param  {  {payload: import('./Search').SearchResultsDto}} action
       */
        setSearchResult(state, action) {
            state.result = action.payload;
        },

        /**
      * @param  {  {payload: number}} action
      */
        setPage(state, action) {
            state.page = action.payload;
        },

        /**
         * @param  {  {payload: boolean}} action
         */
        toggleShow(state, action) {
            state.showSearch = action.payload;
        },

        /**
         * @param  {  {payload: string}} action
         */
        addToHistory(state, action) {
            state.history.splice(0,0, action.payload);
        },



    }

});


const doSearch = async (dispatch, getState) => {

    try {
        /**
         * @type {import('./search').SearchState}
         */
        const state = getState().search;
        const url = endPoint.SEARCH_URL(state.searchText, state.page);

        if (state.searchText === null || state.searchText === "") {
            dispatch(searchDuck.setSearchResult(null));
            return;
        }
        /**
         * @type {{data: import('./search').SearchResultsDto}} response
         */
        const response = await instance.get(url);
        if( !response){
            return;
        }

        dispatch(searchDuck.setSearchResult(response.data));
        dispatch((searchDuck.setLastSearchText(state.searchText)));
        dispatch(searchDuck.setPage(1));
        dispatch(searchDuck.addToHistory( state.searchText ));


    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 * 
 * @param {Func} dispatch 
 * @param {Func} getState 
 * @param { "next" | "prev" } pagemove 
 */
const fetchMoreResults = async (dispatch, getState, pagemove) => {



    try {
        /**
         * @type {import('./search').SearchState}
         */
        const state = getState().search;
        const move = pagemove === "next" ? 1 : -1;
        if (state.result.HasMoreResults !== true && move === 1) {
            return;
        }

        if (state.page + move < 1) {
            return;
        }


        const url = endPoint.SEARCH_URL(state.lastSearchText, state.page + move);

        /**
         * @type {{data: import('./search').SearchResultsDto}} response
         */
        const response = await instance.get(url);
        if( !response){
            return;
        }
        dispatch(searchDuck.setSearchResult(response.data));
        dispatch(searchDuck.setPage(state.page + move));


    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }

}

export const searchDuck = {
    ...searchSlice.actions, doSearch, fetchMoreResults
};
export default searchSlice.reducer;