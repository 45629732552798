//@ts-check
import React from "react";

/**
 * ReceiversText
 * @module features/classes/ClassItems/ClassMail/ReceiversText
 * @return an HTML snippet displaying receiver info.
 * @param {{
    userList: any,
    t: any,
}} props
 */
const ReceiversText = ({ userList, t }) => {

    if (!userList) {
        return
    }
    const selected = {
        Students: userList.Students.filter(r => r.Checked),
        Teachers: userList.Teachers.filter(r => r.Checked)
    }

    let output = '', teacherOut = '', studentOut = '', removeValue = 0;
    if (selected[ 'Teachers' ] && selected[ 'Teachers' ].length > 0) {
        output += '<div><strong>' + t.getText('teachers') + ':</strong> ';
        teacherOut += selected[ 'Teachers' ][ 0 ].Receiver.FullName;
        removeValue++;
    }
    if (selected[ 'Teachers' ] && (selected[ 'Teachers' ].length === 2 || selected[ 'Teachers' ].length > 3 )) {
        teacherOut += ', ' + selected[ 'Teachers' ][ 1 ].Receiver.FullName;
        removeValue++;
    }
    if (selected[ 'Teachers' ] && selected[ 'Teachers' ].length > 2) {
        teacherOut = t.getText('mail.receivers.teachers.chosen', [ teacherOut, (selected[ 'Teachers' ].length - removeValue) ]);
    }
    if (output) {
        output += teacherOut + '</div>';
    }
    removeValue = 0;
    if (selected[ 'Students' ] && selected[ 'Students' ].length > 0) {
        output += '<div><strong>' + t.getText('students') + ':</strong> ';
        studentOut += selected[ 'Students' ][ 0 ].Receiver.FullName;
        removeValue++;
    }
    if (selected[ 'Students' ] && (selected[ 'Students' ].length === 2 || selected[ 'Students' ].length > 3 )) {
        studentOut += ', ' + selected[ 'Students' ][ 1 ].Receiver.FullName;
        removeValue++;
    }
    if (selected[ 'Students' ] && selected[ 'Students' ].length > 2) {
        studentOut = t.getText('mail.receivers.students.chosen', [ studentOut, (selected[ 'Students' ].length - removeValue) ]);
    }
    if (output && studentOut) {
        output += studentOut + '</div>';
    }

    return (<div className="d-flex flex-column" dangerouslySetInnerHTML={{ __html: output || '<div>' + t.getText('no.receivers.chosen') + '</div>' }}></div>)
}
export default ReceiversText;
