export class debouncer{

    func = ()=>{};
    timer = null;
    timeout = 300;

    constructor(func, timeout = 300){
        this.func = func;
        this.timeout = timeout;
    }
    
    debounce = (...args) => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => { this.func.apply(this, args); }, this.timeout);
    };

    clear = ()=>{
        clearTimeout(this.timer);
    }
  }