//@ts-check
import React from "react";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";

/**
 * @param  { { onChange: Function, subjectType: string, setFlags: string[], permission: import('./Permissions').ObjectPermissionRowDto}} props;
 */
const PermissionRow = ({ onChange, subjectType, permission, setFlags }) => {

    const { languageService: t } = useI18n();

    let boxes = [];

    for (let i = 0; i < setFlags.length; i++) {
        const flag = setFlags[i];
        const p = permission.Permissions[flag];
        if (p) {
            boxes.push({ ...p, name: flag });
        }
    }


    return <div className="d-flex mb-2 border-top pt-2">
        <div className="mr-2">
            <LnIcon name={subjectType} className="more" />
        </div>
        <div className="mr-2 overflow-hidden" title={permission.Name}>
            {permission.Name}
        </div>
        <div className="d-flex ml-auto">
            {
                boxes.map((p, index) => <div key={index} >

                    <div className="custom-control custom-checkbox mb-4">
                        <input className="custom-control-input" type="checkbox"
                            onChange={(e) => onChange(e, permission.UserOrGroupId, subjectType, p.name, false)} checked={p.Set} id={"l" + permission.UserOrGroupId + p.name} />
                        <label className="custom-control-label pt-1" htmlFor={"l" + permission.UserOrGroupId + p.name} >{t.getText(p.name)}</label>
                    </div>
                </div>)
            }

            <div className="ml-2 mt-1">
                <button title={t.getText("remove")} className="btn btn-link" onClick={(e)=>onChange(e, permission.UserOrGroupId, subjectType, 'read', true)}>
                    <LnIcon className="natural" name="delete" />
                    </button>
                    </div>
        </div>

    </div>

}

export default PermissionRow;