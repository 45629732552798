//@ts-check
import { createSlice } from '@reduxjs/toolkit'

/**
 * @type import('./Language').TextState
 */
const initialState = {
    text: {},
    languages: [],
    currentLang: null
};

const textSlice = createSlice({
    name: 'text',
    initialState: initialState,
    reducers: {

        /**
         * @param {{payload: import('types/types').Dictionary<string>}} action
         */
        addDictionary(state, action) {
            state.text = action.payload;
        },

        /**
         * @param {{payload:import("./Language").LanguageDto[]}} action
         */
        addLanguages(state, action) {

            state.languages = action.payload;

            const lang = action.payload.find((l) => l.IsCurrent === true);
            state.currentLang = lang;
        },

        /**
         * @param {{payload: number}} action
         */
        setCurrentLang(state, action) {

            state.languages.forEach(l => l.IsCurrent = (l.Id === action.payload))
            let lang = state.languages.find(l => l.Id === action.payload);
            state.currentLang = lang;
            if (document) {
                const html = document.getElementsByTagName("html");
                if (html && html.length > 0) {
                    html[0].setAttribute('lang', lang.Short);
                }
            }
        }
    }
});

export const { addDictionary, addLanguages, setCurrentLang } = textSlice.actions;
export default textSlice.reducer;