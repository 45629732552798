import React from "react";
import { courseDuck } from "features/course/CourseDuck";
import EvalForm from "./EvalForm";
import EvalStatistics from "./Evalstatistics";

const Evals = (props) => {
    const part = courseDuck.useCurrentPart();
 
    if( !part || !part.Eval){
        return null;
    }

    if(  part && part.Eval && part.Eval.Submitted === false ){
        return <EvalForm />
    }
    return <EvalStatistics />
}

export default Evals;