//@ts-check

import { classesDuck } from "./features/classes/ClassesDuck";
import { timetableDuck } from "./features/classes/ClassItems/TimeTable/TimeTableDuck";
import { courseDuck } from "./features/course/CourseDuck";
import { lectureDuck } from "./features/course/part/Lectures/LectureDuck";
import { LoginDuck } from "features/login/LoginDuck";


import TestService from "./features/course/part/test/TestService";
import { QATestDuck } from "features/course/part/QATest/QATestDuck";
import { MQATestDuck } from "./features/course/part/MQATest/MQATestDuck";
import { mcTestDuck } from "./features/course/part/McTest/McTestDuck";
import { feedDuck } from "./features/classes/ClassItems/Feed/FeedDuck";
import { forumService } from "./features/classes/ClassItems/Forum/ForumDuck";
import { classMailDuck } from "./features/classes/ClassItems/ClassMail/ClassMailDuck";
import { singleMailDuck } from "features/General/SingleMail/SingleMailDuck";
import { classFilesDuck } from "./features/classes/ClassItems/ClassFiles/ClassFilesDuck";
import { userDuck } from "./features/User/UserDuck";
import languageService from "features/language/languageService";
import { docBoxDuck } from "features/General/DocBox/DocBoxDuck";
import { d3GraphDuck } from "ducks/d3Graph/D3GraphDuck"
import { endPoint } from "AppConstants";
import instance from "utils/axios";

import assertTeacherComponents from "./LoadTeachersFeatures";

/* from the parameters, make sure all course data are available that are needed for a route */
/**
 * 
 * @param {Function} getState 
 * @param {Function} dispatch 
 * @param {string} classid 
 * @param {string | null} themeid 
 * @param {string| null} partid 
 * @param {string | null} partTab 
 * @param {boolean | null} forceClass 
 * 
 * @returns {  Promise<import('features/classes/Classes').ClassAllDataDto> }
 */
const assertCourseData = async (getState, dispatch, classid, themeid, partid, partTab, forceClass) => {

    if (!classid) {
        try {
            /**
            * @type {import('features/login/login').LoginState}
             */
            const { user, loggedIn } = getState().login;
            if (!loggedIn) {
                return null;
            }

            if (user && user.CurrentClass) {
                classid = user.CurrentClass
            }
            else {
                const resp = await instance.get(endPoint.USER_CURRENT_CLASSID_URL);
                if (resp) {
                    classid = resp.data;
                    if (classid == null) {
                        return null;
                    }
                }
            }
        } catch (error) {
            return null;
        }
    }

    /**
    * @type {{ classData: import('features/classes/Classes').ClassState, course: any}}
    */
    let {
        classData: { currentClass, eventsSinceDate },
        course
    } = getState();

    const currentPart = await courseDuck.getCurrentPart(course);

    if (partid && currentPart && currentPart.Id !== partid) {
        dispatch(courseDuck.setCurrentPart(null));
    }

    if (themeid && course.currentThemeId && course.currentThemeId !== themeid) {
        dispatch(courseDuck.setCurrentTheme(null));
    }

    if (forceClass || currentClass == null || currentClass.Id !== classid) {


        if (currentClass && currentClass.Id === classid) {  // load async but not waiting
            classesDuck.getCurrentClass(dispatch, classid, eventsSinceDate, false)
                .then((classdata) => {
                    if (classdata) {
                        dispatch(courseDuck.setCurrentCourse({ themeId: themeid, courseData: { Themes: classdata.Themes } }))
                    }
                });
        }
        else {
            // load and wait
            currentClass = await classesDuck.getCurrentClass(dispatch, classid, eventsSinceDate);
            if (currentClass) {
                dispatch(courseDuck.setCurrentCourse({ themeId: themeid, courseData: { Themes: currentClass.Themes } }))
            }
        }


    }

    dispatch(courseDuck.setCurrentTheme(themeid));

    let getPart = false;
    if (partid != null) {
        if (currentPart == null || currentPart.Id !== partid) {
            dispatch(courseDuck.setCurrentPart(null));
            getPart = true;
        }
    }
    else {
        dispatch(courseDuck.setCurrentPart(null));
    }



    if (getPart === true) {
        await courseDuck.getCoursePart(dispatch, { classId: classid, partId: partid });
    }

    if (partTab != null) {
        dispatch(courseDuck.setPartTab(partTab.toUpperCase()));
    }

    return currentClass;
}


/* ************************************************************* */

const classPaths = {
    'CLASS': {
        path: '/class/:classid',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
                login: { user }

            } = getState()

            let realClassId = classid;
            if (classid === "none") {
                realClassId = user.currentClass;
            }
            dispatch(forumService.setStartData(null));
            await assertCourseData(getState, dispatch, realClassId, null, null, null, true);

        }
    },

    'CLASS_NOTIFICATIONS': {
        path: '/class/:classid/notifications',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
                login: { user }

            } = getState()

            let realClassId = classid;
            if (classid === "none") {
                realClassId = user.currentClass;
            }

            const classData = await assertCourseData(getState, dispatch, realClassId, null, null, null, false);
            if (!classData) {
                return;
            }
            dispatch(classesDuck.getNotifications(realClassId));

        }
    },



    'CLASS_NOTIFICATIONS_EDIT': {
        path: '/class/:classid/notifications/edit',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
                login: { user },

            } = getState()

            let realClassId = classid;
            if (classid === "none") {
                realClassId = user.currentClass;
            }

            const classData = await assertCourseData(getState, dispatch, realClassId, null, null, null, false);
            if (!classData) {
                return;
            }
            await dispatch(classesDuck.getNotifications(realClassId, true));
        }
    },

    'CLASS_NOTIFICATIONS_EDIT_NOTIFICATION': {
        path: '/class/:classid/notifications/edit/:id',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid, id } },
                login: { user },

            } = getState()

            let realClassId = classid;
            if (classid === "none") {
                realClassId = user.currentClass;
            }
            console.log("edit not");
            const currentClass = await assertCourseData(getState, dispatch, realClassId, null, null, null, false);
            if (!currentClass) {
                return;
            }
            if (currentClass.Id !== realClassId || !currentClass.notificationsToEdit) {
                await dispatch(classesDuck.getNotifications(realClassId, true));
            }
            dispatch(classesDuck.getNotificationForEdit(classid, id));

        },
        confirmLeave: (state, action) => {
            if (state.classData.NotificationInEdit && state.classData.NotificationInEdit.dirty === true) {
                return { text: languageService.getText("save.before.close"), type: "stayOnYes" };
            }
        }
    },

    'CLASS_TIMETABLE': {
        path: '/class/:classid/timetable',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
                login: { user }

            } = getState()

            let realClassId = classid;
            if (classid === "none") {
                realClassId = user.currentClass;
            }

            const classData = await assertCourseData(getState, dispatch, realClassId, null, null, null, false);
            if (!classData) {
                return;
            }
            dispatch(timetableDuck.getTimetableStartData(realClassId));

        }
    },

    'CLASS_INFO': {
        path: '/class/:classid/intro',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
            } = getState()

            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }
            dispatch(classesDuck.getClassMembers(classid));
        }
    },

    'CLASS_FILES': {
        path: '/class/:classid/files',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
            } = getState()

            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }
            dispatch(classFilesDuck.fetchRootFolder(classid, classData.CurrentUserIsTeacher));
        }
    },

    'CLASS_FILES_EDIT': {
        path: '/class/:classid/editfiles',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
            } = getState()

            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }
            await dispatch(classFilesDuck.fetchRootFolder(classid, classData.CurrentUserIsTeacher));



        }
    },


    'CLASS_PERSONAL_PLAN': {
        path: '/class/:classid/personalplan',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },

            } = getState()

            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }
            window.setTimeout(() => {
                const {
                    login: { user }
                } = getState()
                userDuck.getUserProgress(user.Id, classid, dispatch);
            }, 100);
        }
    },

    'CLASS_PROGRESS': {
        path: '/class/:classid/progress/:type',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },

            } = getState()

            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }
            window.setTimeout(() => {
                const {
                    login: { user }
                } = getState()
                userDuck.getUserProgress(user.Id, classid, dispatch);
            }, 100);

        }
    },


    'CLASS_FEED': {
        path: '/class/:classid/feed',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
            } = getState()

            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }
            dispatch(feedDuck.setStartData(null));
            dispatch(feedDuck.getFeedStartDataForClass(classid));
        }
    },

    'CLASS_FORUM': {
        path: '/class/:classid/forum',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
                forum,
            } = getState()

            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }

            if (forum.currentForum && forum.classId !== classid) {
                dispatch(forumService.setStartData(null));
            }
            var data = await forumService.getForumStartDataForClass(classid, dispatch, getState);
            if (data && data.IsTeacher) {
                assertTeacherComponents();
            }
        }
    },

    'CLASS_FORUM_THREAD': {
        path: '/class/:classid/forum/:forumId/thread/:thread',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid, thread } }
            } = getState()

            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }
            var data = await forumService.getForumStartDataForClass(classid, dispatch, getState);
            if (data && data.IsTeacher) {
                await assertTeacherComponents();
            }
            dispatch(forumService.getThreadData(thread));


        }
    },


    'GENERAL_FEEDBACK_FORUM': {
        path: '/general/feedback',
        thunk: async (dispatch) => {
            dispatch(forumService.setStartData(null));
            await forumService.getForumStartDataForFeed(dispatch);
        }
    },

    'GENERAL_FEEDBACK_THREAD': {
        path: '/general/feedback/thread/:thread',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { thread } }
            } = getState()


            await forumService.getForumStartDataForFeed(dispatch);

            dispatch(forumService.getThreadData(thread));


        }
    },


    'CLASS_MAIL': {
        path: '/class/:classid/mail/:listtype',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
            } = getState()

            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }
            dispatch(classMailDuck.getMailListsForClass(classid));
        }
    },

    'CLASS_MAIL_EDIT': {
        path: '/class/:classid/mail/:listtype/:mailid',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid, mailid } },
            } = getState()

            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }

            await dispatch(classMailDuck.getMailListsForClass(classid));
            classMailDuck.getMail(classid, mailid, dispatch);
        },
        confirmLeave: (state, action) => {
            if (state.classMail.dirty === true) {
                return { text: languageService.getText("save.before.close"), type: "stayOnYes" };
            }
        }
    },




    'CLASS_CHANGES': {
        path: '/class/:classid/changes',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
            } = getState()

            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }

        }
    },

    'CLASS_SUBGROUP': {
        path: '/class/:classid/group/:groupid/:type',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid, type, groupid } },
            } = getState()


            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }

            switch (type) {

                case "info":
                    dispatch(forumService.setFilterData(null));
                    classesDuck.getGroupMembers(dispatch, classid, groupid);
                    break;

                case "feed":
                    dispatch(forumService.setFilterData(null));
                    dispatch(feedDuck.getFeedStartDataForSubGroup(classid, groupid));
                    break;

                case "forum":

                    forumService.getForumStartDataForSubGroup(classid, groupid, dispatch, getState);
                    break;

                default:
                    dispatch(forumService.setFilterData(null));
                    break;

            }

        }
    },

    'CLASS_SUBGROUP_FORUM_THREAD': {
        path: '/class/:classid/group/:groupid/:type/thread/:thread',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid, thread, groupid } },
            } = getState()

            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }
            await forumService.getForumStartDataForSubGroup(classid, groupid, dispatch, getState);
            dispatch(forumService.getThreadData(thread));
        }
    },


    'CLASS_SUBGROUP_MAIL': {
        path: '/class/:classid/group/:groupid/:type/:listtype',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid, groupid } },
            } = getState()


            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }

            dispatch(classMailDuck.getMailListsForSubGroup(classid, groupid, true));
        }
    },

    'CLASS_SUBGROUP_MAIL_EDIT': {
        path: '/class/:classid/group/:groupid/:type/:listtype/:mailid',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid, mailid, groupid } },
            } = getState()


            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            if (!classData) {
                return;
            }
            await dispatch(classMailDuck.getMailListsForSubGroup(classid, groupid, false));
            classMailDuck.getMail(classid, mailid, dispatch);
        },
        confirmLeave: (state, action) => {
            if (state.classMail.dirty === true) {
                return { text: languageService.getText("save.before.close"), type: "stayOnYes" };
            }
        }
    },





}

const coursePaths = {
    'CLASS_THEME': {
        path: '/class/:classid/theme/:themeid',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { themeid, classid } },
            } = getState()

            const classData = await assertCourseData(getState, dispatch, classid, themeid, null, null, false);
            if (!classData) {
                return;
            }
        }
    },

    'CLASS_THEME_PART': {
        path: '/class/:classid/theme/:themeid/part/:partid/:type',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid, themeid, partid, type } },
            } = getState();

            const classData = await assertCourseData(getState, dispatch, classid, themeid, partid, type, false);
            if (!classData) {
                return;
            }


        }
    },

    'CLASS_THEME_PART_TEST': {
        path: '/class/:classid/theme/:themeid/part/:partid/runtest/:testid/:testtype',
        thunk: async (dispatch, getState) => {

            dispatch(mcTestDuck.resetMcTest({}));
            dispatch(QATestDuck.resetQATest({}));
            dispatch(MQATestDuck.resetMQATest({}));
            dispatch(courseDuck.setTestReadyToView({ state: false }));
            const {
                location: { payload: { classid, themeid, partid, testid, testtype } },
            } = getState();

            const classData = await assertCourseData(getState, dispatch, classid, themeid, partid, "runtest", null);
            if (!classData) {
                return;
            }

            TestService.getTest(dispatch, { classid, testtype, testid });
        },

        confirmLeave: (state, action) => {
            const dirty = state.mctest.dirty;
            const testtype = state.location.payload.testtype;

            if (testtype.indexOf("multiplechoice") > -1 && dirty === true) {
                return { text: languageService.getText("test.warning.close_without_submit"), type: "leaveOnYes" };
            }
        }
    },


    'CLASS_THEME_PART_LECTURE_PLAY': {
        path: '/class/:classid/theme/:themeid/part/:partid/lecture/:lectureid/:slide',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid, themeid, partid, lectureid, slide } },
            } = getState();

            const classData = await assertCourseData(getState, dispatch, classid, themeid, partid, null, false);
            if (!classData) {
                return;
            }

            const {
                lecture: { currentLecture }
            } = getState();

            if (currentLecture !== null && currentLecture.LectureID === lectureid && currentLecture.Screens) {
                dispatch(lectureDuck.setCurrentScreen({ screenNumber: slide - 1 }));
            }
            else {
                lectureDuck.getCurrentLecture(getState, dispatch, classid, lectureid, slide - 1);
            }

        }
    },
    'CLASS_THEME_PART_LECTURE_PRINT': {
        path: '/class/:classid/theme/:themeid/part/:partid/lecture/:lectureid/print',
        thunk: async (dispatch, getState) => {
            const {

                location: { payload: { classid, themeid, partid, lectureid } },
            } = getState();

            const classData = await assertCourseData(getState, dispatch, classid, themeid, partid, null, false);
            if (!classData) {
                return;
            }
            lectureDuck.getCurrentLecture(getState, dispatch, classid, lectureid, 0);

        }
    }
}

const basePaths = {

    'ROOT': {
        path: '/'
    },
    'LOGIN':
    {
        path: '/login',
    },
    'LOGIN_NEW_PASSWD':
    {
        path: '/login/ChangePasswd/:userid/:key/:d/:ticket',
        thunk: async (dispatch, getState) => {
            const {

                location: { payload: { userid, key, d, ticket } },
            } = getState();
            await dispatch(LoginDuck.GetChangePasswordData({ UserId: userid, Key: key, Date: d, Ticket: ticket }));
        }
    },
    'LOGIN_VERIFY_EMAIL':
    {
        path: '/mail/verify/:ticket/expire/:stamp/signature/:signature'
    },

    'LOGIN_REGISTER':
    {
        path: '/registration/:classid/:formid',
        thunk: async (dispatch, getState) => {
            const {

                location: { payload: { classid, formid } },
            } = getState();
            await dispatch(LoginDuck.GetSignOnData({ classid, formid }));
        }
    },



    'NOT_FOUND': {
        path: '/not-found'
    },
    'ADMIN': {
        path: '/admin'
    }
}

const generalPaths = {


    'GENERAL_TEACHER_OVERVIEW': {
        path: '/general/teacheroverview',
        thunk: async (dispatch, getState) => {

            dispatch(classesDuck.getTeacherClasses());
        }
    },

    'GENERAL_SINGLE_MAIL': {
        path: '/general/singlemail/:listtype',
        thunk: async (dispatch, getState) => {
            await assertCourseData(getState, dispatch, null, null, null, null, false);
            await singleMailDuck.getMailLists(dispatch, getState);
        }
    },

    'GENERAL_SINGLE_MAIL_EDIT': {
        path: '/general/singlemail/:listtype/:mailid',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { mailid } }

            } = getState()

            await assertCourseData(getState, dispatch, null, null, null, null, false);

            await singleMailDuck.getMailLists(dispatch, getState);
            singleMailDuck.getMail(mailid, dispatch);
        },
        confirmLeave: (state, action) => {
            if (state.singleMail.dirty === true) {
                return { text: languageService.getText("save.before.close"), type: "stayOnYes" };
            }
        }
    },

    'GENERAL_PERSONAL_SETTINGS': {
        path: '/general/user/:userid/:type',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { userid, type } },
                user: { userData, sessionData },
            } = getState();

            const prevUserId = userData && userData.Id;

            await assertCourseData(getState, dispatch, null, null, null, null, false);


            if (type === "personal") {
                await userDuck.getUserData(userid, dispatch, getState);
            }

            if (type === "root") {
                if (userData == null) {
                    await userDuck.getUserData(userid, dispatch, getState);
                }
            }

            if (type === "emailsettings") {
                if (userData == null) {
                    await userDuck.getUserData(userid, dispatch, getState);
                }
                if (prevUserId !== userid || sessionData == null) {
                    userDuck.getSessionData(userid, null, null, dispatch);
                }
            }

            if (type === "sessions") {
                if (userData == null) {
                    await userDuck.getUserData(userid, dispatch, getState);
                }
                userDuck.getSessionData(userid, null, null, dispatch);
            }

            if (type === "progress") {
                if (userData == null) {
                    await userDuck.getUserData(userid, dispatch, getState);
                }

                const {
                    login: { user }
                } = getState();

                const classId = user && user.CurrentClass;
                userDuck.getUserProgress(userid, classId, dispatch);
            }

        }
    },
    'GENERAL_PERSONAL_DATA_POLICY': {
        path: '/general/personal-data-policy/:userid',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { userid } },
            } = getState();
            await assertCourseData(getState, dispatch, null, null, null, null, false);
            dispatch(userDuck.GetPolicyData(userid));
        }
    },

    'GENERAL_COURSE_OVERVIEW': {
        path: '/general/course-overview',
        thunk: (dispatch, getState) => {
            dispatch(classesDuck.getClassOverview());
            assertCourseData(getState, dispatch, null, null, null, null, false);
        }
    },

    'GENERAL_DOCBOX': {
        path: '/general/docbox/:type',   // type =  "Start"|"Shared"|"Trash"|"SharedTrash"; 
        thunk: async (dispatch, getState) => {

            await assertCourseData(getState, dispatch, null, null, null, null, false);

            const {
                location: { payload: { type } }
            } = getState()

            await docBoxDuck.getStartData(dispatch);

            if (type === "Shared") {
                docBoxDuck.getSharedData(dispatch, 0);
            }

            if (type === "Trash") {
                docBoxDuck.getTrashData(dispatch);
            }

            if (type === "SharedTrash") {
                docBoxDuck.getSharedTrashData(dispatch);
            }


        }
    },

    'GENERAL_SWITCH_USER': {
        path: '/general/switch-user',
        thunk: (dispatch, getState) => {
            assertCourseData(getState, dispatch, null, null, null, null, false);
        }
    },
}



const teacherPaths = {

    'CLASS_LIST_FINISHED': {
        path: '/finishedClasses',
        thunk: async (dispatch, getState) => {
            const { teacherData } = getState().classData;

            if (!teacherData) {
                dispatch({ type: "ROOT" });
            }
        }
    },


    'CLASS_FULL_PROGRESS_PARTIAL': {
        path: '/class/:classid/classprogress/:type/:param/:resultview',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid, resultview, param } },
            } = getState()

            const importPromise = assertTeacherComponents();
            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            const { TestResultService } = await importPromise;

            if (!TestResultService || !classData) {
                return;
            }

            await TestResultService.fetchClassTestResults({ singleResult: null, classId: classid, skipIfExists: true, dispatch, getState });
            if (resultview === "bytest") {
                await TestResultService.fetchResultsForOneTest({ classId: classid, testId: param, dispatch });
            }
        }
    },

    'CLASS_FULL_PROGRESS': {
        path: '/class/:classid/classprogress/:type',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid, type } },
            } = getState()

            const importPromise = assertTeacherComponents();
            const classData = await assertCourseData(getState, dispatch, classid, null, null, null, false);
            const { TestResultService, classActivityDuck } = await importPromise;

            if (!TestResultService || !classActivityDuck || !classData) {
                console.error("Could not fetch lazy loaded components: TestResultService, classActivityDuck, classData")
                return;
            }


            switch (type) {

                case "loggedin":
                    dispatch(d3GraphDuck.fetchLoggedInData(classid));
                    break;
                case "watched":
                    dispatch(classActivityDuck.fetchWatchedData(classid));
                    break;
                case "activity":
                    dispatch(classActivityDuck.fetchActivityData(classid));
                    break;
                case "links":
                    dispatch(classActivityDuck.fetchLinksData(classid));
                    break;
                case "files":
                    dispatch(classActivityDuck.fetchFilesData(classid));
                    break;
                case "results":
                    let singleStudentResult = classData.studentForSingleTestResult;
                    if (singleStudentResult) {
                        dispatch(classesDuck.setStudentForSingleTestResult(null));
                    }
                    await TestResultService.fetchClassTestResults({ singleResult: singleStudentResult, classId: classid, skipIfExists: false, dispatch, getState });
                    break;
                default:
                    break;
            }
        }
    },

    'CLASS_EVAL_SUMMARY': {
        path: '/class/:classid/evalsummary/:type',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
            } = getState()

            const importPromise = assertTeacherComponents();
            await assertCourseData(getState, dispatch, classid, null, null, null, false);
            const { evalSummaryDuck } = await importPromise;

            if (!evalSummaryDuck) {
                return;
            }

            dispatch(evalSummaryDuck.fetchSummaryData(classid));

        }
    },

    'CLASS_STUDENTS_TABLE': {
        path: '/class/:classid/table',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
            } = getState()

            const importPromise = assertTeacherComponents();
            await assertCourseData(getState, dispatch, classid, null, null, null, false);
            const { studentsTableDuck } = await importPromise;

            if (!studentsTableDuck) {
                return;
            }

            dispatch(studentsTableDuck.getTableData(classid));

        }
    },

    'CLASS_SUBSCRIPTION': {
        path: '/class/:classid/subscription',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
            } = getState();

            const importPromise = assertTeacherComponents();
            await assertCourseData(getState, dispatch, classid, null, null, null, false);
            const { subscriptionsDuck } = await importPromise;

            if (!subscriptionsDuck) {
                return;
            }

            dispatch(subscriptionsDuck.GetDataForClass(classid));

        }
    },

    'CLASS_ATTENDANCE': {
        path: '/class/:classid/attendance',
        thunk: async (dispatch, getState) => {
            const {
                location: { payload: { classid } },
            } = getState()

            const importPromise = assertTeacherComponents();
            await assertCourseData(getState, dispatch, classid, null, null, null, false);
            const { attendanceDuck } = await importPromise;

            if (!attendanceDuck) {
                return;
            }

            dispatch(attendanceDuck.getMeetup(classid));

        }
    },

}

export const routePaths = { ...basePaths, ...classPaths, ...coursePaths, ...generalPaths, ...teacherPaths }