//@ts-check
import { createSlice } from "@reduxjs/toolkit";
import instance from "utils/axios";


/* All the redux stuff needed for ImagePicker feature. LäraNära Portal */

/** @type {import('./ImagePicker_types').ImagePickerState} */
const initialState = {
    imageData: null,
    modalOpen: false,
    tab: 'browse',
    subTab: 'preview',
    selectedImage: null,
    filteredImages: null,
    imageAlign: 'none',
    imagePreviewWidth: 0,
    isUploading: false,
    currentImage: null,
    deleteRequested: false,
    alignData: null
}

/****************** reducers ************************/
const ImagePickerSlice = createSlice({
    name: "imagepicker",
    initialState: initialState,
    reducers: {

        /**
         * @param {import('./ImagePicker_types').ImagePickerState} state 
         * @param {{payload: import('./ImagePicker_types').ImagePickerImageListDto}} action 
         */
        setImagesDate(state, action) {
            state.imageData = action.payload;
            state.selectedImage = null;
            state.tab = 'browse';
            state.imageAlign = 'none';
            state.imagePreviewWidth = 0;
            state.isUploading = false;
            state.currentImage = null;
            state.deleteRequested = false;
            state.subTab = 'preview';
            if (action.payload != null) {
                state.filteredImages = [...action.payload.Images];
            }
            else {
                state.filteredImages = null;
            }
        },

        /**
        * @param {import('./ImagePicker_types').ImagePickerState} state 
        * @param {{payload: boolean}} action 
        */
        setModalState(state, action) {
            state.modalOpen = action.payload;
        },

        /**
        * @param {import('./ImagePicker_types').ImagePickerState} state 
        * @param {{payload: import("./ImagePicker_types").ImageAlignData}} action 
        */
        setAlingData(state, action) {
            state.alignData = action.payload;
            if (action.payload) {
                state.imageAlign = action.payload.imageAlign;
            }
            else {
                state.imageAlign = "none";
            }
        },



        /**
        * @param {import('./ImagePicker_types').ImagePickerState} state 
        * @param {{payload: boolean}} action 
        */
        setIsUploading(state, action) {
            state.isUploading = action.payload;
        },

        /**
       * @param {import('./ImagePicker_types').ImagePickerState} state 
       * @param {{payload: boolean}} action 
       */
        setDeleteRequested(state, action) {
            state.deleteRequested = action.payload;
        },

        /**
        * @param {import('./ImagePicker_types').ImagePickerState} state 
        * @param {{payload: 'browse' | 'selected' | 'web' | 'upload'}} action 
        */
        setTab(state, action) {
            state.tab = action.payload;
        },

        /**
       * @param {import('./ImagePicker_types').ImagePickerState} state 
       * @param {{payload: 'preview' | 'information' | 'permissions'}} action 
       */
        setSubTab(state, action) {
            state.subTab = action.payload;
        },

        /**
        * @param {import('./ImagePicker_types').ImagePickerState} state 
        * @param {{payload: string}} action 
        */
        setSelectedImage(state, action) {
            state.selectedImage = state.imageData.Images.find(i => i.ImageId === action.payload);
            state.subTab = 'preview';
        },

        /**
         * @param {import('./ImagePicker_types').ImagePickerState} state 
         * @param {{payload: string}} action 
         */
        setSelectedUser(state, action) {
            if (!action.payload || action.payload === null) {
                state.filteredImages = [...state.imageData.Images];
            } else {
                state.filteredImages = state.imageData.Images.filter(i => i.User.Id === action.payload);
            }
        },

        /**
        * @param {import('./ImagePicker_types').ImagePickerState} state 
        * @param {{payload: 'none' | 'left' | 'right'}} action 
        */
        setImageAlign(state, action) {
            state.imageAlign = action.payload;
        },


        /**
        * @param {import('./ImagePicker_types').ImagePickerState} state 
        * @param {{payload: number}} action 
        */
        setImagePreviewWidth(state, action) {
            state.imagePreviewWidth = action.payload;
        },


        /**
        * @param {import('./ImagePicker_types').ImagePickerState} state 
        * @param {{payload: import('./ImagePicker_types').ImagePickerImageDto}} action 
        */
        setNewImage(state, action) {
            state.imageData.Images = [action.payload, ...state.imageData.Images];
            state.filteredImages = [...state.imageData.Images];
        },


        /**
        * @param {import('./ImagePicker_types').ImagePickerState} state 
        * @param {{payload: import('./ImagePicker_types').ImagePickerImageData}} action 
        */
        setCurrentImage(state, action) {
            state.currentImage = action.payload;
            state.deleteRequested = false;
        },


        /**
        * @param {import('./ImagePicker_types').ImagePickerState} state 
        * @param {{payload: void}} action 
        */
        deleteCurrentImageInState(state, action) {
            if (!state.currentImage) {
                return;
            }
            state.imageData.Images = state.imageData.Images.filter(i => i.ImageId !== state.currentImage.Overview.Id);
            state.currentImage = null;
            state.filteredImages = [...state.imageData.Images];
            state.selectedImage = null;
            state.deleteRequested = false;
        },

        /**
        * @param {import('./ImagePicker_types').ImagePickerState} state 
        * @param {{payload: import("./ImagePicker_types").ImagePickerPermissionDto}} action 
        */
        setPermissions(state, action) {
            if (!state.currentImage) {
                return;
            }
            state.currentImage.Permissions = action.payload;
        },
    }

});

/****************** service methods ************************/

const services = {


    /**
     *  get data for images
     * 
     */
    fetchImageData: () => async (dispatch) => {

        const response = await instance.get(urls.GET_IMAGES_DATA_URL);
        if (response != null) {
            await dispatch(imagePickerDuck.setImagesDate(response.data));
        };
    },



    /**
     * image uploaded to temp bucket
     * now it's time to add to server 
     * and to local state
     * 
     */
    addImage: (data) => async (dispatch) => {

        
        const serveData = await imagePickerDuck.addImageToSystem(data)
        if (serveData != null) {
            await dispatch(imagePickerDuck.setNewImage(serveData));

            return serveData.ImageId;
        };

        return null;
    },

    /**
     * image uploaded to temp bucket
     * now it's time to add to server 
     * 
     */
     addImageToSystem: async (data) => {

        /**
         * @type {{data: import('./ImagePicker_types').ImagePickerImageDto}}
         */
        const response = await instance.post(urls.ADDIMAGE_URL, data);
        if (response != null) {
            return response.data;
        };

        return null;
    },





    /**
    * image uploaded to temp bucket
    * @param {string} imageId 
    * 
    */
    getImageInfo: (imageId, currentuserid) => async (dispatch, getState) => {


        /**
        * @type {{selectedImage: import('./ImagePicker_types').ImagePickerImageDto}}
        */
        const { selectedImage } = getState().imagepicker;

        if (selectedImage.User.Id !== currentuserid) {
            dispatch(imagePickerDuck.setCurrentImage(null));
            return;
        }

        /**
         * @type {{data: import('./ImagePicker_types').ImagePickerImageData}}
         */
        const response = await instance.get(urls.GET_IMAGE_INFO_URL(imageId));
        if (response != null) {
            dispatch(imagePickerDuck.setCurrentImage(response.data));
        };
    },

    deleteCurrentImage: () => async (dispatch, getState) => {

        /**
         * @type {{currentImage: import('./ImagePicker_types').ImagePickerImageData}}
         */
        const { currentImage } = getState().imagepicker;

        const response = await instance.delete(urls.GET_IMAGE_DELETE_URL(currentImage.Overview.Id));
        if (response != null) {
            dispatch(imagePickerDuck.deleteCurrentImageInState());
            dispatch(imagePickerDuck.setTab('browse'));
        };
    },

    /**
     * 
     * @param {string} imageid 
     * @param {'unitroots' | 'unitadmins' | 'teachers'} type 
     * @param {boolean} state 
     */
    setPermission: (type, state) => async (dispatch, getState) => {

        /**
        * @type {{currentImage: import('./ImagePicker_types').ImagePickerImageData}}
        */
        const { currentImage } = getState().imagepicker;

        const data = {
            Value: state
        };

        let response;
        switch (type) {
            case 'unitroots':
                response = await instance.put(urls.GET_IMAGE_ROOTADMIN_PERMISSION_URL(currentImage.Overview.Id), data);
                if (response) {
                    const newData = { ...currentImage.Permissions, UnitRootsCanRead: state }
                    dispatch(imagePickerDuck.setPermissions(newData));
                }
                break;

            case 'unitadmins':
                response = await instance.put(urls.GET_IMAGE_UNITADMIN_PERMISSION_URL(currentImage.Overview.Id), data);
                if (response) {
                    const newData = { ...currentImage.Permissions, UnitAdminsCanRead: state }
                    dispatch(imagePickerDuck.setPermissions(newData));
                }
                break;

            case 'teachers':
                response = await instance.put(urls.GET_IMAGE_TEACHERS_PERMISSION_URL(currentImage.Overview.Id), data);
                if (response) {
                    const newData = { ...currentImage.Permissions, TeachersCanRead: state }
                    dispatch(imagePickerDuck.setPermissions(newData));
                }
                break;

            default:
                break;
        }


    },

    /**
     * 
     * @param {import("features/User/User").UserMailDto} user
     */
    addUserPermission: (user) => async (dispatch, getState) => {

        const data = {
            Value: user.Id
        };

        /**
        * @type {{currentImage: import('./ImagePicker_types').ImagePickerImageData}}
        */
        const { currentImage } = getState().imagepicker;

        const response = await instance.put(urls.GET_IMAGE_ADD_USER_PERMISSION_URL(currentImage.Overview.Id), data);
        if (response) {
            const newData = { ...currentImage.Permissions, Users: [...currentImage.Permissions.Users, user] }
            dispatch(imagePickerDuck.setPermissions(newData));
        }
    },

    removeUserPermission: (userid) => async (dispatch, getState) => {

        const data = {
            Value: userid
        };

        /**
        * @type {{currentImage: import('./ImagePicker_types').ImagePickerImageData}}
        */
        const { currentImage } = getState().imagepicker;

        const response = await instance.put(urls.GET_IMAGE_REMOVE_USER_PERMISSION_URL(currentImage.Overview.Id), data);
        if (response) {
            const newData = { ...currentImage.Permissions, Users: currentImage.Permissions.Users.filter(u => u.Id !== userid) };
            dispatch(imagePickerDuck.setPermissions(newData));
        }
    },

    getUsersForPermissions: async (query, imageId) => {
        const response = await instance.get(urls.GET_IMAGE_SEARCH_USERS_URL(imageId, query));
        if (response) {
            return response.data;
        }
    },


};


const urls = {
    GET_IMAGES_DATA_URL: "imagepicker/GetImages",
    ADDIMAGE_URL: "/imagepicker/AddImage",
    GET_IMAGE_INFO_URL: (id) => `/imagepicker/${id}/GetImageData`,

    GET_IMAGE_DELETE_URL: (id) => `/imagepicker/${id}/DeleteImage`,

    GET_IMAGE_ROOTADMIN_PERMISSION_URL: (id) => `/imagepicker/${id}/permission/SetUnitRootPermission`,
    GET_IMAGE_UNITADMIN_PERMISSION_URL: (id) => `/imagepicker/${id}/permission/SetUnitAdminPermission`,
    GET_IMAGE_TEACHERS_PERMISSION_URL: (id) => `/imagepicker/${id}/permission/SetTeacherPermission`,
    GET_IMAGE_ADD_USER_PERMISSION_URL: (id) => `/imagepicker/${id}/permission/AddPermission`,
    GET_IMAGE_REMOVE_USER_PERMISSION_URL: (id) => `/imagepicker/${id}/permission/RemovePermission`,

    GET_IMAGE_SEARCH_USERS_URL: (id, text) => `/imagepicker/${id}/permission/FindUsersByText?text=${text}`,


}

export const imagePickerDuck = { ...ImagePickerSlice.actions, ...services }
export default ImagePickerSlice.reducer;