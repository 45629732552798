//@ts-check

import React from "react";

import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";

import classes from './dashboard.module.scss';

/**
 * CourseAccessWidget
 * @module features/classes/Dashboard/CourseAccessWidget
 * @return an LnCard with Course Acces info.
 * @param {{
    DivHeight?: string,
    StudentData: import("../Classes").StudentClassDtoForStudent,
    isTeacher: boolean
}} props
 */
const CourseAccessWidget = (props) => {

    const { languageService: t } = useI18n();
    const studentClass = props.StudentData.StudentClass;
    const HasPlan = props.StudentData.HasPlan;

    const divCLasses = ['card-box big-card-box card ln-card mr-5 mb-5', classes['widget-card']];

    if (props.DivHeight) {
        divCLasses.push(classes[props.DivHeight]);
    }

    let htmlCourseAccess;

    if (!props.isTeacher) {
        htmlCourseAccess = t.getText('your.course.access', ['<br /><span className="text-nowrap">'
            + t.getMediumDateString(studentClass.StartDate) + '</span> ' + t.getText('to').toLowerCase()
            + ' <span className="text-nowrap">' + t.getMediumDateString(studentClass.EndDate) + '</span>']);
    }
    else {
        htmlCourseAccess = t.getText('course.access') + '<br /><span className="text-nowrap">'
            + t.getMediumDateString(studentClass.StartDate) + '</span> ' + t.getText('to').toLowerCase()
            + ' <span className="text-nowrap">' + t.getMediumDateString(studentClass.EndDate) + '</span>';
    }


    return (
        <div className={divCLasses.join(' ')}>
            <div className={["card-header", classes['card-header']].join(' ')}>
                <LnIcon className="header-icon" name="note-icon" />
                {!props.isTeacher ? t.getText('your.course.access', "") : t.getText('course.access')}
            </div>
            <div className="card-body pt-3 container">
                <div className="">
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-row mb-4">
                            <LnIcon className="header-icon mr-3" name="pc-icon" />
                            {!!studentClass.EndDate && <span>
                                {HasPlan ?
                                    <span>{t.getText("course.access.by.plan")}</span> :
                                    <span dangerouslySetInnerHTML={{ __html: htmlCourseAccess }}></span>}

                            </span>}
                        </div>
                        <span>

                        </span>

                    </div>
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-row mb-4">
                        <LnIcon className="header-icon mr-3" name="board-icon" />
                            {!!studentClass.ClassEndDate && <span>
                                
                                <span dangerouslySetInnerHTML={{ __html: (t.getText('course.teaching.period', ['<br /><span className="text-nowrap">' + t.getMediumDateString(studentClass.ClassStartDate) + '</span> ' + t.getText('to').toLowerCase() + ' <span className="text-nowrap">' + t.getMediumDateString(studentClass.ClassEndDate) + '</span>'])) }}></span>

                            </span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseAccessWidget;