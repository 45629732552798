// @ts-check
import React from 'react';
import { UserMode, SignOnDataStatus, SignOnReplyStatus } from "features/User/user.enums";
import { createSlice } from "@reduxjs/toolkit";
import { pathToAction } from "redux-first-router";
import instance from "utils/axios.js";
import { endPoint } from "AppConstants";
import { routePaths } from "routes.js";
import { v4 as uuid } from 'uuid'
import { classesDuck } from "features/classes/ClassesDuck";
import { errorDuck } from "components/ErrorHandler/ErrorHandlerDuck";
import { searchDuck } from "features/search/SearchDuck";
import languageService from "features/language/languageService";
import * as Sentry from "@sentry/react";
import { Alert } from "components/OnlineModal";
import * as Yup from "yup";
import pingService from 'utils/PingService';

/** @type {import('./login').LoginState} */
const initialState = {
    userAvatar: null,
    user: null,
    loggedIn: false,
    langId: 0,
    loginData: null,
    isLoggingIn: false,
    errorState: null,
    redirectUrlAfterLogin: null,

    loginGuiState: {
        Identifier: null,
        Password: null,
        Federated: false,
        ShowSendPassword: false,
        federatedTextData: { result: false, text: null },
        canSubmit: false,
        IdentifierError: null,
        PasswordError: null,
    },
    isLoading: false,
    newPasswordData: null,
    signOnData: null,
    relogin: false,
    cdnUrl: null
};


const loginSlice = createSlice({
    name: "login",
    initialState: initialState,
    reducers: {
        /**
         * @param {import('./login').LoginState} state 
         * @param {{payload:{user:any, langId: number}}} action 
         */
        loginSuccess(state, action) {
            state.user = action.payload.user;
            state.loggedIn = true;
            state.langId = action.payload.langId;
            state.isLoggingIn = false;
            state.userAvatar = endPoint.GET_USER_IMAGE_URL(action.payload.user.Id) + `?x=${uuid()}`
        },

        /**
         * @param {import('./login').LoginState} state 
         * @param {{payload:string}} action 
         */
        changedUserImage(state, action) {
            if (state.user && state.user.Id === action.payload) {

                state.userAvatar = endPoint.GET_USER_IMAGE_URL(state.user.Id) + `?x=${uuid()}`
            }
        },

        /**
         * @param {import('./login').LoginState} state 
         * @param {{payload:import('./login').NewPasswordData}} action 
         */
        setNewPasswordData(state, action) {
            state.newPasswordData = action.payload;
        },

        /**
         * 
         * @param {import('./login').LoginState} state 
         * @param {{payload:{user:any, langId: number}}} action 
         */
        setUserAndLang(state, action) {
            state.user = action.payload.user;
            state.langId = action.payload.langId;
        },
        loggedOut(state, action) {
            state.loggedIn = false;
            state.isLoggingIn = false;
            state.userAvatar = null;
            // @ts-ignore
            state.user = {};

            

        },
        pinged(state, action) {
            state.loggedIn = true;
        },

        loggingIn(state, action) {
            state.isLoggingIn = true;
        },

        endLoggingIn(state, action) {
            state.isLoggingIn = false;
        },
        /**
        * 
        * @param {import('./login').LoginState} state 
        * @param {{payload: import('components/ErrorHandler/ErrorHandler').ErrorState}} action 
        */
        errorLoggingIn(state, action) {
            state.isLoggingIn = false;
            state.errorState = action.payload;
        },

        /**
         * 
         * @param {import('./login').LoginState} state 
         * @param {{payload:import('./login').LoginDataDto}} action 
         */
        setLoginData(state, action) {
            state.loginData = action.payload;
        },

        /**
         * 
         * @param {import('./login').LoginState} state 
         * @param {{payload:string}} action 
         */
        setRedirectUrl(state, action) {
            state.redirectUrlAfterLogin = action.payload;
        },

        /**
         * 
         * @param {import('./login').LoginState} state 
         * @param {{payload: import('./login').FederatedTextData}} action 
         */
        setFederatedTextData(state, action) {
            state.loginGuiState.federatedTextData = action.payload;
        },

        /**
         * 
         * @param {import('./login').LoginState} state 
         * @param {{payload: {value:string, error: ()=>string}}} action 
         */
        setIdentifierText(state, action) {
            state.loginGuiState.Identifier = action.payload.value;
            state.loginGuiState.IdentifierError = action.payload.error;
        },

        /**
         * 
         * @param {import('./login').LoginState} state 
         * @param {{payload: {value:string, error: ()=>string}}} action 
         */
        setPasswordText(state, action) {
            state.loginGuiState.Password = action.payload.value;
            state.loginGuiState.PasswordError = action.payload.error;
        },

        /**
         * 
         * @param {import('./login').LoginState} state 
         * @param {{payload: import('./login').LoginGuiState}} action 
         */
        setGuiState(state, action) {
            state.loginGuiState = action.payload;
        },


        /**
        * 
        * @param {import('./login').LoginState} state 
        * @param {{payload: boolean}} action 
        */
        setLoadingState(state, action) {
            state.isLoading = action.payload;
        },

        /**
       * 
       * @param {import('./login').LoginState} state 
       * @param {{payload: boolean}} action 
       */
        setEmail2Verified(state, action) {
            state.user.Mail2Verified = action.payload;
        },

        /**
        * 
        * @param {import('./login').LoginState} state 
        * @param {{payload: boolean}} action 
        */
        setLoggedIn(state, action) {
            state.isLoggingIn = action.payload;
        },

        /**
        * 
        * @param {import('./login').LoginState} state 
        * @param {{payload: string}} action 
        */
        setUserCurrentClass(state, action) {
            if (state.user != null) {
                state.user.CurrentClass = action.payload;
            }
        },

        /**
        * 
        * @param {import('./login').LoginState} state 
        * @param {{payload: boolean}} action 
        */
        setCanSubmit(state, action) {
            state.loginGuiState.canSubmit = action.payload;
        },

        resetError(state, action) {
            state.errorState = null;
        },

        /**
       * 
       * @param {import('./login').LoginState} state 
       * @param {{payload: import('./login').VerifyEmailData}} action 
       */
        setVerifyEmailData(state, action) {
            state.verifyEmailData = action.payload;
        },

        /**
       * 
       * @param {import('./login').LoginState} state 
       * @param {{payload: import('./login').SignOnData}} action 
       */
        setSignOnData(state, action) {
            state.signOnData = action.payload;
        },

        /**
       * 
       * @param {import('./login').LoginState} state 
       * @param {{payload: string}} action 
       */
        setSignOnDataHtmlText(state, action) {
            if (state.signOnData) {
                state.signOnData.HtmlText = action.payload;
            }
        },

        /**
        * 
        * @param {import('./login').LoginState} state 
        * @param {{payload: boolean}} action 
        */
         setRelogin(state, action) {
            state.relogin = action.payload;
        },

          /**
        * 
        * @param {import('./login').LoginState} state 
        * @param {{payload: string}} action 
        */
          setCdnUrl(state, action) {
            state.cdnUrl = action.payload;
        },

    }
});

// is an email entered in 'Identifier' ?
const isEmail = Yup.string().email();


const services = {

    handleInputChange: (value, paramName) => async (dispatch, getState) => {

        let error = null;


        /**
        * @type {import('./login').LoginState} 
        */
        const { loginData, loginGuiState } = getState().login;
        const validData = { Identifier: false, Password: false, Federated: false }

        switch (paramName) {
            case "Identifier":

                if (value === null || value === "") {
                    error = () => languageService.getText("required_field");
                }
                else {
                    error = null;
                    validData.Identifier = true;
                }

                dispatch(LoginDuck.setIdentifierText({ value, error }));

                if (isEmail.isValidSync(value)) {
                    let fedDomain = value ? loginData.FederatedDomains.filter(f => {
                        return value.toLowerCase().endsWith(f.Domain)
                    }
                    ) : null;

                    if (fedDomain != null && fedDomain.length === 1) {
                        dispatch(
                            LoginDuck.setFederatedTextData({
                                result: true,
                                text: fedDomain[0].UnitText
                            })
                        );

                        validData.Federated = true;

                    } else {
                        dispatch(LoginDuck.setFederatedTextData({ result: false, text: null }));
                    }
                }

                validData.Password = !!loginGuiState.Password && !loginGuiState.PasswordError;

                break;

            case "Password":
                if (value === null || value === "") {
                    error = () => languageService.getText("required_field");
                }
                else if (value.length < 5) {
                    error = () => languageService.getText("toshortpasswd");
                }
                else {
                    error = null;
                    validData.Password = true;
                }
                dispatch(LoginDuck.setPasswordText({ value, error }));

                validData.Identifier = !!loginGuiState.Identifier && !loginGuiState.IdentifierError;
                break;

            default:
                break;
        }

        dispatch(LoginDuck.setCanSubmit((validData.Identifier && validData.Password) || validData.Federated));


    },



    /**
     * 
     * @param { import('./login').LoginFormDto } payload 
     */
    loginAtServer: payload => async (dispatch, getState) => {

        dispatch(LoginDuck.loggingIn({}));

        payload.FinalDomain = "portal";

        /**
         *  @type { import('./login').LoginState }
         * 
        */
        const { relogin } = getState().login;

        /**
         *  @type { import("features/User/User").CurrentUser }
         * 
        */
        let user = null;
        try {

            /**
             *  @type {{data: import('./login').LoginReplyDto}}
             * 
            */
            const response = await instance.post(endPoint.LOGIN_URL, payload);

            if (!response) {
                if (payload.Identifier || payload.Password) {
                    dispatch(LoginDuck.errorLoggingIn({ header: null, message: languageService.getText("login.error.try.again") }));
                }
                else {
                    dispatch({ type: "ROOT" });
                }


                return null;
            }



            /**
             *  @type { import('./login').LoginReplyDto }
             * 
            */
            let data = response.data;
            if (data.Result === true) {


                if (data.RedirectUrl) {
                    window.location.href = data.RedirectUrl;
                    return;
                }

              
                user = data.CurrentUser;

                if (!user.IsVerified) {

                    Alert({
                        backdrop: 'static',
                        title: languageService.getText('dashboard.email.verification.header'),
                        message: <div className="preserve-white" dangerouslySetInnerHTML={{ __html: languageService.getText("dashboard.email.verification.message.email", "<h5>" + user.Email + "</h5>") }}></div>,
                        button: {
                            className: 'btn btn-primary',
                            text: languageService.getText("send.verification.email"),
                            type: "submit"
                        }
                    }).then(() => {
                        instance.post(endPoint.GET_USER_EMAIL_VERIFY_URL(user.Id, 1), null)
                            .then((result) => {
                                if (result && result.data) {
                                    Alert({
                                        backdrop: true,
                                        title: languageService.getText('dashboard.email.verification.header'),
                                        message: result.data.Value,
                                        button: {
                                            className: 'btn btn-primary',
                                            text: languageService.getText("ok"),
                                            type: "submit"
                                        }
                                    });

                                    dispatch(LoginDuck.logoutAtServer());
                                }
                                else {
                                    dispatch(LoginDuck.logoutAtServer());
                                }
                            })

                    }).catch(() => dispatch(LoginDuck.logoutAtServer()));

                    return;
                }

                if (!user.CurrentClass) {
                    const resp = await instance.get(endPoint.USER_CURRENT_CLASSID_URL);
                    if (resp && resp.data) {
                        user.CurrentClass = resp.data;
                    }
                }




                user.LastLogin = new Date(user.LastLogin);
                dispatch(
                    LoginDuck.loginSuccess({
                        user: data.CurrentUser,
                        langId: data.LangId
                    })
                );

                pingService.startPing();

                if( relogin){
                    dispatch(LoginDuck.setRelogin(false));
                    
                    return;
                }

                dispatch(LoginDuck.setGuiState(initialState.loginGuiState));

                dispatch(searchDuck.setSearchResult(null));
                dispatch(searchDuck.setSearchText(""));
                dispatch(searchDuck.setPage(1));

                dispatch(classesDuck.setEventsSinceDate(user.LastLogin));
                dispatch(classesDuck.resetReadNotifications());
                dispatch(classesDuck.setTeacherClasses([]));

                Sentry.addBreadcrumb({
                    category: "auth",
                    message: "user login: " + user.Id,
                    level: 'info'
                });

                Sentry.setUser({ id: user.Id });

               

                if (!data.CurrentUser.HasAcceptedPolicy || !data.CurrentUser.HasAgreedToCookies ) {
                    window.setTimeout(() => {
                        dispatch({ type: "GENERAL_PERSONAL_DATA_POLICY", payload: { userid: user.Id } })
                    }, 1000);
                }

                /** @type any */
                let action = await LoginDuck.loadUserDataAndGetPath(user, dispatch)

                const { redirectUrlAfterLogin } = getState().login;
                if (redirectUrlAfterLogin != null) {
                    const redirAction = pathToAction(redirectUrlAfterLogin, routePaths);
                    if (redirAction.type !== "LOGIN") {
                        action = redirAction;
                    }
                    dispatch(LoginDuck.setRedirectUrl(null));
                }
                dispatch(action);

                if (data.ClientClockDiff !== null) {
                    dispatch(errorDuck.setError({ header: languageService.getText("error"), message: languageService.getText("client.time.settings.off"), when: new Date() }));
                }
            } else {
                if (payload.Identifier) {
                    dispatch(LoginDuck.errorLoggingIn({ header: null, message: data.Message }));
                }
            }
        } finally {
            dispatch(LoginDuck.endLoggingIn({}));
        }

        return user;

    },


    logoutAtServer: () => async (dispatch) => {
        dispatch(LoginDuck.loggingIn({}));
        try {
            await instance.post(endPoint.LOGOUT_URL, null)
            Sentry.addBreadcrumb({
                category: "auth",
                message: "user logged out",
                level: 'info',
            });
            
            pingService.stopPing();
            dispatch(LoginDuck.loggedOut({}));
            dispatch({ type: "LOGIN" });
        } catch (error) {
            dispatch(LoginDuck.loggedOut({}));
            dispatch(LoginDuck.errorLoggingIn(error.message));

        };
    },

    loadLoginData: async (langId, dispatch) => {
        const response = await instance.get(endPoint.LOGIN_DATA_URL(langId));
        if (!response) {
            return;
        }
        dispatch(LoginDuck.setLoginData(response.data));
    },


    /**
     *
     * @param { string } UserId
     * @param { boolean } Shadow
     */
    switchUser: (UserId, Shadow) => async (dispatch) => {
        try {
            const url = endPoint.GET_USER_SWITCH_URL();

            /**
             * @type {{data: import('./login').SwitchUserResult}}
             */
            const response = await instance.post(url, { UserId: UserId, Shadow: Shadow });
            if (!response) {
                return;
            }
            Sentry.addBreadcrumb({
                category: "auth",
                message: `switch user ${Shadow} => ${UserId}`,
                level: 'info'
            });


            await dispatch(LoginDuck.loginSuccess({ user: response.data.CurrentUser, langId: response.data.LangId }));
            dispatch(classesDuck.setTeacherClasses([]));
            let action = await LoginDuck.loadUserDataAndGetPath(response.data.CurrentUser, dispatch)

            window.setTimeout(() => dispatch(action), 100);


        } catch (error) {
            dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
        }
    },



    switchBackUser: () => async (dispatch) => {
        try {
            const url = endPoint.GET_USER_SWITCH_BACK_URL();

            /**
             * @type {{data: import('./login').SwitchUserResult}}
             */
            const response = await instance.post(url, null);
            if (!response) {
                return;
            }

            Sentry.addBreadcrumb({
                category: "auth",
                message: `switch back ${response.data.CurrentUser.Id}`,
                level: 'info'
            });

            await dispatch(LoginDuck.loginSuccess({ user: response.data.CurrentUser, langId: response.data.LangId }));
            dispatch(classesDuck.setTeacherClasses([]));
            let action = await LoginDuck.loadUserDataAndGetPath(response.data.CurrentUser, dispatch)
            window.setTimeout(() => dispatch(action), 100);

        } catch (error) {
            dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
        }
    },


    sendVerificationEmail: (userid, type) => async (dispatch) => {
        instance.post(endPoint.GET_USER_EMAIL_VERIFY_URL(userid, type), null)
            .then((result) => {
                if (result && result.data) {
                    Alert({
                        backdrop: true,
                        title: languageService.getText('dashboard.email.verification.header'),
                        message: result.data.Value,
                        button: {
                            className: 'btn btn-primary',
                            text: languageService.getText("ok"),
                            type: "submit"
                        }
                    });
                    dispatch(LoginDuck.setEmail2Verified(true));
                }
            })
    },


    sendFormVerificationCodeEmail: (email, langId, classId) => {
        instance.post(endPoint.GET_SEND_VERIFICATION_URL({ email, langId, classId }), null)
            .then((result) => {
                if (result) {
                    Alert({
                        backdrop: true,
                        title: languageService.getText('mail.sent'),
                        message: <div className='preserve-white' >{languageService.getText('code.sent.by.mail', email)}</div>,
                        button: {
                            className: 'btn btn-primary',
                            text: languageService.getText("ok"),
                            type: "submit"
                        }
                    });
                }
            })
    },


    /**
     *
     * @param { import('features/User/User').CurrentUser } user
     * @param { Function } dispatch
     * @returns {  Promise<{type: string, payload?: any}> }
     */
    loadUserDataAndGetPath: async (user, dispatch) => {

        // TODO: switch for teacher, admin, root
        // TODO: if user != admin , teacher or has no classes, show info page

        dispatch(classesDuck.setTeacherData(null));

        if (user.UserState === UserMode.student) {
            try {
                /**
                 * @type { import('axios').AxiosResponse<import('types/types').IdAndNameDto[]>}
                 */
                const response = await instance.get(endPoint.ALL_CLASSES_STUDENT_URL);
                if (!response) {
                    return { type: "LOGIN", payload: {} };
                }
                if (response.data && response.data.length) {
                    await dispatch(classesDuck.setClasses(response.data));
                    return { type: "CLASS", payload: { classid: user.CurrentClass } };
                }
                return { type: "LOGIN", payload: {} };

            } catch (error) {
                console.log(error);
            }
        }

        if (user.UserState === UserMode.teacher) {

            const success = await classesDuck.getTeacherData(dispatch);
            if (success) {
                return { type: "CLASS", payload: { classid: user.CurrentClass } };
            }

        }

        if (user.UserState > UserMode.teacher) {
            if (user.CurrentClass) {
                const success = await classesDuck.getTeacherData(dispatch);
                if (success) {
                    return { type: "CLASS", payload: { classid: user.CurrentClass } };
                }
            }
            return { type: "ADMIN" };
        }
    },


    GetChangePasswordData: ({ UserId, Key, Date, Ticket }) => async dispatch => {

        /**
        * @type { import('axios').AxiosResponse<{name:string, error:string}>}
        */
        const response = await instance.get(endPoint.GET_USER_NAME_PW_CHANGE(UserId, Key, Date, Ticket));
        if (response) {
            /**
             * @type {import('./login').NewPasswordData}
             */
            const data = { UserId, Key, Date, Ticket, UserName: response.data.name, Error: response.data.error };
            dispatch(LoginDuck.setNewPasswordData(data));
        }

    },


    GetVerifyEmailData: ({ signature, stamp, ticket }) => async dispatch => {
        /**
        * @type { import('axios').AxiosResponse<import('./login').VerifyEmailData>}
        */
        const response = await instance.get(endPoint.GET_VERIFY_EMAIL_LINK(signature, stamp, ticket));
        if (response) {

            dispatch(LoginDuck.setVerifyEmailData(response.data));
        }
    },


    /**
    * @param {import('./login').ChangePasswordFormDTO} data
    */
    TryChangePassord: async (data) => {

        const response = await instance.post(endPoint.PW_CHANGE_URL, data);
        if (response) {
            return response.data;
        }

        return null;
    },

    GetSignOnData: ({ classid, formid }) => async (dispatch) => {

        const response = await instance.get(endPoint.GET_SIGN_ON_DATA_URL({ classid, formid }));
        if (response) {
            dispatch(LoginDuck.setSignOnData(response.data));
        }


    },

    /**
     * 
     * @param {import('./login').SignOnData} data 
     * @returns 
     */
    PostSignOnData: (data) => async (dispatch) => {
        console.log(data);

        /**
         * @type {import('axios').AxiosResponse<import('./login').SignOnRegisterReply>}
         */
        const response = await instance.post(endPoint.POST_SIGN_ON_DATA_URL, data);
        if (response && response.data) {
            const reply = response.data;
            switch (reply.Status) {
                case SignOnReplyStatus.UserExists:
                    dispatch(errorDuck.setError({ okText: languageService.getText("good.understand"), header: "", message: reply.Message, when: new Date() }));
                    dispatch(LoginDuck.setSignOnData(response.data.NewData));
                    break;

                case SignOnReplyStatus.UserExistsAndHaveNotLoggedIn:
                case SignOnReplyStatus.UserExistsAndInClass:
                    dispatch(errorDuck.setError({ okText: languageService.getText("ok.understand"), header: "", message: reply.Message, when: new Date() }));
                    dispatch(LoginDuck.setSignOnData({ ...response.data.NewData, Status: response.data.Status }));
                    break;

                case SignOnReplyStatus.error:
                    dispatch(errorDuck.setError({ header: languageService.getText("error"), message: reply.Message, when: new Date() }));
                    break;

                case SignOnReplyStatus.fullCanBeReserve:
                    dispatch(errorDuck.setError({ okText: languageService.getText("good.understand"), header: "", message: languageService.getText("sign.up.reserve.places.left"), when: new Date() }));
                    dispatch(LoginDuck.setSignOnDataHtmlText(response.data.Message));
                    break;

                case SignOnReplyStatus.fullCanNotBeReserve:
                    dispatch(errorDuck.setError({ okText: languageService.getText("ok.understand"), header: "", message: reply.Message, when: new Date() }));
                    break;

                case SignOnReplyStatus.ok:
                    dispatch(LoginDuck.setSignOnData({ ...response.data.NewData, State: SignOnDataStatus.registrationReady }));
                    break;

                default:
                    break;
            }


        }

    },

    cancelRelogin: () => (dispatch) => {
        dispatch( LoginDuck.setRelogin(false) );
    }


}

export default loginSlice.reducer;

export const LoginDuck = { ...loginSlice.actions, ...services }
