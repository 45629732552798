//@ts-check
import React, {useRef} from 'react';
import PropTypes from 'prop-types';

let rotateAngle = 0;
const getTotal = values => values.reduce((acc, { value }) => acc + value, 0);
const getPercent = (value, total) => value / total;
const getCircumference = radius => 2 * Math.PI * radius;
const convertDegreesToRadians = angle => angle * (Math.PI / 180);

const Donut = props => {
	const total = getTotal(props.segments);
	const loadTimeout = useRef(null);

	const { size, svgSize, radius, thickness, className, circleProps, textProps, centerText, drawLabels, gap } = props;

	

	

	const getStrokeDashOffset = (value, circumference) => {
		if( total === 0 )
		{
			return 0;
		}
		const diff = getPercent(value, total) * circumference;
		return circumference - diff;
	};

	const [segments, setSegments] = React.useState([]);
	const [isLoaded, setIsLoaded] = React.useState(false);

	React.useEffect(() => {

		const getTextCoordinates = (value, angleOffset) => {
			const { size, radius, segments } = props;
			const tot = getTotal(segments);
			const angle = (getPercent(value, tot) * 360) / 2 + angleOffset;
			const radians = convertDegreesToRadians(angle);
	
			return {
				x: radius * Math.cos(radians) + size / 2,
				y: radius * Math.sin(radians) + size / 2
			};
		};

		const getTransforms = () => {
			const rotations = [];
			const textCoords = [];
			const { startAngle, segments } = props;
			const tot = getTotal(segments);
	
			rotateAngle = startAngle;
	
			segments.forEach(({ value }) => {
				const data = rotateAngle;
				const percent = getPercent(value, tot);
				const { x, y } = getTextCoordinates(value, rotateAngle);
	
				rotations.push(data);
				textCoords.push({ x, y });
	
				const result = rotations[rotations.length - 1] || startAngle;
	
				rotateAngle = percent * 360 + result;
			});
	
			return { rotations, textCoords };
		};

		const { segments, size } = props;
		const { rotations, textCoords } = getTransforms();

		setSegments(
			segments.map(({ value, cssClass }, i) => ({
				value,
				cssClass,
				percent: getPercent(value, total),
				rotate: `rotate(${rotations[i]}, ${size / 2}, ${size / 2})`,
				textCoords: textCoords[i]
			}))
		);

		loadTimeout.current = setTimeout(() => {
			setIsLoaded(true);
		}, 100);

		return () => {
			clearTimeout(loadTimeout.current);
		};
	}, [props, total]);


	const halfSize = size / 2;
	const circumference = getCircumference(radius);

	return (
		<div className={ "donut-complex" + (isLoaded ? ' donut-complex--loaded' : '') + " "  + className}>
			<svg height={svgSize} width={svgSize} viewBox={`0 0 ${size} ${size}`}>
				{segments.map((segment, i) => (
					<g key={i}>
						<circle
							{...circleProps}
							r={radius}
							cx={halfSize}
							cy={halfSize}
							transform={segment.rotate}
							stroke={segment.color}
							strokeWidth={thickness}
							strokeDasharray={circumference - gap}
							strokeDashoffset={getStrokeDashOffset(segment.value, circumference)}
							className={segment.cssClass}
						/>
						{drawLabels &&
							<text
								{...textProps}
								x={segment.textCoords.x}
								y={segment.textCoords.y}
								dy="3px"
								textAnchor="middle"
							>
								{`${Math.round(segment.percent * 100)}%`}
							</text>
						}
					</g>
				))}
				{centerText && <g>
					<text className="donut-center-text" x={"50%"} y={"53%"} textAnchor="middle">{centerText}</text>
				</g>}
			</svg>
		</div>
	);
};

Donut.propTypes = {
	size: PropTypes.number.isRequired,
	svgSize: PropTypes.string.isRequired,
	radius: PropTypes.number.isRequired,
	segments: PropTypes.arrayOf(
		PropTypes.shape({
			cssClass: PropTypes.string,
			value: PropTypes.number
		})
	).isRequired,
	thickness: PropTypes.number.isRequired,
	startAngle: PropTypes.number,
	className: PropTypes.string,
	circleProps: PropTypes.object,
	textProps: PropTypes.object,
	centerText: PropTypes.string,
	drawLabels: PropTypes.bool,
	gap: PropTypes.number,
};

Donut.defaultProps = {
	size: 160,
	svgSize: "100%",
	radius: 60,
	segments: [],
	thickness: 30,
	startAngle: -90,
	className: '',
	circleProps: {},
	textProps: {},
	centerText: null,
	drawLabels: false,
	gap: 2,
};

export default Donut;
