//@ts-check
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import { useI18n } from "components/lni18n";
import { imagePickerDuck } from "./ImagePickerDuck";
import UserAsyncFind from "components/UserAsyncFind";
import { CustomInput } from "reactstrap";
import LnIcon from "components/LnIcon";

const ImagePermissions = (props) => {

    const dispatch = useDispatch();
    const { languageService: t } = useI18n();
    const { currentImage, selectedImage } = useTypedSelector(state => state.imagepicker);
    const [userSelected, setUserSelected] = useState(null);


    const setPermission = (e, type) => {
        dispatch(imagePickerDuck.setPermission(type, e.target.checked));
    }

    const userSearch = async (query) => {
        const users = await imagePickerDuck.getUsersForPermissions(query, selectedImage.ImageId);
        return users;
    };
    const handlePermissionUserChange = (e) => {
        setUserSelected(e);
    }

    const onAddUserPermission = () => {
        dispatch(imagePickerDuck.addUserPermission(userSelected));
    }

    const removePermission = (uid) => {
        dispatch(imagePickerDuck.removeUserPermission(uid));
    }

    return <>
        <div className="d-flex flex-column">
            <CustomInput type="checkbox" className="mb-2" id="unitroots" label={t.getText('imagepicker.permission.unitroots.allowed')}
                onChange={(e) => { setPermission(e, 'unitroots') }}
                checked={currentImage.Permissions.UnitRootsCanRead} />

            <CustomInput type="checkbox" className="mb-2" id="unitadmins" label={t.getText('imagepicker.permission.unitadmins.allowed')}
                onChange={(e) => { setPermission(e, 'unitadmins') }}
                checked={currentImage.Permissions.UnitAdminsCanRead} />

            <CustomInput type="checkbox" className="mb-2" id="teachers" label={t.getText('imagepicker.permission.teachers.allowed')}
                onChange={(e) => { setPermission(e, 'teachers') }}
                checked={currentImage.Permissions.TeachersCanRead} />

        </div>
        <div className="mt-4">
            <div className="form-group mb-5">
                <div className="form-group mb-5">
                    <h4 className="mb-2">{t.getText('add')}</h4>
                    <div className="d-flex align-items-center">
                        <UserAsyncFind className="w-75" onUserSelected={handlePermissionUserChange} userSearch={userSearch} />
                        <button className="ml-4 btn btn-small btn-primary" onClick={() => onAddUserPermission()} >{t.getText("add")}</button>
                    </div>
                </div>
            </div>
            <div>
                {currentImage.Permissions.Users.map(u => {
                    return <div className="mb-3 d-flex align-items-center">
                        <button onClick={() => removePermission(u.Id)} className="btn btn-link" ><LnIcon name="delete" className="icon-small" /></button>
                        <div>{u.FullName}</div>
                        <small>({u.Mail})</small>
                    </div>
                })}
            </div>
        </div>
    </>
}

export default ImagePermissions;
