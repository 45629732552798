//@ts-check

import React from "react";

import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";

import classes from './dashboard.module.scss';

/**
 * SocMedWidget
 * @module features/classes/Dashboard/CourseAccessWidget
 * @return an LnCard with Course Acces info.
 * @param {{
    DivHeight?: string
}} props
 */
const SocMedWidget = (props) => {

    const { languageService: t } = useI18n();
    
    const divClasses = ['card-box big-card-box card ln-card mr-5 mb-5', classes['widget-card']];
    if (props.DivHeight) {
        divClasses.push(classes[props.DivHeight]);
    }

    return (
        <div className={divClasses.join(' ')}>
            <div className={["card-header", classes['card-header']].join(' ')}>
                <LnIcon className="header-icon" name="thumbs-up" />
                {t.getText('social.media', "")}
            </div>
            <div className="card-body pt-3 container">
                <div className="">
                    <div className="mb-3">
                    {t.getText('social.media.like.us', "")}
                    </div>
                    <div className="d-flex">
                        
                    <div className="mr-4">
                            <a title="Instagram" href="https://www.instagram.com/laranaralms/"  rel="noopener noreferrer" target="_blank">
                                <img alt="Instagram" src="/imgs/somed/instagram.png" />
                            </a>
                        </div>
          
                        <div className="mr-4">
                            <a title="Facebook" href="https://www.facebook.com/profile.php?id=61560026296245"  rel="noopener noreferrer" target="_blank">
                                <img alt="Facebook" src="/imgs/somed/facebook.png" />
                            </a>
                        </div>
                  
                        <div className="mr-4">
                            <a title="LinkedIn" href="https://www.linkedin.com/company/laranara"  rel="noopener noreferrer" target="_blank">
                                <img alt="LinkedIn" src="/imgs/somed/linkedin.png" />
                            </a>
                        </div>
                        
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SocMedWidget;