//@ts-check
import React from "react";
import ScrollContainer from './ScrollContainer';

/**
 *
 * @param {{className?: string, scrollname: string, children: import('react').ReactChild | import('react').ReactChild[]}} props
 *
 */
const ScrollContent = (props) => {

    if (!props.scrollname) {
        throw new Error("no scroll name")
    }

    return <ScrollContainer  scrollKey={props.scrollname} >
        
        <div className={"scrollContent ul-fix scroller " + props.className} aria-live="polite">
            {props.children}
        </div>
    </ScrollContainer>

}


export default ScrollContent;