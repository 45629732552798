//@ts-check
import React from "react";

import { useI18n } from "components/lni18n";

import classes from './mail.module.scss';
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import MailHeaderListing from "./MailHeaderListing";

const MailHeaders = ({currentProject, handleClick, handleDelete, mailLists, listtype, changeTab}) => {

    const {  languageService: t } = useI18n();

    const toggleListTab = tab => {
        if (listtype !== tab) changeTab(tab);
    }

    return (<>
        <Nav tabs className={[ "nav-justified" ].join(' ')}>
            <NavItem>
                <NavLink
                    className={[ listtype === 'draft' ? 'active ' + classes[ 'active' ] : '' ].join(' ')}
                    onClick={() => { toggleListTab('draft'); }}
                >
                    {t.getText("not.sent")}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={[ listtype === 'sent' ? 'active ' + classes[ 'active' ] : '' ].join(' ')}
                    onClick={() => { toggleListTab('sent'); }}
                >
                    {t.getText("sent")}
                </NavLink>
            </NavItem>
        </Nav>
        <TabContent className={classes[ 'tab-content' ]} activeTab={listtype}>
            <TabPane tabId="draft">
            <div className="pt-4">
                    <MailHeaderListing currentProject={currentProject} mailList={mailLists.NotSent} handleClick={handleClick} handleDelete={handleDelete} />
                </div>
            </TabPane>
            <TabPane tabId="sent">
                <div className="pt-4">
                    <MailHeaderListing currentProject={currentProject} mailList={mailLists.Sent} handleClick={handleClick} handleDelete={handleDelete} />
                </div>
            </TabPane>
        </TabContent>
    </>);
}
export default MailHeaders;
