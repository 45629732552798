//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import { LoginDuck } from "features/login/LoginDuck";
import { forumService } from "./ClassItems/Forum/ForumDuck";
import { TestResultStatus } from 'features/course/part/test/TestEnums';


export const ClientMode = {
    notset : 0,
    patient : 1,
    student : 2,
    school : 4,
    selfstudies: 8
}


const classDefaults =
{
    "Id": "noid",
    "Name": "",
    "ShowTeacherInformationWarning": false,
    "ShowInformation": false,
    "ShowMembers": false,
    "ShowProgress": false,
    "ShowPersonalPlan": false,
    "ShowTimetable": false,
    "ShowFeed": false,
    "ShowForum": false,
    "ShowBulkMail": false,
    "ShowFiles": false,
    "CurrentUserIsTeacher": false,
    "ImageId": "noid",
    "SubGroups": [

    ],
    "Themes": [],
    "CanEditNotifications": false,
    "DashBoardData": {
        "TeacherData": null,
        "StudentData": {
            "NewCommentedTestResultCount": 0,
            "ShowLecturesViewed": true,
            "PercentLecturesViewed": 0,
            "FinishedUser": null,
            "HasPlan": false,
            "LastClass": false,
            "Changes": {
                "Forums": [],
                "FeedPosts": [],
                "SubGroups": [],
                "ClassFiles": [],
                "Notifications": [],
                "MajorEvent": false,
                "CourseParts": [],
                "TotalForumCount": 0,
                "TotalFeedPostCount": 0,
                "TotalCount": 0,
                "Start": "2000-01-01"
            },
            "Notifications": [],
            "StudentClass": {
                "CourseName": "",
                "CourseDescription": "",
                "StartDate": null,
                "EndDate": null,
                "ClassStartDate": null,
                "ClassEndDate": null,
                "Id": "noid",
                "Name": ""
            },
            "ServiceProviders": [],
            "Addons": [

            ],
            "CourseType": 2,
            "TestResultCounts": {
                "Total": 0,
                "Submitted": 0,
                "Approved": 0,
                "Failed": 0,
                "ToFollowUp": 0
            },
            "CourseImageUrl": null
        },
        "OnlyShowUnit": false,
        "UnitName": "",
        "Start": "2000-01-01",
        "ComingEvents": [],
        "NumNewNotifications": 0
    },
    "FeedbackData": {
        "HaveFeeback": false,
        "NumNewPosts": 0
    },
    "AllowSingleMailForStudents": false
};

/**
 * @type import('./Classes').ClassState
 */
const initialState = {
    teacherData: null,
    classes: [],
    currentClass: null,
    classOverview: null,
    eventsSinceDate: new Date((new Date()).getTime() - 1000 * 3600 * 24 * 14), // 14 days back from now
    NotificationInEdit: null,
    readNotifications: {},
    teacherClasses: null,

};


const classesSlice = createSlice({
    name: 'classes',
    initialState: initialState,
    reducers: {
        setClasses(state, action) {
            state.classes = action.payload;
        },


        /**
         * 
         * @param {{payload:import('./Classes').ClassAllDataDto}} action 
         */
        setClassData(state, action) {


            if (!action.payload) {
                state.currentClass = {
                    ...initialState
                }

                state.NotificationInEdit = null;
                state.currentClass.notificationsToEdit = null;

                return;
            }

            state.classes = [...state.classes.filter(c => c.Id !== classDefaults.Id && c.Id !== action.payload.Id), action.payload];
            let newData = { ...action.payload, notifications: [] };
            if (newData.DashBoardData) {
                if (newData.DashBoardData.StudentData == null && newData.DashBoardData.TeacherData != null) {
                    newData.DashBoardData.StudentData = { ...newData.DashBoardData.TeacherData };
                }
                newData.notifications = newData.DashBoardData.StudentData.Notifications;
                if (newData.notifications) {
                    newData.notifications.forEach(n => {
                        if (state.readNotifications[n.Id]) {
                            n.NewForUser = false;
                        }
                    });
                }
                else {
                    newData.notifications = [];
                }

                newData.DashBoardData =
                    { ...newData.DashBoardData, NumNewNotifications: newData.notifications.filter(n => n.NewForUser).length };
            }
            state.currentClass = newData;

            state.NotificationInEdit = null;
            state.currentClass.notificationsToEdit = null;
        },

        /**
         * 
         * @param {{payload:import('./TeacherData').NavbarDto}} action 
         */
        setTeacherData(state, action) {
            if (action.payload?.Classes) {

                state.teacherData = {
                    ...action.payload, Classes: {
                        ...action.payload.Classes, RunningFilter: {
                            Classes: [...action.payload.Classes.Running],
                            FilterText: '',
                            Order: 'none'
                        }
                    }
                };
            }
            else {
                state.teacherData = action.payload;
            }

        },

        /**
         * 
         * @param {{payload:{ text: string, order: 'none'| 'asc' | 'desc' }}} action 
         */
        filterRunningclasses(state, action) {

            if (!action.payload.text && action.payload.order === 'none') {
                state.teacherData.Classes.RunningFilter = {
                    Classes: state.teacherData.Classes.Running,
                    FilterText: null,
                    Order: 'none'
                }
            }
            else {

                let classes = [...state.teacherData.Classes.Running];

                if (action.payload.order !== 'none') {
                    classes.sort((a, b) => {
                        if (action.payload.order === 'asc') {
                            return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase());
                        }
                        else {
                            return b.Name.toLowerCase().localeCompare(a.Name.toLowerCase());
                        }
                    });
                }

                if (action.payload.text && action.payload.text.length > 0) {
                    const t = action.payload.text.toLowerCase();
                    classes = classes.filter(c => c.Name.toLowerCase().indexOf(t) > -1);
                }

                state.teacherData.Classes.RunningFilter = {
                    Classes: classes,
                    FilterText: action.payload.text,
                    Order: action.payload.order
                };

            }
        },


        /**
        * 
        * @param {{payload: import('./Classes').TeacherClassDto[] }} action 
        */
        setTeacherClasses(state, action) {
            state.teacherClasses = action.payload;
        },

        /**
         * 
         * reset the list of  read notification
         * useful at login 
         */
        resetReadNotifications(state, action) {
            state.readNotifications = {};
        },


        /**
         * @param  {  {payload: import('./Classes').ClassInfoDto}} action
         */
        setMembers(state, action) {
            state.currentClass.Info = action.payload;
        },

        /**
       * @param  {  {payload: string}} action
       */
        setPartWatched(state, action) {
            state.currentClass.Themes.forEach(t => {
                const part = t.CourseParts.find(p => p.Id === action.payload);
                if (part && part.NoTests) {
                    part.Status = "approvedbyteacher";
                }
            })
        },


        /**
        * @param  {  {payload: {members :import('features/User/User').UserInfoCellDto[], groupId: string }}} action
        */
        setGroupMembers(state, action) {
            const group = state.currentClass.SubGroups.find(gr => gr.Id === action.payload.groupId);

            if (group == null) {
                throw Error("Group not found. Id: " + action.payload.groupId);
            }

            group.Members = action.payload.members;
        },

        /**
        * @param  {  {payload: {data: import('./Classes').NotificationDto[], edit: boolean}}} action
        */
        setNotifications(state, action) {
            if (action.payload.edit) {
                state.currentClass.notificationsToEdit = action.payload.data;
            }
            else {
                action.payload.data.forEach(n => {
                    if (state.readNotifications[n.Id]) {
                        n.NewForUser = false;
                    }
                })
                state.currentClass.notifications = action.payload.data;
                state.currentClass.notificationsToEdit = null;
            }
        },

        /**
        * @param  {  {payload: import('./Classes').NotificationDto[]}} action
        */
        addNotifications(state, action) {
            action.payload.forEach(n => state.currentClass.notifications.push(n));
        },


        /**
        * @param  {  {payload: Date} } action
        */
        setEventsSinceDate(state, action) {
            state.eventsSinceDate = action.payload;
        },

        /**
        * @param  {  {payload: string} } action
        */
        setStudentForSingleTestResult(state, action) {
            if (state.currentClass) {
                state.currentClass.studentForSingleTestResult = action.payload;
            }
        },





        /**
        * @param  {  {payload: { partId:string, status:string}}} action
        */
        setStatusInCurrentClass(state, action) {

            if (state.currentClass && state.currentClass.Themes) {
                state.currentClass.Themes.forEach(theme => {
                    theme.CourseParts.forEach(part => {
                        if (part.Id === action.payload.partId) {
                            part.Status = action.payload.status;
                            let statuses = theme.CourseParts.map(p => p.Status.toLowerCase());
                            theme.Status = getThemeStatus(statuses);
                        }
                    });
                });
            }
        },

        /**
        * @param  {  {payload: import('./Classes').ClassOverviewDto[]}} action
        */
        setClassOverviews(state, action) {
            state.classOverview = action.payload;
        },

        /**
            * @param  {  {payload: import('./Classes').NotificationEditDto}} action
            */
        setEditNotification(state, action) {
            state.NotificationInEdit = action.payload;
        },

        /**
           * @param  {  {payload: boolean}} action
           */
        setEditNotificationDirty(state, action) {
            if (state.NotificationInEdit) {
                state.NotificationInEdit.dirty = action.payload;
            }
        },


        /**
         * @param  {  {payload: import('./Classes').NotificationEditDto}} action
         */
        updateNotification(state, action) {
            const data = action.payload;
            var index = state.currentClass.notificationsToEdit.findIndex(n => n.Id === data.Id);

            if (index > -1) {
                const note = state.currentClass.notificationsToEdit[index];
                const newData = {
                    ...note, StartDate: data.StartDate, EndDate: data.EndDate,
                    DisplayType: data.DisplayType, PublishGroup: data.PublishGroup,
                    Html: data.Messages[0].Message, Header: data.Messages[0].Header,
                    Visible: data.Visible
                };

                state.currentClass.notificationsToEdit.splice(index, 1, newData);
            }
        },

        /**
         * @param  {  {payload: string}} action
         */
        removeNotificationFromList(state, action) {
            var index = state.currentClass.notificationsToEdit.findIndex(n => n.Id === action.payload);

            if (index > -1) {
                state.currentClass.notificationsToEdit.splice(index, 1);
            }
        },

        /**
         * @param  {  {payload: string}} action
         */
        setNotificationAsRead(state, action) {
            let note = state.currentClass.notifications.find(n => n.Id === action.payload);
            if (note) {
                if (note.NewForUser) {
                    note.NewForUser = false;
                }

                let dashBoardData = state.currentClass.DashBoardData.TeacherData;
                if (!dashBoardData) {
                    dashBoardData = state.currentClass.DashBoardData.StudentData;
                }

                if (dashBoardData) {
                    let note = dashBoardData.Notifications.find(n => n.Id === action.payload);
                    if (note && note.NewForUser) {
                        note.NewForUser = false;
                    }
                }

                state.readNotifications[note.Id] = true;

                state.currentClass.DashBoardData.NumNewNotifications
                    = state.currentClass.notifications.filter(n => n.NewForUser).length;
            }
        },



        /**
         * @param  {  {payload: boolean}} action
         */
        setMaximizedActivityScreen(state, action) {
            state.currentClass.maximizedscreen = action.payload;
        },


    }
});


const getClassOverview = () => async (dispatch) => {
    /**
     * @type {{data: import('./Classes').ClassOverviewDto[]}}
     */
    const response = await instance.get(endPoint.CLASS_OVERVIEW_URL());
    if (!response) {
        return;
    }
    dispatch(classesDuck.setClassOverviews(response.data));
}

const getClassMembers = (classId) => async (dispatch) => {
    const response = await instance.get(endPoint.CLASS_INFO_URL(classId));
    if (!response) {
        return;
    }
    dispatch(classesDuck.setMembers(response.data));
}

const getStudents = (classId) => async (dispatch, getState) => {

    /**
     * @type import('./Classes').ClassState
     */
    const classData = getState().classData;

    if (!classData.currentClass) {
        return [];
    }

    if (classData.currentClass.Info) {
        return classData.currentClass.Info.Students;
    }


    /**
     * @type {{data: import('./Classes').ClassInfoDto}}
     */

    const response = await instance.get(endPoint.CLASS_INFO_URL(classId));
    if (!response) {
        return;
    }
    dispatch(classesDuck.setMembers(response.data));

    return response.data.Students
}

const getStudentsAndTeachers = (classId) => async (dispatch, getState) => {

    /**
     * @type import('./Classes').ClassState
     */
    const classData = getState().classData;

    if (!classData.currentClass) {
        return [];
    }

    if (classData.currentClass.Info) {
        return [...classData.currentClass.Info.Students, ...classData.currentClass.Info.Teachers];
    }


    /**
     * @type {{data: import('./Classes').ClassInfoDto}}
     */

    const response = await instance.get(endPoint.CLASS_INFO_URL(classId));
    if (!response) {
        return;
    }
    dispatch(classesDuck.setMembers(response.data));

    return [...response.data.Students, ...response.data.Teachers];
}


const getNotifications = (classId, edit) => async (dispatch) => {

    if (!edit) {
        return;
    }

    const url = edit ? endPoint.CLASS_NOTIFICATIONS_EDIT_URL(classId) : endPoint.CLASS_NOTIFICATIONS_URL(classId);
    const response = await instance.get(url);
    if (!response) {
        return;
    }
    dispatch(classesDuck.setNotifications({ data: response.data, edit }));
}


const newNotification = (classId) => async (dispatch) => {

    /**
     * @type {{data: {Value: string}}}
     */
    const response = await instance.post(endPoint.CLASS_NOTIFICATION_NEW_URL(classId), null);
    if (!response) {
        return;
    }
    dispatch(classesDuck.getNotifications(classId, true));
    return response.data.Value;
}

const deleteNotification = (classid, id) => async (dispatch) => {

    await instance.delete(endPoint.CLASS_NOTIFICATION_DELETE_URL(classid, id));
    dispatch(classesDuck.removeNotificationFromList(id));
    dispatch(classesDuck.setEditNotification(null));
}


const getNotificationForEdit = (classId, id) => async (dispatch) => {

    dispatch(classesDuck.setEditNotification(null));
    /**
     * @type {{data: import('./Classes').NotificationEditDto}}
     */
    const response = await instance.get(endPoint.CLASS_NOTIFICATION_EDIT_URL(classId, id));
    if (!response) {
        return;
    }
    dispatch(classesDuck.setEditNotification(response.data));
}


/**
 * @param {import('./Classes').NotificationEditDto} notif
 */
const saveNotification = (notif) => async (dispatch, getState) => {

    /**
     * @type {import('./Classes').ClassState}
     */
    const state = getState().classData;

    /**
     * @type {{data: import('./Classes').NotificationEditDto}}
     */
    const response = await instance.put(endPoint.CLASS_NOTIFICATION_EDIT_URL(state.currentClass.Id, notif.Id), notif);
    if (!response) {
        return;
    }
    dispatch(classesDuck.updateNotification(response.data));
}



const getCurrentClass = async (dispatch, classId, eventsSinceDate, clearOld = true) => {

    if (clearOld) {
        await dispatch(classesDuck.setClassData(classDefaults));
    }
    const data = await classesDuck.getClassDashBoardData(classId, eventsSinceDate)
    if (data) {

        data.Themes.forEach(th => {

            let statuses = th.CourseParts.map(p => p.Status.toLowerCase());
            th.Status = getThemeStatus(statuses);
        });

        dispatch(classesDuck.setClassData(data));
        dispatch(LoginDuck.setUserCurrentClass(classId));
        dispatch(forumService.setFilterData(null));
        return data;

    }

    return null;
}
/**
 * 
 * @param {string} classId 
 * @param {Date} eventsSinceDate 
 * @returns {Promise<import('./Classes').ClassAllDataDto>}
 */
const getClassDashBoardData = async (classId, eventsSinceDate) => {

    /**
     * @type { {data: import('./Classes').ClassAllDataDto  }}
     */
    const response = await instance.get(endPoint.DASHBOARD_AND_CLASS_URL(classId, eventsSinceDate.toISOString()));
    if (response && response.data) {
        return response.data;
    }

    return null;
}

/**
 * 
 * Get data to start the teacher all clases overview
 */
const getTeacherClasses = () => async (dispatch, getState) => {

    /**
     * @type { {data: import('./Classes').TeacherClassDto[]  }}
     */
    const response = await instance.get(endPoint.TEACHER_ALL_CLASSES_URL());
    if (response && response.data) {
        dispatch(classesDuck.setTeacherClasses(response.data));
    }

    return null;
}

/**
 * 
 * @param {string} classid 
 * @param {Date} lastDate 
 * @returns { Promise<import('./Classes').StudentClassDtoForTeacher>}
 */
const getTeacherClassData = async (classid, lastDate) => {

    /**
     * @type {{data: import('./Classes').StudentClassDtoForTeacher }}
     */
    const response = await instance.get(endPoint.TEACHER_CLASS_DATA_URL({ classid, dateString: lastDate.toISOString() }));
    if (response) {
        return response.data;
    }

    return null;
}


const getGroupMembers = async (dispatch, classId, groupId) => {

    const response = await instance.get(endPoint.GET_MEMBERS_FOR_BASEGROUP_URL(classId, groupId));
    if (!response) {
        return;
    }
    dispatch(classesDuck.setGroupMembers({ members: response.data, groupId: groupId }));
}

const getTeacherData = async (dispatch) => {
    /**
        * @type {{data: import('features/classes/TeacherData').NavbarDto}}
        */
    const response = await instance.get(endPoint.TEACHER_NAVBAR_URL);
    if (response && response.data) {
        dispatch(classesDuck.setTeacherData(response.data));
        return true;
    }

    return false;
}

const getThemeStatus = (statuses) => {

    var tstatus = { approved: false, notset: false, some: false };

    statuses.forEach(stat => {

        if (stat.indexOf("approved") > -1) {
            tstatus.approved = true;
        }
        else if (stat.indexOf("notset") === -1) {
            tstatus.some = true
        } else {
            tstatus.notset = true;
        }
    });

    if (tstatus.approved) {
        if (tstatus.notset || tstatus.some) {
            return TestResultStatus.Passed;
        }
        else {
            return TestResultStatus.ApprovedByTeacher;
        }
    } else if (tstatus.some) {
        return TestResultStatus.Passed;
    } else {
        return TestResultStatus.NotSet;
    }



}

const resendVerificationCode = async () => {

    const resp = await instance.post(endPoint.RESEND_VERFICATION_CODE_URL);
    if (resp.status === 200) {
        return resp.data;
    }

    return null;

}

const verifyCode = async (code, token) => {

    const resp = await instance.post(endPoint.VERFY_CODE_URL, { code, token });
    if (resp && 100 < resp.status && resp.status < 300) {
        return true;
    }

    return false;

}


export const classesDuck = {
    ...classesSlice.actions,
    getCurrentClass, getNotifications, getClassMembers,
    getNotificationForEdit, getClassOverview, getGroupMembers,
    saveNotification, newNotification, deleteNotification,
    getStudents, getTeacherData, getClassDashBoardData, getTeacherClasses,
    getTeacherClassData, getStudentsAndTeachers,
    resendVerificationCode, verifyCode

};

export default classesSlice.reducer;
