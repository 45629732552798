//@ts-check
import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import ScrollContent from "components/ScrollContent";
import { useDispatch } from "react-redux";
import WaitRipple from "components/WaitRipple";
import FeedbackView from "./FeedbackView";
import FeedbackThread from "./FeedThread";

const Feedback = () => {
    const route = useTypedSelector(state => state.location.type);
    const currentForum = useTypedSelector(state => state.forum.currentForum);
    const dispatch = useDispatch();

    const handleThreadClick = (threadId) => {
        dispatch({
            type: "GENERAL_FEEDBACK_THREAD",
            payload: {
                thread: threadId
            }
        });
    }

    const handleLeaveThreadClick = () => {
        dispatch({
            type: "GENERAL_FEEDBACK_FORUM",
        });
    }

    if( currentForum === null ){
        return <WaitRipple />
    }

    return (
        <>
            {route === "GENERAL_FEEDBACK_FORUM" &&
                <ScrollContent scrollname="class-forum">
                    <div className="card-box big-card-box">
                        <FeedbackView onThreadClick={handleThreadClick} />
                    </div>
                </ScrollContent>
            }
            {route === "GENERAL_FEEDBACK_THREAD" &&
                <ScrollContent scrollname="class-forumthread">
                    <div className="card-box big-card-box">
                        <FeedbackThread onLeaveThreadClick={handleLeaveThreadClick} />
                    </div>
                </ScrollContent>
            }

        </>
    )




}


export default Feedback;