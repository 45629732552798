//@ts-check
import React, { useEffect, useRef } from "react";
import useTypedSelector from 'utils/useTypedSelector';
import UserImg from "components/UserImg";
import { endPoint } from "AppConstants";

import LnIcon from "components/LnIcon";
import WaitRipple from "components/WaitRipple";
import { useI18n } from "components/lni18n";
import classes from 'features/classes/ClassItems/Forum/forum.module.scss';


/**
 * 
 * @param {{onThreadClick: Function, subGroupId?: string }} param0 
 */
const FeedbackView = ({ onThreadClick }) => {
    const currentForum = useTypedSelector(state => state.forum.currentForum);
    const { languageService: t } = useI18n();

    /**
     * @type {{current: HTMLTableCellElement}}
     */
    const selectedRow = useRef(null);
  
   
    useEffect(() => {
        if (!selectedRow.current) {
            return;
        }
        const e = selectedRow.current;
        window.setTimeout(() => e.scrollIntoView(true), 300);

    }, [selectedRow]);

    if (currentForum == null) {
        return <WaitRipple />
    };


    return (
        <>
            <div className="card-header ln-nostyle d-flex justify-content-between flex-wrap">
                <h3 className="mb-4">
                    <LnIcon className="header-icon" name="ear" />
                    {t.getText('feedback')}
                </h3>


            </div>
            <div className="card-body pt-5">
               

                <table role="table" className={["table table-hover pointer ln-table-responsive", classes['forum-table']].join(' ')}>
                    <thead>
                        <tr role="row">
                            <th role="columnheader" className={['th-clean', classes['td-l']].join(' ')}>
                                {t.getText('title')}/{t.getText('owner')}
                                {currentForum.NumPages > 1 &&
                                    <small className="ml-3 py-1 px-3 border rounded">{currentForum.Page} / {currentForum.NumPages}</small>
                                }
                            </th>
                            <th role="columnheader" className={['th-clean', classes['td-xs']].join(' ')}>
                                {t.getText('replies')}
                            </th>
                            <th role="columnheader" className={['th-clean', classes['td-s']].join(' ')}>
                                {t.getText('latest.activity')}

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentForum.Headers.map(th => {

                            const newPost = th.UnreadCount > 0 || !th.HasRead;
                            return (

                                <tr role="row" className={classes['row-link'] + (newPost ? " font-weight-bold" : "") + (th.selected ? " bg-primary-lightest" : "")}
                                    key={th.Id} onClick={() => onThreadClick(th.Id)}>
                                    <td role="cell" ref={th.selected ? selectedRow : null} >


                                        <span className="d-flex flex-row align-items-center">
                                            {th.Writer.ImageUrl ? <UserImg src={endPoint.API_HOST + th.Writer.ImageUrl} className={['rounded-circle mr-4', 'avatar-img', classes['thread-writer-avatar']].join(' ')} alt={th.Writer.FullName} /> : <LnIcon className={['rounded-circle mr-4', 'avatar-img', classes['thread-generic-avatar']].join(' ')} name="user-icon" />}
                                            <span className="d-flex flex-column">
                                                <span className="d-flex">
                                                    <label className="td-label">{t.getText('title')}</label>
                                                    {th.Header}
                                                </span>
                                                <span className="d-flex">
                                                    <label className="td-label">{t.getText('owner')}</label>
                                                    <small>{th.Writer.FullName}</small>
                                                </span>
                                            </span>
                                        </span>
                                    </td>
                                    <td role="cell" >
                                        <div className="d-flex align-items-center">
                                            <label className="td-label">{t.getText('replies')}</label>
                                            {th.AnswerCount}
                                            {th.UnreadCount  ? <>
                                                <span className="badge-warning badge badge-pill fixedSize ml-4" title={t.getText('forum.unread.posts2')} dangerouslySetInnerHTML={{ __html: th.UnreadCount ? th.UnreadCount.toString() : "&nbsp;" }} ></span>
                                                <span className="sr-only">{t.getText('forum.unread.posts2')}</span>
                                            </> : null}
                                        </div>
                                    </td>
                                    <td role="cell">
                                        <div className={"position-relative small" + (newPost ? " font-weight-bold" : "")} >


                                            <label className="td-label">{t.getText('latest.activity')}</label>
                                            <span >
                                                {t.getShortDateTimeString(th.LastActivity)}
                                            </span>
                                            <br />
                                            {currentForum.IsTeacher && <LnIcon className="small" name={th.Published ? "eye-icon" : "eye-icon-off"} />}
                                        </div>
                                    </td>
                                </tr>

                            )
                        })}
                    </tbody>
                </table>
              
            </div>
        </>
    );
};


export default FeedbackView;