// @ts-check
import React, { useState } from 'react';
import dayjs from "dayjs";
import LnIcon from "components/LnIcon";
import { Button} from 'reactstrap';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DayPicker from 'react-day-picker/DayPicker';

import 'assets/scss/_datepicker.scss';
import { useI18n } from "components/lni18n";
import useTypedSelector from "utils/useTypedSelector";
import TimeKeeper from 'react-timekeeper';
import 'dayjs/locale/sv';
import 'dayjs/locale/da';




/**
 * DateTimePicker
 * @module components/DateTimePicker/DateTimePicker
 * @return a Date selected by the user.
 * @param {{
    Date:  Date,
    DatePickerId?: string,
    showMonthDropdown?: boolean,
    showYearDropdown?: boolean,
    showTimeSelect?: boolean,
    showWeekNumbers?: boolean,
    timeCaption?: string, // time
    timeFormat?: string, // HH:mm
    timeIntervals?: number, // {15}
    renderDays: 'all' | 'past' | 'future',
    onDateChanged: Function;

}} props
 */
const DateTimePicker = (props) => {


    const initialDate = props.Date ? props.Date : new Date();
    const [dateValue, setDateValue] = useState(initialDate);
    const { languageService: t } = useI18n();
    const [dropDownDate, setDropDownDate] = useState(initialDate);

    const currentLang = useTypedSelector(state => state.text.currentLang);

    //dayjs.extend(localeData);
    const globalLocaleData = dayjs.localeData()
    const localMonths = globalLocaleData.months();
    const localDaysShort = globalLocaleData.weekdaysShort();
    const localDays = globalLocaleData.weekdays();
    const firstDayOfWeek = globalLocaleData.firstDayOfWeek();


    const handleTime = (data) => {
        setDateValue(dayjs(dateValue).hour(data.hour).minute(data.minute).toDate());
    }

    const handleMonthChange = (month) => {
        setDropDownDate(month);
    }

    const handleDateClick = (d) => {
        setDateState(d);
    }

    const setstartMonth = (index) => {
        const d = setDateState(dayjs(dropDownDate).month(index).toDate());
        setDropDownDate(d);
    }

    const setstartYear = (y) => {
        const d = setDateState(dayjs(dropDownDate).year(y).toDate());
        setDropDownDate(d)

    }

    const setDateState = (d) => {

        let da = d;
        if (props.showTimeSelect) {
            const oldVal = dayjs(dateValue);
            da = dayjs(d).hour(oldVal.hour()).minute(oldVal.minute()).toDate();
        } else {
            da = dayjs(d).hour(0).minute(0).toDate();
        }

        setDateValue(da);
        props.onDateChanged(da);
        return da;
    }

    let disabledDays = (day) => false;

    let now = dayjs().hour(0).minute(1);


    if (props.renderDays === 'past') {
        disabledDays = (day) => now.isBefore(day, 'day');
    }

    if (props.renderDays === 'future') {
        disabledDays = (day) => now.isAfter(day, 'day');
    }

    const years = [];
    const startYear = (new Date()).getFullYear() - 10;
    for (let i = startYear; i <= (startYear + 20); i += 1) {
        years.push(i);
    }

    const setToday = () => {
        setDateValue(new Date());
        setDropDownDate(new Date());
    }

    const Navbar = (prps) => {
        return <div className="mt-3 d-flex justify-content-between">
            <button className="btn btn-link" onClick={() => prps.onPreviousClick()} ><LnIcon name="previous" className="mr-2" /> {t.getText("previous")} </button>
            <button className="btn btn-link" onClick={() => prps.onNextClick()} >{t.getText("next")}<LnIcon className="ml-2" name="next" /></button>
        </div>
    }

    return (<>
        {(props.showMonthDropdown || props.showMonthDropdown) &&
            <div className="d-flex justify-content-center">
                {props.showMonthDropdown &&
                    <UncontrolledDropdown >
                        <DropdownToggle caret className="btn btn-primary btn-small">
                            {dayjs(dropDownDate,).format("MMM")}
                        </DropdownToggle>
                        <DropdownMenu>
                            {dayjs.months().map((m, i) => <DropdownItem key={i} onClick={() => setstartMonth(i)} >{m}</DropdownItem>)}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                }

                {props.showYearDropdown &&
                    <UncontrolledDropdown className="ml-5">
                        <DropdownToggle caret className="btn btn-primary btn-small">
                            {dayjs(dropDownDate,).format("YYYY")}
                        </DropdownToggle>
                        <DropdownMenu >
                            {years.map((y) => <DropdownItem className={y === now.year() ? "font-weight-bold text-success" : ""} key={y} onClick={() => setstartYear(y)} >{y}</DropdownItem>)}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                }


            </div>
        }

        <div className="d-flex m-auto justify-content-center">

            <DayPicker onDayClick={(d) => handleDateClick(d)}
                initialMonth={dateValue}
                selectedDays={dateValue}
                numberOfMonths={window.outerWidth < 900 ? 1 : 2}
                showOutsideDays={true}
                showWeekNumbers={props.showWeekNumbers}
                disabledDays={disabledDays}
                month={dateValue}
                locale={currentLang.Short}
                weekdaysShort={localDaysShort}
                navbarElement={<Navbar />}
                onMonthChange={handleMonthChange}
                months={localMonths}
                weekdaysLong={localDays}
                firstDayOfWeek={firstDayOfWeek}

            />
        </div>

        <div className="d-flex align-items-center justify-content-between">
            <div>&nbsp;</div>
            {!props.showTimeSelect ? <span> {t.getShortDateString(dateValue)}</span> :
                <UncontrolledDropdown direction="up" >
                    <DropdownToggle tag="button" className="btn btn-link d-flex align-items-center"  >
                        {t.getShortDateTimeString(dateValue)} <LnIcon name="time" className="ml-3 small" />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem>
                            <button type="button" className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
                        </DropdownItem>
                        <TimeKeeper
                            time={dayjs(dateValue).format("HH:mm")}
                            onChange={(newTime) => handleTime(newTime)}
                            hour24Mode={t.localeUses24HourTime()}
                            switchToMinuteOnHourSelect={true}
                            coarseMinutes={5}
                            forceCoarseMinutes={true}
                        />


                    </DropdownMenu>
                </UncontrolledDropdown>
            }

            <Button className={'btn btn-small btn-primary mr-4'} onClick={() => setToday()}>{t.getText("today")}</Button>
        </div>
    </>

    );
}

export default DateTimePicker;

