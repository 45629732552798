//@ts-check
import React, { useEffect, useState } from "react";
import useTypedSelector from 'utils/useTypedSelector';
import { useDispatch } from "react-redux";

import LnIcon from "components/LnIcon";
import WaitRipple from "components/WaitRipple";
import { useI18n } from "components/lni18n";
import { feedDuck } from "./FeedDuck";
import FeedCard from "./FeedCard";
import FeedForm from "./FeedForm";
import classes from './feed.module.scss';
import UncontrolledAlert from "reactstrap/lib/UncontrolledAlert";
import FilterOutUserInFeed from "./FilterOutUserInFeed";

/**
 * Feed
 * @module features/classes/ClassItems/Feed
 * @return the feed with all GUI for reading and posting
 * @param {{subGroupId?: string }} props
 *
 */

const FeedView = ({subGroupId}) => {
    const currentFeed = useTypedSelector(state => state.feed.currentFeed);
    const dispatch = useDispatch();
    const { languageService: t } = useI18n();
    const [showForm, toggleShowForm] = useState(false);
    const currentUser = useTypedSelector(state => state.login.user);

    const GetMorePosts = () => {
        const lastId = currentFeed.Posts[currentFeed.Posts.length - 1].Id;
        dispatch(feedDuck.getMoreData(lastId));
    };

    const toggleForm = () => {
        toggleShowForm(!showForm);
    };

    useEffect(()=>{
        dispatch(feedDuck.setFilterData(null));
        // eslint-disable-next-line 
    }, [])

    if (currentFeed === null) {
        return <WaitRipple />
    };

    return (
        <>
            <div className="card-header ln-nostyle d-flex justify-content-between flex-wrap">
                <h3>
                    <LnIcon className="header-icon" name="feed-icon" />
                    {t.getText('feed.menuname')}
                </h3>
                <UncontrolledAlert className="d-print-none" >
                    <h3>{t.getText("help")}</h3>
                    <a href="https://static.laranara.se/video/feed.mp4" rel="noopener noreferrer" target="_blank" >
                        {t.getText("video")} <LnIcon name="play" className="icon-small"/>
                    </a>
                </UncontrolledAlert>
                {!showForm ? <button className="d-print-none btn btn-primary btn-small" onClick={() => toggleForm()}>{t.getText('new_posting')} </button> : null}

                {currentUser.UserState > 0 && <FilterOutUserInFeed subGroupId={subGroupId} className="w-50 d-print-none" />}

            </div>
            <div className="card-body pt-5">
                <div className="d-flex justify-content-between mb-4 d-print-none">
                    <p className={classes['pre-wrap']}>
                        {t.getText('feed.explain')}
                    </p>
                </div>
                {showForm ? <div><FeedForm initialValue="" postId={null} isComment={false}  feedId={currentFeed.Id} toggleForm={toggleForm} /></div> : null}
                {currentFeed.Posts.map(p => (
                    (
                        <FeedCard key={p.Id} post={p} />
                    )
                ))}
                <div> {currentFeed.HasMorePosts && <button className="btn btn-primary" onClick={() => GetMorePosts()}>{t.getText("feed.moreposts")}</button>}</div>
            </div>
        </>
    )
}

export default FeedView;
