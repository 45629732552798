//@ts-check
import { createSlice } from "@reduxjs/toolkit";
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import TestService from "../test/TestService";
import { errorDuck } from "components/ErrorHandler/ErrorHandlerDuck"

const FileTestFileTypeStrings =
{
  Submitted: "Submitted",
  Commented: "Commented"
}

/**
 * @type import('./FileTest').FileTestState
 */
const initialState = {

  isDeletingFile: false,
  currentTest: {
    CanSubmit: false,
    Question: null,
    Options: null,
    SubmittedFiles: [],
    CommentedFiles: [],
  },
  currentTestForComments: null


}

const fileTestSlice = createSlice({
  name: "filetest",
  initialState: initialState,
  reducers: {

    /**
     * @param  {  {payload: import('./FileTest').FileTestData}} action
     */
    setCurrentFileTest(state, action) {
      state.currentTest = action.payload;
    },

    /**
     * @param  {  {payload: import('./FileTest').FileTestStateFileDto}} action
     */
    addStudentFile(state, action) {
      if (state.currentTest === null) { return; }
      state.currentTest.SubmittedFiles.push(action.payload);

    },



    /**
    * @param  {  {payload: {fileId: string, type: string}}} action
    */
    deleteFile(state, action) {
      if (state.currentTest === null) {
        return;
      }

      const key = action.payload.type + "Files"
      if (!state.currentTest[key]
        || state.currentTest[key].length === 0) {
        return;
      }

      state.currentTest[key] = state.currentTest[key]
        .filter(f => f.Id !== action.payload.fileId);

    },

    resetFileTest(state, action) {
      state.currentTest = null;
    },

    /**
    * @param  {  {payload: boolean}} action
    */
    setDeletintgFile(state, action) {
      state.isDeletingFile = action.payload;
    },


    /**
    * @param  {  {payload: import("./FileTest").FileTestStateForCommentsDto }} action
    */
    setCommentData(state, action) {
      state.currentTestForComments = action.payload;
    },


    /**
    * @param  {  {payload: {data: import('features/course/part/FileTest/FileTest').FileTestStateFileDto, type: string}}} action
    */
    addFileToFileTest(state, action) {
      if (!state.currentTestForComments) {
        return;
      }



      if (action.payload.type === "Commented") {
        state.currentTestForComments.CommentedFiles.push(action.payload.data);
      }
      else {
        state.currentTestForComments.SubmittedFiles.push(action.payload.data);
      }

    },

    /**
      * @param  {  {payload: { fileId: string, type: string } }} action
      */
    removeFileFromFileTest(state, action) {
      if (state.currentTestForComments) {
        if (action.payload.type === "Commented") {
          state.currentTestForComments.CommentedFiles = state.currentTestForComments.CommentedFiles.filter(f => f.Id !== action.payload.fileId);
        }
        else {
          state.currentTestForComments.SubmittedFiles = state.currentTestForComments.SubmittedFiles.filter(f => f.Id !== action.payload.fileId);
        }
      }
    },


  }



});

/**
 * @param {{ classId:string, testId:string, dto:import('./FileTest').FileTestAddFilePostDto}} payload
 * @param {boolean | undefined} teacherComment
 */
const addFileToTest = (payload, teacherComment) => async (dispatch, getState) => {


  try {
    /**
     * 
     * @type {{data: import('./FileTest').FileTestStateFileDto}}
     */
    const response = await instance.post(endPoint.GET_POST_FILE_TO_TEST_URL(payload.classId, payload.testId), payload.dto);
    if (!response) {
      return;
    }

    if (!teacherComment) {
      if (payload.dto.Type === "Submitted") {
        dispatch(fileTestDuck.addStudentFile(response.data));

        TestService.updateTestStatus({ classId: payload.classId, testId: payload.testId }, dispatch);
      }
    }

    if (teacherComment) {
      dispatch(fileTestDuck.addFileToFileTest({ data: response.data, type: payload.dto.Type }));
    }

  }
  catch (error) {
    handleErrors(error);
  }
};

/**
 * @param {{ type: string,  classId:string, testId:string, dto:import('./FileTest').FileTestRemoveFileData}} payload
 * @param {boolean} teacherComment
 */
const removeFileFromTest = (payload, teacherComment) => async (dispatch, getState) => {
  try {

    /**
    * @type import('./FileTest').FileTestState
    */
    const state = getState().filetest;
    if (state.isDeletingFile) {
      return;
    }

    dispatch(fileTestDuck.setDeletintgFile(true));

    /**
     * @type {{data:{ result:string, status: number, errorMessage: string  }}}
     */
    var result = await instance.post(endPoint.GET_REMOVE_FILE_FROM_TEST_URL(payload.classId, payload.testId), payload.dto);
    if (!result) {
      return;
    }
    var data = result.data;
    if (data.result === "ok") {
      if (!teacherComment) {
        dispatch(fileTestDuck.deleteFile({ fileId: payload.dto.FileId, type: payload.type }));
        TestService.updateTestStatus({ classId: payload.classId, testId: payload.testId }, dispatch);
      }
      else {  // teacher comment
        dispatch(fileTestDuck.removeFileFromFileTest({ fileId: payload.dto.FileId, type: payload.type }));
      }
    }
    else {
      dispatch(errorDuck.setError({ when: new Date(), header: "", message: data.errorMessage, htmlMessage: null }));
    }

  }
  catch (error) {
    handleErrors(error);
  }
  finally {
    dispatch(fileTestDuck.setDeletintgFile(false));
  }

};

const setAsSubmitted = (testId, classId)=> async (dispatch)=>{

     /**
     * @type {{data:boolean}}
     */
      var result = await instance.post(endPoint.GET_SUBMIT_FILETEST_URL(classId, testId));
      if (!result) {
        return;
      }

      if( result.data){
        TestService.updateTestStatus({ classId: classId, testId: testId }, dispatch);
      }
    
}


const handleErrors = error => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }
  console.log(error.config);
};



export const fileTestDuck = { ...fileTestSlice.actions, removeFileFromTest, addFileToTest, FileTestFileTypeStrings, setAsSubmitted }
export default fileTestSlice.reducer;
