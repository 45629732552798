//@ts-check
import React from "react";
import LnIcon from "components/LnIcon";
import { ModalDialog } from "components/OnlineModal";
import {GradeJudgement} from "AppConstants";

const ThemeGrade = ({ grade, languageService }) => {

    const t = languageService;

    const displayGrade = (grade) => {
        ModalDialog({
            bodyClass: 'modal-body',
            buttons: [{
                className: 'btn btn-primary btn-small',
                text: t.getText('close'),
                type: 'dismiss'
            }],

            headerClass: 'modal-header-default',
            message: (<div className="">
                <strong>{grade.Teacher} {t.getShortDateString(grade.Dato)}</strong>

                <p className="mt-3" dangerouslySetInnerHTML={{ __html: grade.Comment }}></p>
            </div>),
            title: <span>
                {grade.Judgement === GradeJudgement.ApprovedPlus ? <LnIcon className="icon-small filter-green mr-2 mb-1" name="star-filled" /> : <LnIcon className="icon-small mr-2 mb-1" name="star" />}
                    <span className="mr-2">
                        {t.getText('student.grade')}:
                    </span>
                {grade.Grade}
            </span>
        })
    }


    const judgementLevels = {
        0: 'grade-notset',
        1: 'grade-failed',
        2: 'grade-approved',
        3: 'grade-approved-excellence'
    }

    return (
        <button className={['btn btn-small', judgementLevels[grade.Judgement]].join(' ')} onClick={() => displayGrade(grade)}>
            <LnIcon className="icon-small filter-white mr-2 mb-1" name={grade.Judgement === GradeJudgement.ApprovedPlus ? "star-filled" : "star"} />
            <span className="mr-2">
                {t.getText('student.grade')}:
            </span>
            {grade.Grade}
        </button>
    )
}


export default ThemeGrade;