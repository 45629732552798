//@ts-check
import React, { useCallback, useEffect, useMemo } from "react";
import useTypedSelector from "utils/useTypedSelector";

import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { ErrorMessage } from '@hookform/error-message';

import { useI18n } from "components/lni18n";
import { useState } from "react";
import Preview from "./Preview";
import classes from "./ImagePicker.module.scss";
let timerId = null;

/**
 * 
 * @param {{onSubmit: Function}} props
 */
const ImageExternUrl = ({ onSubmit }) => {

    const [imgUrl, setImgUrl] = useState(null);
    const { imageAlign } = useTypedSelector(state => state.imagepicker);
    const { languageService: t } = useI18n();

    const validationSchema = useMemo(() => Yup.object({
        url: Yup.string()
            .matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                t.getText('not.a.valid.url')
            ).required(t.getText('not.a.valid.url'))
    }), [t]);



    const { register, watch, errors, formState } = useForm({
        defaultValues: { url: "" },
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });


    const handleInsert = useCallback(() => {
        onSubmit({ imageAlign: imageAlign, selectedUrl: imgUrl });
    }, [imageAlign, imgUrl, onSubmit]);


    const watchUrl = watch(['url']);

    const handleUrl = useCallback((url) => {

        /**
         * @type {HTMLImageElement}
         */
        var image = new Image();
        image.onload = () => {
            console.log(image.width);
            setImgUrl(old => url);
        }

        try {
            image.src = url;
        } catch (e) {
            setImgUrl(null);
        }
    }, [setImgUrl]);

    useEffect(() => {

        if (timerId != null) {
            window.clearTimeout(timerId);
        }

        timerId = window.setTimeout(() => {
            timerId = null;
            if (formState.isValid) {
                if (watchUrl.url !== imgUrl) {
                    handleUrl(watchUrl.url);
                }
            }
            else {
                setImgUrl(null);
            }
        }, 1000);



    }, [watchUrl, formState, imgUrl, handleUrl]);


    return <div className={classes.scrollPanel}>

        <div className="max-col-width mx-auto">
            <div className="mb-3">{t.getText("enter.image.url")}</div>
            <input ref={register} type="url" className="form-control" name="url" placeholder="http://www.server.com/img.jpg" ></input>
            <ErrorMessage as="div" errors={errors} name="url" className="ml-3 mt-2" />

            <div>
                {imgUrl && <Preview className="mt-4" onSubmit={() => handleInsert()} src={imgUrl} />}
            </div>
        </div>
    </div>
}

export default ImageExternUrl;
