//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import ScrollContent from 'components/ScrollContent';
import { useI18n } from "components/lni18n";
import WaitRipple from "components/WaitRipple";
import useTypedSelector from "utils/useTypedSelector";
import { NavLink } from "redux-first-router-link";
import LnIcon from "components/LnIcon";
import ResultsGraphic from "components/ResultsGraphic/ResultsGraphic"
import { DropdownMenu, DropdownItem, TabContent, TabPane } from 'reactstrap';
import LnDropDownMenu from "components/LnDropDownMenu";
import classes from './myprogress.module.scss';
import UserTestResults from "./UserTestResults";
import UserWatchedLectures from "./UserWatchedLectures";


const MyProgress = (props) => {

    const dispatch = useDispatch();
    const { languageService: t } = useI18n();
    const { userProgress } = useTypedSelector(state => state.user);
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const userClasses = useTypedSelector(state => state.classData.classes);
    const lastFeature = useTypedSelector(state => state.layout.lastFeature);
    const studentData = currentClass.DashBoardData.StudentData;

    const activeTab = useTypedSelector(state => state.location.payload.type);



    let classesMenu = null;


    if (userProgress == null) {
        return <ScrollContent scrollname="class-myprogress">
            <div className="card-box big-card-box">
                <WaitRipple />
            </div>
        </ScrollContent>
    }

    const goBack = () => {
        if (lastFeature == null) {
            window.history.back();
        }
        else {
            dispatch({ type: lastFeature.type, payload: lastFeature.payload });
        }

    }



    const handleClassClick = (id) => {
        dispatch({ type: "CLASS_PROGRESS", payload: { classid: id, type: 'tests' } })
    }

    if (userClasses && userClasses.length > 1) {
        classesMenu = (<LnDropDownMenu
            caret={false} direction="down"
            toggleComponent={<div className="pointer white-space-nowrap mr-4">{currentClass.Name}
                <span className="dark-caret-small"></span></div>}
            toogleClassName={""}
            toogleTagName="div"
        >
            <DropdownMenu tag="div">
                {
                    userClasses.map(f => {
                        if (f.Id !== currentClass.Id) {
                            return (
                                <DropdownItem className="koll" key={f.Id} tag="div" onClick={() => handleClassClick(f.Id)} >
                                    {f.Name}
                                </DropdownItem>)
                        }
                        else {
                            return null;
                        }
                    })
                }
            </DropdownMenu>
        </LnDropDownMenu>);
    }
    else {
        classesMenu = (<div className={classes.oneClass}>{currentClass.Name}</div>);
    }


    return (
        <ScrollContent scrollname="class-myprogress">
            <div className="card-box big-card-box">
                <div className="card-header ln-nostyle d-flex flex-wrap justify-content-between">
                    <div className="d-flex">
                        <div>
                            <LnIcon className="header-icon" name="progress-icon" />
                            <div>
                                <h3>

                                    {t.getText('my_progress')}
                                </h3>

                                {classesMenu}
                            </div>
                        </div>
                    </div>

                    <ResultsGraphic classData={studentData}
                        listingStyle={classes.graphListstyle}
                        chartStyle={classes.chartStyle}
                        showStatusTexts={false}
                        showLectures={true}
                        testText={<div>{t.getText("tests")}</div>}
                        lecturesText={<div>{t.getText("lectures")}</div>} />

                    <div>
                        <button id="backfromMyProgress" type="button" className="btn btn-inverse btn-small mr-4" onClick={() => goBack()}>{t.getText('back')}</button>
                    </div>
                </div>

                <div className="card-body pt-4">

                    <nav className="custom-tab-heading-list w-100 position-relative d-flex overflow-hidden mb-5" >
                        <NavLink
                            key="tests"
                            className={[activeTab === 'tests' ? 'active ' : ''].join(' ')}
                            to={{ type: "CLASS_PROGRESS", payload: { classid: currentClass.Id, type: 'tests' } }}>
                            {t.getText("tests")}
                        </NavLink>

                        <NavLink
                            key="lectures"
                            className={[activeTab === 'lectures' ? 'active ' : ''].join(' ')}
                            to={{ type: "CLASS_PROGRESS", payload: { classid: currentClass.Id, type: 'lectures' } }}>
                            {t.getText("lectures")}
                        </NavLink>

                    </nav>


                    <TabContent className={classes['tab-content']} activeTab={activeTab}>
                        <TabPane tabId="tests">
                            <UserTestResults />
                        </TabPane>
                        <TabPane tabId="lectures">
                            <UserWatchedLectures />
                        </TabPane>

                    </TabContent>


                </div>

            </div>

        </ScrollContent>
    )
}

export default MyProgress;