//@ts-check
import React,{useState} from "react";
import WaitRipple from "components/WaitRipple"
let Evalsummary = null;

const EvalSummaryStub = (props) => {

    
    const [ready, setReady ] = useState(false);

    import("teacherFeatures").then(features => {
        Evalsummary = features.Evalsummary;
        setReady(true);
    });

    if (!ready) {
        return <WaitRipple />;
    }
    else {
        return <Evalsummary />
    }
}

export default EvalSummaryStub;
