// @ts-check
import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import LoginNormal from "./loginNormal";
import { useI18n } from "components/lni18n";
import { LoginDuck } from "./LoginDuck";
import pingService from "utils/PingService";

const ReLogin = () => {

    const dialog = React.useRef(undefined);
    const { languageService: t } = useI18n();

    const dispatch = useDispatch();

    const handleClose = () => {
        // should we close
        console.log("login close");
        if (dialog && dialog.current) {
            window.setTimeout(() => {
                dialog.current.showModal();
            }, 100);
        }
    }


    const setRefAndOpenDialog = React.useCallback((node) => {
        if (node) {
            node.showModal();
            dialog.current = node;
            node.addEventListener("cancel", (event) => {
                handleClose();
            });
        }
    }, []);

    const cancelRelogin = ()=>{
        
        dispatch(LoginDuck.cancelRelogin());
        //start pinger after 30 sec
        window.setTimeout(()=>pingService.startPing(), 30 * 1000);
    }

    const {
        relogin, user
    } = useTypedSelector(state => state.login, shallowEqual);

    if (!relogin) return null;

    return <dialog className="md" ref={setRefAndOpenDialog}>

        <h4>{t.getText("logged.out")}</h4>
        <div className="mb-4 preserve-white">
            { !user.Federated || <span>{t.getText("logged.out.relogin.federated")}</span>}
            { user.Federated || <span>{t.getText("logged.out.try.relogin")}</span>}
        </div>
        <div className="mb-5 alert alert-grey" >
            <div className="preserve-white mb-2">{ t.getText("no.continue.logged.out") }</div>
            <button onClick={cancelRelogin} className="btn btn-inverse btn-small">{t.getText("continue")}</button>
        </div>

        <LoginNormal />
    </dialog>;

};

export default ReLogin;
