//@ts-check
import { createSlice } from "@reduxjs/toolkit";
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import TestService from "../test/TestService";

/**
 * @type {import('./DrawingTest').DrawingTestState}
 */
const initialState = {
    currentQuestion: null,
    splineOk: false,
    textOk: false,
    canSubmit: false,
    savingToserver: false,

}

const drawingTestSlice = createSlice({
    name: "drawingTest",
    initialState: initialState,
    reducers: {

        /**
         * @param  {  {payload: import('./DrawingTest').DrawingTestData}} action
         */
        setCurrentTest(state, action) {
            state.currentQuestion = action.payload;
            state.textOk = false;
            state.splineOk = false;
        },

        /**
         * @param  {  {payload: boolean} } action
         */
        setSplineOk(state, action) {
            state.splineOk = action.payload;
            state.canSubmit = state.splineOk &&
                ((state.currentQuestion.RequireExplanation && state.textOk) || !state.currentQuestion.RequireExplanation);
        },

        /**
         * @param  {  {payload: boolean}}  action
         */
        setTextState(state, action) {
            state.textOk = action.payload;
        },

        /**
        * @param  {  {payload: string}}  action
        */
        setExplaination(state, action) {
            state.currentQuestion.Explanation = action.payload;
            state.textOk = action.payload.trim().length > 3;
            state.canSubmit = state.splineOk &&
                ((state.currentQuestion.RequireExplanation && state.textOk) || !state.currentQuestion.RequireExplanation);
        },

        /**
         * @param  {  {payload: import('types/types').Point[]}}  action
         */
        setPoints(state, action) {
            state.currentQuestion.Points = action.payload;
        },

        /**
        * 
        * @param {{payload: boolean}} action 
        */
        setSavingToserver(state, action) {
            state.savingToserver = action.payload;
        },


    }
});

/**
 * 
 * @param {string} classId 
 * @param {string} testId 
 * @param {import('./DrawingTest').SubmitDrawingTestData} data 
 * @param {Function} dispatch 
 */
const submitDrawingTest = async (classId, testId, data, dispatch) => {

    try {
        dispatch(drawingTestDuck.setSavingToserver(true));
        const url = endPoint.GET_SUBMIT_DRAWINGTEST_URL(classId, testId)
        await instance.post(url, data);
        TestService.updateTestStatus({ classId: classId, testId: testId }, dispatch);

    } finally {
        dispatch(drawingTestDuck.setSavingToserver(false));
    }
}


/**
 * 
 * @param {string} classId 
 * @param {string} testId 
 * @param {import('types/types').Point[]} data 
 * @param {Function} dispatch 
 */
const checkCurve = async (classId, testId, data, dispatch) => {

    const url = endPoint.GET_CHECK_DRAWINGTEST_URL(classId, testId);
    /**
     * @type {{data:{Value:boolean}}}
     */
    const response = await instance.post(url, data);
    if (!response) {
        return;
    }
    dispatch(drawingTestDuck.setSplineOk(response.data.Value));

}


export const drawingTestDuck = { ...drawingTestSlice.actions, submitDrawingTest, checkCurve }
export default drawingTestSlice.reducer;
