//@flow
export const parseFileSize = function (text) {
    var muls = {
        k: 1024,
        m: 1048576,
        g: 1073741824
    };
    var match = /^([0-9]+)([kmg]?)$/.exec(String(text).toLowerCase().replace(/[^0-9kmg]/g, ''));
    var size = +match[1];
    var mul = match[2];
    if (muls.hasOwnProperty(mul)) {
        size *= muls[mul];
    }

    return size;
}


export const getFileSizeString = function (size: number) {
    var muls = [
        { size: size, text: "bytes" },
        { size: size/1024 , text: "kB" },
        { size: size/1048576, text: "MB" },
        { size: size/1073741824, text: "GB" }
    ];

    let num = size;
    let text = "bytes"
    for (let index = 0; index < muls.length; index++) {
        const element = muls[index];
        if( index > 0 && element.size < 1){
            num = muls[index-1].size;
            text = muls[index-1].text;
            break;
        }
    }

    return num.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})  + " " + text;

}
