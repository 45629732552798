//@ts-check
import dispatchService from "./utils/DispatchService";

/**
* @type {()=>Promise<import('./teacherFeatures/index')>}}
 */
const assertTeacherComponents = () => {
    const importPromise = import("teacherFeatures").then(features => {

        if (!dispatchService.store.asyncReducers["testcomments"]) {
            dispatchService.store.injectReducer("testcomments", features.testCommentReducer);
        }

        if (!dispatchService.store.asyncReducers["classactivity"]) {
            dispatchService.store.injectReducer("classactivity", features.classActivityReducer);
        }

        if (!dispatchService.store.asyncReducers["evalsummary"]) {
            dispatchService.store.injectReducer("evalsummary", features.evalSummarReducer);
        }

        if (!dispatchService.store.asyncReducers["studentsTable"]) {
            dispatchService.store.injectReducer("studentsTable", features.studentsTableReducer);
        }

        if (!dispatchService.store.asyncReducers["generalNotes"]) {
            dispatchService.store.injectReducer("generalNotes", features.GeneralNotesReducer);
        }

        if (!dispatchService.store.asyncReducers["subscriptions"]) {
            dispatchService.store.injectReducer("subscriptions", features.SubscriptionsReducer);
        }

        if(  !dispatchService.store.asyncReducers["attendance"] ){
            dispatchService.store.injectReducer( "attendance", features.AttendanceReducer  );
        }
        

        return features;


    });

    return importPromise;

}



export default assertTeacherComponents;