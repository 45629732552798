import React from "react";

const TestStatusIcon = (props) => {

    if (!props.status)
        return <span></span>

    return <img className="icon" src={ "/imgs/status/" + props.status + ".svg"} alt={props.status}/>

}



export default TestStatusIcon;