export const FormatTimeDuration = (Duration, t) => {
    const
        duration = Duration.split(':'),
        hour = parseInt(duration[ 0 ]),
        minute = parseInt(duration[ 1 ]),
        second = parseInt(duration[ 2 ]);
    let output = '';
    output += (hour ? hour + ' ' + t.getText('hour') + ', ' : '');
    output += (minute ? minute + ' ' + t.getText('minute') + ', ' : '');
    output += ((!hour && !(minute > 15) && second )? second + ' ' + t.getText('second') : '');

    return output.replace(/, +$/, "");
}


