//@ts-check
import React, { useCallback, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable"

import { useI18n } from "components/lni18n";
import useTypedSelector from 'utils/useTypedSelector';
import { CustomInput } from "reactstrap";
import { useDispatch } from "react-redux";
import { ForumEmailSending, forumService } from "./ForumDuck";

const ForumSubscription = () => {

    const dispatch = useDispatch();
    const [settings, setSettings] = useState({ primary: false, secondary: false});
    const [modal, setModal] = useState(false);
    const { languageService: t } = useI18n();
    const { SubscribeTo, Id } = useTypedSelector(state => state.forum.currentForum);
    const { user } = useTypedSelector(state => state.login);

    const toggleState = (type) => {

        let newData = { ...settings };
        newData[type] = !settings[type];

        setSettings(old => newData);

        saveSettings(newData);
    }

     const openModal = useCallback( ()=>{

        const data = { 
                primary: SubscribeTo === ForumEmailSending.both || SubscribeTo === ForumEmailSending.onlyPrimary,
                secondary: SubscribeTo === ForumEmailSending.both || SubscribeTo === ForumEmailSending.onlySecondary};
        setSettings(data);

        toggleModal();

    }, [setSettings, SubscribeTo]);

    const toggleModal = () => {
        setModal(s => !s);
    }

    const saveSettings = async (newData)=>{
        
        let state = -1;
        if( newData.primary  ){
            if( newData.secondary){
                state = ForumEmailSending.both;
            }
            else{
                state = ForumEmailSending.onlyPrimary;
            }
        }
        else{
            if( newData.secondary){
                state = ForumEmailSending.onlySecondary;
            }
        }

        await dispatch(forumService.setSubscription( Id, state ));

    }


    return <div className="d-flex justify-content-end align-items-center">
        {SubscribeTo < 0 ? <span className="text-danger">{t.getText("no.subscriptions")}</span> : <span className="text-primary">{t.getText("subscriber")}</span>}
        <button onClick={openModal} className="ml-4 btn btn-small btn-primary" >{t.getText("change")}</button>

        <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>

            <Modal role="dialog" isOpen={modal} toggle={toggleModal} className="change-password-modal" backdrop='static'>

                <ModalHeader toggle={toggleModal}>{t.getText('email')}</ModalHeader>
                <ModalBody tabIndex={-1}>

                    <div>
                        <div className="mb-3 preserve-white">{t.getText("forum.subscription")}</div>
                        <CustomInput type="switch" id="primary" checked={settings.primary} onChange={() => toggleState("primary")} label={user.Email} />
                        {user.Email2 && <div>
                            <CustomInput type="switch" id="secondary" checked={settings.secondary} onChange={() => toggleState("secondary")} label={user.Email2} />
                        </div>}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-inverse btn-small" type="button" onClick={() => toggleModal()}> {t.getText('close')} </button>
                </ModalFooter>
            </Modal>
        </Draggable>

    </div>


}


export default ForumSubscription;