//@ts-check

import React from "react";

import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import ResultsGraphic from "components/ResultsGraphic/ResultsGraphic"
import Link from "redux-first-router-link";
import classes from './dashboard.module.scss';

/**
 * ProgressWidget
 * @module features/classes/Dashboard/ProgressWidget
 * @return an LnCard with Class Progress.
 * @param {{
    data: import("../Classes").StudentClassDtoForStudent
}} props
 */
const ResultsWidget = (props) => {

    const { languageService: t } = useI18n();
    const studentData = props.data;


    return (
        <div className={['card-box big-card-box card ln-card mr-5 mb-5', classes['widget-card'], classes['my-progress']].join(' ')}>
            <div className={["card-header d-flex justify-content-between", classes['card-header']].join(' ')}>
                <span>
                    <LnIcon className="header-icon" name="progress-icon" />
                    {t.getText('results')}
                </span>

                <span>
                    <Link id="link_CLASS_PROGRESS" to={{
                        type: 'CLASS_PROGRESS',
                        payload: { classid: props.data.StudentClass.Id , type: 'tests'}
                    }} className="card-header-link">
                        {t.getText('view')}
                    </Link>

                </span>
            </div>
            <div className="card-body pt-3">

                <ResultsGraphic classData={props.data}
                    listingStyle={classes['doughnut-listing']}
                    chartStyle={classes['myprogress-chart']}
                    showLectures={true}
                    showStatusTexts={true} />

                <div className="mt-1 ml-5 mb-3">
                    {studentData.NewCommentedTestResultCount && studentData.NewCommentedTestResultCount > 0 ? <>
                        <Link to={{
                            type: 'CLASS_PROGRESS',
                            payload: { classid: props.data.StudentClass.Id,type: 'tests' }
                        }} >
                            <span className="round-badge mr-3">{studentData.NewCommentedTestResultCount}</span> {t.getText('new.test.comments')}
                        </Link>
                    </>
                        :
                        <span></span>
                    }
                </div>

            </div>
        </div>
    )
}

export default ResultsWidget;