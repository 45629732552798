//@ts-check
import React from "react";
import { DropdownItem, DropdownMenu } from 'reactstrap';
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import { useI18n } from "components/lni18n";
import classes from './layout.module.scss';
import { LoginDuck } from "features/login/LoginDuck";
import LnDropDownMenu from "components/LnDropDownMenu";
import UserImg from "components/UserImg";
import systemInfoService from "utils/SystemInfoService";
import { useStore } from "react-redux";
import {ClientMode} from "features/classes/ClassesDuck"; 


const UserMenu = (props) => {

    const { languageService: t } = useI18n();

    const user = useTypedSelector(state => state.login.user);
    const dispatch = useDispatch();
    const store = useStore();
    const userImage = useTypedSelector(state => state.login.userAvatar);
    const currentClass = useTypedSelector(state => state.classData.currentClass);

    

    // get data for the user menu. Consider the settings at user object

    let items = [];
if( user.SessionLocked)
{
    items = getSchoolItems(dispatch,  t, store);
}
else{
    items = getItems(dispatch, user, t, currentClass, store);
}
     


    let index = 0;
    const userMenuItems = items.filter(i => i.visible).map(i => {
        if (i.type === "cmd") {
            return (<DropdownItem key={index++} onClick={i.action} tag="div">
                <div className="px-4 pt-1 pb-2" >{i.text}</div>
            </DropdownItem>)

        } else {
            return (<DropdownItem key={index++} disabled tag="div">
                <hr />
            </DropdownItem>)
        }
    });


    return <div className={classes["user-info"]}>
        <LnDropDownMenu
            caret={false}
            toggleComponent={
                <>
                    <i className={classes["user-img"]}>
                        {" "}
                        <UserImg src={userImage} alt={t.getText('user')} />
                    </i>{" "}
                    <span className={[classes.caret, "white-caret"].join(" ")} />
                </>
            }
            toogleClassName={classes.dropDownItem + " btn btn-minimal pointer"}
            toogleTagName="div"
        >
            <DropdownMenu className={classes.dropDownMenu} right container="body">
                {userMenuItems}
            </DropdownMenu>
        </LnDropDownMenu>
    </div>
}

function getItems(dispatch, user, t, currentClass, store){

 
    const courseType =  currentClass?.DashBoardData?.StudentData?.CourseType;

    return [
        {
            visible: user && (user.IsRoot || user.CanSwitchUser || user.ShowSingleMail || (currentClass && currentClass.AllowSingleMailForStudents)),
            type: "cmd",
            text: t.getText("personal.email"),
            action: () => dispatch({ type: "GENERAL_SINGLE_MAIL", payload: { listtype: 'draft' } })
        },
        {
            visible: true,
            type: "cmd",
            text: t.getText("menu.tools.personalsettings"),
            action: () => dispatch({ type: "GENERAL_PERSONAL_SETTINGS", payload: { userid: user.Id, type: "personal" } })
        },
        {
            visible: user && !!user.CurrentClass,
            type: "cmd",
            text: t.getText("my_progress"),
            action: () => dispatch({ type: "CLASS_PROGRESS", payload: { classid: currentClass.Id, type: 'tests' } })
        },
        {
            visible: courseType === ClientMode.student,
            type: "cmd",
            text: t.getText("docbox.menu"),
            action: () => dispatch({ type: "GENERAL_DOCBOX", payload: { type: "Start" } })
        },


        {
            visible: true,
            type: "cmd",
            text: t.getText("course.summary"),
            action: () => dispatch({ type: "GENERAL_COURSE_OVERVIEW" })
        },

        {
            visible: true,
            type: "separator"
        },
        {
            visible: user && user.CanSwitchUser && !user.HasShadow,
            type: "cmd",
            text: t.getText("switch.user"),
            action: () => dispatch({ type: "GENERAL_SWITCH_USER" })
        },
        {
            visible: user && user.HasShadow,
            type: "cmd",
            text: t.getText("shadow.user.switch.back"),
            action: () => dispatch((LoginDuck.switchBackUser()))
        },
        {
            visible: true,
            type: "cmd",
            text: t.getText("print"),
            action: () => window.print()
        },
        {
            visible: true,
            type: "cmd",
            text: t.getText("personal.data.policy"),
            action: () => dispatch({ type: "GENERAL_PERSONAL_DATA_POLICY", payload: { userid: user.Id } })
        },
        {
            visible: true,
            type: "cmd",
            text: t.getText("menu.systeminfo"),
            action: () => systemInfoService.viewInfo(store)
        },
        {
            visible: true,
            type: "separator"
        },
        {
            visible: true,
            type: "cmd",
            text: t.getText("logoff"),
            action: () => dispatch(LoginDuck.logoutAtServer())
        }
    ];

}

function getSchoolItems(dispatch,  t, store){

    return [
        {
            visible: true,
            type: "cmd",
            text: t.getText("print"),
            action: () => window.print()
        },
        {
            visible: true,
            type: "cmd",
            text: t.getText("menu.systeminfo"),
            action: () => systemInfoService.viewInfo(store)
        },
        {
            visible: true,
            type: "separator"
        },
        {
            visible: true,
            type: "cmd",
            text: t.getText("logoff"),
            action: () => dispatch(LoginDuck.logoutAtServer())
        }
    ];

}

export default UserMenu;