//@ts-check
import React from "react";
import { Alert } from 'components/OnlineModal';
import TestStatusIcon from "components/TestStatusIcon";
import dayjs from 'dayjs';


/**
 * 
 * @param {any[]} events 
 * @param {any} languageService 
 */
const showHistoryDialog = (events, languageService) => {

    const t = languageService;

    const dialogText = <table className="table table-condensed table-striped border-top-0 ">
        <thead>
            <tr>
                <th>{t.getText("date")}</th>
                <th>{t.getText("comment")}</th>
                <th>{t.getText("teacher")}</th>
                <th>&nbsp;</th>
            </tr>
        </thead>
        <tbody>
            {events.map(e =>  (
                 <tr key={e.When}>
                    <td>
                        { dayjs( e.When).format("LLL")}
                    </td>
                    <td dangerouslySetInnerHTML={{ __html: e.Action }}></td>
                    <td>{e.Teacher && e.Teacher.FullName }</td>
                    <td>
                        <TestStatusIcon status={e.Status} />
                    </td>
                </tr>
            ))}
        </tbody>
    </table>;

    Alert({
        class: "modal-lg",
        backdrop: 'static',
        title: t.getText('result.history'),
        message: dialogText,
        button: {
            className: 'btn btn-primary',
            text: t.getText("ok"),
            type: "submit"
        }
    })

}

export { showHistoryDialog };