//@ts-check
import React,{useState} from "react";
import WaitRipple from "components/WaitRipple"
let Subscriptions = null;

const SubscriptionsStub = (props) => {

    const [ready, setReady ] = useState(false);

    import("teacherFeatures").then(features => {
        Subscriptions = features.Subscriptions
        setReady(true);
    });

    if (!ready) {
        return <WaitRipple />;
    }
    else {
        return <Subscriptions />
    }
}

export default SubscriptionsStub;
