/* eslint-disable */
//@ts-check
import React, { useState, useCallback } from "react";
import { useDispatch, useStore } from "react-redux";

import { endPoint } from "AppConstants";
import { useI18n } from "components/lni18n";
import FileIcon from "components/FileIcons/FileIcon";
import LnIcon from "components/LnIcon";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";

import PermissionsDialog from "features/Permissions/PermissionsDialog";

import ActionDropDownFileBuilder from "./ActionDropDownFileBuilder";
import ActionDropDownFolderBuilder from "./ActionDropDownFolderBuilder";
import FileDialog from "./FileDialog";
import { docBoxDuck } from "./DocBoxDuck";
import { ModalConstants, Confirm } from "components/OnlineModal";

import classes from './DocBox.module.scss';


const DocBoxRoot = ({ type }) => {

    const dispatch = useDispatch();
    const store = useStore();
    const getState = store.getState;
    const docbox = useTypedSelector(state => state.docbox);
    const { languageService: t } = useI18n();

    /**
     * @type {import('./DocBox').DocBoxRootFolderDto}
     */
    const root = docbox[type];

    const [editFileData, setEditFileData] = useState({
        open: false,
        file: null,
        folder: null,
        origin: ''
    });

    const [showPermissionsData, setShowPermissionsData] = useState({
        open: false,
        objectId: null,
        type: 0,
        setForallSubitems: false,
        setFlags: ['read'],
        onClose: () => { }
    });
    const [urlCreator] = useState({
        SetPermissionUrl: () => endPoint.DOCBOX_SET_PERMISSIONS_URL,
        DeletePermissionUrl: () => endPoint.DOCBOX_DELETE_PERMISSIONS_URL,
        GetstartUrl: endPoint.GET_DOCBOX_PERMISSIONS_URL,
        GetSearchUrl: (t, type, id) => endPoint.GET_DOCBOX_USER_AND_GROUP_SEARCH_URL(id, type, 0, t)
    });

    const { folder: currentFolder, motherFolder, path: breadCrumbs } = docBoxDuck.useCurrentFolder(root);

    let motherFolderId = null;
    if (motherFolder) {
        motherFolderId = motherFolder.Id;
    }

    const handleClosePermissions = useCallback((result) => {
        dispatch(docBoxDuck.setpermission(result));
        setShowPermissionsData({
            open: false,
            type: 0,
            objectId: null,
            setForallSubitems: false,
            setFlags: ['read'],
            onClose: () => { }
        });
    }, []);

    const handleEmptyTrash = async () => {
        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("empty.trash"),
            message: (<div>{t.getText("empty.trash.confirm")}</div>),
            languageService: t,
            okOnEnter: true,
            type: "yes/no"
        });

        if (result === ModalConstants.Yes) {
            docBoxDuck.emptyTrash(dispatch, getState, currentFolder.Id, "Trash");
        }
    }

    /**
     * @param {string | number} folder,
     * @param {import('features/General/DocBox/DocBox').DocBoxFileDto} file?,
     */
    const openAddFile = (folder, file, origin) => {
        setEditFileData({
            open: true,
            file: file || null,
            folder: folder,
            origin: origin
        });
    }

    /**
    * @param {string | number} id,
    * @param {number} type,
    * @param {boolean} subItems,
    */
    const openPermissions = (id, type, subItems) => {
        setShowPermissionsData({
            open: true,
            objectId: id,
            type: type,
            setForallSubitems: subItems,
            setFlags: ['read'],
            onClose: handleClosePermissions
        });
    }

    const setFolder = (folderId) => {
        dispatch(docBoxDuck.setCurrentFolder({ id: folderId, type: type }));
    }

    const moveAction = async (file, targetFolder) => {
        await docBoxDuck.moveFile(dispatch, getState, file.Id, targetFolder.Id, type);
    }

    if (root == null) {
        return <WaitRipple />
    }

    const isRoot = breadCrumbs.length < 2;

    return (<>
        <div className={["card-header ln-nostyle d-flex justify-content-between", classes.noSidePadding].join(' ')}>
            <h3>
                {breadCrumbs.map(f => {
                    {/*  bread crumbs */ }
                    if (f.Id === root.CurrentFolder) {
                        return <small key={f.Id}>{f.Name}</small>
                    }
                    else {
                        return <small key={f.Id}><button className="btn btn-link" onClick={() => setFolder(f.Id)} >{f.Name}</button> <LnIcon className="icon-small" name="right-arrow" /> </small>
                    }
                })}
            </h3>
            {type !== "Trash" ?
                <ActionDropDownFolderBuilder
                    caret={true}
                    classNameMenu="btn-inverse"
                    currentFolder={currentFolder}
                    currentParentFolder={motherFolder}
                    direction="down"
                    folder={currentFolder}
                    isRoot={isRoot}
                    openAddFile={openAddFile}
                    openPermissions={openPermissions}
                    title={t.getText('actions')}
                    type={type}
                    t={t}
                />
                : <button onClick={handleEmptyTrash} className="btn btn-inverse btn-small">{t.getText("empty.trash")}</button>
            }

        </div>
        <div className={["card-body pt-5", classes.docBox, classes.noSidePadding].join(' ')}>
            <table className="table ln-table-responsive table-hover">
                <thead>
                    <tr role="row">
                        <th role="columnheader" className="th-clean">
                            {t.getText('name')}
                        </th>
                        {type === "Shared" && <th role="columnheader" className="th-clean">
                            {t.getText('owner')}
                        </th>}
                        <th role="columnheader" className="th-clean">
                            {t.getText('description')}
                        </th>
                        <th role="columnheader" className="d-none d-md-table-cell th-clean">
                            {t.getText('date.created')}
                        </th>
                        <th role="columnheader" className="th-clean">
                            {t.getText('size')}
                        </th>
                        <th role="columnheader" className="th-clean text-right">
                            {t.getText('actions')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {(currentFolder.Children && (currentFolder.Children.length > 0)) ?
                        currentFolder.Children.map(f => { // Folders
                            return (
                                <tr role="row" key={f.Id}>
                                    <td>
                                        <span className="d-sm-flex d-md-block justify-content-between">
                                            <button type="button" className={["btn btn-link text-left d-flex", classes.btnMargin].join(' ')} onClick={() => setFolder(f.Id)} ><LnIcon className={["mr-4", classes.fileIcon].join(' ')} name="dox-icon" />
                                                <span>
                                                    {f.Name}
                                                </span>
                                            </button>

                                        </span>
                                        <span className={[classes.smallIcons].join(' ')}>
                                            {f.Shared && <button className={['btn btn-link mr-2', classes.smallShow].join(' ')} onClick={() => openPermissions(f.Id, 31, true)} ><LnIcon className="pointer" name="group" /></button>}

                                            <ActionDropDownFolderBuilder
                                                classNameMenu="btn-link"
                                                currentFolder={currentFolder}
                                                currentParentFolder={motherFolder}
                                                direction="left"
                                                isRoot={false}
                                                folder={f}
                                                openAddFile={openAddFile}
                                                openPermissions={openPermissions}
                                                type={type}
                                                t={t}
                                            />
                                        </span>
                                    </td>
                                    {type === "Shared" && <td>
                                        {f.Owner.Name}
                                    </td>}
                                    <td className="d-none d-md-flex justify-content-between flex-row-reverse">
                                        {f.Shared && <button className="btn btn-link" onClick={() => openPermissions(f.Id, 31, true)} ><LnIcon className={["mr-3 d-none d-md-inline-block pointer"].join(' ')} name="group" /></button>}
                                    </td>
                                    <td className={["d-none d-md-table-cell", classes.smallHide].join(' ')}>
                                        &nbsp;

                                    </td>
                                    <td className={classes.smallHide}>
                                        {f.Size}
                                    </td>
                                    <td className={["text-right", classes.smallHide].join(' ')}>
                                        <ActionDropDownFolderBuilder
                                            currentFolder={currentFolder}
                                            currentParentFolder={motherFolder}
                                            classNameMenu="btn-link"
                                            direction="left"
                                            folder={f}
                                            isRoot={false}
                                            openAddFile={openAddFile}
                                            openPermissions={openPermissions}
                                            type={type}
                                            t={t}
                                        />
                                    </td>
                                </tr>
                            )
                        }) : null}
                    {(currentFolder.Files && (currentFolder.Files.length > 0)) ?
                        currentFolder.Files.map(f => { // Files
                            return (
                                <tr key={f.Id}>
                                    <td className="pb-sm-5">
                                        <a href={endPoint.API_HOST + f.Url} target="_blank" rel="noopener noreferrer">
                                            <FileIcon className={["mr-4", classes.smallHide, classes.fileIcon].join(' ')} size="48px" name={(f.Name || '').split('.').pop()} /></a>
                                        <a className={classes.btnMargin} href={endPoint.API_HOST + f.Url} target="_blank" rel="noopener noreferrer">{f.Name}</a>
                                        <span className={[classes.smallIcons].join(' ')}>
                                            {f.Shared && <button className="mr-2 btn btn-link" onClick={() => openPermissions(f.FileId, 30, false)} ><LnIcon className={["d-inline-block pointer"].join(' ')} name="group" /></button>}
                                            <ActionDropDownFileBuilder
                                                classNameMenu="btn-link"
                                                currentFolder={currentFolder}
                                                currentParentFolder={motherFolder}
                                                file={f}
                                                motherFolderId={motherFolderId}
                                                openAddFile={openAddFile}
                                                openPermissions={openPermissions}
                                                root={root}
                                                type={type}
                                                t={t}
                                                moveFileAction={moveAction}
                                            />

                                        </span>
                                    </td>
                                    {type === "Shared" && <td>
                                        {f.Owner.Name}
                                    </td>}
                                    <td>
                                        <div className="d-flex justify-content-between">
                                            <div className={classes.additionalTxt}>
                                                {f.Description}
                                            </div>
                                            {f.Shared && <button className="btn btn-link" onClick={() => openPermissions(f.FileId, 30, false)} ><LnIcon className={["mr-3 d-none d-md-inline-block pointer"].join(' ')} name="group" /></button>}
                                        </div>

                                    </td>
                                    <td className="d-none d-md-table-cell">
                                        {t.getShortDateString(f.Created)}
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-between">
                                            <div className={[classes.smallShow, classes.additionalTxt].join(' ')}>
                                                {t.getShortDateString(f.Created)}
                                            </div>
                                            <div className={classes.additionalTxt}>
                                                {f.Size}

                                            </div>
                                        </div>
                                    </td>
                                    <td className={["text-right", classes.smallHide].join(' ')}>
                                        <ActionDropDownFileBuilder
                                            currentFolder={currentFolder}
                                            currentParentFolder={motherFolder}
                                            file={f}
                                            motherFolderId={motherFolderId}
                                            openAddFile={openAddFile}
                                            openPermissions={openPermissions}
                                            root={root}
                                            type={type}
                                            t={t}
                                            moveFileAction={moveAction}
                                        />
                                    </td>
                                </tr>
                            )
                        }) : null
                    }
                </tbody>
            </table>
        </div>

        <FileDialog data={editFileData} type={type} />

        <PermissionsDialog
            showData={showPermissionsData}
            urlCreator={urlCreator} />
    </>
    )

}

export default DocBoxRoot;