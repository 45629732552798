// @ts-check
import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";
import Members from "./Members";
import ScrollContent from "components/ScrollContent";


const ClassMembers = (props) => {


    const classData = useTypedSelector(state => state.classData);
    

    if (!classData || !classData.currentClass || !classData.currentClass.Info) {
        return < WaitRipple />
    }

    const { Students, Teachers } = classData.currentClass.Info;

    return (
        <ScrollContent scrollname="class-members">
            <div className="card-box big-card-box">
                <Members classId={classData.currentClass.Id} students={Students} teachers={Teachers} currentUserIsTeacher={classData.currentClass.CurrentUserIsTeacher}  name={classData.currentClass.Name} />
            </div>
        </ScrollContent>
    )
}

export default ClassMembers;
