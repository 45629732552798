//@ts-check
import React from "react";
import { DropdownItem, DropdownMenu } from "reactstrap";

import { Confirm, ModalConstants, Input } from "components/OnlineModal";
import LnDropDownMenu from "components/LnDropDownMenu";
import LnIcon from "components/LnIcon";

import { docBoxDuck } from "./DocBoxDuck";

import classes from './DocBox.module.scss';
import { useDispatch, useStore } from "react-redux";

/**
 * ActionDropDownFolderBuilder
 * @return a dropdown menu.
 * @param {{
    caret?: boolean,
    classNameMenu?: string,
    classNameItem?: string,
    currentFolder?: import('features/General/DocBox/DocBox').DocBoxFolderDto,
    currentParentFolder?: import('features/General/DocBox/DocBox').DocBoxFolderDto,
    direction?: 'left' | 'down',
    folder: import('features/General/DocBox/DocBox').DocBoxFolderDto,
    isRoot: boolean,
    openAddFile: any,
    openPermissions: any,
    title?: string,
    type: 'Start' | 'Shared' | 'Trash' | 'SharedTrash',
    t: any
}} props
 */
const ActionDropDownFolderBuilder = ({ caret, classNameMenu, classNameItem, currentFolder, 
    currentParentFolder, direction, folder, isRoot, openAddFile, openPermissions, title, type, t }) => {

    const dispatch = useDispatch();
    const store = useStore();
    const getState = store.getState;

    const permission = folder.Permission;

    const dropDownItems = [];

    const handleAddFolder = () => {

        const promise = Input({
            closeOnEnter: true,
            fields: [{ name: "folderName", type: "text", label: t.getText("name"), placeholder: "" }],
            languageService: t,
            message: null,
            title: t.getText("folder.create"),
        }).promise;

        promise.then(async (dialogresult) => {
            if (!dialogresult || !dialogresult.folderName) {
                return;
            }
            const data = {
                MotherId: folder.Id,
                Name: dialogresult.folderName
            }
            await docBoxDuck.createFolder(dispatch, getState, data, type);
        })

    }

    const handleDelete = async () => {

        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("delete") + ': ' + folder.Name,
            message: (<div dangerouslySetInnerHTML={{ __html: t.getText('delete_general_confirm') }}></div>),
            languageService: t,
            type: "yes/no",
            okOnEnter: true
        });

        if (result === ModalConstants.Yes) {
            docBoxDuck.deleteFolder(dispatch, getState, folder.Id, type);
        };
    }

    const handleEditFolder = () => {
        const promise = Input({
            closeOnEnter: true,
            fields: [{ name: "folderName", type: "text", label: t.getText("name"), placeholder: "", value: folder.Name }],
            languageService: t,
            message: null,
            title: t.getText("edit"),
        }).promise;

        promise.then(async (dialogresult) => {
            if (!dialogresult || !dialogresult.folderName) {
                return;
            }
            await docBoxDuck.updateFolderName(dispatch, getState, folder.Id, dialogresult.folderName, type);
        })

    }

    const handleTrash = async () => {

        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("delete") + ': ' + folder.Name,
            message: (<div dangerouslySetInnerHTML={{ __html: t.getText('folder.delete.warning') }}></div>),
            languageService: t,
            type: "yes/no",
            okOnEnter: true
        });

        if (result === ModalConstants.Yes) {
            docBoxDuck.trashFolder(dispatch, getState, folder.Id, type);
        };
    }

    const handleUntrash = () => {
        docBoxDuck.untrashFolder(dispatch, getState, folder.Id, type);
    }

    if (type === 'Start') {
        if (permission.create && folder.Id === currentFolder.Id)
            dropDownItems.push(
                permission.create && <DropdownItem key="add.file" tag="div" className={classNameItem || ''} onClick={() => openAddFile(folder, null, 'add')}>
                    <LnIcon className="mr-3 d-none d-md-inline-block pointer icon-small" name="file-icon" />
                    {t.getText('add.file')}
                </DropdownItem>
            );
        if (permission.edit && !isRoot)
            dropDownItems.push(
                <DropdownItem key="edit" tag="div" className={classNameItem || ''} onClick={() => handleEditFolder()}>
                    <LnIcon className="mr-3 d-none d-md-inline-block pointer icon-small" name="edit-icon" />
                    {t.getText('edit')}
                </DropdownItem>
            );
        if (permission.create && folder.Id === currentFolder.Id)
            dropDownItems.push(
                <DropdownItem key="folder.create" tag="div" className={classNameItem || ''} onClick={() => handleAddFolder()}>
                    <LnIcon className="mr-3 d-none d-md-inline-block pointer icon-small" name="dox-icon" />
                    {t.getText('folder.create')}
                </DropdownItem>
            );
        if (permission.admin && openPermissions)
            dropDownItems.push(
                <DropdownItem key="share" tag="div" className={classNameItem || ''} onClick={() => openPermissions(folder.Id, 31, true)}>
                    <LnIcon className="mr-3 d-none d-md-inline-block pointer icon-small" name="group" />
                    {t.getText('share')}
                </DropdownItem>
            );
        if (permission.delete && !isRoot) {
            dropDownItems.push(
                <DropdownItem key="delete" tag="div" onClick={() => handleTrash()}>
                    <LnIcon className="mr-3 d-none d-md-inline-block pointer icon-small" name="delete" />
                    {t.getText('delete')}
                </DropdownItem>
            );
        }

    } else if (type === 'Trash') {
        if (permission.delete) {
            if (currentParentFolder === null) {
                dropDownItems.push(
                    <DropdownItem key="untrash" tag="div" className={classNameItem || ''} onClick={() => handleUntrash()}>
                        <LnIcon className="mr-3 d-none d-md-inline-block pointer icon-small" name="info-icon" />
                        {t.getText('reset')}
                    </DropdownItem>
                );
            }


            dropDownItems.push(
                <DropdownItem key="delete" tag="div" onClick={() => handleDelete()}>
                    <LnIcon className="mr-3 d-none d-md-inline-block pointer icon-small" name="delete" />
                    {t.getText('delete')}
                </DropdownItem>
            );


        }
    }

    return (
        dropDownItems.length ? <LnDropDownMenu
            caret={caret || false}
            className={"btn pointer pl-4  pl-md-n4 " + (classNameMenu || '')}
            direction={direction || "left"}
            toggleComponent={
                <>
                    {title || <LnIcon name="more" className={"more "} />}
                </>
            }
            toogleClassName={classes.dropDownItem + " "}
            toogleTagName="div"
        >
            <DropdownMenu className={classes.dropDownMenu} tag="div">
                {dropDownItems.map((i) => {
                    return i
                })}
            </DropdownMenu>
        </LnDropDownMenu> : null
    )
}

export default ActionDropDownFolderBuilder;