//@ts-check

import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import Link from "redux-first-router-link";
import { useI18n } from "components/lni18n";
import WaitRipple from "components/WaitRipple";
import LnIcon from "components/LnIcon";
import ScrollContent from "components/ScrollContent";
import NotificationItem from 'features/classes/Dashboard/NotificationItem.js';

/**
 * NotificationList
 * @module features/classes/ClassItems/Notifications/NotificationList
 * @return displays the full list of notifications for a Class
 
 */
const NotificationList = () => {

    const { languageService: t } = useI18n();
    const notifications = useTypedSelector(state => state.classData.currentClass.notifications);
    const { Id: classId, CanEditNotifications } = useTypedSelector(state => state.classData.currentClass);

    const notificationTypeCssClass = (type) => {
        const _type = String(type);
        return {
            '1': 'notification-type-system',
            '2': 'notification-type-school-unit',
            '3': 'notification-type-class-unit'
        }[_type] || 'notification-type';
    }

    if (notifications == null) {
        return <WaitRipple />
    }

    return (
        <ScrollContent scrollname="notifications">
            <div className="card-box big-card-box">
                <div className="card-header ln-nostyle d-flex justify-content-between">
                    <h3>
                        <LnIcon className="header-icon" name="bell-icon" />
                        {t.getText('messages')}
                    </h3>
                    {CanEditNotifications === true &&
                        <Link className="btn btn-inverse btn-small" to={{
                            type: 'CLASS_NOTIFICATIONS_EDIT',
                            payload: { classid: classId }
                        }}>{t.getText("edit")}</Link>
                    }
                </div>
                <div className="card-body pt-5 max-col-width">
                    <ul className="list-group list-group-flush">
                        {notifications.filter(n=>n.Visible).map((i) => {
                            return (
                                <li key={i.Id} className="list-group-item">
                                    <NotificationItem classId={classId} canEdit={CanEditNotifications} item={i} nTClass={notificationTypeCssClass} />
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </ScrollContent>)
}

export default NotificationList;