export class BrowserService {

    canFullScreen = false;
    canAudioVolume = false;
    haveLocalStorage = false;
    constructor() {

        
        this.canFullScreen = document.fullscreenEnabled ||
            document.mozFullscreenEnabled ||
            document.webkitFullscreenEnabled ||
            document.msFullscreenEnabled;

        /**
         * @type {HTMLAudioElement}
         */
        const audio = document.createElement("audio");
        const vol = audio.volume;
        audio.addEventListener("volumechange", () => {
            this.canAudioVolume = true;
        });
        audio.volume = 0;

        window.setTimeout(() => {
            if (this.canAudioVolume) {
                audio.volume = vol;
            }
            audio.remove();
        }, 100);

        this.haveLocalStorage = this.storageAvailable('localStorage');

    }

    storageAvailable(type) {
        var storage;
        try {
            storage = window[type];
            var x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch(e) {
            return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                (storage && storage.length !== 0);
        }
    }

    canUseLocalStorage = ()=>{
        return this.haveLocalStorage;
    }

    canSetAudioVolume = () => {
        return this.canAudioVolume;
    }

    canSetFullScreen = () => {
        return this.canFullScreen;
    }

    havePassiveEvents = () => {
        var supportsPassiveOption = false;
        try {
          var opts = Object.defineProperty({}, 'passive', {
            get: function() {
              supportsPassiveOption = true;
              return false;
            }
          });
          window.addEventListener('test', null, opts);
          window.removeEventListener('test', null, opts);
        } catch (e) {
            return false;
        }
        return supportsPassiveOption;
    }
}

const browserService = new BrowserService();
export default browserService;