//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";

/**
 * @type {import('./Permissions').PermissionsState}
 */
const initialState = {
    subjects: null,
};

const PermissionsSlice = createSlice({
    name: 'permissions',
    initialState: initialState,
    reducers: {

        /**
        * @param  {  {payload: import('./Permissions').ObjectPermissionEditorDto}} action
        */
        setSubjects(state, action) {
            state.subjects = action.payload;
        },


        /**
        * @param  {  {payload: { subjectType: 'user' | 'group', data:import('./Permissions').ObjectPermissionRowDto}}} action
        */
        updatePermissionRow(state, action) {
            const { subjectType, data } = action.payload;

            let array = null;
            if (subjectType === "group") {
                array = state.subjects.Groups;
            }
            else {
                array = state.subjects.Users;
            }
            let row = array.find(p => p.UserOrGroupId === data.UserOrGroupId);
            if (row) {
                const index = array.indexOf(row);
                array.splice(index, 1, data);
            }

        },

        /**
        * @param  {  {payload: { subjectType: 'user' | 'group', data:import('./Permissions').ObjectPermissionRowDto}}} action
        */
       addPermissionRow(state, action) {
        const { subjectType, data } = action.payload;

        let array = null;
        if (subjectType === "group") {
            array = state.subjects.Groups;
        }
        else {
            array = state.subjects.Users;
        }
        
        array.splice(0, 0, data);
        

    },

         /**
        * @param  {  {payload: { subjectType: 'user' | 'group', data:import('./Permissions').DeleteBodyDto}}} action
        */
       deletePermissionRow(state, action) {
        const { subjectType, data } = action.payload;

        let array = null;
        if (subjectType === "group") {
            array = state.subjects.Groups;
        }
        else {
            array = state.subjects.Users;
        }
        let row = array.find(p => p.UserOrGroupId === data.UserOrGroupId);
        if (row) {
            const index = array.indexOf(row);
            array.splice(index, 1);
        }

    },
    }
});

const getListData = async (dispatch, url) => {
    dispatch(permissionsDuck.setSubjects(null));

    /**
     * @type {{data:import('./Permissions').ObjectPermissionEditorDto}}
     */
    const response = await instance.get(url);
    if( !response){
        return;
    }

    dispatch(permissionsDuck.setSubjects(response.data));
}

/**
 * @param {Function} dispatch
 * @param {string} url
 * @param {import('./Permissions').SetPermissionDto} postData
 * @param {'user' | 'group'} subjectType
 */
const setPermission = async (dispatch, url, postData, subjectType) => {

    /**
     * @type {{data:import('./Permissions').ObjectPermissionRowDto}}
     */
    const response = await instance.put(url, postData);
    if( !response){
        return;
    }

    dispatch(permissionsDuck.updatePermissionRow({ data: response.data, subjectType: subjectType }));
}

/**
 * @param {Function} dispatch
 * @param {string} url
 * @param {import('./Permissions').SetPermissionDto} postData
 * @param {'user' | 'group'} subjectType
 */
const addPermission = async (dispatch, url, postData, subjectType) => {

    /**
     * @type {{data:import('./Permissions').ObjectPermissionRowDto}}
     */
    const response = await instance.put(url, postData);
    if( !response){
        return;
    }
    
    dispatch(permissionsDuck.addPermissionRow({ data: response.data, subjectType: subjectType }));
}


/**
 * @param {Function} dispatch
 * @param {string} url
 * @param {import('./Permissions').SetPermissionDto} postData
 * @param {'user' | 'group'} subjectType
 */
const deletePermission = async (dispatch, url, postData, subjectType) => {

    /**
     * @type {{data:boolean}}
     */
    await instance.put(url, postData);
    dispatch(permissionsDuck.deletePermissionRow({ data: postData, subjectType: subjectType }));
}

export const permissionsDuck = {
    ...PermissionsSlice.actions,
    getListData, setPermission, addPermission, deletePermission
};
export default PermissionsSlice.reducer;    