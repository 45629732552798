//@ts-check
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { courseDuck } from "features/course/CourseDuck";
import { lectureDuck } from "features/course/part/Lectures/LectureDuck";
import { Card, CardTitle, CardFooter, CardBody, UncontrolledTooltip } from 'reactstrap';

import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import { FormatTimeDuration } from "utils/DateTimeFormat/DateTimeFormat";
import classes from './lecture.module.scss';
import useTypedSelector from "utils/useTypedSelector";



const Lectures = (props) => {
    const dispatch = useDispatch();
    const { languageService: t } = useI18n();
    const part = courseDuck.useCurrentPart();
    const currentTheme = courseDuck.useCurrentTheme();
    const currentClass = useTypedSelector(state => state.classData.currentClass);


    const printLecture = (event, lect) => {

        event.stopPropagation();
        dispatch({
            type: 'CLASS_THEME_PART_LECTURE_PRINT',
            payload: {
                classid: currentClass.Id,
                themeid: currentTheme.Id,
                partid: part.Id,
                lectureid: lect.Id
            }
        });

        return false;

    }

    useEffect(() => {
        // on exit reset lecture data
        return () => dispatch(lectureDuck.setCurrentLecture(null));
    }, [dispatch])


    const openLecture = (lect) => {

        dispatch({
            type: 'CLASS_THEME_PART_LECTURE_PLAY',
            payload: {
                classid: currentClass.Id,
                themeid: currentTheme.Id,
                partid: part.Id,
                lectureid: lect.Id,
                slide: 1
            }
        });

    }

    if (!part.Lectures || part.Lectures.length === 0) {
        return null;
    }

    return (
        <div role="list" className={classes.cardWrapper}>
            {part.Lectures.map(l => {
                return (
                    <Card onClick={() => openLecture(l)} role="listitem" className={[classes.card, ' pointer h-wtd'].join(' ')} key={l.Id} id={'l_' + l.Id}>
                        <CardBody className="p-3">
                            <CardTitle className={classes['card-title']}>
                                <div className={classes.nameDiv}>
                                    {l.Name}
                                </div>

                            </CardTitle>
                            <div className={classes['img-wrapper']}>
                                <img className={classes['lecture-img']} src={l.ImageUrl} alt={l.Name} />
                            </div>



                        </CardBody>

                        <div className={classes.printButtonWrap}>
                            <button className={[classes.printBtn, 'btn btn-link'].join(' ')} type="button" onClick={(e) => printLecture(e, l)} title={t.getText("print")}>
                                <LnIcon className="icon-medium" name="print-icon" />
                            </button>
                        </div>

                        <CardFooter className={classes['card-footer']}>
                            <span><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="slides" />
                                &nbsp;{l.NumOfScreens} <span className="d-none d-sm-inline">{t.getText('lecture.screens')}</span></span>
                            {
                                FormatTimeDuration(l.Duration, t) &&
                                <span className="ml-1"><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="time" /> {FormatTimeDuration(l.Duration, t)}</span>
                            }
                            {(l.Watched) && <LnIcon className="done-icon natural" name="checked" />}
                        </CardFooter>
                        {(l.Watched) && <LnIcon className="done-icon natural" name="checked" />}
                        <UncontrolledTooltip placement="right" target={'l_' + l.Id} trigger={'hover'}
                            delay={{ show: 100, hide: 0 }}>
                            <strong>{l.Name}</strong>
                            <div dangerouslySetInnerHTML={{ __html: l.Description }}></div>
                        </UncontrolledTooltip>
                    </Card>
                )
            })}
        </div>

    )
}


export default Lectures;
