//@ts-check
import React from "react";
import classes from './lecture.module.scss';
import useTypedSelector from "utils/useTypedSelector";
import Segments from "./Segments";
import LnIcon from "components/LnIcon";

const PlayProgress = (props) => {
    let progress = 0;
    const { mediaData, currentScreen } = useTypedSelector(state => state.lecture);

    if (mediaData) {
        if (mediaData.Duration > 0) {
            progress = Math.min( (100 * mediaData.Time / mediaData.Duration), 100 );
        }
    }

    let cuePoints = [];

    if( currentScreen && currentScreen.CuePoints && currentScreen.CuePoints.length ){
        // cp.position is in millisec, duration i sec
        cuePoints = currentScreen.CuePoints.filter(cp=>!cp.Passed).map(cp=> ( cp.position / 10 /currentScreen.DurationInSeconds) );
    }

    return (
        <div className={classes.progress + " progress-bar"} >
            <div className="progress-bar" role="progressbar" aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100} style={{ width: progress + "%" }}></div>
            <Segments className="mt-1" values={currentScreen?.Styles || [] } />
            {cuePoints.map(cp=> 
                <LnIcon name="position" key={cp} className={classes.cuepoint + " filter-red"} style={{left: cp + '%' }} />
             )}
        </div>
    )
}

export default PlayProgress;