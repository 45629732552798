//@ts-check
import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import { useDispatch } from "react-redux";
import ForumView from "../Forum/ForumView";
import ForumThread from "../Forum/ForumThread";
import WaitRipple from "components/WaitRipple";

const ForumForGroup = () => {
    const { type: route, payload: { groupid } } = useTypedSelector(state => state.location);
    const currentForum = useTypedSelector(state => state.forum.currentForum);
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const dispatch = useDispatch();


    const handleThreadClick = (threadId) => {
        dispatch({
            type: "CLASS_SUBGROUP_FORUM_THREAD",
            payload: {
                classid: currentClass.Id,
                groupid: groupid,
                thread: threadId,
                type: "forum",
            }
        });
    }

    const handleLeaveThreadClick = () => {
        dispatch({
            type: "CLASS_SUBGROUP",
            payload: {
                classid: currentClass.Id,
                groupid: groupid,
                type: "forum"
            }
        });
    }


    if( currentForum == null || currentForum.Id !== groupid){
        return <WaitRipple />
    }

    return (
        <>
            {route === "CLASS_SUBGROUP" &&
                <ForumView subGroupId={groupid} onThreadClick={handleThreadClick} />
            }

            {route === "CLASS_SUBGROUP_FORUM_THREAD" &&
                <ForumThread onLeaveThreadClick={handleLeaveThreadClick} />
            }

        </>
    )
}
export default ForumForGroup;