//@ts-check

class DispatchService {
    dispatch = null;
    getState = null;
    store = null;
    SetStore = (store) => {

        this.dispatch = store.dispatch;
        this.getState = store.getState;
        this.store = store;
    };

}

/**
 * @type {{ dispatch: Function, getState:Function, SetStore: Function, store: import('redux').Store  }}
 */
const dispatchService = new DispatchService();
export default dispatchService;