import React, { useEffect, useState } from "react";

const DebouncedButton = ({ as = "button", delay=500, onClick, ...props }) => {
    const [isDisabled, setDisabled] = useState(false);
  

    useEffect(() => {
      if (!isDisabled) {
        // timeout elapsed, nothing to do
        return;
      }
  
      // isDisabled was changed to true, set back to false after `delay`
      const handle = setTimeout(() => {
        setDisabled(false);
      }, delay);
      return () => clearTimeout(handle);
    }, [isDisabled, delay]);
  
    const handleClick = (e) => {
      if (isDisabled) {
        return;
      }
  
      setDisabled(true);
      return onClick(e);
    };
  
    const Component = as;
    return <Component {...props} disabled={ props.disabled } onClick={handleClick} />;
  };

  export default DebouncedButton;