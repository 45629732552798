//@ts-check
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { ErrorMessage } from '@hookform/error-message';
import { useI18n } from "components/lni18n";
import useTypedSelector from "utils/useTypedSelector";
import FileUpload from "components/FileUpload/FileUpload";

import { forumService } from "./ForumDuck";
import classes from './forum.module.scss';
import LnIcon from "components/LnIcon";
import { UncontrolledCollapse } from "reactstrap";

/**
 * ForumForm
 * @module features/classes/ClassItems/Forum/ForumForm
 * @return a forum form.
 * @param {{
    toggleForm: Function,
    fileOptions?: {AcceptFileTypes: string, MaxFileSize: number, videoFileTypes: string, audioFileTypes: string, imageFileTypes:string},
    attachment: import("./Forum").ForumThreadAttachmentDto,
    article: string,
    showFileUpload: boolean,
    postid: number,
    postType: 'thread' | 'answer' | 'reply',
    parentId: number | undefined,
    forumId: string,
    onLeaveThread: Function | undefined,

}} props
 */

const EditForumForm = (props) => {

    const { postType, postid, showFileUpload, attachment, article, fileOptions, toggleForm } = props;
    const { languageService: t } = useI18n();
    const dispatch = useDispatch();

    const { imagePreviews, uploadedFile } = useTypedSelector(state => state.fileUpload);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fileDeleted, setFileDeleted] = useState(false);

    const initialValues = {
        article: article,
    };

    const validationSchema = Yup.object({
        article: Yup.string()
            .required(t.getText('servermessage.empty')),
    });

    /**
    *
    * @param {{key:string, name:string, size:number, type:string}} f
    */
    const handleFileUploadReady = (f) => {
        // setUploadedFile(f);
    }

    const handleSubmitForm = async (values) => {
        setIsSubmitting(true);
        const postData = {
            Article: values.article,
            FileName: uploadedFile.name,
            FileTempName: uploadedFile.key,
            DeleteFile: fileDeleted,
            Id: postid
        }
        try {
            await forumService.updateArticle(postData, postType, dispatch);
            toggleForm();

        } finally {
            setIsSubmitting(false);
        }

    }

    const { register, handleSubmit, errors} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues,
        mode: "onBlur"
    });

    

  
    

    return (

        <div className={"pl-2 d-flex flex-fill"}>

            <form onSubmit={handleSubmit(handleSubmitForm)} className={["ml-2 mb-5", classes['forum-form']].join(' ')}>
                <div>
                    {showFileUpload ? <>

                        <FileUpload className="p-3" imagePreviewOptions={[{ name: "small", maxheight: 100, maxwidth: 100 }]} handleUploadReady={handleFileUploadReady} accept={fileOptions.AcceptFileTypes} languageService={t} maxsize={fileOptions.MaxFileSize}
                            postchildren={(<>
                                <div className="d-flex">
                                    <div className="flex-fill">
                                        <button type="button" className="btn btn-primary btn-small" id="togglerFiletextEdit">
                                            {t.getText("more.information")}
                                        </button>
                                    </div>

                                    <div>

                                        <button className="btn btn-primary btn-small mr-4"
                                            disabled={isSubmitting}
                                            type="submit">{t.getText('save.short')}</button>
                                        <button className="btn btn-inverse btn-small" type="button" onClick={() => toggleForm()}>{t.getText('cancel')} </button>
                                    </div>
                                </div>

                                <div>

                                    <UncontrolledCollapse toggler="#togglerFiletextEdit">
                                        <div className="preserve-white">
                                            <small>{t.getText("forum.attachfile.explain.2", fileOptions.imageFileTypes, fileOptions.audioFileTypes, fileOptions.videoFileTypes)}</small>
                                        </div>
                                    </UncontrolledCollapse>
                                </div>


                            </>)}
                        >


                            <div className="form-group mb-4"
                            >
                                <textarea ref={register}
                                    name="article"
                                    className="form-control"
                                    rows={6}

                                />
                                <ErrorMessage as="div" errors={errors} className="errorMessage" name="article" />

                                {attachment && <div className="my-3 d-flex flex-wrap align-items-center">
                                    <div className="mr-3"><strong>{t.getText("file")}: </strong> {attachment.FileName}</div>
                                    {fileDeleted ? <div>
                                        <LnIcon name="alert" className="icon-medium" /> {t.getText('delete')}
                                        <button className="ml-4 btn btn-inverse btn-primary btn-small" type="button" onClick={() => setFileDeleted(false)}>{t.getText('reset')} </button>
                                    </div>
                                        :
                                        <button className="ml-4 btn btn-inverse btn-danger btn-small" type="button" onClick={() => setFileDeleted(true)}>{t.getText('delete')} </button>
                                    }
                                </div>}

                            </div>

                            {uploadedFile && uploadedFile.name && <div>{uploadedFile.name}</div>}
                            {imagePreviews ? <div>
                                <img src={imagePreviews.small.src} alt={uploadedFile.name} />
                            </div> : null
                            }

                        </FileUpload>
                    </>
                        :
                        <div className="form-group mb-4">
                            <textarea ref={register}
                                name="article"
                                className="form-control"
                                rows={6}

                            />
                            <div className="mt-3 d-flex justify-content-end" >

                                <button className="btn btn-primary btn-small mr-4"
                                    disabled={isSubmitting}
                                    type="submit">{t.getText('save.short')}</button>
                                <button className="btn btn-inverse btn-small" type="button" onClick={() => toggleForm()}>{t.getText('cancel')} </button>
                            </div>
                            <ErrorMessage as="div" errors={errors} className="errorMessage" name="article" />
                        </div>
                    }
                </div>


            </form >

        </div>

    )

}
export default EditForumForm;
