//@ts-check
import React from "react";
import ContentWrapper from "features/layout/ContentWrapper";
import WaitRipple from "components/WaitRipple";

const Root = props => {

    return <>
        
        <ContentWrapper>
        <WaitRipple />
        </ContentWrapper>
    </>
};

export default Root;
