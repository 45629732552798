//@ts-check

import React from "react";

import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import ResultsGraphic from "components/ResultsGraphic/ResultsGraphic"
import Link from "redux-first-router-link";
import classes from './dashboard.module.scss';

/**
 * TeacherResultsWidget
 * @module features/classes/Dashboard/TeacherResultsWidget
 * @return an LnCard with Class Progress.
 * @param {{
    data: import("../Classes").StudentClassDtoForTeacher
}} props
 */
const TeacherResultsWidget = (props) => {

    const { languageService: t } = useI18n();
    const data = props.data;


    return (
        <div className={['card-box big-card-box card ln-card mr-5 mb-5', classes['widget-card'], classes['my-progress']].join(' ')}>
            <div className={["card-header d-flex justify-content-between", classes['card-header']].join(' ')}>
                <span>
                    <LnIcon className="header-icon" name="progress-icon" />
                    {t.getText('results')}
                </span>

                <span>
                    <Link to={{
                        type: 'CLASS_PROGRESS',
                        payload: { classid: data.StudentClass.Id, type: 'tests' }
                    }} className="card-header-link">
                        {t.getText('view')}
                    </Link>

                </span>
            </div>
            <div className="card-body pt-3">

                <ResultsGraphic classData={data}
                    listingStyle={classes['doughnut-listing']}
                    chartStyle={classes['myprogress-chart']}
                    showLectures={true}
                    showStatusTexts={true} />
                    
                    <div className="d-flex mt-1 ml-5 pr-5">
                {(data.NewSubmittedTestResultCount > 0 ) &&
                    <div className="mr-3">
                        <div className="d-flex ">
                            <div>
                                <span className="round-badge mr-3">{data.NewSubmittedTestResultCount}</span>
                            </div>
                            <span>
                                { data.NewSubmittedTestResultCount > 1 ? 
                                    t.getText('dashboard.teacher.newsubmitted.tests', data.NewSubmittedTestResultCount)
                                    :
                                    t.getText('dashboard.teacher.newsubmitted.tests.single')
                                }</span>
                        </div>
                    </div>
                }
                <Link to={{type: 'GENERAL_TEACHER_OVERVIEW'}} className="btn btn-small btn-primary">{t.getText("course.summary")}</Link>
                </div>

            </div>
        </div>
    )
}

export default TeacherResultsWidget;