//@ts-check
import React from "react";
import { useI18n } from "components/lni18n";
import MailMeta from "components/Mail/MailMeta";
import { UncontrolledAlert } from 'reactstrap';
import { endPoint } from "AppConstants";
import FileIcon from "components/FileIcons/FileIcon";
import UserImg from "components/UserImg";
import classes from './singlemail.module.scss';

/**
 * SingleMailDetail
 * @module features/General/SingleMail/SingleMailDetail
 * @return a detail view of a mail.
 * @param {{
    mailData: import('./SingleMail').SingleMailProjectDetailDto,
    handleDelete: Function,
    closeForm: Function,
}} props
 */
const SingleMailDetail = ({ mailData, handleDelete, closeForm }) => {

    const {  languageService: t } = useI18n();

    return (<>
        <div className="row mb-4">
            <div className={[ "col-sm-10 " ].join(' ')}>
                <div className={[ classes[ 'detail-label' ] ].join(' ')}>
                    {t.getText('mail.reciever')}
                </div>
                <div>{mailData.Receiver.FullName} ({mailData.Receiver.Mail})
                <UserImg alt={mailData.Receiver.FullName} className="rounded-circle ml-3 avatar-img" src={endPoint.GET_USER_IMAGE_URL(mailData.Receiver.Id)} />
                </div>
            </div>

            {mailData.JobErrors &&  mailData.JobErrors.length > 0 && (
                <UncontrolledAlert color="danger" className="mb-4">
                    <strong>{t.getText("mail.sending.error")}</strong>
                    {mailData.JobErrors.map( e=> (
                        <div className="row">
                            <div className="col-sm-4">{e.MailAddress}</div>
                            <div className="col-sm-8 preserve-white">{e.Errors}</div> 
                        </div>) )}
                </UncontrolledAlert>
            )}

        </div>
        <div className="row">
            <div className={[ "col-sm-12 mb-4" ].join(' ')}>
                <div className={[ "card p-4" ].join(' ')}>
                    <h3>
                        {mailData.MailSubject}
                    </h3>
                    <MailMeta mailData={mailData} t={t} />
                    <hr />
                    <div>
                        <p dangerouslySetInnerHTML={{ __html: mailData.MailBody }}></p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className={[ "col-sm-12 mb-4", "d-flex flex-column" ].join(' ')}>
                {mailData.Attachments.map(f => {
                    return (
                        <a key={f.Id} className="mb-4" href={endPoint.API_HOST + f.Url}>
                            <FileIcon className={[ classes[ 'file-icon' ] ].join(' ')} size="512px" name={(f.Name || '').split('.').pop()} />
                            <span>
                                {f.Name}

                            </span>
                        </a>
                    )

                })}
            </div>
        </div>
        <div className={[ "pb-4 pt-4 border-top", classes[ 'sticky-footer' ] ].join(' ')}>
            <div className="mb-4 d-flex justify-content-between">
                <button className="btn btn-primary btn-small"
                    onClick={() => closeForm()}
                    type="button">{t.getText('close')}
                </button>
                <button className="btn btn-inverse btn-danger btn-small ml-5" type="button" onClick={() => handleDelete(mailData.Id)}>{t.getText('delete')} </button>
            </div>
            <div className="mb-4 d-flex justify-content-start">
                {mailData.Queued && mailData.TimeToLive ? <small><strong>{t.getText('mail.autodelete', [ t.getShortDateTimeString(mailData.TimeToLive) ])}</strong></small> : null}
            </div>
        </div>
    </>)

}
export default SingleMailDetail;
