import React, { useMemo} from "react";
import * as Yup from "yup";
import { useI18n } from "components/lni18n";
import classes from './login.module.scss';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import { ErrorMessage } from '@hookform/error-message';
import useTypedSelector from "utils/useTypedSelector";

const SendPasswdMail = (props) => {

    const { languageService: t } = useI18n();
    const {loginGuiState} = useTypedSelector(state => state.login );

    const schema = useMemo( ()=> Yup.object().shape({
        Identifier: Yup.string()
            .required(t.getText("required_field")),
    }), [t]);
    
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues:{"Identifier": loginGuiState.Identifier}
    });

    const onSubmit = data => {
        props.handleSendPasswordMail(data);
    };

    return (

        <form className={classes.loginForm} onSubmit={handleSubmit(onSubmit)} >
            <div className="form-group">

                <input autoComplete="username" ref={register({ required: true  })} type="text"  name="Identifier" placeholder={t.getText("mail")} className={`input form-control custom-input`} />
                <ErrorMessage as="div" errors={errors} name="Identifier"  className="errorMessage is-danger" />
                
            </div>

            <div className="ml-3 mb-3 mt-3">
                <div>
                    {t.getText('forgot.passwd')}
                </div>
            </div>

            <button type="submit" className="btn btn-primary w-100" title={t.getText("send.passwd")}>{t.getText("send.passwd")}</button>

        </form>

    );
}


export default SendPasswdMail;