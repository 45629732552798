//@ts-check
import React from "react";
import TestStatusIcon from "components/TestStatusIcon";
import LnIcon from "components/LnIcon";
import { showHistoryDialog } from "../test/History";
import { courseDuck } from "features/course/CourseDuck";
import { useI18n } from "components/lni18n";
import testService from "./TestService";
import useTypedSelector from "utils/useTypedSelector";

/**
 * 
 * @param {{ 
    *       languageService: import('features/language/Language').LanguageService,
    *       classId: string,
    *       themeId: string,
    *       partId: string,
    *       hideInstructions?: boolean,
    *       children?: import('react').ReactChildren | import('react').ReactChild} 
    *   }props
    */
const TestTop = (props) => {

    const { languageService: t } = useI18n();
    const currentTest = courseDuck.useCurrentTest();
    const { Events, Name, Status, TeacherComment, SubmitDate }
        = currentTest;

    const { showTestDialog }
        = useTypedSelector(state => state.lecture);

    const closeTest = () => {
        testService.closeTest(props.classId, props.themeId, props.partId);
    }

    return <>
        <div className="row">
            <div className="col d-flex align-items-start">
                <div className="mr-2">
                    <TestStatusIcon status={Status} />
                </div>


                <div className="">
                    <strong>{Name}</strong>
                    <div className="small text-secondary">
                        {SubmitDate && <span >{t.getText("submitted")} {t.getShortDateTimeString(SubmitDate)} </span>}
                    </div>



                </div>


            </div>
            <div className="col d-flex align-items-center flex-row-reverse flex-wrap">



                {!showTestDialog && <>
                    <button className="btn btn-inverse btn-danger mt-3" onClick={closeTest}>{t.getText("close.test")}</button>
                    <button onClick={() => showHistoryDialog(Events, t)} className="btn btn-inverse btn-primary mr-2 mt-3" >
                        <LnIcon name={'time'} className="mr-1" />  {t.getText("result.history")}</button>
                </>
                }
                {props.children}

            </div>

        </div>

        {TeacherComment
            && <div className="ml-2 mr-2">

                <strong>{t.getText("teachers_comment")}</strong>

                <div className="small text-secondary"> {TeacherComment.Teacher.FullName} {t.getShortDateTimeString(TeacherComment.CommentDate)} </div>
                <span dangerouslySetInnerHTML={{ __html: TeacherComment.Comment }}></span>
                <hr />
            </div>
        }

        {!props.hideInstructions && currentTest.Instructions
            && <div className="ml-2 mr-2 mt-3">

                <strong>{t.getText("instructions")}</strong>

                <div className="preserve-white max-col-width"> {t.getText(currentTest.Instructions)} </div>
                <hr />
            </div>
        }

    </>

}

export default TestTop;