//@ts-check

import React, { lazy, Suspense } from "react";
import ScrollContent from 'components/ScrollContent';
import WaitRipple from "components/WaitRipple";


const TimeTableLoader = () => {

   
    const LazyTable = lazy(() => import("./TimeTable"));
    return <Suspense fallback={<ScrollContent scrollname="class-timetable">
        <div className="card-box big-card-box">
            <WaitRipple />
        </div>
    </ScrollContent>}>
        <>
            <LazyTable  />
        </>
    </Suspense>
}


export default TimeTableLoader;
