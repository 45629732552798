//@ts-check
import React from "react";
import classes from './lecture.module.scss';
import useTypedSelector from "utils/useTypedSelector";

const SimplePlayProgress = (props) => {
    let progress = 0;
    const { mediaData } = useTypedSelector(state => state.lecture);

    if (mediaData) {
        if (mediaData.Duration > 0) {
            progress = Math.min( (100 * mediaData.Time / mediaData.Duration), 100 );
        }
    }

    return (
        <div className={classes.simple_progress + " progress-bar"} >
            <div className="progress-bar" role="progressbar" aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100} style={{ width: progress + "%" }}></div>
        </div>
    )
}

export default SimplePlayProgress;