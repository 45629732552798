/* eslint-disable */
//@ts-check
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { endPoint } from "AppConstants";
import { useI18n } from "components/lni18n";
import FileIcon from "components/FileIcons/FileIcon";
import LnIcon from "components/LnIcon";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";

import ActionDropDownFileBuilder from "./ActionDropDownFileBuilder";
import ActionDropDownFolderBuilder from "./ActionDropDownFolderBuilder";
import FileDialog from "./FileDialog";
import { docBoxDuck } from "./DocBoxDuck";

import classes from './DocBox.module.scss';
import ScrollContent from "components/ScrollContent";
import EditFileDialog from "./EditFileDialog";


const EditClassFiles = () => {

    const dispatch = useDispatch();
    const docbox = useTypedSelector(state => state.docbox);
    const { languageService: t } = useI18n();
    const { currentClass } = useTypedSelector(state => state.classData);

    /**
     * @type {import('./DocBox').DocBoxRootFolderDto}
     */
    const root = docbox.Start;
    const type = "Start";

    const [editFileData, setEditFileData] = useState({
        open: false,
        file: null,
        folder: null,
        origin: ''
    });

 

    const { folder: currentFolder, motherFolder, path: breadCrumbs } = docBoxDuck.useCurrentFolder(root);

    let motherFolderId = null;
    if (motherFolder) {
        motherFolderId = motherFolder.Id;
    }

    /**
     * @param {string | number} folder,
     * @param {import('features/General/DocBox/DocBox').DocBoxFileDto} file?,
     */
    const openAddFile = (folder, file, origin) => {
        setEditFileData({
            open: true,
            file: file || null,
            folder: folder,
            origin: origin
        });
    }

    const setFolder = (folderId) => {
        dispatch(docBoxDuck.setCurrentFolder({ id: folderId, type: type }));
    }

    if (root == null || currentFolder == null || currentFolder == null ) {
        return <WaitRipple />
    }

    const isRoot = !breadCrumbs || breadCrumbs.length < 2;

    const goBack = () => {
        dispatch({ type: "CLASS_FILES", payload: { classid: currentClass.Id } });
    }

    const moveAction = async (file, targetFolder)=>{
        await docBoxDuck.moveClassFile(dispatch, currentClass.Id, file.Id, targetFolder.Id);
    }

    return (

        <ScrollContent scrollname="docbox">
            <div className="card-box big-card-box">
                <div className={["card-header ln-nostyle d-flex justify-content-between", classes.noSidePadding].join(' ')}>
                <h3>
                    <LnIcon className="header-icon" name="dox-icon" />
                        { breadCrumbs.map(f => {
                            {/*  bread crumbs */ }
                            if (f.Id === root.CurrentFolder) {
                                return <span key={f.Id}>{f.Name}</span>
                            }
                            else {
                                return <span key={f.Id}><button className="btn btn-link" onClick={() => setFolder(f.Id)} >{f.Name}</button> <LnIcon className="icon-small" name="right-arrow" /> </span>
                            }
                        })}
                    </h3>

                    <div className={["text-right", classes.headerBtnWrapper].join(' ')}>
                        <button id="backfromEditClassFiles" type="button" className="btn btn-inverse btn-small ml-4" onClick={() => goBack()}>{t.getText('back')}</button>
                    </div>
                </div>


                <div className={["card-header ln-nostyle d-flex justify-content-end", classes.noSidePadding].join(' ')}>
                  
                    <ActionDropDownFolderBuilder
                        caret={true}
                        classNameMenu="btn-inverse"
                        currentFolder={currentFolder}
                        currentParentFolder={motherFolder}
                        direction="down"
                        folder={currentFolder}
                        isRoot={isRoot}
                        openAddFile={openAddFile}
                        openPermissions={null}
                        title={t.getText('actions')}
                        type={type}
                        t={t}
                    />

                </div>
                <div className={["card-body pt-5", classes.docBox, classes.noSidePadding].join(' ')}>
                    <table className="table ln-table-responsive table-hover">
                        <thead>
                            <tr role="row">
                                <th role="columnheader" className="th-clean">
                                    {t.getText('name')}
                                </th>
                                <th role="columnheader" className="th-clean">
                                    {t.getText('description')}
                                </th>
                                <th role="columnheader" className="d-none d-md-table-cell th-clean">
                                    {t.getText('date.created')}
                                </th>
                                <th role="columnheader" className="th-clean">
                                    {t.getText('size')}
                                </th>
                                <th role="columnheader" className="th-clean text-right">
                                    {t.getText('actions')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(currentFolder.Children && (currentFolder.Children.length > 0)) ?
                                currentFolder.Children.map(f => { // Folders
                                    return (
                                        <tr role="row" key={f.Id}>
                                            <td>
                                                <span className="d-sm-flex d-md-block justify-content-between">
                                                    <button type="button" className={["btn btn-link text-left d-flex", classes.btnMargin].join(' ')} onClick={() => setFolder(f.Id)} ><LnIcon className={["mr-4", classes.fileIcon].join(' ')} name="dox-icon" />
                                                        <span>
                                                            {f.Name}
                                                        </span>
                                                    </button>

                                                </span>
                                                <span className={[classes.smallIcons].join(' ')}>

                                                    <ActionDropDownFolderBuilder
                                                        classNameMenu="btn-link"
                                                        currentFolder={currentFolder}
                                                        currentParentFolder={motherFolder}
                                                        direction="left"
                                                        isRoot={false}
                                                        folder={f}
                                                        openAddFile={openAddFile}
                                                        openPermissions={null}
                                                        type={type}
                                                        t={t}
                                                    />
                                                </span>
                                            </td>
                                            <td className="d-none d-md-flex">
                                                &nbsp;
                                            </td>
                                            <td className={["d-none d-md-table-cell", classes.smallHide].join(' ')}>
                                                &nbsp;

                                            </td>
                                            <td className={classes.smallHide}>
                                                {f.Size}
                                            </td>
                                            <td className={["text-right", classes.smallHide].join(' ')}>
                                                <ActionDropDownFolderBuilder
                                                    currentFolder={currentFolder}
                                                    currentParentFolder={motherFolder}
                                                    classNameMenu="btn-link"
                                                    direction="left"
                                                    folder={f}
                                                    isRoot={false}
                                                    openAddFile={openAddFile}
                                                    openPermissions={null}
                                                    type={type}
                                                    t={t}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }) : null}
                            {(currentFolder.Files && (currentFolder.Files.length > 0)) ?
                                currentFolder.Files.map(f => { // Files
                                    return (
                                        <tr key={f.Id}>
                                            <td className="pb-sm-5">
                                                <a href={endPoint.API_HOST + f.Url} target="_blank" rel="noopener noreferrer">
                                                    <FileIcon className={["mr-4", classes.smallHide, classes.fileIcon].join(' ')} size="48px" name={(f.Name || '').split('.').pop()} /></a>
                                                <a className={classes.btnMargin} href={endPoint.API_HOST + f.Url} target="_blank" rel="noopener noreferrer">{f.Name}</a>
                                                <span className={[classes.smallIcons].join(' ')}>
                                                    {f.Shared && <button className="mr-2 btn btn-link" onClick={() => openPermissions(f.FileId, 30, false)} ><LnIcon className={["d-inline-block pointer"].join(' ')} name="group" /></button>}
                                                    <ActionDropDownFileBuilder
                                                        classNameMenu="btn-link"
                                                        currentFolder={currentFolder}
                                                        currentParentFolder={motherFolder}
                                                        file={f}
                                                        motherFolderId={motherFolderId}
                                                        openAddFile={openAddFile}
                                                        openPermissions={null}
                                                        root={root}
                                                        type={type}
                                                        t={t}
                                                        moveFileAction={moveAction}
                                                    />

                                                </span>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-between">
                                                    <div className={classes.additionalTxt}>
                                                        {f.Description}
                                                    </div>
                                                </div>

                                            </td>
                                            <td className="d-none d-md-table-cell">
                                                {t.getShortDateString(f.Created)}
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-between">
                                                    <div className={[classes.smallShow, classes.additionalTxt].join(' ')}>
                                                        {t.getShortDateString(f.Created)}
                                                    </div>
                                                    <div className={classes.additionalTxt}>
                                                        {f.Size}

                                                    </div>
                                                </div>
                                            </td>
                                            <td className={["text-right", classes.smallHide].join(' ')}>
                                                <ActionDropDownFileBuilder
                                                    currentFolder={currentFolder}
                                                    currentParentFolder={motherFolder}
                                                    file={f}
                                                    motherFolderId={motherFolderId}
                                                    openAddFile={openAddFile}
                                                    openPermissions={null}
                                                    root={root}
                                                    type={type}
                                                    t={t}
                                                    moveFileAction={moveAction}
                                                />

                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                </div>

                <EditFileDialog data={editFileData} classid={currentClass.Id} />

            </div>
        </ScrollContent>
    )

}

export default EditClassFiles;