//@ts-check
import React from "react";
import { shallowEqual } from "react-redux";
import { courseDuck } from "features/course/CourseDuck";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";
import McTestStudy from "./McTestStudy";
import McTestStandard from "./McTestStandard";

const McTest = (props) => {

    const { currentTest, currentMCQuestion } = useTypedSelector(state => state.mctest, shallowEqual);
    const currentBaseTest = courseDuck.useCurrentTest();

    if (!currentBaseTest || !currentMCQuestion) {
        return <WaitRipple />
    }

    if( currentTest.StudyMode ){
        return <McTestStudy >{props.children}</McTestStudy>
    }
    else{
        return <McTestStandard >{props.children}</McTestStandard>
    }

}
export default McTest;
