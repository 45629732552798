//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import { errorDuck } from "components/ErrorHandler/ErrorHandlerDuck";
import { classFilesDuck } from 'features/classes/ClassItems/ClassFiles/ClassFilesDuck';

const OwnerType = {
    user: 0,
    group: 1
}

const VirusScanStatus = {
    infected: -1,
    unknown: 0,
    inprogress: 1,
    scanned: 2
}

const StudentsGroupsScope =
{
    All: 0,
    ActiveAndRunning: 1,
    Running: 2,
    None: 3
}

const objectType = {
    file: 30,
    folder: 31
}


/**
 * @type import('./DocBox').DocBoxState
 */
const initialState = {
    currentRoot: null,
    Shared: null,
    SharedScope: 0,
    SharedTrash: null,
    Start: null,
    Trash: null,
    filePickerData: null
};


const docBoxSlice = createSlice({
    name: 'DocBox',
    initialState: initialState,
    reducers: {

        /**
         * @param  {  {payload: { setGuiState: boolean, data:import('features/General/DocBox/DocBox').DocBoxRootFolderDto} }} action
         */
        setStartData(state, action) {

            let currentFolder = action.payload.data.Id;
            if (action.payload.setGuiState === false && state.Start) {
                currentFolder = state.Start.CurrentFolder;
            }
            action.payload.data.CurrentFolder = currentFolder;
            state.Start = action.payload.data;
            if (action.payload.setGuiState === true) {
                state.currentRoot = "Start";
            }
        },

        /**
         * @param  {  {payload: { scope: 0|1|2|3, setGuiState: boolean, data:import('features/General/DocBox/DocBox').DocBoxRootFolderDto} }} action
         */
        setSharedData(state, action) {

            let currentFolder = action.payload.data.Id;
            if (action.payload.setGuiState === false && state.Shared) {
                currentFolder = state.Shared.CurrentFolder;
            }

            state.Shared = action.payload.data;
            action.payload.data.CurrentFolder = currentFolder;
            state.SharedScope = action.payload.scope;

            if (action.payload.setGuiState === true) {
                state.Shared.CurrentFolder = action.payload.data.Id;
                state.currentRoot = "Shared";
            }
        },

        /**
         * @param  {  {payload: { data:import('features/General/DocBox/DocBox').DocBoxRootFolderDto} }} action
         */
        setSharedDataForDialog(state, action) {


            state.filePickerData.Shared = action.payload.data;
            state.filePickerData.Shared.CurrentFolder = action.payload.data.Id;
            state.filePickerData.currentRoot = "Shared";

        },

        /**
        * @param  {  {payload: { data:import('features/General/DocBox/DocBox').DocBoxRootFolderDto} }} action
        */
        setStartDataForDialog(state, action) {

            if (!state.filePickerData) {
                state.filePickerData = { Start: null, Shared: null, currentRoot: null };
            }

            state.filePickerData.Start = action.payload.data;
            state.filePickerData.Start.CurrentFolder = action.payload.data.Id;
            state.filePickerData.currentRoot = "Start";

        },

        /**
        * @param  {  {payload: import('features/General/DocBox/DocBox').DocBoxRootFolderDto }} action
        */
        setTrashData(state, action) {
            state.Trash = action.payload;
            state.currentRoot = "Trash";
            state.Trash.CurrentFolder = action.payload.Id;
        },

        /**
         * @param  {  {payload: import('features/General/DocBox/DocBox').DocBoxRootFolderDto }} action
         */
        setSharedTrashData(state, action) {
            state.SharedTrash = action.payload;
            state.currentRoot = "SharedTrash";
            state.SharedTrash.CurrentFolder = action.payload.Id;
        },

        /**
       * @param  {  {payload: {id:string, type:string}}} action
       */
        setCurrentFolder(state, action) {

            /**
            * @type {import('features/General/DocBox/DocBox').DocBoxRootFolderDto}
            */
            let data = state[action.payload.type];
            data.CurrentFolder = action.payload.id;
        },

        /**
       * @param  {  {payload: {id:string}}} action
       */
        setCurrentFolderFileDialog(state, action) {

            /**
            * @type {import('features/General/DocBox/DocBox').DocBoxRootFolderDto}
            */
            let data = state.filePickerData[state.filePickerData.currentRoot];
            data.CurrentFolder = action.payload.id;

        },

        /**
        * @param  {  {payload: { motherId: string, type: string, folder: import('features/General/DocBox/DocBox').DocBoxFolderDto}}} action
        */
        addFolder(state, action) {
            const data = state[action.payload.type];
            /**
             * * @type {import('features/General/DocBox/DocBox').DocBoxFolderDto}
             */

            const { folder: mother } = findFolder(data, action.payload.motherId, null)

            if (mother.Children === null) {
                mother.Children = [];
            }
            mother.Children.push(action.payload.folder);

        },

        /**
         *
        * @param  {  {payload: { motherId: string, type: string, file: import('features/General/DocBox/DocBox').DocBoxFileDto}}} action
        */
        addNewFile(state, action) {
            const data = state[action.payload.type];
            /**
             * @type {{ folder: import('features/General/DocBox/DocBox').DocBoxFolderDto, motherFolder: import('features/General/DocBox/DocBox').DocBoxFolderDto}}
             */

            const { folder: mother } = findFolder(data, action.payload.motherId, null)
            if (mother.Files === null) {
                mother.Files = [];
            }

            mother.Files.push(action.payload.file);


        },

        /**
  *
 * @param  {  {payload: { motherId: string, type: string, file: import('features/General/DocBox/DocBox').DocBoxFileDto}}} action
 */
        updateFile(state, action) {
            const data = state[action.payload.type];
            /**
             * @type {{ folder: import('features/General/DocBox/DocBox').DocBoxFolderDto, motherFolder: import('features/General/DocBox/DocBox').DocBoxFolderDto}}
             */

            const { folder: mother } = findFolder(data, action.payload.motherId, null)
            if (mother.Files === null) {
                return;
            }

            const file = mother.Files.find(f => f.Id === action.payload.file.Id);
            if (file === null) {
                throw Error("File not found. Id: " + action.payload.file.Id)
            }

            const index = mother.Files.indexOf(file);
            mother.Files.splice(index, 1, action.payload.file);

        },

        /**
       * @param  {  {payload: { folderId: string, type: string, fileId: number}} } action
       */
        fileTrashed(state, action) {
            const data = state[action.payload.type];
            /**
             * @type {{ folder: import('features/General/DocBox/DocBox').DocBoxFolderDto, motherFolder: import('features/General/DocBox/DocBox').DocBoxFolderDto}}
             */
            const { folder: mother } = findFolder(data, action.payload.folderId, null);

            if (mother.Files === null) {
                return;
            }

            mother.Files = mother.Files.filter(f => f.Id !== action.payload.fileId);


        },

        /**
       * @param  {  {payload: { motherId: string, folderId: string, type: string}}} action
       */
        folderTrashed(state, action) {
            const data = state[action.payload.type];
            /**
             * @type {{ folder: import('features/General/DocBox/DocBox').DocBoxFolderDto, motherFolder: import('features/General/DocBox/DocBox').DocBoxFolderDto}}
             */

            const { folder: motherFolder } = findFolder(data, action.payload.motherId, null);
            if (motherFolder === null) {
                return;
            }
            motherFolder.Children = motherFolder.Children.filter(f => f.Id !== action.payload.folderId);
        },

        /**
      * @param  {  {payload: {
        * folderId: string,
        * type: string // 'Trash' | 'SharedTrash'
        * }}} action
        */
        folderUnTrashed(state, action) {

            // type is
            const data = state[action.payload.type];
            /**
             * @type {import('features/General/DocBox/DocBox').DocBoxFolderDto}
             */

            data.Children = data.Children.filter(f => f.Id !== action.payload.folderId);
        },

        /**
      * @param  {  {payload: {
        * fileId: number,
        * type: string // 'Trash' | 'SharedTrash'
        * }}} action
        */
        fileUnTrashed(state, action) {

            // type is
            const data = state[action.payload.type];
            /**
             * @type {import('features/General/DocBox/DocBox').DocBoxFolderDto}
             */

            data.Files = data.Files.filter(f => f.Id !== action.payload.fileId);
        },


        /**
        * @param  {  {payload: { type: string }}} action // 'Trash' | 'SharedTrash'
        */
        trashEmptied(state, action) {

            // type is
            const data = state[action.payload.type];
            /**
             * @type {import('features/General/DocBox/DocBox').DocBoxFolderDto}
             */

            data.Files = [];
            data.Children = [];
        },

        /**
      * @param  {  {payload: {
        * fileId: number,
        * type: string // 'Trash' | 'SharedTrash'
        * }}} action
          */
        fileDeleted(state, action) {

            // type is
            const data = state[action.payload.type];
            /**
             * @type {import('features/General/DocBox/DocBox').DocBoxFolderDto}
             */

            data.Files = data.Files.filter(f => f.Id !== action.payload.fileId);
        },

        /**
      * @param  {  {payload: {
      * fileId: string,
      * type: string // 'Trash' | 'SharedTrash'
      * }}} action
      */
        folderDeleted(state, action) {

            // type is
            const data = state[action.payload.type];
            /**
             * @type {import('features/General/DocBox/DocBox').DocBoxFolderDto}
             */

            data.Children = data.Children.filter(f => f.Id !== action.payload.fileId);
        },

        /**
         * @param  {  {payload: { folderId: string,  type: 'Start' | 'Shared', name: string }}} action
         */
        renameFolder(state, action) {
            // type is
            const data = state[action.payload.type];
            let { folder } = findFolder(data, action.payload.folderId, null);
            if (folder != null) {
                folder.Name = action.payload.name;
            }
        },


        /**
      * @param  {  {payload: { type: number, objectId: string, haveSubject: boolean}}} action
      */
        setpermission(state, action) {

            const root = state[state.currentRoot];

            /**
             * @type {{ folder: import('features/General/DocBox/DocBox').DocBoxFolderDto, motherFolder: import('features/General/DocBox/DocBox').DocBoxFolderDto}}
             */

            const { folder: currentFolder } = findFolder(root, root.CurrentFolder, null)

            if (action.payload.type === objectType.file) {
                let file = currentFolder.Files.find(f => f.FileId === action.payload.objectId);
                if (file) {
                    file.Shared = action.payload.haveSubject;
                }
            }
            else {
                if (currentFolder.Id === action.payload.objectId) {
                    setFolderShared(currentFolder, action.payload.haveSubject);
                }
            }
        },
    }
});

const setFolderShared = (fold, state) => {
    fold.Shared = state;
    if (fold.Files) {
        fold.Files.forEach(f => f.Shared = state);
    }
    if (fold.Children) {
        fold.Children.forEach(fo => setFolderShared(fo, state));
    }

}

export const getStartData = async (dispatch, setGuistate) => {
    try {

        if (setGuistate !== false) {
            setGuistate = true;
        }
        /**
         * @type {{data: import('features/General/DocBox/DocBox').DocBoxRootFolderDto}}
         */
        var response = await instance.get(endPoint.DOCBOX_START_URL);
        if (!response) {
            return;
        }
        dispatch(docBoxDuck.setStartData({ setGuiState: setGuistate, data: response.data }));
    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

export const getStartDataForDialog = async (dispatch) => {
    try {


        /**
         * @type {{data: import('features/General/DocBox/DocBox').DocBoxRootFolderDto}}
         */
        var response = await instance.get(endPoint.DOCBOX_START_URL);
        if (!response) {
            return;
        }
        dispatch(docBoxDuck.setStartDataForDialog({ data: response.data }));
    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

export const getSharedDataForDialog = async (dispatch) => {


    try {
        /**
         * @type {{data: import('features/General/DocBox/DocBox').DocBoxRootFolderDto}}
         */
        var response = await instance.get(endPoint.GET_DOCBOX_SHARED_URL(0));
        if (!response) {
            return;
        }
        dispatch(docBoxDuck.setSharedDataForDialog({ data: response.data }));
    } catch (error) { }
}

export const getSharedData = async (dispatch, scope, setGuiState) => {

    if (setGuiState !== false) {
        setGuiState = true;
    }

    try {
        /**
         * @type {{data: import('features/General/DocBox/DocBox').DocBoxRootFolderDto}}
         */
        var response = await instance.get(endPoint.GET_DOCBOX_SHARED_URL(scope || 0));
        if (!response) {
            return;
        }
        dispatch(docBoxDuck.setSharedData({ scope: scope, setGuiState: setGuiState, data: response.data }));
    } catch (error) { }
}

export const getTrashData = async (dispatch) => {
    try {
        /**
         * @type {{data: import('features/General/DocBox/DocBox').DocBoxRootFolderDto}}
         */
        var response = await instance.get(endPoint.DOCBOX_TRASH_URL);
        if (!response) {
            return;
        }
        dispatch(docBoxDuck.setTrashData(response.data));
    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

export const getSharedTrashData = async (dispatch) => {
    try {
        /**
         * @type {{data: import('features/General/DocBox/DocBox').DocBoxRootFolderDto}}
         */
        var response = await instance.get(endPoint.DOCBOX_SHARED_TRASH_URL);
        if (!response) {
            return;
        }
        dispatch(docBoxDuck.setSharedTrashData(response.data));
    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 * @param { Function } dispatch
 * @param { Function } getState
 * @param { import('features/General/DocBox/DocBox').CreateFolderData } postdata
 * @param { string } type
 */
export const createFolder = async (dispatch, getState, postdata, type) => {

    const data = getState().docbox[type];
    if (data === null) {
        return;
    }

    const { folder } = findFolder(data, postdata.MotherId, null);
    if (folder === null) {
        return;
    }

    try {
        /**
         * @type {{data: import('features/General/DocBox/DocBox').DocBoxFolderDto}}
         */
        var response = await instance.post(endPoint.DOCBOX_CREATE_FOLDER_URL, postdata);
        if (!response) {
            return;
        }
        dispatch(docBoxDuck.addFolder({ motherId: postdata.MotherId, type: type, folder: response.data }));

    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 * @param { Function } dispatch
 * @param { Function } getState
 * @param { string } folderId
 * @param { string } type
 */
export const trashFolder = async (dispatch, getState, folderId, type) => {

    const data = getState().docbox[type];
    if (data === null) {
        return;
    }

    const { folder, motherFolder } = findFolder(data, folderId, null);
    if (folder === null) {
        return;
    }

    try {
        /**
         * @type {{data: boolean}}
         */
        var response = await instance.post(endPoint.GET_DOCBOX_TRASH_FOLDER_URL(folderId), null);

        if (response && response.data === true) {
            if (folderId === data.CurrentFolder) {
                dispatch(docBoxDuck.setCurrentFolder({ id: motherFolder.Id, type: type }))
            }
            dispatch(docBoxDuck.folderTrashed({ motherId: motherFolder.Id, folderId: folderId, type: type }));
        }

    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 * @param { Function } dispatch
 * @param { Function } getState
 * @param { string } folderId
 * @param { string } type // 'Trash' | 'SharedTrash'
 */
export const untrashFolder = async (dispatch, getState, folderId, type) => {

    const data = getState().docbox[type];
    if (data === null) {
        return;
    }

    const { folder } = findFolder(data, folderId, null);
    if (folder === null) {
        return;
    }

    try {
        /**
         * @type {{data: string}}
         */
        await instance.post(endPoint.GET_DOCBOX_UNTRASH_FOLDER_URL(folderId), null);


        dispatch(docBoxDuck.folderUnTrashed({ folderId: folderId, type: type }));
        // reload start data
        await getStartData(dispatch, false);


    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 * @param { Function } dispatch
 * @param { Function } getState
 * @param { import('features/General/DocBox/DocBox').UploadFileDto } postdata
 * @param { string } type
 */
export const addFile = async (dispatch, getState, postdata, type) => {

    const data = getState().docbox[type];
    if (data === null) { return; }

    const { folder } = findFolder(data, postdata.Folder, null);
    if (folder === null) { return; }

    try {
        /**
         * @type {{data: import('features/General/DocBox/DocBox').DocBoxFileDto}}
         */
        var response = await instance.post(endPoint.DOCBOX_SAVE_UPDATE_FILE_URL, postdata);
        if (!response) {
            return;
        }
        dispatch(docBoxDuck.addNewFile({ motherId: postdata.Folder, type: type, file: response.data }));

    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

const updateFolderName = async (dispatch, getState, folderId, name, type) => {


    const data = getState().docbox[type];
    if (data === null) { return; }

    const { folder } = findFolder(data, folderId, null);
    if (folder === null) { return; }

    const postdata = { Value: name };

    try {
        await instance.post(endPoint.GET_DOCBOX_RENAME_FOLDER_URL(folderId), postdata);
        dispatch(docBoxDuck.renameFolder({ folderId: folderId, type: type, name: name }));
    } catch (error) {

    }

}

/**
 * @param { Function } dispatch
 * @param { Function } getState
 * @param { import('features/General/DocBox/DocBox').UploadFileDto } postdata
 * @param { string } type
 */
export const saveEditedFile = async (dispatch, getState, postdata, type) => {

    const data = getState().docbox[type];
    if (data === null) { return; }

    const { folder } = findFolder(data, postdata.Folder, null);
    if (folder === null) { return; }

    try {
        /**
         * @type {{data: import('features/General/DocBox/DocBox').DocBoxFileDto}}
         */
        var response = await instance.post(endPoint.DOCBOX_SAVE_UPDATE_FILE_URL, postdata);
        if (!response) {
            return;
        }
        dispatch(docBoxDuck.updateFile({ motherId: postdata.Folder, type: type, file: response.data }));

    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}


/**
 * @param { Function } dispatch
 * @param { Function } getState
 * @param { import('features/General/DocBox/DocBox').UploadFileDto } postdata
 * @param { import('features/General/DocBox/DocBox').DocBoxFileDto } file
 * @param { string } classid
 */
 export const saveEditedClassFile = async (dispatch, getState, postdata, file, classid) => {

    const data = getState().docbox.Start;
    if (data === null) { return; }

    const { folder } = findFolder(data, postdata.Folder, null);
    if (folder === null) { return; }

    /**
     * @type {import('features/General/DocBox/DocBox').ClassFileFormDto}
     */
    
    const classPostData = {
        Description: postdata.Description,
        FileName: postdata.Name,
        FileTempName: postdata.Key
    };

    try {
        /**
         * @type {{data: import('features/General/DocBox/DocBox').ClassFileDto}}
         */
        var response = await instance.put(endPoint.DOCBOX_SAVE_UPDATE_CLASSFILE_URL(classid, file.FileId), classPostData);
        if (!response) {
            return;
        }

        /**
         * @type {import('features/General/DocBox/DocBox').DocBoxFileDto}
         */
        const fileData = { ...file,
            Name: response.data.Name,
            Url: response.data.Url,
            Description: response.data.Description
        };

        dispatch(docBoxDuck.updateFile({ motherId: postdata.Folder, type: "Start", file: fileData }));

    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}


/**
 * @param { Function } dispatch
 * @param { Function } getState
 * @param { string } folderId
 * @param { string } type
 * @param { number } fileId
 */
export const trashFile = async (dispatch, getState, folderId, type, fileId) => {

    const data = getState().docbox[type];
    if (data === null) { return; }


    const { folder } = findFolder(data, folderId, null);
    if (folder === null) { return; }

    const file = folder.Files.find(f => f.Id === fileId);
    if (file === null) { return; }

    try {
        /**
         * @type {{data: boolean}}
         */
        var response = await instance.post(endPoint.GET_DOCBOX_TRASH_FILE_URL(fileId), null);
        if (response && response.data === true) {
            dispatch(docBoxDuck.fileTrashed({ folderId: folderId, type: type, fileId: fileId }));
        }

    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 * @param { Function } dispatch
 * @param { Function } getState
 * @param { number } fileId
 * @param { string } type // 'Trash' | 'SharedTrash'
 */
export const untrashFile = async (dispatch, getState, fileId, type) => {

    const data = getState().docbox[type];
    if (data === null) {
        return;
    }

    const file = data.Files.find(f => f.Id === fileId);
    if (file === null) {
        return;
    }

    try {
        /**
         * @type {{data: string}}
         */
        await instance.post(endPoint.GET_DOCBOX_UNTRASH_FILE_URL(fileId), null);


        dispatch(docBoxDuck.fileUnTrashed({ fileId: fileId, type: type }));
        // reload start data
        await getStartData(dispatch, false);


    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}


/**
 * @param { Function } dispatch
 * @param { Function } getState
 * @param { string } rootId
 * @param { string } type // 'Trash' | 'SharedTrash'
 */
export const emptyTrash = async (dispatch, getState, rootId, type) => {

    const data = getState().docbox[type];
    if (data === null) {
        return;
    }
    try {
        /**
         * @type {{data: boolean}}
         */
        await instance.delete(endPoint.GET_DOCBOX_EMPTY_TRASH_URL(rootId));
        dispatch(docBoxDuck.trashEmptied({ type: type }));

    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 * @param { Function } dispatch
 * @param { Function } getState
 * @param { number } fileId
 * @param { string } type // 'Trash' | 'SharedTrash'
 */
export const deleteFile = async (dispatch, getState, fileId, type) => {

    const data = getState().docbox[type];

    try {
        /**
         * @type {{data: boolean}}
         */
        await instance.delete(endPoint.GET_DOCBOX_DELETE_FILE_URL(fileId));
        if (data != null) {
            dispatch(docBoxDuck.fileDeleted({ type: type, fileId: fileId }));
        }

    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 * @param { Function } dispatch
 * @param { Function } getState
 * @param { string } folderId
 * @param { string } type // 'Trash' | 'SharedTrash'
 */
export const deleteFolder = async (dispatch, getState, folderId, type) => {

    /**
     * @type {import('./DocBox').DocBoxState}
     */
    const doxboxstate = getState().docbox;
    /**
     * @type {import('./DocBox').DocBoxRootFolderDto}
     */
    const data = doxboxstate[type];



    try {


        /**
         * @type {{data: boolean}}
         */
        await instance.delete(endPoint.GET_DOCBOX_DELETE_FOLDER_URL(folderId));


        if (data != null) {
            dispatch(docBoxDuck.folderDeleted({ type: type, fileId: folderId }));
        }

    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}


/**
 * @param { Function } dispatch
 * @param { number } fileId
 * @param { string } targetFolderId
 */
export const moveClassFile = async (dispatch, classId, fileId, targetFolderId) => {



    const data = { Value: targetFolderId };
    try {

        // move file on server
        await instance.put(endPoint.GET_DOCBOX_MOVE_FILE_URL(fileId), data);

        // then re-fetch all data to make sure all is updated
        dispatch( classFilesDuck.fetchRootFolder(classId, true) );
        

    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 * @param { Function } dispatch
 * @param { Function } getState
 * @param { number } fileId
 * @param { string } targetFolderId
 * @param { string } type // 'Start' | 'Shared'
 */
export const moveFile = async (dispatch, getState, fileId, targetFolderId, type) => {


    const scope = getState().docbox.SharedScope;


    const data = { Value: targetFolderId };
    try {
        /**
         * @type {{data: import('types/types').IdAndNameDto[]}}
         */
        await instance.put(endPoint.GET_DOCBOX_MOVE_FILE_URL(fileId), data);
        switch (type) {
            case "Start":
                await getStartData(dispatch, false);
                break;

            case "Shared":
                getSharedData(scope, false);
                break;
            default:
                break;
        }



    } catch (error) {
        dispatch(errorDuck.setError({ header: "Error", message: error.message, when: new Date() }))
    }
}

/**
 * @param {import('features/General/DocBox/DocBox').DocBoxRootFolderDto} root
 * @return {  {folder: import('features/General/DocBox/DocBox').DocBoxFolderDto,
 * motherFolder: import('features/General/DocBox/DocBox').DocBoxFolderDto,
 * path: import('types/types').IdAndNameDto[] }}
 */
const useCurrentFolder = (root) => {

    if (root === null || root.CurrentFolder === null) {
        return { folder: null, motherFolder: null, path: null };
    }
    return findFolder(root, root.CurrentFolder, null)
}


/**
 *
 * @param {import('features/General/DocBox/DocBox').DocBoxFolderDto} folder
 * @param {string} folderId
 *
* @return {  {folder: import('features/General/DocBox/DocBox').DocBoxFolderDto,
 motherFolder: import('features/General/DocBox/DocBox').DocBoxFolderDto,
 path: import('types/types').IdAndNameDto[] }}
 */
const findFolder = (folder, folderId, motherFolder) => {
    if (folder.Id === folderId) {
        return { folder, motherFolder, path: [{ Id: folder.Id, Name: folder.Name }] };
    }
    if (folder.Children && folder.Children.length > 0) {

        for (let i = 0; i < folder.Children.length; i++) {
            const f = findFolder(folder.Children[i], folderId, folder);
            if (f.folder !== null) {
                f.path.splice(0, 0, { Id: folder.Id, Name: folder.Name });
                return f;
            }
        }
    }
    return { folder: null, motherFolder: null, path: null };
}





//  Exports ********************************************

export const docBoxDuck = {
    ...docBoxSlice.actions, useCurrentFolder, getStartData, trashFile,
    untrashFile, trashFolder, untrashFolder, deleteFile, deleteFolder,
    getSharedData, getTrashData, getSharedTrashData,
    findFolder, createFolder, addFile, emptyTrash, moveFile, moveClassFile,
    OwnerType, VirusScanStatus, StudentsGroupsScope,
    getStartDataForDialog, getSharedDataForDialog,
    saveEditedFile, updateFolderName,
    saveEditedClassFile
}

export default docBoxSlice.reducer

