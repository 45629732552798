//@ts-check
import { createSlice } from "@reduxjs/toolkit";
import { endPoint, release } from "AppConstants";
import instance from "utils/axios";

/* All the redux stuff needed for NetworkReconnect feature. LäraNära Portal */

/** @type {import('./NetworkReconnect_types').NetworkReconnectState} */
const initialState = {
    connected: true,
    timerId: null
}

/****************** reducers ************************/
const NetworkReconnectSlice = createSlice({
    name: "networkReconnect",
    initialState: initialState,
    reducers: {

        /**
         * @param {{payload: boolean}} action 
         */
        setConnected(state, action) {
            state.connected = action.payload;
        },

        /**
         * @param {{payload: number}} action 
         */
        setTimerId(state, action) {
            state.timerId = action.payload;
        },
    }

});

/****************** service methods ************************/
const services = {
    setNetworkState: (netUp) => (dispatch, getState) => {

        /**
         * @type{ import("./NetworkReconnect_types").NetworkReconnectState }
         */
        const state = getState().networkReconnect;

        if (netUp === false) {
            if (!state.timerId) {
                dispatch(networkReconnectDuck.setConnected(netUp));
                pollNetwork(dispatch);

            }
        }
        else {  // net is up again
            if (state.timerId) {
                window.clearTimeout(state.timerId);
            }
            dispatch(networkReconnectDuck.setConnected(netUp));
            dispatch(networkReconnectDuck.setTimerId(null));
        }


    },
};

const pollNetwork = async (dispatch) => {
    let postData = { ReleaseId: release };
    /**
     * @type  {{data: import("components/Pinger").LoginPingDto}}
     */
    try {
        const res = await instance.post(endPoint.PING_URL, postData);
        if( res && res.data){
            dispatch(services.setNetworkState(true));
        }
        else{
            const id =  window.setTimeout( ()=>pollNetwork(dispatch), 5*1000 );
            dispatch(networkReconnectDuck.setTimerId(id));
        }
    }
    catch (e) {
        console.log("catch poller")
    }

   

}

export const networkReconnectDuck = { ...NetworkReconnectSlice.actions, ...services }
export default NetworkReconnectSlice.reducer;