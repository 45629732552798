//@ts-check
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, shallowEqual, useStore } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from "reactstrap";
import Draggable from "react-draggable"

import { parseFileSize, getFileSizeString } from "utils/FileSizeUtils";
import useTypedSelector from "utils/useTypedSelector";
import ImagePreview from "../FileUpload/ImagePreview";
import { fileUploadDuck } from "../FileUpload/FileUploadDuck";
import ImageScaler from "./ImageScaler";

import { imagePickerDuck } from "./ImagePickerDuck";

let uploadAction = null;


/**
 *
 * @param {{
 *  files: File[],
 *  onImageReady: (id:string)=>void,
 *  accept: string,
 *  languageService: import('features/language/Language').LanguageService,
 *  maxsize: number| string,
 *  imagePreviewOptions?: {name: string, maxwidth: number, maxheight: number }[],
 *  onCloseDialog: Function
 *  }} props
 */
const ImageDrop = ({ files, onImageReady, accept, languageService, maxsize, imagePreviewOptions, onCloseDialog }) => {

    const t = languageService;
    const dispatch = useDispatch();
    const store = useStore();

    const maxSizeInBytes = parseFileSize(maxsize);
    const maxSizeString = getFileSizeString(maxSizeInBytes);

    const allowedTypes = accept.replace(/,/g, ", ");

    const { isUploading, percentUploaded } = useTypedSelector(state => state.fileUpload, shallowEqual);
    const user = useTypedSelector(state => state.login.user);

    const [showDialog, setShowDialog] = useState(false);

    const doCloseDialog = useCallback(() => {
        setShowDialog(false);
        if (onCloseDialog) {
            onCloseDialog();
        }
    }, [onCloseDialog])


    const [s3Uploader, setS3Uploader] = useState(null);

    import("../FileUpload/S3Uploader").then((s3) => {
        setS3Uploader(s3.default);
    });

    const setUploadAction = useCallback((action) => {
        uploadAction = action;
    }, []);

    useEffect(() => {
        dispatch(fileUploadDuck.setFile(null));
        // eslint-disable-next-line
    }, []);

    const doSaveImage = () => {
        uploadAction();

    }

    const tempUploadReady = async (file) => {

        const width = store.getState().imagepicker.imagePreviewWidth;

        const data = {
            FileName: file.name,
            UploadedFilename: file.key,
            MaxWidth: width
        }

        try {

            const serveData = await imagePickerDuck.addImageToSystem(data);
            if (serveData) {
                onImageReady(serveData.ImageId);
            }

        }
        finally {
            dispatch(fileUploadDuck.setImagePreviews(null));
            uploadAction = null;
            dispatch(imagePickerDuck.setIsUploading(false));
        }
    }



    useEffect(() => {
        /**
         * @param {File} fi 
         */
        const doUpload = (fi) => {

            dispatch(fileUploadDuck.startUpload({}));

            s3Uploader.uploadToS3(
                fi,
                user.Id,
                d => {
                    const percent = 100 * d.loaded / d.total;
                    dispatch(fileUploadDuck.setPercentage(percent));
                },
                file => {
                    dispatch(fileUploadDuck.setUploading(false));
                    let transformedFileData = {};
                    Object.keys(file)
                        .forEach(k => transformedFileData[k.toLowerCase()] = file[k]);

                    transformedFileData.name = fi.name;
                    transformedFileData.size = fi.size;
                    transformedFileData.type = fi.type;
                    transformedFileData.created = fi.lastModified;
                    tempUploadReady(transformedFileData);
                },
                d => { console.log(d); throw (d) }
            );
        }


        if (!files || files.length === 0) {
            doCloseDialog();
            return;
        }

        const fi = files[0];

        if (fi.size > maxSizeInBytes) {
            dispatch(fileUploadDuck.setError(t.getText("file.too.big", fi.name, getFileSizeString(fi.size), maxSizeString)));
            return;
        }

        const splits = fi.name.split(".");
        if (splits && splits.length > 1) {
            var ext = splits[splits.length - 1].toLowerCase();
            if (accept.indexOf(ext) < 0) {
                return;
            }
        }

        if (imagePreviewOptions != null && imagePreviewOptions.length > 0) {
            ImagePreview(fi, imagePreviewOptions)
                .then(data => {
                    dispatch(fileUploadDuck.setImagePreviews(data))
                    const width = data.full?.originalWidth;
                    if (width) {
                        dispatch(imagePickerDuck.setImagePreviewWidth(width));
                    }
                    setUploadAction(() => doUpload(fi));
                    setShowDialog(true);
                });

        }
        // eslint-disable-next-line
    }, [onImageReady, accept, doCloseDialog, setUploadAction, files, s3Uploader, user, dispatch, maxSizeInBytes, t, allowedTypes, maxSizeString, imagePreviewOptions]);




    if (!s3Uploader) {
        return null;
    }

    return (<>



        <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>
            <Modal size="xl" role="alertdialog" isOpen={showDialog} toggle={() => doCloseDialog()} className="" backdrop="static">
                <ModalHeader className="bg-light" toggle={() => doCloseDialog()} >{t.getText("image")}</ModalHeader>
                <ModalBody>


                    <div className={"mt-3 " + (isUploading ? "" : "d-none")}>
                        <Progress
                            color="success"
                            value={percentUploaded}
                        />{" "}
                        &nbsp;
                    </div>

                    <ImageScaler onSaveImage={doSaveImage} />

                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-inverse" onClick={() => doCloseDialog()}>{t.getText("close")}</Button>
                </ModalFooter>
            </Modal>
        </Draggable>
    </>



    );
}

export default ImageDrop;