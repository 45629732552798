//@ts-check
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classes from './login.module.scss';
import useTypedSelector from "utils/useTypedSelector";
import { LoginDuck } from "./LoginDuck";
import { useI18n } from "components/lni18n";
import dayjs from "dayjs";


const LoginNormal = (props) => {

    const { languageService: t } = useI18n();
    const dispatch = useDispatch()
    const { loginGuiState, relogin, user } = useTypedSelector(state => state.login);

    const [capsOn, setCapsOn] = useState(false);




    const testCapsLock = useCallback((e) => {
        if (e.getModifierState("CapsLock") !== capsOn) {
            setCapsOn(val => !val);
        }
    }, [setCapsOn, capsOn]);


    const goSendPasswd = () => {
        dispatch(LoginDuck.setGuiState({ ...loginGuiState, ShowSendPassword: true }))
    };

    const handleValueChange = (e, name) => {
        dispatch(LoginDuck.handleInputChange(e.target.value, name))
    }

    useEffect(()=>{
        
        if( relogin){
            window.setTimeout(()=> dispatch(LoginDuck.handleInputChange(user.Email, "Identifier")), 200);
            
        }
    }, [relogin, dispatch, user])


    /**
     * 
     * @param {import("react").FormEvent} e 
     * @returns 
     */
    const handleLogin = (e) => {

        // Localtime must be formatted with timezone explicit
        // this is how the api will evaluate the client's timezone
        // ex. 2020-09-01T08:43:32.967+02:00
        //
        // dayjs is doing a good job here
        const localtime = dayjs().format("YYYY-MM-DDTHH:mm:ssZ");

        // data is read only
        const loginData = { Identifier: loginGuiState.Identifier, Password: loginGuiState.Password, Federated: loginGuiState.federatedTextData.result, LocalTime: localtime };
        dispatch(LoginDuck.loginAtServer(loginData));
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    return <form onSubmit={handleLogin} className={classes.loginForm} >
        <div className="form-group">
            {!relogin &&
                <>
                    <input value={loginGuiState.Identifier || ""} onKeyUp={testCapsLock} autoFocus={true}
                        autoComplete="username" data-testid="inp-alias" type="text"
                        name="Identifier" onChange={(e) => handleValueChange(e, "Identifier")} 
                        placeholder={t.getText("alias")} className={`input form-control custom-input`} />

                    {loginGuiState.IdentifierError &&
                        <div className="errorMessage" >
                            {loginGuiState.IdentifierError()}
                        </div>
                    }
                </>}

            {relogin && <input value={loginGuiState.Identifier || ""} disabled
            onChange={(e) => handleValueChange(e, "Identifier")}
                data-testid="inp-alias" type="text"
                name="Identifier" className={`input form-control custom-input`} />}
        </div>

        {/* federated domain. Show only email and submit */}
        {loginGuiState.federatedTextData && loginGuiState.federatedTextData.result &&
            <div className="ml-3 mb-3 mt-3  clearfix">
                {loginGuiState.federatedTextData.text}
            </div>
        }

        {/* here is the normal password input in non federated login */}
        {(!loginGuiState.federatedTextData || !loginGuiState.federatedTextData.result) &&
            <>

                <div className={`form-group`}>
                    <input value={loginGuiState.Password || ""} onKeyUp={testCapsLock} autoComplete="current-password" type="password"
                        name="Password" onChange={(e) => handleValueChange(e, "Password")} placeholder={t.getText("passwd")} className="input form-control custom-input" />
                    {loginGuiState.PasswordError &&
                        <div className="errorMessage" >
                            {loginGuiState.PasswordError()}
                        </div>
                    }
                </div>
                {capsOn &&
                    <div className="preserve-white errorMessage">{t.getText("caps.lock.on")}</div>
                }
                <div>
                    <div className="ml-3 mb-3 mt-3 clearfix">
                        <button type="button" data-testid="btn-forgot-passwd" onClick={() => goSendPasswd()} title="" className={"btn btn-link " + classes.forgotPasswdLink}>{t.getText("no.password")}</button>
                    </div>
                </div>
            </>
        }

        <button type="submit" disabled={!loginGuiState.canSubmit} data-testid="btn-submit" className="btn btn-primary" title={t.getText("login")}>{t.getText("login")}</button>

    </form>

}

export default LoginNormal;
