//@ts-check
import React from "react";
import useTypedSelector from "utils/useTypedSelector";
import classes from './layout.module.scss';

 const ContentWrapper = (props) => {

    const sideBarForced = useTypedSelector(state => state.layout.sideBarForced);
    return <div className={[props.className, classes.wrapper, sideBarForced ? classes.forcedState : ""].join(' ')} >
        <div className="scrollBg d-print-none">&nbsp;</div>
        {props.children}
    </div>;
}


export default ContentWrapper;