//@ts-check
import React from "react";
import ContentWrapper from "features/layout/ContentWrapper";
import classes from './layout.module.scss';
import { LOGO } from "assets/images";
import languageService from "features/language/languageService"
import ScrollContent from "components/ScrollContent";
import LnIcon from "components/LnIcon";
import Link from "redux-first-router-link";
const NotFound = props => {

    return (<>

        <ContentWrapper className={classes.forcedState}>
            <div className={[classes.siteHeader, 'd-print-none p-3'].join(' ')}>
                <div className={"p-3 bg-white " + classes.notfoundlogo} >
                    <img alt="" className="small" src={LOGO} />
                </div>
            </div>

            <ScrollContent scrollname="not-found">
                <div className="card-box big-card-box max-col-width pb-5">
                    <div className="card-header">
                        <h2>
                            <LnIcon name="questionmark-icon" /> 404
                        </h2>
                    </div>
                    <div className="card-body">
                        <div className="preserve-white">
                            {languageService.getText("404.message")}
                        </div>
                        <div className="mt-4">
                            <Link className="btn btn-primary" to={{
                                type: 'LOGIN'
                            }}>
                                {languageService.getText("login")}
                            </Link>
                        </div>
                    </div>
                </div>
            </ScrollContent>

        </ContentWrapper>
    </>
    );
};

export default NotFound;