//@ts-check
import React from "react";
import Link from "redux-first-router-link";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";


/**
 * EventListItem
 * @module features/classes/Dashboard/EventListItem
 * @return an Event display for use in listing.
 * @param {{
    partData: import('../Classes').CoursePartReportDto,
    classId : string
}} props
 */
const CourseChangeItem = (props) => {

    const { languageService: t } = useI18n();
    const { partData, classId } = props;

    return (
        <div className="row mb-5">
            <div className="col-xs-2">
                <LnIcon className="mr-4" name="calendar-icon" />
            </div>
            <div className="col-xs-10">
                <small>
                    {t.getShortDateString(partData.When)}
                </small>

                <span className="ml-3">
                    <Link to={{
                        type: 'CLASS_THEME_PART',
                        payload: { classid: classId, themeid: partData.ThemeId, partid: partData.Id, type: "intro" }
                    }}>
                        {partData.Name}
                    </Link>
                    <span className="ml-2 badge">{partData.IsNew ? t.getText("new") : t.getText("changed")}</span>
                </span>

                {partData.HasNewFiles && <div className="mt-2">
                    <Link to={{
                        type: 'CLASS_THEME_PART',
                        payload: { classid: classId, themeid: partData.ThemeId, partid: partData.Id, type: "file" }
                    }}>
                        {t.getText("files")}
                    </Link>
                </div>}

                {partData.HasNewLinks && <div className="mt-2">
                <Link to={{
                        type: 'CLASS_THEME_PART',
                        payload: { classid: classId, themeid: partData.ThemeId, partid: partData.Id, type: "link" }
                    }}>
                        {t.getText("links")}
                    </Link>
                </div>}

                {partData.HasNewTests && <div className="mt-2">
                    <Link to={{
                        type: 'CLASS_THEME_PART',
                        payload: { classid: classId, themeid: partData.ThemeId, partid: partData.Id, type: "test" }
                    }}>
                        {t.getText("tests")}
                    </Link>
                </div>}

                {partData.Lectures && partData.Lectures.length > 0 ?
                    <div className="mt-2">

                        <strong>{t.getText("lectures")}</strong>
                        
                        {partData.Lectures.map(lect => {
                            return <div className="ml-4">
                                <Link to={{
                                    type: 'CLASS_THEME_PART',
                                    payload: { classid: classId, themeid: partData.ThemeId, partid: partData.Id, type: "lecture" }
                                }}>{lect.Name}</Link>

                               
                            </div>
                        })}
                    </div>
                    : null}

            </div>
        </div>


    )
}

export default CourseChangeItem;