//@ts-check
import React, { useCallback, useEffect } from "react";
import { useDispatch, useStore } from "react-redux";
import { useI18n } from "components/lni18n";
import useTypedSelector from "utils/useTypedSelector";
import FileUpload from "components/FileUpload/FileUpload";
import { fileUploadDuck } from "components/FileUpload/FileUploadDuck"
import { imagePickerDuck } from "./ImagePickerDuck";
import classes from "./ImagePicker.module.scss";
import WaitRipple from "components/WaitRipple";
import ImageScaler from "./ImageScaler";

let uploadAction = null;

const ImageUpload = (props) => {

  const dispatch = useDispatch();
  const store = useStore();
  const { languageService: t } = useI18n();
  const { isUploading } = useTypedSelector(state => state.imagepicker);
  const { imagePreviews } = useTypedSelector(state => state.fileUpload);


  const fileOptions = {
    fileExtensions: "bmp,gif,jpg,png,tif,tiff,jpeg",
    maxsize: "10mb",
    mimetypes: "image/jpeg,image/png,image/gif,image/tiff,image/bmp"
  };

  useEffect(() => {
    if (imagePreviews && imagePreviews.full) {
      dispatch(imagePickerDuck.setImagePreviewWidth(imagePreviews.full.width))
    }
  }, [imagePreviews, dispatch]);

  const imagePreviewReady = useCallback((action) => {
    uploadAction = action;
  }, []);


  const doSaveImage = () => {
    dispatch(imagePickerDuck.setIsUploading(true));
    uploadAction();

  }


  /**
      *
      * @param {{key:string, name:string, size:number, type:string}} file
      */
  const handleFileUploadReady = useCallback(async (file) => {

    const {imagePreviewWidth} = store.getState().imagepicker;

    const data = {
      FileName: file.name,
      UploadedFilename: file.key,
      MaxWidth: imagePreviewWidth
    }
    try {
      const newId = await dispatch(imagePickerDuck.addImage(data));
      if (newId) {
        dispatch(imagePickerDuck.setSelectedImage(newId));
        dispatch(imagePickerDuck.setTab('selected'));
      }

    }
    finally {
      dispatch(fileUploadDuck.setImagePreviews(null));
      uploadAction = null;
      dispatch(imagePickerDuck.setIsUploading(false));
    }

  }, [store, dispatch]);


  return <div className={classes.scrollPanel}>

    {isUploading && <WaitRipple />}

    <FileUpload previewReady={imagePreviewReady}
      className="w-100 h-100" hideDox={true} imagePreviewOptions={[{ name: "full", maxheight: 1200, maxwidth: 1200 }]}
      handleUploadReady={handleFileUploadReady} accept={fileOptions.fileExtensions} languageService={t}
      maxsize={fileOptions.maxsize} >

      <h3>{t.getText("upload.file")}</h3>

      {imagePreviews ? <ImageScaler onSaveImage={doSaveImage} />
      : null
      }

    </FileUpload>

  </div>
}

export default ImageUpload;
