//@ts-check
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useI18n } from "components/lni18n";
import useTypedSelector from "utils/useTypedSelector";
import LnIcon from "components/LnIcon";
import { endPoint } from "AppConstants";
import { userDuck } from "./UserDuck";
import { LoginDuck } from "features/login/LoginDuck";
import UserImg from "components/UserImg";
import ScrollContent from "components/ScrollContent";
import UserAsyncFind from "components/UserAsyncFind";

const SwitchUser = () => {

    const findUser = useRef(null);
    const dispatch = useDispatch();
    const { user: currentUser } = useTypedSelector(state => state.login);
    const lastFeature = useTypedSelector(state => state.layout.lastFeature);
    const { languageService: t } = useI18n();

    const [userSelected, setUserSelected] = useState(null);
    const [shadow, setShadow] = useState(true);

    const handleSwitch = () => {
        dispatch(LoginDuck.switchUser(userSelected.Id, shadow));
    }

    useEffect(() => {
        if (findUser.current) {
            findUser.current.focus();
        }
    }, [findUser])

    const goBack = () => {
        if (lastFeature == null) {
            window.history.back();
        }
        else {
            dispatch({ type: lastFeature.type, payload: lastFeature.payload });
        }
    }

    const handleUserChange = (e) => {
        setUserSelected(e);

    }

    const userSearch = async (query) => {
        const users = await userDuck.getUsers(query, "switchOnly", true);

        return users.map((i) => ({
            Mail: i.Mail,
            Initials: i.Initials,
            Id: i.Id,
            FullName: i.FullName
        }));
    };

    return (
        <ScrollContent scrollname="user-switch">
            <div className="card-box big-card-box max-col-width m-auto">
                <div className="card-header ln-nostyle d-flex justify-content-between">
                    <h3>
                        <LnIcon className="header-icon" name="info-icon" />
                        {t.getText("switch.user")}
                    </h3>
                    <div>
                        <button id="backfromSwitchUser" type="button" className="btn btn-inverse btn-small mr-4" onClick={() => goBack()}>{t.getText('back')}</button>
                    </div>
                </div>
                <div className="card-body pt-5 max-col-width">
                    <div className="d-flex">
                        <div className="flex-fill">

                            <UserAsyncFind onUserSelected={handleUserChange} userSearch={userSearch} />

                        </div>
                        {userSelected && <UserImg alt={userSelected.FullName} className="rounded-circle ml-3 avatar-img" src={endPoint.GET_USER_IMAGE_URL(userSelected.Id)} />}
                    </div>

                    <div className="mt-4 d-flex">
                        <button onClick={handleSwitch} disabled={!userSelected} className="btn btn-primary" >{t.getText("switch.user")}</button>

                        {currentUser.IsRoot &&
                            <div className="custom-control custom-switch ml-5">
                                <input type="checkbox" className="custom-control-input" id="switchShadow" checked={shadow} onChange={(e) => setShadow(e.target.checked)} />
                                <label className="custom-control-label" htmlFor="switchShadow">Shadow</label>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </ScrollContent>)

}



export default SwitchUser;