//@ts-check
import React from "react";
import { DropdownMenu, DropdownItem } from 'reactstrap';
import LnDropDownMenu from "components/LnDropDownMenu";
import Link from "redux-first-router-link";
import classes from './layout.module.scss';
import { useI18n } from "components/lni18n";
import { useDispatch } from "react-redux";
import { classesDuck } from "features/classes/ClassesDuck";
import LnIcon from "components/LnIcon";

/**
 * 
 * @param {{currentClass: import("features/classes/Classes").ClassAllDataDto,
 *  teacherData: import("features/classes/TeacherData").NavbarDto}} props
 */
const ClassesMenuTeacher = ({ currentClass, teacherData }) => {

    const dispatch = useDispatch();
    const { languageService: t } = useI18n();

    const setFilter = (e)=>{
        dispatch(classesDuck.filterRunningclasses({ text: e.target.value, order: teacherData.Classes?.RunningFilter?.Order }));
    }

    const toggleOrder = () => {

        const or = teacherData.Classes?.RunningFilter?.Order;
        let neworder = "";
        if (!or) return;
        switch (or) {
            case 'asc':
                neworder = 'desc';
                break;
            case 'desc':
                neworder = 'none';
                break;
            case 'none':
                neworder = 'asc';
                break;
            default:
                neworder = 'none';
                break;
        }

        dispatch(classesDuck.filterRunningclasses({ text: teacherData.Classes?.RunningFilter?.FilterText, order: neworder }));
    }

    let classesMenu;

    const runningClasses = teacherData.Classes?.RunningFilter?.Classes;
    const order = teacherData.Classes?.RunningFilter?.Order;

    let icon = "sorting";

    if (order) {
        if (order === 'asc') {
            icon = "sorting-down";
        }
        if (order === 'desc') {
            icon = "sorting-up";
        }


    }

    if (currentClass) {

        classesMenu = (<LnDropDownMenu
            caret={false} direction="down"
            toggleComponent={<div className="d-flex align-content-center">
                <h2 className="overflow-y-hidden ml-4 mr-2">{currentClass.Name}</h2>
                <span className={"white-caret"}></span>
            </div>}
            toogleClassName={[classes.currentClassToggle, "btn btn-link"].join(' ')}
            toogleTagName="div" >

            <DropdownMenu className={"px-3 " + classes.dropDownMenu} tag="ul">

                {runningClasses && <>
                    <div>
                        <div className="px-4 pt-2 pb-3 white-space-nowrap noHover d-flex align-items-center">
                            <strong>{t.getText("current.groups")}</strong>
                            <button onClick={toggleOrder} className="btn btn-link">
                                <LnIcon className="mx-3 icon-small" name={icon}></LnIcon>
                            </button>
                            <input placeholder={t.getText("search")} onChange={setFilter} className="ml-auto w-25 form-control form-control-sm" type="text" value={teacherData.Classes?.RunningFilter?.FilterText} />
                        </div>

                    </div>
                    {runningClasses.map(cl => {

                        return <DropdownItem key={cl.Id} tag="div" className={currentClass.Id === cl.Id ? " bg-primary-lightest text-white" : ""} >
                            <Link className="px-4 pt-2 pb-3" to={{ type: "CLASS", payload: { classid: cl.Id } }}>
                                <div className="d-flex">
                                    <div className={(cl.MainTeacher ? " primary-dot" : "")}>{cl.Name}</div>
                                    {cl.SubmittedTests ? <div className="ml-auto pl-3">({cl.SubmittedTests})</div> : ""}
                                </div>

                            </Link>
                        </DropdownItem>

                    })
                    }
                </>
                }

                {teacherData.Classes.Active && teacherData.Classes.Active.length > 0 && <>
                    <div>
                        <div className="px-4 pt-2 pb-3 white-space-nowrap noHover">
                            <strong>{t.getText("active.courses")}</strong>
                        </div>
                    </div>

                    {teacherData.Classes.Active.map(cl => {

                        return <DropdownItem key={cl.Id} tag="div" >
                            <Link className="px-4 pt-2 pb-3" to={{ type: "CLASS", payload: { classid: cl.Id } }}>
                                <div className="d-flex">
                                    <div className={(cl.MainTeacher ? " primary-dot" : "")}>{cl.Name}</div>
                                    {cl.SubmittedTests ? <div className="ml-auto pl-3">({cl.SubmittedTests})</div> : ""}
                                </div>

                            </Link>
                        </DropdownItem>

                    })
                    }
                </>
                }

                {teacherData.Classes.Rest && teacherData.Classes.Rest.length > 0 && <>

                    <div>
                        <div className="px-4 pt-2 pb-3 white-space-nowrap noHover">
                            <strong>{t.getText("additional")}</strong>
                        </div>
                    </div>

                    {teacherData.Classes.Rest.map(cl => {

                        return <DropdownItem key={cl.Id} tag="div" >
                            <Link className="px-4 pt-2 pb-3" to={{ type: "CLASS", payload: { classid: cl.Id } }}>
                                <div className="d-flex">
                                    <div>{cl.Name}</div>
                                </div>

                            </Link>
                        </DropdownItem>

                    })}
                </>
                }

                {teacherData.Classes.Finished && teacherData.Classes.Finished.length > 0 &&
                    <DropdownItem tag="div" >
                        <Link className="px-4 pt-2 pb-3" to={{ type: "CLASS_LIST_FINISHED" }}>
                            {t.getText("stopped_courses")}
                        </Link>
                    </DropdownItem>
                }


            </DropdownMenu>
        </LnDropDownMenu>);

    }
    else {
        classesMenu = <div>&nbsp;</div>;
    }

    return classesMenu;

}

export default ClassesMenuTeacher;
