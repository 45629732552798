//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import { useI18n } from "components/lni18n";
import { imagePickerDuck } from "./ImagePickerDuck";
import LnIcon from "components/LnIcon";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";
import CardTitle from "reactstrap/lib/CardTitle";

const ImageInfo = (props) => {

    const dispatch = useDispatch();
    const { languageService: t } = useI18n();

    const { selectedImage, currentImage, deleteRequested } = useTypedSelector(state => state.imagepicker);
    const { user: currentUser } = useTypedSelector(state => state.login);

    const onDeleteRequest = (state) => {
        dispatch(imagePickerDuck.setDeleteRequested(state));
    }

    const doDelete = () => {
        dispatch(imagePickerDuck.deleteCurrentImage());
    }

    return <>

        {currentUser.Id === selectedImage.User.Id && currentImage && <>

            <button className="ml-auto d-block btn btn-inverse btn-danger" onClick={() => onDeleteRequest(true)} ><LnIcon className="icon-medium filter-red" name="delete" /></button>


            <Collapse isOpen={deleteRequested} >
                <div className="mb-4">
                    {(currentImage.Overview.TotalReferenceCount > 0) && t.getText("image.used.delete.warning")}
                    <br />
                    {t.getText("sure_to_delete")}
                    <div className="mt-3 d-flex justify-content-between">
                        <button className="btn btn-inverse" onClick={() => onDeleteRequest(false)} >{t.getText("cancel")}</button>
                        <button className="btn btn-danger btn-inverse" onClick={() => doDelete()} >{t.getText("image.delete")}</button>
                    </div>
                </div>
            </Collapse>


        </>
        }

        {currentImage.Overview.TotalReferenceCount > 0 ? <>
            <h3 className="mb-3">{t.getText("in.use")}</h3>
            <div >
                {currentImage.Overview.Courses.length > 0 && <Card >
                    <CardHeader>
                        <CardTitle> {t.getText("courses")} </CardTitle>
                    </CardHeader>
                    <CardBody>

                        {currentImage.Overview.Courses.map(c => <div className="ml-4 mb-3 primary-dot"><strong>{c.Name}</strong> {c.Published ? t.getText("published") : t.getText("in.edit")}</div>
                        )}
                    </CardBody>
                </Card>}

                {currentImage.Overview.CourseParts.length > 0 && <Card >
                    <CardHeader>
                        <CardTitle> {t.getText("courseparts")} </CardTitle>
                    </CardHeader>
                    <CardBody>

                        {currentImage.Overview.CourseParts.map(c => <div className="ml-4 mb-3 primary-dot"><strong>{c.Name}</strong> {c.Published ? t.getText("published") : t.getText("in.edit")}</div>
                        )}
                    </CardBody>
                </Card>}

                {(currentImage.Overview.MailCount > 0 || currentImage.Overview.NotificationCount > 0 ||
                    currentImage.Overview.StudentCommentCount > 0 || currentImage.Overview.CourseAddonCount > 0||
                    currentImage.Overview.UnitCount > 0 ) &&

                    <Card >
                        <CardHeader>
                            <CardTitle> {t.getText("additional")} </CardTitle>
                        </CardHeader>
                        <CardBody>
                            {currentImage.Overview.MailCount > 0 && <div className="mb-3 ml-4 primary-dot">
                                {currentImage.Overview.MailCount}
                            </div>}

                            {currentImage.Overview.NotificationCount > 0 && <div className="mb-3 ml-4 primary-dot">
                                {t.getText("notifications")} {currentImage.Overview.NotificationCount}
                            </div>}

                            {currentImage.Overview.StudentCommentCount > 0 && <div className="mb-3 ml-4 primary-dot">
                                {t.getText("student.notes")} {currentImage.Overview.StudentCommentCount}
                            </div>}

                            {currentImage.Overview.CourseAddonCount > 0 && <div className="mb-3 ml-4 primary-dot">
                                {t.getText("courseaddons")} {currentImage.Overview.CourseAddonCount}
                            </div>}

                            {currentImage.Overview.UnitCount > 0 && <div className="mb-3 ml-4 primary-dot">
                                {t.getText("units")} {currentImage.Overview.UnitCount}
                            </div>}
                        </CardBody>
                    </Card>
                }

            </div>
        </> : <h4>{t.getText("image.overview.not.used.anywhere")}</h4>
        }

    </>
}

export default ImageInfo;
