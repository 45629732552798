//@ts-check
import React, { useState } from "react";
import {  useDispatch } from "react-redux";
import { useI18n } from "components/lni18n";
import classes from './login.module.scss';
import useTypedSelector from "utils/useTypedSelector";
import LoginNormal from "./loginNormal";
import SendPasswdMail from "./sendPasswdMail";
import WaitRipple from "components/WaitRipple";
import LanguageMenu from "components/LanguageMenu/LanguageMenu";
import {LoginDuck} from "./LoginDuck";
import {
    BACKARROW, LOGO, PDFIC
} from 'assets/images/index';
import LnIcon from "components/LnIcon";

const LoginMain = (props) => {

    const { languageService: t } = useI18n();
    const dispatch = useDispatch();
    const { loginGuiState, loginData } = useTypedSelector(state => state.login);
    const [showInfo, setShowInfo] = useState(false);

    const setShowForgotPassword = (state)=>{
        dispatch( LoginDuck.setGuiState({...loginGuiState, ShowSendPassword: state}));

    }

    if (!loginData) {
        return <div><WaitRipple /></div>
    }

    return (
        <div className={classes.loginPage}>

            <div className={classes.bannerBlock} style={{ backgroundImage: `url(${loginData.MainImageUrl}` }}>
                <div className={classes.loginBox}>
                    {
                        props.isLoading ? (<WaitRipple />) : null
                    }
                    <div className={classes.loginBoxHeader}>
                        <div>
                            {
                                // if( showForgotPassword)  show back arrow
                                loginGuiState.ShowSendPassword && (
                                    <button id="backfromloginMain" className={[classes.backArrow, "btn btn-link"].join(' ')} title={t.getText('back')} aria-label={t.getText('back')}
                                        onClick={() => setShowForgotPassword(false)} >
                                        <img src={BACKARROW} alt={t.getText('back')} />
                                    </button>

                                ) }
                        </div>
                        <LanguageMenu caretClass="dark-caret"></LanguageMenu>
                    </div>
                    <div className={classes.logoImage}>
                        <img src={LOGO} alt="logo" />
                    </div>

                    {
                        // if(
                            !loginGuiState.ShowSendPassword ?
                            <LoginNormal /> :  // else
                            <SendPasswdMail handleSendPasswordMail={props.handleSendPasswordMail} />
                        // end if
                    }
                    <div className="mt-4">
                        {
                            loginData.ManualUrls.map(p =>
                                (
                                    <div key={p.Header} className={classes.pdfLink}>
                                        <a href={p.Url} title={t.getText("manual")} target="_blank" rel="noopener noreferrer">
                                            <img src={PDFIC} alt={t.getText("manual")} />
                                            &nbsp;&nbsp;{t.getText("manual")} {p.Header}
                                        </a>
                                    </div>
                                )
                            )
                        }
                    </div>



                </div>
            </div>
            <button onClick={() => setShowInfo(!showInfo)} className={classes.infoButton + " btn btn-link"}><LnIcon name="info-icon" /></button>
            <div className={[classes.welcomeBlock, showInfo ? "d-block" : ""].join(' ')} >
                <button onClick={() => setShowInfo(!showInfo)} className={classes.closeInfoButton + " btn btn-link"}><LnIcon name="close" /></button>
                <div dangerouslySetInnerHTML={{ __html: loginData.Text }}></div>
            </div>

        </div>
    )


}


export default LoginMain;