import React from "react";

const LnIcon = (props) => {

    if (!props.name)
        return <span></span>

    return <img id={props.id ? props.id: ""}  className={'icon ' + props.className } title={props.title} src={ "/imgs/icons/" + props.name + ".svg"} style={props.style} alt={props.name}/>

}

export default LnIcon;