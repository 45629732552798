//@ts-check
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { UncontrolledCollapse } from "reactstrap";
import { useDispatch, shallowEqual } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress, Alert } from "reactstrap";
import Draggable from "react-draggable"

import { parseFileSize, getFileSizeString } from "utils/FileSizeUtils";
import LnResultIcon from "components/LnResultIcon";
import useTypedSelector from "utils/useTypedSelector";
import LnIcon from "components/LnIcon";
import ImagePreview from "components/FileUpload/ImagePreview";
import DocBoxFilePicker from "features/General/DocBox/DocBoxFilePicker";

import { endPoint } from "AppConstants";
import { fileUploadDuck } from "./FileUploadDuck";
import instance from "utils/axios";
import { TestResultStatus } from "features/course/part/test/TestEnums";
import { isFunction } from "utils/underscore"




/**
 *
 * @param {{
 *  accept: string,
 *  languageService: import('features/language/Language').LanguageService,
 *  maxsize: number| string,
 *  handleUploadReady: Function,
 *  children?: import('react').ReactChildren | import('react').ReactChild | import('react').ReactChild[],
 *  postchildren?: import('react').ReactChildren | import('react').ReactChild,
 *  className?:string,
 *  hideDox?: boolean,
 *  imagePreviewOptions?: {name: string, maxwidth: number, maxheight: number }[],
 *  previewReady?: Function
 *  }} props
 */
const FileUpload = ({ accept, languageService, maxsize, handleUploadReady, previewReady, children, postchildren, imagePreviewOptions, className, hideDox }) => {

    const t = languageService;
    const dispatch = useDispatch();

    const maxSizeInBytes = parseFileSize(maxsize);
    const maxSizeString = getFileSizeString(maxSizeInBytes);
    const acceptArray = accept ? accept.split(",").map(t => "." + t.replace(".", "").trim())
        : null;

    const allowedTypes = accept.replace(/,/g, ", ");

    const _className = className || 'border rounded-lg p-3';

    const { isUploading, percentUploaded, errorMess } = useTypedSelector(state => state.fileUpload, shallowEqual);
    const user = useTypedSelector(state => state.login.user);

    const [showDox, setShowDocBox] = useState(false);

    const [s3Uploader, setS3Uploader] = useState(null);

    import("./S3Uploader").then((s3) => {
        setS3Uploader(s3.default);
    });

    useEffect(() => {
        dispatch(fileUploadDuck.setFile(null));
        // eslint-disable-next-line
    }, [])

    const onDrop = useCallback(files => {

        /**
         * 
         * @param {File} fi 
         */
        const doUpload = (fi) => {

            dispatch(fileUploadDuck.startUpload({}));

            s3Uploader.uploadToS3(
                fi,
                user.Id,
                d => {
                    const percent = 100 * d.loaded / d.total;
                    dispatch(fileUploadDuck.setPercentage(percent));
                },
                file => {
                    dispatch(fileUploadDuck.setUploading(false));
                    let transformedFileData = {};
                    Object.keys(file)
                        .forEach(k => transformedFileData[k.toLowerCase()] = file[k]);

                    transformedFileData.name = fi.name;
                    transformedFileData.size = fi.size;
                    transformedFileData.type = fi.type;
                    transformedFileData.created = fi.lastModifiedDate;

                    dispatch(fileUploadDuck.setFile(transformedFileData));
                    handleUploadReady(transformedFileData);
                },
                d => { console.log(d); throw (d) }
            );
        }


        if (files.length === 0) {

            dispatch(fileUploadDuck.setError(t.getText("allowed.file.types") + " " + allowedTypes));
            return;
        }

        const fi = files[0];

        if (fi.size > maxSizeInBytes) {
            dispatch(fileUploadDuck.setError(t.getText("file.too.big", fi.name, getFileSizeString(fi.size), maxSizeString)));
            return;
        }

        if (imagePreviewOptions != null && imagePreviewOptions.length > 0) {
            ImagePreview(fi, imagePreviewOptions)
                .then(data => {
                    dispatch(fileUploadDuck.setImagePreviews(data))
                    if (isFunction(previewReady)) {
                        previewReady(() => doUpload(fi));
                    }
                });

        }

        if (!previewReady) {
            doUpload(fi);
        }



    }, [s3Uploader, user, dispatch, maxSizeInBytes, t, handleUploadReady, allowedTypes, maxSizeString, imagePreviewOptions, previewReady]);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        accept: acceptArray,
        noClick: true,
        multiple: false
    });



    const docBoxFileChosen = async (file) => {

        setShowDocBox(false);
        try {
            /**
             * @type {{data: import('./FileUploader').ExtendedFileInfoDTO}}
             */
            const response = await instance.post(endPoint.GET_DOCBOX_TRANSFER_TO_TEMP_URL(file.Id), null);
            if (!response) {
                return;
            }

            const fi = response.data;

            const syntheticFile = {
                Name: fi.Name,
                name: fi.Name,
                Type: fi.MimeType,
                Created: fi.CreationTimeUtc,
                size: fi.Length,
                type: fi.MimeType,
                key: fi.StorageKey

            }

            const fileData = syntheticFile;
            dispatch(fileUploadDuck.setFile(fileData));
            handleUploadReady(fileData);

        } catch (error) {
            throw error;
        }


    }

    if (!s3Uploader) {
        return null;
    }

    return (
        <>
            <div className={_className + (isDragActive ? " bg-primary-lightest" : "")}
                {...getRootProps()}
            >
                {children}

                <div className={"mt-3 " + (isUploading ? "" : "d-none")}>
                    <Progress
                        color="success"
                        value={percentUploaded}
                    />{" "}
                    &nbsp;
                </div>

                <Alert className="d-flex flex-row" isOpen={errorMess != null} toggle={() => dispatch(fileUploadDuck.setError(null))} color="danger">
                    <LnResultIcon className="mr-3" status={TestResultStatus.FollowUpNeeded} />
                    <div className="preserve-white ">{errorMess}</div>
                </Alert>

                <input {...getInputProps()} />

                <button type="button" className="mt-3  btn btn-small btn-inverse" onClick={open}>
                    <LnIcon name="upload" />
                    &nbsp;{t.getText("choose.file")}
                </button>

                {!hideDox && <button type="button" className="ml-4 btn btn-small btn-inverse" onClick={() => setShowDocBox(true)}>
                    <LnIcon name="dox-icon" />
                    &nbsp;Dox
                </button>}
                <div className="mb-3 small text text-muted">
                    {t.getText("drag.to.upload")}
                    &nbsp;&nbsp;
                    {t.getText("max.filesize")} {maxSizeString}

                    <div>
                        <button type="button" id="allowedTypesBtn" className="btn btn-link btn-small">{t.getText("allowed.file.types")}</button>
                        <UncontrolledCollapse toggler="#allowedTypesBtn">{allowedTypes}</UncontrolledCollapse>
                    </div>
                </div>

                {postchildren}
            </div>
            <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>
                <Modal size="lg" role="alertdialog" isOpen={showDox} toggle={() => setShowDocBox(!showDox)} className="" backdrop="static">
                    <ModalHeader className="bg-light" toggle={() => setShowDocBox(!showDox)} >Dox</ModalHeader>
                    <ModalBody>
                        <DocBoxFilePicker choseHandler={(e) => docBoxFileChosen(e)} />
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-inverse" onClick={() => setShowDocBox(!showDox)}>{t.getText("close")}</Button>
                    </ModalFooter>
                </Modal>
            </Draggable>
        </>



    );
}

export default FileUpload;