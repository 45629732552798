//@ts-check
import { createSlice } from '@reduxjs/toolkit'
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import { courseDuck } from "../../CourseDuck";
import { classesDuck } from 'features/classes/ClassesDuck';


const screenJumps = {
    first: 0,
    last: 1,
    prev: 2,
    next: 3,
    specificNumber: 4
}

/**
 * @type import('./Lectures').CurrentScreen
 */
const currentScreenDataInitial = {
    Id: null,
    imgUrl: "",
    videoUrl: null,
    audioUrl: null,
    DurationInSeconds: 0,
    html: "",
    transcript: "",
    screenType: "",
};

/**
 * @type import('./Lectures').LectureState
 */
const initialState = {
    currentLecture: null,
    showTranscript: false,
    currentScreen: null,
    previousScreen: null,
    mediaData: null,
    showScreensList: false,
    controlsState: {
        visible: true,
        fullscreen: false,
        showingMenu: false,
    },
    volume: 100,
    changeDirection: null,
    autoRepeat: false,
    autoNext: false,
    autoNextTimerId: 0,
    doPlay: false,
    isPlaying: false,
    playbackRate: 1,
    currentScreenData: currentScreenDataInitial,
    printstyle: 3,
    stoppedAtCuePoint: false,
    showForumDialog: false,
    showTestDialog: false,
    showNextPopup: false,
};


const lectureSlice = createSlice({
    name: 'lecture',
    initialState: initialState,
    reducers: {

        /**
        * @param  {  {payload: {lecture: import('./Lectures').LecturePlayerDto, startScreen: number }} } action
         */
        setCurrentLecture(state, action) {

            state.stoppedAtCuePoint = false;
            state.showNextPopup = false;

            if (action.payload == null) {
                state.currentLecture = null;
                state.currentScreen = null;
                state.previousScreen = null;
                state.autoRepeat = false;
                state.autoNext = false

            }
            else {

                state.currentLecture = action.payload.lecture;
                state.currentLecture.Screens.forEach((s, i) => {
                    s.Order = i;
                });
                state.previousScreen = null;



                state.mediaData = {
                    Duration: 0,
                    Time: 0,
                    VideoUrl: null,
                    AudioUrl: null,
                    ImageUrl: null,
                    Html: null,
                    Transcript: null,

                }

                setCurrentScreenDataJob(state, action.payload.startScreen);
            }

            state.currentScreenData = currentScreenDataInitial;

            state.showScreensList = false;
            state.controlsState = {
                visible: true,
                fullscreen: false,
                showingMenu: false
            };

            state.autoRepeat = false;
            state.autoNext = false

            state.changeDirection = null;
            state.autoNextTimerId = 0;


        },

        /**
      * @param  {  {payload: import('./Lectures').CurrentScreen }} action
       */
        setCurrentScreenData(state, action) {

            state.stoppedAtCuePoint = false;
            state.showNextPopup = false;

            if (action.payload === null) {
                state.currentScreenData = currentScreenDataInitial;
            }
            else {
                state.currentScreenData = action.payload;
            }
        },

        /**
       * @param  {  {payload: {screenNumber: number }} } action
        */
        setCurrentScreen(state, action) {

            setCurrentScreenDataJob(state, action.payload.screenNumber);
            state.stoppedAtCuePoint = false;
            state.showNextPopup = false;
        },

        /**
       * @param  {  {payload: number} } action
        */
        setPrintStyle(state, action) {
            state.printstyle = action.payload;
        },


        /**
        * @param {{payload: any }} action
        */
        setMediaData(state, action) {
            state.mediaData = { ...state.mediaData, ...action.payload };
        },

        /**
        * @param {{payload:void }} action
        */

        toggleAutoRepeat(state, action) {
            state.autoRepeat = !state.autoRepeat;
            if (state.autoRepeat === true) {
                state.autoNext = false;
            }
        },

        /**
        * @param {{payload:void }} action
        */

        toggleAutoNext(state, action) {
            if (state.currentLecture && (state.currentLecture.AllViewed || state.currentLecture.AlwaysAllowAutoplay)) {
                state.autoNext = !state.autoNext;
                if (state.autoNext === true) {
                    state.autoRepeat = false;
                }
            }
            else {
                state.autoNext = false;
            }
        },

        /**
       * @param {{payload:number }} action
       */

        setAutoNextTimerId(state, action) {
            state.autoNextTimerId = action.payload;
        },

        /**
        * @param {{payload: number }} action
        */
        setVolume(state, action) {
            state.volume = action.payload;
        },

        /**
         * @param {{payload:number }} action
         */
        setPlaybackRate(state, action) {
            state.playbackRate = action.payload;
        },


        /**
        * @param {{payload: boolean }} action
        */
        setControlsVisible(state, action) {
            state.controlsState.visible = action.payload;
        },

        /**
        * @param {{payload: boolean }} action
        */
        setShowingControlsMenu(state, action) {
            state.controlsState.showingMenu = action.payload;
        },

        /**
        * @param {{payload: boolean }} action
        */
        setFullScreen(state, action) {
            state.controlsState.fullscreen = action.payload;
        },



        /**
         *
         * @param {{payload: void }} action
         */
        toggleTranscript(state, action) {
            if (state.currentLecture) {
                state.showTranscript = !state.showTranscript;
            }
        },


        /**  set current time in seconds
         *
         * @param {{payload: number }} action
         */
        setScreenTime(state, action) {
            if (state.currentScreen) {
                state.mediaData.Time = action.payload;
            }
        },

        /**  set playing on for Media
         *
         * @param {{payload: boolean}} action
         */
        setWantedPlayingState(state, action) {
            if (action.payload === false || !state.stoppedAtCuePoint) {
                state.doPlay = action.payload;
            }

        },

        /**  set stopped at a cuePoint
        *
        * @param {{payload: boolean}} action
        */
        setStoppedAtCuePoint(state, action) {
            state.stoppedAtCuePoint = action.payload;
            if (action.payload === true) {
                state.doPlay = false;
            }
        },


        /**  shows playing state for Media
         *
         * @param {{payload: boolean}} action
         */
        setPlayerRunningState(state, action) {
            if (state.currentScreen) {
                state.isPlaying = action.payload;
            }
        },

        /**  toggle playing for Audio/Video
         *
         * @param {{payload: void}} action
         */
        togglePlayingState(state, action) {
            if (state.currentScreen) {
                if (state.isPlaying === true || !state.stoppedAtCuePoint) {
                    state.doPlay = !state.doPlay;
                }
            }
        },

        /**  toggle show screens thumbs
       *
       * @param {{payload: void}} action
       */
        toggleShowScreensList(state, action) {
            state.showScreensList = !state.showScreensList;
        },

        /**  toggle show screens thumbs
       *
       * @param {{payload: boolean}} action
       */
        setShowScreensList(state, action) {
            state.showScreensList = action.payload;
        },

        /**  
          *
          * @param {{payload: { screenId: string, segments: import('./Lectures').WatchedSegmentDto[] }}} action
          */
        setCurrentSegments(state, action) {



            if (state.currentScreen.Id === action.payload.screenId) {
                state.currentScreen.Segments = action.payload.segments;

                if (state.mediaData && state.mediaData.Duration) {
                    state.currentScreen.Styles =
                        makeSegementStyles(state.mediaData.Duration,
                            [...action.payload.segments, {
                                Start: state.currentScreen.LastStartPosition,
                                End: state.currentScreen.LastEndPosition
                            }]);
                }
            }
        },

        /**  
         *
         * @param {{payload: { start: number, end: number }}} action
         */
        setRunningSegment(state, action) {
            state.currentScreen.LastStartPosition = action.payload.start;
            state.currentScreen.LastEndPosition = action.payload.end;
            state.currentScreen.Styles = makeSegementStyles(
                state.mediaData.Duration, [...state.currentScreen.Segments, {
                    Start: state.currentScreen.LastStartPosition,
                    End: state.currentScreen.LastEndPosition
                }]);
        },

        /**  
         *
         * @param {{payload: string}} action
         */
        setScreenWatched(state, action) {

            const screen = state.currentLecture.Screens.find(s => s.Id === action.payload);
            if (screen != null) {
                screen.Watched = true;
            }
        },


        /**  
         *
         * @param {{payload: string}} action
         */
        setCurrentImage(state, action) {
            state.mediaData.ImageUrl = action.payload;
        },

        /**  
         *
         * @param {{payload: string}} action
         */
        setCurrentVideoUrl(state, action) {
            state.mediaData.VideoUrl = action.payload;
            state.mediaData.AudioUrl = "-";
        },

        /**  
         *
         * @param {{payload: string}} action
         */
        setCurrentAudioUrl(state, action) {
            state.mediaData.AudioUrl = action.payload;
        },

        /**  
        *
        * @param {{payload: boolean}} action
        */
        setShowForumDialog(state, action) {
            state.showForumDialog = action.payload;
        },

        /**  
        *
        * @param {{payload: boolean}} action
        */
        setShowTestDialog(state, action) {
            state.showTestDialog = action.payload;
            if (action.payload === false && state.currentScreen.continueOnCloseDialog === true) {
                state.stoppedAtCuePoint = false;
                state.doPlay = true;
                state.currentScreen.continueOnCloseDialog = false;
            }
        },

        setCurrentCuePointAsPassed(state, action) {
            state.stoppedAtCuePoint = false;
            state.currentScreen.currentCuePoint.Passed = true;
            var point = state.currentScreen.CuePoints.find(c => c.CuePointID === state.currentScreen.currentCuePoint.CuePointID);
            if (point) {
                point.Passed = true;

                state.currentScreen.continueOnCloseDialog = true

                const nextCuePoints = getNextCuePoint(state.currentScreen.CuePoints);
                if (nextCuePoints) {
                    state.currentScreen.maxPositionInSeconds = nextCuePoints.position;
                    state.currentScreen.currentCuePoint = nextCuePoints.cuePoint;
                }
                else {
                    state.currentScreen.currentCuePoint = null;
                    state.currentScreen.maxPositionInSeconds = state.currentScreen.DurationInSeconds + 1;
                }
            }
        },

        /**
      * @param  {  {payload: boolean} } action
       */
        setShowNextPopup(state, action) {
            state.showNextPopup = action.payload;
        },


    }
},
);




/**
 * 
 * @param {number} duration 
 * @param {import('./Lectures').WatchedSegmentDto[]} segments 
 */
const makeSegementStyles = (duration, segments) => {

    return segments.map((s) => {
        var left = ConvertToPercent(duration, s.Start);
        var right = ConvertToPercent(duration, s.End);

        return {
            'left': left + "%",
            'width': (right - left) + "%"
        };
    })
}

const ConvertToPercent = (duration, seconds) => {
    return Math.max(0, Math.min(100, 100 * seconds / duration));
}


/**
 * 
 * @param {import('./Lectures').LectureState} state 
 * @param {number | null} screenNumber 
 */
const setCurrentScreenDataJob = (state, screenNumber) => {


    if (state.currentScreen) {
        if (state.currentLecture.Screens && state.currentLecture.Screens[state.currentScreen.Number])
            state.currentLecture.Screens[state.currentScreen.Number].Segments = state.currentScreen.Segments;

        if (screenNumber !== null) {
            state.changeDirection = state.currentScreen.Order < screenNumber ? "fromLeft" : "fromRight";
        }
        else {
            state.changeDirection = "fromLeft";
        }
        state.previousScreen = state.currentScreen;
    }
    else {
        state.previousScreen = null;
    }

    state.autoRepeat = false;
    if (screenNumber !== null) {
        state.currentScreen = state.currentLecture.Screens[screenNumber];
    }
    else {
        state.currentScreen = null;
    }


    if (state.currentScreen) {



        state.currentScreen.Number = screenNumber;
        state.currentScreen.currentCuePoint = null;
        state.currentScreen.maxPositionInSeconds = state.currentScreen.DurationInSeconds + 1;

        const nextCuePoints = getNextCuePoint(state.currentScreen.CuePoints);
        if (nextCuePoints) {
            state.currentScreen.maxPositionInSeconds = nextCuePoints.position;
            state.currentScreen.currentCuePoint = nextCuePoints.cuePoint;
        }

        state.currentScreen.Styles = makeSegementStyles(state.currentScreen.DurationInSeconds, state.currentScreen.Segments);
        state.currentScreen.LastStartPosition = 0
        state.currentScreen.LastEndPosition = 0




        if (state.currentLecture.Screens.length > 1) {
            state.currentLecture.AtLastScreen = screenNumber >= state.currentLecture.Screens.length - 1;
            state.currentLecture.AtFirstScreen = screenNumber < 1;
        }
        else {
            state.currentLecture.AtLastScreen = true;
            state.currentLecture.AtFirstScreen = true;
        }

        if (state.currentScreen.DurationInSeconds && !state.currentScreen.Segments) {
            state.currentScreen.Segments = [];
        }
    }



    state.isPlaying = false;
    //state.doPlay = true; // to enable auto start of media
    state.mediaData.Duration = state.currentScreen ? state.currentScreen.DurationInSeconds : 0;
    state.mediaData.Time = 0;
    state.mediaData.AudioUrl = null;
    state.mediaData.Html = null;
    state.mediaData.Transcript = null;
}

const getNextCuePoint = (cuePoints, lectureId) => {
    if (cuePoints && cuePoints.filter(p => !p.Passed).length > 0) {
        const firstCuePoint = cuePoints.filter(p => !p.Passed).sort((a, b) => a.position - b.position)[0];

        if (firstCuePoint) {
            // cuepoint positions are in milliseconds, convert to seconds
            return { position: firstCuePoint.position / 1000, cuePoint: firstCuePoint };
        }
    }

    return null;
}


/**
 *
 */
const getCurrentLecture = async (getState, dispatch, classId, lectureId, startScreen) => {

    /**
     * @type {import('utils/Config/Config').LectureConfig}}
     */
    const lectConfig = getState().config.lectureConfig;

    /**
     * @type {{data: import('./Lectures').LecturePlayerDto}}
     */
    var response = await instance.get(endPoint.GET_LECTURE_DATA_URL(classId, lectureId,
        lectConfig.screenHeight, lectConfig.screenWidth, lectConfig.pixelDensity));

    if (!response) {
        return;
    }

    startScreen = startScreen || 0;
    const lecture = response.data;

    dispatch(lectureDuck.setCurrentLecture({ lecture: response.data, startScreen: startScreen }));

    if (lecture.Screens.length > 1 && startScreen === 0 && lecture.Screens[0].DurationInSeconds === 0) {
        const disp = dispatch;
        window.setTimeout(() => {
            disp(lectureDuck.setShowNextPopup(true));
        }, 600);
    }




}



/**
 * 
 * @param {Function} dispatch 
 * @param {Function} getState 
 * @param {number} jumpType
 * @param {number| null} screenNum
 */
const tryShiftScreen = async (dispatch, getState, jumpType, screenNum) => {



    /**
         * @type {import('./Lectures').LectureState}
         */
    const state = getState().lecture;

    const { payload: { classid, themeid, partid, lectureid } } = getState().location;


    if (state.currentLecture == null || !state.currentScreen) {
        return false;
    }

    let slideNum = -1;

    switch (jumpType) {
        case lectureDuck.screenJumps.first:
            if (state.currentScreen.Order > 0) {
                slideNum = 0;
            }
            break;

        case lectureDuck.screenJumps.last:
            if (state.currentScreen.Order < state.currentLecture.Screens.length - 1) {
                slideNum = state.currentLecture.Screens.length - 1;
            }
            break;

        case lectureDuck.screenJumps.prev:
            if (state.currentScreen.Order > 0) {
                slideNum = state.currentScreen.Order - 1;
            }
            break;

        case lectureDuck.screenJumps.next:
            if (state.currentScreen.Order < state.currentLecture.Screens.length - 1) {
                slideNum = state.currentScreen.Order + 1;
            }
            break;

        case lectureDuck.screenJumps.specificNumber:
            if (screenNum !== null && screenNum >= 0 && screenNum < state.currentLecture.Screens.length
                && screenNum !== state.currentScreen.Order) {
                slideNum = screenNum
            }

            break;

        default:
            break;
    }

    if (state.currentScreen && !state.currentScreen.Watched && state.currentScreen.Segments) {

        SaveSegments(dispatch, getState, state.currentScreen.Id);
    }


    if (slideNum > -1) {  // navigate to URL for this screen

        await lectureDuck.onStop(dispatch, getState);



        dispatch(lectureDuck.setCurrentScreen({ screenNumber: null }));

        window.setTimeout(() => {
            dispatch(lectureDuck.setPlayerRunningState(false));
            dispatch(lectureDuck.setScreenTime(0));

            dispatch({
                type: "CLASS_THEME_PART_LECTURE_PLAY", payload: {
                    classid: classid,
                    themeid: themeid,
                    partid: partid,
                    lectureid: lectureid,
                    slide: slideNum + 1
                }
            });

        }, 10)

        return true;
    }
    else {
        return false;
    }

}


/**
 * 
 * @param {Function} dispatch 
 * @param {Function} getState 
*/
const onStop = async (dispatch, getState) => {

    /**
     * @type {import('./Lectures').LectureState}
     */
    const { currentScreen, mediaData, currentLecture } = getState().lecture;

    if (currentScreen) {
        const data = {
            Start: Math.max(currentScreen.LastStartPosition, 0),
            End: Math.max(currentScreen.LastEndPosition, currentScreen.LastStartPosition + 1)
        };

        if (currentLecture.Screens.length > 1 && mediaData.Time + 5 > mediaData.Duration && currentScreen.Order === 0) {
            await dispatch(lectureDuck.setShowNextPopup(true));
        }

        const segments = mergeSegments([...currentScreen.Segments, data]);

        await dispatch(lectureDuck.setCurrentSegments({ screenId: currentScreen.Id, segments: segments }));
    }

}


/**
 * 
 * @param {Function} dispatch 
 * @param {Function} getState 
 * @param {number} currentTime 
*/
const handleSegment = async (dispatch, getState, currentTime) => {

    /**
     * @type {import('./Lectures').LectureState}
     */
    const lectureData = getState().lecture;
    const currentScreen = lectureData.currentScreen;
    if (!currentScreen || currentScreen.Watched) {
        return;
    }

    if (currentTime <= 0) {
        return;
    }

    let start = currentScreen.LastStartPosition;
    let end = currentTime;
    dispatch(lectureDuck.setRunningSegment({ start: start, end: end }));

    return;


}

/**
 * 
 * @param {Function} dispatch 
 * @param Function getState
 * @param {string} screenId
 */
const SaveSegments = async (dispatch, getState, screenId) => {

    try {
        /**
        * @type {import('./Lectures').LectureState}
        */
        const state = getState().lecture;
        const screen = state.currentScreen;
        if (!screen || screen.Watched) {
            return;
        }

        const segments = screen.Segments.map(seg => {
            /**
             * @type {import('./Lectures').WatchedSegmentDto}
             */
            const s = { Start: Math.floor(seg.Start), End: Math.ceil(seg.End) };
            return s
        })

        /**
         * @type {{data: import('./Lectures').WatchedDto}}
         */
        const response = await instance.put(endPoint.GET_LOG_SCREEN_VIEWS_URL(screenId), segments);
        if (!response || !response.data) {
            return;
        }
        const d = response.data;

        if (!d || !d.ScreenId) {
            // server error. stale in DB - do not consider this
            return;
        }

        if (d.ScreenWatched) {
            await dispatch(lectureDuck.setScreenWatched(d.ScreenId));
        }


        if (d.LectureWatched) {
            await dispatch(courseDuck.setLectureWatched({ lectureId: d.LectureId, partId: d.CoursePartId }));
        }

        if (d.CoursePartWatched) {
            await dispatch(courseDuck.setCurrentPartWatched(d.CoursePartId));
            await dispatch(classesDuck.setPartWatched(d.CoursePartId));
        }
    }
    catch (error) {
        handleErrors(error);
    }


}

/**
 * 
 * @param {Function} dispatch 
 * @param {string} screenId 
 */
const saveSilentScreen = async (dispatch, screenId) => {

    try {

        const url = endPoint.GET_LOG_SCREEN_URL(screenId);

        /**
         * @type {{data: import('./Lectures').WatchedDto}}
         */
        const response = await instance.put(url, null);
        if (!response) {
            return;
        }
        const d = response.data;

        if (!d || !d.ScreenId) {
            // server error. stale in DB - do not consider this
            return;
        }

        if (d.ScreenWatched) {
            dispatch(lectureDuck.setScreenWatched(d.ScreenId));
        }


        if (d.LectureWatched) {
            dispatch(courseDuck.setLectureWatched({ lectureId: d.LectureId, partId: d.CoursePartId }));
        }

        if (d.CoursePartWatched) {
            dispatch(courseDuck.setCurrentPartWatched(d.CoursePartId));
            dispatch(classesDuck.setPartWatched(d.CoursePartId));
        }
    }
    catch (error) {
        handleErrors(error);
    }


}


/**
 * 
 * @param {import('./Lectures').WatchedSegmentDto[]} segmentsIn 
 */
const mergeSegments = (segmentsIn) => {


    /**
     * @type import('./Lectures').WatchedSegmentDto[]
     */
    const segments = [];

    // order reverse
    const segmentsOrdered = segmentsIn.map(s => { return { ...s }; }).sort((b, a) => a.Start - b.Start);

    let newNode = segmentsOrdered.pop();
    while (newNode) {
        let nextNode = segmentsOrdered.pop();
        while (nextNode && nextNode.Start < newNode.End + 1) {
            newNode.End = Math.max(nextNode.End, newNode.End);
            nextNode = segmentsOrdered.pop();
        }
        segments.push(newNode);
        newNode = nextNode;
    }


    return segments;

}



//   Errors *******************************************

const handleErrors = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    console.log(error.config);

}


//  Exports ********************************************

export const lectureDuck = {
    ...lectureSlice.actions, getCurrentLecture, handleSegment,
    tryShiftScreen, screenJumps, mergeSegments, onStop, saveSilentScreen,
    SaveSegments
}

export default lectureSlice.reducer


