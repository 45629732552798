//@ts-check
import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { ErrorMessage } from '@hookform/error-message';
import UserImg from "components/UserImg";
import { endPoint } from "AppConstants";
import { useI18n } from "components/lni18n";
import useTypedSelector from "utils/useTypedSelector";

import { forumService } from "./ForumDuck";
import classes from './forum.module.scss';
import { useEffect } from "react";
import { debouncer } from "utils/debouncer";

/**
 * ForumForm
 * @module features/classes/ClassItems/Forum/ForumForm
 * @return a forum form.
 * @param {{
    toggleForm: any,
    replyTo:number,
    forumId:string,
}} props
 */



const ForumSimpleForm = (props) => {

    const { languageService: t } = useI18n();
    const dispatch = useDispatch();
    const store = useStore();
    const debouncerRef = useRef(null);
    const draftIdRef = useRef(null);

    const user = useTypedSelector(state => state.login.user);
    const userImage = endPoint.GET_USER_IMAGE_URL(user.Id);
    const [isSubmitting, setIsSubmitting] = useState(false);


    const { replyTo, forumId, toggleForm } = props;

    const initialValues = {
        article: '',
    };

    const validationSchema = Yup.object({
        article: Yup.string()
            .required(t.getText('servermessage.empty')),
    });



    const handleSubmitForm = async (values, setSubmitting) => {
        setIsSubmitting(true);
        const postData = {
            article: values.article,
            fileName: null,
            fileTempName: null,
            replyTo: replyTo,
            header: values.header
        }
        try {
            await forumService.createReply(forumId, postData, dispatch, store.getState);
            forumService.deleteDraft(draftIdRef.current);
            draftIdRef.current = undefined;
            toggleForm();
        } finally {
            setIsSubmitting(false);
        }

    }


    const { register, handleSubmit, errors, watch, getValues, setValue } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues,
        mode: "onBlur"
    });

    const watchAll = watch();

    // at changes
    useEffect(() => {
        if (debouncerRef.current) {
            debouncerRef.current.debounce();
        }
    }, [watchAll]);

    const upDateDraft = useCallback(() => {
        const values = getValues();
        /**
         * @type{ import("./Forum").ForumDraft }
         */
        const data = {
            file: null,
            id: draftIdRef.current,
            forumId: forumId,
            replyTo: replyTo,
            article: values.article,
            userId: user.Id
        }
        forumService.saveDraft(data);

    }, [getValues, replyTo, user, forumId]);


        // at upstart
        useEffect(() => {
            if (user && forumId) {
                forumService.getDraft({ forumId, replyTo, userId: user.Id })
                    .then(data => {
                        if (data) {
                            setValue("article", data.article);
                            draftIdRef.current = data.id;
                        }
                    });
    
            }
        }, [setValue, user, forumId, replyTo, dispatch]);
    
        useEffect(() => {
            const d = new debouncer(() => upDateDraft(), 300);
            debouncerRef.current = d;
            return () => d.clear();
        }, [upDateDraft])


    return (

        <div className="pl-2 d-flex">
            <UserImg src={userImage} className={['rounded-circle', 'avatar-img', classes['answer-avatar']].join(' ')} alt={t.getText('user')} />
            <form onSubmit={handleSubmit(handleSubmitForm)} className={["ml-2 mb-5", classes['forum-form']].join(' ')}>
                <div>
                    <div className="form-group mb-4">
                        <label htmlFor="article">{t.getText('reply')}</label>
                        <textarea ref={register}
                            name="article"
                            className="form-control"
                            rows={6}
                        />
                        <ErrorMessage as="div" errors={errors} className="errorMessage" name="article" />
                    </div>
                </div>

                <div className="mb-4 mt-4 d-flex justify-content-end">
                    <button className="btn btn-primary btn-small mr-4"
                        disabled={isSubmitting}
                        type="submit">{t.getText('submit')}</button>
                    <button className="btn btn-inverse btn-small" type="button" onClick={() => toggleForm()}>{t.getText('cancel')} </button>
                </div>
            </form>

        </div>

    )

}
export default ForumSimpleForm;
