//@ts-check
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useI18n } from "components/lni18n";
import { classMailDuck } from "./ClassMailDuck";
import { errorDuck } from "components/ErrorHandler/ErrorHandlerDuck";
import MailEditor from "components/Mail/MailEditor";
import ReceiverModal from "./ReceiverModal";
import MailMeta from "components/Mail/MailMeta";
import { Confirm, ModalConstants } from "components/OnlineModal";

import classes from './classmail.module.scss';

/**
 * ClassMailForm
 * @module features/classes/ClassItems/ClassMail/ClassMailForm
 * @return a feed form.
 * @param {{
    classData: any,
    mailData: any,
    handleDelete: Function,
    closeForm: Function,
    handleMailSent: Function
}} props
 */
const ClassMailForm = ({ classData, mailData, handleDelete, closeForm, handleMailSent }) => {

    const {  languageService: t } = useI18n();
    const dispatch = useDispatch();

    const prepReceiverIdList = (lists) => {
        const Students = lists.Students.filter(r => r.Checked).map(r => r.Receiver.Id);
        const Teachers = lists.Teachers.filter(r => r.Checked).map(r => r.Receiver.Id);
        return { Students, Teachers };
    }

    const [ mailFormValues, setMailFormValues ] = useState({});
    const [ mailFormState, setMailFormState ] = useState({});

    const [ canSave, setCanSave ] = useState(false);
    const [ canSend, setCanSend ] = useState(false);

    const [ receiverIds, setReceiverIds ] = useState(prepReceiverIdList(mailData));
    const [ haveReceivers, setHaveReceivers ] = useState((receiverIds.Teachers.length + receiverIds.Students.length) > 0);

    let teachers;
    let students;

    const handleFileDelete = async (id) => {
        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("delete"),
            message: (<div>{t.getText("file.delete.file.warning")}</div>),
            languageService: t,
            okOnEnter: true,
            type: "yes/no"
        });

        if (result === ModalConstants.Yes) {
            dispatch(classMailDuck.deleteAttachment(mailData.Id, id));
        }
    }

    const handleFileUploaded = (file) => {
        const data = {
            FileName: file.name,
            FileTempName: file.key

        }
        dispatch(classMailDuck.addAttachment(mailData.Id, data));
    }

    const handleSave = async () => {
        if (canSave) {
            const data = {
                Note: mailFormValues[ 'Note' ],
                MailSubject: mailFormValues[ 'MailSubject' ],
                MailBody: mailFormValues[ 'MailBody' ],
                AutoDelete: true,
                Teachers: receiverIds.Teachers,
                Students: receiverIds.Students,
            }
            try {
                await dispatch(classMailDuck.saveMail(mailData.Id, data));
                setCanSave(false);
                dispatch(classMailDuck.setDirty(false));
            } catch (error) {
                console.error(error);
                dispatch(errorDuck.setError({ when: new Date(),  header: t.getText('error'), message: t.getText('servererror') + '\n' + error }));
            }
        }
    }

    const handleSend = async () => {
        if (canSend) {
            const result = await Confirm({
                buttons: [],
                backdrop: 'static',
                class: null,
                title: t.getText("send"),
                message: (<div>{t.getText("mail.bulk.queue.confirm")}</div>),
                languageService: t, okOnEnter:true, type: "yes/no"
            });

            if (result === ModalConstants.Yes) {

                try {
                    await handleSave();
                    await dispatch(classMailDuck.sendMail(mailData.Id));
                    // redirect to detail, view must be handled by containing control group/class
                    handleMailSent( mailData.Id );

                } catch (error) {
                    console.error(error);
                    dispatch( errorDuck.setError({ when: new Date(), header: t.getText('error'), message: t.getText('servererror') + '\n' + error }));
                }
            };
        }
    }

    const receiverListHandler = (receiverList) => {
        teachers = prepReceiverList(receiverList.Teachers);
        students = prepReceiverList(receiverList.Students);
        const selectedReceiverIds = prepReceiverIdList(receiverList);
        setReceiverIds(selectedReceiverIds);
        const _haveReceivers = (selectedReceiverIds.Students.length + selectedReceiverIds.Teachers.length) > 0;
        setHaveReceivers(_haveReceivers);
        setCanSend(mailFormState[ 'isValid' ] && _haveReceivers);
        setCanSave(mailFormState[ 'isValid' ]);
    }

    const prepReceiverList = (list) => {
        return list.map(r => {
            return {
                Checked: r.Checked,
                Receiver: { ...r.Receiver },
                Archived: r.Archived
            }
        })
    }

    const updateStatus = (values, isSubmitting, isValid, dirty) => {
        setCanSave((!isSubmitting && isValid && dirty));
        setCanSend((!isSubmitting && isValid && haveReceivers));
        setMailFormValues(values);
        setMailFormState({ isSubmitting, isValid, dirty });
        dispatch(classMailDuck.setDirty(dirty));
    }

    teachers = prepReceiverList(mailData.Teachers);
    students = prepReceiverList(mailData.Students);

    return (<>
        <ReceiverModal ReturnListHandler={receiverListHandler} isTeacher={mailData.SentAsTeacher} userLists={{ Teachers: teachers, Students: students }} />
        <MailEditor fileUploaded={handleFileUploaded} handleFileDelete={handleFileDelete} mailData={mailData} showNote={true} updateStatus={updateStatus} />
        <div className={["pb-4 pt-4 border-top", classes['sticky-footer'], classes['mail-form']].join(' ')}>
            <div className="mb-2 mb-sm-4 d-flex ">
                <div>
                    <button className="btn btn-primary btn-small mr-1 mr-sm-4"
                        disabled={!canSave}
                        onClick={() => handleSave()}
                        type="button">{t.getText('save.short')}</button>
                    <button className="btn btn-primary btn-small mr-1 mr-sm-4"
                        disabled={!canSend}
                        onClick={() => handleSend()}
                        type="button">{t.getText('send')}</button>
                    <button className="btn btn-inverse btn-small mr-1 mr-sm-4"
                        onClick={() => closeForm()}
                        type="button">{t.getText('close')}
                    </button>
                </div>
                <button className="btn btn-inverse btn-danger btn-small ml-auto  ml-1" type="button" onClick={() => handleDelete(mailData.Id)}>{t.getText('delete')} </button>
            </div>
            <MailMeta mailData={mailData} t={t} />

        </div>
    </>)

}
export default ClassMailForm;
