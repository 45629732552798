import React, { useState } from "react";
import { Dropdown, DropdownToggle } from 'reactstrap';


/**
 * @param {{ className?: string, children: React.ReactChild, caret:any, toggleComponent: any, toogleClassName: string, toogleTagName:string, direction?: string }}
 */
const LnDropDownMenu = ({ className, children, caret, toggleComponent, toogleClassName, toogleTagName, direction }) => {

    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    const dir = direction || "down"

    return (
        <Dropdown className={className} a11y={false} direction={dir} isOpen={dropdownOpen} toggle={toggle} >
            <DropdownToggle caret={caret} className={toogleClassName} tag={toogleTagName}>
                {toggleComponent}
            </DropdownToggle>
            {children}
        </Dropdown >
    )
};


export default LnDropDownMenu;