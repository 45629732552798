//@ts-check
import React from "react";
import { useDispatch } from "react-redux";

import { useI18n } from "components/lni18n";
import TestTop from "../test/TestTop";
import { courseDuck } from "features/course/CourseDuck";
import { confirmTestDuck } from "./ConfirmTestDuck";
import useTypedSelector from "utils/useTypedSelector";
import { TestResultStatus } from "../test/TestEnums"
import WaitRipple from "components/WaitRipple";
import DebouncedButton from "components/DebounceButton";
import Link from "redux-first-router-link";
import ThreadView from "features/classes/ClassItems/Forum/fortest/ThreadView";


const ConfirmTest = props => {

    const currentTheme = courseDuck.useCurrentTheme();
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const currentTest = useTypedSelector(state => state.confirmTest);

    /**
     * @type {import('../test/Test').StartTestDto<any> }
     */
    const currentBaseTest = courseDuck.useCurrentTest();
    const currentPart = courseDuck.useCurrentPart();

    const dispatch = useDispatch();
    const { languageService: t } = useI18n();


    const handleConfirm = () => {
        confirmTestDuck.submitConfirmTest(currentClass.Id, currentBaseTest.TestId, dispatch);
    }

    if( !currentTest ||  !currentTest.currentQuestion){
        return <WaitRipple />;
    }

    return <div>

        <TestTop hideInstructions={currentTest.currentQuestion.ForumData !== null} languageService={t} classId={currentClass.Id} themeId={currentTheme.Id} partId={currentPart.Id} />

        <div className="mt-3 max-col-width">
            <div >
                <div className="alert alert-grey" >
                    <div>
                        {currentBaseTest.StudentInstructions
                            && <div className="alert border-primary"
                                dangerouslySetInnerHTML={{ __html: currentBaseTest.StudentInstructions }} >
                            </div>}
                        {!currentTest.currentQuestion.ForumData &&
                            <div className="max-col-width clearfix" dangerouslySetInnerHTML={{ __html: currentTest.currentQuestion.Text }}></div>
                        }

                        {currentTest.currentQuestion.ForumData &&
                            <div className="max-col-width clearfix" >
                                <div className="preserve-white">{currentTest.currentQuestion.ForumData.Instructions}</div>
                                <div className="mt-3">
                                    <Link to={{
                                        type: 'CLASS_FORUM_THREAD', payload:
                                        {
                                            classid: currentTest.currentQuestion.ForumData.ForumId,
                                            forumId: currentTest.currentQuestion.ForumData.ForumId,
                                            thread: currentTest.currentQuestion.ForumData.PostId
                                        }
                                    }} >
                                        {t.getText("forum.go.to.thread")}
                                    </Link>
                                </div>

                                <div className="mt-4" >
                                </div>
                                {currentTest.currentQuestion.Thread &&
                                    <ThreadView currentThread={currentTest.currentQuestion.Thread} />
                                }
                            </div>
                        }

                    </div>
                </div>

                <div className="position-relative w-50">
                    {currentTest.savingToserver && <WaitRipple />}
                </div>


                {(!currentTest.currentQuestion.ForumData && (currentBaseTest.Status === TestResultStatus.NotSet
                    || currentBaseTest.Status === TestResultStatus.TeacherUrge
                    || currentBaseTest.Status === TestResultStatus.FollowUpNeeded)) &&
                    <DebouncedButton disabled={currentTest.savingToserver} onClick={() => handleConfirm()} className="btn btn-primary mr-2" >{t.getText("ok")}</DebouncedButton>}


            </div>
            <div className="mt-4">&nbsp;</div>

        </div>

    </div>
}


export default ConfirmTest;

