//@ts-check
import React from "react";
import { useDispatch, shallowEqual } from "react-redux";

import { mcTestDuck } from "./McTestDuck";
import TestService from "../test/TestService";
import { useI18n } from "components/lni18n";
import McTestNoData from "./McTestNoData";
import { showHistoryDialog } from "../test/History";
import McTestTop from "./McTestTop";
import MCTestQuestion from "./MCTestQuestion";
import { courseDuck } from "features/course/CourseDuck";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";
import TestTop from "../test/TestTop";

const McTestStandard = (props) => {

    const currentPart = courseDuck.useCurrentPart();
    const currentTheme = courseDuck.useCurrentTheme();
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const { currentTest, currentMCQuestion } = useTypedSelector(state => state.mctest, shallowEqual);
    const currentBaseTest = courseDuck.useCurrentTest();
    const { currentTestId: testId } = useTypedSelector(state => state.course);



    const dispatch = useDispatch();
    const { languageService: t } = useI18n();


    const reloadTest = () => {
        TestService.getTest(dispatch, { testtype: currentBaseTest.Type, classid: currentBaseTest.ClassId, testid: currentBaseTest.TestId });
    }

    const submittestToServer = () => {
        const payload = {
            classid: currentClass.Id,
            testid: testId,
            sessionid: currentTest.TestSessionID
        }

        dispatch(mcTestDuck.submitMcTest(payload));
    }


    if (!currentBaseTest || !currentMCQuestion) {
        return <WaitRipple />
    }

    if (currentMCQuestion.data == null) {

        mcTestDuck.setDirtyState({ state: false });

        return <>
                <TestTop languageService={t} classId={currentClass.Id}
                    themeId={currentTheme.Id} partId={currentPart.Id}  >
                    {props.children}
                </TestTop>
            
            <McTestNoData
                reloadTestHandler={reloadTest}
                submittestToServer={submittestToServer}
                testName={currentBaseTest.Name}
                currentQuestion={currentMCQuestion}
                currentTest={currentTest}
                currentClass={currentClass}
                currentTheme={currentTheme}
                currentPart={currentPart}
                languageService={t}
                showHistoryDialog={() => showHistoryDialog(events, t)} />

        </>

    }


    let topInner = null;

    if (currentMCQuestion.data) {  // then set topInner
        const correctAnsw = currentMCQuestion.TotalCorrect;
        const totalAnswered = currentMCQuestion.TotalAnswered;

        const percent = Math.floor(100 * correctAnsw / totalAnswered);
        topInner = (
            <div className="d-inline-flex flex-column mr-2 mt-3" >
                <div className="white-space-nowrap small">
                    <span >{t.getText("correct_answers")}</span>  <strong>{correctAnsw}/{totalAnswered} ({percent}%)</strong>
                </div>

                <div className="progress primary">
                    <div className="progress-bar" role="progressbar" style={{ width: percent + "%" }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                </div>
            </div>);
    }

    const { Events: events } = currentBaseTest;

    return <>

            <McTestTop languageService={t} classId={currentClass.Id}
                themeId={currentTheme.Id} partId={currentPart.Id}  >
            </McTestTop>
            <>
                {topInner}
            </>
        

        {/* if we have data show the question */}
        {currentMCQuestion.data && <MCTestQuestion />}
    </>

}
export default McTestStandard;
