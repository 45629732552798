//@ts-check

import React from "react";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import classes from './dashboard.module.scss';

/**
 * UpdatedFeedPosts
 * @module features/classes/Dashboard/UpdatedFeedPosts
 * @return an UpdatedFeedPosts null || one item plus info if there are more
 * @param {{
    feedPosts: import('features/classes/Classes').FeedPostReportDto[], onlyLast: boolean
} } props
 */
const UpdatedFeedPosts = (props) => {

    const { languageService: t } = useI18n();

    const { feedPosts, onlyLast } = props;


    if (!feedPosts || feedPosts.length === 0) {
        return null;
    }

    let sorted = [...feedPosts];
    sorted.sort((f1, f2) => f2.When.getTime() - f1.When.getTime());
    const numPosts = sorted.length;
    if (onlyLast) {
        sorted = [sorted[0]];
    }

    return (<div>
        <strong>{t.getText("feed.menuname")}</strong>
        {sorted.map(f => <div key={f.Id}>
            <div className="d-flex">
                <div className="mr-5">
                    <small className="text-nowrap">
                        <LnIcon className="mr-4" name="calendar-icon" />
                        {t.getShortDateString(f.When)}
                    </small>
                </div>
                <div className="ml-auto min-width-0">
                    <span className={[classes['event'], classes['message-text']].join(' ')} dangerouslySetInnerHTML={{ __html: f.Text }}></span>
                    {onlyLast && numPosts > 1 && <span className="small">{t.getText("plus.n.more", numPosts - 1)}</span>}
                </div>
            </div>


        </div>
        )}
    </div>)

}

export default UpdatedFeedPosts;