//@ts-check
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import { useI18n } from "components/lni18n";
import classes from "./sidebarmenu.module.scss";
import { Collapse, CardBody, Card, CardHeader } from "reactstrap";
import { RIGHTARROW } from "assets/images";
import LnIcon from "components/LnIcon";
import { courseDuck } from "features/course/CourseDuck";
import { layoutDuck } from "features/layout/LayoutDuck";


const MyGroup = ({ resetTheme }) => {

    const { languageService: t } = useI18n();
    const currentClass = useTypedSelector(state => state.classData.currentClass);
    const { type, payload } = useTypedSelector(state => state.location);
    const sideBarForced = useTypedSelector(state => state.layout.sideBarForced);

    const dispatch = useDispatch();
    const currentTheme = courseDuck.useCurrentTheme();

    const selected = !currentTheme;


    const toogleOpen = useCallback(() => {
        resetTheme();
    }, [resetTheme]);


    if (!currentClass || currentClass.ShowInformation === false) {
        return <div></div>;
    }



    // get data for the user menu. Consider the settings at user object
    const items = [
        {
            visible: currentClass.ShowMembers,
            type: "cmd",
            selectExpr: (type, payload) => type === "CLASS_INFO",
            id: "CLASS_INFO",
            text: t.getText("members"),
            action: () => dispatch({ type: "CLASS_INFO", payload: { classid: currentClass.Id } })
        },
        {
            visible: currentClass.ShowPersonalPlan,
            type: "cmd",
            selectExpr: (type, payload) => type === "CLASS_PERSONAL_PLAN",
            id: "CLASS_PERSONAL_PLAN",
            text: t.getText("studentclassplan.label"),
            action: () => dispatch({ type: "CLASS_PERSONAL_PLAN", payload: { classid: currentClass.Id } })
        },
        {
            visible: currentClass.ShowTimetable,
            type: "cmd",
            selectExpr: (type, payload) => type === "CLASS_TIMETABLE",
            id: "CLASS_TIMETABLE",
            text: t.getText("timetable"),
            action: () => dispatch({ type: "CLASS_TIMETABLE", payload: { classid: currentClass.Id } })
        },
        {
            visible: currentClass.ShowFeed,
            type: "cmd",
            selectExpr: (type, payload) => type === "CLASS_FEED",
            id: "CLASS_FEED",
            text: t.getText("feed.menuname"),
            action: () => dispatch({ type: "CLASS_FEED", payload: { classid: currentClass.Id } })
        },
        {
            visible: currentClass.ShowForum,
            type: "cmd",
            selectExpr: (type, payload) => type.indexOf("CLASS_FORUM") !== -1,
            id: "CLASS_FORUM",
            text: t.getText("forum"),
            action: () => dispatch({ type: "CLASS_FORUM", payload: { classid: currentClass.Id } })
        },
        {
            visible: currentClass.ShowBulkMail,
            type: "cmd",
            selectExpr: (type, payload) => type.indexOf("CLASS_MAIL") > -1,
            id: "CLASS_MAIL",
            text: t.getText("studentsmenu.bulkmaillist"),
            action: () => dispatch({ type: "CLASS_MAIL", payload: { classid: currentClass.Id, listtype: 'draft' } })
        },

        {
            visible: currentClass.ShowFiles,
            type: "cmd",
            selectExpr: (type, payload) => type === "CLASS_FILES" || type === "CLASS_FILES_EDIT",
            id: "CLASS_FILES",
            text: t.getText("files"),
            action: () => dispatch({ type: "CLASS_FILES", payload: { classid: currentClass.Id } })
        },

        {
            visible: currentClass.DashBoardData && currentClass.DashBoardData.TeacherData,
            type: "separator"
        },

        {
            visible: currentClass.DashBoardData && currentClass.DashBoardData.TeacherData,
            type: "cmd",
            selectExpr: (type, payload) => type === "CLASS_ATTENDANCE",
            id: "CLASS_ATTENDANCE",
            text: t.getText("class.attendance"),
            action: () => dispatch({ type: "CLASS_ATTENDANCE", payload: { classid: currentClass.Id } })
        },

        {
            visible: currentClass.DashBoardData && currentClass.DashBoardData.TeacherData,
            type: "cmd",
            selectExpr: (type, payload) => type === "CLASS_FULL_PROGRESS",
            id: "CLASS_FULL_PROGRESS",
            text: t.getText("class.full.progress"),
            action: () => dispatch({ type: "CLASS_FULL_PROGRESS", payload: { classid: currentClass.Id, type: "results" } })
        },
        {
            visible: currentClass.DashBoardData && currentClass.DashBoardData.TeacherData,
            type: "cmd",
            selectExpr: (type, payload) => type === "CLASS_NOTIFICATIONS_EDIT",
            id: "CLASS_NOTIFICATIONS_EDIT",
            text: t.getText("notifications"),
            action: () => dispatch({ type: "CLASS_NOTIFICATIONS_EDIT", payload: { classid: currentClass.Id } })
        },
        {
            visible: currentClass.DashBoardData && currentClass.DashBoardData.TeacherData,
            type: "cmd",
            selectExpr: (type, payload) => type === "CLASS_EVAL_SUMMARY",
            id: "CLASS_EVAL_SUMMARY",
            text: t.getText("evaluations"),
            action: () => dispatch({ type: "CLASS_EVAL_SUMMARY", payload: { classid: currentClass.Id, type: "allparts" } })
        },
        {
            visible: currentClass.DashBoardData && currentClass.DashBoardData.TeacherData,
            type: "cmd",
            selectExpr: (type, payload) => type === "CLASS_STUDENTS_TABLE",
            id: "CLASS_STUDENTS_TABLE",
            text: t.getText("students.table"),
            action: () => dispatch({ type: "CLASS_STUDENTS_TABLE", payload: { classid: currentClass.Id } })
        },
        {
            visible: currentClass.DashBoardData && currentClass.DashBoardData.TeacherData,
            type: "cmd",
            selectExpr: (type, payload) => type === "CLASS_SUBSCRIPTION",
            id: "CLASS_SUBSCRIPTION",
            text: t.getText("subscriptions"),
            action: () => dispatch({ type: "CLASS_SUBSCRIPTION", payload: { classid: currentClass.Id } })
        }
    ];

    if (currentClass.SubGroups != null && currentClass.SubGroups.length > 0) {
        items.push({
            visible: true,
            type: "separator",
            selectExpr: null,
            id: null,
            text: null,
            action: null

        });


        currentClass.SubGroups.forEach(gr => {
            items.push(
                {
                    visible: true,
                    type: "cmd",
                    selectExpr: (type, payload) => type.indexOf("CLASS_SUBGROUP") > -1 && payload.groupid === gr.Id,
                    id: "CLASS_SUBGROUP_" + gr.Id,
                    text: t.getText("subgroup") + " - " + gr.Name,
                    action: () => dispatch({ type: "CLASS_SUBGROUP", payload: { classid: currentClass.Id, groupid: gr.Id, type: "info" } })
                });
        });
    }

    const performAction = (action) => {
        if (sideBarForced) {
            dispatch(layoutDuck.toggleSideBarState({}));
        }

        action();
    }


    return (

        <Card key={t.Id} className={[classes.theme, selected ? classes.selected + " border border-primary" : null].join(' ')}>
            <CardHeader className={classes.header + " px-1 py-0"}>
                <button className="btn btn-link w-100 d-flex my-2 py-1 pl-3 pr-0" onClick={() => toogleOpen()} >
                    < LnIcon name="group" className="mr-2 mt-2" />
                    <div className={classes.themename + " flex-grow-1 word-break-word"}>
                        {t.getText("studentsmenu.mygroup")}</div>
                    <img src={RIGHTARROW} alt="" className={["mx-1 mt-2", classes.indicator, selected ? classes.down : null].join(' ')} />
                </button>
            </CardHeader>
            <Collapse isOpen={selected}>
                <CardBody className={classes.parts}>
                    {
                        items.filter((item) => item.visible).map((item, i) => {
                            if (item.type === "cmd") {
                                return (
                                    <button
                                        className={[(item.selectExpr(type, payload) ? "selected" : ""), classes.part, "btn btn-link w-100"].join(' ')}
                                        key={i}
                                        onClick={() => performAction(item.action)}>
                                        <div className={"word-break-word " + classes.partname}>{item.text}</div>
                                    </button>
                                )
                            }

                            if (item.type === "separator") return <hr key={i} />

                            return null;
                        }
                        )
                    }
                </CardBody>
            </Collapse>
        </Card >


    )
}
export default MyGroup;
