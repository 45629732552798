//@ts-check
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useTypedSelector from 'utils/useTypedSelector';
import UserImg from "components/UserImg";
import { endPoint } from "AppConstants";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import classes from './forum.module.scss';
import { Confirm, ModalConstants } from "components/OnlineModal";
import { forumService } from "./ForumDuck";
import EditForumForm from "./EditForumForm";
import LnDropDownMenu from "components/LnDropDownMenu";
import { DropdownItem, DropdownMenu } from "reactstrap";

/**
 * ForumAnswerCard
 * @module features/classes/ClassItems/Forum/ForumAnswerCard
 * @return an Card with forum answer/answer detail.
 *
 * @param {{
    reply: import("./Forum").ForumThreadDto,
    parent: import("./Forum").ForumThreadDto,
}} props
 */

const ForumAnswerReplyCard = ({ reply, parent }) => {
    const dispatch = useDispatch();
    const { languageService: t } = useI18n();
    const { currentForum, userId } = useTypedSelector(state => state.forum);
    const [editMode, setEditMode] = useState(false);

    const setPublished = () => {
        dispatch(forumService.setPostingPublishState({ forumId: currentForum.Id, threadId: reply.Id, parentId: parent.Id, state: !reply.Published }));
    };


    /**
     * @type {{current: HTMLDivElement}}
     */
    const articleDiv = useRef();

    const getArticleText = () => {
        if (articleDiv.current) {
            return articleDiv.current.innerText;
        }

        return null;
    }

    const editReply = () => {
        setEditMode(true);
    }

    const deleteCurrent = async () => {
        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("comment.delete"),
            message: (<div>{t.getText("forum.delete.reply")}</div>),
            languageService: t,
            type: "yes/no"
        });

        if (result === ModalConstants.Yes) {
            dispatch(forumService.deletePosting(currentForum.Id, reply.Id, parent.Id));
        };
    };

    return (
        <div className={['ml-3 mr-1 d-md-flex', classes['forum-answer']].join(' ')}>
            <div className="d-none d-md-block">
                {reply.Writer.ImageUrl ? <UserImg src={endPoint.API_HOST + reply.Writer.ImageUrl} className={'rounded-circle avatar-img small ' + classes['answer-avatar']} alt={reply.Writer.FullName} /> : <LnIcon className={'rounded-circle avatar-img small ' + classes['answer-avatar']} name="user-icon" />}
            </div>
            <div className={["border card  mb-2", classes['answer-card'], (reply.Writer.Id === userId && "bg-primary-lightest p-3")].join(' ')}>
                <div className={"card-title d-flex justify-content-between "}>
                    <h5 className={"d-flex " + classes['forum-title']}>
                        <div className="d-md-none">
                            {reply.Writer.ImageUrl ? <UserImg src={endPoint.API_HOST + reply.Writer.ImageUrl} className={'rounded-circle avatar-img small ' + classes['answer-avatar']} alt={reply.Writer.FullName} /> : <LnIcon className={'rounded-circle avatar-img small ' + classes['answer-avatar']} name="user-icon" />}
                        </div>
                        {reply.Writer.FullName}

                    </h5>




                    <div className="d-flex">
                        <small className="d-flex flex-row flex-sm-column align-items-end">
                            <div className={"mr-2 " + ((!reply.Edited && reply.Unread) ? " badge-warning badge badge-pill" : "")}>
                                {t.getShortDateTimeString(reply.Created)}
                            </div>
                            {reply.Edited && <div className={"mt-2 d-flex " + (reply.Unread && " badge-warning badge badge-pill")}>
                                <div>{t.getText("edited")}&nbsp;</div>
                                <div>{t.getShortDateTimeString(reply.Edited)}</div>
                            </div>}
                        </small>

                        <div className="d-flex flex-md-column  flex-row">
                            {(reply.IsModerator || reply.CanEdit) &&
                                <LnDropDownMenu
                                    direction="down"
                                    caret={false}
                                    toggleComponent={
                                        <button type="button" className="edit round" title={t.getText("edit")} >
                                            <LnIcon className="icon-small" name="edit-icon" alt="Edit" />
                                        </button>
                                    }
                                    toogleClassName=""
                                    className="ml-3 mb-3"
                                    toogleTagName="div"
                                >
                                    <DropdownMenu right className="" container="body">
                                        <div className="d-flex flex-column">
                                            {reply.CanEdit &&
                                                <DropdownItem onClick={() => editReply()} tag="span" >{t.getText("edit")}</DropdownItem>
                                            }
                                            {(reply.IsModerator || reply.CanDelete) &&
                                                <DropdownItem onClick={() => deleteCurrent()} tag="span" >{t.getText("delete")}</DropdownItem>
                                            }

                                        </div>
                                    </DropdownMenu>
                                </LnDropDownMenu>
                            }
                            {reply.IsModerator &&
                                <button onClick={setPublished} type="button" className={"ml-3 edit round " + (reply.Published ? "" : "inverse")}>
                                    <LnIcon className="small" name={reply.Published ? "eye-icon" : "eye-icon-off"} />
                                </button>

                            }
                        </div>

                    </div>

                </div>

                <div className="d-flex">
                    {!editMode &&
                        <div ref={articleDiv} className="mb-3 flex-grow-1">
                            <div className="word-break-word card-text max-col-width" dangerouslySetInnerHTML={{ __html: reply.Article }}></div>
                        </div>
                    }

                    {editMode && <EditForumForm onLeaveThread={undefined} parentId={parent.Id} forumId={currentForum.Id} postType="reply" fileOptions={{
                        imageFileTypes: currentForum.ImageFileTypes,
                        videoFileTypes: currentForum.VideoFileTypes,
                        audioFileTypes: currentForum.AudioFileTypes,
                        AcceptFileTypes: currentForum.AcceptFileTypes,
                        MaxFileSize: currentForum.MaxFileSize
                    }} attachment={reply.Attachment}
                        article={getArticleText()}
                        showFileUpload={false}
                        postid={reply.Id}
                        toggleForm={() => setEditMode(false)} />

                    }

                </div>
            </div>
        </div>
    );
};

export default ForumAnswerReplyCard;